import store from 'store';
import { compose, lifecycle } from 'recompose';
import { getValues } from 'redux/selectors';
import { setValues } from 'redux/actions/ValueAction';

/*
 * This HOC will take in BaseRadioGroup component and return a new functionality
 *   If and only field.primary is true and value changed is 'yes',
 *   component will behave as a primary button across sets
 */
const withPrimaryRadioButtonLogic = compose(
    lifecycle({
        componentDidUpdate(prevProps) {
            const prevValue = prevProps.value;
            const { primary, value } = this.props;

            if (!primary) return true;

            const VALUE_YES = 'yes';
            const VALUE_NO = 'no';

            const { initialId, setId, tabIndex } = this.props;
            const reduxStates = store.getState();
            const vals = getValues(reduxStates);
            const principals = vals[setId];

            // if value not 'yes' or value din't change
            if (value !== VALUE_YES || prevValue === value) return;

            const newPrincipals = principals.map((principal, i) => {
                if (tabIndex !== i) {
                    principal[initialId] = VALUE_NO;
                }
                return principal;
            });

            store.dispatch(
                setValues({
                    [setId]: newPrincipals,
                }),
            );
        },
    }),
);

export default withPrimaryRadioButtonLogic;
