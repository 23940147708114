// https://github.com/benjaminoakes/moment-strftime/blob/v0.5.0/lib/moment-strftime.js
const replacements = {
    a: 'ddd',
    A: 'dddd',
    b: 'MMM',
    B: 'MMMM',
    c: 'lll',
    d: 'DD',
    '-d': 'D',
    e: 'D',
    F: 'YYYY-MM-DD',
    H: 'HH',
    '-H': 'H',
    I: 'hh',
    '-I': 'h',
    j: 'DDDD',
    '-j': 'DDD',
    k: 'H',
    l: 'h',
    m: 'MM',
    '-m': 'M',
    M: 'mm',
    '-M': 'm',
    p: 'A',
    P: 'a',
    S: 'ss',
    '-S': 's',
    u: 'E',
    w: 'd',
    W: 'WW',
    x: 'll',
    X: 'LTS',
    y: 'YY',
    Y: 'YYYY',
    z: 'ZZ',
    Z: 'z',
    f: 'SSS',
    '%': '%',
};

/**
 * A method to translate strftime format to js understandable datetime string format
 * @param {string} format - strftime format
 * @returns {string} format - moment format
 */
function translateToMoment(format = '') {
    const tokens = format.split(/(%\-?.)/);
    const momentFormat = tokens
        .map(function(token) {
            // Replace strftime tokens with moment formats
            if (token[0] === '%' && replacements.hasOwnProperty(token.substr(1))) {
                return replacements[token.substr(1)];
            }
            // Escape non-token strings to avoid accidental formatting
            return token.length > 0 ? '[' + token + ']' : token;
        })
        .join('');

    return momentFormat;
}

/**
 * A method to translate strftime format for each of the key value of the obj param
 * @param {Object} obj - object with key value in strftime format
 * @returns {Object} translated - translated object with key value in moment format
 */
export function translateDatetimeFormats(obj) {
    const translated = {};
    Object.keys(obj).forEach(key => {
        translated[key] = translateToMoment(obj[key]);
    });
    return translated;
}
