import RiskScoringSummary from '.';
import { compose, lifecycle } from 'recompose';
import { withProcessedProps } from '../../Misc/forms/withFieldProcessedProps';
import withGetTriggerValidityHandler from '../../Misc/forms/withGetTriggerValidityHandler';
import withDependencyCheckHandler from '../../Misc/forms/withDependencyCheckHandler';

const EnhancedRiskScoringSummary = compose(
    withProcessedProps,
    withGetTriggerValidityHandler,
    withDependencyCheckHandler,
    lifecycle({
        componentDidMount() {
            const { dependencyCheck } = this.props;
            dependencyCheck(true);
        },
    }),
);

export default EnhancedRiskScoringSummary(RiskScoringSummary);
