import BaseAPI from '../BaseAPI';
import axiosBaseAPI from '../AxiosBaseAPI';

export class ReportAPI extends BaseAPI {
    basePath = 'cap/reports/';
    reportPath = 'reporting-management-types/';
    headers = this.headers;
    host = this.host;

    async getReportTypes() {
        let reportType;

        try {
            reportType = await this.get({
                path: `${ this.basePath }${ this.reportPath }`,
            });
        } catch (e) {
            throw e.responseJSON;
        }

        return reportType;
    }

    async generateReport(data, reportType) {
        let report;
        try {
            const path = `${ this.basePath }${ this.reportPath }${ reportType }/generate`;

            report = await axiosBaseAPI({
                method: 'POST',
                path,
                data,
                responseType: 'blob',
            });
        } catch (e) {
            throw e.response.statusText;
        }
        return report;
    }
}
export const reportAPI = new ReportAPI();
export default ReportAPI;
