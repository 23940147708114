import BaseAPI from '../BaseAPI';
import axiosBaseAPI from '../AxiosBaseAPI';

export interface Workbook {
    upload_id: string;
    file_name: string;
    status: string;
    error: object;
    uploaded_by: string;
    extracted_rows: number;
    uploaded_at: string;
}

export interface WorkbookRow {
    registration_number: string;
    dba_name: string;
    registered_name: string;
    form: string;
    error: object;
}

class FormAPI extends BaseAPI {
    basePath = 'cap/templates/';
    formPath = 'cap/forms/';
    activeCampaignPath = 'cap/campaigns/active/';

    async getActiveCampaigns() {
        let campaigns;
        try {
            ({ campaigns } = await this.get({
                path: this.activeCampaignPath,
            }));
        } catch (e) {
            if (e.responseJSON) throw new Error(e.responseJSON);
            else throw new Error(e);
        }
        return campaigns;
    }

    async getFormData(templateId, formId) {
        let form, viewAccess;
        try {
            const response = await this.get({
                path: `${this.basePath}${templateId}/forms/${formId}/`,
            });
            form = response.form;
            viewAccess = response.view_access;
        } catch (e) {
            if (e.responseJSON) throw new Error(e.responseJSON);
            else throw new Error(e);
        }

        return { form, view_access: viewAccess };
    }

    // GRABBING THE LIST OF FORMS
    async getFormList(templateId, checkedStatuses, checkedAssignees, createdFromDate, createdToDate, updatedFromDate, updatedToDate, applicationId, uen, companyName) {
        let formData;
        const path = `${this.basePath}${templateId}/forms/search`;
        const data = {
            assignees: checkedAssignees,
            form_statuses: checkedStatuses,
        };

        if (createdFromDate && createdToDate) {
            data.created_from_date = createdFromDate;
            data.created_to_date = createdToDate;
        }
        if (updatedFromDate && updatedToDate) {
            data.updated_from_date = updatedFromDate;
            data.updated_to_date = updatedToDate;
        }

        if (applicationId) {
            data.application_id = applicationId;
        }

        if (uen) {
            data.uen = uen;
        }

        if (companyName) {
            data.company_name = companyName;
        }

        try {
            formData = await this.post({
                path,
                data,
            });
        } catch (e) {
            throw e;
        }
        return formData;
    }

    async callFormAction({ formId, uid, data }) {
        let response;

        try {
            response = await this.post({
                path: `${this.formPath}${formId}/execute-action`,
                data: {
                    uid,
                    data,
                },
            });
        } catch (e) {
            if (e.responseJSON) throw e.responseJSON;
            else throw e;
        }
        return response;
    }

    /**
     * A method that GET a form through path /cap/forms/<id>/download
     * @param {Object} data - format: { form: { data, id, template }, index }
     */
    async exportForm(data) {
        let response;
        const formId = data.form.id;

        try {
            response = await axiosBaseAPI({
                method: 'GET',
                path: `${this.formPath}${formId}/download`,
                responseType: 'blob',
            });
        } catch (e) {
            if (e.responseJSON) throw e.responseJSON;
            else throw e;
        }
        return response;
    }

    async bulkUpload(file: File) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('name', file.name);
        formData.append('template_id', '27');

        try {
            await axiosBaseAPI({
                method: 'POST',
                path: `${this.formPath}bulk/`,
                contentType: 'multipart/form-data',
                data: formData,
            });
        } catch (e) {
            if (e.responseJSON) throw e.responseJSON;
            else throw e;
        }
    }

    async getBulkUploadList(): Promise<Workbook[]> {
        try {
            const { data } = await this.get({
                path: `${this.formPath}bulk/workbooks`,
            });
            return data;
        } catch (e) {
            if (e.responseJSON) throw e.responseJSON;
            else throw e;
        }
    }

    async getBulkUploadResult(uploadId: string): Promise<WorkbookRow[]> {
        try {
            const { data } = await this.get({
                path: `${this.formPath}bulk/workbooks/${uploadId}`,
            });
            return data;
        } catch (e) {
            if (e.responseJSON) throw e.responseJSON;
            else throw e;
        }
    }

    async exportBulkUploadResult(uploadId: string) {
        try {
            await axiosBaseAPI({
                method: 'GET',
                path: `${this.formPath}bulk/workbooks/${uploadId}/download`,
                responseType: 'blob',
            });
        } catch (e) {
            throw e;
        }
    }
}

export const formAPI = new FormAPI();
export default FormAPI;
