import React from 'react';
import moment from 'moment-timezone';

import CalendarPopUpDate from './CalendarPopUpDate';
import CalendarPopUpMonth from './CalendarPopUpMonth';
import CalendarPopUpYear from './CalendarPopUpYear';
import CalendarPopUpHour from './CalendarPopUpHour';
import CalendarPopUpMinutes from './CalendarPopUpMinutes';
import CalendarPopUpYearRange from './CalendarPopUpYearRange';
import { compose, onlyUpdateForKeys, withStateHandlers, withPropsOnChange } from 'recompose';
import { calendarKey } from '../FormRules';

export const CalendarPopUp = props => {
    const { onMouseOver, onMouseLeave, onTouchStart, onTouchCancel, onClose } = props;
    const { open, position, setDate } = props;
    const {
        date,
        type,
        minDate,
        maxDate,
        timezone,
        yearRange,
        showYearRange,
        showYearAtDate,
    } = props;
    const {
        selectingDate,
        selectingHour,
        selectingMinute,
        selectingMonth,
        selectingYear,
        setSelectingDate,
        setSelectingHour,
        setSelectingMinute,
        setSelectingMonth,
        setSelectingYear,
        resetAll,
        selectingYearRange,
        setSelectingYearRange,
        setShownYearRange,
    } = props;
    const classNames = [
        'calendar-popup',
        position && position === 'top' ? 'popup-top' : 'popup-bottom',
    ];
    if (!open) return <div />;
    return (
        <div
            className={classNames.join(' ')}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            onTouchStart={onTouchStart}
            onTouchCancel={onTouchCancel}>
            <div className="popup-pointer"></div>
            <div className="popup-pointer-background"></div>
            <div className="popup-content">
                {selectingDate && (
                    <CalendarPopUpDate
                        date={date}
                        maxDate={maxDate}
                        minDate={minDate}
                        timezone={timezone}
                        showYearAtDate={showYearAtDate}
                        onYearClick={() => setSelectingYear(true)}
                        onMonthClick={() => setSelectingMonth(true)}
                        onClick={(e, data) => {
                            const date = `${data.year}-${data.month + 1}-${data.day}`;
                            const time = `${data.hour}:${data.minute}`;
                            const datetime = moment.tz(
                                `${date} ${time}`,
                                'YYYY-M-D HH:mm',
                                timezone,
                            );
                            setDate(datetime.toDate());
                            if (type === calendarKey.datetime) {
                                setSelectingHour(true);
                            } else {
                                resetAll();
                                onClose();
                            }
                        }}
                    />
                )}
                {selectingMonth && (
                    <CalendarPopUpMonth
                        date={date}
                        maxDate={maxDate}
                        minDate={minDate}
                        timezone={timezone}
                        onYearClick={() => setSelectingYear(true)}
                        onClick={(_, data) => {
                            const maxDays = moment([data.year, data.month]).daysInMonth();
                            const daysNo = data.day > maxDays ? maxDays : data.day;

                            const date = `${data.year}-${data.month + 1}-${daysNo}`;
                            const time = `${data.hour}:${data.minute}`;
                            const datetime = moment.tz(
                                `${date} ${time}`,
                                'YYYY-M-D HH:mm',
                                timezone,
                            );
                            setDate(datetime.toDate());
                            if (type === calendarKey.monthYear) {
                                onClose();
                            } else {
                                setSelectingDate(true);
                            }
                        }}
                    />
                )}
                {selectingYear && (
                    <CalendarPopUpYear
                        range={yearRange}
                        showYearRange={showYearRange}
                        onYearRangeClicked={() => setSelectingYearRange(true)}
                        date={date}
                        maxDate={maxDate}
                        minDate={minDate}
                        timezone={timezone}
                        onClick={(e, data) => {
                            const date = `${data.year}-${data.month + 1}-${data.day}`;
                            const time = `${data.hour}:${data.minute}`;
                            const datetime = moment.tz(
                                `${date} ${time}`,
                                'YYYY-M-D HH:mm',
                                timezone,
                            );
                            setDate(datetime.toDate());
                            if (type === calendarKey.year) {
                                onClose();
                                resetAll();
                            } else {
                                setSelectingMonth(true);
                            }
                        }}
                    />
                )}

                {selectingYearRange && (
                    <CalendarPopUpYearRange
                        date={date}
                        maxDate={maxDate}
                        minDate={minDate}
                        onClick={(e, data) => {
                            const yearRange = data.range;
                            setShownYearRange(yearRange);
                            setSelectingYear(true);
                        }}
                    />
                )}

                {selectingHour && (
                    <CalendarPopUpHour
                        date={date}
                        maxDate={maxDate}
                        minDate={minDate}
                        timezone={timezone}
                        onClick={(e, data) => {
                            const date = `${data.year}-${data.month + 1}-${data.day}`;
                            const time = `${data.hour}:${data.minute}`;
                            const datetime = moment.tz(
                                `${date} ${time}`,
                                'YYYY-M-D HH:mm',
                                timezone,
                            );
                            setDate(datetime.toDate());
                            setSelectingMinute(true);
                        }}
                    />
                )}
                {selectingMinute && (
                    <CalendarPopUpMinutes
                        date={date}
                        maxDate={maxDate}
                        minDate={minDate}
                        timezone={timezone}
                        onClick={(e, data) => {
                            const date = `${data.year}-${data.month + 1}-${data.day}`;
                            const time = `${data.hour}:${data.minute}`;
                            const datetime = moment.tz(
                                `${date} ${time}`,
                                'YYYY-M-D HH:mm',
                                timezone,
                            );
                            setDate(datetime.toDate());
                            onClose();
                            resetAll();
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export const withCalendarPopUpState = withStateHandlers(
    ({ type }) => {
        let selectingDate, selectingHour, selectingYear, selectingMonth;

        switch (type) {
            case calendarKey.time:
                selectingHour = true;
                break;
            case calendarKey.year:
                selectingYear = true;
                break;
            case calendarKey.monthYear:
                selectingMonth = true;
                break;
            default:
                selectingDate = true;
        }

        const reset = {
            selectingDate: false,
            selectingHour: false,
            selectingMinute: false,
            selectingMonth: false,
            selectingYear: false,
            selectingYearRange: false,
        };
        return {
            selectingDate,
            selectingHour,
            selectingYear,
            selectingMonth,
            reset,
            type,
        };
    },
    {
        setDate: () => date => ({
            popUpDate: date,
        }),
        setShownYearRange: () => yearRange => ({
            yearRange,
        }),
        setSelectingDate: ({ reset }) => selectingDate => ({
            ...reset,
            selectingDate,
        }),
        setSelectingHour: ({ reset }) => selectingHour => ({
            ...reset,
            selectingHour,
        }),
        setSelectingMinute: ({ reset }) => selectingMinute => ({
            ...reset,
            selectingMinute,
        }),
        setSelectingMonth: ({ reset }) => selectingMonth => ({
            ...reset,
            selectingMonth,
        }),
        setSelectingYear: ({ reset }) => selectingYear => ({
            ...reset,
            selectingYear,
        }),
        setSelectingYearRange: ({ reset }) => selectingYearRange => ({
            ...reset,
            selectingYearRange,
        }),
        resetAll: ({ reset, type }) => () => {
            let selectingDate, selectingHour, selectingYear, selectingMonth;

            switch (type) {
                case calendarKey.time:
                    selectingHour = true;
                    break;
                case calendarKey.year:
                    selectingYear = true;
                    break;
                case calendarKey.monthYear:
                    selectingMonth = true;
                    break;
                default:
                    selectingDate = true;
            }

            return {
                ...reset,
                selectingDate,
                selectingHour,
                selectingYear,
                selectingMonth,
            };
        },
    },
);

/**
 * Enhance calendar pop up to update only
 * value is changed
 */
const enhance = compose(
    onlyUpdateForKeys(['date', 'open']),
    withCalendarPopUpState,
    withPropsOnChange(['popUpDate'], ({ onChange, popUpDate }) => {
        const data = { value: popUpDate, date: popUpDate };

        if (data.value) onChange && onChange(null, data);
    }),
);

export default enhance(CalendarPopUp);
