import BaseAPI from '../BaseAPI';
import axiosBaseAPI from '../AxiosBaseAPI';

import { STATUS_CODE, HTTP_CODE } from '../../components/Misc/api';
import { session } from '../../components/SessionStorage';

class FileAPI extends BaseAPI {
    basePath = 'cap/uploaded-files/';
    systemConfigBasePath = 'cap/system-configurations/';
    headers = this.headers;
    host = this.host;

    async logoUploader({ formData, file }) {
        const url = `${ this.host }${ this.basePath }upload/logo`;
        return await this.fileUploader({ formData, file, url });
    }

    async systemConfigurationUploader({ formData, file }) {
        const url = `${ this.host }${ this.systemConfigBasePath }file-upload`;
        return await this.fileUploader({ formData, file, url });
    }

    async fileUploader({ formData, file, url }) {
        let response;
        url = url || `${ this.host }${ this.basePath }upload`;
        try {
            // format: { data: { file }, status_cd, status_desc }
            // logo format: { file, status_cd, status_desc }
            response = await axiosBaseAPI({
                method: 'POST',
                url,
                data: formData,
            });
            // to have a standardize format returned from api level
            // response.file -> response.data.file
            if (!response.data && response.file) {
                response.data = { file: response.file };
                delete response.file;
            }

            if (response.file.path) {
                response.data.file.name = response.file.path;
            } else {
                response.data.file.name = file.name;
            }
        } catch (error) {
            const { response: errorResponse = {} } = error;
            const errorStatus = errorResponse[HTTP_CODE.CODEKEY];
            const { data = {} } = errorResponse;
            const statusCode = data[STATUS_CODE.CODEKEY];

            // throw error if its 400, 404, 500
            if (
                errorStatus !== HTTP_CODE.NOT_FOUND &&
                errorStatus !== HTTP_CODE.BAD_REQUEST &&
                errorStatus !== HTTP_CODE.SERVER_ERROR
            ) {
                throw errorResponse.statusText;
            }

            if (
                statusCode !== STATUS_CODE.INVALID_FILE &&
                statusCode !== STATUS_CODE.MALICIOUS_FILE
            ) {
                response = {
                    data: {
                        file,
                    },
                    failed: true,
                    message: errorResponse.statusText,
                };
            } else {
                response = {
                    data: {
                        file,
                    },
                    // status_cd, status_desc
                    ...data,
                };
            }
        }
        return response;
    }

    async fileDownloader({ value, fieldId, formId }) {
        const url = this.generateDownloadURL({ value, fieldId, formId });
        let downloader;

        try {
            downloader = await axiosBaseAPI({
                method: 'GET',
                url,
                responseType: 'blob',
            });
        } catch (error) {
            const { response = {} } = error;
            throw response.statusText;
        }

        return downloader;
    }

    generateDownloadURL({ value, fieldId, formId, token }) {
        const arr = [];

        if (fieldId) {
            arr.push(`field_id=${ fieldId }`);
        }

        if (formId) {
            arr.push(`form_id=${ formId }`);
        }

        if (token === true) {
            arr.push(`token=${ session.getToken() }`);
        }

        const path = arr.join('&');
        const url = value.url || `${ this.host }${ this.basePath }${ value.uid }/download?${ path }`;

        return url;
    }
}

export default FileAPI;
export const fileAPI = new FileAPI();
