import React from 'react';
import PropTypes from 'prop-types';
import { compose, pure } from 'recompose';
import _ from 'underscore';
import moment from 'moment-timezone';
import { Label } from './Label';
import { withCaptureFieldHandlers } from '../Misc/forms';
import { getClientDatetimeFormats } from '../../datetime';
import { formatDate } from '../Misc/datetime';

class LabelValue extends React.Component {
    render() {
        const classWarning = 'warning';
        const {
            className,
            // option
            value,
            name,
            label,
            size,
            align,
            colour,
            lineHeight,
            bold,
            italic,
            underlined,
            capitalize,
            isValid,
            errorMessage,
            // utils
            showComponent,
            timezone,
        } = this.props;

        if (!showComponent) return <div />;

        const classNames = [_.isString(className) ? className : '', !isValid ? classWarning : ''];

        let title = value;

        if (value && label && label.title) {
            // check if string is date
            const isDate = moment(value).isValid();

            if (isDate) {
                const format = getClientDatetimeFormats()['datetime'];
                title = formatDate({ date: value, format, timezone });
            }

            // by default, position is left
            if (label.position === 'right') {
                title = title + label.title;
            } else {
                title = label.title + title;
            }
        }

        return (
            <div className={classNames.join(' ')}>
                <Label
                    name={name}
                    title={title}
                    align={align}
                    size={size}
                    bold={bold}
                    italic={italic}
                    colour={colour}
                    lineHeight={lineHeight}
                    underlined={underlined}
                    capitalize={capitalize}
                />

                <div className={'input__message ' + (errorMessage ? 'input__message--error' : '')}>
                    {errorMessage}
                </div>
            </div>
        );
    }
}

LabelValue.defaultProps = {
    className: '',
    value: '',
    colour: 'black',
    showComponent: true,
    isValid: true,
};

LabelValue.propTypes = {
    className: PropTypes.string,
    value: PropTypes.string,
    colour: PropTypes.string,
    align: PropTypes.string,
    size: PropTypes.string,
    bold: PropTypes.bool,
    italic: PropTypes.bool,
    underlined: PropTypes.bool,
    lineHeight: PropTypes.number,
    showComponent: PropTypes.bool,
    isValid: PropTypes.bool,
};

export default pure(compose(withCaptureFieldHandlers)(LabelValue));
