import React from 'react';
import _ from 'underscore';
import moment from 'moment-timezone';
import ObjectPath from 'object-path';
import ObjectClone from 'clone';
import ObjectAssign from 'object-assign';

import { withHandlers, compose } from 'recompose';
import { connect } from 'react-redux';

import {
    setSelectingField,
    setTemplateCreateObject,
    setWorkingPath,
    setPanel,
    setCurrentFieldValue,
    setTotalField,
    setTotalGrid,
    setTotalFieldset,
    setEditingField,
    setEditingGrid,
    setEditingFieldset,
    setClipboardField,
    setClipboard,
} from '../../redux/actions/SectionAction';
import { withFormUtils } from './forms';
import {
    DefaultGridObject,
    DefaultFieldsetObject,
    DefaultSectionObject,
} from '../Misc/DefaultFormObject';
import SectionBuilder from '../Form/SectionBuilder';
import { IconCopy, IconEdit, IconRemove } from '../../images/features/';
import { validateForm } from '../Form/FormRules';
import store from '../../store';
import { setValidators } from '../../redux/actions/ValidatorAction';
import { getValues } from '../../redux/selectors';
import { setValidities } from '../../redux/actions/ValidityAction';

export const TYPE = {
    field: 'field',
    grid: 'grid',
    fieldset: 'fieldset',
};

export function generateID() {
    return moment().format('x');
}

export function generateFieldID(id) {
    return TYPE.field + '_rn' + (id ? id : generateID());
}

export function generateGridID(id) {
    return TYPE.grid + '_rn' + (id ? id : generateID());
}

export function generateFieldsetID(id) {
    return TYPE.fieldset + '_rn' + (id ? id : generateID());
}

export const mapStateToProps = ({ section = {}, form = {} }) => {
    const { creation = {}, detail = {} } = section;
    const cloneSection = ObjectClone(DefaultSectionObject);
    const isActionButton = _.isEmpty(detail.fieldsets);
    const formCreation = form.creation;
    const { editing = {}, clipboard = {} } = creation;
    let editingField = ObjectPath.get(form, creation.workingPath, {});
    const clipboardFields = clipboard.fields || [];
    if (formCreation) cloneSection.fieldsets = formCreation.fieldsets;
    editingField = editingField || editing.obj || {};

    return {
        creatingSection: section,
        root: 'creation',
        sectionObj: cloneSection,
        isActionButton: isActionButton,
        workingPath: creation.workingPath,
        isSelecting: creation.isSelecting,
        totalField: creation.totalField || 1,
        totalGrid: creation.totalGrid || 1,
        totalFieldset: creation.totalFieldset || 1,
        isEditingField: editing.field,
        isEditingGrid: editing.grid,
        isEditingFieldset: editing.fieldset,
        editingField: editingField,
        isPanelOpen: creation.panel,
        isClipboardOpen: clipboard.showing,
        clipboardPath: clipboard.path,
        clipboardFields: clipboardFields,
    };
};

export const mapDispatchToProps = dispatch => {
    return {
        setSelectingField: isSelecting => {
            dispatch(setSelectingField(isSelecting));
        },
        selectingField: () => {
            dispatch(setSelectingField(true));
        },
        notSelectingField: () => {
            dispatch(setSelectingField(false));
        },
        setTemplateCreateObject: obj => {
            dispatch(setTemplateCreateObject(obj));
        },
        emptyTemplateCreateObject: () => {
            dispatch(setTemplateCreateObject({}));
        },
        setWorkingPath: path => {
            dispatch(setWorkingPath(path));
        },
        // numbers
        setTotalField: total => {
            dispatch(setTotalField(total));
        },
        setTotalGrid: total => {
            dispatch(setTotalGrid(total));
        },
        setTotalFieldset: total => {
            dispatch(setTotalFieldset(total));
        },
        // editing state
        setEditingField: isEditing => {
            dispatch(setEditingField(isEditing));
        },
        setEditingGrid: isEditing => {
            dispatch(setEditingGrid(isEditing));
        },
        setEditingFieldset: isEditing => {
            dispatch(setEditingFieldset(isEditing));
        },
        // panel & panel value change and update
        openPanel: () => {
            dispatch(setPanel(true));
        },
        closePanel: () => {
            dispatch(setPanel(false));
        },
        setCurrentFieldValue: data => {
            dispatch(setCurrentFieldValue(data));
        },
        // clipbaord
        setClipboardField: path => {
            dispatch(setClipboardField(path));
        },
        openClipboard: () => {
            dispatch(setClipboard(true));
        },
        closeClipboard: () => {
            dispatch(setClipboard(false));
        },
    };
};

const withStateChanger = withHandlers({
    changeEditingState:
        ({
            // props
            isEditingField = false,
            isEditingGrid = false,
            isEditingFieldset = false,
            // actions
            setEditingField,
            setEditingGrid,
            setEditingFieldset,
        }) =>
        (type, value) => {
            let state = false,
                func = () => {};

            switch (type) {
                case TYPE.field:
                    state = isEditingField;
                    func = setEditingField;
                    break;
                case TYPE.grid:
                    state = isEditingGrid;
                    func = setEditingGrid;
                    break;
                case TYPE.fieldset:
                    state = isEditingFieldset;
                    func = setEditingFieldset;
                    break;
            }

            value = value === undefined ? state : value;
            func(value);
        },
    changeFieldValue:
        ({
            // props
            workingPath,
            // handlers
            setCurrentFieldValue,
        }) =>
        (e, { path, value }) => {
            path = workingPath + '.' + path;
            setCurrentFieldValue({ path: path, value: value });
        },
});

export const withButtonListeners = withHandlers({
    handleFieldClick:
        ({
            // props
            isSelecting,
            sectionObj,
            workingPath = '',
            totalField = 1,
            root,
            // actions
            setTemplateCreateObject,
            notSelectingField,
            setTotalField,
            closeClipboard,
        }) =>
        (e, field) => {
            const cloneField = ObjectClone(field);
            cloneField.created_dt = moment().toISOString();

            if (isSelecting) {
                const isString = _.isString(root);
                workingPath = isString ? workingPath.replace(root + '.', '') : workingPath;
                let fields = ObjectPath.get(sectionObj, workingPath);
                fields = _.isArray(fields) ? fields : [];
                cloneField.id = generateFieldID();
                fields.push(cloneField);
                ObjectPath.set(sectionObj, workingPath, fields);
                setTemplateCreateObject(sectionObj);
                notSelectingField();
                setTotalField(++totalField);
                closeClipboard();
            }
        },
    handleAddFieldClick:
        ({
            // props
            sectionObj,
            totalField = 1,
            root,
            // actions
            setTemplateCreateObject,
            setTotalField,
            closeClipboard,
        }) =>
        (e, { field, path }) => {
            if (!_.isString(path)) return;
            const cloneField = ObjectClone(field);
            cloneField.created_dt = moment().toISOString();

            const p = path.split('.');
            p.push(generateFieldID());
            let newPath = p.join('.');
            const isString = _.isString(root);
            newPath = isString ? newPath.replace(root + '.', '') : newPath;
            ObjectPath.set(sectionObj, newPath, cloneField);
            setTemplateCreateObject(sectionObj);
            setTotalField(++totalField);
            closeClipboard();
        },
    handleChooseFieldClick:
        ({
            // props
            isSelecting,
            clipboardFields,
            // actions
            setSelectingField,
            setWorkingPath,
            openClipboard,
        }) =>
        (e, path) => {
            isSelecting = !isSelecting;
            setSelectingField(isSelecting);

            // let p = path.split('.');
            // p.push(generateFieldID());
            // let newPath = p.join('.');
            setWorkingPath(path);
            clipboardFields.length > 0 && openClipboard();
            return true;
        },
    handleCopyToClipboardClick:
        ({
            // handlers
            setClipboardField,
            openClipboard,
            closePanel,
        }) =>
        (e, path, field) => {
            if (!field) return;
            setClipboardField(ObjectAssign({}, field));
            openClipboard();
            closePanel();
        },
    handleEditFieldClick:
        ({
            // handlers
            setWorkingPath,
            openPanel,
            closeClipboard,
        }) =>
        (e, path) => {
            if (!path) return;

            setWorkingPath(path);
            openPanel();
            closeClipboard();
        },
    handleRemoveFieldClick:
        ({
            // handlers
            sectionObj,
            setTemplateCreateObject,
            closePanel,
            root,
            // from FormUtils
            showDeleteConfirmationForm,
            hideForm,
        }) =>
        (e, path) => {
            if (!path) return;

            const isString = _.isString(root);
            path = isString ? path.replace(root + '.', '') : path;

            showDeleteConfirmationForm({
                message: 'Are you sure you want to remove this field?',
                onConfirm() {
                    hideForm();
                    ObjectPath.del(sectionObj, path);
                    setTemplateCreateObject(sectionObj);
                    closePanel();
                },
            });
            return true;
        },
    handleAddGridClick:
        ({
            // props
            sectionObj,
            totalGrid,
            root,
            // actions
            setTemplateCreateObject,
            setTotalGrid,
            closePanel,
        }) =>
        (e, path, position) => {
            const isString = _.isString(root);
            path = isString ? path.replace(root + '.', '') : path;
            const cloneGrid = ObjectClone(DefaultGridObject);
            cloneGrid.id = generateGridID();
            cloneGrid.created_dt = moment().toISOString();
            const grids = ObjectPath.get(sectionObj, path) || [];

            if (position === 'left') {
                grids.unshift(cloneGrid);
            } else {
                // right
                grids.push(cloneGrid);
            }

            ObjectPath.set(sectionObj, path, grids);
            setTemplateCreateObject(sectionObj);
            setTotalGrid(++totalGrid);
            closePanel();
            return true;
        },
    handleRemoveGridClick:
        ({
            // props
            sectionObj,
            totalGrid,
            root,
            // actions
            setTemplateCreateObject,
            setTotalGrid,
            closePanel,
        }) =>
        (e, path) => {
            if (totalGrid < 1 || !path) return;

            const isString = _.isString(root);
            path = isString ? path.replace(root + '.', '') : path;

            ObjectPath.del(sectionObj, path);
            setTemplateCreateObject(sectionObj);
            setTotalGrid(--totalGrid);
            closePanel();
        },
    handleAddFieldsetClick:
        ({
            // props
            sectionObj,
            totalFieldset,
            root,
            // actions
            setTemplateCreateObject,
            setTotalFieldset,
            closePanel,
            // formUtils
            showCreateForm,
            hideForm,
            TYPE,
        }) =>
        (e, path = '', position = '') => {
            store.dispatch(
                setValidities({
                    fieldset_name: null,
                }),
            );
            store.dispatch(
                setValidators({
                    fieldset_name: ['isRequired', { max: 255 }],
                }),
            );
            showCreateForm({
                name: '',
                fields: [
                    {
                        type: TYPE.text,
                        label: 'Fieldset Name',
                        key: 'fieldset_name',
                        value: '',
                        placeholder: 'Fieldset Name',
                        validators: ['isRequired'],
                    },
                ],
                onCreate(e, { form }) {
                    const values = getValues(store.getState());
                    const validities = validateForm({ form }).validities;
                    const isValid =
                        Object.values(validities).filter(v => v && !v.isValid).length < 1;
                    store.dispatch(setValidities(validities));
                    if (!isValid) return;

                    const cloneFieldset = ObjectClone(DefaultFieldsetObject);
                    cloneFieldset.id = generateFieldsetID();
                    cloneFieldset.name = values.fieldset_name;
                    cloneFieldset.created_dt = moment().toISOString();
                    ObjectPath.set(cloneFieldset, 'grids.0.created_dt', moment().toISOString());
                    const isString = _.isString(root);
                    path = `${path}.fieldsets`;
                    path = isString ? path.replace(`${root}.`, '') : path;
                    const fieldsets = ObjectPath.get(sectionObj, path) || [];

                    if (position === 'top') {
                        fieldsets.unshift(cloneFieldset);
                    } else {
                        // bottom
                        fieldsets.push(cloneFieldset);
                    }

                    ObjectPath.set(sectionObj, path, fieldsets);
                    setTemplateCreateObject(sectionObj);
                    setTotalFieldset(++totalFieldset);

                    closePanel();

                    hideForm();
                },
            });
            return true;
        },
    handleEditFieldsetClick:
        ({
            // handlers
            setWorkingPath,
            openPanel,
            closeClipboard,
        }) =>
        (e, path) => {
            if (!path) return;

            setWorkingPath(path);
            openPanel();
            closeClipboard();
            return true;
        },
    handleRemoveFieldsetClick:
        ({
            // props
            sectionObj,
            totalFieldset,
            root,
            // actions
            setTemplateCreateObject,
            setTotalFieldset,
            closePanel,
        }) =>
        (e, path = '') => {
            closePanel();
            if (!path) return;

            const isString = _.isString(root);
            path = isString ? path.replace(`${root}.`, '') : path;
            ObjectPath.del(sectionObj, path);
            setTemplateCreateObject(sectionObj);
            setTotalFieldset(--totalFieldset);
        },
});

export const withCreationUtils = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withFormUtils,
    withStateChanger,
    withButtonListeners,
);

export const fieldAction = ({
    // props
    path,
    field,
    edit = true,
    // handlers
    handleCopyToClipboardClick,
    handleEditFieldClick,
    handleRemoveFieldClick,
}) => (
    <div className="button-hover-show button-top button-right button-hover action__wrapper grid">
        <div
            className="action__button"
            title="Copy to clipboard"
            data-rh="Copy to clipboard"
            data-rh-at="bottom"
            onClick={e => {
                handleCopyToClipboardClick(e, path, field);
            }}>
            <img src={IconCopy} alt="Copy to clipboard" className="button__icon" />
        </div>
        {edit && (
            <div
                className="action__button"
                title="Edit Field"
                data-rh="Edit Field"
                data-rh-at="bottom"
                onClick={e => {
                    handleEditFieldClick(e, path, field);
                }}>
                <img src={IconEdit} alt="Edit Field" className="button__icon" />
            </div>
        )}
        <div
            className="action__button"
            title="Remove Field"
            data-rh="Remove Field"
            data-rh-at="bottom"
            onClick={e => {
                handleRemoveFieldClick(e, path);
            }}>
            <img src={IconRemove} alt="Remove Field" className="button__icon" />
        </div>
    </div>
);

export const gridAction = ({
    path,
    handleRemoveGridClick,
    // utils
    hideForm,
    showDeleteConfirmationForm,
}) => (
    <div className="button-hover-show button-top button-center button-hover action__wrapper grid">
        <div
            className="action__button"
            title="Remove Grid"
            data-rh="Remove Grid"
            data-rh-at="bottom"
            onClick={e => {
                showDeleteConfirmationForm({
                    message: 'All fields within this grid will be removed. Are you sure ?',
                    onConfirm() {
                        hideForm();
                        handleRemoveGridClick(e, path);
                    },
                });
            }}>
            <img src={IconRemove} alt="Remove Grid" className="button__icon" />
        </div>
    </div>
);

export const fieldsetAction = ({
    path,
    fieldset,
    // handlers
    handleEditFieldsetClick,
    handleRemoveFieldsetClick,
    // utils
    hideForm,
    showDeleteConfirmationForm,
}) => (
    <div className="button-hover-show button-top button-right button-hover action__wrapper grid">
        <div
            className="action__button"
            title="Edit Fieldset"
            data-rh="Edit Fieldset"
            data-rh-at="bottom"
            onClick={e => {
                return handleEditFieldsetClick(e, path, fieldset);
            }}>
            <img src={IconEdit} alt="Edit Fieldset" className="button__icon" />
        </div>
        <div
            className="action__button"
            title="Remove Fieldset"
            data-rh="Remove Fieldset"
            data-rh-at="bottom"
            onClick={e => {
                showDeleteConfirmationForm({
                    message: 'All fields within this fieldset will be removed. Are you sure ?',
                    onConfirm() {
                        hideForm();
                        handleRemoveFieldsetClick(e, path);
                    },
                });
                return true;
            }}>
            <img src={IconRemove} alt="Remove Fieldset" className="button__icon" />
        </div>
    </div>
);

export const allFieldButton = ({ path = '', FIELD, handleAddFieldClick }) => {
    const className = ['button', 'button-small', 'button-grey'];
    const buttonArr = Object.values(FIELD);

    return (
        <div>
            {buttonArr.map((field, index) => (
                <div
                    key={index}
                    className={className.join(' ')}
                    style={{ float: 'left' }}
                    onClick={e => {
                        return handleAddFieldClick(e, { field, path });
                    }}>
                    {`+ ${field.type}`}
                </div>
            ))}
        </div>
    );
};

export const chooseFieldButton = ({
    path = '',
    workingPath,
    isSelecting,
    handleChooseFieldClick,
}) => {
    const className = [
        'button',
        'button-small',
        'button-bottom',
        'button-grey',
        'button-center',
        'button-hover',
        'button-hover-show',
    ];
    const isMe = workingPath && isSelecting ? workingPath === path : true;

    if (!isMe) return <div />;

    return (
        <div
            className={className.join(' ')}
            onClick={e => {
                return handleChooseFieldClick(e, path);
            }}>
            {isSelecting ? 'Cancel' : 'Add Field'}
        </div>
    );
};

export const addGridButton = ({ position = 'left', path = '', handleAddGridClick }) => {
    const className = [
        'button',
        'button-small',
        'button-div',
        'button-grey',
        'button-hover',
        'button-hover-show',
    ];
    position === 'left' && className.push('button-left');
    position === 'right' && className.push('button-right');

    return (
        <div
            className={className.join(' ')}
            onClick={e => {
                return handleAddGridClick(e, path, position);
            }}>
            Add Grid
        </div>
    );
};

export const addFieldsetButton = ({
    position = '',
    path = '',
    handleAddFieldsetClick = () => {},
}) => {
    const className = ['button', 'button-small', 'button-div', 'button-grey', 'button-hover'];
    position === 'top' && className.push('button-top');
    position === 'bottom' && className.push('button-bottom');

    return (
        <div
            className={className.join(' ')}
            onClick={e => {
                return handleAddFieldsetClick(e, path, position);
            }}>
            Add Fieldset
        </div>
    );
};

export const removeFieldsetButton = ({
    path = '',
    hideForm,
    showDeleteConfirmationForm = () => {},
    handleRemoveFieldsetClick = () => {},
}) => {
    const className = [
        'button',
        'button-small',
        'button-bottom',
        'button-center',
        'button-grey',
        'button-hover',
        'button-hover-show',
    ];

    return (
        <div
            className={className.join(' ')}
            onClick={e => {
                showDeleteConfirmationForm({
                    message: 'All fields within this fieldset will be removed. Are you sure ?',
                    onConfirm() {
                        hideForm();
                        handleRemoveFieldsetClick(e, path);
                    },
                });
                return true;
            }}>
            Remove Fieldset
        </div>
    );
};

export const FieldAction = withCreationUtils(fieldAction);
export const GridAction = withCreationUtils(gridAction);
export const FieldsetAction = withCreationUtils(fieldsetAction);
export const ChooseFieldButton = withCreationUtils(chooseFieldButton);
export const AddGridButton = withCreationUtils(addGridButton);
export const AddFieldsetButton = withCreationUtils(addFieldsetButton);
export const RemoveFieldsetButton = withCreationUtils(removeFieldsetButton);
export default withCreationUtils(props => <SectionBuilder {...props} />);
