import React from 'react';

import { lifecycle, compose, withProps, withState, mapProps } from 'recompose';
import { connect } from 'react-redux';
import { session } from '../SessionStorage';
import { goToDashboardUser, goToLogin, PagePath } from '../GoToPage';
import { setToken, setUserInfo, setExpiryDate } from '../../redux/actions/AuthenticationAction';
import { HTTP_CODE } from './api';

const mapStateToProps = ({ user }) => {
    const { token } = user;
    return {
        token,
    };
};

const mapDispatchToProps = dispatch => ({
    setUserToken: token => {
        dispatch(setToken(token));
    },
    setUserProfile: info => {
        dispatch(setUserInfo(info));
    },
    setExpiryDate: date => {
        dispatch(setExpiryDate(date));
    },
});

export const withToken = compose(withState('isLoggedIn', 'setIsLoggedIn', !!session.getToken()));

const LoggedInLifecycle = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withToken,
    withProps(() => {
        const deltas = session.getLoginLog('deltas') || [];
        const totalDelta = deltas.length && deltas.reduce((a, v) => a + v);
        const forceStopRedirect = totalDelta && totalDelta < maxDeltaMs;

        return {
            forceStopRedirect,
        };
    }),
    lifecycle({
        componentDidMount() {
            const { redirect, isLoginPage, forceStopRedirect = false, location = {} } = this.props;
            let isPasswordPage = false;
            if (!isLoginPage) {
                if (location.pathname === PagePath.forgot_password || PagePath.reset_password) {
                    isPasswordPage = true;
                }
            }
            const token = session.getToken();
            if (!token) {
                // need to check is valid token with endpoint instead of this
                if (redirect && !isPasswordPage && !isLoginPage && !forceStopRedirect) {
                    goToLogin();
                }
            } else {
                if (isLoginPage && !forceStopRedirect) {
                    goToDashboardUser();
                } else {
                    this.props.setIsLoggedIn(true);
                    this.props.setUserToken(token);
                    this.props.setUserProfile(session.getUserInfo());
                    this.props.setExpiryDate(session.getExpiryDate());
                }
            }
        },
    }),
    mapProps(({ isLoggedIn, forceStopRedirect, setIsLoggedIn, ...rest }) => ({
        isLoggedIn,
        ...rest,
    })),
);

const maxDeltaMs = 1 * 60 * 60;
export const withIsLogInPage = compose(
    withProps(() => ({
        isLoginPage: true,
    })),
    lifecycle({
        componentDidMount() {
            const now = new Date().getTime();
            const lastAccess = session.getLoginLog('lastAccess') || now;
            let deltas = session.getLoginLog('deltas') || [];
            // only retain the last 3 delta
            deltas = deltas.length > 3 ? deltas.slice(deltas.length - 2, deltas.length) : deltas;
            const clearDelta = lastAccess - now > maxDeltaMs;

            session.setLoginLog({
                lastAccess: now,
                deltas: clearDelta ? [] : [...deltas, now - lastAccess],
            });
        },
    }),
);

/**
 * Upgrade renderProps of hoc.
 * HOC to return isLoggedIn state to the component (with redirecting).
 *   component can use this prop to identify whether it's should
 *   render out it's page content when user are not logged in.
 */
export const withTokenCheckWithRedirect = compose(
    withProps(({ redirect = true }) => ({
        redirect,
    })),
    LoggedInLifecycle,
);
export const TokenCheckWithRedirect = withTokenCheckWithRedirect(({ children, ...rest }) =>
    children(rest),
);
/**
 * Upgrade renderProps of hoc.
 * HOC to return isLoggedIn state to the component (without redrecting).
 *   component can use this prop to identify whether it's should
 *   render out it's page content when user are not logged in.
 */
export const withTokenCheck = compose(LoggedInLifecycle);
export const TokenCheck = withTokenCheck(({ children, ...rest }) => children(rest));

/**
 * HOC to return isForbidden to the component.
 *   component can use isForbidden to identify whether it's should
 *   render out it's page content when user are not not allowed to
 *   access.
 */
export const withForbiddenChecking = compose(
    withProps(({ error }) => {
        const isForbidden = error ? error.status === HTTP_CODE.FORBIDDEN : false;

        return {
            Forbidden: (
                <div
                    className="container content forbidden"
                    onClick={() => {
                        goToDashboardUser();
                    }}>
                    Forbidden
                </div>
            ),
            isForbidden,
        };
    }),
);

export { compose };
