import _ from 'underscore';
import store from '../../store';
import * as Selector from '../../redux/selectors';
import { getFields } from 'redux/selectors/FieldSelector';

import ObjectEntries from 'object.entries';
import ObjectClone from 'clone';
import ObjectPath from 'object-path';
import IsObject from 'is-object';
import ObjectClean from 'clean-deep';
import DeepAssign from 'deep-assign';
import jsonLogic from 'json-logic-js';

import Calc from 'expression-calculator/exprcalc';

import { rshRules } from '../Misc/jsonLogics/rsh';
import { mphRules } from '../Misc/jsonLogics/mph';

import { replaceValidation } from '../Misc/forms';
import { validateInputOld } from './OldFormRules';
import { FormState } from 'redux/reducers/FormReducer';

export const isRequiredKey = 'isRequired';
export const isAcceptedKey = 'isAccepted';
export const isAgreementGeneratedKey = 'isAgreementGenerated';
export const valueFormatKey = 'valueFormat';
export const minLengthKey = 'minLength';
export const maxLengthKey = 'maxLength';
export const viewKey = 'view';
export const sectionKey = 'section';
export const fieldsetKey = 'fieldset';
export const gridKey = 'grid';
export const textKey = 'text';
export const textWithLabelTop = 'textTop';
export const textWithLabelLeft = 'textLeft';
export const textWithLabelRight = 'textRight';
export const textWithoutLabel = 'textWithoutLabel';
export const textWithLeftInnerLabelKey = 'textWithLeftInnerLabel';
export const textWithRightInnerLabelKey = 'textWithRightInnerLabel';
export const textWithButtonKey = 'textWithButton';
export const labelKey = 'label';
export const labelValueKey = 'labelValue';
export const noteKey = 'note';
export const selectKey = 'select';
export const selectOptionsKey = 'options';
export const selectWithoutLabel = 'selectWithoutLabel';
export const radioKey = 'radio';
export const radioOptionsKey = 'radios';
export const checkboxKey = 'checkbox';
export const checkboxOptionsKey = 'checkboxes';
export const buttonKey = 'button';
export const uploadButtonKey = 'upload';
export const calendarKey = {
    default: 'calendar',
    calendar: 'calendar',
    datetime: 'datetime',
    date: 'date',
    time: 'time',
    year: 'year',
    monthYear: 'monthYear',
};
export const imageKey = 'image';
export const breakLineKey = 'breakline';
export const textAreaKey = 'textarea';
export const remarksKey = 'remark';
export const hoTableKey = 'hot';
export const hoTableColumnKey = 'hotColumn';
export const duplicableFieldset = 'duplicableFieldset';
export const signatoryKey = 'signatory';
export const acceptanceKey = 'acceptance';
export const gpsKey = 'gps';
export const midtidKey = 'midtid';
export const productTypesKey = 'product_types';
export const mdrKey = 'mdr';
export const productsKey = 'products';
export const standardMDRsKey = 'standard_merchant_rates';
export const standardMDRsListKey = 'mdrs';
export const terminalConfigurationKey = 'terminal_configuration';
export const dateTimeRangeKey = 'datetime_range';
export const copyKey = 'copy';
export const mdr = {
    offUsCredit: 'off_us_credit',
    offUsDebit: 'off_us_debit',
    offUsIntl: 'off_us_international',
    onUsCredit: 'on_us_credit',
    onUsDebit: 'on_us_debit',
    onUsIntl: 'on_us_international',
    flatRate: 'flat_rate',
    flatRatePercent: 'flat_rate_percent',
};
export const setTypeKey = {
    vertical: 'vertical',
    spreadsheet: 'spreadsheet',
};
export const riskScoringSummaryKey = 'risk_scoring_summary';
export const creditScoringSummaryKey = 'credit_scoring_summary';

const calc = new Calc();
export const VALIDATOR_ERROR_MESSAGE = {
    isAccepted:
        'Please scroll to the end, check and accept the terms and conditions before proceeding further',
    isUrl: 'URL',
    isEmail: 'email address',
    isAlpha: 'only alphabetic character(s)',
    isAlphanumeric: 'only alphabetic and number character(s)',
    isAlphanumericSpace: 'only alphabetic, number and space character(s)',
    isAlphanumericSpaceWithSpecialChar: 'only alphabetic, number, space and special character(s)',
    isNumeric: 'only numbers',
    isInt: 'only numbers',
    isEmpty: 'empty',
    isFloat: 'a decimal number',
    isLength: 'length of',
    isTime: 'time (HH:MM AM/PM)',
    isYear: 'year',
    regex: 'in the pattern of',
    escape: 'escaped with these character(s)',
    isDecimal: 'having a decimal place',
    isDecimalWithOneDigit: 'only 1 digit with 2 decimal place',
    isRegisteredNumber: 'only max 10 digits followed by a - and a single alphabet (009999999-X)',
};

export const DefaultValidators = {
    label: {
        type: [labelKey],
        title: 'Rules :',
        align: 'left',
        size: 'small',
    },
    [valueFormatKey]: {
        type: [selectKey],
        [selectOptionsKey]: [
            {
                title: 'Alphabet',
                name: 'alphabet',
                value: 'isAlpha',
            },
            {
                title: 'Alphabet + Number',
                name: 'alphabet and number',
                value: 'isAlphanumeric',
            },
            {
                title: 'Email address',
                name: 'email Address',
                value: 'isEmail',
            },
            {
                title: 'Number',
                name: 'numeric',
                value: 'isNumeric',
            },
            {
                title: 'Integer',
                name: 'integer',
                value: 'isInt',
            },
        ],
        title: 'Type of value',
        defaultValue: 'isAlphanumeric',
    },
    [minLengthKey]: {
        type: [textKey],
        label: {
            type: 'inner',
            title: 'min',
            position: 'left',
        },
        title: 'min length',
        value: 1,
        isValid: true,
        validators: ['isNumeric'],
    },
    [maxLengthKey]: {
        type: [textKey],
        [labelKey]: {
            type: 'inner',
            title: 'max',
            position: 'left',
        },
        title: 'max length',
        value: '',
        isValid: true,
        validators: ['isNumeric'],
    },
};

/**
 * Clean up data.
 * ObjectClean removes null/undefined values from arrays and objects.
 * return 0 for empty array
 * @param {any} value - any format of value
 * @param {boolean} emptyArrayToZero - truth value to indicate either to return 0 if 'value' is empty array
 * @param {trim} trim - truth value to indicate either to return trimmed 'value'
 * @returns {any} processedValue
 */
export function cleanValue(value, emptyArrayToZero = true, trim = true) {
    if (_.isArray(value) || _.isObject(value)) {
        value = ObjectClean(value);
        if (emptyArrayToZero && _.isArray(value) && !value.length) value = value.length;
    } else if (typeof value === 'string' && trim) {
        value = value.trim();
    } else if (_.includes([undefined, null], value)) {
        value = '';
    }
    return value;
}

export function validateInput({ id, validators = [], value = null }) {
    const isAdmin = Selector.getIsAdminPage(store.getState());
    if (isAdmin) {
        return validateInputOld({ validators, value });
    }

    const validate = Selector.getValidators(store.getState());

    if (_.isEmpty(validate)) {
        return { isValid: true, errorMessage: '' };
    }

    let errorMessage = '';

    value = cleanValue(value, false, false);

    if (_.isNumber(value)) {
        value = value.toString();
    }

    /** value validates against the schema */
    const isValid = _.isFunction(validate) ? validate({ [id]: value }) : true;

    if (!isValid) errorMessage = validate.errors[0].message;

    return { isValid, errorMessage };
}

/**
 * Function to get a object of validities with a form object.
 * @returns {object} - { field_id: validity }
 */

export const validateFormViews = (form: FormState, end?: number): boolean[] => {
    const viewValidities = new Array(form.views.length).fill(true);
    end = end || form.views.length;
    form.views.slice(0, end).forEach((view, idx) => {
        const validities = validateForm({ form: view }).validities;
        viewValidities[idx] = isAllValid(validities);
    });

    return viewValidities;
};

const isAllValid = (validities: object): boolean => {
    return Object.values(validities)
        .map(x => {
            if (Array.isArray(x)) {
                return x.map(x => isAllValid(x)).every(x => x);
            }
            return x.isValid;
        })
        .every(x => x);
};

export function validateForm({
    form,
    path,
    validityPath,
    validators,
    tabsId,
    tabIndex,
    ignoredIds = [],
}) {
    const state = store.getState();
    const valueObj = {
        ...Selector.getValues(state),
        // add custom values for validation
        ...Selector.getCustomDefaultValue(state),
    };
    const triggerObj = Selector.getTriggers(state);
    const triggeredStates = Selector.getTriggeredStates(state);
    const validatorObj = validators || Selector.getValidators(state);
    const disabledFields = {
        ...Selector.getDisabledFields(state),
        ...Selector.getDisabledCampaignFields(state),
    };
    const disabledSections = Selector.getDisabledSections(state);
    let validity = {
        isValid: true,
        validities: {},
    };
    const contextPath = path;
    const contextValidityPath = validityPath;

    ObjectEntries(form).forEach(formObj => {
        let [key, value] = formObj;
        let formValidity = {};
        let { set = [], setType, grids, type, id, isEditable = true } = value || {};
        const isHoTable = setType === 'spreadsheet';
        set = _.isArray(set) ? set : [];
        if (id) {
            key = value.id;
        }

        // stop searching when value is not object
        // + section is disabled
        // + not editable
        // + triggeredStates of this id is true
        let statePath = contextPath ? `${contextPath}.${key}` : key;
        if (type === fieldsetKey) {
            statePath = contextValidityPath ? `${contextValidityPath}.${id}` : id;
        }
        if (
            !_.isObject(value) ||
            disabledSections[id] ||
            !isEditable ||
            // commenting this, dependencyCheck is setting outlet.n.fields at root level, this line is returning wrong decision
            // isTriggered(triggeredStates[id]) ||
            // this line should already handle root / multi levels triggered state check respesctively
            isTriggered(ObjectPath.get(triggeredStates, statePath)) ||
            ignoredIds.includes(id)
        )
            return;
        // when is mid tid field
        if (type === midtidKey) {
            const valueId = value.valueId || key;
            validityPath = contextValidityPath ? `${contextValidityPath}.${key}` : key;
            const valuePath = contextPath ? `${contextPath}.${valueId}` : valueId;
            const fieldsObj = getFields(store.getState()) || {};
            formValidity = validateMIDTID({
                field: value,
                validityPath,
                path: valuePath,
                disabledFields,
                fieldsObj,
            });
        }
        // when is mdr field
        else if (type === mdrKey) {
            const valueId = value.valueId || key;
            validityPath = contextValidityPath ? `${contextValidityPath}.${key}` : key;
            const valuePath = contextPath ? `${contextPath}.${valueId}` : valueId;

            formValidity = validateMDR({
                field: value,
                validityPath,
                path: valuePath,
                disabledFields,
            });
        } else if (!isHoTable && isCaptureFieldType(type)) {
            if (disabledFields[id]) return;
            formValidity = validateField({
                field: value,
                path: contextPath,
                validityPath,
                disabledFields,
            });
        }
        // when is HoTable
        else if (isHoTable) {
            if (disabledFields[id]) return;
            validityPath = contextValidityPath ? `${contextValidityPath}.${key}` : key;
            formValidity = validateHOTable({
                hot: {
                    ...value,
                    fields: grids[0].fields,
                },
                id: validityPath,
            });
        }
        // when is DuplicableFieldset
        else if (set.length) {
            const valueId = value.valueId || key;
            validityPath = contextValidityPath ? `${contextValidityPath}.${key}` : key;
            const valuePath = contextPath ? `${contextPath}.${valueId}` : valueId;
            const setValue = ObjectPath.get(valueObj, valuePath, []);

            // when value is more than the set number.
            // this happen when tab is not rendered or loaded in UI
            // tab/set number will increase only when user view the tab
            if (setValue.length <= set.length) {
                formValidity = validateDuplicateBySet({
                    tabsId,
                    tabIndex,
                    set,
                    validityPath,
                    valuePath,
                    ignoredIds,
                });
            } else {
                formValidity = validateDuplicateByValue({
                    tabsId,
                    tabIndex,
                    value: setValue,
                    set,
                    validityPath,
                    valuePath,
                    ignoredIds,
                });
            }
        }
        // when is not above, loop
        else {
            formValidity = validateForm({
                tabsId,
                tabIndex,
                form: value,
                path,
                validityPath,
                ignoredIds,
            }).validities;
        }

        const { isValid, errorMessage, rowErrors, cellErrors, tableErrorMessage } = formValidity;

        validity.isValid &= isValid;
        if (!validity.errorMessage && errorMessage) validity.errorMessage = errorMessage;
        if (rowErrors && rowErrors.length) {
            ObjectPath.set(validity.validities, `${validityPath}`, rowErrors);
        }
        if (cellErrors && cellErrors.length) {
            ObjectPath.set(validity.validities, `${validityPath}`, cellErrors);
        }
        if (tableErrorMessage) {
            ObjectPath.set(validity.validities, `${validityPath}`, tableErrorMessage);
        }
        delete formValidity.isValid;
        delete formValidity.errorMessage;
        delete formValidity.rowErrors;
        delete formValidity.cellErrors;
        delete formValidity.tableErrorMessage;

        validity = mergeValidityWithPath({
            formObject: value,
            path: validityPath,
            validity,
            formValidity,
        });
    });

    return validity;

    function validateField({ field, path, validityPath }) {
        let { id, valueId } = field;
        const reduxTrigger = ObjectPath.get(triggerObj, id, {}).data;
        const { trigger = reduxTrigger, extend = {} } = field;
        valueId = valueId || id;
        const valuePath = path ? `${path}.${valueId}` : valueId;
        validityPath = validityPath ? `${validityPath}.${id}` : id;

        // set default value that will be returned without going through validateInput
        const validity = {};
        ObjectPath.set(validity, validityPath, { isValid: true, errorMessage: '' });

        replaceValidation({
            extendingProps: extend,
            validatorObj,
            id: valueId,
            path: valuePath,
        });

        const triggered = ObjectPath.get(triggeredStates, validityPath, null);
        const value = ObjectPath.get(valueObj, valuePath, null);
        // check triggered state first, to skip unnessary run of getTriggeresValidity method
        if (triggered) {
            return validity;
        }
        if (
            _.isNull(triggered) &&
            trigger &&
            !getTriggersValidity({ trigger, path, value }).isValid
        ) {
            return validity;
        }
        const fieldValidity = validateInput({
            id,
            validators: validatorObj[id],
            value,
        });
        ObjectPath.set(validity, validityPath, fieldValidity);
        return validity;
    }

    function validateMDR({ field, path, disabledFields }) {
        const { id } = field;
        const validity = [];
        const value = ObjectPath.get(valueObj, path, [{}]) || [{}];
        const standardMDRs = valueObj[standardMDRsKey] || [];
        const isEditableObject = convertDisabledState(disabledFields[id]);
        const productsKeyMapObject = Selector.getLists(store.getState())[productsKey].reduce(
            (a, c) => ({ ...a, [c.value]: c }),
            {},
        );

        // loop value and check each product.mdrs availablity + isEditable
        standardMDRs.forEach(({ product }) => {
            const v = value.find(v => v.product === product) || {};
            const productMdrs = productsKeyMapObject[product].mdrs || [];
            const productValidity = {};
            // { payment_channel: false, ... }
            const productisEditable = isEditableObject[product] || {};
            Object.entries(productisEditable).forEach(([id, isEditable]) => {
                const isListedInConfigToShow = productMdrs.includes(id);
                if (!isEditable || !isListedInConfigToShow) {
                    productValidity[id] = {
                        isValid: true,
                    };
                    return;
                }

                // validate field
                const fieldValidity = validateInput({ id, value: v[id] });
                productValidity[id] = fieldValidity;
            });
            productValidity.product = product;
            validity.push(productValidity);
        });

        return {
            [id]: validity,
        };
    }

    function validateMIDTID({ field, path, disabledFields, fieldsObj }) {
        const { id } = field;
        const midtid = ['mid', 'tid'];
        const validity = [];
        const value = ObjectPath.get(valueObj, path, [{}]) || [{}];

        if (disabledFields[id]) {
            return {
                [id]: validity,
            };
        }

        value.forEach(({ product }) => {
            const v = value.find(v => v.product === product) || {};
            // { mid: { isValid, errorMessage}, tid: { isValid, errorMesssage } }
            const productValidity = {};
            // { visa_mastercard: false, ... } or { visa_mastercard: { mid: false, tid: false }, ... }
            const productIsEditable =
                !_.isObject(disabledFields[product]) && !disabledFields[product];

            if (_.isObject(disabledFields[product])) {
                midtid.forEach(id => {
                    const value = v[id];

                    if (disabledFields[v][id]) {
                        productValidity[id] = {
                            isValid: true,
                            errorMessage: '',
                        };
                        return;
                    }

                    // validate field
                    const fieldValidity = validateInput({ id, value });
                    productValidity[id] = fieldValidity;
                });
            } else if (productIsEditable) {
                midtid.forEach(id => {
                    const value = v[id];
                    const field = fieldsObj[id] || {}; // mid or tid
                    const customProps = field.custom_properties || {};
                    const extendingProps = customProps.extend || {};

                    // mid tid replace validity check
                    replaceValidation({
                        extendingProps,
                        validatorObj,
                        id,
                        path,
                    });
                    // validate field
                    const fieldValidity = validateInput({ id, value });
                    productValidity[id] = fieldValidity;
                });
            } else {
                // is not object and not editable
                midtid.forEach(id => {
                    productValidity[id] = {
                        isValid: true,
                        errorMessage: '',
                    };
                });
            }
            productValidity.product = product;
            validity.push(productValidity);
        });

        return {
            [id]: validity,
        };
    }

    function validateHOTable({ id, hot }) {
        const validity = {
            isValid: true,
        };
        let errorMessage = '';
        const rowErrors = [];
        const cellErrors = [];
        const valueId = hot.valueId || id;
        const value = ObjectPath.get(valueObj, valueId, [{}]);

        value.forEach((row, rowIndex) => {
            let rowIsValid = true;
            cellErrors[rowIndex] = [];
            hot.fields.forEach((field, columnIndex) => {
                const key = field.id;
                const { trigger } = field;
                let value = row[key];
                const isCellTriggerValid = getTriggersValidity({
                    trigger,
                    value,
                }).isValid;
                const { isEditable: _isEditable, extend = {} } = field;
                let { isEditable = _isEditable } = extend;
                isEditable = isEditable || true;

                let cellValidity = { isValid: true };

                if (!_.isUndefined(disabledFields[key]) && disabledFields[key]) {
                    isEditable = false;
                }

                // isEditable with given property
                if (_.isObject(isEditable)) {
                    const { by } = isEditable;
                    const validity = getTriggersValidity({
                        trigger: isEditable,
                        value: row[by],
                    });

                    if (!validity.isValid) {
                        isEditable = false;
                    }
                }

                replaceValidation({
                    extendingProps: extend,
                    hotParentId: valueId,
                    hotRowIndex: rowIndex,
                });

                if (isEditable && isCellTriggerValid) {
                    // format to string
                    if (value || value === 0) value = value.toString();
                    cellValidity = validateInput({ id: key, value });
                }
                if (!cellValidity.isValid) {
                    rowIsValid = false;
                    cellErrors[rowIndex].push(columnIndex);

                    if (!errorMessage) errorMessage = cellValidity.errorMessage;
                }
            });
            if (!rowIsValid) {
                rowErrors.push(rowIndex);
                validity.isValid &= false;
            }
        });

        if (rowErrors.length) validity.isValid = false;
        if (errorMessage) validity.tableErrorMessage = errorMessage;
        validity.cellErrors = cellErrors;
        validity.rowErrors = rowErrors;

        const tableValidity = {};
        ObjectPath.set(tableValidity, id, validity);
        return { ...tableValidity };
    }
}

/**
 * A method to validate a duplicable fieldset.
 *   FieldsetObject: { type: 'fieldset', set: [<FieldsetObject>] }
 *   Validity: { isValid, errorMessage }
 * @returns {Object} - { set_id: [{ field_id: Validity }, {...}] }
 *  e.g. { principals: [{ from_year: Validity }, { from_year: Validity }] }
 */
function validateDuplicateBySet({ valuePath, set, validityPath, tabsId, tabIndex, ignoredIds }) {
    const validities = [];

    set.forEach((tabFieldset, index) => {
        // If tabsId is set and equal
        if (tabsId === valuePath) {
            if (tabIndex !== index) return; // stop validate unrelated tab
        }

        const tabValidity = validateForm({
            form: tabFieldset,
            path: `${valuePath}.${index}`,
            validityPath: `${validityPath}.${index}`,
            ignoredIds,
        });
        const tabValidities = tabValidity.validities;
        // [{ field_id: Validity, field_id2: Validity }]
        const gotValidities = ObjectPath.get(tabValidities, validityPath) || {};
        // resulting { field_id: Validity, field_id2: Validity }
        const currentTabValidities = gotValidities[index] || {};

        // append current tab validities
        validities.push(currentTabValidities);
    });
    // e.g. {  principals: [{ from_year: Validity }]  }
    return { [validityPath]: validities };
}

/**
 * A method to validate a duplicable fieldset by value.
 *   FieldsetObject: { type: 'fieldset', set: [<FieldsetObject>] }
 *   Validity: { isValid, errorMessage }
 * @returns {Object} - { set_id: [{ field_id: Validity }, {...}] }
 *  e.g. { principals: [{ from_year: Validity }, { from_year: Validity }] }
 */
export function validateDuplicateByValue({
    valuePath,
    set,
    value,
    validityPath,
    tabsId,
    tabIndex,
    ignoredIds,
}) {
    const validities = [];
    const _isUndefined = _.isUndefined;
    value.forEach((_, index) => {
        // If tabsId is set and equal
        if (!_isUndefined(tabsId) && tabsId === valuePath) {
            if (tabIndex !== index) return; // stop validate unrelated tab
        }
        // get the first set fieldset object
        const tabFieldset = set[0];
        const tabValidity = validateForm({
            form: tabFieldset,
            path: `${valuePath}.${index}`,
            validityPath: `${validityPath}.${index}`,
            ignoredIds,
        });
        const tabValidities = tabValidity.validities;
        // [{ field_id: Validity, field_id2: Validity }]
        const gotValidities = ObjectPath.get(tabValidities, validityPath) || {};
        // resulting { field_id: Validity, field_id2: Validity }
        const currentTabValidities = gotValidities[index] || {};
        // append current tab validities
        validities.push(currentTabValidities);
    });
    // e.g. {  principals: [{ from_year: Validity }]  }
    return { [validityPath]: validities };
}

/**
 * A method to process validity of a form object.
 *   Validity will be deep assign into validity object,
 *   which merge all validiities of the form into one object.
 *
 *   FieldsetObject: { type: 'fieldset', set: [<FieldsetObject>] }
 *   Validity: { isValid, errorMessage }
 *   Validities: { field_id: Validity, ... }
 * @param {Object} - {
 *    formObject: {FormObject} - any form object,
 *    path: {string} - Path to the field. e.g parent.child.field_1,
 *           which will be used to merge the fromValidity,
 *    validity: {Object} - Object with validities e.g. { validities },
 *    formValidity: {Object} - Object of field ids to validity e.g. { field_id: Validity }
 * }
 * @returns {Object} - { validities: Validities  }
 */
export function mergeValidityWithPath({ formObject = {}, path = '', validity = {}, formValidity }) {
    const { set = [], type } = formObject;
    // keys is the ids of the field inside formValidity
    const keys = Object.keys(formValidity);

    if (set.length || type === midtidKey || type === mdrKey) {
        keys.forEach(key => {
            const fieldValidity = formValidity[key];
            const fieldPath = path.indexOf('.') > -1 ? path : key;
            const splitedPaths = fieldPath.split('.');

            let joinedKeys = '';
            // To check either the given path is fully connected
            //    till the last child is accessible
            // e.g. parent.0.child_1.0.child_1_0,
            // To confirm path to access child_1_0 validity is built
            splitedPaths.forEach((splitedPath, i) => {
                // slowly join keys back to a path
                // e.g. a -> a.0 -> a.0.b -> a.0.b.0 ....
                if (!joinedKeys) joinedKeys += splitedPath;
                else joinedKeys += `.${splitedPath}`;

                const pathValue = ObjectPath.get(validity.validities, joinedKeys, null);

                // if found path that's in number format,
                //   set it as an array instead of object
                if (!pathValue) {
                    if (parseInt(splitedPaths[i + 1]) > -1)
                        ObjectPath.set(validity.validities, joinedKeys, []);
                    else ObjectPath.set(validity.validities, joinedKeys, {});
                }
            });
            // merge mid tid field into validity
            ObjectPath.set(validity.validities, path, fieldValidity);
        });
    } else {
        DeepAssign(validity.validities, formValidity);
    }
    return validity;
}

/**
 * This method clear the value inside the form object.
 *   Array type of value will become empty array
 *   Object type of valu will become empty object
 *   Other will become null
 * @param {Object} form - { data: Object, form: Object, force: boolean }
 * @returns {Object} clearedValue
 */
export function clearFormValue(form) {
    let clone = {};

    if (_.isArray(form)) clone = [];

    ObjectEntries(form).forEach(formObj => {
        const [key, value] = formObj;

        if (!IsObject(value)) {
            clone[key] = value;
            return;
        }

        switch (value.type) {
            case labelKey:
                break;
            default:
                if (isCaptureFieldType(value.type)) {
                    clone[key] = clearValue(value);
                    return;
                }
        }

        const returned = clearFormValue(value);
        clone[key] = returned;
    });

    return clone;

    function clearValue(field) {
        const cloneField = ObjectClone(field);
        const value = cloneField.value;

        if (_.isArray(value)) cloneField.value = [];
        else if (IsObject(value)) cloneField.value = {};
        else cloneField.value = undefined;

        return cloneField;
    }
}

/***
 * Form value inserting, by form, view, fieldset, or grid
 * @param {Object} object - { data: Object, form: Object, force: boolean }
 * @returns {Object} insertedValue
 */
export function insertValueIntoForm({ data = {}, form, force }) {
    let clone = {};

    if (_.isArray(form)) clone = [];

    ObjectEntries(form).forEach(formObj => {
        const [key, value] = formObj;

        if (!IsObject(value)) {
            clone[key] = value;
            return;
        }

        switch (value.type) {
            case labelKey:
                break;
            case fieldsetKey:
                if (value.replicate) {
                    clone = [...clone, ...generateReplicate({ data, fieldset: formObj, force })];
                    return;
                } else if (_.isArray(value.set)) {
                    clone = {
                        ...clone,
                        [key]: {
                            ...value,
                            set: generateDuplicate({ data: data[key], set: value.set, force }),
                        },
                    };
                    return;
                }
            default:
                if (isCaptureFieldType(value.type)) {
                    clone[key] = insertValue({ data: data[value.id], field: value, force });
                    return;
                }
        }

        const returned = insertValueIntoForm({ data, form: value, force });

        if (_.isArray(form)) {
            clone.push(returned);
        } else {
            clone[key] = returned;
        }
    });

    return clone;

    function insertValue({ data = '', field, force }) {
        const removeValidators = false;
        const cloneField = ObjectClone(field);
        const { type } = field;
        const value = data;

        if (!type || !value) return cloneField;
        if (removeValidators) delete cloneField.validators;

        // All fields now used value property for it's value
        //   previously radio, select, and checkbox use different
        //   property
        cloneField.value = force ? value : cloneField.value || value;

        return cloneField;
    }

    function generateReplicate({ data = '', fieldset, force }) {
        const [key, value] = fieldset;
        const { replicate } = value;
        const { breakline = true } = replicate;
        const replicateNum = replicate.max || 0;
        const newFieldsets = [];
        const currentSetData = data[value.id] || [];
        const dataLength = currentSetData.length;

        // If there's no data for the replicate set, return original set,
        // which quantity of replicated is none, with total of 1 set returned
        if (!dataLength) return [value];

        /**
         * data[key] return set object with key and value in array [{ key: value }, { key: value}]
         */
        ObjectEntries(currentSetData).forEach((fsObj, index) => {
            let newFieldset = {};
            const [, currentData] = fsObj;

            if (index !== 0) {
                if (breakline)
                    newFieldsets.push({
                        type: fieldsetKey,
                        grids: [
                            {
                                type: gridKey,
                                fields: [
                                    {
                                        type: breakLineKey,
                                    },
                                ],
                            },
                        ],
                    }); // add break line

                newFieldset = {
                    replicateOf: key,
                };
            } else {
                newFieldset = {
                    isReplicateBase: true,
                };
            }
            newFieldset = {
                replicateId: index,
                ...newFieldset,
                // insert value into replicate fieldset with it's own data
                // according to sequence in the data[key] array
                ...insertValueIntoForm({ data: currentData, form: value, force }),
            };

            // there's max limit
            if (replicateNum) {
                const max = replicateNum - index - 1;
                if (max) newFieldset.replicate.max = max;

                delete newFieldset.replicate;
                // if not max last set
                if (replicateNum - 1 > index) {
                    // if not last
                    if (dataLength - 1 !== index) delete newFieldset.replicate;
                } else {
                    if (!max) delete newFieldset.replicate;
                }
            } else {
                // if not max last set
                if (replicateNum - 1 > index) {
                    delete newFieldset.replicate;
                }
            }
            newFieldsets.push(newFieldset);
        });

        return newFieldsets;
    }

    function generateDuplicate({ data = '', set = [], force }) {
        if (!_.isArray(set)) return;
        const cloneSet = [];
        let currentSetData;

        set.forEach((subset, index) => {
            currentSetData = data[index];
            const subsetWithValue = insertValueIntoForm({
                data: currentSetData,
                form: subset,
                force,
            });
            cloneSet.push(subsetWithValue);
        });

        return cloneSet;
    }
}

/**
 * Trigger validity {isValid: boolean}
 * @param {Object} object - { trigger: Object, path: string, value: any }
 * @returns {Object} { isValid, value }
 */
export function getTriggersValidity({ trigger = {}, path, value }) {
    const form = Selector.getForm(store.getState());
    let values = Selector.getValues(store.getState());
    const triggeredStates = Selector.getTriggeredStates(store.getState());
    const validities = Selector.getValidities(store.getState());

    if (!trigger || _.isEmpty(trigger)) return { isValid: true };
    let {
        by = '',
        byFn,
        when = {},
        method = 'show',
        not,
        and,
        thisLevel,
        wholeNumber,
        isSetLength,
    } = trigger;

    let validity = { isValid: true, errorMessage: '' };
    by = path && !_.isArray(by) ? `${path}.${by}` : by;
    if (_.isArray(by) && thisLevel) {
        // get values at this level
        values = {
            ...values, // root
            ...ObjectPath.get(values, path, {}), // this level
        };
    }

    if (byFn) {
        // Call back receives the form state.
        validity.isValid &= !!byFn(form);
        return validity;
    }

    let gotValue;

    if (method === 'mdr_rsh') {
        // Show Approve if true, show recommend if false
        let rshValidity = jsonLogic.apply(rshRules, values);

        if (not) {
            rshValidity = !rshValidity;
        }

        validity.isValid = rshValidity;
        return validity;
    } else if (method === 'mdr_mph') {
        // Show Approve if true, show recommend if false
        let mphValidity = jsonLogic.apply(mphRules, values);

        if (not) {
            mphValidity = !mphValidity;
        }

        validity.isValid = mphValidity;
        return validity;
    }

    if (method === 'total') {
        const gotValues = getFieldValues({
            triggeredStates,
            values,
            fieldIds: by,
            isSetLength,
        });
        gotValue = _.reduce(gotValues, (a = 0, v) => parseFloat(a + (parseFloat(v) || 0)));
    } else if (_.isString(method) && method !== 'show' && _.isArray(by)) {
        const valuesArr = getFieldValuesArrObj({
            triggeredStates,
            values,
            fieldIds: by,
        });
        const totalArr = [];

        valuesArr.forEach(values => {
            const vars = {};
            let total = 0;
            by.forEach(id => {
                const isValid = true;
                // to run trigger[field_id].when
                // if there is
                if (trigger[id]) {
                    const isValid = getTriggersValidity({
                        trigger: {
                            by: id,
                            when: trigger[id].when,
                        },
                        value: values[id],
                    }).isValid;

                    if (isValid) vars[id] = 1;
                    else vars[id] = values[id];
                } else {
                    if (isValid) vars[id] = values[id];
                    else vars[id] = 0;
                }
            });

            try {
                total = calc.compile(method).calc(vars);
                if (wholeNumber) {
                    total = Math.ceil(total);
                }
            } catch (e) {
                if (e instanceof Calc.SyntaxError) {
                    console.log(e);
                } else {
                    throw e;
                }
            }

            totalArr.push(total);
        });
        gotValue = _.reduce(totalArr, (a, v) => a + v);
    } else {
        // if field.by is triggered, don't get value
        if (!isTriggered(triggeredStates[by])) gotValue = value || ObjectPath.get(values, by, '');
    }

    if (_.isArray(by) && method === 'show') {
        let isValid = false;
        if (and) isValid = true;
        const gotValues = [];

        by.forEach(fieldId => {
            gotValue = null;
            // don't get value if is hidden
            if (!isTriggered(triggeredStates[fieldId]))
                gotValue = ObjectPath.get(values, fieldId, '');
            // for values that's in a sets, this will get [value, value]
            if (!gotValue) {
                gotValue = getFieldValues({ triggeredStates, values, fieldIds: fieldId });
            }
            gotValues.push(gotValue);
            // To retieve trigger.fieldId.when, if there is
            if (trigger[fieldId]) {
                when = trigger[fieldId].when;
            }

            const validitiesIsValid = validities[fieldId] ? validities[fieldId].isValid : null;

            const currentIsValid = getValidity(when, gotValue, validitiesIsValid).isValid;

            // If and = true, run AND condition.
            if (!and) {
                isValid |= currentIsValid;
            } else {
                isValid &= currentIsValid;
            }
        });

        if (not) {
            validity.isValid = !isValid;
        } else {
            validity.isValid = isValid;
        }

        validity.value = gotValues;
        return validity;
    }
    validity = getValidity(when, gotValue);

    if (not) {
        validity.isValid = !validity.isValid;
    }
    return validity;

    /**
     * Function that return value of field ids.
     * @param {Object} object - { triggeredStates, values, fieldIds, isSetLength }
     * @returns {any[]} [fieldId1_value, fieldId2_value, ...]
     */
    function getFieldValues({ triggeredStates, values, fieldIds, isSetLength = false }) {
        const gotValues = [];

        loop(values);
        return gotValues;
        function loop(obj) {
            if (!obj || !_.isObject(obj)) return;

            Object.entries(obj).forEach(child => {
                const [id, value] = child;
                let isFieldId;
                const show = !isTriggered(triggeredStates[id]);
                if (!show) return;

                // to handle more than one field ids
                if (_.isArray(fieldIds)) {
                    isFieldId = fieldIds.indexOf(id) > -1;
                } else {
                    isFieldId = id === fieldIds;
                }

                if (!isFieldId) {
                    loop(value);
                    return;
                } else if (isSetLength) {
                    gotValues.push(value ? value.length : 0);
                } else {
                    gotValues.push(value);
                }
            });
        }
    }

    function getFieldValuesArrObj({ triggeredStates, values, fieldIds }) {
        const valuesByField = {};
        const gotArrayFieldsValues = [];
        const numbers = [];
        let max = 0;

        // convert [fieldId1_value, fieldId2_value, ...] to
        // { fieldId1: [value1, value2], fieldId2: [value1, ...] }
        fieldIds.forEach(id => {
            const extractedValues = getFieldValues({
                triggeredStates,
                values,
                fieldIds: id,
            });
            valuesByField[id] = extractedValues;
            numbers.push(extractedValues.length);
        });

        max = _.max(numbers);

        // extending out value array till max length
        let index = 0;
        while (index < max) {
            const fieldsValueObj = {};
            fieldIds.forEach(id => {
                fieldsValueObj[id] = valuesByField[id][index];
            });
            gotArrayFieldsValues.push(fieldsValueObj);
            index++;
        }

        return gotArrayFieldsValues;
    }

    function verifyValueIsNotNull(value) {
        let valid;
        if (_.isArray(value)) valid = ObjectClean(value).length > 0;
        else if (value === 0) valid = true;
        // handle 0
        else valid = !!value;
        return valid;
    }

    function getValidity(
        {
            valueIs,
            valueMoreThan,
            valueLessThan,
            valueHas,
            valueIsNull,
            valueIsNotNull,
            valueIsValid,
            not,
        },
        value,
        validitiesIsValid,
    ) {
        const validity = { isValid: true, errorMessage: '', value };

        if (valueIs) {
            validity.isValid &= !!(valueIs === value);
        }
        if (valueMoreThan) {
            valueMoreThan = parseFloat(valueMoreThan);
            value = parseFloat(value);
            validity.isValid &= !!(valueMoreThan < value);
        }
        if (valueLessThan) {
            valueLessThan = parseFloat(valueLessThan);
            value = parseFloat(value);
            validity.isValid &= !!(valueLessThan > value);
        }
        if (valueHas && valueHas.includes(',')) {
            // for string, before this support [] only
            if (!_.isArray(gotValue)) gotValue = [gotValue];
            validity.isValid &=
                value &&
                !_.isEmpty(
                    _.intersection(
                        valueHas.split(',').map(item => item.trim()),
                        gotValue,
                    ),
                );
        } else if (valueHas && !valueHas.includes(',')) {
            if (_.isNumber(gotValue)) gotValue = String(gotValue);
            if (!gotValue) gotValue = '';
            validity.isValid &= value && !!(gotValue.indexOf(valueHas) > -1);
        }
        if (valueIsNotNull) {
            validity.isValid &= verifyValueIsNotNull(value);
        }
        if (valueIsNull) {
            validity.isValid &= !verifyValueIsNotNull(value);
        }
        if (valueIsValid) {
            validity.isValid &= validitiesIsValid;
        }
        if (not) {
            validity.isValid = !validity.isValid;
        }
        return validity;
    }
}

export function isCaptureFieldType(type) {
    switch (type) {
        case textKey:
        case selectKey:
        case checkboxKey:
        case radioKey:
        case buttonKey:
        case uploadButtonKey:
        case labelKey:
        case labelValueKey:
        case calendarKey.default:
        case remarksKey:
        case textAreaKey:
        case hoTableKey:
        case signatoryKey:
        case acceptanceKey:
        case gpsKey:
        case dateTimeRangeKey:
        case midtidKey:
        case productTypesKey:
        case mdrKey:
        case terminalConfigurationKey:
        case riskScoringSummaryKey:
        case creditScoringSummaryKey:
            return true;
    }
    return false;
}

export function isTriggered(triggered) {
    // to handle mdr triggered
    if (_.isObject(triggered)) {
        return null;
    } else if (_.isBoolean(triggered)) return triggered;
    else return null;
}

/**
 * Private functions
 */

/*
 * A method to convert field's truth state of disabled -> isEditable
 *   from an Object or Object[].
 *   Currently this is being used to convert and return mdr field's
 *   isEditable state, which is in object or array format.
 *   mdr: [{ off_us_credit: true, off_us_credit: false }]
 * e.g [] -> isEditable = false
 * @param {Object} disabledField - Object with field_id as the key and value of an array or object
 * @returns {Object|Object[]} isEditableState
 */
export function convertDisabledState(disabledField = {}) {
    let isEditableState = {},
        isArrayDisabledField;
    if (_.isArray(disabledField)) {
        isArrayDisabledField = true;
        isEditableState = [];
    }

    Object.entries(disabledField).forEach(([key, disabled]) => {
        if (isArrayDisabledField) {
            isEditableState.push(!disabled);
        } else if (_.isObject(disabled)) {
            isEditableState[key] = convertDisabledState(disabled);
        } else {
            isEditableState[key] = !disabled;
        }
    });

    return isEditableState;
}

export function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
