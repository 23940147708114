import BaseAPI from '../BaseAPI';

class SectionAPI extends BaseAPI {
    basePath = 'cap/templates/';

    // get specific fieldset object
    async getFieldset(templateId, sectionId) {
        const { section } = await this.get({
            path: `${ this.basePath }${ templateId }/sections/${ sectionId }/`,
        });
        return section;
    }

    // update specific fieldset object
    async updateSection(templateId, sectionId, section) {
        const response = await this.post({
            path: `${ this.basePath }${ templateId }/sections/${ sectionId }/`,
            data: section,
        });
        return response;
    }

    async getViewSections(templateId, sectionId) {
        const { section } = await this.get({
            path: `${ this.basePath }${ templateId }/sections/${ sectionId }/`,
        });
        return section;
    }

    async getTemplateSections(templateId) {
        const { sections } = await this.get({
            path: `${ this.basePath }${ templateId }/sections/`,
        });
        return sections;
    }

    async getFields(templateId, sectionId) {
        const { section } = await this.get({
            path: `${ this.basePath }${ templateId }/sections/${ sectionId }/`,
        });
        return section;
    }
}

export const sectionAPI = new SectionAPI();
