import _ from 'underscore';
import moment from 'moment-timezone';
import Ajv from 'ajv';
import { printf } from 'fast-printf';
import { validateCalendarInput } from '../Form/Calendar/misc';
import { sampleDatetime, getClientDatetimeFormats } from '../../datetime';
import { DEFAULT_FORMAT } from './datetime';
import { isRequiredKey, isAcceptedKey, isAgreementGeneratedKey } from '../Form/FormRules';

// ajv setup
let ajv = new Ajv({ allErrors: true, jsonPointers: true });

/** if pattern is null, use logic to do validation */
const customValidators = {
    isRequired: (schema, data) => {
        let valid = true;

        if (
            (_.isObject(data) && Object.keys(data).length === 0) ||
            (_.isArray(data) && data.length === 0) ||
            data.length === 0
        )
            valid = false;

        return valid;
    },
    isMoreThan: (schema, data) => {
        let valid = true;
        const convertedData = Number(data);

        if (convertedData <= schema) valid = false;

        return valid;
    },
    isLessThan: (schema, data) => {
        let valid = true;
        const convertedData = Number(data);

        if (convertedData >= schema) valid = false;

        return valid;
    },
    isMax: (schema, data) => {
        let valid = true;
        const convertedData = Number(data);

        if (convertedData > schema) valid = false;

        return valid;
    },
    isMin: (schema, data) => {
        let valid = true;
        const convertedData = Number(data);

        if (convertedData < schema) valid = false;

        return valid;
    },
    isEquals: (schema, data) => {
        return String(data) === String(schema);
    },
    isEqualsIgnoreCase: (schema, data) => {
        return String(data).toLowerCase() === String(schema).toLowerCase();
    },
    isSize: (schema, data) => {
        return _.isArray(data) && data.length === schema;
    },
    isIn: (schema, data) => {
        return _.isArray(data) && data.includes(schema);
    },
    startsWith: (schema, data) => {
        return String(data).startsWith(String(schema));
    },
    endsWith: (schema, data) => {
        return String(data).endsWith(String(schema));
    },
    startsWithAny: (schema, data) => {
        if (!data) return true;
        let any = schema.filter(num => data.startsWith(num));
        if (Array.isArray(any) && !any.length) {
            any = false;
        }
        return String(data).startsWith(String(any));
    },
    endsWithAny: (schema, data) => {
        if (!data) return true;
        let any = schema.filter(num => data.startsWith(num));
        if (Array.isArray(any) && !any.length) {
            any = false;
        }
        return String(data).endsWith(String(any));
    },
    isTextDate: (schema, data) => {
        if (!data) return true;

        const { isValid } = validateCalendarInput({
            value: data,
            format: getClientDatetimeFormats()['date'],
            type: 'date',
            strict: true,
        });
        return isValid;
    },
    isDate: (schema, data) => {
        if (!data) return true;

        // Saved value format is in ISO 8601, DEFAULT_FORMAT.date format
        // trim to DATE format length
        data = data.slice(0, DEFAULT_FORMAT.date.length);

        // moment('It is 2012-05-25', 'YYYY-MM-DD').isValid();       // true
        // moment('It is 2012-05-25', 'YYYY-MM-DD', true).isValid(); // false
        const date = moment(data, DEFAULT_FORMAT.date, true);
        return date.isValid();
    },
    isYear: (schema, data) => {
        if (!data) return true;
        data = data.slice(0, DEFAULT_FORMAT.year.length);
        const year = moment(data, DEFAULT_FORMAT.year, true);
        return year.isValid();
    },
    isMonthYear: (schema, data) => {
        if (!data) return true;
        data = data.slice(0, DEFAULT_FORMAT.monthYear.length);
        const monthYear = moment(data, DEFAULT_FORMAT.monthYear, true);
        return monthYear.isValid();
    },
    isAccepted: (schema, data) => {
        if (!data) return false;
        if (data.indexOf('accept') > -1) return true;

        return false;
    },
    isDatetimeInRange: (schema, data = {}) => {
        // Allow both blank.
        if (_.isEmpty(data)) return true;

        let type = 'datetime';
        const from = moment(data.from);
        const to = moment(data.to);
        if (_.isObject(schema)) {
            type = schema.type || type;
        }
        const formatMapping = {
            datetime: x => x,
            date: x => x.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
            year: x => x.year(),
        };

        // Default to datetime if type is unrecognized.
        const formatFn = formatMapping[type] || formatMapping['datetime'];

        // To check if from and to are valid formats
        const fromValidity = moment(from, DEFAULT_FORMAT[type], true).isValid();
        const toValidity = moment(to, DEFAULT_FORMAT[type], true).isValid();

        if (!fromValidity || !toValidity) {
            return false;
        }

        if (formatFn(from) > formatFn(to)) {
            return false;
        }
        return true;
    },
    isAgreementGenerated: (schema, data = {}) => {
        if (!data) return false;
        if (data.length) return true;

        return false;
    },
};

// some default pattern for testing, where when there's no api call for validators retrieval
export const validators = {
    isNumeric: {
        pattern: '^(\\-)?([0-9]+)$',
        error: 'Value should be only numbers.',
    },
    isEmail: {
        pattern:
            "^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+([a-zA-Z]{2,})$",
        error: 'Value should be an email address.',
    },
    isUrl: {
        pattern:
            '^(http://www\\.|https://www\\.|http://|https://)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$',
        error: 'Value should be an URL.',
    },
    isTime: {
        pattern: '^((1[0-2]|0?[1-9]):([0-5][0-9]) ([AaPp][Mm]))$',
        error: 'Value should be time (HH:MM AM/PM).',
    },
    isRegisteredNumber: {
        pattern: '^[0-9]{1,10}\\-[a-zA-Z]$',
        error: 'Value should be only max 10 digits followed by a - and a single alphabet (009999999-X).',
    },
    isValidMCC: {
        pattern:
            '^(5200|5499|5533|5611|5621|5631|5641|5651|5655|5661|5691|5697|5712|5722|5732|5733|5734|5735|5812|5945|5995|5999|7230|7399|7538|7542|8043|8050|8299|8351)$',
        error: 'Please select a valid MCC.',
    },
    isAlpha: {
        pattern: '^[a-zA-Z]+$',
        error: 'Value should be only alphabetic character(s).',
    },
    isAlphaSpace: {
        pattern: '^[a-zA-Z ]+$',
        error: 'Value should be only alphabetic and space character(s).',
    },
    isAlphanumeric: {
        pattern: '^[a-zA-Z0-9]+$',
        error: 'Value should be only alphabetic and number character(s).',
    },
    isAlphanumericWithSpecialChar: {
        pattern: '^[^ ]+$',
        error: 'Value should be only alphabetic, number and special character(s).',
    },
    isAlphanumericSpace: {
        pattern: '^[a-zA-Z0-9 ]*$',
        error: 'Value should be only alphabetic, number and space character(s).',
    },
    isAlphanumericSpaceWithSpecialChar: {
        pattern: '^[a-zA-Z0-9 .,!@$%#^*@+&_:;\\"\\\'\\/\\-)(]*$',
        error: 'Value should be only alphabetic, number, space and special character(s).',
    },
    isDecimal: {
        pattern: '^(\\-)?([0-9]+)\\.([0-9]+)$',
        error: 'Value should be in decimal place(s).',
    },
    isInteger: {
        pattern: '^(\\-)?([0-9]+)$',
        error: 'Value should be only numbers.',
    },
    isLeadingZero: {
        pattern: '^0{%d,}',
        error: 'Value must have %s leading zero.',
    },
    isDecimalPlaceMin: {
        pattern: '^(\\-)?([0-9]+)(\\.[0-9]{%d,})?$',
        error: 'Value should have at least %s decimal place(s).',
    },
    isDecimalPlaceMax: {
        pattern: '^(\\-)?([0-9]+)(\\.[0-9]{0,%d})?$',
        error: 'Value should not exceed %s decimal place(s).',
    },
    isRequired: {
        pattern: null,
        error: 'This is a mandatory field.',
    },
    isLengthMin: {
        pattern: '^[\\s\\S]{%d,}$',
        error: 'Value should be at least %s characters long.',
    },
    isLengthMax: {
        pattern: '^[\\s\\S]{0,%d}$',
        error: 'Value should not exceed %s characters.',
    },
    isLengthExact: {
        pattern: '^[\\s\\S]{%d}$',
        error: 'Value must be %s characters long',
    },
    isMin: {
        pattern: null,
        error: 'Number should be at least %s.',
    },
    isMax: {
        pattern: null,
        error: 'Number should not exceed %s.',
    },
    isLessThan: {
        pattern: null,
        error: 'Number should be less than %s.',
    },
    isMoreThan: {
        pattern: null,
        error: 'Number should be more than %s.',
    },
    isEquals: {
        pattern: null,
        error: 'Value can be %s only',
    },
    isEqualsIgnoreCase: {
        pattern: null,
        error: 'Value can be %s only',
    },
    isSize: {
        pattern: null,
        error: 'Should contain %s items only',
    },
    isIn: {
        pattern: null,
        error: 'Value %s is not found in items',
    },
    startsWith: {
        pattern: null,
        error: 'Value must start with %s',
    },
    endsWith: {
        pattern: null,
        error: 'Value must end with %s',
    },
    startsWithAny: {
        pattern: null,
        error: 'Value must start with %s',
    },
    isTextDate: {
        pattern: null,
        error: 'Please input date as %s format',
    },
    isDate: {
        pattern: null,
        error: 'Please input date as %s format',
    },
    isYear: {
        pattern: null,
        error: 'Please input date as $s format',
    },
    isMonthYear: {
        pattern: null,
        error: 'Please input date as $s format',
    },
    isAccepted: {
        pattern: null,
        error: 'Please scroll to the end, check and accept the terms and conditions before proceeding further',
    },
    isDatetimeInRange: {
        pattern: null,
        error: 'Datetime is outside of range',
    },
    isAgreementGenerated: {
        pattern: null,
        error: 'Please generate agreement',
    },
};

export function addValidatorKeywords(validators) {
    /** add custom keywords to ajv */
    Object.keys(validators).forEach(key => {
        // stop adding same validators, for now same key will have the same validation logic
        if (ajv.RULES.keywords[key]) {
            return;
        }
        ajv.addKeyword(key, {
            validate: function validate(schema, data) {
                let pattern = '';
                let error = '';
                let valid = true;

                /** if pattern contains regex, use regex object for validation */
                if (validators[key].pattern !== null) {
                    if (data.length !== 0) {
                        pattern = printf(validators[key].pattern, schema);
                        error = printf(validators[key].error, schema);

                        const reg = new RegExp(pattern);
                        valid = reg.test(data);
                    }
                } else {
                    /** if pattern is null, use logic to do validation */
                    /** handle schema error message for schema array */
                    let schemaMsg = schema;
                    if (_.isArray(schema)) {
                        const schemaCopy = [...schema];
                        const msgLastValue = schemaCopy.pop();
                        const msgJoinValue = schemaCopy.join(', ');
                        schemaMsg = `${msgJoinValue} or ${msgLastValue}`;
                    }

                    const str = validators[key].error;
                    if (key === 'isDate' || key === 'isTextDate') {
                        const defaultDate = sampleDatetime({ type: 'date' });
                        schemaMsg = defaultDate;
                    }

                    error = printf(str, String(schemaMsg));

                    valid = customValidators[key](schema, data);
                }

                validate.errors = [
                    {
                        keyword: key,
                        message: error,
                        params: {
                            keyword: key,
                        },
                    },
                ];

                return valid;
            },
            errors: true,
        });
    });

    return ajv;
}

let isSetWithDefault = false;
export function getAjvWithDefaultValidators() {
    if (!isSetWithDefault) {
        ajv = addValidatorKeywords(validators);
    }
    isSetWithDefault = true;
    return ajv;
}

export function getIsRequired(schema = {}, id) {
    return (
        schema.properties &&
        schema.properties[id] &&
        (schema.properties[id][isRequiredKey] ||
            schema.properties[id][isAcceptedKey] ||
            schema.properties[id][isAgreementGeneratedKey])
    );
}

export { ajv, customValidators };
