import _ from 'underscore';
import { compose, withHandlers } from 'recompose';
import { REPLACE_VALIDATION_KEY } from '.';

const withExtendingPropsHandler = compose(
    withHandlers({
        generateExtendingProps: props => () => {
            const { extend, tabId, tabIndex, isEditable } = props;
            let extendingProps = {};
            const currentPath = `${ tabId }.${ tabIndex }`;
            if (_.isFunction(extend)) {
                extendingProps = extend(props);
            } else if (_.isObject(extend)) {
                Object.entries(extend).forEach(prop => {
                    let [key, value] = prop;
                    if (_.isFunction(value)) {
                        const currentValue = value(props);
                        extendingProps[key] = currentValue || props[key];
                    } else if (_.isObject(value)) {
                        const path = value.thisLevel ? currentPath : '';
                        const targetValue = value.targetValue;
                        const targetKey = value.targetKey;
                        const invalidValue = value.invalidValue;
                        const method = value.method;
                        let newValue;

                        if (method === 'year') {
                            return; // stop, logic moved to withOnValuesChange
                        }
                        const v = props.getTriggerValidity({
                            trigger: value,
                            path,
                        });

                        // if targetKey is set
                        if (targetKey) key = targetKey;

                        // if targetValue is set
                        if (!_.isUndefined(targetValue)) {
                            if (_.isArray(targetValue)) {
                                if (!_.isArray(extendingProps[key])) extendingProps[key] = [];

                                // # To cater multiple equivalent targetValue
                                // append and concate each targetValue
                                extendingProps[key] = [...extendingProps[key], ...targetValue];
                                if (!v.isValid) {
                                    if (
                                        _.isArray(extendingProps[key]) &&
                                        extendingProps[key].length
                                    ) {
                                        newValue = extendingProps[key];
                                    }
                                    if (!_.isArray(newValue)) newValue = [];
                                    // Remove only when currently is invalid
                                    let found = 0;
                                    extendingProps[key] = newValue.filter(v => {
                                        if (targetValue.indexOf(v) > -1 && !found) {
                                            found = 1;
                                            return false;
                                        }
                                        return true;
                                    });
                                }

                                return;
                            } else {
                                newValue = targetValue;
                            }
                        } else {
                            if (!_.isNull(targetValue)) newValue = v.value;
                            else newValue = null;
                        }

                        if (v.isValid && props[key] !== newValue) {
                            if (key === 'isEditable' && !isEditable) {
                                return;
                            }
                            extendingProps[key] = newValue;
                        } else if (!v.isValid && !extendingProps[key]) {
                            if (key === 'value') {
                                // Reset value
                                if (!_.isUndefined(props.localValue)) {
                                    // with inputted value
                                    extendingProps[key] = props.localValue;
                                    if (!extendingProps[key]) {
                                        extendingProps[key] = props.value;
                                    }
                                } else if (!_.isUndefined(props.value))
                                    // with default value
                                    extendingProps[key] = props.value;
                                else delete extendingProps[key];
                            } else {
                                // Reset value
                                if (!_.isUndefined(invalidValue)) {
                                    // to given invalidValue
                                    extendingProps[key] = invalidValue;
                                    // if the section it self is not editable
                                    if (key === 'isEditable' && !_.isUndefined(isEditable)) {
                                        extendingProps[key] = isEditable;
                                    }
                                } // to false
                                else extendingProps[key] = false;
                            }
                        }
                        if (key === REPLACE_VALIDATION_KEY) {
                            extendingProps[key] = props.extend[REPLACE_VALIDATION_KEY];
                        }
                    }
                });
            }

            return extendingProps;
        },
    }),
);

export default withExtendingPropsHandler;
