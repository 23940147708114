import _ from 'underscore';
import * as FR from '../../Form/FormRules';
/**
 * A method to process props.extend with the given id map value
 *   values object, then return it's parents and extendedValues
 * @param {Object} - { extend, values }
 * {
 *   extend: {Object|Object[]} - e.g. {
 *                                  isEditable: { by: generation_error, when }
 *                                  value: { by: generated_file, when }
 *                               }
 *   values: {Object} - { field_id: value, ...fieldN: valueN }
 * }
 * @returns {Object} object - {
 *   extendedValues: {Object} - object that keeps all the
 *                   value of extend trigger's by's (field id) value
 *                   e.g { generation_error: true, generated_file: [{ uid, name }]  }
 *   parents: {string[]}
 * }
 */
function processExtendedValues({ extend = [], values }) {
    let arr = extend;
    let bys = [];
    let parents = [];
    const extendedValues = {};
    if (_.isObject(extend)) arr = Object.values(extend);

    // get all by and byParent
    arr.forEach(e => {
        if (_.isArray(e.byParent)) {
            parents = [...parents, ...e.byParent];
        } else if (e.byParent) {
            parents.push(e.byParent);
        }
        if (_.isArray(e.by)) {
            bys = [...bys, ...e.by];
        } else if (e.by) {
            bys.push(e.by);
        }
    });

    bys.forEach(by => {
        extendedValues[by] = getFieldValues({ values, fieldIds: [by] });
        // when not more than one is not field id that's found in nested value
        //   getFieldValues will return [field_value], which always in an array
        //   format, function was meant to handle multiple by.
        //   So, if it's size 1, store it straight as value instead of [value]
        if (extendedValues[by].length === 1) {
            extendedValues[by] = extendedValues[by][0];
        }
    });
    return {
        extendedValues,
        parents,
    };
}

export default processExtendedValues;

// private
/**
 * A method that return value of field ids.
 * @param {Object} { triggeredStates, values, fieldIds }
 * @returns {any[]} [fieldId1_value, fieldId2_value, ...]
 */
function getFieldValues({ triggeredStates = {}, values, fieldIds }) {
    const gotValues = [];

    loop(values);
    return gotValues;
    function loop(obj) {
        if (!obj || !_.isObject(obj)) return;

        Object.entries(obj).forEach(child => {
            const [id, value] = child;
            let isFieldId;
            const show = !FR.isTriggered(triggeredStates[id]);
            if (!show) return;

            // to handle more than one field ids
            if (_.isArray(fieldIds)) {
                isFieldId = fieldIds.indexOf(id) > -1;
            } else {
                isFieldId = id === fieldIds;
            }

            if (!isFieldId) {
                loop(value);
                return;
            } else {
                gotValues.push(value);
            }
        });
    }
}
