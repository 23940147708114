export const SET_VALIDITY = 'SET_VALIDITY';
export function setValidity({ path, validity }) {
    return {
        type: SET_VALIDITY,
        payload: {
            path,
            validity,
        },
    };
}

export const SET_VALIDITIES = 'SET_VALIDITIES';
export function setValidities(validities) {
    return {
        type: SET_VALIDITIES,
        validities,
    };
}

export const SET_VIEW_VALIDITIES = 'SET_VIEW_VALIDITIES';
export function setViewValidities(viewValidities: boolean[]) {
    return {
        type: SET_VIEW_VALIDITIES,
        viewValidities,
    };
}

export const SET_VALIDITY_TARGETS = 'SET_VALIDITY_TARGETS';
export function setValidityTargets(validityTargets) {
    return {
        type: SET_VALIDITY_TARGETS,
        validityTargets,
    };
}

export const SET_VALIDITIES_ERROR = 'SET_VALIDITIES_ERROR';
const setValiditiesError = error => ({
    type: SET_VALIDITIES_ERROR,
    error,
});

export function clearValidatorErrors() {
    return setValiditiesError(null);
}
