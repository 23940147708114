import React from 'react';

const Paging = ({ page, total, onPageClick, onPrev, onNext }) => {
    const totalPages = total;
    const { number: pageNumber } = page;
    const pagingMax = 10;
    // Half of the paging max, which will be pivot of number for Paging component. it's contantly 5th now
    const pagingMid = pagingMax / 2;
    // Last possible pivot page number e.g. 24 - 5 + 1 = 20, 20 is the last number that can be pivot
    const pagingPivotLastNumber = totalPages - pagingMid + 1;
    // This decide to show [1,... pivot-1, pivot, pivot+1 .... , N]
    const isInBetweenView = pageNumber >= pagingMid && pageNumber <= pagingPivotLastNumber;
    // This indicate to show [1, 2, 3, ... pivot .... , N ]
    const isBeginningView = pageNumber >= 1 && pageNumber < pagingMid;
    // Array to store the pages number component
    const pages = [];
    const hasPrev = pageNumber > 1;
    const hasNext = pageNumber < totalPages;

    // when both isInBetweenView, isBeginningView false
    // it indicate to show [1, ... pivot .... N-2, N-1, N ]

    // chevron left
    pages.push(
        <div
            key="previous"
            className={hasPrev ? 'page' : 'page disabled'}
            onClick={hasPrev ? onPrev : undefined}>
            <i className="chevron left icon"></i>
        </div>,
    );

    // when number of pages less than or equal to max paging number
    if (totalPages <= pagingMax) {
        for (let i = 0; i < totalPages; i++) {
            const number = i + 1;
            const cls = ['page', number, pageNumber === number ? 'active' : ''];
            pages.push(
                <div
                    key={i}
                    className={cls.join(' ')}
                    onClick={() => {
                        onPageClick(number);
                    }}>
                    {number}
                </div>,
            );
        }
    } // if page.number >= pagingMid && <= totalPage - (pagingMid + 1)
    else if (isInBetweenView) {
        // put first page
        pages.push(
            <div
                key="first-between"
                className="page"
                onClick={() => {
                    onPageClick(1);
                }}>
                {1}
            </div>,
        );

        pages.push(
            <div key="ellipsis1-between" className="page ellipsis disabled">
                ...
            </div>,
        );

        for (let num = pageNumber - 1; num <= pageNumber + 1; num++) {
            const cls = ['page', num, pageNumber === num ? 'active' : ''];
            pages.push(
                <div
                    key={`${ num }-between`}
                    className={cls.join(' ')}
                    onClick={() => {
                        onPageClick(num);
                    }}>
                    {num}
                </div>,
            );
        }

        pages.push(
            <div key="ellipsis2-between" className="page ellipsis disabled">
                ...
            </div>,
        );

        // put last page
        pages.push(
            <div
                key="last-between"
                className="page"
                onClick={() => {
                    onPageClick(totalPages);
                }}>
                {totalPages}
            </div>,
        );
    } else if (isBeginningView) {
        for (let num = 1; num <= pagingMid; num++) {
            const cls = ['page', num, pageNumber === num ? 'active' : ''];
            pages.push(
                <div
                    key={`${ num }-starting`}
                    className={cls.join(' ')}
                    onClick={() => {
                        onPageClick(num);
                    }}>
                    {num}
                </div>,
            );
        }

        // ellipsis
        pages.push(
            <div key="ellipsis-starting" className="page ellipsis disabled">
                ...
            </div>,
        );

        // put last page
        pages.push(
            <div
                key="last-starting"
                className="page"
                onClick={() => {
                    onPageClick(totalPages);
                }}>
                {totalPages}
            </div>,
        );
    } else {
        // put first page
        pages.push(
            <div
                key="first-ending"
                className="page"
                onClick={() => {
                    onPageClick(1);
                }}>
                {1}
            </div>,
        );

        // ellipsis
        pages.push(
            <div key="ellipsis-ending" className="page ellipsis disabled">
                ...
            </div>,
        );

        for (let num = pagingPivotLastNumber; num <= totalPages; num++) {
            const cls = ['page', num, pageNumber === num ? 'active' : ''];
            pages.push(
                <div
                    key={`${ num }-ending`}
                    className={cls.join(' ')}
                    onClick={() => {
                        onPageClick(num);
                    }}>
                    {num}
                </div>,
            );
        }
    }

    // chevron right
    pages.push(
        <div
            key="next"
            className={hasNext ? 'page' : 'page disabled'}
            onClick={hasNext ? onNext : undefined}>
            <i className="chevron right icon"></i>
        </div>,
    );

    return <div className="table-paging">{pages}</div>;
};

export default Paging;
