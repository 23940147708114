import _ from 'underscore';
import { startLoading, stopLoading } from './PageAction';
import { rulesAPI } from '../../api/rule/RuleAPI';
import { HTTP_CODE, STATUS_CODE } from '../../components/Misc/api';
import { hideForm } from '../../components/Misc/forms';
import { showFormOutdated } from '../../components/Misc/forms/popUps';
import SETTINGS from '../../settings';

export const SET_RULES = 'SET_RULES';
export function setRules(rules) {
    return {
        type: SET_RULES,
        rules,
    };
}

export function getRules() {
    return async dispatch => {
        const rules = await rulesAPI.getRules();
        dispatch(setRules(rules));
    };
}

export function callRule({
    assignee,
    ruleId,
    formId,
    templateId,
    relativePath,
    contextId,
    data,
    delta,
    formStatus,
    updatedDate,
    campaignSlug,
    formCampaignSlug,
    index,
    moduleType,
    type,
    cb,
}) {
    const configParams =
        moduleType != null
            ? {
                risk_integrations: [
                    {
                        module: moduleType,
                        type,
                        index: index > -1 ? index : null,
                    },
                ],
            }
            : null;

    // formCampaignSlug comes from form.campaign.slug
    const formattedData = {
        form: {
            data,
            template: templateId,
            id: formId,
            form_status: formStatus,
            updated_dt: updatedDate,
        },
        index: {
            [contextId]: relativePath,
        },
        config_params: configParams,
        rules: ruleId,
        campaign_slug: campaignSlug || formCampaignSlug,
    };
    if (SETTINGS.SEND_DELTA) {
        formattedData.form.delta = delta;
        console.log(delta);
    }
    if (assignee) {
        // key shall not be send through request if it's not defined
        formattedData.assignee_id = assignee;
    }
    return async dispatch => {
        let response;
        let actionCode;
        dispatch(startLoading());
        try {
            response = await rulesAPI.callRule(ruleId[0], formattedData);
        } catch (e) {
            const responseJSON = e.responseJSON || {};
            const { status_cd: statusCode, status_desc: statusDesc } = responseJSON;
            actionCode = responseJSON.action_cd;
            if (e.status === HTTP_CODE.BAD_REQUEST && statusCode === STATUS_CODE.OUTDATED_DATA) {
                dispatch(
                    showFormOutdated({
                        message: statusDesc,
                        onConfirm: () => {
                            dispatch(hideForm());
                        },
                    }),
                );
            } else {
                throw e;
            }
        } finally {
            if (actionCode !== 'A002') {
                dispatch(stopLoading());
            }
            _.isFunction(cb) && cb(response);
        }
        return response;
    };
}

export const SET_RULES_ERROR = 'SET_RULES_ERROR';
const setRulesError = error => ({
    type: SET_RULES_ERROR,
    error,
});

export function clearRuleErrors() {
    return setRulesError(null);
}
