import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export interface Record {
    upload_id: string;
    filename: string;
    status: string;
    creator: string;
    total_records: string;
    remarks: string;
    date_updated: string;
}

export interface Application {
    company_reg_no: string;
    dba_name: string;
    registered_name: string;
    status: string;
    application_id: string;
    remarks: string;
    date_updated: string;
}

export interface BulkUploadState {
    records: Record[];
    applications: Application[];
}

const initialState: BulkUploadState = {
    records: [],
    applications: [],
};

const bulkUploadSlice = createSlice({
    name: 'BulkUploadReducer',
    initialState,
    reducers: {
        setBulkUploadList(state, action: PayloadAction<Record[]>) {
            state.records = action.payload;
        },
        setBulkUploadResult(state, action: PayloadAction<Application[]>) {
            state.applications = action.payload;
        },
    },
});

export const { setBulkUploadList, setBulkUploadResult } = bulkUploadSlice.actions;
export default bulkUploadSlice.reducer;
