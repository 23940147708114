import {
    SET_ERROR,
    SET_SYSTEM_CONFIGURATIONS,
    SET_SYSTEM_CONFIGURATION_VALUES,
    SET_CHANGED_SYSTEM_CONFIGURATION_VALUES,
} from '../actions/SystemConfigurationAction.js';

const INITIAL_STATE = {
    systemConfigs: {},
    systemConfigValues: {},
    changedSystemConfigValues: {},
    error: null,
};

export default function systemConfigurationReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
    case SET_SYSTEM_CONFIGURATIONS: {
        const { systemConfigs } = action;
        return {
            ...state,
            systemConfigs,
        };
    }

    case SET_SYSTEM_CONFIGURATION_VALUES: {
        const { systemConfigValues } = action;
        return {
            ...state,
            systemConfigValues,
        };
    }

    case SET_CHANGED_SYSTEM_CONFIGURATION_VALUES: {
        const { changedSystemConfigValues } = action;
        return {
            ...state,
            changedSystemConfigValues,
        };
    }

    case SET_ERROR: {
        const { error } = action;
        return {
            ...state,
            error,
        };
    }

    default:
        return state;
    }
}
