import BaseAPI from './BaseAPI';
import axios from 'axios';
import { STATUS_CODE, HTTP_CODE, ERROR_MESSAGES } from '../components/Misc/api';
import {
    actionClearSession,
    actionForbiddenAcess,
    actionShowError,
    actionAPIError,
    enforceChangePassword,
} from '../redux/actions/PageAction';

const { headers, host } = new BaseAPI();

/**
 * getHeaders is to concat custom headers with default headers
 * @param {object} headerParams - custom headers passed from other api
 * @returns {object} headersObj
 */
const getHeaders = headerParams => {
    const headersObj = {
        ...headers,
        ...headerParams,
    };
    return headersObj;
};

/**
 * axiosResponseHandler is to return response
 *
 * @param {object} response - response object from api call
 * @returns {object} response
 */
export const axiosResponseHandler = response => {
    const statusCode = response.data.status_cd;

    if (statusCode === STATUS_CODE.USER_FORCE_PASSWORD_CHANGE) {
        enforceChangePassword();
    }

    return {
        ...response,
        ...response.data,
    };
};

/**
 * axiosErrorHandler is to handle errors thrown in axios,
 *
 * @param {object} error - error object from api call
 * @returns {object} { response, responseJSON }
 */
export const axiosErrorHandler = error => {
    const { response } = error;
    if (response[HTTP_CODE.CODEKEY] === HTTP_CODE.UNAUTHORIZED) {
        return actionClearSession();
    } else if (response[HTTP_CODE.CODEKEY] === HTTP_CODE.FORBIDDEN) {
        const { status_cd: statusCode, status_desc } = response.data || {};

        if (statusCode === STATUS_CODE.USER_FORCE_PASSWORD_CHANGE) {
            enforceChangePassword();
        }

        if (statusCode === STATUS_CODE.BAD_REQUEST) {
            actionForbiddenAcess({
                message: status_desc,
            });
        }
    } else if (response[HTTP_CODE.CODEKEY] === HTTP_CODE.SERVER_ERROR) {
        actionShowError({
            message: ERROR_MESSAGES[HTTP_CODE.SERVER_ERROR],
        });
    } else if (response[HTTP_CODE.CODEKEY] === HTTP_CODE.NOT_FOUND) {
        return actionAPIError();
    }
    return { response, responseJSON: response.data };
};

/**
 * axiosBaseAPI is a wrapper for all axios request,
 *
 * @param {string} method - e.g GET, POST, PATCH, DELETE
 * @param {string} path - cap/templates/
 * @param {string} url - http://localhost:8000/cap/templates/
 * @param {object} data - {}
 * @param {object} headerParams - { Content-Type': 'application/json' }
 * @param {string} responseType - blob
 * @returns {AxiousPromise} axiosPromise
 */
const axiosBaseAPI = ({ method, path, url, data, headerParams, responseType }) => {
    if (path) {
        path = host + path;
    }
    const headersObj = getHeaders(headerParams);
    const options = {
        method,
        url: path || url,
        data,
        headers: headersObj,
        responseType,
    };
    return axios(options)
        .then(response => {
            return axiosResponseHandler(response);
        })
        .catch(error => {
            throw axiosErrorHandler(error);
        });
};

export default axiosBaseAPI;
