import BaseAPI from '../BaseAPI';

export class HistoryAPI extends BaseAPI {
    basePath = 'cap/templates/';

    async getHistories({ templateId, formId }) {
        let formAudits;
        try {
            const response = await this.get({
                path: `${ this.basePath }${ templateId }/forms/${ formId }/history/`,
            });
            formAudits = response.form_audits;
        } catch (e) {
            throw Error(e.responseJSON);
        }

        return formAudits;
    }
}
export const historyAPI = new HistoryAPI();
export default historyAPI;
