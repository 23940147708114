import React from 'react';
import {
    compose,
    lifecycle,
    withStateHandlers,
    withPropsOnChange,
    onlyUpdateForKeys,
    withHandlers,
} from 'recompose';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon/Icon';
import _ from 'underscore';
import { NULL_VALUE_TEXT } from '../../Misc/forms';

const SEARCH_TEXT = 'search for more...';
const SEARCH_CHAR_TEXT = '- At least 4 char(s) to search -';
const NONE_TEXT = 'No results found.';
const SEARCH_CHAR = '_search_at_char';
const SEARCH_VALUE = '_search_for_more';
const NONE_VALUE = '_none';

const DropdownItem = props => {
    const { className, option, selectedItem = {}, onClick } = props;
    const { title, value, description } = props.option;
    const classNames = [
        className ? className : '',
        'dropdown-item',
        value,
        selectedItem.value === value && 'selected',
    ];
    if (!value && value !== '') return <div />;
    return (
        <div
            className={classNames.join(' ')}
            onClick={e => {
                e.stopPropagation();
                onClick && onClick(e, { value, option });
            }}>
            {value === '' ? <em>{title}</em> : title}
            {description && <div className="dropdown-item-description">{description}</div>}
        </div>
    );
};

const DropdownItemWrapper = props => {
    const {
        options,
        selectedItem,
        isOptionsSpliced,
        searchAtChar,

        onMouseOver,
        onMouseLeave,
        onTouchStart,
        onTouchCancel,
        onClick,
    } = props;
    const classNames = ['dropdown-item-wrapper'];
    const found = [];
    const uniqs = options.filter(option => {
        const { value } = option;
        if (value && found.indexOf(value) < 0) {
            found.push(value);
        } else {
            return false;
        }
        return true;
    });
    const showSearchAtItem = !!(searchAtChar && uniqs.length);

    return (
        <div
            className={classNames.join(' ')}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            onTouchStart={onTouchStart}
            onTouchCancel={onTouchCancel}>
            {showSearchAtItem && (
                <DropdownItem
                    className="disabled-item small"
                    option={{
                        title: SEARCH_CHAR_TEXT,
                        value: SEARCH_CHAR,
                    }}
                />
            )}
            <DropdownItem option={{ title: 'None', value: '' }} onClick={onClick} />
            {uniqs.map((option, i) => (
                <DropdownItem
                    key={i}
                    option={option}
                    onClick={onClick}
                    selectedItem={selectedItem}
                />
            ))}
            {!uniqs.length && (
                <DropdownItem
                    className="default-item"
                    option={{
                        title: NONE_TEXT,
                        value: NONE_VALUE,
                    }}
                    onClick={(e, data) => {
                        onClick(e, {
                            ...data,
                            mode: NONE_VALUE,
                        });
                    }}
                />
            )}
            {isOptionsSpliced && (
                <DropdownItem
                    className="search-more-item disabled-item small"
                    option={{
                        title: SEARCH_TEXT,
                        value: SEARCH_VALUE,
                    }}
                    onClick={(e, data) => {
                        onClick(e, {
                            ...data,
                            mode: SEARCH_VALUE,
                        });
                    }}
                />
            )}
        </div>
    );
};

const SelectedItem = ({ className, item, onClick }) => {
    const classNames = ['dropdown-selected-wrapper', className ? className : ''];
    const { title } = item;

    if (!title) return <div />;
    return (
        <div className={classNames.join(' ')} onClick={onClick}>
            {item.title}
        </div>
    );
};

const MultipleSelectedItem = ({ item, onRemove }) => {
    const { title, value } = item;
    return (
        <div className="dropdown-multiple-selected">
            {title}
            <Icon
                name="cancel"
                className="remove"
                onClick={e => {
                    onRemove(e, { value, item });
                }}
            />
        </div>
    );
};

const DropdownValue = ({ options, value, showUnavailable, isLoading }) => {
    let filtered = options.filter(option => option.value === value);
    // to set unavailable option value only if value is string
    // NOTE: this changes is required to handle {} that was mistaken
    // set to dropdown field as default value in configuration repo.
    if (!filtered.length && !isLoading && showUnavailable && _.isString(value)) {
        filtered = [{ title: value }];
    }
    return (
        <div className="dropdown-input">
            {(filtered[0] && filtered[0].title) || NULL_VALUE_TEXT}
        </div>
    );
};

const IEDropdown = props => {
    let {
        className,
        position,
        note,
        placeholder,
        isMultiple,
        isEditable,
        value,
        options,

        open,
        selecting,
        searching,
        selectedItem,
        selectedItems,
        filteredOptions,
        // handlers
        setOpen,
        setClose,
        setSelectedValue,
        addSelectedValue,
        setSelecting,
        setSearching,
        removeSelectedValue,
        removeLastValue,
        filterOptions,
        isLoading,
        showUnavailable,

        searchAtChar,
        maxLoad = 2800,
    } = props;
    const classNames = [
        'dropdown-wrapper',
        className,
        position === 'top' ? 'top' : 'bottom',
        isEditable && open ? 'open' : '',
        selecting ? 'selecting' : '',
        !_.isEmpty(note) ? 'note' : '',
        !isEditable ? 'noteditable' : '',
    ];
    let searchInput, noteClass, isOptionsSpliced;
    if (!_.isEmpty(note) && note.position === 'left') {
        noteClass = 'note-left';
    } else if (note) {
        noteClass = 'note-right';
    }

    if (isMultiple) {
        value = value || [];
        filteredOptions = filteredOptions.filter(option => {
            return value.indexOf(option.value) < 0;
        });
    } else {
        if (_.isObject(value)) {
            // console.error('\'value\' should be String or Number only');
        }
    }

    maxLoad = parseInt(maxLoad, 10);
    filteredOptions = [...filteredOptions];
    if (filteredOptions.length > maxLoad) {
        filteredOptions = filteredOptions.splice(0, maxLoad);
        isOptionsSpliced = true;
    }

    return (
        <div
            className={classNames.join(' ')}
            onClick={() => {
                if (isLoading) return;
                setSearching(true);
                searchInput && searchInput.focus();
            }}>
            {isLoading && (
                <div className="loader-container fixed">
                    <div className="loader"></div>
                </div>
            )}
            {isEditable && <Icon name="caret down" className="dropdown-icon" />}
            {isMultiple && (
                <div className={`dropdown-multiple-wrapper ${ noteClass }`}>
                    {selectedItems.length > 0 &&
                        selectedItems.map((item, i) => (
                            <MultipleSelectedItem
                                key={i}
                                item={item}
                                onRemove={(e, { item }) => {
                                    if (isEditable) {
                                        e.stopPropagation();
                                        removeSelectedValue(item.value);
                                    }
                                }}
                            />
                        ))}
                    {isEditable && (
                        <input
                            ref={search => {
                                searchInput = search;
                            }}
                            className="dropdown-input append"
                            onFocus={setOpen}
                            onBlur={() => {
                                setSearching(false);
                                if (!selecting) {
                                    setClose();
                                    if (searchInput) searchInput.value = '';
                                }
                            }}
                            onKeyUp={e => {
                                filterOptions(e.target.value);
                            }}
                            onKeyDown={e => {
                                removeLastValue(e);
                            }}
                        />
                    )}
                </div>
            )}
            {isEditable && !isMultiple && !searching && selectedItem && (
                <SelectedItem item={selectedItem} />
            )}
            {isEditable &&
                !searching &&
                _.isEmpty(selectedItem) &&
                !selectedItems.length &&
                placeholder && (
                <SelectedItem className="placeholder" item={{ title: placeholder }} />
            )}
            {!isMultiple && isEditable && (
                <input
                    ref={search => {
                        searchInput = search;
                    }}
                    className={`dropdown-input ${ noteClass }`}
                    onFocus={setOpen}
                    onBlur={() => {
                        if (!selecting) {
                            setClose();
                            searchInput.value = '';
                            filterOptions('');
                        }
                    }}
                    onKeyUp={e => {
                        filterOptions(e.target.value);
                    }}
                />
            )}
            {!isMultiple && !isEditable && (
                <DropdownValue
                    options={options}
                    value={value}
                    isLoading={isLoading}
                    showUnavailable={showUnavailable}
                />
            )}
            {isEditable && open && (
                <DropdownItemWrapper
                    options={filteredOptions}
                    onClick={(e, data) => {
                        e.stopPropagation();
                        if (data.mode === SEARCH_VALUE || data.mode === SEARCH_CHAR) {
                            setSearching(true);
                            searchInput.focus();
                            return;
                        } else if (data.mode === NONE_VALUE) {
                            filterOptions('');
                            setClose();
                            return;
                        }
                        if (isMultiple) {
                            searchInput && searchInput.focus();
                            addSelectedValue(data.option.value);
                        } else {
                            setSelectedValue(data.option.value);
                            searchInput.value = '';
                            filterOptions('');
                            setClose();
                        }
                    }}
                    onMouseOver={() => setSelecting(true)}
                    onMouseLeave={() => setSelecting(false)}
                    onTouchStart={() => setSelecting(true)}
                    onTouchCancel={() => setSelecting(false)}
                    selectedItem={selectedItem}
                    isOptionsSpliced={isOptionsSpliced}
                    searchAtChar={searchAtChar}
                />
            )}
            {isEditable && open && (
                <div
                    className="dropdown-outbox"
                    onClick={e => {
                        e.stopPropagation();
                        setClose();
                        setSearching(false);
                    }}></div>
            )}
        </div>
    );
};

IEDropdown.defaultProps = {
    options: [],
    selectedItems: [],
    isEditable: true,
    isMultiple: false,
    showUnavailable: true,
};

export default compose(
    onlyUpdateForKeys(['value', 'open', 'options', 'isMultiple']),
    withStateHandlers(
        ({ value, options, selectedItems = [], selectedItem = {} }) => {
            return {
                selectedValue: value,
                selectedItems,
                selectedItem,
                filteredOptions: options,
                unfilteredOptions: options,
            };
        },
        {
            trigger: ({ open }) => () => ({
                open: !open,
            }),
            setOpen: () => () => ({
                open: true,
            }),
            setClose: () => () => ({
                open: false,
                searching: false,
                selecting: false,
            }),
            setFilteredOptions: () => filteredOptions => ({
                filteredOptions,
            }),
            setUnfilteredOptions: () => unfilteredOptions => ({
                unfilteredOptions,
            }),
            setSelectedValue: () => selectedValue => ({
                selectedValue,
            }),
            addSelectedValue: ({ selectedValue = [] }) => value => ({
                selectedValue: [...selectedValue, value],
            }),
            setSelectedItems: () => selectedItems => ({
                selectedItems,
            }),
            setSelectedItem: () => selectedItem => ({
                selectedItem,
            }),
            setSelecting: () => selecting => ({
                selecting,
            }),
            setSearching: () => searching => ({
                searching,
            }),
            removeSelectedValue: ({ selectedValue = [] }) => value => {
                selectedValue = _.clone(selectedValue);
                const index = selectedValue.indexOf(value);
                if (index > -1) selectedValue.splice(index, 1);
                return {
                    selectedValue,
                    selecting: true,
                    searching: true,
                };
            },
            removeValueAtIndex: ({ selectedValue = [] }) => index => {
                if (selectedValue.length) {
                    selectedValue = _.clone(selectedValue);
                    selectedValue.splice(index, 1);
                }
                return {
                    selectedValue,
                    selecting: true,
                    searching: true,
                };
            },
        },
    ),
    withHandlers({
        updateValue: ({ onChange, isMultiple, selectedValue, unfilteredOptions }) => () => {
            if (isMultiple) {
                selectedValue = selectedValue || [];
                const selectedItems = selectedValue.forEach(value =>
                    unfilteredOptions.filter(option => option.value === value),
                );
                const data = { value: selectedValue, option: selectedItems };
                onChange && onChange(null, data);
            } else {
                const selectedItem = unfilteredOptions.filter(
                    option => option.value === selectedValue,
                );
                const data = {
                    value: selectedValue,
                    option: selectedItem && selectedItem[0],
                };
                onChange && onChange(null, data);
            }
        },
    }),
    withHandlers({
        removeLastValue: ({ isMultiple, removeValueAtIndex }) => e => {
            const isBackSpace = e.keyCode === 8;
            if (!e.target.value && isMultiple && isBackSpace) {
                removeValueAtIndex(-1);
            }
        },
        filterOptions: ({
            setFilteredOptions,
            unfilteredOptions,
            searchAtChar,
            searchAtStarting,
        }) => str => {
            str = str.trim().toLowerCase();
            const allowSearch = searchAtChar ? str.length >= parseInt(searchAtChar, 10) : true;
            if (str && allowSearch) {
                const filtered = searchAtStarting
                    ? unfilteredOptions.filter(
                        option =>
                            (option.title && option.title.toLowerCase().indexOf(str) === 0) ||
                              (option.description &&
                                  option.description.toLowerCase().indexOf(str) === 0),
                    ) // only that startWith
                    : unfilteredOptions.filter(
                        option =>
                            (option.title && option.title.toLowerCase().indexOf(str) > -1) ||
                              (option.description &&
                                  option.description.toLowerCase().indexOf(str) > -1),
                    ); // all that contains
                setFilteredOptions(filtered);
                return;
            }

            setFilteredOptions(unfilteredOptions);
        },
    }),
    withPropsOnChange(['selectedValue', 'unfilteredOptions'], props => {
        const { unfilteredOptions, isMultiple } = props;
        let { selectedValue, selectedItems, selectedItem } = props;
        /**
         * Set component's selected values with values
         */
        if (isMultiple) {
            selectedValue = selectedValue || [];
            const filtered = unfilteredOptions.filter(
                option => selectedValue.indexOf(option.value) > -1,
            );
            selectedItems = filtered;
        } else {
            const filtered = unfilteredOptions.filter(option => option.value === selectedValue);
            selectedItem = filtered[0];
        }
        return {
            selectedItems,
            selectedItem,
            selectedValue,
        };
    }),
    lifecycle({
        componentDidMount() {
            if (this.props.value) this.props.setSelectedValue(this.props.value);
        },
        componentDidUpdate(prevProps) {
            if (!_.isEqual(prevProps.value, this.props.value)) {
                this.props.setSelectedValue(this.props.value);
            }
            if (!_.isEqual(prevProps.selectedValue, this.props.selectedValue)) {
                this.props.updateValue();
            }
            // update options list when props.options changed
            if (!_.isEqual(prevProps.options, this.props.options)) {
                this.props.setUnfilteredOptions([...this.props.options]);
                this.props.setFilteredOptions([...this.props.options]);
            }
        },
    }),
)(IEDropdown);
