import { SET_SCHEMA } from '../actions/SchemaAction';

const INITIAL_STATE = {
    schema: {},
    fetching: false,
    error: null,
};

export default function schemaReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
    case SET_SCHEMA:
        const { schema } = action;
        return {
            ...state,
            schema: {
                ...state.schema,
                ...schema,
            },
        };
    default:
        return state;
    }
}
