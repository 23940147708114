import { createSelector } from 'reselect';

const showWarningSelector = state => state.session.showWarning;
const countdownSelector = state => state.session.countdown;

export const getShowWarning = createSelector(
    showWarningSelector,
    showWarning => showWarning,
);

export const getCountdown = createSelector(
    countdownSelector,
    countdown => countdown,
);
