import './polyfills';

import './rum';

import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

import Logo from './images/favicon/favicon.svg';
import './css/style.css';
import { withIE9Checking } from './components/Misc/page';

const container = document.getElementById('app');
const root = createRoot(container);

const EnhancedApp = withIE9Checking(App);

root.render(<EnhancedApp logo={Logo} />);
