import _ from 'underscore';
import * as FR from '../../Form/FormRules';

/**
 * A method to get dependants from triggers
 * @param {Object[]} triggers - list of triggers
 * @param {Object} fields - key value field object
 * @returns {Object} dependants
 */
function getDependants(triggers = [], fields = {}) {
    const byArray = {}; // to store {  target_field: [by, by1, by2, ...] }
    let dependants = _.groupBy(triggers, (t = {}) => {
        if (!_.isArray(t.data.by)) {
            const by = t.data.by;
            // add valueId as depandents if there is
            if (getCustomPropValueId(fields[by]))
                byArray[t.target] = [getCustomPropValueId(fields[by])];
            return t.data.by;
        } else {
            let by = t.data.by;
            // check if dependents has valueId
            // add valueId as depandents if there is
            // if yes, [by, valueId]
            // e.g. [by, [by1, by1valueId], by2]
            by = by.map(each => {
                if (getCustomPropValueId(fields[each]))
                    return [by, getCustomPropValueId(fields[each])];
                return each;
            });
            // flatten dependents
            // [by, by1, by1valueId, by1]
            by = _.flatten(by);
            byArray[t.target] = by;
        }
    });

    // to remove undefined key that's generated by _.groupBy if nothing returned
    delete dependants['undefined'];

    dependants = _.mapObject(dependants, group =>
        _.flatten(
            group.map((t = {}) => {
                if (getCustomPropValueId(fields[t.target])) {
                    return [t.target, getCustomPropValueId(fields[t.target])];
                }
                return t.target;
            }),
        ),
    );

    // To reformat the data structure
    // from { target_field: [by, by1, by2, ....] }
    // to { by: [target_field, by1: [target_field], by2: [target_field] }
    _.pairs(byArray).forEach(([key, fields]) => {
        // to insert [by, by1, by2, ...]
        // back to it dependent (target)
        fields.forEach(field => {
            if (!_.isArray(dependants[field])) dependants[field] = [dependants[field]];

            if (key) dependants[field].push(key);

            dependants[field] = _.uniq(dependants[field]);
        });
    });

    // to extract field.extend.replaceValidation dependants
    // this relationship is not being used yet
    Object.entries(fields).forEach(([key, prop]) => {
        const {
            custom_properties: { extend },
        } = prop;
        if (!extend || !extend.replaceValidation) return;
        // format it to trigger format
        const triggers = [
            {
                target: key,
                data: extend.replaceValidation,
            },
        ];
        const replaceValidationDependants = getDependants(triggers);
        Object.entries(replaceValidationDependants).forEach(([dependantId, ids]) => {
            // if already exist based on trigger generated
            if (dependants[dependantId]) {
                dependants[dependantId] = [...dependants[dependantId], ...ids];
            } else {
                // if not exist at all
                dependants[dependantId] = ids;
            }
        });
    });

    // remove undefined and null value
    dependants = FR.cleanValue(dependants);
    return dependants;

    function getCustomPropValueId(field) {
        if (field && field.custom_properties && field.custom_properties.valueId)
            return field.custom_properties.valueId;
    }
}

export default getDependants;
