import store from 'store';
import * as FR from '../../../Form/FormRules';
import { getValues } from 'redux/selectors';
import { setValidities } from 'redux/actions/ValidityAction';
import { setValue, setValues } from 'redux/actions/ValueAction';

const ASSIGNEE_DROPDOWN_ID = 'field_rn1519985436690';
/**
 * A method that generate assign to other form based on given data in the first param
 *
 * @param {Object} data - {
 *   formStatus {string}
 *   applicationId {string}
 *   assignees {string[]}
 *   assign {Funciton}
 *   cancel {Function}
 * }
 * @returns {Object} formObject - can be displayed by dispatching setActionForm(formObject)
 */
function createAssignOtherForm(data) {
    const { formStatus, applicationId, assignees, assign, cancel } = data;

    const form = {
        views: [
            {
                type: 'view',
                sections: [
                    {
                        type: 'section',
                        title: 'Section Assign to other',
                        name: 'Assign to other Section',
                        fieldsets: [
                            {
                                type: 'fieldset',
                                name: 'Fieldset',
                                grids: [
                                    {
                                        type: 'grid',
                                        fields: [
                                            {
                                                type: 'label',
                                                name: 'Application ID [Label]',
                                                title: `Application: ${applicationId}`,
                                                id: 'field_rn1523000462144',
                                                bold: true,
                                            },
                                        ],
                                    },
                                    {
                                        type: 'grid',
                                        className: 'decline-form-status',
                                        fields: [
                                            {
                                                type: 'label',
                                                name: 'Status [Label]',
                                                title: `Status: ${formStatus}`,
                                                id: 'field_rn1523000462143',
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                type: 'fieldset',
                                grids: [
                                    {
                                        type: 'grid',
                                        fields: [
                                            {
                                                type: 'select',
                                                name: 'Assignee [Dropdown]',
                                                label: {
                                                    type: 'outer',
                                                    title: 'Assignee',
                                                    position: 'top',
                                                },
                                                options: assignees,
                                                placeholder: 'Select one assignee',
                                                id: ASSIGNEE_DROPDOWN_ID,
                                            },
                                        ],
                                    },
                                    {
                                        type: 'grid',
                                        fields: [],
                                        id: 'grid_rn1519621234107',
                                    },
                                ],
                                id: 'fieldset_rn1519626647239',
                                name: 'Fieldset Assignee',
                            },
                        ],
                    },
                ],
            },
        ],
    };
    const buttons = [
        {
            title: 'Assign',
            name: 'assign',
            onClick: () => {
                // validate
                const values = getValues(store.getState());
                assign(values[ASSIGNEE_DROPDOWN_ID]);
            },
        },
        {
            title: 'Cancel',
            name: 'cancel',
            buttonType: 'secondary',
            onClick: () => {
                cancel();
            },
        },
    ];
    return { form, buttons };
}

export default createAssignOtherForm;

export { showAssignOtherFormError, clearAssignOtherFormValidities, clearAssignOtherFormValue };

function showAssignOtherFormError() {
    store.dispatch(
        setValidities({
            [ASSIGNEE_DROPDOWN_ID]: {
                isValid: false,
                errorMessage: FR.VALIDATOR_ERROR_MESSAGE.isRequired,
            },
        }),
    );
}

function clearAssignOtherFormValidities() {
    store.dispatch(
        setValidities({
            [ASSIGNEE_DROPDOWN_ID]: null,
        }),
    );
}

function clearAssignOtherFormValue() {
    store.dispatch(
        setValues({
            [ASSIGNEE_DROPDOWN_ID]: '',
        }),
    );
}

export function setLastAssigneeIdIfExist(history?: Record<string, any>) {
    if (!history || !history['last_assignee']) {
        return;
    }

    store.dispatch(
        setValue({
            path: ASSIGNEE_DROPDOWN_ID,
            value: history['last_assignee'],
        }),
    );
    store.dispatch(
        setValidities({
            [ASSIGNEE_DROPDOWN_ID]: {
                isValid: true,
                errorMessage: '',
            },
        }),
    );
}
