import { actionGetTemplates } from './TemplateAction';
import { actionGetFormList } from './TemplateFormAction';
import { setValues } from './ValueAction';
import { setFormCampaignSlug, actionLoadForm, setInitialFormData } from './FormAction';
import { formAPI } from '../../api/form/FormAPI';
import { userAPI } from '../../api/user/UserAPI';
import { startLoading, stopLoading } from './PageAction';

export const SET_ERROR = 'SET_ERROR';

export const setError = error => {
    return {
        type: SET_ERROR,
        error,
    };
};

export const SET_DASHBOARD_DELEGATED_ACCESSES = 'SET_DASHBOARD_DELEGATED_ACCESSES';

export const setDelegatedAccesses = dashboardDelegatedAccesses => {
    return {
        type: SET_DASHBOARD_DELEGATED_ACCESSES,
        payload: dashboardDelegatedAccesses,
    };
};

export const SET_DELEGATED_USER = 'SET_DELEGATED_USER';

export const setDelegatedUser = delegatedUser => {
    return {
        type: SET_DELEGATED_USER,
        delegatedUser,
    };
};

export const SET_UPDATE_FILTERS = 'SET_UPDATE_FILTERS';

export const setUpdateFilters = isUpdateFilters => {
    return {
        type: SET_UPDATE_FILTERS,
        isUpdateFilters,
    };
};

export function retrieveDelegatedUser(delegatedUser) {
    return dispatch => {
        const { role = null, user } = delegatedUser;
        if (!role) {
            // Myself
            dispatch(setDelegatedUser(user));
        } else {
            // Delegated User
            dispatch(setDelegatedUser(delegatedUser));
        }
    };
}

export function retrieveGivenAccesses() {
    return async dispatch => {
        dispatch(startLoading());
        try {
            const { delegated_accesses } = await userAPI.getDashboardDelegatedAccesses();
            dispatch(setDelegatedAccesses(delegated_accesses));
        } catch (e) {
            dispatch(setError(e));
        } finally {
            dispatch(stopLoading());
        }
    };
}

export function retrieveDashboard() {
    return dispatch => {
        dispatch(actionGetTemplates());
    };
}

export function retrieveTemplateFormList(templateId, checkedStatuses, checkedAssignees) {
    return dispatch => {
        // Reset isUpdateFilters state to false, after a successful form withdrawal.
        // When a form is withdrawn, it will call apply filters which will run these action.
        // This will prevent any unwanted apply filters.
        dispatch(actionGetFormList(templateId, checkedStatuses, checkedAssignees));
        dispatch(setUpdateFilters(false));
    };
}

export function getDecliningApplicationForm({ formId, templateId }) {
    return async dispatch => {
        dispatch(startLoading());
        try {
            const {
                form: { data, campaign, form_status, updated_dt },
            } = await formAPI.getFormData(templateId, formId);
            const { slug } = campaign || {};
            dispatch(setValues(data));
            dispatch(setInitialFormData(data));
            dispatch(setFormCampaignSlug(slug));
            // set formStatus and updatedDate into redux .form
            dispatch(
                actionLoadForm({
                    formStatus: form_status,
                    updatedDate: updated_dt,
                }),
            );
        } catch (e) {
            dispatch(setError(e));
        } finally {
            dispatch(stopLoading());
        }
    };
}
