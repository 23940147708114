import BaseAPI from '../BaseAPI';

class DelegatedAccessAPI extends BaseAPI {
    basePath = 'cap/delegated-accesses/';
    allowedDelegates = 'cap/users/me/allowed-delegates';

    // To retrieve delegated accesses
    async getDelegatedAccesses() {
        let delegatedAccesses;
        try {
            const response = await this.get({
                path: this.basePath,
            });
            delegatedAccesses = response.delegated_accesses;
        } catch (error) {
            throw error.statusText;
        }
        return delegatedAccesses;
    }

    // To retrieve allowed delegates list
    async getAllowedDelegatesByTemplates(templates) {
        let user;
        templates = templates
            .map(template => {
                return `templates=${ template }`;
            })
            .join('&');

        try {
            ({ user } = await this.get({
                path: `${ this.allowedDelegates }?${ templates }`,
            }));
        } catch (error) {
            throw error.statusText;
        }

        return user;
    }

    // To add delegated access
    async addDelegatedAccess(data) {
        let delegatedAccess;
        try {
            const response = await this.post({
                path: this.basePath,
                data,
            });
            delegatedAccess = response.delegated_access;
        } catch (error) {
            try {
                if (error.responseText) throw JSON.parse(error.responseText);
                else throw error.statusText;
            } catch (e) {
                throw e;
            }
        }

        return delegatedAccess;
    }

    // To update delegated access
    async updateDelegatedAccess(accessId, data) {
        let delegatedAccess;

        try {
            const response = await this.post({
                path: `${ this.basePath }${ accessId }/`,
                data,
            });
            delegatedAccess = response.delegated_access;
        } catch (error) {
            try {
                if (error.responseText) throw JSON.parse(error.responseText);
                else throw error.statusText;
            } catch (e) {
                throw e;
            }
        }

        return delegatedAccess;
    }

    // To delete delegated access
    async deleteDelegatedAccess(accessId) {
        try {
            await this.delete({
                path: `${ this.basePath }${ accessId }/`,
            });
        } catch (error) {
            throw error.statusText;
        }
    }
}

export const delegatedAccessAPI = new DelegatedAccessAPI();
export default DelegatedAccessAPI;
