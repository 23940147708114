import _ from 'underscore';
import * as FR from '../../Form/FormRules';
import { OWN_SUFFIX } from '.';
import getMatchedPlaceholders from './getMatchedPlaceholders';

/**
 * A method that loop through view object and return the sectionChildren
 *   that contain childs on the section, and a fieldsetFields that
 *   contain fields that mapped to fieldset of the section
 * @param {Object} view object
 * @param {Object} triggers object
 * @param {string[]} products - list of products [product_1_value, product_2_value, ...]
 * @param {string[]} mdrs - list of mdr fields ['paymentch_channel', 'off_us_credit', ...]
 * @param {string[]} dependants - list of dependants fields ['paymentch_channel', 'off_us_credit', ...]
 * @returns {Object} { sectionChildren, fieldsetFields }
 */
function getFormObjRelationships(view, triggers = {}, products = [], mdrs = [], dependants = {}) {
    const { sections } = view;
    const sectionChildren = {},
        fieldsetsFields = {},
        fieldsetFieldsets = {},
        fieldsetsOwnFields = {},
        fieldsetOwnFieldsets = {};
    sections.forEach(section => {
        const fieldsets = [];
        let fields = [];
        getFieldsAndFieldsets(section);

        sectionChildren[section.id] = {
            fields,
            fieldsets,
        };

        /**
         * Function to return fields and fieldset
         *   that belong to the obj passed
         * @param {Object} obj object
         * @param {string} fsId id, if exist
         * @returns {Object} Object { fieldIds: Array, fieldsetIds: Array }
         */
        function getFieldsAndFieldsets(obj, fsId) {
            let f = [],
                fs = [];
            if (!obj || !_.isObject(obj)) return {};

            Object.entries(obj).forEach(([, value]) => {
                let { type, id, valueId, extend, options, trigger = {}, set, setType } =
                    value || {};
                const isSpreadsheet = setType === FR.setTypeKey.spreadsheet;

                if (FR.isCaptureFieldType(type) || isSpreadsheet) {
                    // add all products as fields / child of parent form object
                    if (type === FR.midtidKey || type === FR.mdrKey) {
                        f = [...f, ...products];
                        fields = [...fields, ...products];
                    }
                    if (type === FR.mdrKey) {
                        f = [...f, ...mdrs];
                        fields = [...fields, ...mdrs];
                    }

                    f.push(id);
                    valueId && f.push(valueId);
                    fields.push(id);
                    valueId && fields.push(valueId);

                    if (_.isObject(options) && !_.isEmpty(options)) {
                        const { listID } = options;
                        const keys = listID && getMatchedPlaceholders(String(listID));

                        if (_.isArray(keys) && keys.length) {
                            fields = [...fields, ...keys];
                            f = [...f, ...keys];
                        }
                    }

                    if (fsId) {
                        fieldsetsFields[fsId].push(id);
                        fieldsetsFields[fsId].push(valueId || id);
                        fieldsetsOwnFields[fsId] = [...fieldsetsFields[fsId]];
                        // if there's extend dependants
                        // add also the dependant so
                        // that when value change field
                        // will update
                        if (extend) {
                            Object.values(extend).forEach(e => {
                                if (_.isArray(e.by)) {
                                    f = [...f, ...e.by];
                                    fieldsetsFields[fsId] = [...fieldsetsFields[fsId], ...e.by];
                                    fields = [...fields, ...e.by];
                                    e.by.forEach(each => {
                                        if (dependants[each]) dependants[each].push(id);
                                        else dependants[each] = [id];
                                    });
                                } else if (e.by) {
                                    fieldsetsFields[fsId].push(e.by);
                                    fields.push(e.by);
                                    f.push(e.by);

                                    if (dependants[e.by] && dependants[e.by].indexOf(id) < 0)
                                        dependants[e.by].push(id);
                                    else dependants[e.by] = [id];
                                }

                                if (_.isArray(e.byParent)) {
                                    f = [...f, ...e.byParent];
                                    fieldsetsFields[fsId] = [
                                        ...fieldsetsFields[fsId],
                                        ...e.byParent,
                                    ];
                                    fields = [...fields, ...e.byParent];

                                    e.byParent.forEach(each => {
                                        if (dependants[each]) dependants[each].push(id);
                                        else dependants[each] = [id];
                                    });
                                } else if (e.byParent) {
                                    fieldsetsFields[fsId].push(e.byParent);
                                    fields.push(e.byParent);
                                    f.push(e.byParent);

                                    if (
                                        dependants[e.byParent] &&
                                        dependants[e.byParent].indexOf(id) < 0
                                    )
                                        dependants[e.byParent].push(id);
                                    else dependants[e.byParent] = [id];
                                }
                            });
                        }
                    }
                    // when is spreadsheet add in valueId
                    if (isSpreadsheet) {
                        if (!fieldsetsFields[id]) fieldsetsFields[id] = [];

                        fieldsetsFields[id].push(valueId);
                    }

                    // include trigger dependents for field
                    if (triggers[id] && triggers[id].data) {
                        trigger = triggers[id].data;
                    }
                    if (trigger) {
                        const { by } = trigger;

                        if (_.isArray(by)) {
                            f = [...f, ...by];
                            fields = [...fields, ...by];
                        } else if (by) {
                            f.push(by);
                            fields.push(by);
                        }
                    }
                }

                if (type === FR.fieldsetKey || type === FR.terminalConfigurationKey) {
                    fs.push(id);
                    if (!fieldsetsFields[id]) fieldsetsFields[id] = [];

                    if (fieldsets.indexOf(id) < 0) fieldsets.push(id);

                    // add into section's fieldsets
                    if (valueId && fieldsets.indexOf(valueId) < 0) fieldsets.push(valueId);

                    // to handle undefined value
                    if (fsId && !fieldsetsFields[fsId]) fieldsetsFields[fsId] = [];

                    if (fsId && !fieldsetFieldsets[fsId]) fieldsetFieldsets[fsId] = [];

                    fsId && fieldsetFieldsets[fsId].push(id);
                    fsId && valueId && fieldsetFieldsets[fsId].push(valueId);

                    // if it's set don't need the child fieldsets for it's own ids
                    if (set && fsId) {
                        fieldsetOwnFieldsets[fsId] = [...fieldsetFieldsets[fsId]];
                        // pop out valueId first if it's defined
                        valueId && fieldsetOwnFieldsets[fsId].pop();
                        // pop out id then if it's defined
                        fieldsetOwnFieldsets[fsId].pop();
                    }

                    // if is duplicable set, treat it as a field also
                    //   when fsId exist, treat it as one of the field in the fieldset
                    if (set) {
                        fsId && fieldsetsFields[fsId].push(id);
                        valueId && fieldsetsFields[id].push(valueId);
                        f.push(id);
                        valueId && f.push(valueId);
                    }

                    const { fieldIds = [], fieldsetOwnIds = [] } = getFieldsAndFieldsets(
                        value,
                        id || fsId,
                    );

                    // To get all the fields from the fieldset and
                    //  it's inner fieldsets' field ids
                    let allFS, allOwnFS;
                    let allF = [...fieldIds, ...fieldsetsFields[id]];
                    let allOwnF = [...fieldsetOwnIds];

                    if (fieldsetFieldsets[id]) {
                        allFS = _.flatten(
                            fieldsetFieldsets[id].map(fsId => {
                                return fieldsetFieldsets[fsId] || fsId;
                            }),
                        );
                        allFS = [...allFS, ...fieldsetFieldsets[id]];

                        allF = _.flatten(
                            allFS.map(fs => {
                                return _.uniq(fieldsetsFields[fs]);
                            }),
                        );
                        allF = [...allF, ...fieldsetsFields[id]];

                        allOwnFS = _.flatten(
                            fieldsetFieldsets[id].map(fsId => {
                                return fieldsetOwnFieldsets[fsId] || fsId;
                            }),
                        );

                        allOwnF = _.flatten(
                            allOwnFS.map(fs => {
                                return _.uniq(fieldsetsOwnFields[fs]);
                            }),
                        );
                    }

                    if (set) {
                        fieldsetsFields[`${ id }${ OWN_SUFFIX }`] = _.uniq([...allOwnF]);
                    }

                    // include extend dependency
                    if (extend) {
                        Object.values(extend).forEach(e => {
                            if (_.isArray(e.by)) {
                                allF = [...allF, ...e.by];
                            } else if (e.by) {
                                allF.push(e.by);
                            }

                            if (_.isArray(e.byParent)) {
                                allF = [...allF, ...e.byParent];
                            } else if (e.byParent) {
                                allF.push(e.byParent);
                            }
                        });
                    }

                    // include trigger dependents for fieldset
                    if (triggers[id] && triggers[id].data) {
                        const { by } = triggers[id].data;

                        if (_.isArray(by)) {
                            allF = [...allF, ...by];
                        } else if (by) {
                            allF.push(by);
                        }
                    }

                    fieldsetsFields[id] = _.uniq(allF);
                } else {
                    const { fieldIds = [], fieldsetIds = [] } = getFieldsAndFieldsets(value, fsId);

                    if (fieldsetIds.length) {
                        fs = _.uniq([...fieldsetIds, ...fs]);
                    }
                    if (fieldIds.length) {
                        f = _.uniq([...fieldIds, ...f]);
                    }
                }
            });
            return {
                fieldIds: f,
                fieldsetIds: fs,
                fieldsetOwnIds: fieldsetsOwnFields[fsId],
            };
        }
    });

    return {
        sectionChildren,
        fieldsetsFields,
        dependants,
    };
}

export default getFormObjRelationships;
