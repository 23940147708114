import React from 'react';

import ObjectClone from 'clone';
import ObjectPath from 'object-path';

import _ from 'underscore';
import Label from './Label';
import Copy from './Copy';
import FieldsetBuilder from './FieldsetBuilder';
import { connect } from 'react-redux';
import { observableDiff } from 'deep-diff';
import {
    compose,
    withState,
    lifecycle,
    withHandlers,
    withPropsOnChange,
    shouldUpdate,
    withStateHandlers,
    mapProps,
} from 'recompose';

import { actionChangeFieldset } from '../../redux/actions/FormAction';
import { withFormUtils, withProcessedProps, OWN_SUFFIX } from '../Misc/forms';
import { getValidities } from '../../redux/selectors';
import DefaultAddMoreButton from '../Misc/DefaultFormObject/DefaultAddMoreButton';
import store from '../../store';
import { setValue } from '../../redux/actions/ValueAction';

// Recompose
const withShouldUpdate = compose(
    shouldUpdate((current, next) => {
        if (!_.isEqual(current, next)) return true;
        return false;
    }),
);

const DuplicableFieldset = ({
    // option
    className,
    id,
    tabs,
    setId,
    tabId,
    setPath,
    tabPath,
    isEditable = true,
    isValid = true,
    // props
    name,
    duplicate,
    showBorder,
    showTabName,
    copy,
    tabErrors = [],
    allowAddMore,
    allowCopy,
    max,
    tabView = true,
    // state
    activeIndex,
    isLoadingActive = true,
    // utils
    showComponent,
    path,
    // inherited parent utils
    isEditingField,
    isEditingGrid,
    // handlers
    incrementTabsLoadedNo,
    handleAddMoreClick,
    handleTabMenuClick,
    handleTabRemoveClick,

    // component to pass to children
    value = [],
    validity = [],
    triggers,
    triggeredState = [],
    disabledFields,
    fieldsetsFields,
}) => {
    const EnhancedFSBuilder = withShouldUpdate(FieldsetBuilder);

    if (!showComponent) return <span />;

    const classWarning = 'warning';
    const classNames = [
        'jpt--input input__tab',
        allowAddMore ? 'input__tab-duplicable' : '',
        !isValid ? classWarning : '',
        _.isString(className) ? className : '',
        !tabView ? 'vertical' : '',
        showBorder ? 'border' : '',
        showTabName ? 'tab-name' : '',
    ];
    const panes = [],
        menus = [];
    tabErrors = _.isArray(tabErrors) ? tabErrors : [];

    Object.entries(tabs).forEach((keyValue, index) => {
        const tab = keyValue[1];
        const num = index + 1;
        const isActiveIndex = index === activeIndex;
        const isFirstTab = index === 0;
        const showRemoveButton = !isFirstTab && isEditable && allowAddMore;
        const tabName = name ? `${ name } ${ num }` : `Tab ${ num }`;
        const forceHide = tabView && index >= parseInt(duplicate, 10);
        const classNames = [
            'item',
            tabView && isActiveIndex && 'active',
            tabErrors.indexOf(index) > -1 && 'warning',
        ];

        const extraProps = {
            isEditingField,
            isEditingGrid,
            isParentEditable: !forceHide && isEditable,
            setId,
            tabIndex: index,
            tabId,
            fieldname: {
                parent: path,
                current: `set.${ index }`,
            },
            className: forceHide && 'hidden',

            // component to pass to children
            values: value[index],
            validities: validity[index],
            triggeredStates: triggeredState[index],
            trigger: triggers[id],
            triggers,
            disabledFields,
            fieldsetsFields,
        };

        // handle tab view
        if (tabView) {
            menus.push(
                <div
                    key={tabName}
                    className={classNames.join(' ')}
                    role="button"
                    onClick={e => {
                        handleTabMenuClick(e, index);
                    }}>
                    {tabName}
                    {showRemoveButton && (
                        <RemoveButton
                            onClick={e => {
                                handleTabRemoveClick(e, { index, tabName });
                            }}
                        />
                    )}
                </div>,
            );

            panes.push(
                <EnhancedTabPane
                    key={index}
                    show={isActiveIndex}
                    tab={tab}
                    index={index}
                    setPath={setPath}
                    tabPath={tabPath}
                    {...extraProps}
                />,
            );
        } else {
            panes.push(
                <VerticalPane
                    key={index}
                    tab={tab}
                    index={index}
                    setPath={setPath}
                    tabPath={tabPath}
                    tabName={tabName}
                    showRemoveButton={showRemoveButton}
                    {...extraProps}
                    onRemovePane={handleTabRemoveClick}
                />,
            );
        }
    });

    // If it's allowed to add more tab
    //  and also editable
    const inRange = !max || (max && tabs.length < max);
    if (allowAddMore && isEditable && inRange) {
        if (tabView) {
            menus.push(
                <div key="add_more" className="item" role="button" onClick={handleAddMoreClick}>
                    <i className="icon plus add-more" />
                    {name}
                </div>,
            );

            panes.push(<div key="hidden" hidden={true} />);
        } else {
            const addMore = new DefaultAddMoreButton(handleAddMoreClick);

            panes.push(
                <EnhancedFSBuilder
                    key="panes"
                    fieldset={{ grids: addMore }}
                    grids={addMore}
                    className="jpt--input input__add-more"
                />,
            );
        }
    }

    // Show loader when it's needed
    const showLoader = tabs.length < parseInt(duplicate, 10);
    if (!tabView && showLoader) {
        panes.push(
            <Loader
                key="loader"
                show={showLoader}
                offset={{ top: -300 }}
                active={isLoadingActive}
                onEnterViewport={incrementTabsLoadedNo}
            />,
        );
    }

    // If horizontal tab view, show as tab panes
    if (!tabView) {
        return (
            <div className={classNames.join(' ')}>
                {_.isObject(copy) && allowCopy && (
                    <div className="copy">
                        <Copy
                            onClick={{
                                fields: copy.fields,
                                from: `${ id }.${ copy.index | 0 }`,
                            }}
                            label={copy.label}
                            isEditable={isEditable}
                        />
                    </div>
                )}
                {panes}
            </div>
        );
    }

    // when is tab view
    return (
        <div className={classNames.join(' ')}>
            {_.isObject(copy) && allowCopy && (
                <div className="copy">
                    <Copy
                        onClick={{
                            fields: copy.fields,
                            from: `${ id }.${ copy.index | 0 }`,
                        }}
                        label={copy.label}
                        isEditable={isEditable}
                    />
                </div>
            )}
            <div className="menu">{menus}</div>
            {panes}
        </div>
    );
};

DuplicableFieldset.defaultProps = {
    showBorder: true,
    showTabName: true,
};

// To get tabErrors in redux state
const mapStateToProps = (state, { id }) => {
    const validities = getValidities(state);
    let tabErrors = ObjectPath.get(validities, `${ id }.tabErrors`);
    if (!_.isArray(tabErrors)) tabErrors = [];

    return {
        tabErrors,
    };
};

// To create handler that dispatch
// - setTabs action
const mapDispatchToProps = dispatch => {
    return {
        setTabs({ path = '', tabs = [] }) {
            dispatch(
                actionChangeFieldset({
                    path,
                    fieldsets: tabs,
                }),
            );
        },
    };
};

export default compose(
    // To get tabErrors in redux state
    //   and setTabs action to dispatch
    //   tab change
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    shouldUpdate((current, next) => {
        return !_.isEqual(current, next);
    }),
    withProcessedProps,
    // State that hold the current viewing tab index
    withState('activeIndex', 'setActiveIndex', 0),
    // Set allowAddMore and allowRemove false once
    //   isEditable is false
    withPropsOnChange(['isEditable'], ({ isEditable = true }) => {
        if (!isEditable) {
            return {
                allowAddMore: false,
                allowRemove: false,
            };
        }
    }),
    // auto populate repetitive set base on value length
    withPropsOnChange(['tabs', 'value'], ({ tabs, value, max }) => {
        if (
            value &&
            (!tabs || tabs.length < value.length) &&
            value.length > 1 &&
            value.length <= max
        ) {
            return {
                duplicate: value.length,
            };
        }
    }),
    // tabsLoadedNo and loadNumber is set to
    //   1 by default, which represet the
    //   total tab loaded initially when
    //   it's more than the default value
    // setLoadedNumber will set tabsLoadedNo,
    //   a total number of tabs loaded
    withStateHandlers(
        ({ tabsLoadedNo = 1, tabs }) => ({
            loadNumber: tabsLoadedNo,
            tabsLoadedNo,
            tabs,
        }),
        {
            setLoadedNumber: () => number => {
                return {
                    tabsLoadedNo: number,
                };
            },
            setLocalTabs: () => tabs => ({
                tabs,
            }),
        },
    ),
    // state
    // initialTab: tabs original copy of first set
    withStateHandlers(
        ({ tabs }) => ({
            initialTab: ObjectClone(cleanTab(tabs[0])),
        }),
        null,
    ),
    // handlers list:
    // spliceTabsLocalStorage, duplicateTabPane
    // appendNewTab
    withHandlers({
        // * not actually being used
        //  * there's handlers still depend on this
        // This handler remove tab of tabIndex in
        //   local storage value
        spliceTabsLocalStorage: ({ templateId, id, localStorage }) => ({ tabIndex }) => {
            if (!localStorage) return;

            let path = id;
            path = `t_${ templateId }.${ id }`;
            const localValue = localStorage.getApplicationFormValue({ path });

            if (!_.isEmpty(localValue) && tabIndex > 0) {
                localValue.splice(tabIndex, 1);
                localStorage.setApplicationFormValue({
                    path,
                    value: localValue,
                });
            }
        },
        // handler to duplicate tab based on tabsLoadedNo
        duplicateTabPane: props => () => {
            let { value = [], path = '', tabs = [], duplicate, tabsLoadedNo } = props;

            // when duplicate less than tabsLoadedNo
            if (duplicate < tabsLoadedNo) {
                tabsLoadedNo = duplicate;
            } else {
                // set tabsLoadedNo equivalent to value size, to load tabs based on value size
                if (value.length > tabsLoadedNo) {
                    tabsLoadedNo = value.length;
                }

                // stop, if tabs number is equivalent to what's already loaded
                if (tabsLoadedNo === tabs.length) {
                    return;
                }

                // when value is more than tabs
                if (value.length > tabs.length) {
                    props.setLoadedNumber(value.length);
                    tabsLoadedNo = value.length;
                }
            }
            // based on tabsLoadedNo, replicate tab needs to show
            if (parseInt(tabsLoadedNo, 10) !== tabs.length) {
                const newTabs = [];
                for (let index = 0; index < tabsLoadedNo; index++) {
                    const tab = ObjectClone(props.initialTab);
                    newTabs.push(tab);
                }

                props.setTabs({
                    path: `${ path }.set`,
                    tabs: newTabs,
                });
                props.setLocalTabs(newTabs);
                props.setLoadedNumber(tabsLoadedNo);
            }
        },
        // handler to append new tab at the end
        // depending methos: setTabs
        appendNewTab: ({ tabs, setLoadedNumber, max }) => () => {
            const isLessThanMax = !max || (max && tabs.length < max);
            if (isLessThanMax) {
                setLoadedNumber(tabs.length + 1);
            }
        },
    }),
    // ### end
    // Form utils to show confirmation pop up
    withFormUtils,
    mapProps(
        ({
            TYPE,
            form,
            showCreateForm,
            showErrorForm,
            showHistoryForm,
            showPageErrorForm,
            showTheHistoryForm,
            showUpdateForm,
            showForm,

            ...rest
        }) => ({
            ...rest,
        }),
    ),
    // ### end
    // handlers list:
    // incrementTabsLoadedNo, showTabRemoveConfirmation
    withHandlers({
        // Handler that increase tabs loaded number
        //   to show the total number of tab
        //   when scrolled to bottom, which
        //   when loader is visible
        incrementTabsLoadedNo: ({
            duplicate,
            tabs = [],
            tabsLoadedNo,
            loadSetPerLoad = 5,
            setLoadedNumber,
        }) => () => {
            if (tabs.length >= parseInt(duplicate, 10)) return;
            let total = parseInt(loadSetPerLoad, 10) + parseInt(tabsLoadedNo, 10);
            if (total >= parseInt(duplicate, 10)) {
                total = duplicate;
            }
            setLoadedNumber(total);
        },
        // Handler to show tab removal confirmation box
        showTabRemoveConfirmation: ({ showDeleteConfirmationForm, hideForm }) => ({
            itemName,
            message,
            cb,
        }) => {
            if (!message) {
                message = `You might lose all of the contents
                    in ${ itemName }. Are you sure ?`;
            }
            showDeleteConfirmationForm({
                message,
                onConfirm() {
                    hideForm();
                    cb();
                },
            });
        },
    }),
    // ### end
    // handlers list:
    // handleAddMoreClick,
    // handleTabMenuClick,
    // handleTabRemoveClick,
    withHandlers({
        /**
         * Handle add more tab menu click. It will append new tab with
         *   content from last tab, which is the tab before add more tab.
         *   It will run only when allowAddMore given in the prop is true.
         *
         * @param {SyntheticEvent} e - React click synthetic event object
         * @returns {undefined}
         */
        handleAddMoreClick: ({
            initialId,
            id,
            setPath,
            tabs,
            tabView = true,
            allowAddMore,
            fieldsetsFields,
            tabsLoadedNo,
            value = [],
            // hanlders
            setActiveIndex,
            appendNewTab,
            onTabAdd,
        }) => () => {
            if (allowAddMore) {
                const lastIndex = tabs.length - 1;
                appendNewTab();
                if (tabView) setActiveIndex(lastIndex + 1);
                _.isFunction(onTabAdd) &&
                    onTabAdd({ path: id, parent: setPath, index: tabs.length - 1 });

                // add new keys into new tab, so that tab is created and value can be copied to it with copy feature
                fieldsetsFields = fieldsetsFields || {};
                const fields = fieldsetsFields[`${ initialId }${ OWN_SUFFIX }`];

                const newValue = addInDuplicableSetValueDefaultKeys({
                    duplicate: tabsLoadedNo + 1,
                    value: ObjectClone(value),
                    fields,
                });
                store.dispatch(
                    setValue({
                        path: id,
                        value: newValue,
                    }),
                );
            }
        },
        /**
         * Handle tab menu click. It will set the active index of tab
         *   to index of clicked tab menu.
         *
         * @param {SyntheticEvent} e - React click synthetic event object
         * @param {number} index - Index of clicked tab menu
         * @returns {undefined}
         */
        handleTabMenuClick: ({ setActiveIndex }) => (e, index) => {
            setActiveIndex(index);
        },
        /**
         * Handle tab menu remove button click. It will remove clicked
         *   tab menu from tabs with index param value. Then, It will
         *   set the active index of tab to one index before clicked
         *   tab menu.
         *
         * @param {SyntheticEvent} e - React click synthetic event object
         * @param {number} index - Index of clicked tab menu
         * @returns {undefined}
         */
        handleTabRemoveClick: ({
            tabs,
            setId,
            id,
            path,
            value = [],
            activeIndex,
            isEditable,
            tabsLoadedNo,
            // handlers
            setLoadedNumber,
            showTabRemoveConfirmation,
            spliceTabsLocalStorage,
            setActiveIndex,
            setTabs,
            onTabRemove,
        }) => (e, { index, tabName }) => {
            e.preventDefault();
            e.stopPropagation();
            showTabRemoveConfirmation({
                itemName: tabName,
                cb: () => {
                    if (isEditable) {
                        value.splice(index, 1);
                        tabs.splice(index, 1);
                        setLoadedNumber(tabsLoadedNo - 1);
                        setTabs && setTabs({ path: `${ path }.set`, tabs });
                        store.dispatch(
                            setValue({
                                path: id,
                                value,
                            }),
                        );
                        spliceTabsLocalStorage &&
                            spliceTabsLocalStorage({
                                tabIndex: index,
                            });
                        _.isFunction(onTabRemove) &&
                            onTabRemove({ path: setId || id, index, value });
                    }
                    // handle active index if the removing is active tab
                    if (activeIndex === index && index > 0) {
                        setActiveIndex(index - 1);
                    } else if (activeIndex !== 0) {
                        setActiveIndex(tabs.length - 1);
                    }
                },
            });
        },
        setValueDefaultKey: ({
            fieldsetsFields,
            duplicate,
            value,
            initialId,
            valuePath,
        }) => strictCheck => {
            // for setting default key for each record
            fieldsetsFields = fieldsetsFields || {};
            const fields = fieldsetsFields[`${ initialId }${ OWN_SUFFIX }`];

            const newValue = addInDuplicableSetValueDefaultKeys({
                duplicate,
                value: ObjectClone(value),
                fields,
            });

            let update = false;
            if (strictCheck) {
                // to check only own field ids
                const onlyOwnIdsValue = [];
                const onlyOwnIdsNewValue = [];
                _.each(newValue, (v, i) => {
                    _.each(fields, f => {
                        if (!onlyOwnIdsValue[i]) {
                            onlyOwnIdsValue[i] = {};
                        }
                        if (!onlyOwnIdsNewValue[i]) {
                            onlyOwnIdsNewValue[i] = {};
                        }
                        // fallback to null cause for undefined newValue will be null
                        //   this is to ensure an equal comparison between two values
                        onlyOwnIdsValue[i][f] = value[i][f] || null;
                        onlyOwnIdsNewValue[i][f] = v[f];
                    });
                });
                // if array value of current and new is not equal
                update = !_.isEqual(onlyOwnIdsValue, onlyOwnIdsNewValue);
            } else {
                update = !_.isEqual(value, newValue);
            }

            if (update)
                store.dispatch(
                    setValue({
                        path: valuePath,
                        value: newValue,
                    }),
                );
        },
        removeUnnecessaryTabValue: ({ value, valuePath, duplicate }) => currentSize => {
            if (!value) return;
            value = removeTabValue({
                value,
                before: currentSize,
                after: duplicate,
            });
            store.dispatch(
                setValue({
                    path: valuePath,
                    value,
                }),
            );

            return value;
        },
    }),
    // ### end
    // lifecycle
    // componentWillReceiveProps
    //   - duplicate tab pane based on value.length
    //     or duplicate number
    lifecycle({
        componentWillReceiveProps(nextProps) {
            nextProps.duplicateTabPane();
        },
        componentDidMount() {
            this.props.duplicateTabPane();

            const { value = [] } = this.props;
            if (value && value.length) {
                setTimeout(() => {
                    // temporary delay this action, so that the rest of the for field is ready for the next update
                    this.props.setValueDefaultKey(true);
                }, 1000);
            }
        },
        componentDidUpdate({ duplicate, tabId, id, tabs, tabsLoadedNo, onAfterTabAdd, value }) {
            const currentTabs = this.props.tabs;
            const currentTabsLoadedNo = this.props.tabsLoadedNo;

            if (tabs.length < currentTabs.length) {
                _.isFunction(onAfterTabAdd) &&
                    onAfterTabAdd({ path: tabId || id, index: currentTabs.length - 1 });
            }
            if (currentTabsLoadedNo !== tabsLoadedNo) {
                this.props.duplicateTabPane();
            }

            // if duplicate change to smaller value
            if (duplicate !== this.props.duplicate && duplicate > this.props.duplicate) {
                duplicate = parseInt(duplicate, 10) || 0;
                this.props.removeUnnecessaryTabValue(duplicate);
            }

            const diffValue = observableDiff(value, this.props.value);
            if (diffValue.length) {
                // temporary delay this action, so that the rest of the for field is ready for the next update
                setTimeout(() => {
                    this.props.setValueDefaultKey();
                }, 1000);
            }
        },
    }),
    // ### end
    // remove unessary props that shouldn't
    //   pass to DuplicableFieldset
    mapProps(
        ({
            form,
            setAcionForm,
            setActiveIndex,
            appendNewTab,
            showTabRemoveConfirmation,
            spliceTabsLocalStorage,
            initialTab,
            setTabs,
            ...rest
        }) => ({
            ...rest,
        }),
    ),
)(DuplicableFieldset);

const TabPane = ({ tab, index, className, show, ...rest }) => {
    const num = index + 1;
    const classNames = [
        'jpt--input input__tab-pane',
        _.isString(className) && className,
        `tab-${ num }`,
        !show && 'hidden',
    ];
    return (
        <FieldsetBuilder
            key={rest.tabId || index}
            show={show}
            attached={false}
            className={classNames.join(' ')}
            isTab={!!tab}
            id={rest.tabId}
            grids={tab.grids}
            {...rest}
        />
    );
};
const EnhancedTabPane = withShouldUpdate(TabPane);

const VerticalPane = ({
    tabName,
    tab,
    index,
    className,
    showRemoveButton,
    onRemovePane,
    ...rest
}) => {
    const num = index + 1;
    const classNames = [
        _.isString(className) && className,
        'jpt--input input__tab-pane tab-pane--vertical',
    ];
    return (
        <div key={index} className={classNames.join(' ')}>
            <Label className="tab-name" title={tabName} bold={true} />
            {showRemoveButton && (
                <RemoveButton
                    onClick={e => {
                        onRemovePane(e, { index, tabName });
                    }}
                    className="top right"
                />
            )}
            <FieldsetBuilder
                key={index}
                id={rest.id}
                fieldset={tab}
                isTab={!!tab}
                className={`tab-${ num }`}
                grids={tab.grids}
                {...rest}
            />
        </div>
    );
};

const RemoveButton = ({ onClick, className }) => {
    const classNames = [_.isString && className, 'icon remove remove-tab'];
    return <i className={classNames.join(' ')} onClick={onClick} role="button" />;
};

const Loader = compose(
    withHandlers(() => {
        let ref;
        return {
            setRef: () => r => {
                ref = r;
            },
            getRef: () => () => ref,
        };
    }),
    withState('isVisible', 'setIsVisible', false),
    withHandlers({
        scrollListener: props => () => {
            const { isVisible, offset } = props;
            const el = props.getRef();
            // console.log('scrolled')
            if (el && isElementInViewport(el, offset)) {
                if (!isVisible) props.setIsVisible(true);
            } else {
                if (isVisible) props.setIsVisible(false);
            }
        },
    }),
    withPropsOnChange(['scrollListener'], ({ scrollListener }) => ({
        debouncedScrollListener: (function() {
            var timer = 0;
            return function() {
                clearTimeout(timer);
                timer = setTimeout(scrollListener, 10);
            };
        })(),
    })),
    lifecycle({
        componentDidMount() {
            const { getRef, offset, onEnterViewport } = this.props;
            window.addEventListener('scroll', this.props.debouncedScrollListener, false);
            // check my self is in view port
            // delayed due to the incorrect position returned, when it's mounted (during css animation)
            setTimeout(() => {
                if (getRef() && isElementInViewport(getRef(), offset)) {
                    onEnterViewport();
                }
            }, 1000);
        },
        componentWillUnmount() {
            window.removeEventListener('scroll', this.props.debouncedScrollListener);
        },
        componentDidUpdate(prevProps) {
            const props = this.props;
            const { onEnterViewport } = this.props;

            if (prevProps.isVisible !== props.isVisible) {
                onEnterViewport();
            }
        },
    }),
)(({ setRef, show }) => {
    if (!show) return <span />;

    const classNames = [
        'loader',
        // !isVisible && "hidden"
    ];
    return (
        <div className="loader-container" ref={setRef}>
            <div className={classNames.join(' ')}></div>
        </div>
    );
});

function isElementInViewport(el, offset = { top: 0 }) {
    var rect = el.getBoundingClientRect();

    return (
        rect.top + offset.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

/**
 * Clean up any duplicated tab object in the array of 'set'
 *   Recursive call to clean up to clean up multilevel of tab in tab object
 * @param {Object} tab - tab object, it is a fieldset object
 * @returns {Object} newTab
 */
export function cleanTab(tab = {}) {
    const newTab = {};
    Object.entries(tab).forEach(([key, value]) => {
        if (key === 'set') {
            newTab[key] = [cleanTab(value[0])];
        } else if (_.isArray(value)) {
            newTab[key] = value.map(v => {
                if (_.isObject(v)) return cleanTab(v);
                else return v;
            });
        } else {
            newTab[key] = value;
        }
    });
    return newTab;
}

/**
 * A methat that generate an array with object of field ids into the value given
 * based on the number of duplicate.
 * @param {Object} object - {
 *   duplicate, - number of duplicate / number of tab
 *   value, - array value for the tab
 *   fields - array of field ids
 * }
 * @returns {Object[]} value - array of value for the set without removing existing key with value
 */
export function addInDuplicableSetValueDefaultKeys({ duplicate, value = [], fields = [] }) {
    if (!value && !duplicate) return value;

    duplicate = duplicate || value.length;

    const fieldKeys = {};
    fields.forEach(f => {
        fieldKeys[f] = null;
    });

    for (const i of _.range(duplicate)) {
        const row = ObjectClone(value[i]) || {};
        value[i] = {
            ...fieldKeys,
            ...row,
        };
    }

    return value;
}

/**
 * A method to remove array value based on before and after number of rows given
 * @param {Object} object - {
 *   value: {Object[]} - [{ ...keyValue }],
 *   before: {number} - e.g. 10
 *   after: {number} - e.g. 2
 * @returns {Object[]} value - {Object[]} will original value if after is
 * more than or equal to before
 * }
 */
export function removeTabValue({ value, before, after, debug }) {
    after = parseInt(after || 0, 10);
    before = parseInt(before || 0, 10);
    if (after >= before) return value;
    const recordsNumberToRemoved = before - after;
    const startingIndex = after;
    value.splice(startingIndex, recordsNumberToRemoved);
    debug &&
        console.table({
            Before: before,
            After: after,
            NumberOfRecordsRemoved: recordsNumberToRemoved,
            RemoveFromIndex: startingIndex,
            NewValueSize: value.length,
        });

    return value;
}
