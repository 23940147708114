import _ from 'underscore';
import store from 'store';
import { compose, lifecycle } from 'recompose';
import { getValues } from 'redux/selectors';
import { setPresetList } from 'redux/actions/ListAction';

const withTerminalConfigurationsListCheck = compose(
    lifecycle({
        componentDidMount() {
            const LIST_ID = 'terminal_configurations';
            const ID = 'terminal_configuration';
            const NAME = 'Terminal Type';
            const { optionName = NAME, options, terminalConfiguration } = this.props;
            let id = LIST_ID;

            if (_.isObject(options) && terminalConfiguration) {
                id = options.listID;
            } else {
                return;
            }
            const terminalConfigurations = getValues(store.getState())[id] || [];

            // set preset list
            if (terminalConfigurations.length) {
                const list = terminalConfigurations.map((v, i) => ({
                    title: v.name ? v.name : `${ optionName } ${ i + 1 }`,
                    value: v[ID],
                }));
                store.dispatch(setPresetList(id, list));
            }
        },
    }),
);

export default withTerminalConfigurationsListCheck;
