import React from 'react';
import _ from 'underscore';

const Button = ({ name, icon, children, onClick }) => {
    name = _.isString(children) && children;

    return (
        <div className="signatory-button" role="button" onClick={onClick}>
            {icon && <img className="button-icon" src={icon} alt="button" />}
            <div className="button-name">{name}</div>
            {!_.isString(children) && children}
        </div>
    );
};

export default Button;
