import { gridKey, buttonKey } from '../../Form/FormRules';

function DefaultAddMoreButton(onClick) {
    return [
        {
            type: gridKey,
            fields: [
                {
                    type: buttonKey,
                    icon: {
                        name: 'plus',
                        position: 'left',
                    },
                    title: 'Add more',
                    onClick: function() {
                        onClick && onClick();
                    },
                },
            ],
        },
    ];
}
export default DefaultAddMoreButton;
