import React from 'react';
import _ from 'underscore';

import RadioGroup from './RadioGroup';
import IERadioGroup from './IERadioGroup';
import ErrorMessage from './ErrorMessage';
import Label from './../Label';
import store from '../../../store';

import { compose, branch, withPropsOnChange } from 'recompose';
import { FieldAction } from '../../Misc/template';
import { withCaptureFieldHandlers, withPrimaryRadioButtonLogic } from '../../Misc/forms';
import withDeprecatedOptions from '../../Misc/forms/withDeprecatedOptions';

const BaseRadioGroupHOC = Component =>
    class BaseRadioGroup extends React.Component {
        topClass = 'input__label--top';
        classWarning = 'warning';

        static defaultProps = {
            className: '',
            fieldname: {},
            label: {},
            options: [],
            placeholder: '',
            isValid: true,
            columns: 12,
        };

        constructor(props) {
            super(props);
            this.props = props;

            this.handleChange = this.props.handleChange.bind(this);
        }

        render() {
            const {
                className,
                // option
                type,
                title,
                label,
                name,
                value,
                isEditable,
                onChange,
                isValid,
                errorMessage,
                columns,
                message,
                options,
                isDisabledOptions,
                // utils
                showComponent,
                path,
                id,
            } = this.props;
            const option = {
                type,
                title,
                label,
                name,
                value,
                options,
                onChange,
                message,
                columns,
            };
            const classNames = [
                'jpt--input input__radio',
                _.isString(className) && className,
                !isValid && this.classWarning,
                !isEditable && 'noteditable',
            ];

            if (!showComponent) return <div />;

            return (
                <div className={classNames.join(' ')} id={id}>
                    {label && <Label className={this.topClass} {...label}></Label>}

                    <Component
                        columns={columns}
                        radios={options}
                        isEditable={isEditable}
                        selectedValue={value}
                        isDisabledOptions={isDisabledOptions}
                        onChange={(e, data) => {
                            this.handleChange(e, data);
                            onChange(e, data);
                        }}
                    />

                    <ErrorMessage message={errorMessage} />

                    {this.props.isEditingField && <FieldAction path={path} field={option} />}
                </div>
            );
        }
    };

const enhance = compose(
    withCaptureFieldHandlers,
    withPropsOnChange(['options'], ({ options }) => {
        if (_.isArray(options)) return {};
        const { listID } = options;
        const { lists = {} } = store.getState().list || {};
        return {
            options: lists[listID],
        };
    }),
    // deprecated options must only be processed after options / options.listID mapping all done
    withDeprecatedOptions,
    withPrimaryRadioButtonLogic,
);

/**
 * Branch, Conditionally Render RadioGroup
 * If IE9 then show IE dropdown else show normal
 * radiogroup
 */
const BranchRadioGroupHOC = branch(
    () => {
        const { platform } = store.getState().page;
        return platform.ie9;
    },
    () => BaseRadioGroupHOC(IERadioGroup),
    t => BaseRadioGroupHOC(t),
)(IERadioGroup);

/**
 * Enhanced BranchRadioHOC
 */
const IECheckedRadioGroup = enhance(BranchRadioGroupHOC);

/**
 * EnhancedRadioGroup as default export
 */
export default IECheckedRadioGroup;

export { IECheckedRadioGroup, RadioGroup, IERadioGroup };
