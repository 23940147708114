export const SET_FORM_TRIGGER = 'SET_FORM_TRIGGER';
export function setTrigger({ id, trigger }) {
    return {
        type: SET_FORM_TRIGGER,
        payload: {
            id,
            trigger,
        },
    };
}

export const SET_FORM_TRIGGERS = 'SET_FORM_TRIGGERS';
export function setTriggers(triggers) {
    return {
        type: SET_FORM_TRIGGERS,
        triggers,
    };
}

export const SET_TRIGGERED_STATE = 'SET_TRIGGERED_STATE';
export function setTriggeredState({ id, triggered }) {
    return {
        type: SET_TRIGGERED_STATE,
        payload: {
            id,
            triggered,
        },
    };
}

export const SET_TRIGGERED_STATES = 'SET_TRIGGERED_STATES';
export function setTriggeredStates(states) {
    return {
        type: SET_TRIGGERED_STATES,
        states,
    };
}

export const CLEAR_TRIGGERED_STATES = 'CLEAR_TRIGGERED_STATES';
export function clearTriggeredStates() {
    return {
        type: CLEAR_TRIGGERED_STATES,
    };
}

export const SET_TRIGGER_DEPENDANT = 'SET_TRIGGER_DEPENDANT';
export function setTriggeredDependant({ id, dependant }) {
    return {
        type: SET_TRIGGER_DEPENDANT,
        payload: {
            id,
            dependant,
        },
    };
}

export const SET_TRIGGER_DEPENDANTS = 'SET_TRIGGER_DEPENDANTS';
export function setTriggeredDependants(dependants) {
    return {
        type: SET_TRIGGER_DEPENDANTS,
        dependants,
    };
}
