import {
    SET_PARAMETERS,
    SET_PARAMETERS_FETCHING,
    SET_CONFIGURATION_STATE,
} from '../actions/ConfigurationAction';

const PARAMETERS_INITIAL_STATE = {
    data: null,
    fetching: false,
};

const INITIAL_STATE = {
    currentConfiguration: 'role',
    parameters: PARAMETERS_INITIAL_STATE,
};

function parameterReducer(state = PARAMETERS_INITIAL_STATE, action) {
    switch (action.type) {
    case SET_PARAMETERS:
        state = {
            ...state,
            data: action.data,
        };
        break;
    case SET_PARAMETERS_FETCHING:
        state = {
            ...state,
            fetching: action.fetching,
        };
    }
    return state;
}

export default function configurationReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
    case SET_CONFIGURATION_STATE: {
        return {
            ...state,
            ...action.payload,
        };
    }
    default:
        state = {
            ...state,
            ...{ parameters: parameterReducer(state.parameters, action) },
        };
        return state;
    }
}
