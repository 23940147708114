/**
 * A method to extract protocol, domain and port of current URL.
 * @param {string} url - A url passed as argument, if not passed, will use current url as default.
 * @returns {Object} protocol, domain and port.
 */
export default function parseUrl(url = window.location.href) {
    const parser = document.createElement('a');
    parser.href = url;

    return {
        protocol: parser.protocol,
        domain: parser.hostname,
        port: parser.port,
    };
}
