import React from 'react';
import _ from 'underscore';
import DOMPurify from 'dompurify';

import { compose, pure } from 'recompose';
import { Label as SemanticLabel } from 'semantic-ui-react';
import { FieldAction } from '../Misc/template';
import { withCaptureFieldHandlers } from '../Misc/forms';

const COLOURS = ['black', 'red', 'yellow', 'green'];

class Label extends React.Component {
    static defaultProps = {
        className: '',
        fieldname: {},
        isValid: true,
    };

    render() {
        const classWarning = 'warning';
        let {
            type,
            className,
            // option
            colour,
            title,
            html,
            name,
            size,
            align,
            lineHeight,
            bold,
            italic,
            underlined,
            capitalize,
            isValid,
            errorMessage,
            // utils
            showComponent = true,
            path,
        } = this.props;
        const option = {
            type,
            title,
            name,
            size,
            align,
            lineHeight,
            bold,
            italic,
            underlined,
            capitalize,
        };
        colour = COLOURS.includes(colour) ? colour : undefined;
        const classNames = [
            'jpt--input input__label',
            _.isString(className) && className,
            !isValid && classWarning,
            bold && 'bold',
            italic && 'italic',
            underlined && 'underlined',
            colour ? colour : '',
            typeof align === 'string' ? `align-${ align }` : 'align-left',
        ];

        lineHeight = typeof lineHeight === 'number' ? `${ lineHeight }px` : lineHeight;
        // sanitize html
        if (html) html = DOMPurify.sanitize(html, { SAFE_FOR_JQUERY: true, ADD_ATTR: ['target'] });

        if (!showComponent || (!title && !html)) return <div />;

        return (
            <div className={classNames.join(' ')}>
                {title ? (
                    <SemanticLabel
                        size={size}
                        basic
                        className="input__label--text"
                        style={{
                            lineHeight: lineHeight,
                            textTransform: capitalize ? 'capitalize' : 'none',
                        }}>
                        {title}
                    </SemanticLabel>
                ) : (
                    <div className="html-wrapper" dangerouslySetInnerHTML={{ __html: html }} />
                )}

                <div className={'input__message ' + (errorMessage ? 'input__message--error' : '')}>
                    {errorMessage}
                </div>

                {this.props.isEditingField && <FieldAction path={path} field={option} />}
            </div>
        );
    }
}

export default pure(compose(withCaptureFieldHandlers)(Label));

export { Label };
