import { connect } from 'react-redux';
import { session } from '../SessionStorage';
import { actionGetClient, setClient } from '../../redux/actions/ClientAction';
import _ from 'underscore';
import { compose, lifecycle } from 'recompose';

const DEFAULT_PRIMARY_COLOR = '#ffffff';
const DEFAULT_SECONDARY_COLOR = '#ffffff';
const CSS_SKIN_ID = 'client-skin';

const mapStateToProps = ({ client = {} }) => {
    return {
        client,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getCurrentClient() {
            dispatch(actionGetClient());
        },
        setCurrentClient(client) {
            dispatch(setClient(client));
        },
    };
};

export const withClient = compose(
    connect(mapStateToProps, mapDispatchToProps),
    lifecycle({
        componentDidMount() {
            const sessionClient = session.getClient();
            const { primaryColor, secondaryColor } = sessionClient || {};
            if (!_.isEmpty(sessionClient)) {
                createSkinCSS(primaryColor, secondaryColor);
            }
            if (!_.isEmpty(sessionClient) && !_.isEqual(sessionClient, this.props.client)) {
                this.props.setCurrentClient({
                    ...sessionClient,
                    logo: sessionClient.logo,
                });
            }
        },
        componentDidUpdate(prevProps) {
            if (!_.isEqual(this.props.client, prevProps.client)) {
                const { primaryColor, secondaryColor } = this.props.client || {};
                if (!primaryColor && !secondaryColor) {
                    // default value
                    removeSkinCSS();
                } else {
                    updateSkinCSS(primaryColor, secondaryColor);
                }
            }
        },
    }),
);

function updateSkinCSS(primaryColor, secondaryColor) {
    removeSkinCSS();
    createSkinCSS(primaryColor, secondaryColor);
}

function removeSkinCSS() {
    if (document.getElementById(CSS_SKIN_ID)) {
        document.getElementById(CSS_SKIN_ID).remove();
    }
}

export function createSkinCSS(primaryColor, secondaryColor) {
    // to handle empty string '' value
    primaryColor = primaryColor || DEFAULT_PRIMARY_COLOR;
    secondaryColor = secondaryColor || DEFAULT_SECONDARY_COLOR;
    if (document.getElementById(CSS_SKIN_ID)) return;

    const css = document.createElement('style');
    css.type = 'text/css';
    css.id = CSS_SKIN_ID;

    let styles = '';
    // Decision to change footer color and use secondory color
    if (primaryColor && primaryColor !== DEFAULT_PRIMARY_COLOR) {
        styles += `
            .navbar {
                background-color: ${ primaryColor } !important;
            }
            /* dashboard */
            .input__table table .table__column-clickable:hover {
                color: ${ primaryColor } !important;
            }
            .table-paging .page.active {
                color: ${ primaryColor } !important;
            }
            .table-paging .page:not(.disabled):hover {
                color: ${ primaryColor } !important;
                opacity: 0.85;
            }
            .warning-unsaved-form-link {
                color: ${ primaryColor } !important;
            }
            .warning-unsaved-form-link:hover,
            .warning-unsaved-form-link:active,
            .warning-unsaved-form-link:focus {
                color: ${ primaryColor } !important;
                opacity: 0.85;
            }

            /* form */
            .form .form--header .header--tabs .tab__wrapper.tab__wrapper-active .tab__item,
            .entry__modal .action__form .section .form--header .header--tabs .tab__wrapper.tab__wrapper-active .tab__item {
                color: ${ primaryColor } !important;
            }

            .jpt--input button {
                background-color: ${ primaryColor } !important;
            }
            .jpt--input:not(.button__cancel):not(.button--secondary):not(.button__remove) button:hover,
            .jpt--input:not(.button__cancel):not(.button--secondary):not(.button__remove) button:active,
            .jpt--input:not(.button__cancel):not(.button--secondary):not(.button__remove) button:focus,
            .jpt--input:not(.button__cancel):not(.button--secondary):not(.button__remove) .ui.button:hover,
            .jpt--input:not(.button__cancel):not(.button--secondary):not(.button__remove) .ui.button:active,
            .jpt--input:not(.button__cancel):not(.button--secondary):not(.button__remove) .ui.button:focus  {
                background-color: ${ primaryColor } !important;
                opacity: 0.85;
            }
            .jpt--input button:hover,
            .jpt--input button:active,
            .jpt--input button:focus,
            .jpt--input .ui.button:hover,
            .jpt--input .ui.button:active,
            .jpt--input .ui.button:focus {
                opacity: 0.85;
            }
            .jpt--input:not(.noteditable) .checkbox-box .checked-box-shape.radio {
                background-color: ${ primaryColor } !important;
            }
            .jpt--input:not(.noteditable) .checkbox-box .checked-box-shape {
                border-color: ${ primaryColor } !important;
            }
            .jpt--input.button__cancel > button {
                color: ${ primaryColor } !important;
                border-color: ${ primaryColor } !important;
            }
            .dropdown-item.selected {
                color: ${ primaryColor } !important;
            }
            .dropdown-item-wrapper {
                border-right-color: ${ primaryColor } !important;
                border-bottom-color: ${ primaryColor } !important;
                border-left-color: ${ primaryColor } !important;
            }
            .jpt--input input:active,
            .jpt--input input:focus,
            .jpt--input select:active,
            .jpt--input select:focus,
            .jpt--input option:active,
            .jpt--input option:focus,
            .jpt--input div[role='combobox']:active,
            .jpt--input div[role='combobox']:focus {
                border-color: ${ primaryColor } !important;
            }
            .jpt--input.input__textarea > textarea:focus,
            .jpt--input.input__textarea > textarea:active {
                border-color: ${ primaryColor } !important;
            }
            .calendar-popup .calendar-column-day.active,
            .calendar-popup .calendar-column-month.active,
            .calendar-popup .calendar-column-year.active,
            .calendar-popup .calendar-column-hour.active,
            .calendar-popup .calendar-column-minute.active,
            .calendar-popup .calendar-column-day:not(.disabled):not(.header):hover,
            .calendar-popup .calendar-column-month:not(.disabled):hover,
            .calendar-popup .calendar-column-year:not(.disabled):hover,
            .calendar-popup .calendar-column-hour:not(.disabled):hover,
            .calendar-popup .calendar-column-minute:not(.disabled):hover {
                background-color: ${ primaryColor } !important;

            }
            .jpt--input.input__tab .menu .item.active {
                color: ${ primaryColor } !important;
                border-bottom-color: ${ primaryColor } !important;
            }
        `;
        if (!isLightColor(primaryColor)) {
            styles += `
            .navbar .menu__item img{
                filter: brightness(0) invert(0.9);
            }
            `;
        } else {
            styles += `
            .navbar .menu__item img{
                filter: brightness(0) invert(0.4);
            }
            `;
        }
    }

    // Decision to change footer color and use secondory color
    if (secondaryColor && secondaryColor !== DEFAULT_SECONDARY_COLOR) {
        styles += `
            .footer {
                background-color: ${ secondaryColor } !important;
            }
            /* breadcrumb */
            .breadcrumb__item a {
                color: ${ secondaryColor } !important;
            }
            .breadcrumb__item.item__arrow {
                color: ${ secondaryColor } !important;
            }
        `;
        if (!isLightColor(secondaryColor)) {
            styles += `
            .footer {
                color: white !important;
            }
            `;
        } else {
            styles += `
            .footer {
                color: black !important;
            }
            `;
        }
    }

    if (css.styleSheet) {
        css.styleSheet.cssText = styles;
    } else {
        css.appendChild(document.createTextNode(styles));
    }

    document.getElementsByTagName('head')[0].appendChild(css);
}

export function generateLogoPath(url) {
    // need to clean value for redux store.
    // e.g. http://localhost:8000/cap/capture-media/client/logos/logo.png
    // -> capture-media/client/logos/logo.png
    const matched = url.match(/^https?:\/\/[\w\-\:]+\/cap\/(.+)/);

    return matched ? matched[1] : url;
}

// https://stackoverflow.com/questions/12043187/how-to-check-if-hex-color-is-too-black
/**
 * A method that check whether the given hex color code is light color
 * @param {string} color - #hex color
 * @returns {boolean} isLightColor
 */
function isLightColor(color) {
    const c = color.substring(1); // strip #
    const rgb = parseInt(c, 16); // convert rrggbb to decimal
    const r = (rgb >> 16) & 0xff; // extract red
    const g = (rgb >> 8) & 0xff; // extract green
    const b = (rgb >> 0) & 0xff; // extract blue

    const luma = 0.2 * r + 0.5 * g + 0.2 * b; // adjusted based on John's preference

    return luma > 125;
}
