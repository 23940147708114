import _ from 'underscore';
import * as FR from '../../Form/FormRules';
/**
 * A method that get triggers in a form
 * @param {Object} form - form object
 * @returns {Array} triggers [{ target, type, data }]
 */
function getFormTriggers(form) {
    const triggers = [];
    loop(form);
    return triggers;

    function loop(obj) {
        if (!_.isObject(obj)) return;
        Object.entries(obj).forEach(child => {
            const value = child[1];
            const { type, id, trigger } = value || {};
            if (FR.isCaptureFieldType(type) && trigger) {
                triggers.push({ target: id, type: 'field', data: trigger });
            } else if (type === FR.fieldsetKey && trigger) {
                triggers.push({ target: id, type: 'fieldset', data: trigger });
                loop(value);
            } else {
                loop(value);
                return;
            }
        });
    }
}

export default getFormTriggers;
