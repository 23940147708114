import {
    sectionKey,
    gridKey,
    fieldsetKey,
    labelKey,
    textKey,
    selectKey,
    radioKey,
    checkboxKey,
    buttonKey,
    calendarKey,
    textAreaKey,
    remarksKey,
    breakLineKey,
    hoTableKey,
    hoTableColumnKey,
} from '../../Form/FormRules';

const PresetFieldObject = {
    [labelKey]: {
        type: labelKey,
        name: 'Label',
        title: 'Label or Text',
    },
    [textKey]: {
        type: textKey,
        name: 'Input Field',
        [labelKey]: { type: 'outer', title: 'Title', position: 'top' },
        title: 'Title',
        value: '',
        placeholder: 'placeholder',
    },
    [selectKey]: {
        type: selectKey,
        name: 'Dropdown',
        [labelKey]: { title: 'Dropdown' },
        name: 'dropdown',
        options: [
            { value: 'option1', name: 'option1', title: 'Option1' },
            { value: 'option2', name: 'option2', title: 'Option2' },
        ],
        value: 'option1',
    },
    [radioKey]: {
        type: radioKey,
        name: 'RadioGroup',
        [labelKey]: { title: 'Radio' },
        name: 'radioGroup',
        options: [
            { name: 'yes', title: 'Yes', value: 'yes' },
            { name: 'no', title: 'No', value: 'no' },
        ],
        value: 'yes',
    },
    [checkboxKey]: {
        type: checkboxKey,
        name: 'CheckboxGroup',
        [labelKey]: {
            title: 'Checkbox',
            position: 'top',
        },
        options: [
            { name: 'option', title: 'Option', value: 'option' },
            { name: 'other', title: 'Other', value: 'other' },
        ],
        value: [],
    },
    [buttonKey]: {
        type: buttonKey,
        title: 'Button',
        name: 'Button',
        icon: {
            name: 'search',
            position: 'right',
        },
    },
    [calendarKey.default]: {
        type: calendarKey.default,
        name: 'Calendar',
        calendarType: calendarKey.datetime,
        [labelKey]: {
            title: 'Datetime/Date/Time',
            position: 'top',
        },
        placeholder: 'YYYY/MM/DD HH:MM A',
    },
    [textAreaKey]: {
        type: textAreaKey,
        name: 'Textarea',
        [labelKey]: {
            title: 'Textarea',
        },
        placeholder: 'textarea',
    },
    [remarksKey]: {
        type: remarksKey,
        name: 'Remarks',
        [labelKey]: {
            title: 'Remarks',
        },
        history: [], // { value, date }
        value: '',
        placeholder: 'Remarks',
    },
    [breakLineKey]: {
        type: breakLineKey,
        name: 'Break Line',
    },
    [hoTableKey]: {
        type: hoTableKey,
        name: 'Table',
        title: 'Record',
        fields: [
            {
                type: hoTableColumnKey,
                id: 'default',
                name: 'Column 1',
            },
        ],
        minRows: 4,
        width: 200,
        height: 100,
    },
};

export const DefaultFieldObject = {
    [labelKey]: PresetFieldObject[labelKey],
    [textKey]: PresetFieldObject[textKey],
    [selectKey]: PresetFieldObject[selectKey],
    [radioKey]: PresetFieldObject[radioKey],
    [checkboxKey]: PresetFieldObject[checkboxKey],
    [buttonKey]: PresetFieldObject[buttonKey],
    [calendarKey.default]: PresetFieldObject[calendarKey.default],
    [textAreaKey]: PresetFieldObject[textAreaKey],
    [remarksKey]: PresetFieldObject[remarksKey],
    [breakLineKey]: PresetFieldObject[breakLineKey],
    [hoTableKey]: PresetFieldObject[hoTableKey],
};

export const DefaultGridObject = {
    type: gridKey,
    fields: [],
};

export const DefaultFieldsetObject = {
    type: fieldsetKey,
    name: 'Fieldset',
    grids: [
        {
            ...DefaultGridObject,
        },
    ],
};

export const DefaultSectionObject = {
    type: sectionKey,
    title: 'Section Title',
    name: 'Section',
    fieldsets: [
        {
            ...DefaultFieldsetObject,
        },
    ],
};
