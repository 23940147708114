import React from 'react';

import _ from 'underscore';
import moment from 'moment-timezone';

import Calendar, { FORMAT } from './Calendar';
import ErrorMessage from './ErrorMessage';
import JPLabel from '../Label';

import { compose, withProps, withStateHandlers, lifecycle } from 'recompose';
import { FieldAction } from '../../Misc/template';
import { withCaptureFieldHandlers } from '../../Misc/forms';
import { toDate, validateCalendarInput } from './misc';

const INPUTTABLE_CALENDAR_TYPES = {
    date: true,
    year: true,
    monthYear: true,
};

const BaseCalendarHOC = Component =>
    class BaseCalendar extends React.Component {
        topClass = 'input__label--top';
        classWarning = 'warning';

        static defaultProps = {
            className: '',
            fieldname: {},
            label: {},
            note: {},
            placeholder: 'Date/Time',
            isValid: true,
        };

        constructor(props) {
            super(props);
            this.props = props;
            this.handleChange = this.props.handleChange.bind(this);
        }

        render() {
            let {
                id,
                className,
                // option
                type,
                title,
                name,
                value,
                note,
                label,
                calendarType,
                format,
                position,
                placeholder,
                isValid,
                isEditable,
                errorMessage,
                onChange,
                today,
                maxDate,
                minDate,
                isValidCalendar,
                // utils
                showComponent,
                path,
                timezone,
            } = this.props;
            const option = {
                type,
                title,
                name,
                value,
                note,
                label,
                calendarType,
                format,
                position,
                placeholder,
                onChange,
                today,
            };
            const classNames = [
                'jpt--input input__calendar-date',
                _.isString(className) && className,
                !isValid && this.classWarning,
                (!isValid || !isValidCalendar.isValid) && this.classWarning,
                !value && !isEditable && 'null-value',
            ];

            if (!showComponent) return <div />;

            if (!errorMessage && !isValidCalendar.isValid) {
                errorMessage = isValidCalendar.errorMessage;
            }

            return (
                <div
                    ref={field => {
                        this.field = field;
                    }}
                    id={id}
                    className={classNames.join(' ')}>
                    {!_.isEmpty(label) && <JPLabel className={this.topClass} {...label}></JPLabel>}

                    <Component
                        type={calendarType}
                        note={note}
                        value={value}
                        icon={this.iconName}
                        placeholder={placeholder}
                        today={today}
                        minDate={minDate}
                        maxDate={maxDate}
                        timezone={timezone}
                        format={format}
                        isEditable={isEditable}
                        isInputtable={INPUTTABLE_CALENDAR_TYPES[calendarType]}
                        onChange={(e, data) => {
                            const { value, inputValue } = data;

                            // Perform component validation before reformatted to ISOString
                            if (INPUTTABLE_CALENDAR_TYPES[calendarType]) {
                                const datetimeFormat = format || FORMAT[calendarType];

                                const { isValid, errorMessage } = validateCalendarInput({
                                    value: inputValue,
                                    format: datetimeFormat,
                                    type: calendarType,
                                    strict: true,
                                });

                                this.props.setIsValidCalendar({
                                    isValid,
                                    errorMessage,
                                });
                            }

                            // If selected datepicker, will convert value into iso string
                            if (inputValue) {
                                const isValid = moment(inputValue, format, true).isValid();
                                if (isValid) {
                                    // keep ISO formatted datetime
                                    data.value = moment(inputValue, format)
                                        .tz(timezone)
                                        .toISOString();
                                }
                                // keep invalid date e.g. '12/12/20'
                                data.value = inputValue;
                            } else if (value) {
                                data.value = moment.tz(value, timezone).toISOString();
                            }

                            if (today && !value) data.value = moment().toISOString();

                            onChange && onChange(e, data);
                            this.handleChange(e, data);
                        }}
                    />
                    <ErrorMessage message={errorMessage} />

                    {this.props.isEditingField && <FieldAction path={path} field={option} />}
                </div>
            );
        }
    };

/**
 * HOC to enhance component withCaptureFieldHandlers
 */
const enhance = compose(
    withCaptureFieldHandlers,
    withStateHandlers(
        ({
            isValidCalendar = {
                isValid: true,
                errorMessage: '',
            },
        }) => ({
            isValidCalendar,
        }),
        {
            setIsValidCalendar: () => isValidCalendar => ({
                isValidCalendar,
            }),
        },
    ),
    // run minDate and maxDate format checking
    withProps(({ minDate, maxDate }) => {
        minDate = toDate(minDate, 'minDate');
        maxDate = toDate(maxDate, 'maxDate');
        return {
            minDate,
            maxDate,
        };
    }),
    lifecycle({
        componentDidUpdate({ isEditable: prevIsEditable }) {
            const { isEditable, setIsValidCalendar } = this.props;
            // clear calendar state's error message if it's disabled
            if (!isEditable && !_.isEqual(isEditable, prevIsEditable)) {
                setIsValidCalendar({ isValid: true, errorMessage: '' });
            }
        },
    }),
);

/**
 * Enhanced Calendar with Capture Field Handler
 */
const EnhancedCalendar = enhance(Calendar);

/**
 * EnhancedCalendar as default export
 */
export default enhance(BaseCalendarHOC(Calendar));

export { EnhancedCalendar, Calendar };
