import React from 'react';
import CheckboxGroup from './CheckboxGroup';
import PropTypes from 'prop-types';
import { isObject, isArray, isFunction, noop } from 'underscore';
import store from '../../store';

import { getValues, getTriggerDependants, getTriggers } from '../../redux/selectors';
import { getLists } from '../../redux/selectors/ListsSelector';
import { setValues } from '../../redux/actions/ValueAction';

const PRODUCT_TYPES_ID = 'product_types';

const ProductTypes = props => {
    return (
        <CheckboxGroup
            {...props}
            onChange={(e, data) => {
                // remove value
                if (data.value.length) {
                    const state = store.getState();
                    const values = getValues(state);
                    const dependants = getTriggerDependants(state)['product_types'] || [];
                    // filter out dependants that's conditionally depending product_types field
                    const triggers = getTriggers(state);
                    const filteredDependants =
                        findNonConditionalDependants({ dependants, triggers }) || [];
                    let productTypes = data.value;
                    let products = getLists(state)['products'] || [];
                    products = products.map(p => p.value);
                    productTypes = products.filter(p => productTypes.indexOf(p) > -1);
                    // remove
                    const newValues = removeUnnecessaryDependantsRecord({
                        productTypes,
                        values,
                        dependants: filteredDependants,
                    });
                    store.dispatch(setValues(newValues));
                }

                isFunction(props.onChange) && props.onChange(e, data);
            }}
        />
    );
};

ProductTypes.propTypes = {
    id: PropTypes.string,
    // some default value in config is set to string
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    onChange: PropTypes.func,
};

ProductTypes.defaultProps = {
    id: PRODUCT_TYPES_ID,
    value: [],
    onChange: noop,
};

export function removeUnnecessaryDependantsRecord({ productTypes, values, dependants }) {
    let newValues = {},
        valueIsArray = false;
    if (isArray(values)) {
        valueIsArray = true;
        newValues = [];
    }

    Object.entries(values).forEach(([key, value]) => {
        let newValue;

        if (isObject(value)) {
            // found product types depandant field
            if (dependants.indexOf(key) > -1) {
                if (value.length > productTypes.length) {
                    // remove unusable record
                    const filtered = value.filter(r => {
                        return r && productTypes.indexOf(r.product) > -1;
                    });
                    newValue = filtered;
                } else {
                    // add empty records
                    const valueOnlyProduct = value.map(v => v && v.product);
                    const newProducts = productTypes.map(p => {
                        const index = valueOnlyProduct.indexOf(p);
                        if (index > -1) {
                            return value[index];
                        } else {
                            // return back value e.g. { product: 'visa_mastercard' }
                            return {
                                product: p,
                            };
                        }
                    });
                    newValue = newProducts;
                }
            } else {
                // recursive here
                newValue = removeUnnecessaryDependantsRecord({
                    productTypes,
                    values: value,
                    dependants,
                });
            }
        } else {
            newValue = value;
        }

        if (valueIsArray) newValues.push(newValue);
        else newValues[key] = newValue;
    });

    return newValues;
}

/**
 * A method that filter out dependants field id that's does has any condition
 * e.g terminal_configurations
 * triggers: {
 *   terminal_configurations: {
 *     data: { by: 'field_id' }
 *   }
 * }
 * @param {Object} {
 *   dependants - list of field ids of field dependant
 *   triggers - trigger object { field_id: { data, ... }}
 * }
 * @returns {string[]} filteredNonConditionalDependants
 */
export function findNonConditionalDependants({ dependants = [], triggers = {} }) {
    return dependants.filter(id => {
        const { data } = triggers[id] || {};
        let { by, when } = data || {};
        if (!by || !data || when) return false;

        by = !isArray(by) ? [by] : by;
        const foundAnyKey = by.filter(id => data[id]).length;
        return !foundAnyKey;
    });
}

export default ProductTypes;
