import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import BetaTag from './BetaTag';
import SETTINGS from '../settings';
import { withRouter } from 'react-router-dom';

import { FeaturesMapFunction, Features } from './Misc';

import { compose } from 'recompose';
import { connect } from 'react-redux';

import { loadMyFeature } from '../redux/actions/UserAction';
import { withToken } from './Misc/authentication';

class Menu extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    componentDidMount() {
        if (this.props.isLoggedIn) this.props.getRoleFeatures();
    }

    render() {
        const { featureGroups, features, history, showBeta } = this.props;

        if (!featureGroups.length) {
            return <div />;
        }

        const menuComponent = Object.entries(featureGroups).map((featureGroup, index) => {
            const feature = FeaturesMapFunction(featureGroup, _.indexBy(features, 'id'));
            const ViewDashboard = feature.type === Features.viewDashboard;
            if (!feature || ViewDashboard) return <div key={index} />;
            if (!feature.onClick) return;

            const featureName = feature.name;
            const data = {
                params: {
                    templateId: feature.templateId,
                    accessId: feature.accessId,
                },
                page: feature.link,
            };

            return (
                <div className="menu__item" key={index} data-rh={featureName} data-rh-at="right">
                    <img
                        className={'menu__icon icon_' + feature.icon.name}
                        src={feature.icon.path}
                        onClick={e => {
                            _.isFunction(feature.onClick) && feature.onClick(e, data, history);
                        }}
                        alt={featureName}
                        title={featureName}
                    />
                    {showBeta && feature.isBeta && <BetaTag />}
                </div>
            );
        });

        return <div className="menu__list">{menuComponent}</div>;
    }
}

const mapStateToProps = ({ user }) => {
    return {
        features: user.features,
        featureGroups: user.featureGroups,
        showBeta: SETTINGS.SHOW_BETA_TAG,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getRoleFeatures: () => {
            dispatch(loadMyFeature());
        },
    };
};

export { Menu };

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withToken,
    withRouter,
)(Menu);
