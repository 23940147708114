import _ from 'underscore';
import captureEntryAPI from '../../api/CaptureEntryAPI';

export const SET_LIST_ITEMS = 'SET_LIST_ITEMS';
export const setListItems = ({ selectedTab, listItems, listItemsOrder }) => {
    return {
        type: SET_LIST_ITEMS,
        selectedTab,
        listItems,
        listItemsOrder,
    };
};

export const SET_LIST_ITEM = 'SET_LIST_ITEM';
export const setListItem = ({ selectedTab, uid, listItem }) => {
    return {
        type: SET_LIST_ITEM,
        selectedTab,
        uid,
        listItem,
    };
};

export const UPDATE_LIST_ITEM = 'UPDATE_LIST_ITEM';
export const updateListItem = ({ selectedTab, uid, listItem }) => {
    return {
        type: UPDATE_LIST_ITEM,
        selectedTab,
        uid,
        listItem,
    };
};

export const SET_SUCCESS_MESSAGE = 'SET_SUCCESS_MESSAGE';
export const setSuccessMsg = ({ selectedTab, successMsg }) => {
    return {
        type: SET_SUCCESS_MESSAGE,
        selectedTab,
        successMsg,
    };
};

export const TOGGLE_CHECKBOX_ENTRY_LISTS = 'TOGGLE_CHECKBOX_ENTRY_LISTS';
export const actionToggleSelectedEntryLists = ({ selectedTab, selectedEntryLists }) => {
    return {
        type: TOGGLE_CHECKBOX_ENTRY_LISTS,
        selectedTab,
        selectedEntryLists,
    };
};

export const SET_FIELD_TYPE = 'SET_FIELD_TYPE';
export const actionSetFieldType = ({ selectedTab, selectedFieldType }) => {
    return {
        type: SET_FIELD_TYPE,
        selectedTab,
        selectedFieldType,
    };
};

export const SET_SEARCH_INPUT = 'SET_SEARCH_INPUT';
export const actionSetSearchInput = ({ selectedTab, searchInput }) => {
    return {
        type: SET_SEARCH_INPUT,
        selectedTab,
        searchInput,
    };
};

export const SET_SEARCHED_INPUT = 'SET_SEARCHED_INPUT';
export const actionSetSearchedInput = ({ selectedTab, searchedInput }) => {
    return {
        type: SET_SEARCHED_INPUT,
        selectedTab,
        searchedInput,
    };
};

export const SET_PAGE = 'SET_PAGE';
export const actionSetPage = ({ selectedTab, page }) => {
    return {
        type: SET_PAGE,
        selectedTab,
        page,
    };
};

export const SET_SORTING = 'SET_SORTING';
export const actionSetSorting = ({ selectedTab, sorted }) => {
    return {
        type: SET_SORTING,
        selectedTab,
        sorted,
    };
};

export const SET_TABLE_DATA_FETCHING = 'SET_TABLE_DATA_FETCHING';
export const setCapEntryFetching = fetching => {
    return {
        type: SET_TABLE_DATA_FETCHING,
        fetching,
    };
};

export const SET_TABLE_DATA = 'SET_TABLE_DATA';
export const setTableData = ({
    selectedTab,
    listItems,
    listItemsOrder,
    searchedInput,
    page,
    totalPages,
}) => {
    return {
        type: SET_TABLE_DATA,
        selectedTab,
        listItems,
        listItemsOrder,
        page,
        totalPages,
        searchedInput,
    };
};

export const SET_ERROR = 'SET_ERROR';
export const setError = ({ selectedTab, error }) => {
    return {
        type: SET_ERROR,
        selectedTab,
        error,
    };
};

export const SET_SELECTED_TAB_DETAILS = 'SET_SELECTED_TAB_DETAILS';
export const actionSetSelectedTab = selectedTab => {
    return {
        type: SET_SELECTED_TAB_DETAILS,
        selectedTab,
    };
};

export const SET_ENTRY_LISTS = 'SET_ENTRY_LISTS';
export const setEntryLists = ({ selectedTab, entryLists, entryListsOrder }) => {
    return {
        type: SET_ENTRY_LISTS,
        selectedTab,
        entryLists,
        entryListsOrder,
    };
};

function getEntryListName({ listItems, uid, entryLists }) {
    const selectedListItem = listItems[uid];
    const selectedEntryList = entryLists[selectedListItem.entry_list];
    const entryListName = selectedEntryList && selectedEntryList.name;
    return entryListName;
}

export const actionGetListItem = ({ selectedTab, uid }) => {
    return async dispatch => {
        dispatch(setCapEntryFetching(true));
        try {
            const { list_item } = await captureEntryAPI.getListItem({ selectedTab, uid });
            dispatch(setListItem({ selectedTab, uid, listItem: list_item }));
        } catch (error) {
            dispatch(setError({ selectedTab, error: error.message }));
        } finally {
            dispatch(setCapEntryFetching(false));
        }
    };
};

export const actionCreateListItem = ({ selectedTab, listItem }) => {
    return async (dispatch, getState) => {
        const { entryLists } = getState().captureEntry[selectedTab];
        dispatch(setCapEntryFetching(true));
        try {
            const { list_item: createdListItem } = await captureEntryAPI.createListItem({
                selectedTab,
                listItem,
            });
            const { entry_list } = createdListItem;
            const entryListName = entryLists[entry_list] && entryLists[entry_list].name;
            const successMsg = `Successfully created ${ entryListName } entry`;
            dispatch(setSuccessMsg({ selectedTab, successMsg }));
        } catch (error) {
            dispatch(setError({ selectedTab, error: error.message }));
        } finally {
            dispatch(setCapEntryFetching(false));
        }
    };
};

export const actionUpdateListItem = ({ selectedTab, uid, listItem }) => {
    return async (dispatch, getState) => {
        const { listItems, entryLists } = getState().captureEntry[selectedTab];
        dispatch(setCapEntryFetching(true));
        try {
            const entryListName = getEntryListName({ listItems, uid, entryLists });

            const { list_item } = await captureEntryAPI.updateListItem({
                selectedTab,
                uid,
                listItem,
            });
            dispatch(updateListItem({ selectedTab, uid, listItem: list_item }));
            const successMsg = `Successfully updated ${ entryListName } entry`;
            dispatch(setSuccessMsg({ selectedTab, successMsg }));
        } catch (error) {
            dispatch(setError({ selectedTab, error: error.message }));
        } finally {
            dispatch(setCapEntryFetching(false));
        }
    };
};

export const actionDeleteListItem = ({ selectedTab, uid, delete_reason, delete_remarks }) => {
    return async (dispatch, getState) => {
        let { listItemsOrder, listItems, entryLists } = getState().captureEntry[selectedTab];
        dispatch(setCapEntryFetching(true));
        try {
            const entryListName = getEntryListName({ listItems, uid, entryLists });

            await captureEntryAPI.deleteListItem({
                selectedTab,
                uid,
                delete_reason,
                delete_remarks,
            });
            delete listItems[uid];
            listItemsOrder = _.without(listItemsOrder, uid);

            const successMsg = `Successfully removed ${ entryListName } entry`;

            dispatch(setListItems({ selectedTab, listItems, listItemsOrder }));
            dispatch(setSuccessMsg({ selectedTab, successMsg }));
        } catch (error) {
            dispatch(setError({ selectedTab, error: error.message }));
        } finally {
            dispatch(setCapEntryFetching(false));
        }
    };
};

// When user performs search query
export const actionGetTableQuery = ({
    selectedEntryLists,
    page,
    fieldType,
    searchedInput,
    sorted,
    selectedTab,
}) => {
    // will use the search endpoint
    return async dispatch => {
        dispatch(setCapEntryFetching(true));
        try {
            const { list_items, meta } = await captureEntryAPI.getTableQuery({
                selectedEntryLists,
                page,
                fieldType,
                searchedInput,
                sorted,
                selectedTab,
            });
            const { current_page, total_pages } = meta;
            const listItems = _.indexBy(list_items, 'uid');
            const listItemsOrder = _.pluck(list_items, 'uid');
            dispatch(
                setTableData({
                    selectedTab,
                    listItems,
                    listItemsOrder,
                    searchedInput,
                    page: current_page,
                    totalPages: total_pages,
                }),
            );
        } catch (error) {
            dispatch(setError({ selectedTab, error: error.message }));
        } finally {
            dispatch(setCapEntryFetching(false));
        }
    };
};

// Fetches entry lists of both tabs
export const actionGetEntryLists = selectedTab => {
    return async dispatch => {
        dispatch(setCapEntryFetching(true));
        try {
            const { entry_lists } = await captureEntryAPI.getEntryLists(selectedTab);

            const entryLists = _.indexBy(entry_lists, 'uid');
            const entryListsOrder = _.pluck(entry_lists, 'uid');
            dispatch(setEntryLists({ entryLists, entryListsOrder, selectedTab }));
        } catch (error) {
            dispatch(setError({ selectedTab, error: error.message }));
        } finally {
            dispatch(setCapEntryFetching(false));
        }
    };
};
