import $ from 'jquery';
import { STATUS_CODE, HTTP_CODE, ERROR_MESSAGES } from '../components/Misc/api';
import { getCookie } from '../components/Cookie';
import SessionStorage from '../components/SessionStorage';
import {
    actionClearSession,
    actionForbiddenAcess,
    actionShowError,
    actionAPIError,
    enforceChangePassword,
} from '../redux/actions/PageAction';
import SETTINGS from '../settings';

export default class BaseAPI {
    sessionStorage = new SessionStorage();
    token = this.sessionStorage.getToken();

    constructor({ host = '', headers = {} } = {}) {
        this.host = host || SETTINGS.API_URL;
        this.tokenHeader = this.token
            ? {
                Authorization: this.token,
            }
            : {};
        this.headers = {
            ...headers,
            ...this.tokenHeader,
        };
        $.support.cors = true;
    }

    _requestBuilder(
        method,
        {
            path = '/',
            data = null,
            contentType = 'application/json',
            dataType = 'json',
            successCallback,
            failCallback,
        },
    ) {
        const dashboardAccess = this.sessionStorage.getDashboardId();
        const authorizationToken = {
            Authorization: this.sessionStorage.getToken(),
        };
        return $.ajax({
            type: method,
            contentType: contentType,
            url: this.host + path,
            data: data ? JSON.stringify(data) : false,
            dataType: dataType,
            headers: {
                'capture-delegated-access': dashboardAccess,
                'X-CSRFToken': getCookie('csrftoken'),
                ...this.headers,
                ...authorizationToken,
            },
        })
            .done((data, textStatus, jqXHR) => {
                if (dataType === 'json') {
                    if (data[STATUS_CODE.CODEKEY] === STATUS_CODE.FAIL) {
                        const message = `[${ STATUS_CODE.FAIL }] - ${ data[STATUS_CODE.MESSAGE_KEY] }`;
                        // throw new Error (message);
                        console.error(message);
                    }
                    if (data[STATUS_CODE.CODEKEY] === STATUS_CODE.USER_FORCE_PASSWORD_CHANGE) {
                        enforceChangePassword();
                    }
                }
                successCallback && successCallback(data, textStatus, jqXHR);
            })
            .fail(error => {
                if (error[HTTP_CODE.CODEKEY] === HTTP_CODE.UNAUTHORIZED) {
                    return actionClearSession();
                } else if (error[HTTP_CODE.CODEKEY] === HTTP_CODE.FORBIDDEN) {
                    const { status_cd: statusCode, status_desc } = error.responseJSON || {};

                    if (statusCode === STATUS_CODE.USER_FORCE_PASSWORD_CHANGE) {
                        enforceChangePassword();
                    }

                    // STATUS_CODE.BAD_REQUEST is returned from backend for forbidden access
                    //   status code other than that, will not be handled here
                    if (statusCode === STATUS_CODE.BAD_REQUEST) {
                        actionForbiddenAcess({
                            message: status_desc,
                        });
                    }
                } else if (error[HTTP_CODE.CODEKEY] === HTTP_CODE.SERVER_ERROR) {
                    actionShowError({
                        message: ERROR_MESSAGES[HTTP_CODE.SERVER_ERROR],
                    });
                } else if (error[HTTP_CODE.CODEKEY] === HTTP_CODE.NOT_FOUND) {
                    return actionAPIError(
                        error && error.responseJSON && error.responseJSON[STATUS_CODE.MESSAGE_KEY],
                    );
                }

                // if e has jsonResponse
                // throw jsonResponse
                // else
                // handle it here

                failCallback && failCallback(error);
            });
    }

    get(options) {
        return this._requestBuilder('GET', options);
    }

    post(options) {
        return this._requestBuilder('POST', options);
    }

    patch(options) {
        return this._requestBuilder('PATCH', options);
    }

    delete(options) {
        return this._requestBuilder('DELETE', options);
    }
}
