import { SET_CLIENT, SET_CLIENT_FETCHING, SET_CLIENT_ERROR } from '../actions/ClientAction';

const INITIAL_STATE = {
    logo: '',
    primaryColor: '',
    secondaryColor: '',
    fetching: false,
    error: null,
};

export default function clientReducer(state = INITIAL_STATE, action) {
    let { payload } = action;

    switch (action.type) {
    case SET_CLIENT:
        payload = payload || {};
        return {
            ...state,
            ...payload,
        };
    case SET_CLIENT_FETCHING:
        return {
            ...state,
            fetching: payload,
        };

    case SET_CLIENT_ERROR:
        return {
            ...state,
            error: payload,
        };
    default:
        return state;
    }
}
