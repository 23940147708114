import React from 'react';
import _ from 'underscore';

import ObjectAssign from 'object-assign';
import { compose } from 'recompose';
import { Button } from 'semantic-ui-react';
import { withFormActionHandlers } from '../Misc/button';
import { FieldAction } from '../Misc/template';
import { withCaptureFieldHandlers } from '../Misc/forms';
import classnames from '../Misc/utils/classnames';

class ButtonWithIcon extends React.Component {
    static defaultProps = {
        className: '',
        fieldname: {},
        icon: {},
        buttonType: 'primary',
        size: 'normal',
    };

    constructor(props) {
        super(props);

        this.props = props;
        this.handleClick = this.props.handlePresetButtonClick;
    }

    render() {
        let {
            id,
            className,
            fieldname,
            // option
            type,
            title,
            name,
            icon,
            position,
            forceGap,
            onClick,
            isEditable,
            buttonType,
            size,
            setId,
            tabPath,
            tabIndex,
            isLoading,
            loadingText,
            // utils
            showComponent,
            path,
        } = this.props;
        const option = {
            type,
            title,
            name,
            icon,
            position,
            forceGap,
            onClick,
            buttonType,
            size,
        };
        const { parent, current } = fieldname;
        const iconName = icon.name;
        const iconPosition = icon.position;
        const classNames = [
            _.isString(className) && className,
            !isEditable && 'button-disabled',
            isLoading && 'button-loading',
            name && `button button__${ name }`,
            position == 'left' ? 'jpt--input-left' : 'jpt--input-right',
            forceGap && 'jpt--input-gap',
        ];

        switch (buttonType) {
        case 'secondary':
            classNames.push('button--secondary');
            break;
        case 'danger':
            classNames.push('button--danger');
        }

        switch (size) {
        case 'small':
            classNames.push('button--small');
            break;
        case 'medium':
            classNames.push('button--medium');
        }

        onClick = (() => {
            let preset = onClick.preset;
            const rule = onClick.rule;
            const ignoredIds = onClick.ignoredIds;
            const moduleType = onClick.module;
            const type = onClick.type;

            if (_.isFunction(onClick) && isEditable) {
                return onClick;
            } else if (_.isObject(onClick)) {
                return (e, data) => {
                    if (!isEditable) preset = null;
                    const rules = {
                        preset,
                        rule,
                        ignoredIds,
                        tabIndex,
                        relativePath: tabPath,
                        contextId: setId,
                        moduleType,
                        type,
                    };
                    this.handleClick(e, { data, rules });
                };
            } else {
                return e => {
                    e.preventDefault();
                };
            }
        })();
        const handleClick = (e, data) => {
            data = ObjectAssign({}, data, {
                action: name,
                path,
                fieldname: current,
                parent,
                form: this.props.form,
            });

            onClick(e, data);
        };
        const extraProps = iconName
            ? {
                icon: iconName,
                labelPosition: iconPosition,
            }
            : {};

        if (!showComponent) return <div />;
        if (isLoading) {
            isEditable = false;
            title = loadingText || title;
        }

        return (
            <div className={classnames('jpt--input input__button-icon', classNames)} id={id}>
                {isLoading && (
                    <div className="loader-container fixed">
                        <div className="loader"></div>
                    </div>
                )}
                {isEditable && iconPosition && (
                    <Button
                        content={title}
                        type="button"
                        fieldname={fieldname}
                        onClick={handleClick}
                        {...extraProps}
                    />
                )}
                {isEditable && !iconPosition && (
                    <Button type="button" onClick={handleClick}>
                        {title}
                    </Button>
                )}

                {!isEditable && iconPosition && (
                    <Button
                        content={title}
                        type="button"
                        fieldname={fieldname}
                        {...extraProps}
                        onClick={handleClick}
                    />
                )}

                {!isEditable && !iconPosition && (
                    <Button type="button" onClick={handleClick} disabled>
                        {title}
                    </Button>
                )}

                {this.props.isEditingField && <FieldAction path={path} field={option} />}
            </div>
        );
    }
}

export default compose(
    withFormActionHandlers,
    withCaptureFieldHandlers,
)(ButtonWithIcon);
