import _ from 'underscore';

import {
    FETCH_VIEW,
    FETCHING,
    FETCH_FIELDSET_SECTIONS,
    SET_SECTIONS,
    SET_DELETE_FIELDSET_SECTION,
} from '../actions/TemplateViewAction';
import { FETCH_VIEW_SUCCESS, FETCH_VIEW_ERROR } from '../actions/FormAction';

const INITIAL_STATE = {
    view: {},
    fetching: false,
    error: null,
    sections: [],
    choices: {
        sections: {},
    },
};

export default function TemplateViewReducer(state = INITIAL_STATE, action) {
    const { payload } = action;

    switch (action.type) {
    case FETCHING:
        const { fetching } = action;
        return {
            ...state,
            ...{ fetching },
        };

    case FETCH_VIEW:
        return {
            ...state,
            view: payload,
            sections: payload.sections,
        };

    case FETCH_FIELDSET_SECTIONS:
        return {
            ...state,
            choices: {
                ...state.choices,
                sections: _.map(payload, choice => ({ title: choice.name, value: choice.id })),
            },
        };

    case SET_SECTIONS:
        return {
            ...state,
            sections: [...action.payload],
        };

    case SET_DELETE_FIELDSET_SECTION:
        return {
            ...state,
            sections: state.sections.filter(
                fieldsetSection => fieldsetSection.fieldsetId !== payload,
            ),
        };

    case FETCH_VIEW_SUCCESS: {
        return {
            ...state,
            lastModified: action.lastModified,
        };
    }
    case FETCH_VIEW_ERROR: {
        return {
            ...state,
            error: action.error,
        };
    }

    default:
        return state;
    }
}
