import React from 'react';
import _ from 'underscore';
import { toValidDate } from './misc';
import { getFullYear, getMonth, getDate, getHours, getMinutes } from '../../Misc/datetime';

const MIN_YEAR = 1000;
const MAX_YEAR = 9999;

function isInRange({ year, minYear, maxYear }) {
    if (maxYear < year || minYear > year) {
        return false;
    }
    return true;
}

const YearRange = ({ children = '', onClick }) => {
    return (
        <div className="popup-top-title clickable border" onClick={onClick}>
            {children}
        </div>
    );
};

const Year = () => {
    return <div className="popup-top-title border">Year</div>;
};

const YearColumn = ({ year, disabled, selected, onClick }) => {
    const { name, isDisabled } = year;
    const classNames = [
        'calendar-column-year',
        disabled || isDisabled ? 'disabled' : '',
        selected ? 'active' : '',
    ];
    if (name) {
        year = name;
    }
    if (disabled || isDisabled) {
        onClick = _.noop;
    }

    return (
        <div
            className={classNames.join(' ')}
            role="button"
            onClick={e => {
                onClick(e, { year });
            }}>
            {year}
        </div>
    );
};

const YearRows = ({ date, minRange, maxRange, minYear, maxYear, timezone, onClick }) => {
    const selectedYear = getFullYear(date, timezone);
    const years = [];
    let min = minRange,
        max = maxRange;
    if (!minRange || !maxRange) {
        min = selectedYear - 4;
        max = selectedYear + 4;

        if (min < MIN_YEAR) {
            min = MIN_YEAR;
            max = MIN_YEAR + 8;
        } else if (max > MAX_YEAR) {
            min = MAX_YEAR - 8;
            max = MAX_YEAR;
        }
    }
    for (let i = min; i <= max; i++) {
        years.push(i);
    }
    return (
        <div className="calendar-rows-year">
            {years.map((year, i) => (
                <YearColumn
                    key={i}
                    year={year}
                    disabled={!isInRange({ year, minYear, maxYear })}
                    selected={selectedYear === year}
                    onClick={onClick}
                />
            ))}
        </div>
    );
};

const CalendarPopUpYear = props => {
    const {
        onClick,
        minDate,
        maxDate,
        timezone,
        onYearRangeClicked,
        showYearRange,
        range = [],
    } = props;
    let { date } = props;
    date = toValidDate(date);
    const minYear = minDate && getFullYear(minDate, timezone);
    const maxYear = maxDate && getFullYear(maxDate, timezone);
    const month = getMonth(date, timezone);
    const day = getDate(date, timezone);
    const hour = getHours(date, timezone);
    const minute = getMinutes(date, timezone);
    const selectedYear = date.getFullYear();

    const [minRange, maxRange] = range;
    const yearRangeText = range.length
        ? `${ minRange } - ${ maxRange }`
        : `${ selectedYear - 4 } - ${ selectedYear + 4 }`;
    return (
        <div className="calendar-year-wrapper">
            {showYearRange ? (
                <YearRange onClick={onYearRangeClicked}>{yearRangeText}</YearRange>
            ) : (
                <Year />
            )}
            <YearRows
                date={date}
                minRange={minRange}
                maxRange={maxRange}
                minYear={minYear}
                maxYear={maxYear}
                timezone={timezone}
                onClick={(e, data) => {
                    onClick && onClick(e, { ...data, month, day, hour, minute });
                }}
            />
        </div>
    );
};

export default CalendarPopUpYear;
