import {
    SET_DASHBOARD_DELEGATED_ACCESSES,
    SET_ERROR,
    SET_DELEGATED_USER,
    SET_UPDATE_FILTERS,
} from '../actions/DashboardAction';

const INITIAL_STATE = {
    dashboardDelegatedAccesses: [],
    error: null,
    delegatedUser: {},
    isUpdateFilters: false,
};

export default function DashboardReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
    case SET_DELEGATED_USER: {
        const { delegatedUser } = action;
        return {
            ...state,
            delegatedUser,
        };
    }
    case SET_ERROR:
        return {
            ...state,
            error: action.error,
        };
    case SET_DASHBOARD_DELEGATED_ACCESSES: {
        const dashboardDelegatedAccesses = action.payload;
        return {
            ...state,
            dashboardDelegatedAccesses,
        };
    }
    case SET_UPDATE_FILTERS: {
        const { isUpdateFilters } = action;
        return {
            ...state,
            isUpdateFilters,
        };
    }

    default:
        return state;
    }
}
