import { SET_RULES, SET_RULES_ERROR } from '../actions/RuleAction';

const INITIAL_STATE = {
    rules: {},
    fetching: false,
    error: null,
};

export default function RuleReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
    case SET_RULES:
        const { rules } = action;
        return {
            ...state,
            rules: {
                ...state.RULES,
                ...rules,
            },
        };
    case SET_RULES_ERROR:
        const { error } = action;
        return {
            ...state,
            error,
        };
    default:
        return state;
    }
}
