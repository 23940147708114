import { templateDetailAPI } from '../../api/template/TemplateDetailAPI';
import { templateAccessAPI } from '../../api/template/TemplateAccessAPI';
import { templateViewAPI } from '../../api/template/TemplateViewAPI';
import { statusAPI } from '../../api/template/StatusAPI';
import { roleAPI } from '../../api/role/RoleAPI';
import * as _ from 'underscore';
import { setPresetLists } from './ListAction';
import { setDisabledFields } from './DisabledAction';
import { standardMDRsKey, standardMDRsListKey } from '../../components/Form/FormRules';

// #### ACTION CREATORS #####
export const SET_TEMPLATE_DETAIL_FETCHING = 'SET_TEMPLATE_DETAIL_FETCHING';

export const setTemplateDetailFetching = bool => {
    return {
        type: SET_TEMPLATE_DETAIL_FETCHING,
        payload: bool,
    };
};

export const SET_TEMPLATE_DETAIL_ERROR = 'SET_TEMPLATE_DETAIL_ERROR';

export const setTemplateDetailError = error => {
    return {
        type: SET_TEMPLATE_DETAIL_ERROR,
        payload: error,
    };
};

export const SET_TEMPLATE_DETAIL = 'SET_TEMPLATE_DETAIL';

export const setTemplateDetail = templateDetail => {
    return {
        type: SET_TEMPLATE_DETAIL,
        payload: templateDetail,
    };
};

export const SET_TEMPLATE_SECTIONS_FETCHING = 'SET_TEMPLATE_SECTIONS_FETCHING';

export const setTemplateSectionsFetching = bool => {
    return {
        type: SET_TEMPLATE_SECTIONS_FETCHING,
        payload: bool,
    };
};

export const SET_TEMPLATE_SECTIONS_ERROR = 'SET_TEMPLATE_SECTIONS_ERROR';

export const setTemplateSectionsError = error => {
    return {
        type: SET_TEMPLATE_SECTIONS_ERROR,
        payload: error,
    };
};

export const SET_TEMPLATE_SECTIONS = 'SET_TEMPLATE_SECTIONS';

export const setTemplateSections = sections => {
    return {
        type: SET_TEMPLATE_SECTIONS,
        payload: sections,
    };
};

export const SET_TEMPLATE_SECTION = 'SET_TEMPLATE_SECTION';

export const setTemplateSection = section => {
    return {
        type: SET_TEMPLATE_SECTION,
        payload: section,
    };
};

export const SET_TEMPLATE_VIEWS_FETCHING = 'SET_TEMPLATE_VIEWS_FETCHING';

export const setTemplateViewsFetching = bool => {
    return {
        type: SET_TEMPLATE_VIEWS_FETCHING,
        payload: bool,
    };
};

export const SET_TEMPLATE_VIEWS_ERROR = 'SET_TEMPLATE_VIEWS_ERROR';

export const setTemplateViewsError = error => {
    return {
        type: SET_TEMPLATE_VIEWS_ERROR,
        payload: error,
    };
};

export const SET_TEMPLATE_VIEWS = 'SET_TEMPLATE_VIEWS';

export const setTemplateViews = views => {
    return {
        type: SET_TEMPLATE_VIEWS,
        payload: views,
    };
};

export const SET_TEMPLATE_VIEW = 'SET_TEMPLATE_VIEW';

export const setTemplateView = view => {
    return {
        type: SET_TEMPLATE_VIEW,
        payload: view,
    };
};

export const SET_TEMPLATE_ACCESSES_FETCHING = 'SET_TEMPLATE_ACCESSES_FETCHING';

export const setTemplateAccessesFetching = bool => {
    return {
        type: SET_TEMPLATE_ACCESSES_FETCHING,
        payload: bool,
    };
};

export const SET_TEMPLATE_ACCESSES_ERROR = 'SET_TEMPLATE_ACCESSES_ERROR';

export const setTemplateAccessesError = error => {
    return {
        type: SET_TEMPLATE_ACCESSES_ERROR,
        payload: error,
    };
};

export const SET_TEMPLATE_ACCESSES = 'SET_TEMPLATE_ACCESSES';

export const setTemplateAccesses = accesses => {
    return {
        type: SET_TEMPLATE_ACCESSES,
        payload: accesses,
    };
};

export const SET_TEMPLATE_ACCESS = 'SET_TEMPLATE_ACCESS';

export const setTemplateAccess = access => {
    return {
        type: SET_TEMPLATE_ACCESS,
        payload: access,
    };
};

export const SET_TEMPLATE_STATUSES_FETCHING = 'SET_TEMPLATE_STATUSES_FETCHING';

export const setTemplateStatusesFetching = bool => {
    return {
        type: SET_TEMPLATE_STATUSES_FETCHING,
        payload: bool,
    };
};

export const SET_TEMPLATE_STATUSES_ERROR = 'SET_TEMPLATE_STATUSES_ERROR';

export const setTemplateStatusesError = error => {
    return {
        type: SET_TEMPLATE_STATUSES_ERROR,
        payload: error,
    };
};

export const SET_TEMPLATE_STATUSES = 'SET_TEMPLATE_STATUSES';

export const setTemplateStatuses = statuses => {
    return {
        type: SET_TEMPLATE_STATUSES,
        payload: statuses,
    };
};

export const SET_TEMPLATE_STATUS = 'SET_TEMPLATE_STATUS';

export const setTemplateStatus = status => {
    return {
        type: SET_TEMPLATE_STATUS,
        payload: status,
    };
};

export const SET_ROLES_FETCHING = 'SET_ROLES_FETCHING';

export const setRolesFetching = bool => {
    return {
        type: SET_ROLES_FETCHING,
        payload: bool,
    };
};

export const SET_ROLES_ERROR = 'SET_ROLES_ERROR';

export const setRolesError = error => {
    return {
        type: SET_ROLES_ERROR,
        payload: error,
    };
};

export const SET_ROLES = 'SET_ROLES';

export const setRoles = roles => {
    return {
        type: SET_ROLES,
        payload: roles,
    };
};
// #### ACTION CREATORS END #####

// #### ACTION FUNCTIONS ####

export const actionGetTemplateDetail = templateId => {
    return async dispatch => {
        dispatch(setTemplateDetailFetching(true));
        try {
            const template = await templateDetailAPI.getTemplateDetails(templateId);
            template.form_statuses = _.indexBy(template.form_statuses, 'id');

            dispatch(actionSetTemplatePPDA(template));
            dispatch(setTemplateDetail(template));
        } catch (error) {
            dispatch(setTemplateDetailError(error));
        } finally {
            dispatch(setTemplateDetailFetching(false));
        }
    };
};

/**
 * Action to set PPDA into preset list of Capture, to be refer by any
 *    fields by setting field.options: { listID: id }
 * @param {Object} template - a template object from api cap/templates/{id}
 * @returns {function} async function
 */
export const actionSetTemplatePPDA = template => {
    return async dispatch => {
        let {
            device_types: deviceTypes = [],
            application_bank_names: applicationBankNames = [],
            packages = [],
            products = [],
            midtid = [],
            banks = [],
            merchant_agreement_html: merchantAgreementHtml,
            consent_authorisation_template,
        } = template;
        let standardMDRs = template[standardMDRsListKey] || [];

        // format device types options
        deviceTypes = deviceTypes.map(d => ({
            title: d.text,
            value: d.value,
            accessories: d.accessories,
        }));
        let terminalDeviceTypes = {};
        applicationBankNames.forEach(({ value, terminal_type_names }) => {
            const types = terminal_type_names.map(({ value, text }) => ({ title: text, value }));
            terminalDeviceTypes = {
                ...terminalDeviceTypes,
                [value]: {
                    types,
                },
            };
        });

        let deviceTypeMapAccessories = {};
        deviceTypes.forEach(({ value, accessories = [] }) => {
            // format accessories options
            accessories = accessories.map(a => ({ title: a.text, value: a.value }));

            deviceTypeMapAccessories = {
                ...deviceTypeMapAccessories,
                [value]: {
                    accessories,
                },
            };
        });

        // format standard_merchant_rates options
        standardMDRs = standardMDRs.map(mdr => ({ ...mdr, title: mdr.text, value: mdr.value }));
        const mdrsValue = standardMDRs.map(mdr => mdr.value);
        // format packages options
        packages = packages.map(p => ({
            title: p.text,
            value: p.value,
            group: p.group,
        }));
        // format products options
        products = products.map(p => ({
            title: p.text,
            value: p.value,
            mdrs: p.mdrs,
            disabled_mdrs: p.disabled_mdrs,
            default_mdr: p.default_mdr ? p.default_mdr : {},
            group: p.group,
            deprecated: p.deprecated,
        }));
        // format midtid options
        midtid = midtid.map(mt => ({ ...mt, title: mt.text, value: mt.value }));
        // format banks options
        const banksMapToAgreement = _.object(
            banks.map(b => b.value),
            banks.map(b => b.agreement_html),
        );
        banks = banks.map(b => ({
            title: b.text,
            value: b.value,
            agreement_html: b.agreement_html,
        }));

        // disabled mdrs
        const disabledMdrs = {};
        products.forEach(({ disabled_mdrs: disabledMDRs = [], value }) => {
            // find diff
            const diffMDR = _.difference(mdrsValue, disabledMDRs);
            disabledMdrs[value] = {};
            // format disabled mdr
            disabledMDRs.forEach(mdr => {
                // set to true, which mean it's disabled
                disabledMdrs[value][mdr] = true;
            });
            diffMDR.forEach(mdr => {
                // set to false, which means it's enabled
                disabledMdrs[value][mdr] = false;
            });
        });

        dispatch(setTemplateDetail(template));
        dispatch(
            setPresetLists({
                device_types: deviceTypes,
                application_bank_names_map_to: terminalDeviceTypes,
                device_types_map_to: deviceTypeMapAccessories,
                packages: packages,
                products: products,
                banks: banks,
                midtid: midtid,
                [standardMDRsListKey]: standardMDRs,
                banks_map_to_agreement: {
                    ...banksMapToAgreement,
                    capture_client: merchantAgreementHtml,
                    capture_client_consent: consent_authorisation_template,
                },
            }),
        );
        dispatch(setDisabledFields({ [standardMDRsKey]: disabledMdrs }));
    };
};

export const actionGetTemplateSections = templateId => {
    return async dispatch => {
        dispatch(setTemplateSectionsFetching(true));
        try {
            const sections = await templateDetailAPI.getTemplateSections(templateId);
            dispatch(setTemplateSections(_.indexBy(sections, 'id')));
        } catch (error) {
            dispatch(setTemplateSectionsError(error));
        } finally {
            dispatch(setTemplateSectionsFetching(false));
        }
    };
};

export const actionGetTemplateViews = templateId => {
    return async dispatch => {
        dispatch(setTemplateViewsFetching(true));
        try {
            const views = await templateViewAPI.getViews(templateId);
            dispatch(setTemplateViews(_.indexBy(views, 'id')));
        } catch (error) {
            dispatch(setTemplateViewsError(error));
        } finally {
            dispatch(setTemplateViewsFetching(false));
        }
    };
};
export const actionGetTemplateAccesses = templateId => {
    return async dispatch => {
        dispatch(setTemplateAccessesFetching(true));
        try {
            const accesses = await templateAccessAPI.getAccesses(templateId);
            const roles = await roleAPI.getRoles();
            dispatch(setTemplateAccesses(_.indexBy(accesses, 'id')));
            dispatch(setRoles(_.indexBy(roles, 'id')));
        } catch (error) {
            dispatch(setTemplateAccessesError(error));
        } finally {
            dispatch(setTemplateAccessesFetching(false));
        }
    };
};
export const actionGetTemplateStatuses = templateId => {
    return async dispatch => {
        dispatch(setTemplateStatusesFetching(true));
        try {
            const statuses = await statusAPI.getStatuses(templateId);
            dispatch(setTemplateStatuses(_.indexBy(statuses, 'id')));
        } catch (error) {
            dispatch(setTemplateStatusesError(error));
        } finally {
            dispatch(setTemplateStatusesFetching(false));
        }
    };
};

export const actionAddTemplateSection = (templateId, data, hideformCallback) => {
    return async (dispatch, getState) => {
        dispatch(setTemplateSectionsFetching(true));
        const { sections } = getState().templateDetail;
        try {
            const section = await templateDetailAPI.createTemplateSection(templateId, data);
            sections[section.id] = section;
            dispatch(setTemplateSection(sections));
        } catch (error) {
            dispatch(setTemplateSectionsError(error));
        } finally {
            _.isFunction(hideformCallback) && hideformCallback();
            dispatch(setTemplateSectionsFetching(false));
        }
    };
};

export const actionAddTemplateView = (templateId, data, hideformCallback) => {
    return async (dispatch, getState) => {
        dispatch(setTemplateViewsFetching(true));
        const { views } = getState().templateDetail;
        try {
            const view = await templateDetailAPI.createTemplateView(templateId, data);
            views[view.id] = view;

            dispatch(setTemplateView(views));
            hideformCallback();
        } catch (error) {
            dispatch(setTemplateViewsError(error));
        } finally {
            _.isFunction(hideformCallback) && hideformCallback();
            dispatch(setTemplateViewsFetching(false));
        }
    };
};

export const actionAddTemplateAccess = (templateId, data, hideformCallback) => {
    return async (dispatch, getState) => {
        dispatch(setTemplateAccessesFetching(true));
        const { accesses } = getState().templateDetail;
        try {
            const access = await templateDetailAPI.createTemplateAccess(templateId, data);
            accesses[access.id] = access;

            dispatch(setTemplateAccess(accesses));
        } catch (error) {
            dispatch(setTemplateAccessesError(error));
        } finally {
            _.isFunction(hideformCallback) && hideformCallback();
            dispatch(setTemplateAccessesFetching(false));
        }
    };
};

export const actionAddTemplateStatus = (data, hideformCallback) => {
    return async (dispatch, getState) => {
        dispatch(setTemplateStatusesFetching(true));
        const { templateDetail, statuses } = getState().templateDetail;
        const templateId = templateDetail.id;
        try {
            const status = await statusAPI.createStatus(templateId, data);
            statuses[status.id] = status;

            dispatch(setTemplateStatus(statuses));
        } catch (error) {
            dispatch(setTemplateStatusesError(error));
        } finally {
            _.isFunction(hideformCallback) && hideformCallback();
            dispatch(setTemplateStatusesFetching(false));
        }
    };
};

export const actionUpdateTemplateSection = (templateId, sectionId, data, hideformCallback) => {
    return async (dispatch, getState) => {
        dispatch(setTemplateSectionsFetching(true));
        const { sections } = getState().templateDetail;
        try {
            const section = await templateDetailAPI.editTemplateSection(
                templateId,
                sectionId,
                data,
            );
            sections[section.id] = section;
            dispatch(setTemplateSection(sections));
        } catch (error) {
            dispatch(setTemplateSectionsFetching(error));
        } finally {
            _.isFunction(hideformCallback) && hideformCallback();
            dispatch(setTemplateSectionsFetching(false));
        }
    };
};

// update template Details View
export const actionUpdateTemplateView = (templateId, viewId, data, hideformCallback) => {
    return async (dispatch, getState) => {
        dispatch(setTemplateViewsFetching(true));
        const { views } = getState().templateDetail;
        try {
            const view = await templateDetailAPI.editTemplateView(templateId, viewId, data);
            views[view.id] = view;

            dispatch(setTemplateView(views));
        } catch (error) {
            dispatch(setTemplateViewsError(error));
        } finally {
            _.isFunction(hideformCallback) && hideformCallback();
            dispatch(setTemplateViewsFetching(false));
        }
    };
};

// update template Details Access
export const actionUpdateTemplateAccess = (templateId, accessId, data, hideformCallback) => {
    return async (dispatch, getState) => {
        dispatch(setTemplateAccessesFetching(true));
        const { accesses } = getState().templateDetail;
        try {
            const access = await templateDetailAPI.editTemplateAccess(templateId, accessId, data);
            accesses[access.id] = access;

            dispatch(setTemplateAccess(accesses));
        } catch (error) {
            dispatch(setTemplateAccessesError(error));
        } finally {
            _.isFunction(hideformCallback) && hideformCallback();
            dispatch(setTemplateAccessesFetching(false));
        }
    };
};

// update template status
export const actionUpdateTemplateStatus = (statusId, data, hideformCallback) => {
    return async (dispatch, getState) => {
        dispatch(setTemplateStatusesFetching(true));
        const { templateDetail, statuses } = getState().templateDetail;
        const templateId = templateDetail.id;
        try {
            const status = await statusAPI.editStatus(templateId, statusId, data);
            statuses[status.id] = status;

            dispatch(setTemplateStatus(statuses));
        } catch (error) {
            dispatch(setTemplateStatusesError(error));
        } finally {
            _.isFunction(hideformCallback) && hideformCallback();
            dispatch(setTemplateStatusesFetching(false));
        }
    };
};

export const actionDeleteTemplateSection = (templateId, sectionId, data, hideformCallback) => {
    return async (dispatch, getState) => {
        dispatch(setTemplateSectionsFetching(true));
        const { sections } = getState().templateDetail;
        try {
            await templateDetailAPI.removeTemplateSection(templateId, sectionId, data);
            delete sections[sectionId];
            dispatch(setTemplateSection(sections));
        } catch (error) {
            dispatch(setTemplateSectionsError(error));
        } finally {
            _.isFunction(hideformCallback) && hideformCallback();
            dispatch(setTemplateSectionsFetching(false));
        }
    };
};

// delete template Details View
export const actionDeleteTemplateView = (templateId, viewId, data, hideformCallback) => {
    return async (dispatch, getState) => {
        dispatch(setTemplateViewsFetching(true));
        const { views } = getState().templateDetail;
        try {
            await templateDetailAPI.removeTemplateView(templateId, viewId, data);
            delete views[viewId];
            dispatch(setTemplateView(views));
        } catch (error) {
            dispatch(setTemplateViewsError(error));
        } finally {
            _.isFunction(hideformCallback) && hideformCallback();
            dispatch(setTemplateViewsFetching(false));
        }
    };
};

// delete template Details Access
export const actionDeleteTemplateAccess = (templateId, accessId, data, hideformCallback) => {
    return async (dispatch, getState) => {
        dispatch(setTemplateAccessesFetching(true));
        const { accesses } = getState().templateDetail;
        try {
            await templateDetailAPI.removeTemplateAccess(templateId, accessId, data);
            delete accesses[accessId];
            dispatch(setTemplateAccess(accesses));
        } catch (error) {
            dispatch(setTemplateAccessesError(error));
        } finally {
            _.isFunction(hideformCallback) && hideformCallback();
            dispatch(setTemplateAccessesFetching(false));
        }
    };
};

// delete template status
export const actionDeleteTemplateStatus = (statusId, hideformCallback) => {
    return async (dispatch, getState) => {
        dispatch(setTemplateStatusesFetching(true));
        const { templateDetail, statuses } = getState().templateDetail;
        const templateId = templateDetail.id;
        try {
            await statusAPI.removeStatus(templateId, statusId);
            delete statuses[statusId];
            dispatch(setTemplateStatus(statuses));
        } catch (error) {
            dispatch(setTemplateStatusesError(error));
        } finally {
            _.isFunction(hideformCallback) && hideformCallback();
            dispatch(setTemplateStatusesFetching(false));
        }
    };
};

// #### ACTION FUNCTIONS END ####
