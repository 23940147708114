import { templateAPI } from '../../api/template/TemplateAPI';
import * as _ from 'underscore';

// #### ACTION CREATORS #####

// Action Creator fetching status true / false
export const SET_TEMPLATES_FETCHING = 'SET_TEMPLATES_FETCHING';

export const setTemplatesFetching = templatesFetching => {
    return {
        type: SET_TEMPLATES_FETCHING,
        payload: templatesFetching,
    };
};

// gets list of templates
export const SET_TEMPLATES = 'SET_TEMPLATES';

export const setTemplates = templates => {
    return {
        type: SET_TEMPLATES,
        payload: templates,
    };
};

// error checker
export const SET_TEMPLATES_ERROR = 'SET_TEMPLATES_ERROR';

export const setTemplatesError = error => {
    return {
        type: SET_TEMPLATES_ERROR,
        error,
    };
};

// set_template for create, update and delete
export const SET_TEMPLATE = 'SET_TEMPLATE';

export const setTemplate = template => {
    return {
        type: SET_TEMPLATE,
        payload: template,
    };
};

// set current template id
export const SET_CURRENT_TEMPLATE = 'SET_CURRENT_TEMPLATE';

export const setCurrentTemplate = id => {
    return {
        type: SET_CURRENT_TEMPLATE,
        id,
    };
};

// #### ACTION CREATORS END #####

// #### ACTION FUNCTIONS #####

export const actionGetTemplates = () => {
    return async dispatch => {
        dispatch(setTemplatesFetching(true));
        try {
            const templates = await templateAPI.getTemplates();
            dispatch(setTemplates(_.indexBy(templates, 'id')));
        } catch (error) {
            dispatch(setTemplatesError(error));
        } finally {
            dispatch(setTemplatesFetching(false));
        }
    };
};

//  add template to list
export const actionAddTemplate = (data, hideformCallback) => {
    return async (dispatch, getState) => {
        dispatch(setTemplatesFetching(true));
        const { templates } = getState().template;
        try {
            const template = await templateAPI.createTemplate(data);
            templates[template.id] = template;
            dispatch(setTemplate(templates));
            hideformCallback();
        } catch (error) {
            dispatch(setTemplatesError(error));
        } finally {
            dispatch(setTemplatesFetching(false));
        }
    };
};

// update template
export const updateTemplates = (templateId, data, hideformCallback) => {
    return async (dispatch, getState) => {
        dispatch(setTemplatesFetching(true));
        const { templates } = getState().template;
        try {
            const template = await templateAPI.editTemplate(templateId, data);
            templates[template.id] = template;
            dispatch(setTemplate(templates));
            hideformCallback();
        } catch (error) {
            dispatch(setTemplatesError(error));
        } finally {
            dispatch(setTemplatesFetching(false));
        }
    };
};

//  delete template
export const actionDeleteTemplate = (templateId, hideformCallback) => {
    return async (dispatch, getState) => {
        dispatch(setTemplatesFetching(true));
        const { templates } = getState().template;
        try {
            const template = await templateAPI.removeTemplate(templateId);
            delete templates[templateId];
            dispatch(setTemplate(template));
            hideformCallback();
        } catch (error) {
            dispatch(setTemplatesError(error));
        } finally {
            dispatch(setTemplatesFetching(false));
        }
    };
};

export const SET_FIELDS = 'SET_FIELDS';
export const setFields = fields => {
    return {
        type: SET_FIELDS,
        fields,
    };
};

// #### ACTION FUNCTIONS END #####

export const GET_TEMPLATE_OPTIONS = 'GET_TEMPLATE_OPTIONS';
function getTemplateOptions(payload) {
    return {
        type: GET_TEMPLATE_OPTIONS,
        payload: payload,
    };
}

export function actionGetTemplateOptions() {
    return async dispatch => {
        let templates = await templateAPI.getTemplateOptions();
        templates = _.indexBy(templates, 'id');
        return dispatch(getTemplateOptions(templates));
    };
}
