import 'es5-shim';
import 'es5-shim/es5-sham';
import 'console-polyfill';
import 'raf/polyfill';
import 'core-js/stable';
import 'regenerator-runtime/runtime';

if (Function.prototype.bind && window.console && typeof console.log == 'object') {
    ['log', 'info', 'warn', 'error', 'assert', 'dir', 'clear', 'profile', 'profileEnd'].forEach(
        function(method) {
            console[method] = this.bind(console[method], console);
        },
        Function.prototype.call,
    );
}

// polyfill for element.remove
// reference https://github.com/chenzhenxi/element-remove/blob/master/index.js
(function(arr) {
    arr.forEach(function(item) {
        if (item.hasOwnProperty('remove')) {
            return;
        }
        Object.defineProperty(item, 'remove', {
            configurable: true,
            enumerable: true,
            writable: true,
            value: function remove() {
                this.parentNode.removeChild(this);
            },
        });
    });
})([Element.prototype, CharacterData.prototype, DocumentType.prototype]);
