/** this file set up and export ajv that's added with capture valiators keyword  */
import Ajv from 'ajv';
import { printf } from 'fast-printf';
import { customValidators } from '../../Misc/validators';


// ajv setup
const ajv = new Ajv({ allErrors: true, jsonPointers: true });
export const validators = {
    isNumeric: {
        pattern: '^(\\-)?([0-9]+)$',
        error: 'Value should be only numbers.',
    },
    isEmail: {
        pattern:
            '^[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+([a-zA-Z]{2,})$',
        error: 'Value should be an email address.',
    },
    isUrl: {
        pattern:
            '^(http://www\\.|https://www\\.|http://|https://)?[a-z0-9]+([\\-\\.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$',
        error: 'Value should be an URL.',
    },
    isTime: {
        pattern: '^((1[0-2]|0?[1-9]):([0-5][0-9]) ([AaPp][Mm]))$',
        error: 'Value should be time (HH:MM AM/PM).',
    },
    isRegisteredNumber: {
        pattern: '^[0-9]{1,10}\\-[a-zA-Z]$',
        error:
            'Value should be only max 10 digits followed by a - and a single alphabet (009999999-X).',
    },
    isValidMCC: {
        pattern:
            '^(5200|5499|5533|5611|5621|5631|5641|5651|5655|5661|5691|5697|5712|5722|5732|5733|5734|5735|5812|5945|5995|5999|7230|7399|7538|7542|8043|8050|8299|8351)$',
        error: 'Please select a valid MCC.',
    },
    isAlpha: {
        pattern: '^[a-zA-Z]+$',
        error: 'Value should be only alphabetic character(s).',
    },
    isAlphanumeric: {
        pattern: '^[a-zA-Z0-9]+$',
        error: 'Value should be only alphabetic and number character(s).',
    },
    isAlphanumericWithSpecialChar: {
        pattern: '^[^ ]+$',
        error: 'Value should be only alphabetic, number and special character(s).',
    },
    isAlphanumericSpace: {
        pattern: '^[a-zA-Z0-9 ]*$',
        error: 'Value should be only alphabetic, number and space character(s).',
    },
    isAlphanumericSpaceWithSpecialChar: {
        pattern: '^[a-zA-Z0-9 .,!@$%#^*@+&_:;\\"\\\'\\/\\-)(]*$',
        error: 'Value should be only alphabetic, number, space and special character(s).',
    },
    isDecimal: {
        pattern: '^(\\-)?([0-9]+)\\.([0-9]+)$',
        error: 'Value should be in decimal place(s).',
    },
    isInteger: {
        pattern: '^(\\-)?([0-9]+)$',
        error: 'Value should be only numbers.',
    },
    isLeadingZero: {
        pattern: '^0{%d,}',
        error: 'Value must have %s leading zero.',
    },
    isDecimalPlaceMin: {
        pattern: '^(\\-)?([0-9]+)(\\.[0-9]{%d,})?$',
        error: 'Value should have at least %s decimal place(s).',
    },
    isDecimalPlaceMax: {
        pattern: '^(\\-)?([0-9]+)(\\.[0-9]{0,%d})?$',
        error: 'Value should not exceed %s decimal place(s).',
    },
    isRequired: {
        pattern: null,
        error: 'This is a mandatory field.',
    },
    isLengthMin: {
        pattern: '^[\\s\\S]{%d,}$',
        error: 'Value should be at least %s characters long.',
    },
    isLengthMax: {
        pattern: '^[\\s\\S]{0,%d}$',
        error: 'Value should not exceed %s characters.',
    },
    isLengthExact: {
        pattern: '^[\\s\\S]{%d}$',
        error: 'Value must be %s characters long',
    },
    isMin: {
        pattern: null,
        error: 'Number should be at least %s.',
    },
    isMax: {
        pattern: null,
        error: 'Number should not exceed %s.',
    },
    isLessThan: {
        pattern: null,
        error: 'Number should be less than %s.',
    },
    isMoreThan: {
        pattern: null,
        error: 'Number should be more than %s.',
    },
    isEquals: {
        pattern: null,
        error: 'Value can be %s only',
    },
    isEqualsIgnoreCase: {
        pattern: null,
        error: 'Value can be %s only',
    },
    isSize: {
        pattern: null,
        error: 'Should contain %s items only',
    },
    isIn: {
        pattern: null,
        error: 'Value %s is not found in items',
    },
    startsWith: {
        pattern: null,
        error: 'Value must start with %s',
    },
    endsWith: {
        pattern: null,
        error: 'Value must end with %s',
    },
    startsWithAny: {
        pattern: null,
        error: 'Value must start with %s',
    },
    isDate: {
        pattern: null,
        error: 'Date format is invalid',
    },
    isYear: {
        pattern: null,
        error: 'Please input date as 2020 format',
    },
    isMonthYear: {
        pattern: null,
        error: 'Please input date as 2020/12 format',
    },
    isAccepted: {
        pattern: null,
        error:
            'Please scroll to the end, check and accept the terms and conditions before proceeding further',
    },
    isDatetimeInRange: {
        pattern: null,
        error: 'Datetime is outside of range',
    },
    isAgreementGenerated: {
        pattern: null,
        error: 'Please generate agreement',
    },
};

Object.keys(validators).forEach(key => {
    ajv.addKeyword(key, {
        validate: function validate(schema, data) {
            let pattern = '';
            let error = '';
            let valid = true;
            /** if pattern contains regex, use regex object for validation */
            if (validators[key].pattern !== null) {
                if (data.length !== 0) {
                    pattern = printf(validators[key].pattern, schema);
                    error = printf(validators[key].error, schema);

                    const reg = new RegExp(pattern);
                    valid = reg.test(data);
                }
            } else {
                /** if pattern is null, use logic to do validation */
                error = printf(validators[key].error, schema);

                valid = customValidators[key](schema, data);
            }

            validate.errors = [
                {
                    keyword: key,
                    message: error,
                    params: {
                        keyword: key,
                    },
                },
            ];

            return valid;
        },
        errors: true,
    });
});

export default ajv;
