import BaseAPI from '../BaseAPI';

class StatusAPI extends BaseAPI {
    basePath = 'cap/templates/';

    async getStatuses(templateId) {
        let formStatuses;
        try {
            const response = await this.get({
                path: `${ this.basePath }${ templateId }/form-statuses/`,
            });
            formStatuses = response.form_statuses;
        } catch (e) {
            throw new Error(e.responseJSON);
        }

        return formStatuses;
    }

    async createStatus(templateId, status) {
        let formStatus;
        try {
            const response = await this.post({
                path: `${ this.basePath }${ templateId }/form-statuses/`,
                data: status,
            });
            formStatus = response.form_status;
        } catch (e) {
            throw new Error(e.responseJSON);
        }

        return formStatus;
    }

    async editStatus(templateId, statusId, status) {
        let formStatus;
        try {
            const response = await this.post({
                path: `${ this.basePath }${ templateId }/form-statuses/${ statusId }/`,
                data: status,
            });
            formStatus = response.form_status;
        } catch (e) {
            throw new Error(e.responseJSON);
        }

        return formStatus;
    }

    async removeStatus(templateId, statusId) {
        let formStatus;
        try {
            const response = await this.delete({
                path: `${ this.basePath }${ templateId }/form-statuses/${ statusId }/`,
            });
            formStatus = response.form_status;
        } catch (e) {
            throw new Error(e.responseJSON);
        }

        return formStatus;
    }
}

export const statusAPI = new StatusAPI();
