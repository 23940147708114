import React from 'react';
import _ from 'underscore';

import { compose } from 'recompose';
import { FieldAction } from '../Misc/template';
import { withCaptureFieldHandlers } from '../Misc/forms';
import Input from 'semantic-ui-react/dist/commonjs/elements/Input/Input';

class InputWithButton extends React.Component {
    static defaultProps = {
        className: '',
        fieldname: {},
        button: {},
        placeholder: '',
        showComponent: true,
        isValid: true,
    };

    constructor(props) {
        super(props);

        this.props = props;
        this.handleChange = this.handleChange.bind(this);
    }

    render() {
        let {
            className,
            fieldname,
            // option
            type,
            title,
            name,
            button,
            value,
            placeholder,
            onChange,
            errorMessage,
            message,
            isPassword,

            // utils
            showComponent,
            path,
        } = this.props;
        const option = {
            type,
            title,
            name,
            button,
            value,
            placeholder,
            message,
            isPassword,
        };
        const { current } = fieldname;
        const { icon, onClick = _.noop } = button;
        let iconName = icon.name;
        const classNames = ['jpt--input input__text-button', _.isString(className) && className];

        title = title || 'Search...';
        iconName = iconName || 'search';

        if (!showComponent) return <div />;

        return (
            <div className={classNames.join(' ')}>
                <Input
                    size="small"
                    fluid
                    action={{ icon: iconName, onClick: onClick }}
                    placeholder={placeholder}
                    value={value}
                    type={isPassword ? 'password' : 'text'}
                    onChange={(e, data) => {
                        data = Object.assign({}, data, {
                            path,
                            fieldname: current,
                        });
                        onChange(e, data);
                        this.handleChange(e, data);
                    }}></Input>

                <div className={'input__message ' + (errorMessage ? 'input__message--error' : '')}>
                    {errorMessage}
                </div>

                {this.props.isEditingField && <FieldAction path={path} field={option} />}
            </div>
        );
    }
}

export default compose(withCaptureFieldHandlers)(InputWithButton);
