import _ from 'underscore';
import { compose, withPropsOnChange, lifecycle, withHandlers, mapProps } from 'recompose';
import { countYears } from '../datetime';

const withOnValuesChange = compose(
    withHandlers({
        getYearCountMethodLogic: () => props => {
            if (!props.extend) return;

            const { extend } = props;
            const yearCountMethodLogic = Object.values(extend).find(logic => {
                if (logic.method === 'year') {
                    return logic;
                }
            });

            return yearCountMethodLogic;
        },
        countAndSetYears: () => (props, yearCountMethodLogic) => {
            const {
                value,
                timezone,
                clearOnHide,
                showComponent,
                setLocalValue,
                updateValue,
            } = props;
            let { by, format, decimals = 1, to = 'today' } = yearCountMethodLogic;
            by = !_.isArray(by) ? [by] : by;
            const datetime = props.values[by[0]];
            const years = countYears({
                format,
                decimals,
                from: datetime,
                to,
                timezone,
            });
            const valueNotEqual = !_.isEqual(years, value);
            // if clearOnHide is set and component is hidden, don't update it.
            const shouldIgnoreYearCount = clearOnHide && !showComponent;
            if (valueNotEqual && !shouldIgnoreYearCount) {
                setLocalValue(years);
                updateValue({
                    value: years,
                    force: true,
                });
            }
        },
    }),
    lifecycle({
        // to checked extend.trigger.method === 'year'
        componentDidMount() {
            const { value, countAndSetYears, getYearCountMethodLogic } = this.props;
            const yearCountMethodLogic = getYearCountMethodLogic(this.props);
            if (!yearCountMethodLogic) return;
            let { by } = yearCountMethodLogic;
            by = !_.isArray(by) ? [by] : by;
            // update only when by (start date) defined, and years in business is not defined yet
            if (this.props.values[by[0]] && _.isEmpty(value)) {
                countAndSetYears(this.props, yearCountMethodLogic);
            }
        },
        componentDidUpdate(prevProps) {
            const { countAndSetYears, getYearCountMethodLogic } = this.props;
            const yearCountMethodLogic = getYearCountMethodLogic(this.props);
            if (!yearCountMethodLogic) return;
            if (_.isEqual(this.props.values, prevProps.value)) return;
            countAndSetYears(this.props, yearCountMethodLogic);
        },
    }),
    withPropsOnChange(['values'], props => {
        const { extend } = props;

        // stop function if extend isn't defined
        if (!_.isFunction(extend) && !_.isObject(extend)) {
            return {};
        }

        // get extendingProps value
        if (!props.generateExtendingProps) return;
        const extendingProps = props.generateExtendingProps();
        const withoutValue = _.clone(extendingProps);

        // remove .value to stop overwriting
        // props.value
        delete withoutValue.value;

        // spread to replace whatever props
        // inside extendingProps
        // e.g. value, isEditable, maxRows, max, and etc
        return {
            ...withoutValue,
            extendingProps,
        };
    }),
    mapProps(({ getYearCountMethodLogic, countAndSetYears, ...rest }) => ({
        ...rest,
    })),
);

export default withOnValuesChange;
