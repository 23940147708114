export const SET_FIELDSET = 'SET_FIELDSET';
export function setFieldset({ id, fieldset }) {
    return {
        type: SET_FIELDSET,
        payload: {
            id,
            fieldset,
        },
    };
}

export const SET_FIELDSETS = 'SET_FIELDSETS';
export function setFieldsets(fieldsets) {
    return {
        type: SET_FIELDSETS,
        fieldsets,
    };
}

export const SET_FIELDSETS_CHILDS = 'SET_FIELDSETS_CHILDS';
export function setFieldsetsChilds(fieldsetsFields) {
    return {
        type: SET_FIELDSETS_CHILDS,
        fieldsetsFields,
    };
}
