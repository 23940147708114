import React from 'react';

const Message = withReactSuspense(React.lazy(() => import('./Message')));
const History = withReactSuspense(React.lazy(() => import('./History')));
const HistoryDetails = withReactSuspense(React.lazy(() => import('./HistoryDetails')));

const Login = withReactSuspense(React.lazy(() => import('./Login')));
const SessionTimeout = withReactSuspense(React.lazy(() => import('./SessionTimeout')));
const Loading = withReactSuspense(React.lazy(() => import('./Loading')));
const Page404 = withReactSuspense(React.lazy(() => import('./Page404')));

const Template = withReactSuspense(React.lazy(() => import('./Template')));
const TemplateDetail = withReactSuspense(React.lazy(() => import('./TemplateDetail')));
const TemplateView = withReactSuspense(React.lazy(() => import('./TemplateView')));
const TemplateAccess = withReactSuspense(React.lazy(() => import('./TemplateAccess')));

const FieldsetDetails = withReactSuspense(React.lazy(() => import('./FieldsetDetails')));

const ActionForm = withReactSuspense(React.lazy(() => import('./ActionForm')));
const DashboardUser = withReactSuspense(React.lazy(() => import('./DashboardUser')));
const DelegateAccess = withReactSuspense(React.lazy(() => import('./DelegateAccess')));
const ApplicationForm = withReactSuspense(
    React.lazy(() => import('./ApplicationForm')),
    PreloadPage,
);
const SubmittedApplicationForm = withReactSuspense(
    React.lazy(() => import('./SubmittedApplicationForm')),
);
const Configuration = withReactSuspense(React.lazy(() => import('./Configuration')));
const Feature = withReactSuspense(React.lazy(() => import('./Feature')));
const User = withReactSuspense(React.lazy(() => import('./User')));
const ForgotPassword = withReactSuspense(React.lazy(() => import('./ForgotPassword')));
const ChangePassword = withReactSuspense(React.lazy(() => import('./ChangePassword')));
const ResetPassword = withReactSuspense(React.lazy(() => import('./ResetPassword')));
const Report = withReactSuspense(React.lazy(() => import('./Report')));
const Settings = withReactSuspense(React.lazy(() => import('./Settings')));
const SystemConfiguration = withReactSuspense(React.lazy(() => import('./SystemConfiguration')));
const EmailNotification = withReactSuspense(React.lazy(() => import('./EmailNotification.js')));
const BulkUpload = withReactSuspense(React.lazy(() => import('./BulkUpload')));
const BulkUploadResult = withReactSuspense(React.lazy(() => import('./BulkUploadResult')));

/**
 * A warpper to return lazy loaded component that with React.Suspense
 * @param {ReactElement} LazyComponent - lazy component to be rendered
 * @param {ReactElement} FallbackComponent - fallback compmonent to be rendered when lazy component is not ready
 * @returns {ReactElement} React.Suspense
 */
function withReactSuspense(LazyComponent, FallbackComponent) {
    FallbackComponent = FallbackComponent ? <FallbackComponent /> : <div />;
    return function Component(props) {
        return (
            <React.Suspense fallback={FallbackComponent}>
                <LazyComponent {...props} />
            </React.Suspense>
        );
    };
}

export {
    Message,
    History,
    HistoryDetails,
    Login,
    SessionTimeout,
    Loading,
    Page404,
    Template,
    TemplateDetail,
    TemplateView,
    TemplateAccess,
    FieldsetDetails,
    ActionForm,
    DashboardUser,
    DelegateAccess,
    ApplicationForm,
    SubmittedApplicationForm,
    Configuration,
    Feature,
    User,
    ForgotPassword,
    ChangePassword,
    ResetPassword,
    Report,
    Settings,
    SystemConfiguration,
    EmailNotification,
    BulkUpload,
    BulkUploadResult,
    withReactSuspense,
};

function PreloadPage() {
    return <div className="preload-page" />;
}
