import React from 'react';
import _ from 'underscore';
import PropTypes from 'prop-types';
import ProductMIDTID from './ProductMIDTID';

import { compose, lifecycle, withPropsOnChange, shouldUpdate } from 'recompose';
import store from '../../../store';
import { getTriggerDependants } from '../../../redux/selectors';
import { setTriggeredDependants } from '../../../redux/actions/TriggerAction';
import { withProcessedProps } from '../../Misc/forms';
import withMIDTIDReplaceValidityCheck from './withMIDTIDReplaceValidityCheck';

const MID_TID_ID = 'products';
const SELECTED_BY = 'product_types';

const EnhancedProductMIDTID = compose(
    shouldUpdate((current, next) => {
        return !_.isEqual(current, next);
    }),
)(ProductMIDTID);

const MIDTID = ({
    products,
    id,
    initialId,
    valueId,
    setId,
    tabId,
    setPath,
    tabPath,
    bold,
    value,
    validity,
    triggers,
    triggeredStates,
    selected,
    isParentEditable,
    isEditable,
    isRequired,
    extend,
    // util
    showComponent,
}) => {
    valueId = valueId || initialId;
    // logic to handle isEditable that's object.
    //   when parent is not editable
    //   field will be not editable
    if (!isParentEditable) {
        isEditable = false;
    }

    const classNames = ['input__midtid', !selected.length && 'no-mid-tid'];

    if (!showComponent) return <div />;

    return (
        <div className={classNames.join(' ')} id={id}>
            {products.map((p, i) => {
                let foundValueIndex = 0;
                const foundSelectedIndex = selected.indexOf(p.value);
                const foundValue = _.find(value, (v, i) => {
                    if (v && v.product === p.value) {
                        foundValueIndex = i;
                        return true;
                    }
                });
                const foundValidity = _.find(validity, v => {
                    if (v && v.product === p.value) {
                        return true;
                    }
                });
                const isProductEditable = _.isBoolean(isEditable)
                    ? isEditable
                    : isEditable[p.value];
                const isProductRequired = isRequired;
                // do not need to render when it's not selected
                if (foundSelectedIndex < 0) {
                    return false;
                }

                // when there's no value but with validity
                if (!foundValue) {
                    foundValueIndex = foundSelectedIndex;
                }

                const productSetPath =
                    setPath && setPath !== initialId
                        ? `${ setPath }.${ initialId }.${ foundValueIndex }`
                        : `${ initialId }.${ foundValueIndex }`;
                const productTabPath =
                    tabPath && tabPath !== valueId
                        ? `${ tabPath }.${ valueId }.${ foundValueIndex }`
                        : `${ initialId }.${ foundValueIndex }`;

                return (
                    <EnhancedProductMIDTID
                        key={p.value || i}
                        product={p.value}
                        label={{ title: p.title }}
                        bold={bold}
                        id={`${ id }.${ foundValueIndex }`}
                        initialId={initialId}
                        valueId={initialId}
                        index={foundValueIndex}
                        setId={setId}
                        tabId={tabId}
                        setPath={productSetPath}
                        tabPath={productTabPath}
                        value={foundValue}
                        validity={foundValidity}
                        isEditable={isProductEditable}
                        isRequired={isProductRequired}
                        trigger={triggers}
                        triggeredStates={triggeredStates[foundValueIndex]}
                        extend={extend}
                    />
                );
            })}
            {!selected.length && <div>No MID TID</div>}
        </div>
    );
};

MIDTID.propTypes = {
    isEditable: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    isRequired: PropTypes.object,
};

MIDTID.defaultProps = {
    id: MID_TID_ID,
    products: [],
    bold: true,
    selected: [],
    value: [],
    validity: [],
    triggers: {},
    triggeredStates: [],
    isParentEditable: true,
    isEditable: {},
    isRequired: {},
    showComponent: true,
};

export default compose(
    // need this to be on top, so that id (path)
    //  that's being use by other recompose is right
    withProcessedProps,
    withPropsOnChange(['products'], ({ products }) => {
        if (_.isArray(products)) return {};
        const { list_id } = products || {};
        const { lists = {} } = store.getState().list || {};

        return {
            products: lists[list_id],
        };
    }),
    withPropsOnChange(['selected', 'values'], ({ selected, values = {} }) => {
        if (_.isArray(selected)) return {};
        const { by = SELECTED_BY } = selected || {};

        return {
            selected: values[by],
        };
    }),
    lifecycle({
        componentDidMount() {
            const { id = MID_TID_ID, selectedBy = SELECTED_BY } = this.props;
            // set dependants
            const state = store.getState();
            const dependants = getTriggerDependants(state)[selectedBy] || [];

            if (dependants.indexOf(id) < 0) {
                dependants.push(id);
                store.dispatch(
                    setTriggeredDependants({
                        [selectedBy]: [...dependants],
                    }),
                );
            }
        },
    }),
    withMIDTIDReplaceValidityCheck,
)(MIDTID);
