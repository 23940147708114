/**
 * A function that return a boolean value that indicate whether given option should be displayed in UI.
 * - show deprecated option when it's disabled + selected
 * - show non deprecated option every time
 * - hide deprecated option when it's editable
 * @param {Object} properties - The properties that's required for showOption value generation
 * @param {Object} properties.option - The dropdown option.
 * @param {boolean} properties.isEditable - The state either option is editable or not.
 * @param {boolean} properties.isSelected - The state either option is selected or not.
 * @param {(boolean|undefined)} properties.option.deprecated - The deprecated state of the option.
 * @returns {boolean} showOption
 */
function checkShowOption({ isEditable, option, isSelected }) {
    if (isEditable) return !option.deprecated;
    return !option.deprecated || (!!option.deprecated && isSelected);
}

export default checkShowOption;
