import _ from 'underscore';
import ObjectPath from 'object-path';
import { getValues } from '../../../redux/selectors';
import store from '../../../store';
import { setValue } from '../../../redux/actions/ValueAction';

/**
 * A method that update redux value.values of given 'ids'.
 *
 * @param {string[] | Object[]} ids - e.g. ["field_rn1234", "field_rn3241"] or [{ id: "field_rn1234", trim: 20 }, { ... }]
 * @param {any} value - value to be updated
 * @param {function} onValueChanged - handler that update redux value.values
 *
 * @returns {string[]} changedIds - [] | ['field_rn1234', 'field_rn1234']
 */
export default function setValueToIds({ ids = [], path, value, onValueChanged = setReduxValue }) {
    let changedIds = [];
    const isEmptyIds = !ids.length;
    if (isEmptyIds || !_.isArray(ids)) {
        return changedIds;
    }
    // To set exact same value to the field ids in setValueToIds
    const targetToSetValueExist = ids && value;
    if (!targetToSetValueExist) {
        return changedIds;
    }

    const reduxValues = getValues(store.getState());

    // here, a fullId means path + id if path exist
    const getFullId = id => (path ? `${ path }.${ id }` : id);
    const getReduxValueByFullId = fullId => ObjectPath.get(reduxValues, fullId);

    // loop and set each targeted id value into redux state
    changedIds = ids.filter(target => {
        let targetId = target;
        let trim;
        let newValue = value;

        // process target: { id, trim, value } if exist
        if (_.isObject(target)) {
            targetId = target.id;
            newValue = !_.isUndefined(target.value) ? target.value : newValue;
            trim = parseInt(target.trim);
        }

        const targetFullId = getFullId(targetId);
        const targetValue = getReduxValueByFullId(targetFullId);

        if (trim) {
            newValue = newValue.slice(0, trim);
        }

        // target redux value is different compare with the value to be changed to
        const targetValueIsDiff = targetValue !== newValue;
        if (targetValueIsDiff) {
            onValueChanged({ id: getFullId(targetId), value: newValue });
            return targetId;
        }
        return false;
    });

    return changedIds;
}

function setReduxValue({ id, value }) {
    store.dispatch(setValue({ path: id, value }));
}
