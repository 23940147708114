import React from 'react';
import _ from 'underscore';
import { compose, shouldUpdate, withHandlers, lifecycle } from 'recompose';
import { connect } from 'react-redux';

import store from '../store';
import { actionSetConnectionAvailability } from '../redux/actions/PageAction';
import { getIsOnline } from '../redux/selectors/PageSelector';
import { ImageWarning } from '../images/actions';
import { addResumeFormInLocalStorage } from 'services/SystemIdle';
import SETTINGS from '../settings';

const { DISPLAY_OFFLINE_POP_UP } = SETTINGS;
const OFFLINE_MESSAGE = 'You\'re offline';

const ConnectionIndicator = ({ className, isOnline = true }) => {
    const classnames = [
        'connection-indicator-container',
        !isOnline ? 'show' : '',
        _.isString(className) ? className : '',
    ];
    return (
        <div className={classnames.join(' ')}>
            <div className="connection-warning-icon">
                <img src={ImageWarning} />
            </div>
            <div className="connection-header">{OFFLINE_MESSAGE}</div>
        </div>
    );
};

const withConnectionStatus = compose(
    connect(store => {
        const isOnline = getIsOnline(store);
        return {
            isOnline,
        };
    }, null),
    shouldUpdate((current, next) => {
        return !_.isEqual(current, next);
    }),
    lifecycle({
        componentDidMount() {
            const { setOnline, setOffline } = this.props;
            if (DISPLAY_OFFLINE_POP_UP) {
                registerConnectionListeners({ onOnline: setOnline, onOffline: setOffline });
            }
        },
        componentWillUnmount() {
            const { setOnline, setOffline } = this.props;
            if (DISPLAY_OFFLINE_POP_UP) {
                unregisterConnectionListeners({ onOnline: setOnline, onOffline: setOffline });
            }
        },
    }),
);

const withResumeFormOptionOnOffline = compose(
    lifecycle({
        componentDidUpdate() {
            const { isOnline } = this.props;
            const isOffline = isOnline === false;

            if (isOffline) {
                addResumeFormInLocalStorage();
            }
        },
    }),
);

export const withConnectionCheckHandlers = withHandlers({
    setOnline: () => () => {
        store.dispatch(actionSetConnectionAvailability(true));
    },
    setOffline: () => () => {
        store.dispatch(actionSetConnectionAvailability(false));
    },
});

export default ConnectionIndicator;
const EnhancedConnectionIndicator = compose(
    withConnectionCheckHandlers,
    withConnectionStatus,
    withResumeFormOptionOnOffline,
)(ConnectionIndicator);

export { EnhancedConnectionIndicator };

function registerConnectionListeners({ onOnline, onOffline }) {
    window.addEventListener('online', onOnline);
    window.addEventListener('offline', onOffline);
}

function unregisterConnectionListeners({ onOnline, onOffline }) {
    window.removeEventListener('online', onOnline);
    window.removeEventListener('offline', onOffline);
}
