import React from 'react';

import { compose } from 'recompose';
import { withCaptureFieldHandlers } from '../Misc/forms';
import { FieldAction } from '../Misc/template';

class BreakLine extends React.Component {
    render() {
        const { className, path } = this.props;
        const classNames = ['jpt--input form__break', className];

        return (
            <div className={classNames.join(' ')}>
                {this.props.isEditingField && <FieldAction path={path} edit={false} />}
            </div>
        );
    }
}

export default compose(withCaptureFieldHandlers)(BreakLine);
