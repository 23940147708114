import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ApmRoute } from '@elastic/apm-rum-react';
import store, { persistor } from './store';

// tooltip
import ReactHintFactory from 'react-hint';
import 'react-hint/css/index.css';
// Page components
import NavigationBar from './components/NavigationBar';
import Footer from './components/Footer';
import { EnhancedConnectionIndicator } from './components/ConnectionIndicator';
// Pages
import * as Pages from './containers';
const CaptureEntry = Pages.withReactSuspense(React.lazy(() => import('./capture-entry')));
// tooltip instance
const ReactHint = ReactHintFactory(React);

export default class App extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    render() {
        return (
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <BrowserRouter>
                        <div id="capture">
                            <NavigationBar logo={this.props.logo} />
                            <EnhancedConnectionIndicator className="no-delay" />
                            <div className="content__wrapper">
                                <div style={{ zIndex: 2 }}>
                                    <ReactHint events delay={200} />
                                </div>
                                <Route exact path="*" component={Pages.Loading}></Route>
                                <Route exact path="*" component={Pages.ActionForm}></Route>
                                <Route exact path="*" component={Pages.SessionTimeout}></Route>

                                <Switch>
                                    <ApmRoute exact path="/watchlist" component={CaptureEntry} />
                                    <ApmRoute exact path="/message" component={Pages.Message} />
                                    <ApmRoute exact path="/report" component={Pages.Report} />
                                    <ApmRoute
                                        exact
                                        path="/history/:templateId/:formId"
                                        component={Pages.History}
                                    />
                                    <ApmRoute
                                        exact
                                        path="/history/details/:historyId"
                                        component={Pages.HistoryDetails}
                                    />

                                    <Route exact path="/login" component={Pages.Login} />

                                    <ApmRoute exact path="/template" component={Pages.Template} />
                                    <ApmRoute
                                        exact
                                        path="/template/:templateId"
                                        component={Pages.TemplateDetail}
                                    />
                                    <ApmRoute
                                        exact
                                        path="/template/:templateId/view/:viewId"
                                        component={Pages.TemplateView}
                                    />
                                    <ApmRoute
                                        exact
                                        path="/template/:templateId/access/:accessId"
                                        component={Pages.TemplateAccess}
                                    />
                                    <ApmRoute
                                        exact
                                        path="/template/:templateId/fieldset/:fieldsetId"
                                        component={Pages.FieldsetDetails}
                                    />

                                    <ApmRoute exact path="/" component={Pages.DashboardUser} />
                                    <ApmRoute
                                        exact
                                        path="/delegate-access"
                                        component={Pages.DelegateAccess}
                                    />
                                    <ApmRoute
                                        exact
                                        path="/email-notification"
                                        component={Pages.EmailNotification}
                                    />
                                    <ApmRoute
                                        exact
                                        path="/config"
                                        component={Pages.Configuration}
                                    />
                                    <ApmRoute exact path="/user" component={Pages.User} />
                                    <ApmRoute
                                        exact
                                        path="/form/:templateId?"
                                        component={Pages.ApplicationForm}
                                    />
                                    <ApmRoute
                                        exact
                                        path="/submitted/:templateId?/:formId?"
                                        component={Pages.SubmittedApplicationForm}
                                    />
                                    <ApmRoute exact path="/feature" component={Pages.Feature} />
                                    <ApmRoute
                                        exact
                                        path="/forgot-password"
                                        component={Pages.ForgotPassword}
                                    />
                                    <ApmRoute
                                        exact
                                        path="/system-configuration"
                                        component={Pages.SystemConfiguration}
                                    />
                                    <ApmRoute
                                        exact
                                        path="/change-password"
                                        component={Pages.ChangePassword}
                                    />
                                    <ApmRoute
                                        exact
                                        path="/reset-password"
                                        component={Pages.ResetPassword}
                                    />
                                    <ApmRoute exact path="/themes" component={Pages.Settings} />
                                    <ApmRoute
                                        exact
                                        path="/bulk-upload"
                                        component={Pages.BulkUpload}
                                    />
                                    <ApmRoute
                                        exact
                                        path="/bulk-upload/:uploadId?"
                                        component={Pages.BulkUploadResult}
                                    />

                                    <ApmRoute component={Pages.Page404} />
                                </Switch>
                            </div>
                            <Footer />
                        </div>
                    </BrowserRouter>
                </PersistGate>
            </Provider>
        );
    }
}
