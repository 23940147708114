import BaseAPI from '../BaseAPI';

export class TemplateViewAPI extends BaseAPI {
    basePath = 'cap/templates/';

    // get list of views
    async getViews(templateId) {
        const { views } = await this.get({
            path: `${ this.basePath }${ templateId }/views/`,
        });
        return views;
    }

    // get current view details
    async getCurrentView(templateId, viewId) {
        const { view } = await this.get({
            path: `${ this.basePath }${ templateId }/views/${ viewId }/`,
        });
        return view;
    }

    // get created fieldset sections list
    // can use FieldsetApi / SectionApi instead
    async getFieldsetSections(templateId) {
        const { sections } = await this.get({
            path: `${ this.basePath }${ templateId }/sections/`,
        });
        return sections;
    }

    async updateSection(templateId, viewId, view) {
        const response = await this.patch({
            path: `${ this.basePath }${ templateId }/views/${ viewId }/`,
            data: view,
        });
        const { sections } = response.view;
        return sections;
    }

    async deleteFieldsetSection(templateId, viewId, sectionId) {
        const sections = await this.delete({
            path: `${ this.basePath }${ templateId }/views/${ viewId }/${ sectionId }/`,
        });
        return sections;
    }
}

export const templateViewAPI = new TemplateViewAPI();
export default TemplateViewAPI;
