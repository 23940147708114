const { captureConfiguration = {} } = window;
const CAPTURE_CONFIGURATION = { ...captureConfiguration };
const PROCESS_ENV = { ...process.env };

const CLIENT_CONFIGURABLE_KEYS = [
    'SESSION_EXPIRY_WARNING_INTERVALS',
    'ACTIVITY_IDLE_TIMEOUT',
    'DISPLAY_DOWNLOAD_POP_UP',
    'DISPLAY_OFFLINE_POP_UP',
];
const PREFIX_REACT_APP = 'REACT_APP_';
const REGEX_PREFFIX_REACT_APP = new RegExp(`^${ PREFIX_REACT_APP }*`);

const settings = {};
// Process both CAPTURE_CONFIGURATION and PROCESS_ENV here
Object.keys(PROCESS_ENV).forEach(function(key) {
    const isStartedWithReactAppText = key.match(REGEX_PREFFIX_REACT_APP);
    if (isStartedWithReactAppText) {
        // Remove 'REACT_APP_' prefix, e.g. REACT_APP_API_URL -> API_URL
        const processedKey = key.replace(PREFIX_REACT_APP, '');
        if (CLIENT_CONFIGURABLE_KEYS.includes(processedKey)) {
            settings[processedKey] = getDefined(
                CAPTURE_CONFIGURATION[processedKey],
                PROCESS_ENV[key],
            );
        } else if (getDefined(PROCESS_ENV[key])) {
            settings[processedKey] = PROCESS_ENV[key];
        }
    }
});

const API_URL = settings.API_URL;
const CLIENT = settings.CLIENT;

// Send JSON Diff patch setting
const SEND_DELTA = getDefined(parseBoolEnv(settings.SEND_DELTA), false);

// Send JSON Diff patch setting
const SHOW_BETA_TAG = getDefined(parseBoolEnv(settings.SHOW_BETA_TAG), false);

// reCAPTCHA settings
const RECAPTCHA_SITE_KEY = getDefined(settings.RECAPTCHA_SITE_KEY, 'null'); // follow previous way of handling sitekey error
const IS_RECAPTCHA = getDefined(parseBoolEnv(settings.IS_RECAPTCHA), false); // default false
const IS_FORGOT_PASSWORD = getDefined(parseBoolEnv(settings.IS_FORGOT_PASSWORD), false); // default false
// RUM
const IS_RUM = getDefined(parseBoolEnv(settings.IS_RUM), false); // default false
const RUM_SERVER_URL = getDefined(settings.RUM_SERVER_URL, 'null'); // follow previous way of handling serverUrl error
// Form
const FORM_HISTORY_ENABLED = getDefined(parseBoolEnv(settings.FORM_HISTORY_ENABLED), true); // default false
const FORM_HISTORY_CORE_COLUMNS = getDefined(
    parseBoolEnv(settings.FORM_HISTORY_CORE_COLUMNS),
    true,
); // default true
const FORM_HISTORY_DETAILS_ENABLED = getDefined(
    parseBoolEnv(settings.FORM_HISTORY_DETAILS_ENABLED),
    false,
); // default false

// Pop up
const ACTIVITY_IDLE_TIMEOUT = Math.floor(
    getDefined(parseInt(settings.ACTIVITY_IDLE_TIMEOUT), 480) * 1000,
); // default 480
const DISPLAY_DOWNLOAD_POP_UP = getDefined(parseBoolEnv(settings.DISPLAY_DOWNLOAD_POP_UP), true); // default true
const DISPLAY_OFFLINE_POP_UP = getDefined(parseBoolEnv(settings.DISPLAY_OFFLINE_POP_UP), true); // default true
// process data
const sessionWarnTimeBeforeTimeout = getDefined(
    processArrayValue(settings.SESSION_EXPIRY_WARNING_INTERVALS, 'number'),
    [300, 60],
); // default [300, 60]
// check is sessionWarnTimeBeforeTimeout in array format
if (!Array.isArray(sessionWarnTimeBeforeTimeout)) {
    console.error('SESSION_EXPIRY_WARNING_INTERVALS should be array [number, number...] format.');
}
// convert sessionWarnTimeBeforeTimeout to ms
const SESSION_EXPIRY_WARNING_INTERVALS = sessionWarnTimeBeforeTimeout.map(
    seconds => Math.floor(seconds) * 1000,
);
const SESSION_FIRST_WARN_TIME_BEFORE_TIMEOUT = Math.max(...SESSION_EXPIRY_WARNING_INTERVALS);

const SETTINGS = {
    API_URL,
    CLIENT,
    DISPLAY_OFFLINE_POP_UP,
    DISPLAY_DOWNLOAD_POP_UP,
    SESSION_EXPIRY_WARNING_INTERVALS,
    SESSION_FIRST_WARN_TIME_BEFORE_TIMEOUT,
    ACTIVITY_IDLE_TIMEOUT,
    RECAPTCHA_SITE_KEY,
    IS_RECAPTCHA,
    IS_FORGOT_PASSWORD,
    IS_RUM,
    RUM_SERVER_URL,
    FORM_HISTORY_ENABLED,
    FORM_HISTORY_CORE_COLUMNS,
    FORM_HISTORY_DETAILS_ENABLED,
    SEND_DELTA,
    SHOW_BETA_TAG,
};

export default SETTINGS;

/**
 * A method to return the first defined parameters
 *
 * @example
 * getDefined(0, 1) = 0, first argument is returned
 * getDefined(undefined, 0) = 0, second argument is returned
 * @returns {any} arg - first defined param
 */
export function getDefined() {
    let args = [...arguments];
    if (!args) return;
    if (!Array.isArray(args)) {
        args = [args];
    }

    const definedIndex = args.findIndex(function(value) {
        // handle NaN
        if (typeof value === 'number' && !value) {
            value = 0;
        } else if (Array.isArray(value) && !value.length) {
            // handle [] empty array
            value = null;
        }

        // handle 0, undefined, and null
        const isDefined = value === 0 || (value !== undefined && value !== null);

        return isDefined;
    });

    if (definedIndex < 0) return;

    return args[definedIndex];
}

/**
 * A method to process env variable value, to return
 * boolean if 'true' or 'false',
 * array if comma ',' separated e.g '300, 60'
 *
 * @param {string} value - env variable value in string format
 * @param {string} type - value type. 'number', 'string', 'boolean'
 * @returns {any} processedArrayValue
 */
export function processArrayValue(value, type = 'string') {
    if (!value) return [];
    // if comma separated
    if (value.includes(',')) {
        return value.split(',').map(v => processArrayValue(v, type));
    }

    const isBoolean = type === 'boolean';
    const isNumber = type === 'number';

    // if 'true' or 'false'
    if (isBoolean) {
        return parseBoolEnv(value);
    } else if (isNumber) {
        return parseInt(value);
    }

    return value;
}

export function parseBoolEnv(value) {
    return value && value.toLowerCase() === 'true';
}
