export const SET_VALUE = 'SET_VALUE';
export function setValue({ path, value }) {
    return {
        type: SET_VALUE,
        payload: {
            path,
            value,
        },
    };
}

export function delayedSetValue(value) {
    // I don't know why, but delaying the dispatch to the next tick makes the
    // postal code triggers work properly on the first trigger instead of
    // subsequent triggers.
    return dispatch => {
        setTimeout(() => dispatch(setValue(value)), 0);
    };
}

export const SET_VALUES = 'SET_VALUES';
export function setValues(values) {
    return {
        type: SET_VALUES,
        values,
    };
}

export const SET_CUSTOM_VALUES = 'SET_CUSTOM_VALUES';
export function setCustomValues(customDefaultValue) {
    return {
        type: SET_CUSTOM_VALUES,
        customDefaultValue,
    };
}

export const SET_PREV_CUSTOM_VALUES = 'SET_PREV_CUSTOM_VALUES';
export function setPrevCustomValues(prevCustomDefaultValue) {
    return {
        type: SET_PREV_CUSTOM_VALUES,
        prevCustomDefaultValue,
    };
}

export const SET_VALUES_ERROR = 'SET_VALUES_ERROR';
const setValuesError = error => ({
    type: SET_VALUES_ERROR,
    error,
});

export function clearValidatorErrors() {
    return setValuesError(null);
}
