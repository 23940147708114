import {
    SET_ERROR,
    GET_ROLES,
    GET_GROUPS,
    GET_USERS,
    GET_CONTEXT,
    GET_ACTION_CONFIGURATIONS,
    SET_USER,
    SET_USER_FEATURE_GROUP,
    SET_USER_FEATURE,
    SET_USER_DEFAULT_FEATURE,
    SET_USER_ROLE,
    SET_IS_USER_EDITABLE,
    SET_MY_CLIENT,
} from '../actions/UserAction';

import { SET_USER_INFO, SET_TOKEN, SET_EXPIRY_DATE } from '../actions/AuthenticationAction';

const INITIAL_STATE = {
    reducer: 'user',
    users: {},
    actionConfigs: [],
    context: {},
    roles: [],
    groups: [],
    role: {},
    featureGroups: [],
    defaultFeature: {},
    error: null,
    isUserEditable: null,
    client: undefined,
};

export default function UserReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_ERROR:
            return {
                ...state,
                error: action.error,
            };
        case GET_ROLES: {
            return {
                ...state,
                roles: action.roles,
            };
        }
        case GET_GROUPS: {
            return {
                ...state,
                groups: action.groups,
            };
        }
        case GET_USERS: {
            return {
                ...state,
                users: action.users,
            };
        }
        case GET_ACTION_CONFIGURATIONS: {
            return {
                ...state,
                actionConfigs: action.actionConfigs,
            };
        }
        case GET_CONTEXT: {
            return {
                ...state,
                context: action.context,
            };
        }
        case SET_USER: {
            return {
                ...state,
                users: action.users,
            };
        }
        case SET_USER_INFO: {
            return {
                ...state,
                profile: action.payload,
            };
        }
        case SET_TOKEN: {
            return {
                ...state,
                token: action.token,
            };
        }
        case SET_EXPIRY_DATE: {
            return {
                ...state,
                expiryDate: action.expiryDate,
            };
        }
        case SET_USER_FEATURE_GROUP: {
            return {
                ...state,
                featureGroups: action.featureGroups,
            };
        }
        case SET_USER_DEFAULT_FEATURE: {
            return {
                ...state,
                defaultFeature: action.defaultFeature,
            };
        }
        case SET_USER_FEATURE: {
            return {
                ...state,
                features: action.features,
            };
        }
        case SET_USER_ROLE: {
            return {
                ...state,
                role: action.role,
            };
        }
        case SET_IS_USER_EDITABLE: {
            return {
                ...state,
                isUserEditable: action.isUserEditable,
            };
        }
        case SET_MY_CLIENT: {
            return {
                ...state,
                client: action.client,
            };
        }
        default:
            return state;
    }
}
