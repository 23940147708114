import React from 'react';

import ObjectAssign from 'object-assign';
import _ from 'underscore';

import { compose } from 'recompose';
import { Input, Icon, Label } from 'semantic-ui-react';
import { withCaptureFieldHandlers, NULL_VALUE_TEXT } from '../Misc/forms';
import { FieldAction } from '../Misc/template';

class InputField extends React.Component {
    static defaultProps = {
        className: '',
        fieldname: {},
        placeholder: '',
        isValid: true,
    };

    constructor(props) {
        super(props);

        this.handleChange = this.props.handleChange.bind(this);
    }

    render() {
        const classWarning = 'warning';
        const {
            id,
            className,
            fieldname,
            // option
            type,
            title,
            name,
            value,
            placeholder,
            icon,
            isValid,
            errorMessage,
            onChange,
            message,
            isPassword,
            isEditable,
            // utils
            showComponent,
            path,
        } = this.props;
        const option = {
            type,
            title,
            name,
            value,
            placeholder,
            message,
            isPassword,
        };
        const { current } = fieldname;
        const iconName = icon ? icon.name : null;
        const iconPosition = icon ? icon.position : null;
        const classNames = [
            'jpt--input input__text input__text--field',
            _.isString(className) && className,
            !isValid && classWarning,
            !value && !isEditable && 'null-value',
        ];

        if (!showComponent) return <div />;

        return (
            <div className={classNames.join(' ')} id={id}>
                {isEditable && (
                    <Input
                        ref={input => {
                            this.input = input;
                        }}
                        name={name}
                        placeholder={placeholder}
                        iconPosition={iconPosition}
                        type={isPassword ? 'password' : 'text'}
                        onChange={(e, data) => {
                            data = ObjectAssign({}, data, {
                                path,
                                fieldname: current,
                            });
                            onChange(e, data);
                            this.handleChange(e, data);
                        }}>
                        {icon && <Icon name={iconName} />}
                        <input value={value} name={name} />
                    </Input>
                )}
                {!isEditable && (
                    <Label className="input__label--value">{value || NULL_VALUE_TEXT}</Label>
                )}

                <div className={'input__message ' + (errorMessage ? 'input__message--error' : '')}>
                    {errorMessage}
                </div>

                {this.props.isEditingField && <FieldAction path={path} field={option} />}
            </div>
        );
    }
}

export default compose(withCaptureFieldHandlers)(InputField);
