import { createSelector } from 'reselect';
import { RootState } from 'store';

const sectionChildrenSelector = (state: RootState) => state.section.sectionChildren;
const sectionChildrenByIdSelector = (state: RootState, id: string) =>
    state.section.sectionChildren[id];
const sectionCreationSelector = (state: RootState) => state.section.creation;

export const getSectionsItems = createSelector(
    sectionChildrenSelector,
    sectionChildren => sectionChildren,
);

export const getSectionsItemsById = createSelector(
    sectionChildrenByIdSelector,
    sectionChildren => sectionChildren,
);

export const getSectionCreation = createSelector(sectionCreationSelector, creation => creation);
