import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _ from 'underscore';
import classnames from '../Misc/utils/classnames';
import TreeMenuToolTip from './TreeMenuToolTip';

const StyledTreeMenu = styled.div`
    @keyframes tree-menu-content-collapsed {
        from {
            max-height: 9999px;
        }
        to {
            max-height: 0px;
        }
    }

    display: inline-block;
    width: 100%;
    vertical-align: top;
    border-radius: 5px;

    .tree-menu {
        padding-left: 16px;
        padding-right: 0px;
    }

    &.collapsed .tree-menu-content {
        transition: max-height 0.37s ease-out;
        overflow: hidden;
        max-height: 0;
        padding-bottom: 0;
    }
`;

const StyledTreeMenuTitle = styled.div`
    font-size: 1em;
    line-height: 1.5em;
    transition: all 0.37s ease-out;
    padding: 4px 8px;
    display: flex;
    justify-content: space-between;

    .collapse-icon {
        display: none;
    }

    &.collapsible {
        font-weight: bold;
        cursor: pointer;
        border-bottom: solid 1px #dbdbdb;

        &:hover {
            background-color: #dbdbdb;
        }

        .collapse-icon {
            display: block;
            cursor: pointer;
        }

        &:hover {
            box-shadow: 0 0 1px #000;

            ~ .tree-menu-content {
                box-shadow: 0 0 1px #000;
            }
        }
    }

    .title-container {
        display: flex;
    }
    .title-text {
        margin-right: 8px;
    }
    .title-note {
        margin-right: 4px;

        &-text,
        &-label-left,
        &-label-right {
            display: inline-block;
            margin-right: 4px;
        }
    }
`;

const StyledTreeMenuContent = styled.div`
    display: block;
    max-height: 9999px;
`;

class TreeMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: false,
        };
        this.content = React.createRef();
        this.handleClick = this.handleClick.bind(this);
        this.handleCollapse = this.handleCollapse.bind(this);
        this.handleMenuContentHeight = this.handleMenuContentHeight.bind(this);
        this.handleResize = _.debounce(this.handleResize.bind(this), 300);
    }

    handleClick() {
        // no children, not collapsible
        const { menuChildren, children } = this.props;
        if (!children && !menuChildren.length) return;
        this.setState(state => ({
            collapsed: !state.collapsed,
        }));
        this.handleCollapse(!this.state.collapsed);
    }

    handleCollapse(collapsed) {
        const menuContentElement = this.content.current;
        if (collapsed) {
            menuContentElement.style.maxHeight = '0px';
        } else {
            menuContentElement.style.maxHeight = `${ this.state.height }px`;
        }
    }

    handleMenuContentHeight() {
        const menuContentElement = this.content.current;
        const elementHeight = menuContentElement.getBoundingClientRect().height;
        if (this.state.height === elementHeight) return;
        this.setState(() => ({
            height: elementHeight,
        }));
    }

    handleResize() {
        // no children, not collapsible, no resize needed
        const { menuChildren, children } = this.props;
        if (!children && !menuChildren.length) return;
        const menuContentElement = this.content.current;
        menuContentElement.style.maxHeight = null;
        const elementHeight = menuContentElement.getBoundingClientRect().height;
        if (this.state.height === elementHeight) return;
        this.setState(() => ({
            height: elementHeight,
        }));
        if (menuContentElement) {
            menuContentElement.style.maxHeight = `${ elementHeight }px`;
        }
    }

    componentDidMount() {
        this.handleMenuContentHeight();
        // to handle resize to set new max height
        window.addEventListener('resize', this.handleResize);
    }

    componentDidUpdate() {
        if (!this.state.collapsed && !this.state.height) {
            this.handleMenuContentHeight();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    render() {
        const { collapsed } = this.state;
        const {
            className,
            title,
            menuChildren,
            value,
            note,
            children,
            overrideTotalScoreHighest,
        } = this.props;
        const { title: noteTitle, position: notePosition = 'left' } = note;
        const classNames = [className, collapsed && 'collapsed'];

        return (
            <StyledTreeMenu className={classnames('tree-menu', classNames)}>
                <StyledTreeMenuTitle
                    className={classnames('tree-menu-title', [
                        (children || menuChildren.length) && 'collapsible',
                    ])}
                    onClick={this.handleClick}>
                    <div className="title-container">
                        <div className="title-text">{title}</div>
                        {overrideTotalScoreHighest && (
                            <TreeMenuToolTip
                                position="right"
                                size="medium"
                                text='<strong>Override Total Score</strong><br/><br/><div style=\"padding-left: 1em;\">This risk score results in a total score override.</div>'
                            />
                        )}
                    </div>
                    <div className="title-note">
                        {notePosition === 'left' && (
                            <div className="title-note-label-left">{noteTitle}</div>
                        )}
                        <div className="title-note-text">{value}</div>
                        {notePosition === 'right' && (
                            <div className="title-note-label-right">{noteTitle}</div>
                        )}
                    </div>
                </StyledTreeMenuTitle>
                <StyledTreeMenuContent className="tree-menu-content" ref={this.content}>
                    {menuChildren.map(({ title, children, value, note }, i) => (
                        <TreeMenu
                            key={i}
                            title={title}
                            value={value}
                            menuChildren={children}
                            note={note}
                        />
                    ))}
                    {children}
                </StyledTreeMenuContent>
            </StyledTreeMenu>
        );
    }
}

TreeMenu.defaultProps = {
    title: null,
    value: null,
    menuChildren: [],
    note: {
        title: null,
        position: 'left',
    },
    children: null,
};

TreeMenu.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
    menuChildren: PropTypes.array,
    note: PropTypes.shape({
        title: PropTypes.string,
        position: PropTypes.oneOf(['left', 'right', undefined]),
    }),
    children: PropTypes.node,
};

export default TreeMenu;
