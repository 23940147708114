import React from 'react';
import { Link } from 'react-router-dom';
import { PagePath } from '../components/GoToPage';
import Menu from './Menu';

import { connect } from 'react-redux';

class NavigationBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultImage: '/images/logo-iam.svg',
        };
    }

    render() {
        const logo = this.props.logo || this.state.defaultImage;

        return (
            <div className="navbar">
                <div className="navbar__inner">
                    <div className="navbar__container">
                        <Link
                            className="navbar__brand navbar__logo"
                            to={PagePath.dashboard || '/'}
                            id="logo_link">
                            <img src={logo} />
                        </Link>
                        <Menu />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        feature: state.feature,
        path: state.page,
    };
};

export default connect(mapStateToProps)(NavigationBar);
