import React from 'react';
import moment from 'moment-timezone';
import _ from 'underscore';
import { compose, withStateHandlers } from 'recompose';

const IECheckboxGroup = props => {
    const {
        columns,
        checkboxes,
        isEditable,
        isDisabledOptions,
        selectedValue,
        onAnyCheckboxChanged,
    } = props;

    const checkboxGroup = checkboxes.map((checkbox, i) => {
        const { title, name, value, onChange } = checkbox;
        const atIndex = selectedValue.indexOf(value);
        const checked = atIndex > -1;
        const classNames = ['checkbox-wrapper col-xs-12', columns <= 12 && `col-sm-${columns}`];

        let disabledOption = null;

        if (isDisabledOptions) {
            disabledOption = isDisabledOptions.includes(value);
        }

        const checkboxClass = [
            'checkbox-box',
            disabledOption && 'optionsDisabled',
            checked && 'checked-box-shape',
        ];

        return (
            <div
                key={i}
                className={classNames.join(' ')}
                role="button"
                onClick={e => {
                    if (!isEditable) return;
                    const values = _.clone(selectedValue) || [];
                    const indexAt = values.indexOf(value);
                    const found = indexAt > -1;

                    if (!found) {
                        values.push(value);
                    } else {
                        values.splice(indexAt, 1);
                    }

                    onChange &&
                        onChange(e, {
                            selectedValue: values,
                            value: values,
                            checked: !found,
                        });
                    !disabledOption &&
                        onAnyCheckboxChanged &&
                        onAnyCheckboxChanged(e, {
                            index: i,
                            value: values,
                            checked: !found,
                        });
                }}>
                <div className="checkbox-label">
                    <div className="checkbox-box-wrapper">
                        <div className={checkboxClass.join(' ')}>
                            {checked && <div className="checked-box-shape"></div>}
                        </div>
                    </div>
                    {title}
                </div>
                <input
                    className="checkbox-option"
                    type="checkbox"
                    name={name}
                    value={value}
                    title={title}
                    checked={checked}
                    onChange={_.noop}
                />
            </div>
        );
    });

    return <div className="checkboxes-wrapper">{checkboxGroup}</div>;
};

IECheckboxGroup.defaultProps = {
    checkboxes: [],
    selectedValue: [],
    isEditable: true,
};

export default compose(
    withStateHandlers(() => {
        const timestamp = moment().toISOString();
        const name = `checkbox${timestamp}`;
        return {
            name,
        };
    }),
)(IECheckboxGroup);
