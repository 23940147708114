import React from 'react';

import DeepClone from 'clone';
import _ from 'underscore';

import FieldsetBuilder from './FieldsetBuilder';

import { connect } from 'react-redux';
import { compose, withStateHandlers, withProps, shouldUpdate, branch } from 'recompose';
import { fieldsetKey } from './FormRules';
import { AddFieldsetButton } from '../Misc/template';
import { withProcessedFieldname, filterByIds, getFieldsets, getFields } from '../Misc/forms';
import { normalizeString } from '../Misc/index';
import * as Selectors from '../../redux/selectors';
import SectionBuilderPlaceholder from './placeholders/SectionBuilderPlaceholder';
import { Section } from 'redux/reducers/FormReducer';
import { Validity } from 'redux/reducers/ValidityReducer';

const withShouldUpdate = compose(
    shouldUpdate((current, next) => {
        return !_.isEqual(current, next);
    }),
);

interface SectionBuilderProps {
    isCollapsed: boolean;
    setCollapsed: any;
    triggerCollapsed: any;
    section: Section;
    validities: Record<string, Validity>;
}

class SectionBuilder extends React.Component<SectionBuilderProps> {
    EnhancedFSBuilder = withShouldUpdate(FieldsetBuilder);

    static defaultProps = {
        fieldname: {},
        section: {},
        isEditable: true,
    };

    handleUncollapseClick() {
        if (this.props.isCollapsed) this.props.setCollapsed(false);
    }

    handleTriggerCollapseClick() {
        this.props.triggerCollapsed();
    }

    render() {
        let {
            fieldname,
            section,
            className,
            indexOfSection,
            isEditable,
            path,

            isCollapsed,
            isCollapsible,
            // inherited
            isEditingFieldset,
            isEditingField,
            isEditingGrid,

            // component to pass to children
            values,
            validities,
            triggers,
            triggeredStates,
            disabledFields,
            fieldsetsFields,
        } = this.props;
        const extraProps = {
            values,
            validities,
            triggers,
            triggeredStates,
            disabledFields,
            fieldsetsFields,
            isEditingFieldset,
            isEditingField,
            isEditingGrid,
        };
        const { current } = fieldname;
        let {
            title,
            name,
            showTitleAndName = false,
            isTransparent,
            fieldsets = [],
            action_buttons: actionButtons = [],
        } = section;
        const isActionButton = _.isEmpty(fieldsets);
        if (isActionButton) {
            fieldsets = actionButtons;
            isCollapsed = false;
            isTransparent = true;
        }

        name = name || 'Section ' + indexOfSection;
        path = `${path}.fieldsets`;

        // join class name
        const classNames = [
            'fieldset',
            _.isString(className) && className,
            _.isString(name) && normalizeString(name),
            isTransparent && 'fieldset--background-transparent',
            isCollapsed && 'fieldset--collapsed',
        ];

        const sectionComponents = Object.entries(fieldsets).map((fieldset, index) => {
            const [key, value] = fieldset;

            if (_.isObject(value) && value.type === fieldsetKey) {
                const fieldIds = fieldsetsFields[value.id] || [];
                const fieldsetIds = getFieldsets(value);
                const ids = [...fieldIds, value.id, ...fieldsetIds];
                const fsValues = filterByIds(values, ids);
                const fsTriggeredStates = filterByIds(triggeredStates, ids);
                const fsValidities = filterByIds(validities, ids);

                return (
                    <this.EnhancedFSBuilder
                        key={value.id || index}
                        fieldset={value}
                        className="column__grids"
                        fieldname={{ parent: path, current: `${key}` }}
                        isParentEditable={isEditable}
                        {...DeepClone(value)}
                        {...extraProps}
                        values={fsValues}
                        triggeredStates={fsTriggeredStates}
                        validities={fsValidities}
                    />
                );
            }

            return false;
        });
        const headerNameClasses = [title && 'col-sm-3', 'col-xs-12 header--name'];
        return (
            <div className={classNames.join(' ')}>
                {showTitleAndName && !isActionButton && (
                    <div className="fieldset--header">
                        <div className={headerNameClasses.join(' ')}>{name}</div>
                        {title && <div className="col-xs-12 col-sm-9 header--title">{title}</div>}
                        {isCollapsible && (
                            <div
                                className="button-collapse"
                                onClick={this.handleTriggerCollapseClick.bind(this)}>
                                <i className="chevron down icon" />
                            </div>
                        )}
                    </div>
                )}
                <div
                    className="col-xs-12 fieldset--body"
                    onClick={this.handleUncollapseClick.bind(this)}>
                    {sectionComponents}
                    {this.props.isEditingFieldset && [
                        <AddFieldsetButton key="topFieldsetButton" path={current} position="top" />,
                        <AddFieldsetButton
                            key="bottomFieldsetButton"
                            path={current}
                            position="bottom"
                        />,
                    ]}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    const id = props.section.id;
    const collapsibles = [];
    const { fields = getFields(props.section, Selectors.getTriggers(state)), fieldsets = [] } =
        Selectors.getSectionsItemsById(state, id) || {};
    // All the ids for this sections
    const ids = [...fields, ...fieldsets];
    const customDefaultValue = filterByIds(Selectors.getCustomDefaultValue(state), ids);
    const values = DeepClone(Selectors.getValuesByIds(state, ids));
    const triggers = DeepClone(Selectors.getTriggersByIds(state, ids));
    const validities = DeepClone(Selectors.getValiditiesByIds(state, ids));
    // get the list as a filter to stop unnecessray value / props update
    const sectionFieldsets = Selectors.getFieldsetsChilds(state);
    const fieldsetsFields = filterByIds(sectionFieldsets, fieldsets);
    const triggeredStates = DeepClone(Selectors.getTriggeredStatesByIds(state, ids));
    const disabledFields = DeepClone(Selectors.getDisabledFieldsByIds(state, ids));
    const disabledCampaignFields = DeepClone(Selectors.getDisabledCampaignFields(state));

    return {
        ids,
        childs: { fields, fieldsets },
        values: { ...customDefaultValue, ...values },
        triggers,
        validities,
        fieldsetsFields,
        triggeredStates,
        disabledFields: { ...disabledFields, ...disabledCampaignFields },
        disabledSections: Selectors.getDisabledSections(state),
        collapsibles,
    };
};

const EnhancedSectionBuilder = compose(
    connect(mapStateToProps),
    shouldUpdate((currentProps, nextProps) => {
        return !_.isEqual(currentProps, nextProps);
    }),
    withProps(({ collapsibles, section, disabledSections, isParentEditable, isEditable }) => {
        const { id } = section;
        const isCollapsible = collapsibles[id];
        isEditable = !disabledSections[id];
        if (_.isUndefined(isEditable) || isEditable) {
            isEditable = isParentEditable;
        }
        return {
            isCollapsible,
            isEditable,
        };
    }),
    withProcessedFieldname,
    withStateHandlers(
        ({ isCollapsed }) => ({
            isCollapsed,
        }),
        {
            triggerCollapsed:
                ({ isCollapsed }) =>
                () => ({
                    isCollapsed: !isCollapsed,
                }),
            setCollapsed: () => isCollapsed => ({
                isCollapsed,
            }),
        },
    ),
)(SectionBuilder);

class EnhancedSectionBuilderWithPlaceholder extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {};
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                ...this.state,
                Component: EnhancedSectionBuilder,
            });
        }, 750);
    }

    render() {
        return this.state.Component ? (
            <this.state.Component {...this.props} />
        ) : (
            <SectionBuilderPlaceholder />
        );
    }
}

export default branch(
    props => {
        const { showPlaceholder } = props;
        return showPlaceholder;
    },
    () => EnhancedSectionBuilderWithPlaceholder,
    () => EnhancedSectionBuilder,
)(EnhancedSectionBuilder);
