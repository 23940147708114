import ObjectAssign from 'object-assign';
import ObjectPath from 'object-path';

import {
    SET_SECTION_CHILDREN,
    SET_SECTIONS_ITEMS,
    SET_FIELDSET_IDS,
    SET_FIELDSET_FETCHING,
    SET_FIELDSET_ERROR,
    // fieldset creation
    SET_SECTION_CREATION,
    SET_FIELDSET_DETAIL,
    SET_IS_SELECTING_FIELD,
    SET_WORKING_PATH,
    SET_TOTAL_FIELD,
    SET_TOTAL_GRID,
    SET_TOTAL_FIELDSET,
    SET_EDITING_FIELD,
    SET_EDITING_GRID,
    SET_EDITING_FIELDSET,
    SET_PANEL,
    SET_CLIPBOARD_FIELDS,
    SET_CLIPBOARD,
} from '../actions/SectionAction';

const INITIAL_STATE = {
    sectionChildren: {},
};

export default function SectionReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
    case SET_SECTION_CHILDREN: {
        const { id, sectionChildren } = action.payload;

        return {
            ...state,
            sectionChildren: {
                ...state.sectionChildren,
                [id]: sectionChildren,
            },
        };
    }
    case SET_SECTIONS_ITEMS: {
        const { sectionChildren } = action;
        return {
            ...state,
            sectionChildren: {
                ...state.sectionChildren,
                ...sectionChildren,
            },
        };
    }
    case SET_FIELDSET_IDS: {
        const nextState = ObjectAssign({}, state);
        ObjectPath.set(nextState, 'templateId', action.payload.templateId);
        ObjectPath.set(nextState, 'fieldsetId', action.payload.fieldsetId);
        return nextState;
    }
    case SET_FIELDSET_FETCHING: {
        const nextState = ObjectAssign({}, state);
        ObjectPath.set(nextState, 'fetching', action.fetching);
        return nextState;
    }
    case SET_FIELDSET_ERROR: {
        const nextState = ObjectAssign({}, state);
        ObjectPath.set(nextState, 'error', action.error);
        return nextState;
    }
    // fieldset creation
    case SET_SECTION_CREATION: {
        return {
            ...state,
            creation: action.creation,
        };
    }
    case SET_FIELDSET_DETAIL: {
        const nextState = ObjectAssign({}, state, {
            detail: action.obj,
        });
        return nextState;
    }
    case SET_IS_SELECTING_FIELD: {
        const nextState = ObjectAssign({}, state, {
            creation: {
                ...state.creation,
                isSelecting: action.isSelecting,
            },
        });

        return nextState;
    }

    case SET_WORKING_PATH: {
        const nextState = ObjectAssign({}, state, {
            creation: {
                ...state.creation,
                workingPath: action.path,
            },
        });

        return nextState;
    }
    case SET_TOTAL_FIELD: {
        const nextState = ObjectAssign({}, state, {
            creation: {
                ...state.creation,
                totalField: action.num,
            },
        });

        return nextState;
    }
    case SET_TOTAL_GRID: {
        const nextState = ObjectAssign({}, state, {
            creation: {
                ...state.creation,
                totalGrid: action.num,
            },
        });

        return nextState;
    }
    case SET_TOTAL_FIELDSET: {
        const nextState = ObjectAssign({}, state, {
            creation: {
                ...state.creation,
                totalFieldset: action.num,
            },
        });

        return nextState;
    }
    case SET_EDITING_FIELD: {
        const nextState = ObjectAssign({}, state);
        ObjectPath.set(nextState, 'creation.editing.field', action.isEditing);

        return nextState;
    }
    case SET_EDITING_GRID: {
        const nextState = ObjectAssign({}, state);
        ObjectPath.set(nextState, 'creation.editing.grid', action.isEditing);

        return nextState;
    }
    case SET_EDITING_FIELDSET: {
        const nextState = ObjectAssign({}, state);
        ObjectPath.set(nextState, 'creation.editing.fieldset', action.isEditing);

        return nextState;
    }
    case SET_PANEL: {
        const nextState = ObjectAssign({}, state);
        ObjectPath.set(nextState, 'creation.panel', action.showing);

        return nextState;
    }
    case SET_CLIPBOARD_FIELDS: {
        const nextState = ObjectAssign({}, state);
        const fields = ObjectPath.get(nextState, 'creation.clipboard.fields') || [];
        fields.push(action.field);
        ObjectPath.set(nextState, 'creation.clipboard.fields', fields);

        return nextState;
    }
    case SET_CLIPBOARD: {
        const nextState = ObjectAssign({}, state);
        ObjectPath.set(nextState, 'creation.clipboard.showing', action.showing);

        return nextState;
    }

    default:
        return state;
    }
}
