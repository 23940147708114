import React from 'react';
import PropTypes from 'prop-types';
import IECheckboxGroup from './IECheckboxGroup';
import _ from 'underscore';
import Label from '../Label';

const UNDEFINED_GROUP = '_empty';
const GroupedCheckboxGroup = ({
    Component = IECheckboxGroup,
    id,
    columns,
    groupedCheckboxes,
    isEditable,
    isDisabledOptions,
    selectedValue,
    onChange,
}) => {
    // cleaning the checkboxes to find if there's unlabelled group
    const cleanedGroupedCheckboxes = groupedCheckboxes.map(o => {
        if (!o.group) o.group = UNDEFINED_GROUP;
        return o;
    });
    const groupedByName = _.groupBy(cleanedGroupedCheckboxes, o => o.group);
    const checkboxes = Object.entries(groupedByName);

    return (
        <div className="grouped-checkbox-wrapper">
            {checkboxes.map((group, index) => (
                <div key={index} className="grouped-checkbox" id={`${ id }_set_${ index + 1 }`}>
                    <Label
                        className={'input__label--top'}
                        title={group[0] !== UNDEFINED_GROUP ? group[0] : <div>&nbsp;</div>}
                    />

                    <Component
                        name={`${ id }_set_${ index + 1 }`}
                        columns={columns}
                        id={id}
                        checkboxes={group[1]}
                        isEditable={isEditable}
                        isDisabledOptions={isDisabledOptions}
                        selectedValue={selectedValue}
                        onAnyCheckboxChanged={(e, data) => {
                            onChange && onChange(e, data);
                        }}
                    />
                </div>
            ))}
        </div>
    );
};

GroupedCheckboxGroup.propTypes = {
    id: PropTypes.string,
    columns: PropTypes.number,
    groupedCheckboxes: PropTypes.array.isRequired,
    isEditable: PropTypes.bool,
    isDisabledOptions: PropTypes.array,
    selectedValue: PropTypes.array,
    onChange: PropTypes.func,
};

GroupedCheckboxGroup.defaultProps = {
    groupedCheckboxes: [],
    isEditable: true,
};

export default GroupedCheckboxGroup;
