import moment from 'moment-timezone';

import { startLoading, stopLoading } from './PageAction';
import ConfigurationAPI from '../../api/configuration/ConfigurationAPI';
const api = new ConfigurationAPI();

export const SET_PARAMETERS = 'SET_PARAMETERS';
function setParameters(data) {
    return {
        type: SET_PARAMETERS,
        data,
    };
}

export const SET_PARAMETERS_FETCHING = 'SET_PARAMETERS_FETCHING';
function setParametersFetching(fetching) {
    return {
        type: SET_PARAMETERS_FETCHING,
        fetching,
    };
}

export const SET_CONFIGURATION_STATE = 'SET_CONFIGURATION_STATE';
function setConfigurationState(payload) {
    return {
        type: SET_CONFIGURATION_STATE,
        payload: payload,
    };
}

// ACTION
export function fetchParameters() {
    return dispatch => {
        dispatch(setParametersFetching(true));
        dispatch(startLoading());

        api.getParameterList()
            .success(({ parameters }) => {
                const formatedParameters = parameters.map(p => {
                    function isoToDateString(isoString) {
                        const dt = moment(isoString);
                        return dt.format('YYYY/MM/DD');
                    }

                    const createdDate = isoToDateString(p.created_dt);
                    const updatedDate = isoToDateString(p.updated_dt);
                    return { ...p, created_dt: createdDate, updated_dt: updatedDate };
                });

                dispatch(setParameters(formatedParameters));
            })
            .fail(error => {
                // Uhoh something bad happened.
                // TODO error handling
                console.error(error);
            })
            .complete(() => {
                dispatch(setParametersFetching(false));
                dispatch(stopLoading());
            });
    };
}

export function actionSetConfigState(state) {
    return dispatch => {
        dispatch(setConfigurationState(state));
    };
}
