import React from 'react';
import moment from 'moment-timezone';
import _ from 'underscore';
import { DAYS, DAYS_IN_MONTH, toValidDate, MONTHS_LABELS, isDateInRange } from './misc';
import { getMonth, getFullYear, getDate, getDay } from '../../Misc/datetime';

const DayHeaders = () => {
    return (
        <div className="calendar-header-day">
            {DAYS.map((day, i) => (
                <DayColumn key={i} day={day} className="header" />
            ))}
        </div>
    );
};
const DayRows = ({ date, minDate, maxDate, timezone, onClick }) => {
    const month = getMonth(date, timezone); // all logic is handled based on month in index format
    const year = getFullYear(date, timezone);
    let selectedDate = getDate(date, timezone);
    const firstDay = getDay(new Date(year, month, 1), timezone); // month's first day's day
    let daysNo = DAYS_IN_MONTH[month];
    const prevDaysNo = month - 1 < 0 ? DAYS_IN_MONTH[11] : DAYS_IN_MONTH[month - 1];
    const dateRows = [],
        dateDetailRows = [];
    let day = 1,
        nextMonthDay = 1;

    // check is leap year for february
    if (month === 1 && moment([year]).isLeapYear()) {
        daysNo = moment([year, 1]).daysInMonth();
    }

    if (daysNo < selectedDate) {
        selectedDate = daysNo;
    }
    let daysBeforeFirst = firstDay - 1;
    for (var i = 0; i < 7; i++) {
        dateRows[i] = [];
        dateDetailRows[i] = [];
        for (var j = 0; j < 7; j++) {
            if (day <= daysNo && (i > 0 || j >= firstDay)) {
                const currentDate = moment([year, month, day]).toDate();
                const isDisabled = !isDateInRange({ date: currentDate, minDate, maxDate });
                dateRows[i].push(day);
                dateDetailRows[i].push({
                    date: day,
                    isDisabled,
                    isSelected: selectedDate === day++ && !isDisabled,
                });
            }
            // to append the days before first day of the month
            //   that's within then first week into calendar
            else if (i === 0 && day <= daysNo) {
                dateRows[i].push(prevDaysNo - daysBeforeFirst);
                dateDetailRows[i].push({
                    date: prevDaysNo - daysBeforeFirst--,
                    isDisabled: true,
                });
            }
            // to append the days after the last day of the month
            //  that's within the last week into calendar
            else {
                dateRows[i].push(nextMonthDay);
                dateDetailRows[i].push({
                    date: nextMonthDay++,
                    isDisabled: true,
                });
            }
        }
        if (day > daysNo) {
            break;
        }
    }

    return (
        <div className="calendar-rows-day">
            {dateDetailRows.map((days, i) => (
                <DayRow
                    key={i}
                    days={days}
                    date={date}
                    onClick={(e, data) => onClick && onClick(e, { ...data, month, year })}
                />
            ))}
        </div>
    );
};
const DayRow = ({ days = [], onClick }) => (
    <div className="calendar-row-day">
        {days.map((day, i) => (
            <DayColumn key={i} day={day} onClick={onClick} />
        ))}
    </div>
);
const DayColumn = ({ day, disabled, className, onClick }) => {
    const { date, isDisabled, isSelected } = day;
    if (date) {
        day = date;
    }
    if (disabled || isDisabled) {
        onClick = _.noop;
    }
    const classNames = [
        'calendar-column-day',
        disabled || isDisabled ? 'disabled' : '',
        isSelected ? 'active' : '',
        className ? className : '',
        `d${day}`,
    ];
    return (
        <div
            className={classNames.join(' ')}
            role="button"
            onClick={e => onClick && onClick(e, { day })}>
            {day}
        </div>
    );
};

const Month = ({ month, onClick }) => {
    return (
        <div className="popup-top-title clickable" role="button" onClick={onClick}>
            {MONTHS_LABELS[month]}
        </div>
    );
};

/**
 * Pop up day selection component
 * @param {object} props - date: as selected date else today date as default
 * @returns {ReactElement} CalendarPopUpDate
 */
const CalendarPopUpDate = props => {
    let { date = moment().toDate(), minDate, maxDate, timezone, onClick, onMonthClick } = props;
    date = toValidDate(date);
    const month = getMonth(date, timezone);
    const hour = 0;
    const minute = 0;
    return (
        <div className="calendar-day-wrapper">
            <Month month={month} onClick={onMonthClick} />
            <DayHeaders />
            <DayRows
                date={date}
                maxDate={maxDate}
                minDate={minDate}
                timezone={timezone}
                onClick={(e, data) => {
                    onClick(e, {
                        hour,
                        minute,
                        ...data,
                    });
                }}
            />
        </div>
    );
};

export default CalendarPopUpDate;
