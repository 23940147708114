import { createSelector } from 'reselect';

const ajvSelector = state => state.validator.ajv;
const validatorsSelector = state => state.validator.validators;

export const getAjv = createSelector(
    ajvSelector,
    ajv => ajv,
);

export const getValidators = createSelector(
    validatorsSelector,
    validators => validators,
);
