import BaseAPI from '../BaseAPI';

class TemplateDetailAPI extends BaseAPI {
    basePath = 'cap/templates/';

    // get template details
    async getTemplateDetails(templateId) {
        try {
            const { template } = await this.get({
                path: `${ this.basePath }${ templateId }/`,
            });
            return template;
        } catch (error) {
            throw error.statusText;
        }
    }

    async getTemplateSections(templateId) {
        try {
            const { sections } = await this.get({
                path: `${ this.basePath }${ templateId }/sections/`,
            });
            return sections;
        } catch (error) {
            throw error.statusText;
        }
    }

    async createTemplateSection(templateId, data) {
        try {
            const { section } = await this.post({
                path: `${ this.basePath }${ templateId }/sections/`,
                data: data,
            });
            return section;
        } catch (error) {
            throw error.statusText;
        }
    }

    async editTemplateSection(templateId, sectionId, data) {
        try {
            const { sections } = await this.post({
                path: `${ this.basePath }${ templateId }/sections/${ sectionId }/`,
                data: data,
            });
            return sections;
        } catch (error) {
            throw error.statusText;
        }
    }

    async removeTemplateSection(templateId, sectionId, data) {
        try {
            const sections = await this.delete({
                path: `${ this.basePath }${ templateId }/sections/${ sectionId }/`,
                data: data,
            });
            return sections;
        } catch (error) {
            throw error.statusText;
        }
    }

    async createTemplateView(templateId, data) {
        try {
            const { view } = await this.post({
                path: `${ this.basePath }${ templateId }/views/`,
                data: data,
            });
            return view;
        } catch (error) {
            throw error.statusText;
        }
    }

    async editTemplateView(templateId, viewId, data) {
        try {
            const { view } = await this.post({
                path: `${ this.basePath }${ templateId }/views/${ viewId }/`,
                data: data,
            });
            return view;
        } catch (error) {
            throw error.statusText;
        }
    }

    async removeTemplateView(templateId, viewId) {
        try {
            const view = await this.delete({
                path: `${ this.basePath }${ templateId }/views/${ viewId }/`,
            });
            return view;
        } catch (error) {
            throw error.statusText;
        }
    }

    async createTemplateAccess(templateId, data) {
        try {
            const { view_access: viewAccess } = await this.post({
                path: `${ this.basePath }${ templateId }/view-accesses/`,
                data: data,
            });
            return viewAccess;
        } catch (error) {
            throw error.statusText;
        }
    }

    async editTemplateAccess(templateId, accessId, data) {
        try {
            const { view_access: viewAccess } = await this.post({
                path: `${ this.basePath }${ templateId }/view-accesses/${ accessId }/`,
                data: data,
            });
            return viewAccess;
        } catch (error) {
            throw error.statusText;
        }
    }

    async removeTemplateAccess(templateId, accessId, data) {
        try {
            const viewAccess = await this.delete({
                path: `${ this.basePath }${ templateId }/view-accesses/${ accessId }/`,
                data: data,
            });
            return viewAccess;
        } catch (error) {
            throw error.statusText;
        }
    }
}
export const templateDetailAPI = new TemplateDetailAPI();
