import store from 'store';
import _ from 'underscore';
import { compose, lifecycle } from 'recompose';
import { REPLACE_VALIDATION_KEY } from '.';
import replaceValidation from './replaceValidation';
import { setValidity } from 'redux/actions/ValidityAction';

/**
 * HOC that check the replacedValidation field for the needs to
 *   unset its validity
 *
 * depends on setSchema, replaceValidation
 */
const withReplacedValidityCheck = compose(
    lifecycle({
        // to handle schema replacement
        componentDidMount() {
            const {
                extendingProps = {},
                initialId,
                schema,
                valuePath,
                // handlers
                setSchema,
            } = this.props;
            if (!extendingProps[REPLACE_VALIDATION_KEY]) return;
            // get updated schema
            const newSchema = replaceValidation({
                extendingProps,
                id: initialId,
                path: valuePath,
            });
            const schemaIsEqual = _.isEqual(schema, newSchema);
            if (!schemaIsEqual) {
                setSchema && setSchema(newSchema);
            }
        },
        componentDidUpdate({ values: prevValues }) {
            // values changed, means depending values changed
            if (_.isEqual(prevValues, this.props.values)) return;

            const {
                extendingProps = {},
                initialId,
                schema,
                valuePath,
                validityPath,
                // handlers
                setSchema,
            } = this.props;
            if (!extendingProps[REPLACE_VALIDATION_KEY]) return;

            // reset validity for field that has replaceValidation
            const newSchema = replaceValidation({
                extendingProps,
                id: initialId,
                path: valuePath,
            });
            setSchema && setSchema(newSchema);

            const schemaIsEqual = _.isEqual(schema, newSchema);
            if (!schemaIsEqual && !newSchema) {
                store.dispatch(
                    setValidity({
                        validity: {
                            isValid: true,
                        },
                        path: validityPath,
                    }),
                );
            }
        },
    }),
);

export default withReplacedValidityCheck;
