import { compose, lifecycle } from 'recompose';
import _ from 'underscore';

// NOTE: This applicable for fields only
/**
 * Decorator for the lifecycle method componentDidUpdate.
 * It checks if the component will hide, and if the clearOnHide flag is set
 * and it has a value then it will clear the field of any value.
 */
const withClearOnHide = compose(
    lifecycle({
        componentDidUpdate({
            clearOnHide,
            id,
            validityPath,
            showComponent: prevShowComponent,
            onValueChanged,
            onValidityChange,
        }) {
            const { showComponent, reduxValue, defaultValue } = this.props;
            const willHide = !showComponent && showComponent !== prevShowComponent;
            const willShow = showComponent && showComponent !== prevShowComponent;

            if (willHide && clearOnHide) {
                onValueChanged({ id, value: null });
                onValidityChange({ id: validityPath, validity: null });
            } // to handle and set defaultValue when field was mounted but then value is reset
            else if (willShow && _.isNull(reduxValue) && !_.isUndefined(defaultValue)) {
                onValueChanged({ id, value: defaultValue });
            }
        },
    }),
);

export default withClearOnHide;
