import React from 'react';
import _ from 'underscore';
import moment from 'moment-timezone';

import Label from './Label';
import CalendarPopUp from './CalendarPopUp';
import { compose, onlyUpdateForKeys, withStateHandlers, branch, lifecycle } from 'recompose';
import { NULL_VALUE_TEXT } from '../../Misc/forms';
import { calendarKey } from '../FormRules';

/**
 * Constant with property of datetime format
 * in string.
 */
export const FORMAT = {
    date: 'YYYY/MM/DD',
    time: 'hh:mm A',
    year: 'YYYY',
    datetime: 'YYYY/MM/DD hh:mm A',
    datetime2: 'LLL',
    monthYear: 'YYYY/MM',
};

/**
 * A method that return date formatted to FORMAT.datetime, which is the default format
 * if format key is not defined, and also in a given timezone if timezone key is defined.
 * @param {Object} object - { date: string, format: string, timezone: string }
 * @returns {string} formattedStr
 */
function getFormattedDatetime({ date, format = FORMAT.datetime, timezone }) {
    if (!date) return '';
    return moment.tz(date, timezone).format(format);
}

/**
 * A method that return valid calendar input in ISO string format
 * If the input is invalid, it will return as empty string
 * @param {string} calendarInput - 1991/11/11
 * @param {string} type - date
 * @param {string} timezone - Asia/Kuala_Lumpur
 *
 * @returns {string} inputValue - 2020-11-10T16:00:00.000Z
 */
export function handleCalendarInputFormat({ calendarInput, format, type }) {
    format = format || FORMAT[type];
    if (!format) return;
    // Check if user input matches format
    const isValid = moment(calendarInput, format, true).isValid();

    let inputValue = '';
    if (isValid) {
        // Convert valid input to be same with datepicker
        inputValue = calendarInput;
    }
    return inputValue;
}

const Calendar = props => {
    const { open, selecting, setOpen, setClose, setSelecting } = props;
    const {
        isEditable,
        note,
        value,
        placeholder,
        today,
        onChange,
        minDate,
        maxDate,
        timezone,
        format,
        showYearAtDate = true,
        showYearRange = true,
        setCalendarInput,
        isInputtable,
    } = props;
    /* eslint-disable */
    let popUpValue = value;
    let { type, icon, calendarInput } = props;
    const classNames = ['input__calendar'];
    const inputClassNames = [
        'calendar-input',
        !_.isEmpty(note) && note.position === 'right'
            ? 'note-right'
            : !_.isEmpty(note)
                ? 'note-left'
                : '',
    ];
    const noteTitle = note.title;
    let presentingValue = today ? moment().toDate() : value;

    switch (type) {
        case calendarKey.date:
            presentingValue = getFormattedDatetime({ date: value, timezone, format: FORMAT.date });
            icon = icon || 'calendar';
            break;

        case calendarKey.time:
            presentingValue = getFormattedDatetime({ date: value, timezone, format: FORMAT.time });
            icon = icon || 'clock';
            break;

        case calendarKey.year:
            presentingValue = getFormattedDatetime({ date: value, timezone, format: FORMAT.year });
            icon = icon || 'calendar outline';
            break;
        case calendarKey.monthYear:
            presentingValue = getFormattedDatetime({
                date: value,
                timezone,
                format: FORMAT.monthYear,
            });
            icon = icon || 'calendar outline';
            break;

        default:
            presentingValue = getFormattedDatetime({ date: value, timezone });
            icon = icon || 'calendar';
            type = calendarKey.datetime;
            break;
    }

    if (format && value) {
        presentingValue = moment.tz(value, timezone).format(format);
    }
    presentingValue = calendarInput || presentingValue;

    const isValidPopUpValue = moment(presentingValue, format, true).isValid();
    if (isValidPopUpValue) {
        popUpValue = moment(presentingValue, format).toISOString();
    }

    return (
        <div className={classNames.join(' ')}>
            {isEditable && noteTitle && <Label position="left" content={noteTitle} />}

            {isEditable && [
                <input
                    key="input"
                    className={inputClassNames.join(' ')}
                    type="text"
                    placeholder={placeholder}
                    value={presentingValue}
                    onFocus={() => {
                        setOpen();
                    }}
                    onBlur={e => {
                        !selecting && setClose();
                        // Pass calendar inputs to parent for validation
                        if (calendarInput && isInputtable) {
                            let inputValue = handleCalendarInputFormat({
                                calendarInput,
                                type,
                                timezone,
                                minDate,
                                maxDate,
                            });

                            // If input is valid
                            if (inputValue) {
                                // Check for min and max date
                                const isBeforeMinDate =
                                    minDate &&
                                    moment(inputValue).isBefore(
                                        moment.tz(minDate, timezone).toISOString(),
                                    );
                                const isAfterMaxDate =
                                    maxDate &&
                                    moment(inputValue).isAfter(
                                        moment.tz(maxDate, timezone).toISOString(),
                                    );
                                if (isBeforeMinDate || isAfterMaxDate) {
                                    // Clear user input if min or max are out of range.
                                    // In future may change behavour.
                                    setCalendarInput();
                                    inputValue = null;
                                }
                            } else {
                                inputValue = calendarInput;
                            }
                            onChange(e, { inputValue, date: new Date(inputValue) });
                        }
                    }}
                    onKeyDown={e => {
                        e => e.preventDefault();
                        if (!isInputtable && presentingValue && e.keyCode === 8) {
                            onChange(e, { value: null });
                        }
                    }}
                    onChange={e => {
                        if (isInputtable) {
                            if (!e.target.value) {
                                // reset value, prevent fallback to previous defined presentingValue
                                onChange(e, { value: null });
                            }
                            setCalendarInput(e.target.value);
                        }
                    }}
                />,

                <CalendarPopUp
                    key="pop_up"
                    open={open || selecting}
                    type={type}
                    date={value}
                    showYearAtDate={showYearAtDate}
                    showYearRange={showYearRange}
                    maxDate={maxDate}
                    minDate={minDate}
                    timezone={timezone}
                    onMouseOver={() => setSelecting(true)}
                    onMouseLeave={() => setSelecting(false)}
                    onTouchStart={() => setSelecting(true)}
                    onTouchCancel={() => setSelecting(false)}
                    onChange={(e, data) => {
                        setCalendarInput();
                        onChange(e, data);
                    }}
                    onClose={setClose}
                />,

                <i key="icon" className={`calendar-icon icon ${icon}`} />,
            ]}

            {!isEditable && [
                <CalendarInfoHOC key="input" value={presentingValue} note={note} />,
                <i key="icon" className={`calendar-icon icon ${icon}`} />,
            ]}
        </div>
    );
};

const CalendarInfo = props => {
    const { value, format, timezone } = props;
    if (format) {
        value = moment.tz(value, timezone).format(format);
    }

    return (
        <Label className="input__label--value basic full-width">{value || NULL_VALUE_TEXT}</Label>
    );
};

const CalendarInfoNote = props => {
    const { value, note, format, timezone } = props;
    const notePosition = note.position;
    const noteTitle = note.title;
    let presentingValue;

    if (format) {
        value = moment.tz(value, timezone).format(format);
    }

    skip: {
        if (!value) {
            presentingValue = NULL_VALUE_TEXT;
            break skip;
        }
        if (notePosition === 'left') {
            presentingValue = `${noteTitle} ${value}`;
        } else {
            presentingValue = `${value} ${noteTitle}`;
        }
    }

    return <Label className="input__label--value">{presentingValue}</Label>;
};

const CalendarInfoHOC = branch(
    ({ note }) => _.isEmpty(note),
    () => CalendarInfo,
)(CalendarInfoNote);

const enhance = compose(
    onlyUpdateForKeys(['open', 'value', 'minDate', 'maxDate', 'isEditable']),
    withStateHandlers(
        ({ open }) => ({
            open,
        }),
        {
            trigger: ({ open }) => () => ({
                open: !open,
            }),
            setOpen: () => () => ({
                open: true,
            }),
            setClose: () => () => ({
                open: false,
                selecting: false,
            }),
            setSelecting: () => selecting => ({
                selecting,
            }),
            setCalendarInput: () => (value = '') => ({
                calendarInput: value,
            }),
        },
    ),
    lifecycle({
        componentDidUpdate({ value: prevValue }) {
            const { value, calendarInput, setCalendarInput } = this.props;
            // clear calendarInput if new value empty
            if (!value && !_.isEqual(prevValue, value) && calendarInput) {
                setCalendarInput();
            }
        },
    }),
);

export default enhance(Calendar);
