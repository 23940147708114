import BaseAPI from '../BaseAPI';

export class ClientAPI extends BaseAPI {
    basePath = 'cap/clients/current/';

    async getCurrentClient() {
        let client;

        try {
            ({ client } = await this.get({
                path: this.basePath,
            }));
        } catch (e) {
            throw e.statusText;
        }

        return client;
    }

    async updateCurrentClient(client) {
        try {
            ({ client } = await this.patch({
                path: this.basePath,
                data: client,
            }));
        } catch (e) {
            throw e.statusText;
        }

        return client;
    }
}

export const clientAPI = new ClientAPI();
export default ClientAPI;
