import React from 'react';

import _ from 'underscore';
import PropTypes from 'prop-types';
import { compose } from 'recompose';

// Component
import JPLabel from '../Label';
import { Textarea } from '../TextArea';
import Popup from './Popup/index';
import tickimage from './Popup/images/tick.svg';

// Misc
import { FieldAction } from '../../Misc/template';
import { withCaptureFieldHandlers } from '../../Misc/forms';

class HistoricalRemarks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popup: false,
        };
        this.popupHandler = this.popupHandler.bind(this);
        this.popupClosedHandler = this.popupClosedHandler.bind(this);
    }

    popupHandler() {
        this.setState({ popup: true });
    }

    popupClosedHandler() {
        this.setState({ popup: false });
    }

    render() {
        const {
            id,
            setLocalValue,
            delay,

            // option
            type,
            value,
            isEditable,
            isValid,
            className,
            label,
            placeholder,
            errorMessage,
            onChange,
            // utils
            showComponent,
            path,
            handleChange,
            timezone,
            format,
            // inherited parent utils
            isEditingField,
            rows,
        } = this.props;

        const { current = {}, history = [] } = value;
        function onRemarkChange(e, data) {
            const value = data.value;
            handleChange(e, { path, value });
        }
        const classWarning = 'warning';
        const topClass = 'input__label--top';
        const classNames = [
            'jpt--input input__historical-remarks',
            !isValid && classWarning,
            _.isString && className,
        ];
        const option = {
            type,
            value,
            className,
            label,
            placeholder,
            onChange,
        };
        let message = current.message;

        if (!showComponent) return <div />;
        const hasHistoricalRemarks = history.length > 0;

        if (!isEditable && hasHistoricalRemarks) {
            // show empty
            message = current.message || ' ';
        }

        return (
            <div className={classNames.join(' ')} id={id}>
                {label && <JPLabel className={topClass} {...label} />}
                {
                    <div>
                        {hasHistoricalRemarks && (
                            <img
                                alt="activate popup"
                                onClick={this.popupHandler}
                                src={tickimage}
                                className="tick"
                                role="button"
                            />
                        )}
                        {!isEditable && hasHistoricalRemarks && (
                            <div className="view-remarks" role="button" onClick={this.popupHandler}>
                                View Remarks
                            </div>
                        )}
                        <Textarea
                            value={message}
                            placeholder={placeholder}
                            onChange={(e, data) => {
                                onChange(e, data);
                                const updatedValue = {
                                    current: {},
                                    ...this.props.value,
                                };
                                updatedValue.current.message = data.value;
                                setLocalValue(updatedValue);
                                delay(() => {
                                    onRemarkChange(e, { value: updatedValue });
                                }, 700);
                            }}
                            onBlur={(e, data) => {
                                const updatedValue = {
                                    current: {},
                                    ...this.props.value,
                                };
                                updatedValue.current.message = data.value || current.message;

                                delay(() => {
                                    onRemarkChange(e, { value: updatedValue });
                                }, 0);
                            }}
                            isEditable={isEditable}
                            isValid={isValid}
                            errorMessage={errorMessage}
                            rows={rows}
                        />
                    </div>
                }
                {isEditingField && <FieldAction path={path} field={option} />}
                <Popup
                    closed={this.popupClosedHandler}
                    show={this.state.popup}
                    history={history}
                    timezone={timezone}
                    format={format}
                />
            </div>
        );
    }
}
HistoricalRemarks.defaultProps = {
    delay: (function() {
        var timer = 0;
        return function(callback, ms) {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        };
    })(),
    value: { current: {}, history: [] },
    label: {},
    isEditable: true,
    isValid: true,
    onChange: () => {},
};
HistoricalRemarks.propTypes = {
    delay: PropTypes.func,
    current: PropTypes.object,
    history: PropTypes.array,
    label: PropTypes.object,
    isEditable: PropTypes.bool,
    isValid: PropTypes.bool,
    onChange: PropTypes.func,
    format: PropTypes.string,
};
export default compose(withCaptureFieldHandlers)(HistoricalRemarks);
