import { OWN_SUFFIX } from '.';

/**
 * Method to filter out an object with only keys that's exist in given ids
 * @param {Object} values - any object { key: value } to be used to filtered
 * @param {string[]} ids - array of ids [ key ]
 * @param {Object} storeValues - values in redux
 * @returns {Object} filteredValues
 */
function filterByIds(values, ids = [], storeValues = {}) {
    if (!values) return {};

    const filteredValues = {};
    ids.forEach(id => {
        // added isNumber, and isBoolean check,
        // to fit previous condition where it was checking undefined, null, "", 0, true|false
        // now, value fallback only applicable for undefined, null, "" <- to check the reason value fallback needed next time
        filteredValues[id] =
            typeof values[id] === 'number' || typeof values[id] === 'boolean' || values[id]
                ? values[id]
                : storeValues[id];
        filteredValues[`${ id }${ OWN_SUFFIX }`] =
            values[`${ id }${ OWN_SUFFIX }`] || storeValues[`${ id }${ OWN_SUFFIX }`];
    });
    return filteredValues;
}

export default filterByIds;
