import {
    SET_HISTORY_LIST_FETCHING,
    SET_HISTORY_LIST_ERROR,
    SET_HISTORY_LIST,
    SET_HISTORY_FORM_VIEWS,
    SET_HISTORY_FORM_CURRENT_INDEX,
} from '../actions/HistoryAction';

const INITIAL_STATE = {
    fetching: {
        historyList: false,
    },
    error: {
        historyList: null,
    },
    historyList: [],
    historyForm: {
        current: 0,
        views: [],
    },
};

export default function HistoryReducer(state = INITIAL_STATE, action) {
    const { payload } = action;
    switch (action.type) {
    case SET_HISTORY_LIST_FETCHING:
        return {
            ...state,
            fetching: {
                ...state.fetching,
                historyList: payload,
            },
        };

    case SET_HISTORY_LIST_ERROR:
        return {
            ...state,
            error: {
                ...state.error,
                historyList: payload,
            },
        };

    case SET_HISTORY_LIST:
        return {
            ...state,
            historyList: payload,
        };

    case SET_HISTORY_FORM_VIEWS:
        return {
            ...state,
            historyForm: {
                ...state.historyForm,
                views: payload,
            },
        };

    case SET_HISTORY_FORM_CURRENT_INDEX:
        return {
            ...state,
            historyForm: {
                ...state.historyForm,
                current: payload,
            },
        };

    default:
        return state;
    }
}
