import React from 'react';
import moment from 'moment-timezone';
import _ from 'underscore';
import { compose, withStateHandlers } from 'recompose';
import TreeMenuToolTip from '../../TreeMenu/TreeMenuToolTip';

const IERadioGroup = props => {
    const { name, columns, radios, isEditable, selectedValue, onChange, isDisabledOptions } = props;
    // radios = [{title: 'value', value: 'value'},{title: 'value', value: 'value1'}];
    const radioGroup = radios.map((radio, i) => {
        const { title, value, tooltip } = radio;
        const checked = selectedValue === value;
        const classNames = ['checkbox-wrapper col-xs-12', columns <= 12 && `col-sm-${columns}`];
        let disabledOption = null;

        if (isDisabledOptions) {
            disabledOption = isDisabledOptions === value;
        }

        const radioboxClass = ['checkbox-box radio', disabledOption && 'optionsDisabled'];

        return (
            <div
                key={i}
                className={classNames.join(' ')}
                role="button"
                onClick={e => {
                    if (!isEditable) return;
                    if (!checked)
                        !disabledOption && onChange && onChange(e, { selectedValue, value });
                }}>
                <div className="checkbox-label">
                    <div className="checkbox-box-wrapper radio">
                        <div className={radioboxClass.join(' ')}>
                            {checked && <div className="checked-box-shape radio"></div>}
                        </div>
                    </div>
                    {title}
                </div>
                <input
                    className="checkbox-option"
                    type="radio"
                    name={name}
                    value={value}
                    title={title}
                    checked={checked}
                    onChange={_.noop}
                />
                {tooltip && (
                    <div className="checkbox-tooltip">
                        <TreeMenuToolTip position="right" size="medium" text={tooltip} />
                    </div>
                )}
            </div>
        );
    });

    return <div className="checkboxes-wrapper">{radioGroup}</div>;
};

IERadioGroup.defaultProps = {
    checkboxes: [],
    selectedValue: [],
    isEditable: true,
};

export default compose(
    withStateHandlers(() => {
        const timestamp = moment().toISOString();
        return {
            name: `radio${timestamp}`,
        };
    }),
)(IERadioGroup);
