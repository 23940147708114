import store from 'store';
import * as Selectors from 'redux/selectors';
import { compose, withProps, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import createForm, { TYPE } from './createForm';
import { ImageBrokenLink, ImageWarning, ImageRemove } from 'images/actions';
import { actionSetActionForm, actionSetFormError } from 'redux/actions/FormAction';
import { actionSetHistoryForm, actionClearHistoryForm } from 'redux/actions/HistoryAction';
import createHistoryForm from './actions/createHistoryForm';

const withFormUtils = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    withProps({
        TYPE,
    }),
    withHandlers({
        showForm: ({ setActionForm }) => options => {
            setActionForm(createForm(options));
        },
        hideForm: ({ setActionForm }) => () => {
            setActionForm({});
        },
    }),
    withHandlers({
        showHistoryForm: ({ form, setHistoryForm, hideForm, clearHistoryForm }) => ({
            onCancel = hideForm,
            history,
        }) => {
            const lists = Selectors.getLists(store.getState());
            const view = createHistoryForm({
                form,
                viewIndex: 0,
                history,
                formButtons: [
                    {
                        text: 'Close',
                        onClick: () => {
                            clearHistoryForm();
                            onCancel();
                        },
                    },
                ],
                lists,
            });

            setHistoryForm({ viewIndex: 0, view });
        },
        showDeleteConfirmationForm: ({ showForm, hideForm }) => ({
            name,
            message,
            image = ImageRemove,
            onConfirm,
            onCancel = hideForm,
            confirmButtonName = 'Remove',
            cancelButtonName = 'Cancel',
            confirmButtonType,
        }) => {
            if (name && !message) {
                message = `Are you sure you want to remove "${ name }"?`;
            }
            showForm({
                size: 'small',
                image,
                message,
                buttons: [
                    { text: cancelButtonName, onClick: onCancel },
                    {
                        text: confirmButtonName,
                        buttonType: confirmButtonType,
                        onClick: e => {
                            onConfirm(e, { hideForm });
                        },
                    },
                ],
            });
        },
        showErrorForm: ({ showForm, hideForm }) => ({
            message = 'Error has occurred',
            image = ImageWarning,
            onOk = hideForm,
        }) => {
            showForm({
                size: 'small',
                image,
                message,
                buttons: [{ text: 'Ok', onClick: onOk }],
            });
        },
        showCreateForm: ({ showForm, hideForm }) => ({
            name,
            message,
            image,
            fields,
            onCreate,
            onCancel = hideForm,
        }) => {
            if (name && !message) {
                message = `Create a new ${ name }`;
            }
            showForm({
                size: 'big',
                image,
                message,
                fields,
                formButtons: [
                    { text: 'Create', onClick: onCreate },
                    { text: 'Cancel', onClick: onCancel },
                ],
            });
        },
        showUpdateForm: ({ showForm, hideForm }) => ({
            name,
            message,
            image,
            fields,
            onUpdate,
            onCancel = hideForm,
        }) => {
            if (name && !message) {
                message = `Updating ${ name }`;
            }
            showForm({
                size: 'big',
                image,
                message,
                fields,
                formButtons: [
                    { text: 'Update', onClick: onUpdate },
                    { text: 'Cancel', onClick: onCancel },
                ],
            });
        },

        // page error
        showPageErrorForm: ({ showForm }) => ({
            message,
            image = ImageBrokenLink,
            onConfirm,
        } = {}) => {
            if (!message) {
                message = 'Invalid Page URL';
            }
            showForm({
                size: 'small',
                image,
                message,
                buttons: [{ text: 'Back', onClick: onConfirm }],
            });
        },
    }),
);

export default withFormUtils;

// private
function mapStateToProps(state) {
    return {
        form: Selectors.getForm(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setActionForm(form) {
            dispatch(actionSetActionForm(form));
        },
        setHistoryForm(options) {
            dispatch(actionSetHistoryForm(options));
        },
        clearHistoryForm() {
            dispatch(actionClearHistoryForm());
        },
        clearErrorForm() {
            dispatch(actionSetFormError(null));
        },
    };
}
