import { templateAccessAPI } from '../../api/template/TemplateAccessAPI';
import { templateViewAPI } from '../../api/template/TemplateViewAPI';
import { sectionAPI } from '../../api/template/SectionAPI';
import * as _ from 'underscore';

import * as FR from '../../components/Form/FormRules';
import { fieldsetKey } from '../../components/Form/FormRules';

// #### ACTION CREATORS #####

//  ##### FETCHING ##### //

export const SET_CURRENT_ACCESS_FETCHING = 'SET_CURRENT_ACCESS_FETCHING';

export const setAccessFetching = bool => {
    return {
        type: SET_CURRENT_ACCESS_FETCHING,
        payload: bool,
    };
};

export const SET_TRIGGERS_FETCHING = 'SET_TRIGGERS_FETCHING';

export const setTriggersFetching = bool => {
    return {
        type: SET_TRIGGERS_FETCHING,
        payload: bool,
    };
};

export const SET_FIELD_VALIDATIONS_FETCHING = 'SET_FIELD_VALIDATIONS_FETCHING';

export const setFieldValidationsFetching = bool => {
    return {
        type: SET_FIELD_VALIDATIONS_FETCHING,
        payload: bool,
    };
};

export const SET_ROLES_FETCHING = 'SET_ROLES_FETCHING';

export const setRolesFetching = bool => {
    return {
        type: SET_ROLES_FETCHING,
        payload: bool,
    };
};

export const SET_FORM_STATUS_FETCHING = 'SET_FORM_STATUS_FETCHING';

export const setFormStatusFetching = bool => {
    return {
        type: SET_FORM_STATUS_FETCHING,
        payload: bool,
    };
};

export const SET_VIEWS_FETCHING = 'SET_VIEWS_FETCHING';

export const setViewsFetching = bool => {
    return {
        type: SET_VIEWS_FETCHING,
        payload: bool,
    };
};

export const SET_TEMPLATE_VIEWS_FETCHING = 'SET_TEMPLATE_VIEWS_FETCHING';

export const setTemplateViewsFetching = bool => {
    return {
        type: SET_TEMPLATE_VIEWS_FETCHING,
        payload: bool,
    };
};

export const SET_ACTION_BUTTONS_FETCHING = 'SET_ACTION_BUTTONS_FETCHING';

export const SET_ORDER_FETCHING = 'SET_ORDER_FETCHING';

export const setOrderFetching = bool => {
    return {
        type: SET_ORDER_FETCHING,
        payload: bool,
    };
};

export const SET_ACCESS_CHOICES_FETCHING = 'SET_ACCESS_CHOICES_FETCHING';

export const setAccessChoicesFetching = bool => {
    return {
        type: SET_ACCESS_CHOICES_FETCHING,
        payload: bool,
    };
};

export const SET_VALIDATOR_CHOICES_FETCHING = 'SET_VALIDATOR_CHOICES_FETCHING';

export const setValidatorChoicesFetching = bool => {
    return {
        type: SET_VALIDATOR_CHOICES_FETCHING,
        payload: bool,
    };
};

export const SET_TRIGGER_CHOICES_FETCHING = 'SET_TRIGGER_CHOICES_FETCHING';

export const setTriggerChoicesFetching = bool => {
    return {
        type: SET_TRIGGER_CHOICES_FETCHING,
        payload: bool,
    };
};

//  ##### HAS ERRORS ##### //

export const SET_CURRENT_ACCESS_ERROR = 'SET_CURRENT_ACCESS_ERROR';

export const setAccessError = error => {
    return {
        type: SET_CURRENT_ACCESS_ERROR,
        payload: error,
    };
};

export const SET_TRIGGERS_ERROR = 'SET_TRIGGERS_ERROR';

export const setTriggersError = error => {
    return {
        type: SET_TRIGGERS_ERROR,
        payload: error,
    };
};

export const SET_FIELD_VALIDATIONS_ERROR = 'SET_FIELD_VALIDATIONS_ERROR';

export const setFieldValidationsError = error => {
    return {
        type: SET_FIELD_VALIDATIONS_ERROR,
        payload: error,
    };
};

export const SET_ROLES_ERROR = 'SET_ROLES_ERROR';

export const setRolesError = error => {
    return {
        type: SET_ROLES_ERROR,
        payload: error,
    };
};

export const SET_FORM_STATUS_ERROR = 'SET_FORM_STATUS_ERROR';

export const setFormStatusError = error => {
    return {
        type: SET_FORM_STATUS_ERROR,
        payload: error,
    };
};

export const SET_VIEWS_ERROR = 'SET_VIEWS_ERROR';

export const setViewsError = error => {
    return {
        type: SET_VIEWS_ERROR,
        payload: error,
    };
};

export const SET_TEMPLATE_VIEWS_ERROR = 'SET_TEMPLATE_VIEWS_ERROR';

export const setTemplateViewsError = error => {
    return {
        type: SET_TEMPLATE_VIEWS_ERROR,
        payload: error,
    };
};

export const SET_ORDER_ERROR = 'SET_ORDER_ERROR';

export const setOrderError = error => {
    return {
        type: SET_ORDER_ERROR,
        payload: error,
    };
};

export const SET_ACCESS_CHOICES_ERROR = 'SET_ACCESS_CHOICES_ERROR';

export const setAccessChoicesError = error => {
    return {
        type: SET_ACCESS_CHOICES_ERROR,
        payload: error,
    };
};

export const SET_VALIDATOR_CHOICES_ERROR = 'SET_VALIDATOR_CHOICES_ERROR';

export const setValidatorChoicesError = error => {
    return {
        type: SET_VALIDATOR_CHOICES_ERROR,
        payload: error,
    };
};

export const SET_TRIGGER_CHOICES_ERROR = 'SET_TRIGGER_CHOICES_ERROR';

export const setTriggerChoicesError = error => {
    return {
        type: SET_TRIGGER_CHOICES_ERROR,
        payload: error,
    };
};

//  ##### SET STATE ##### //

export const SET_VIEW = 'SET_VIEW';

export const setView = views => {
    return {
        type: SET_VIEW,
        payload: views,
    };
};

export const SET_CURRENT_ACCESS = 'SET_CURRENT_ACCESS';

export const setCurrentAccess = viewaccess => {
    return {
        type: SET_CURRENT_ACCESS,
        payload: viewaccess,
    };
};

export const SET_TRIGGERS = 'SET_TRIGGERS';

export const setTriggers = triggers => {
    return {
        type: SET_TRIGGERS,
        payload: triggers,
    };
};

export const SET_FIELD_VALIDATIONS = 'SET_FIELD_VALIDATIONS';

export const setFieldValidations = fieldValidations => {
    return {
        type: SET_FIELD_VALIDATIONS,
        payload: fieldValidations,
    };
};

export const SET_ROLES = 'SET_ROLES';

export const setRoles = roles => {
    return {
        type: SET_ROLES,
        payload: roles,
    };
};

export const SET_FORM_STATUS = 'SET_FORM_STATUS';

export const setFormStatus = formStatus => {
    return {
        type: SET_FORM_STATUS,
        payload: formStatus,
    };
};

export const SET_TEMPLATE_VIEWS = 'SET_TEMPLATE_VIEWS';

export const setTemplateViews = templateViews => {
    return {
        type: SET_TEMPLATE_VIEWS,
        payload: templateViews,
    };
};

export const SET_VIEWS = 'SET_VIEWS';

export const setViews = views => {
    return {
        type: SET_VIEWS,
        payload: views,
    };
};

export const SET_ORDER = 'SET_ORDER';

export const setOrder = order => {
    return {
        type: SET_ORDER,
        payload: order,
    };
};

export const SET_ACCESS_CHOICES = 'SET_ACCESS_CHOICES';

function setAccessChoices(choices) {
    return {
        type: SET_ACCESS_CHOICES,
        payload: choices,
    };
}

export const SET_VALIDATOR_CHOICES = 'SET_VALIDATOR_CHOICES';

function setValidatorFieldsetChoices(choices) {
    return {
        type: SET_VALIDATOR_CHOICES,
        payload: choices,
    };
}

function setValidatorFieldChoices(choices) {
    return {
        type: SET_VALIDATOR_CHOICES,
        payload: choices,
    };
}

export const SET_TRIGGER_CHOICES = 'SET_TRIGGER_CHOICES';

function setTriggerChoices(choices) {
    return {
        type: SET_TRIGGER_CHOICES,
        payload: choices,
    };
}

export const SET_ACCESS = 'SET_ACCESS';

function setAccess(viewaccess) {
    return {
        type: SET_ACCESS,
        payload: viewaccess,
    };
}

export const SET_FIELD_VALIDATION = 'SET_FIELD_VALIDATION';

function setFieldValidation(fieldValidations) {
    return {
        type: SET_FIELD_VALIDATION,
        payload: fieldValidations,
    };
}

export const SET_TRIGGER = 'SET_TRIGGER';

function setTrigger(triggers) {
    return {
        type: SET_TRIGGER,
        payload: triggers,
    };
}

// Get all fieldsets choices
function setAccessSectionWithFieldChoices(fields, sections) {
    return setAccessChoices({ fields, sections });
}

function setFieldsetChoices(fieldsets) {
    return setValidatorFieldsetChoices({ fieldsets });
}

function setFieldChoices(fields) {
    return setValidatorFieldChoices({ fields });
}

function setTriggerFieldsetWithFieldChoices(fieldsets, fields, triggerBase) {
    return setTriggerChoices({ fieldsets, fields, triggerBase });
}

// #### ACTION CREATORS END #####

// #### ACTION FUNCTIONS ####

export const actionSetOrderDown = (templateId, accessId, row, views) => {
    return async dispatch => {
        dispatch(setViewsFetching(true));
        try {
            const viewLists = Object.values(views);
            let currentIndex;

            viewLists.filter(viewRow => {
                if (viewRow.id === row.id) {
                    currentIndex = viewLists.indexOf(viewRow);
                }
            });
            const nextRow = currentIndex + 1;
            const temp = viewLists[currentIndex];
            viewLists[currentIndex] = viewLists[nextRow];
            viewLists[nextRow] = temp;

            const data = {
                views: viewLists.map(viewList => viewList.id),
            };
            const ordering = await templateAccessAPI.updateViewAccessView(
                templateId,
                accessId,
                data,
            );
            dispatch(setViews(ordering.views));
        } catch (error) {
            dispatch(setViewsError(error));
        } finally {
            dispatch(setViewsFetching(false));
        }
    };
};

// FOR NEW CHANGE
export const actionSetOrderUp = (templateId, accessId, row, views) => {
    return async dispatch => {
        dispatch(setOrderFetching(true));
        try {
            const viewLists = Object.values(views);
            let currentIndex;
            viewLists.filter(viewRow => {
                if (viewRow.id === row.id) {
                    currentIndex = viewLists.indexOf(viewRow);
                }
            });
            const prevRow = currentIndex - 1;
            const temp = viewLists[currentIndex];
            viewLists[currentIndex] = viewLists[prevRow];
            viewLists[prevRow] = temp;

            const data = {
                views: viewLists.map(viewList => viewList.id),
            };
            const ordering = await templateAccessAPI.updateViewAccessView(
                templateId,
                accessId,
                data,
            );
            dispatch(setViews(ordering.views));
        } catch (error) {
            dispatch(setOrderError(error));
        } finally {
            dispatch(setOrderFetching(false));
        }
    };
};

async function getRolesFn(dispatch, roles) {
    dispatch(setRolesFetching(true));
    try {
        // ### ROLES ###
        const rolesName = roles.map(role => role.name);
        dispatch(setRoles(rolesName));
    } catch (error) {
        dispatch(setRolesError(error));
    } finally {
        dispatch(setRolesFetching(false));
    }
}

async function getFormStatusFn(dispatch, formStatuses) {
    dispatch(setFormStatusFetching(true));
    try {
        // ### FORM_STATUS ###
        const formStatusName = formStatuses.map(status => status.name);
        dispatch(setFormStatus(formStatusName));
    } catch (error) {
        dispatch(setFormStatusError(error));
    } finally {
        dispatch(setFormStatusFetching(false));
    }
}

async function getTriggersFn(dispatch, triggers) {
    dispatch(setTriggersFetching(true));
    try {
        dispatch(setTriggers(_.indexBy(triggers, 'id')));
    } catch (error) {
        dispatch(setTriggersError(error));
    } finally {
        dispatch(setTriggersFetching(false));
    }
}

async function getFieldValidationsFn(dispatch, fieldValidations) {
    dispatch(setFieldValidationsFetching(true));
    try {
        dispatch(setFieldValidations(_.indexBy(fieldValidations, 'id')));
    } catch (error) {
        dispatch(setFieldValidationsError(error));
    } finally {
        dispatch(setFieldValidationsFetching(false));
    }
}

async function getViewsFn(dispatch, views) {
    dispatch(setViewsFetching(true));
    try {
        dispatch(setViews(views));
    } catch (error) {
        dispatch(setViewsError(error));
    } finally {
        dispatch(setViewsFetching(false));
    }
}

// ### GET ACCESS ### //
export const actionGetViewAccess = (templateId, accessId) => {
    return async dispatch => {
        dispatch(setAccessFetching(true));
        try {
            // ### VIEW ACCESS ###
            const viewaccess = await templateAccessAPI.getCurrentAccess(templateId, accessId);
            dispatch(setCurrentAccess(viewaccess));
            getRolesFn(dispatch, viewaccess.roles);
            getFormStatusFn(dispatch, viewaccess.form_statuses, templateId);
            getTriggersFn(dispatch, viewaccess.triggers);
            getFieldValidationsFn(dispatch, viewaccess.field_validations);
            getViewsFn(dispatch, viewaccess.views);
        } catch (error) {
            dispatch(setAccessError(error));
        } finally {
            dispatch(setAccessFetching(false));
        }
    };
};

// ### GET TEMPLATE VIEW ### //
export const actionGetTemplateView = templateId => {
    return async dispatch => {
        dispatch(setTemplateViewsFetching(true));
        try {
            const templateViews = await templateAccessAPI.getTemplateView(templateId);
            dispatch(setTemplateViews(_.indexBy(templateViews, 'id')));
        } catch (error) {
            dispatch(setTemplateViewsError(error));
        } finally {
            dispatch(setTemplateViewsFetching(false));
        }
    };
};

//  ### GET all Fields from fieldset ### //
function isField(obj) {
    let field = false;
    switch (obj.type) {
    case FR.textKey:
    case FR.radioKey:
    case FR.checkboxKey:
    case FR.buttonKey:
    case FR.calendarKey.default:
    case FR.labelKey:
    case FR.remarksKey:
    case FR.selectKey:
    case FR.textAreaKey:
    case FR.uploadButtonKey:
        const { title } = obj.label || {};
        field = {
            id: obj.id,
            title: obj.name || title || obj.id,
            value: obj.id,
            type: obj.type,
        };
    }
    return field;
}

function isHot(obj) {
    if (obj.type === FR.hoTableKey) {
        return obj.fields.map(field => ({
            id: field.id,
            title: field.name || field.id,
            value: field.id,
            type: field.hotType,
        }));
    }
    return [];
}

function getFields(obj) {
    let result = [];

    loop(obj);
    return result;
    function loop(obj) {
        if (!_.isObject(obj)) return;
        Object.entries(obj).map(child => {
            let [, value] = child;
            value = value || {};
            const field = isField(value);
            if (field) {
                result.push(field);
            } else {
                const columns = isHot(value);
                if (columns.length) {
                    result = [...result, ...columns];
                } else {
                    loop(value);
                }
            }
        });
    }
}

function getFieldsets(obj) {
    const result = [];

    loop(obj);
    return result;
    function loop(obj) {
        if (!_.isObject(obj)) return;
        Object.entries(obj).map(child => {
            let [, value] = child;
            value = value || {};
            const isFieldset = value.type === fieldsetKey;
            if (isFieldset) {
                result.push(value);
            } else {
                loop(value);
            }
        });
    }
}

// ### GET FIELDSET CHOICES ### // PRIVATE FUNCTION
async function getFieldsetsWithFields(templateId, viewId) {
    // call view api
    const view = await templateViewAPI.getCurrentView(templateId, viewId);
    // getting fieldsets id from current view
    const sectionsArray = view.sections;
    // const fieldsetIds = sectionsArray.map(sectionId => sectionId);

    // call each fieldsets api based on current view
    const eachSection = await Promise.all(
        sectionsArray.map(sectionId => {
            return sectionAPI.getViewSections(templateId, sectionId);
        }),
    );

    // for getting section as choices
    const sectionsResult = eachSection.map(section => {
        return { id: section.id, title: section.name, value: section.id };
    });

    let fieldsetsResult = [];
    // get fieldsets key
    eachSection.forEach(section => {
        const fieldsets = getFieldsets(section).map(fieldset => ({
            id: fieldset.id,
            title: fieldset.name,
            value: fieldset.id,
        }));

        fieldsetsResult = [...fieldsetsResult, ...fieldsets];
    });

    // Get fields
    const fieldsResult = getFields(eachSection);

    return { fieldsetsResult, fieldsResult, sectionsResult };
}

// Action Get access section + field choices
export const actionGetAccessSectionChoices = (templateId, viewId) => {
    return async dispatch => {
        dispatch(setAccessChoicesFetching(true));
        try {
            const { fieldsResult, sectionsResult } = await getFieldsetsWithFields(
                templateId,
                viewId,
            );
            dispatch(setAccessSectionWithFieldChoices(fieldsResult, sectionsResult));
        } catch (error) {
            dispatch(setAccessChoicesError(error));
        } finally {
            dispatch(setAccessChoicesFetching(false));
        }
    };
};

// Action Get validator fieldset choices
export const actionGetValidatorFieldsetChoices = (templateId, viewId) => {
    return async dispatch => {
        dispatch(setValidatorChoicesFetching(true));
        try {
            const view = await templateViewAPI.getCurrentView(templateId, viewId);
            const { sections } = view;
            const fieldsetSection = await sectionAPI.getTemplateSections(templateId);
            const filteredSection = fieldsetSection.filter(section =>
                sections.includes(section.id),
            );
            const sectionResult = filteredSection.map(section => ({
                id: section.id,
                title: section.name,
                value: section.id,
            }));
            dispatch(setFieldsetChoices(sectionResult));
        } catch (error) {
            dispatch(setValidatorChoicesError(error));
        } finally {
            dispatch(setValidatorChoicesFetching(false));
        }
    };
};

// Action Get validator field choices
export const actionGetValidatorFieldChoices = (templateId, fieldsetId) => {
    return async dispatch => {
        dispatch(setValidatorChoicesFetching(true));
        try {
            const section = await sectionAPI.getFields(templateId, fieldsetId);
            const fields = section.fieldsets;
            const response = await getFields(fields);
            dispatch(setFieldChoices(response));
        } catch (error) {
            dispatch(setValidatorChoicesError(error));
        } finally {
            dispatch(setValidatorChoicesFetching(false));
        }
    };
};

// Action Get trigger fieldset + field + triggerBase choices
export const actionGetTriggerFieldsetsChoices = (templateId, viewId) => {
    return async dispatch => {
        dispatch(setTriggerChoicesFetching(true));
        try {
            const { fieldsetsResult, fieldsResult } = await getFieldsetsWithFields(
                templateId,
                viewId,
            );
            const triggerBase = fieldsResult;
            await dispatch(
                setTriggerFieldsetWithFieldChoices(fieldsetsResult, fieldsResult, triggerBase),
            );
        } catch (error) {
            dispatch(setTriggerChoicesError(error));
        } finally {
            dispatch(setTriggerChoicesFetching(false));
        }
    };
};

// #### CREATION FUNCTION #####
export const actionAddAccessView = (templateId, accessId, data, hideformCallback) => {
    return async dispatch => {
        dispatch(setViewsFetching(true));
        try {
            const { views } = await templateAccessAPI.updateViewAccessView(
                templateId,
                accessId,
                data,
            );
            dispatch(setViews(views));
            hideformCallback();
        } catch (error) {
            dispatch(setViewsFetching(error));
        } finally {
            dispatch(setViewsFetching(false));
        }
    };
};

// ##### THIS WILL BE PARTIAL UPDATE using PATCH ##### //
export const actionAddAccess = (templateId, accessId, data, hideformCallback) => {
    return async dispatch => {
        dispatch(setAccessFetching(true));
        try {
            const access = await templateAccessAPI.createAccess(templateId, accessId, data);
            // viewaccess[access.id] = access;
            dispatch(setCurrentAccess(access));
            hideformCallback();
        } catch (error) {
            dispatch(setAccessError(error));
        } finally {
            dispatch(setAccessFetching(false));
        }
    };
};

export const actionAddValidator = (templateId, accessId, data, hideformCallback) => {
    return async (dispatch, getState) => {
        const { fieldValidations } = getState().templateAccess;
        dispatch(setFieldValidationsFetching(true));
        try {
            const fieldValidation = await templateAccessAPI.createValidator(
                templateId,
                accessId,
                data,
            );
            fieldValidations[fieldValidation.id] = fieldValidation;
            dispatch(setFieldValidation(fieldValidations));
            hideformCallback();
        } catch (error) {
            dispatch(setFieldValidationsError(error));
        } finally {
            dispatch(setFieldValidationsFetching(false));
        }
    };
};

export const actionAddTrigger = (templateId, accessId, data, hideformCallback) => {
    return async (dispatch, getState) => {
        dispatch(setTriggersFetching(true));
        const { triggers } = getState().templateAccess;
        try {
            const trigger = await templateAccessAPI.createTrigger(templateId, accessId, data);
            triggers[trigger.id] = trigger;
            dispatch(setTrigger(triggers));
            hideformCallback();
        } catch (error) {
            dispatch(setTriggersError(error));
        } finally {
            dispatch(setTriggersFetching(false));
        }
    };
};

export const actionUpdateAccess = (templateId, accessId, viewId, data, hideformCallback) => {
    return async (dispatch, getState) => {
        dispatch(setAccessFetching(true));
        const { views } = getState().templateAccess;
        try {
            const access = await templateAccessAPI.editAccess(templateId, accessId, viewId, data);
            views[access.id] = access;
            dispatch(setAccess(views));
            hideformCallback();
        } catch (error) {
            dispatch(setAccessError(error));
        } finally {
            dispatch(setAccessFetching(false));
        }
    };
};

export const actionUpdateValidator = (
    templateId,
    accessId,
    validatorId,
    data,
    hideformCallback,
) => {
    return async (dispatch, getState) => {
        dispatch(setFieldValidationsFetching(true));
        const { fieldValidations } = getState().templateAccess;
        try {
            const fieldValidation = await templateAccessAPI.editValidator(
                templateId,
                accessId,
                validatorId,
                data,
            );
            fieldValidations[fieldValidation.id] = fieldValidation;
            dispatch(setFieldValidation(fieldValidations));
            hideformCallback();
        } catch (error) {
            dispatch(setFieldValidationsError(error));
        } finally {
            dispatch(setFieldValidationsFetching(false));
        }
    };
};

export const actionUpdateTrigger = (templateId, accessId, triggerId, data, hideformCallback) => {
    return async (dispatch, getState) => {
        dispatch(setTriggersFetching(true));
        const { triggers } = getState().templateAccess;
        try {
            const trigger = await templateAccessAPI.editTrigger(
                templateId,
                accessId,
                triggerId,
                data,
            );
            triggers[trigger.id] = trigger;
            dispatch(setTrigger(triggers));
            hideformCallback();
        } catch (error) {
            dispatch(setTriggersError(error));
        } finally {
            dispatch(setTriggersFetching(false));
        }
    };
};

export const actionDeleteAccessView = (templateId, accessId, data, hideformCallback) => {
    return async dispatch => {
        dispatch(setViewsFetching(true));
        try {
            const { views } = await templateAccessAPI.updateViewAccessView(
                templateId,
                accessId,
                data,
            );
            dispatch(setViews(views));
            hideformCallback();
        } catch (error) {
            dispatch(setViewsError(error));
        } finally {
            dispatch(setViewsFetching(false));
        }
    };
};

export const actionDeleteAccess = (templateId, accessId, data, hideformCallback) => {
    return async dispatch => {
        dispatch(setAccessFetching(true));
        try {
            const newData = {
                disabled_sections: data.disabled_sections
                    ? data.disabled_sections.map(i => i.disabled_sections)
                    : [],
                disabled_fields: data.disabled_fields
                    ? data.disabled_fields.map(i => i.disabled_fields)
                    : [],
            };
            const access = await templateAccessAPI.removeAccess(templateId, accessId, newData);
            dispatch(setCurrentAccess(access));
            hideformCallback();
        } catch (error) {
            dispatch(setAccessError(error));
        } finally {
            dispatch(setAccessFetching(false));
        }
    };
};

export const actionDeleteValidator = (templateId, accessId, validatorId, hideformCallback) => {
    return async (dispatch, getState) => {
        dispatch(setFieldValidationsFetching(true));
        const { fieldValidations } = getState().templateAccess;
        try {
            await templateAccessAPI.removeValidator(templateId, accessId, validatorId);
            delete fieldValidations[validatorId];
            dispatch(setFieldValidation(fieldValidations));
            hideformCallback();
        } catch (error) {
            dispatch(setFieldValidationsError(error));
        } finally {
            dispatch(setFieldValidationsFetching(false));
        }
    };
};

export const actionDeleteTrigger = (templateId, accessId, triggerId, hideformCallback) => {
    return async (dispatch, getState) => {
        dispatch(setTriggersFetching(true));
        const { triggers } = getState().templateAccess;
        try {
            await templateAccessAPI.removeTrigger(templateId, accessId, triggerId);
            delete triggers[triggerId];
            dispatch(setTrigger(triggers));
            hideformCallback();
        } catch (error) {
            dispatch(setTriggersError(error));
        } finally {
            dispatch(setTriggersFetching(false));
        }
    };
};
