import BaseAPI from '../BaseAPI';

class TemplateAccessAPI extends BaseAPI {
    basePath = 'cap/templates/';

    async updateViewAccessView(templateId, accessId, data) {
        try {
            const { view_access: viewAccess } = await this.patch({
                path: `${ this.basePath }${ templateId }/view-accesses/${ accessId }/`,
                data: data,
            });
            return viewAccess;
        } catch (error) {
            throw error.statusText;
        }
    }

    // get list of accesses
    async getAccesses(templateId) {
        try {
            const { view_accesses: viewAccesses } = await this.get({
                path: `${ this.basePath }${ templateId }/view-accesses/`,
            });
            return viewAccesses;
        } catch (error) {
            throw error.statusText;
        }
    }

    // get current access
    async getCurrentAccess(templateId, accessId) {
        try {
            const { view_access: viewAccess } = await this.get({
                path: `${ this.basePath }${ templateId }/view-accesses/${ accessId }/`,
            });
            return viewAccess;
        } catch (error) {
            throw error.statusText;
        }
    }

    // Get templateView
    async getTemplateView(templateId) {
        try {
            const { views } = await this.get({
                path: `${ this.basePath }${ templateId }/views/`,
            });
            return views;
        } catch (error) {
            throw error.statusText;
        }
    }

    // Get Action button
    async getActionButtons(templateId) {
        try {
            const { sections } = await this.get({
                path: `${ this.basePath }${ templateId }/sections/`,
            });
            return sections;
        } catch (error) {
            throw error.statusText;
        }
    }

    // #### CREATE ####

    // ##### THIS WILL BE PARTIAL UPDATE using PATCH ##### //
    async createAccess(templateId, accessId, data) {
        try {
            const { view_access: viewAccess } = await this.patch({
                path: `${ this.basePath }${ templateId }/view-accesses/${ accessId }/`,
                data: data,
            });
            return viewAccess;
        } catch (error) {
            throw error.statusText;
        }
    }

    async createValidator(templateId, accessId, data) {
        try {
            const { field_validation: fieldValidation } = await this.post({
                path: `${ this.basePath }${ templateId }/view-accesses/${ accessId }/field-validations/`,
                data: data,
            });
            return fieldValidation;
        } catch (error) {
            throw error.statusText;
        }
    }

    async createTrigger(templateId, accessId, data) {
        try {
            const { trigger } = await this.post({
                path: `${ this.basePath }${ templateId }/view-accesses/${ accessId }/triggers/`,
                data: data,
            });
            return trigger;
        } catch (error) {
            throw error.statusText;
        }
    }

    // #### UPDATE ####

    async editAccess(templateId, accessId, viewId, data) {
        try {
            const { view } = await this.post({
                path: `/cap/template/${ templateId }/view-accesses/${ accessId }/views/${ viewId }/`,
                data: data,
            });
            return view;
        } catch (error) {
            throw error.statusText;
        }
    }

    async editValidator(templateId, accessId, validatorId, data) {
        try {
            const { field_validation: fieldValidation } = await this.post({
                path: `${ this.basePath }${ templateId }/view-accesses/${ accessId }/field-validations/${ validatorId }/`,
                data: data,
            });
            return fieldValidation;
        } catch (error) {
            throw error.statusText;
        }
    }

    async editTrigger(templateId, accessId, triggerId, data) {
        try {
            const { trigger } = await this.post({
                path: `${ this.basePath }${ templateId }/view-accesses/${ accessId }/triggers/${ triggerId }/`,
                data: data,
            });
            return trigger;
        } catch (error) {
            throw error.statusText;
        }
    }

    // #### DELETE ####
    async removeAccess(templateId, accessId, data) {
        try {
            const { view_access: viewAccess } = await this.patch({
                path: `${ this.basePath }${ templateId }/view-accesses/${ accessId }/`,
                data: data,
            });
            return viewAccess;
        } catch (error) {
            throw error.statusText;
        }
    }

    async removeValidator(templateId, accessId, validatorId) {
        try {
            const validator = await this.delete({
                path: `${ this.basePath }${ templateId }/view-accesses/${ accessId }/field-validations/${ validatorId }/`,
            });
            return validator;
        } catch (error) {
            throw error.statusText;
        }
    }

    async removeTrigger(templateId, accessId, triggerId) {
        try {
            const trigger = await this.delete({
                path: `${ this.basePath }${ templateId }/view-accesses/${ accessId }/triggers/${ triggerId }/`,
            });
            return trigger;
        } catch (error) {
            throw error.statusText;
        }
    }
}

export const templateAccessAPI = new TemplateAccessAPI();
