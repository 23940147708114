import React from 'react';
import PropTypes from 'prop-types';
import classnames from '../../Misc/utils/classnames';
import TreeMenu from '../../TreeMenu';
import TreeMenuToolTip from '../../TreeMenu/TreeMenuToolTip';

const capitalize = value => value.charAt(0).toUpperCase() + value.slice(1);

const getRuleValue = value => {
    if (typeof value === 'number') {
        return value.toFixed(2);
    } else if (typeof value === 'string') {
        return capitalize(value);
    }
    return capitalize;
};

class CreditScoringSummary extends React.Component {
    render() {
        const { id, className, tooltip, value, placeholder, showComponent } = this.props;
        const { children, meta } = value;

        if (!showComponent) return <div />;

        if (!value || !value.children) {
            const title = value && value.title;
            return (
                <div
                    id={id}
                    className={classnames('jpt-input credit-scoring-summary', [className])}>
                    <strong>{title || 'Credit Scoring Summary'}</strong>
                    <div>
                        <i>{placeholder || 'Generate credit score to view summary'}</i>
                    </div>
                </div>
            );
        }
        return (
            <div id={id} className={classnames('jpt-input credit-scoring-summary', [className])}>
                <TreeMenu
                    title={value.title || 'Credit Scoring Summary'}
                    value={tooltip && <TreeMenuToolTip text={tooltip} />}>
                    {children.map(({ children }) => {
                        return children.map(
                            rule =>
                                rule.is_visible && (
                                    <TreeMenu
                                        key={rule.id}
                                        title={rule.title}
                                        value={getRuleValue(rule.value)}
                                        overrideTotalScoreHighest={
                                            rule.override_total_score_highest
                                        }
                                    />
                                ),
                        );
                    })}
                </TreeMenu>
                <TreeMenu
                    className="total-credit-score"
                    title={<strong>{meta.totalTitle || 'Total Credit Score'}</strong>}
                    value={<strong>{getRuleValue(meta.total)}</strong>}></TreeMenu>
            </div>
        );
    }
}

CreditScoringSummary.defaultProps = {
    className: null,
    tooltip: null,
    value: {},
    showComponent: true,
};

CreditScoringSummary.propTypes = {
    className: PropTypes.string,
    tooltip: PropTypes.string,
    value: PropTypes.shape({
        title: PropTypes.string,
        value: PropTypes.number,
        children: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                title: PropTypes.string.isRequired,
                children: PropTypes.arrayOf(
                    PropTypes.shape({
                        title: PropTypes.string.isRequired,
                        children: PropTypes.arrayOf(
                            PropTypes.shape({
                                title: PropTypes.string.isRequired,
                                value: PropTypes.isRequired,
                            }),
                        ),
                    }),
                ).isRequired,
            }),
        ),
        meta: PropTypes.shape({
            total: PropTypes.number,
        }),
    }),
    showComponent: PropTypes.bool,
};

export default CreditScoringSummary;
