import React from 'react';

class FormHeadersPlaceholder extends React.Component {
    render() {
        return (
            <div
                className="form-headers-placeholder"
                style={{
                    marginBottom: '28px',
                    width: '100%',
                    display: 'inline-block',
                }}>
                <FormHeaderPlaceholder />
                <FormHeaderPlaceholder />
                <FormHeaderPlaceholder />
                <FormHeaderPlaceholder />
                <FormHeaderPlaceholder />
            </div>
        );
    }
}

class FormHeaderPlaceholder extends React.Component {
    render() {
        return (
            <div
                className="form-header-placeholder"
                style={{
                    marginRight: '15px',
                    marginBottom: '15px',
                    borderRadius: '5px',
                    width: 'calc(25% - 15px)',
                    display: 'inline-block',
                }}>
                <i
                    className="icon circle"
                    style={{
                        marginRight: '5px',
                        fontSize: '16px',
                        borderRadius: '8px',
                        color: '#ebebeb',
                        display: 'inline-block',
                        verticalAlign: 'top',
                    }}
                />
                <div
                    className="tab-title-placeholder"
                    style={{
                        width: 'calc(100% - 20px - 5px)',
                        height: '16px',
                        borderRadius: '5px',
                        backgroundColor: '#ebebeb',
                        display: 'inline-block',
                        verticalAlign: 'top',
                    }}>
                    <div className="moving-color" />
                </div>
            </div>
        );
    }
}

export default FormHeadersPlaceholder;
