import React from 'react';
import store from 'store';
import moment from 'moment-timezone';
import _ from 'underscore';
import * as FR from '../../../Form/FormRules';
import { actionSetHistoryForm } from 'redux/actions/HistoryAction';
import { NULL_VALUE_TEXT } from '../misc';
import { DEFAULT_FORMAT } from '../../datetime';

/**
 * This method generate history form object with given params data
 *
 * @param {any} { form, viewIndex, history, formButtons=[], setName, lists }
 * @returns {Object} historyForm
 */
function createHistoryForm({ form, viewIndex = 0, history, formButtons = [], setName, lists }) {
    const NULL_VALUE_DIV = <div className="null-value">{NULL_VALUE_TEXT}</div>;
    const NO_CHANGES_TEXT = 'No changes made';
    const typeOptions = [FR.checkboxKey, FR.radioKey, FR.selectKey];
    const columnName = {
        field: 'Field',
        oldValue: 'Old Value',
        newValue: 'New Value',
    };
    const historyForm = {
        name: 'form-history',
        size: 'bigWithScroll',
        views: [
            {
                type: FR.viewKey,
                showViewNumber: false,
                sections: [
                    {
                        name: 'history-table',
                        type: FR.sectionKey,
                        showTitleAndName: false,
                        fieldsets: [
                            {
                                className: 'table-header',
                                type: FR.fieldsetKey,
                                grids: [
                                    {
                                        type: FR.gridKey,
                                        fields: [
                                            {
                                                type: FR.labelKey,
                                                title: columnName['field'],
                                                bold: true,
                                            },
                                        ],
                                    },
                                    {
                                        type: FR.gridKey,
                                        fields: [
                                            {
                                                type: FR.labelKey,
                                                title: columnName['oldValue'],
                                                bold: true,
                                            },
                                        ],
                                    },
                                    {
                                        type: FR.gridKey,
                                        fields: [
                                            {
                                                type: FR.labelKey,
                                                title: columnName['newValue'],
                                                bold: true,
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                type: FR.fieldsetKey,
                                grids: [
                                    {
                                        type: 'grid',
                                        fields: [
                                            {
                                                type: 'breakline',
                                                className: 'header-border',
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                className: 'table-body',
                                type: FR.fieldsetKey,
                                grids: [
                                    {
                                        type: FR.gridKey,
                                        fieldsets: [],
                                    },
                                ],
                                name: 'History value',
                            },
                        ],
                    },
                ],
            },
        ],
    };

    const { oldValue = [], newValue = [], fields = {} } = history;
    const isSetData = _.isArray(newValue);
    const oldProductTypes = oldValue[FR.productTypesKey] || [];
    const newProductTypes = newValue[FR.productTypesKey] || [];
    const productTypes =
        oldProductTypes.length > newProductTypes.length
            ? oldValue[FR.productTypesKey]
            : oldValue[FR.productTypesKey];
    let fieldsets = [];

    if (isSetData) {
        const moreValue = newValue.length > oldValue.length ? newValue : oldValue;
        moreValue.forEach((_key, i) => {
            if (_.isEqual(oldValue, newValue)) return;
            const eachsetFieldsets = createHistoryFieldsets({
                oldValue: oldValue[i] || {},
                newValue: newValue[i] || {},
                fields,
                setName: `${setName} ${i + 1}`,
                lists,
                productTypes,
            });
            fieldsets = [...fieldsets, ...eachsetFieldsets];
        });
    } else {
        fieldsets = createHistoryFieldsets({ oldValue, newValue, fields, lists, productTypes });
    }

    historyForm.views[0].sections[0].fieldsets[2].grids[0].fieldsets = fieldsets;

    const recordsSize = fieldsets.length;
    if (!recordsSize || _.isEqual(newValue, oldValue)) {
        const grids = [
            {
                type: FR.gridKey,
                fields: [
                    {
                        type: FR.labelKey,
                        title: NO_CHANGES_TEXT,
                    },
                ],
            },
        ];
        const fieldset = {
            type: FR.fieldsetKey,
            grids: grids,
            className: 'record empty',
        };
        historyForm.views[0].sections[0].fieldsets[2].grids[0].fieldsets.push(fieldset);
    }

    let buttons;
    if (formButtons.length) {
        buttons = formButtons.map(({ text, onClick }) => {
            const name = text.replace(/\W /g, '').toLowerCase().replace(/ /g, '_');
            return {
                title: text,
                name,
                onClick,
            };
        });
    }
    return { form: historyForm, buttons };

    /**
     * This method generate the fieldsets object based on oldValue, newValue,
     *   fields, and setName.
     * @param {Object} - { oldValue, newValue, fields, setName }
     * {
     *   oldValue: { id: old_value, ... },
     *   newValue: { id: new_value, ... },
     *   fields: { id: object, ... },
     *   setName: {string},
     *   productTypes: {string[]} - product type value,
     *   lists: {Object},
     * }
     * lists : { list_id: list, ... }
     * @returns {FieldsetObject[]} [ FieldsetObject1, FieldsetObject2, FieldsetObjectN ]
     */
    function createHistoryFieldsets({ oldValue, newValue, fields, setName, productTypes, lists }) {
        const fieldsets = [];
        Object.keys(newValue).forEach((id, index) => {
            const PRODUCT_KEY = 'products';
            const SHOW_MORE_TEXT = 'Show more...';
            const grids = [];
            const typeTitle = [FR.buttonKey];
            let title, before, after;
            let isEmptyValueBefore, isEmptyValueAfter;
            // if not found, don't even show as a record
            if (!fields[id]) {
                return;
            }

            const isInputType = fields[id] && !_.includes(typeTitle, fields[id].type);
            const isHot = fields[id] && fields[id].type === FR.hoTableKey;
            const isSet = _.isArray(fields[id].set);
            const isMIDTID = fields[id] && fields[id].type === FR.midtidKey;
            const isMDR = fields[id] && fields[id].type === FR.mdrKey;
            const isProductMIDTID = id === PRODUCT_KEY;
            const mustShow = isMIDTID || isMDR;

            // set field name
            if (isInputType) {
                title = fields[id].label && fields[id].label.title;
            }
            if (!title) {
                title = (fields[id] && fields[id].name) || id;
            }

            // add in set fieldset when first loop and setName exist
            if (!index && setName) {
                const setLabelFieldset = {
                    type: FR.fieldsetKey,
                    grids: [
                        {
                            type: FR.gridKey,
                            fields: [
                                {
                                    type: FR.labelKey,
                                    title: (
                                        <div>
                                            <div className="text-bold show-sm column-inner">
                                                Record
                                            </div>
                                            <div className="set-name">{setName}</div>
                                        </div>
                                    ),
                                },
                            ],
                        },
                    ],
                    className: 'set-fieldset',
                };
                fieldsets.push(setLabelFieldset);
            }

            if (isHot || isSet || isProductMIDTID) {
                // field name
                grids.push({
                    type: FR.gridKey,
                    fields: [
                        {
                            type: FR.labelKey,
                            title: (
                                <div>
                                    <div className="text-bold show-sm column-inner">
                                        {columnName['field']}
                                    </div>
                                    {title}
                                </div>
                            ),
                        },
                    ],
                });
                // show more..
                grids.push({
                    type: FR.gridKey,
                    fields: [
                        {
                            type: FR.labelKey,
                            title: (
                                <div
                                    className="column-more"
                                    onClick={() => {
                                        const view = createHistoryForm({
                                            form,
                                            viewIndex: viewIndex + 1,
                                            setName: setName ? `${setName} > ${title}` : title,
                                            history: {
                                                oldValue: oldValue[id],
                                                newValue: newValue[id],
                                                fields,
                                            },
                                            formButtons,
                                            lists,
                                            productTypes,
                                        });
                                        // set and append new action form
                                        store.dispatch(
                                            actionSetHistoryForm({
                                                viewIndex: viewIndex + 1,
                                                view,
                                            }),
                                        );
                                    }}>
                                    {SHOW_MORE_TEXT}
                                </div>
                            ),
                        },
                    ],
                });
            } else {
                fields = {
                    // got to hard code
                    product: {
                        type: 'select',
                        options: {
                            listID: FR.productsKey,
                        },
                    },
                    ...fields,
                };
                // set before value
                before = processFieldValue({
                    field: fields[id],
                    value: oldValue[id],
                    lists,
                    productTypes,
                });

                // set after value
                after = processFieldValue({
                    field: fields[id],
                    value: newValue[id],
                    lists,
                    productTypes,
                });

                // don't show the record if not neeeded
                isEmptyValueBefore = _.isEmpty(before) || !before;
                isEmptyValueAfter = _.isEmpty(after) || !after;
                if (
                    !mustShow &&
                    ((isEmptyValueBefore && isEmptyValueAfter) || _.isEqual(before, after))
                ) {
                    return;
                }

                // insert value
                grids.push({
                    type: FR.gridKey,
                    fields: [
                        {
                            type: FR.labelKey,
                            title: (
                                <div>
                                    <div className="text-bold show-sm column-inner">
                                        {columnName['field']}
                                    </div>
                                    <div className="field-name">{title}</div>
                                </div>
                            ),
                        },
                    ],
                });

                grids.push({
                    type: FR.gridKey,
                    fields: [
                        {
                            type: FR.labelKey,
                            title: (
                                <div>
                                    <div className="text-bold show-sm column-inner">
                                        {columnName['oldValue']}
                                    </div>
                                    {before || NULL_VALUE_DIV}
                                </div>
                            ),
                        },
                    ],
                });

                grids.push({
                    type: FR.gridKey,
                    fields: [
                        {
                            type: FR.labelKey,
                            title: (
                                <div>
                                    <div className="text-bold show-sm column-inner">
                                        {columnName['newValue']}
                                    </div>
                                    {after || NULL_VALUE_DIV}
                                </div>
                            ),
                        },
                    ],
                });
            }

            const fieldset = {
                type: FR.fieldsetKey,
                grids: grids,
                className: 'record',
            };
            fieldsets.push(fieldset);
        });

        return fieldsets;
    }

    /**
     * To process field value and return field value's title
     * @param {Object} object - { field: object, value: any, lists: object, productTypes: [] }
     * @returns {any} newValue
     * depending variable - lists, typeOptions
     */
    function processFieldValue({ field, value, lists = {}, productTypes = [] }) {
        const isProductType = field && field.type === FR.productTypesKey;
        const isTypeWithOptions = field && _.includes(typeOptions, field.type);
        const isUploadButton = field && field.type === FR.uploadButtonKey;
        const isCalendar = field && field.type === FR.calendarKey.default;
        const isRemarks = field && field.type === FR.remarksKey;
        const isDatetimeRange = field && field.type === 'datetime_range';
        const isMIDTID = field && field.type === FR.midtidKey;
        const isMDR = field && field.type === FR.mdrKey;
        let newValue;

        // is field type with options
        if (isTypeWithOptions || isProductType) {
            // check is listID is being set
            let options = field.options;
            if (!_.isArray(options)) {
                const listID = options.listID;
                options = lists[listID];
            }

            if (_.isArray(value) && isTypeWithOptions) {
                newValue = value.join(',\n');
                newValue = _.map(value, v => {
                    const found = _.filter(options, o => o.value === v);
                    return found.length && found[0].title;
                }).join(',\n');
            } else {
                const found = _.filter(options, o => o.value === newValue);
                newValue = found.length && found[0].title;
            }
        } else if (isRemarks) {
        } else if (isUploadButton) {
            newValue = _.isObject(value) ? value.name : value;
        } else if (isMIDTID) {
            if (!productTypes.length) {
                newValue = <div className="record empty">{NO_CHANGES_TEXT}</div>;
                return newValue;
            }

            // show only those selected, based on selected product type
            value = value || [];
            const midtid = lists[FR.midtidKey];
            const products = lists[FR.productsKey];
            newValue = products.map((p, i) => {
                if (!_.includes(productTypes, p.value)) return;

                const paragraphs = [];
                const productText = `${p.title} :-`;
                midtid.forEach((mdr, i) => {
                    const { audit = true, title, value } = mdr;
                    if (!audit) return;

                    paragraphs.push(
                        <div className="product-midtid" key={i + 1}>
                            <div className="product-mid-title">{title}:&nbsp;</div>
                            {value[p.value] || NULL_VALUE_DIV}
                        </div>,
                    );
                });
                return (
                    <div className="column-midtid-product" key={i}>
                        <div className="product-title">{productText}</div>
                        {paragraphs}
                    </div>
                );
            });
        } else if (isMDR) {
            if (!productTypes.length) {
                newValue = <div className="record empty">{NO_CHANGES_TEXT}</div>;
                return newValue;
            }

            // show only those selected, based on selected product type
            value = value || [];
            const mdrs = lists[FR.standardMDRsKey];
            const products = lists[FR.productsKey];
            newValue = products.map((p, i) => {
                if (!_.includes(productTypes, p.value)) return;

                const paragraphs = [];
                const productText = `${p.title} :-`;
                mdrs.forEach((mdr, i) => {
                    const { audit = true, title, value } = mdr;
                    if (!audit) return;

                    paragraphs.push(
                        <div className="product-mdr" key={i + 1}>
                            <div className="product-mdr-title">{title}:&nbsp;</div>
                            {value[p.value] || NULL_VALUE_DIV}
                        </div>,
                    );
                });
                return (
                    <div className="column-mdr-product" key={i}>
                        <div className="product-title">{productText}</div>
                        {paragraphs}
                    </div>
                );
            });
        } else if (isDatetimeRange) {
            const fakeField = {
                type: FR.calendarKey.default,
                calendarType: field.type,
            };
            const from = processFieldValue({ field: fakeField, value: value['from'] });
            const to = processFieldValue({ field: fakeField, value: value['to'] });
            newValue = `From: ${from} - To: ${to}`;
        } else if (isCalendar) {
            switch (field.calendarType) {
                case FR.calendarKey.date:
                    newValue = moment(value).format(DEFAULT_FORMAT.date);
                case FR.calendarKey.time:
                    newValue = moment(value).format(DEFAULT_FORMAT.time);
                case FR.calendarKey.year:
                    newValue = moment(value).format(DEFAULT_FORMAT.year);
                default:
                    newValue = moment(value).format(
                        `${DEFAULT_FORMAT.date} ${DEFAULT_FORMAT.time}`,
                    );
            }
        } else {
            newValue = value;
        }

        return newValue;
    }
}

export default createHistoryForm;
