import React from 'react';
import Proptypes from 'prop-types';
import _ from 'underscore';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import { Input, Icon, Dropdown } from 'semantic-ui-react';
import Paging from './Paging';
import Table from './Table';

// Redux
import { withStateHandlers, withHandlers, compose } from 'recompose';

// Misc
import * as IcFeature from '../../../images/features';
import { escapeRegExp } from '../FormRules';
import { translateToSemanticDropdown as translateOptions } from '../../Translator';
import { getIconFromName } from './Misc';
import { log } from '../../Misc';

const TableActionButton = ({
    index,
    className,
    rows,
    checkboxes = [],
    table,
    img: { title, name, image },
    onClick,
}) => (
    <div
        key={index}
        className={['table__action--default ui label', className].join(' ')}
        onClick={e => {
            rows = rows || {};
            const checkedRows = [];
            const checkedIndex = [];

            Object.entries(checkboxes).forEach((checkbox, index) => {
                const [, isChecked] = checkbox;
                if (isChecked) {
                    checkedIndex.push(index);
                    checkedRows.push(rows[index]);
                }
            });

            typeof onClick === 'function' &&
                onClick(e, { checkedRows, checkedIndex, table: table });
        }}>
        <img className="action__icon" src={image} alt={name} title={title} />
    </div>
);

const TableEditButton = ({ index, activate, onClick }) => (
    <TableActionButton
        index={index}
        className={activate ? 'table__action--default-activated' : ''}
        img={{
            image: IcFeature.IconEdit,
            name: 'more',
            title: 'More Actions',
        }}
        onClick={onClick}
    />
);

/**
 * TableWrapper Component that will render table with
 * search bar, paging, and extra columns for No. and Actions.
 * No. column is auto generated.
 * Actions column is generated only if columnActions
 * prop is passed, formatted as, columnActions: { header: { title, name }, actions: Array }
 *
 * @class Table
 * @extends {React.Component}
 */
class TableWrapper extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isEditing: false,
            searchString: '',
            searchableColumns: [],
            headerCheckboxes: {},
            columnCheckboxes: {},
        };
    }

    componentDidMount() {
        const { data = {}, setFilteredRows } = this.props;
        if (data.rows.length) setFilteredRows(setRecordNumberIntoRows(data.rows));
    }

    componentDidUpdate(prevProps) {
        const {
            data = {},
            searchString,
            sortingHeaderName,
            sortingAsc,

            handleFilter,
            setFilteredRows,
        } = this.props;
        const {
            data: prevData,
            sortingHeaderName: prevSortingHeaderName,
            sortingAsc: prevSortingAsc,
            searchString: prevSearchString,
        } = prevProps;
        const isEqualSearchStr = searchString === prevSearchString;
        const isEqualHeaderName = sortingHeaderName === prevSortingHeaderName;
        const isEqualSortingAsc = sortingAsc === prevSortingAsc;
        const isEqualSearchObj = _.isEqual(data.search, prevData.search);

        if (data.rows && !_.isEqual(data.rows, prevData.rows)) {
            setFilteredRows(setRecordNumberIntoRows(data.rows));
            handleFilter();
            return;
        }
        // fitler / search / sort
        if (!isEqualSearchStr || !isEqualSearchObj || !isEqualSortingAsc || !isEqualHeaderName) {
            handleFilter();
        }
    }

    getTableActionComponents({ columnActions = {}, tableActions, isEditing }) {
        let generalTableActionComponent = [],
            tableActionComponent = [];
        const actions = _.isObject(columnActions) && columnActions.actions;
        // add table's action
        const isExistTableAction = _.filter(actions, action => action.isTableAction).length > 0;
        generalTableActionComponent =
            isEditing && isExistTableAction
                ? Object.entries(actions).map((button, index) => {
                    let [, value] = button;
                    value = value || {};
                    let { title, name, icon, onTableActionClick, isTableAction } = value;
                    const { rows } = this.props.data || {};
                    const image = image || getIconFromName(name) || icon;
                    name = name || title || '';
                    const imgOption = {
                        image,
                        name,
                        title,
                    };
                    onTableActionClick =
                          onTableActionClick ||
                          (e => {
                              e.preventDefault();
                          });

                    if (isTableAction)
                        return (
                            <TableActionButton
                                index={index}
                                rows={rows}
                                img={imgOption}
                                checkboxes={this.state.columnCheckboxes}
                                onClick={onTableActionClick}
                            />
                        );

                    return;
                })
                : [];
        generalTableActionComponent =
            isExistTableAction &&
            generalTableActionComponent.concat([
                <TableEditButton
                    key="edit"
                    index="edit"
                    activate={isEditing}
                    onClick={this.handleTableEditClick.bind(this)}
                />,
            ]);

        tableActionComponent = tableActionComponent.concat(generalTableActionComponent);
        tableActionComponent = tableActionComponent.concat(
            Object.entries(tableActions).map((action, index) => {
                const [, value] = action;
                const { icon, title, name, onClick } = value;
                const iconPath = getIconFromName(icon);
                const img = {
                    image: iconPath,
                    name,
                    title,
                };
                return (
                    <TableActionButton
                        key={index}
                        index={index}
                        img={img}
                        onClick={e => {
                            onClick && onClick(e, { value: title || name || icon });
                        }}
                    />
                );
            }),
        );

        return tableActionComponent;
    }

    handleSearchTextChanged(e, value) {
        this.props.setSearchString(value);
    }

    handleOptionChanged(e, data) {
        data = data || {};
        const action = data.action;
        const value = data.value;

        switch (action) {
        case 'search':
            this.onSearchColumnChanged(e, value);
            break;
        default:
        }
    }

    handleTableEditClick() {
        const { isEditing } = this.state;

        this.setState({
            isEditing: !isEditing,
        });
    }

    handlePageChanged(e, number) {
        const { page } = this.state;
        this.setState({
            page: {
                ...page,
                number,
            },
        });
    }

    handleHeaderClick(e, { name, asc, index }) {
        this.props.setSortingAsc(asc);
        this.props.setSortingHeaderName(name);
        this.props.setSortingHeaderIndex(index);
    }

    onSearchColumnChanged(e, value) {
        this.setState({
            searchableColumns: value,
        });
    }

    render() {
        let {
            // Replacing Component,
            Row,
            EmptyRow,
            Header,
            Footer,
            Content = Table,
            isTable = true,

            className,
            data = {},
            page = {},
            links = {},
            columnActions = {},
            tableActions = {},
            columnCheckboxes = {},
            headerCheckboxes = {},
            totalPages = 0,
            filteredRows = [],
            sortingHeaderIndex,
            sortingHeaderName,
            sortingAsc,

            // handler
            setPageNumber,
        } = this.props;
        if (Object.keys(this.props).length === 0) {
            return null;
        }
        const { title, description, search = {}, headers } = data;

        const searchDropdown = search.options || {};
        let searchDropdownOptions = searchDropdown.options || {};
        const translateTo = {
            name: ['key', 'value'],
            title: 'text',
        };
        // remove option that appears in search.match
        searchDropdownOptions = _.filter(searchDropdownOptions, option => {
            return search.match[option.name] === undefined;
        });
        // translate options to required format { title, name } => { key, text, value }
        searchDropdownOptions = translateOptions({
            options: searchDropdownOptions,
            translateTo,
        });

        const { isEditing } = this.state;
        const tableActionComponent = this.getTableActionComponents({
            columnActions,
            tableActions,
            isEditing,
        });

        const arrayRows = Object.entries(filteredRows);
        const first = (page.number - 1) * page.rows;
        const second = first + page.rows;
        const showingRows = arrayRows.slice(first, second);
        const sortingHeaders = setSortingIntoHeaders({
            headers,
            asc: sortingAsc,
            index: sortingHeaderIndex,
        });

        data = {
            ...data,
            rows: showingRows,
            headers: sortingHeaders,
        };

        return (
            <ReactCSSTransitionGroup
                transitionName="fade-in"
                transitionAppear={true}
                transitionAppearTimeout={500}
                transitionEnter={false}
                transitionLeave={false}>
                <div className={'jpt--input input__table ' + className} style={{ float: 'none' }}>
                    <div className="table__content--wrapper">
                        {title && <div className="table__title content--big">{title}</div>}
                        {description && (
                            <div className="table__description content--small">{description}</div>
                        )}
                    </div>
                    <div className="table__wrapper">
                        <div className="table__search">
                            <Input
                                className="table__searchbar"
                                iconPosition="left"
                                placeholder="Search"
                                onChange={_.debounce((e, data) => {
                                    const value = data.value;
                                    this.handleSearchTextChanged(e, value);
                                }, 300)}>
                                <Icon name="search" />
                                <input />

                                {searchDropdownOptions.length > 0 && (
                                    <Dropdown
                                        multiple={true}
                                        className="table__search--dropdown"
                                        basic
                                        floating
                                        options={searchDropdownOptions}
                                        placeholder="All"
                                        defaultValue={searchDropdown.defaultValue}
                                        onChange={(e, data) => {
                                            data = { ...data, action: 'search' };
                                            this.handleOptionChanged(e, data);
                                        }}
                                    />
                                )}
                                {tableActionComponent}
                            </Input>
                        </div>
                        <div className="table__content">
                            <Content
                                data={data}
                                Row={Row}
                                EmptyRow={EmptyRow}
                                Header={Header}
                                Footer={Footer}
                                columnCheckboxes={columnCheckboxes}
                                headerCheckboxes={headerCheckboxes}
                                links={links}
                                page={page}
                                sorting={sortingHeaderName === '_no'}
                                asc={sortingAsc}
                                columnActions={columnActions}
                                isTable={isTable}
                                isEditing={isEditing}
                                onHeaderClick={this.handleHeaderClick.bind(this)}
                            />
                        </div>
                        <Paging
                            page={page}
                            total={totalPages}
                            onPageClick={setPageNumber}
                            onNext={this.props.nextPage}
                            onPrev={this.props.prevPage}
                        />
                    </div>
                </div>
            </ReactCSSTransitionGroup>
        );
    }
}

export default compose(
    // set page number and rows number per page
    withStateHandlers(
        ({ data = {}, recordPerPage = 10 }) => ({
            page: {
                number: 1,
                rows: recordPerPage,
            },
            filteredRows: setRecordNumberIntoRows(data.rows),
            totalPages: 0,
            sortingHeaderIndex: -1,
        }),
        {
            nextPage: ({ page, totalPages }) => () => {
                const { number } = page;
                const nextNumber = number + 1;

                if (nextNumber > totalPages) {
                    return;
                }
                return {
                    page: {
                        ...page,
                        number: nextNumber,
                    },
                };
            },
            prevPage: ({ page }) => () => {
                const { number } = page;
                const prevNumber = number - 1;
                if (prevNumber < 1) {
                    return;
                }
                return {
                    page: {
                        ...page,
                        number: prevNumber,
                    },
                };
            },
            setPageNumber: ({ page }) => number => ({
                page: {
                    ...page,
                    number,
                },
            }),
            setPageRows: ({ page }) => rows => ({
                page: {
                    ...page,
                    rows,
                },
            }),
            setSearchString: () => str => ({
                searchString: str,
            }),
            setSortingAsc: () => (asc = true) => ({
                sortingAsc: asc,
            }),
            setSortingHeaderName: () => headerName => ({
                sortingHeaderName: headerName,
            }),
            setSortingHeaderIndex: () => index => ({
                sortingHeaderIndex: index,
            }),
            setFilteredRows: ({ page }) => rows => {
                return {
                    filteredRows: rows,
                    totalPages: Math.ceil(Object.entries(rows).length / page.rows),
                };
            },
        },
    ),
    withHandlers({
        filterBasedOnHeader: ({
            data = {},
            sortingAsc = true,

            // handler
            sortingHeaderName,
            sortingHeaderIndex,
        }) => ({ headerName, rows }) => {
            const { headers } = data;
            const { sort } = headers[sortingHeaderIndex] || {};
            sortingHeaderName = headerName || sortingHeaderName;

            if (!sortingHeaderName) return rows;

            const key = sortingHeaderName;
            const startTime = new Date().getTime();

            let filteredRows;
            if (sortingHeaderIndex < 0) {
                filteredRows = rows;
            } else {
                filteredRows = _.sortBy(rows, row => {
                    let value = row[key];
                    if (_.isFunction(sort)) {
                        value = sort(value);
                    } else if (_.isString(sort)) {
                        value = row[sort];
                    }
                    return value;
                });
            }

            if (!sortingAsc) {
                filteredRows = filteredRows.reverse();
            }

            const endTime = new Date().getTime();
            log('Total sorting time [header]: ' + (endTime - startTime) + 'ms');

            return filteredRows;
        },
        filterBasedOnMatch: ({
            data = {},
            filteredRows,

            // handler
            setPageNumber,
            setFilteredRows,
        }) => () => {
            let { headers, search = {}, rows } = data;
            const { match = {}, includes = [] } = search;
            let regex, matchedStr, value;
            const pattern = {};
            headers = [...headers.map(h => h.name), ...includes];
            rows = setRecordNumberIntoRows(rows);

            if (_.isEmpty(match)) return rows;

            headers.forEach(header => {
                const currentMatch = match[header];

                const patternOfMatch = _.filter(currentMatch, value => {
                    return _.isString(value);
                })
                    .map(value => {
                        value = escapeRegExp(value);
                        value = value.replace(/\ /g, '\\s');
                        return `\^${ value }\$`;
                    })
                    .join('|');

                if (patternOfMatch) pattern[header] = patternOfMatch;
            });

            if (_.isEmpty(pattern)) return rows;

            const startTime = new Date().getTime();

            // filter pattern
            const foundRows = rows.filter(r => {
                matchedStr = true;
                const foundInvalid = _.find(Object.entries(pattern), ([key, p]) => {
                    value = r[key];

                    if (!_.isString(value)) return false;

                    if (p.trim()) {
                        regex = new RegExp(p);
                        // if all value matched
                        matchedStr = value.match(regex);
                    }

                    return !matchedStr;
                });

                return !foundInvalid;
            });

            const endTime = new Date().getTime();
            log('Total sorting time [filter] : ' + (endTime - startTime) + 'ms');

            // to check and stop unnecessary update
            if (!_.isEqual(filteredRows, foundRows)) {
                setPageNumber(1);
                setFilteredRows(foundRows);
            }

            return foundRows;
        },
        // search only those available in data.headers
        filterBasedOnSearchString: props => (str = '', rows) => {
            const {
                data,
                fuzzy = true,
                // handlers
                setPageNumber,
                setFilteredRows,
            } = props;
            let { headers, search = {} } = data;
            const { includes = [], caseSensitive } = search;
            let matchedStr, regex, value;
            const strArr = str.trim().split(' ');
            headers = [...headers.map(h => h.name), ...includes];

            if (!str.trim()) {
                setPageNumber(1);
                setFilteredRows(rows);
                return;
            }

            const startTime = new Date().getTime();

            const filteredRows = rows.filter(r => {
                matchedStr = false;
                if (fuzzy) {
                    let rowStr = _.map(headers, h => {
                        const value = r[h];
                        if (_.isObject(value)) return;
                        return value;
                    }).join(' ');
                    if (!caseSensitive) {
                        rowStr = rowStr.toLowerCase();
                    }
                    matchedStr = _.all(
                        _.map(strArr, s => {
                            if (!caseSensitive) {
                                s = s.toLowerCase();
                            }
                            return rowStr.match(escapeRegExp(s));
                        }),
                    );
                } else {
                    matchedStr = _.find(headers, c => {
                        value = r[c];

                        if (!_.isString(value)) return;

                        regex = new RegExp(`.*${ escapeRegExp(str) }.*`, 'i');
                        // if value matched any of strArr
                        return value.match(regex);
                    });
                }
                return matchedStr;
            });

            const endTime = new Date().getTime();
            log('Total sorting time [searchString]: ' + (endTime - startTime) + 'ms');

            setPageNumber(1);
            setFilteredRows(filteredRows);
        },
    }),
    withHandlers({
        handleFilter: ({
            searchString,
            filterBasedOnHeader,
            filterBasedOnMatch,
            filterBasedOnSearchString,
        }) => () => {
            let rows = filterBasedOnMatch();
            rows = filterBasedOnHeader({ rows });
            filterBasedOnSearchString(searchString, rows);
        },
    }),
)(TableWrapper);

TableWrapper.defaultProps = {
    className: null,

    Row: null,
    EmptyRow: null,
    Header: null,
    Footer: null,
    Content: Table,

    totalPages: 0,
    data: {},
    page: {},
    links: {},
    pageIndex: true,
    columnActions: {},
    tableActions: [],
    headerCheckboxes: {},
    columnCheckboxes: {},
    filteredRows: [],

    isTable: true,
    fuzzy: true,
};

TableWrapper.propTypes = {
    className: Proptypes.string,

    Row: Proptypes.any,
    EmptyRow: Proptypes.any,
    Header: Proptypes.any,
    Footer: Proptypes.any,
    Content: Proptypes.any,

    totalPages: Proptypes.number,
    data: Proptypes.object,
    page: Proptypes.object,
    links: Proptypes.object,
    pageIndex: Proptypes.bool,
    columnActions: Proptypes.object,
    tableActions: Proptypes.array,
    headerCheckboxes: Proptypes.object,
    columnCheckboxes: Proptypes.object,
    filteredRows: Proptypes.array,

    isTable: Proptypes.bool,
    fuzzy: Proptypes.bool,
};

function setRecordNumberIntoRows(rows) {
    rows = rows.map((r, i) => ({
        ...r,
        _no: i + 1,
    }));

    return rows;
}

function setSortingIntoHeaders({ headers, asc, index }) {
    if (index < 0) return headers;

    headers = headers.map((h, i) => {
        let ascending, sorting;
        if (i === index) {
            ascending = asc;
            sorting = true;
        }

        return {
            ...h,
            asc: ascending,
            sorting,
        };
    });

    return headers;
}
