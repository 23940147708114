import store from '../../store';
import { detect } from 'detect-browser';
import { setPlatform } from '../../redux/actions/PageAction';

import { compose, withProps, lifecycle } from 'recompose';

/* -------------------------------
  HOC
 --------------------------------- */

export const withIE9Checking = compose(
    withProps(() => {
        const browser = detect();
        const isIE = /^ie/.test(browser.name);
        const platform = {
            browser: browser.name,
            version: browser.version,
            ie: isIE,
            chrome: /^chrome/.test(browser.name),
            mozilla: /^mozilla/.test(browser.name),
            safari: /^safari/.test(browser.name),
            opera: /^opera/.test(browser.name),
            ie9: isIE && /^9/.test(browser.version),
            ie10: isIE && /^10/.test(browser.version),
            ie11: isIE && /^11/.test(browser.version),
        };
        return {
            platform,
        };
    }),
    lifecycle({
        componentDidMount() {
            store.dispatch(setPlatform(this.props.platform));
        },
    }),
);
