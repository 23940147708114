import _ from 'underscore';
import { templateRoleAPI } from '../../api/template/TemplateRoleAPI';
import { startLoading, stopLoading } from './PageAction';
import { ERROR_MESSAGES } from '../../components/Misc/api';

export const SET_TEMPLATE_ROLES = 'SET_TEMPLATE_ROLES';
export const setTemplateRoles = templateRoles => {
    return {
        type: SET_TEMPLATE_ROLES,
        templateRoles,
    };
};

export function actionGetTemplateRoles(templateId) {
    return async dispatch => {
        dispatch(startLoading());

        try {
            const response = await templateRoleAPI.getRoles(templateId);
            const { status_cd, status_desc, roles } = response;

            const templateRoles = _.indexBy(roles, 'id');
            dispatch(setTemplateRoles(templateRoles));

            return {
                status_cd,
                status_desc,
                roles,
            };
        } catch (e) {
            const { status_cd, status_desc, users = [] } = e.responseJSON || {};

            let errorMessages = ERROR_MESSAGES[status_cd];

            if (!errorMessages) {
                errorMessages = ERROR_MESSAGES['default'];
            }

            return {
                status_cd,
                status_desc,
                users,
            };
        } finally {
            dispatch(stopLoading());
        }
    };
}
