import BaseAPI from '../BaseAPI';

class LoginAPI extends BaseAPI {
    loginPath = 'cap/login';

    constructor(obj) {
        obj = obj || {};
        obj.host = ''; // set host if different
        super(obj);
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
    }

    // To authenticate username and password
    async login(data) {
        let response;

        try {
            response = await this.post({
                path: this.loginPath,
                data: data,
            });
        } catch (e) {
            if (e.responseJSON) throw e.responseJSON;
        }

        return response;
    }

    // To logout
    async logout(type = null) {
        let response;
        let path = 'cap/logout';
        if (type) {
            path = `cap/logout?type=${ type }`;
        }
        try {
            response = await this.post({ path });
        } catch (e) {
            throw e.responseJSON;
        }
        return response;
    }
}

export default LoginAPI;
