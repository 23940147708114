import { createSelector } from 'reselect';
import { RootState } from 'store';

const valuesSelector = (state: RootState) => state.value.values;
const valuesByIdsSelector = (state: RootState, ids: string[]) => {
    const values = state.value.values;
    const filteredValues: Record<string, any> = {};
    Object.entries(values).forEach(([id, value]) => {
        const found = ids.indexOf(id) > -1;
        if (found) {
            filteredValues[id] = value;
        }
    });
    return filteredValues;
};
const customDefaultValueSelector = (state: RootState) => state.value.customDefaultValue;
const prevCustomDefaultValueSelector = (state: RootState) => state.value.prevCustomDefaultValue;

export const getValues = createSelector(valuesSelector, values => values);

export const getValuesByIds = createSelector(valuesByIdsSelector, values => values);

export const getCustomDefaultValue = createSelector(
    customDefaultValueSelector,
    customDefaultValue => customDefaultValue,
);

export const getPrevCustomDefaultValue = createSelector(
    prevCustomDefaultValueSelector,
    prevCustomDefaultValue => prevCustomDefaultValue,
);
