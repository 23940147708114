import React from 'react';

import { ComposedHeaderRow } from './Header';

const TableFooter = props => (
    <tfoot>
        <ComposedHeaderRow {...props} />
    </tfoot>
);

export default TableFooter;
