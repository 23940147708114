import { createSelector } from 'reselect';

const emailNotificationsSelector = state => state.emailNotification.emailNotifications;
const emailNotificationLimitSelector = state => state.emailNotification.limit;
const notificationScenarios = state => state.emailNotification.scenarios;
const emailNotificationErrorSelector = state => state.emailNotification.error;

export const getEmailNotifications = createSelector(
    emailNotificationsSelector,
    emailNotifications => emailNotifications,
);

export const getEmailNotificationLimit = createSelector(
    emailNotificationLimitSelector,
    limit => limit,
);

export const getNotificationScenarios = createSelector(
    notificationScenarios,
    scenarios => scenarios,
);

export const getEmailNotificationError = createSelector(
    emailNotificationErrorSelector,
    error => error,
);
