import store from 'store';
import { actionSetActionForm } from 'redux/actions/FormAction';
import { setValidities } from 'redux/actions/ValidityAction';
import { setValues } from 'redux/actions/ValueAction';
import { setValidators } from 'redux/actions/ValidatorAction';
import { setDisabledFields } from 'redux/actions/DisabledAction';

function createDeclineForm(data) {
    const { formStatus, applicationId } = data;
    const form = {
        views: [
            {
                type: 'view',
                sections: [
                    {
                        type: 'section',
                        title: 'Section Decline Application',
                        name: 'Decline Section',
                        fieldsets: [
                            {
                                type: 'fieldset',
                                name: 'Fieldset',
                                grids: [
                                    {
                                        type: 'grid',
                                        fields: [
                                            {
                                                type: 'label',
                                                name: 'Application ID [Label]',
                                                title: `Application: ${ applicationId }`,
                                                id: 'field_rn1523000462144',
                                                bold: true,
                                            },
                                        ],
                                    },
                                    {
                                        type: 'grid',
                                        className: 'decline-form-status',
                                        fields: [
                                            {
                                                type: 'label',
                                                name: 'Status [Label]',
                                                title: `Status: ${ formStatus }`,
                                                id: 'field_rn1523000462143',
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                type: 'fieldset',
                                grids: [
                                    {
                                        type: 'grid',
                                        fields: [
                                            {
                                                type: 'text',
                                                name: 'Merchant Registered Name [Input Field]',
                                                label: {
                                                    type: 'outer',
                                                    title: '* Merchant Registered Name',
                                                    position: 'top',
                                                },
                                                title: '',
                                                value: '',
                                                placeholder: 'Registered Name',
                                                id: 'field_rn1519982338630',
                                                isEditable: false,
                                            },
                                        ],
                                    },
                                    {
                                        type: 'grid',
                                        fields: [
                                            {
                                                type: 'text',
                                                name: 'Registered No. [Input Field]',
                                                label: {
                                                    type: 'outer',
                                                    title: '* Registered No.',
                                                    position: 'top',
                                                },
                                                title: '',
                                                value: '',
                                                placeholder: 'Registration No. e.g. 001234567-P',
                                                id: 'field_rn1519982341540',
                                                isEditable: false,
                                            },
                                        ],
                                        id: 'grid_rn1519627124107',
                                    },
                                ],
                                id: 'fieldset_rn1519626381483',
                                name: 'Fieldset Merchant Registered Info',
                            },
                            {
                                type: 'fieldset',
                                name: 'Break Line',
                                grids: [
                                    {
                                        type: 'grid',
                                        fields: [
                                            {
                                                type: 'breakline',
                                                id: 'field_rn1519982341530',
                                            },
                                        ],
                                    },
                                ],
                                id: 'fieldset_rn1523000498404',
                            },
                            {
                                type: 'fieldset',
                                name: 'Decline Title',
                                grids: [
                                    {
                                        type: 'grid',
                                        fields: [
                                            {
                                                type: 'label',
                                                name: 'Reason for Withdrawal / Decline [Label]',
                                                title: 'Reason for Withdrawal / Decline',
                                                id: 'field_rn1523000500379',
                                                bold: true,
                                            },
                                        ],
                                    },
                                ],
                                id: 'fieldset_rn1523000498405',
                            },
                            {
                                type: 'fieldset',
                                name: 'Decline Reason',
                                grids: [
                                    {
                                        type: 'grid',
                                        fields: [
                                            {
                                                type: 'select',
                                                name: 'Reason for Withdrawal / Decline [dropdown]',
                                                label: {
                                                    title: 'Reason for Withdrawal / Decline',
                                                },
                                                options: [
                                                    {
                                                        value: 'withdraw',
                                                        name: 'withdraw',
                                                        title: 'Merchant Withdraw Application',
                                                    },
                                                    {
                                                        value: 'decline',
                                                        name: 'decline',
                                                        title: 'MDR Declined',
                                                    },
                                                    {
                                                        value: 'others',
                                                        name: 'others',
                                                        title: 'Others (Pls Specify)',
                                                    },
                                                ],
                                                value: '',
                                                id: 'field_rn1523000539320',
                                                title: '',
                                                placeholder: 'Select One',
                                            },
                                            {
                                                type: 'text',
                                                id: 'field_rn1523000539321',
                                                title: '',
                                                placeholder: 'Others',
                                                trigger: {
                                                    by: 'field_rn1523000539320',
                                                    when: {
                                                        valueHas: 'others',
                                                    },
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'grid',
                                        fields: [
                                            {
                                                type: 'textarea',
                                                name: 'Decline - Comment [Textarea]',
                                                label: {
                                                    title: 'Comment',
                                                },
                                                placeholder: 'Comment',
                                                id: 'field_rn1523000560740',
                                                title: '',
                                            },
                                        ],
                                        id: 'grid_rn1523000557842',
                                    },
                                ],
                                id: 'fieldset_rn1523000533757',
                            },
                            {
                                type: 'fieldset',
                                name: 'RM Confirmation',
                                grids: [
                                    {
                                        type: 'grid',
                                        fields: [
                                            {
                                                type: 'label',
                                                name: 'Label',
                                                title: 'RM Confirmation',
                                                id: 'field_rn1523000635751',
                                                bold: true,
                                            },
                                            {
                                                type: 'checkbox',
                                                name:
                                                    'Confirmed Withdrawal / Decline Letter sent [CheckboxGroup]',
                                                label: {
                                                    title: '',
                                                    position: 'top',
                                                },
                                                options: [
                                                    {
                                                        name:
                                                            'confirmed_withdrawal_decline_letter_has_been_sent_to_merchant',
                                                        title:
                                                            'Confirmed Withdrawal / Decline Letter has been sent to Merchant',
                                                        value: 'sent',
                                                    },
                                                ],
                                                value: [],
                                                id: 'field_rn1523000652852',
                                                title: '',
                                            },
                                        ],
                                    },
                                    {
                                        type: 'grid',
                                        fields: [
                                            {
                                                type: 'calendar',
                                                name:
                                                    'Sent Withdrawal / Decline Letter Date [Calendar]',
                                                calendarType: 'date',
                                                label: {
                                                    title: 'Sent Withdrawal / Decline Letter Date',
                                                    position: 'top',
                                                },
                                                placeholder: 'YYYY/MM/DD',
                                                id: 'field_rn1523000739958',
                                                title: '',
                                                value: '',
                                            },
                                        ],
                                        id: 'grid_rn1523000646747',
                                    },
                                ],
                                id: 'fieldset_rn1523000631411',
                            },
                        ],
                    },
                ],
            },
        ],
    };
    const buttons = [
        {
            title: 'Decline',
            name: 'deline',
            buttonType: 'danger',
            onClick: {
                preset: ['validate_form', 'with_rule'],
                rule: ['decline'], // slug
            },
        },
        {
            title: 'Close',
            name: 'close',
            buttonType: 'secondary',
            onClick: () => {
                store.dispatch(actionSetActionForm({}));
            },
        },
    ];
    return { form, buttons };
}

export default createDeclineForm;

export {
    setDeclineFormDisabledFields,
    setDeclinFormValidators,
    clearDeclineFormValue,
    clearDeclineFormValidities,
};

function setDeclineFormDisabledFields() {
    store.dispatch(
        setDisabledFields({
            field_rn1519982338630: true,
            field_rn1519982341540: true,
        }),
    );
}

function setDeclinFormValidators() {
    store.dispatch(
        setValidators({
            field_rn1523000539320: ['isRequired'],
            field_rn1523000560740: ['isRequired', { max: 1000 }],
            field_rn1523000652852: ['isRequired'],
            field_rn1523000739958: ['isRequired'],
            field_rn1523000539321: ['isRequired', { max: 255 }],
        }),
    );
}

function clearDeclineFormValue() {
    store.dispatch(
        setValues({
            field_rn1523000539320: '',
            field_rn1523000560740: '',
            field_rn1523000652852: [],
            field_rn1523000739958: '',
            field_rn1523000539321: '',
        }),
    );
}

function clearDeclineFormValidities() {
    store.dispatch(
        setValidities({
            field_rn1523000539320: null,
            field_rn1523000560740: null,
            field_rn1523000652852: null,
            field_rn1523000739958: null,
            field_rn1523000539321: null,
        }),
    );
}
