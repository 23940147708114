import * as Keys from '../../Form/FormKeys';
import { TYPE } from './misc';
/**
 *
 * @param {object} data = {
 *  message {string},
 *  image {any} - image | path | ReactElement,
 *  fields {Field[]},
 *  buttons {Button[]} - button(s) within section,
 *  formButtons {Button[]} - button(s) outside of section,
 *  size {string} - e.g. 'big' or 'small', or undefined
 * }
 * @returns {object} formObject for actionForm
 */
function createForm({
    message = '',
    image = null,
    fields = [],
    buttons = [],
    formButtons = [],
    size = 'big',
}) {
    const form = {
        size: size,
        views: [
            {
                type: Keys.viewKey,
                showViewNumber: false,
                title: '',
                sections: [
                    {
                        id: 'section',
                        type: Keys.sectionKey,
                        showTitleAndName: false,
                        fieldsets: [],
                    },
                ],
            },
        ],
    };
    if (image) {
        form.views[0].sections[0].fieldsets.push({
            type: Keys.fieldsetKey,
            grids: [
                {
                    type: Keys.gridKey,
                    fields: [
                        {
                            type: Keys.imageKey,
                            title: message,
                            name: message,
                            src: image,
                        },
                    ],
                },
            ],
        });
    }
    if (message) {
        form.views[0].sections[0].fieldsets.push({
            type: Keys.fieldsetKey,
            grids: [
                {
                    type: Keys.gridKey,
                    fields: [
                        {
                            type: Keys.labelKey,
                            title: message,
                        },
                    ],
                },
            ],
        });
    }

    if (fields.length) {
        const fieldsFieldSet = {
            type: Keys.fieldsetKey,
            id: 'fieldset_fields',
            grids: [
                {
                    type: Keys.gridKey,
                    fields: [],
                },
            ],
        };
        fields.forEach(({ type, key, label, ...rest }) => {
            if (type === TYPE.label) {
                type = Keys.labelKey;
            } else if (type === TYPE.text) {
                type = Keys.textKey;
            } else if (type === TYPE.checkbox) {
                type = Keys.checkboxKey;
            } else if (type === TYPE.calendar) {
                type = Keys.calendarKey.default;
            } else if (type === TYPE.textarea) {
                type = Keys.textAreaKey;
            } else if (type === TYPE.select) {
                type = Keys.selectKey;
                rest[Keys.selectOptionsKey] = rest.choices;
                delete rest.choices;
            } else if (type === TYPE.radio) {
                type = Keys.radioKey;
                rest[Keys.selectOptionsKey] = rest.choices;
                delete rest.choices;
            } else {
                throw new Error('Invalid type');
            }
            const field = {
                type,
                id: key,
                ...rest,
            };

            if (label) {
                if (typeof label === 'string') {
                    label = { position: 'top', type: 'outer', title: label };
                }
                field[Keys.labelKey] = label;
            }
            fieldsFieldSet.grids[0].fields.push(field);
        });
        form.views[0].sections[0].fieldsets.push(fieldsFieldSet);
    }

    if (buttons.length) {
        const buttonFieldSet = {
            type: Keys.fieldsetKey,
            grids: [
                {
                    type: Keys.gridKey,
                    fields: [],
                },
            ],
        };
        buttons.forEach(({ text, onClick, buttonType }) => {
            const name = text
                .replace(/\W /g, '')
                .toLowerCase()
                .replace(/ /g, '_');
            buttonFieldSet.grids[0].fields.push({
                type: Keys.buttonKey,
                title: text,
                name,
                onClick,
                buttonType,
            });
        });
        form.views[0].sections[0].fieldsets.push(buttonFieldSet);
    }

    const ret = { form };

    if (formButtons.length) {
        ret.buttons = formButtons.map(({ text, onClick }) => {
            const name = text
                .replace(/\W /g, '')
                .toLowerCase()
                .replace(/ /g, '_');
            return {
                title: text,
                name,
                onClick,
            };
        });
    }

    return ret;
}

export default createForm;

export { TYPE };
