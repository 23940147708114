import {
    SET_FIELD,
    SET_FIELDS,
    SET_FIELDS_FETCHING,
    SET_FIELDS_ERROR,
} from '../actions/FieldAction';

const INITIAL_STATE = {
    fields: {},
    fetching: false,
    error: null,
};

export default function FieldsetReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
    case SET_FIELD: {
        const { id, field } = action.payload;

        return {
            ...state,
            fields: {
                ...state.fields,
                [id]: field,
            },
        };
    }
    case SET_FIELDS: {
        const { fields } = action;
        if (fields === null) {
            return {
                ...state,
                fields: {},
            };
        }
        return {
            ...state,
            fields: {
                ...state.fields,
                ...fields,
            },
        };
    }
    case SET_FIELDS_FETCHING: {
        const { fetching } = action;
        return {
            ...state,
            fetching,
        };
    }
    case SET_FIELDS_ERROR: {
        const { error } = action;
        return {
            ...state,
            error,
        };
    }
    default:
        return state;
    }
}
