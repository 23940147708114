import BaseAPI from '../BaseAPI';

export class RulesAPI extends BaseAPI {
    basePath = 'cap/forms/';

    async callRule(rule, data) {
        return await this.post({
            path: `${ this.basePath }${ rule }`,
            data,
        });
    }

    async getRules() {
        let rules;

        try {
            ({ rules } = await this.fakingRulesResponse());
        } catch (e) {
            throw e.responseJSON;
        }
        return rules;
    }

    fakingRulesResponse() {
        const promise = new Promise(resolve => {
            const rules = [
                {
                    id: '1',
                    name: 'Submit',
                    description: 'To submit form',
                },
                {
                    id: '2',
                    name: 'Save as draft',
                    description: 'To save form as draft',
                },
            ];
            resolve({ rules });
        });

        return promise;
    }
}
export const rulesAPI = new RulesAPI();
export default RulesAPI;
