import {
    SET_REPORT_TYPES_FETCHING,
    SET_REPORT_TYPES_ERROR,
    SET_REPORT_TYPES,
    SET_REPORT_CLIENTS,
} from '../actions/ReportAction';

const INITIAL_STATE = {
    reportTypes: {},
    fetching: false,
    error: null,
    clients: {},
};

export default function reportReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
    case SET_REPORT_TYPES_FETCHING: {
        const { fetching } = action;
        return {
            ...state,
            fetching,
        };
    }

    case SET_REPORT_TYPES_ERROR: {
        const { error } = action;
        return {
            ...state,
            error,
        };
    }

    case SET_REPORT_TYPES: {
        const { reportTypes } = action;
        return {
            ...state,
            reportTypes,
        };
    }

    case SET_REPORT_CLIENTS: {
        const { clients } = action;
        return {
            ...state,
            clients,
        };
    }

    default:
        return state;
    }
}
