import store from 'store';
import _ from 'underscore';
import ObjectPath from 'object-path';
import { compose, withHandlers } from 'recompose';
import * as FR from '../../Form/FormRules';
import * as Selectors from 'redux/selectors';
import { setTriggeredStates } from 'redux/actions/TriggerAction';

const withDependencyCheckHandler = compose(
    withHandlers({
        // Method to check the dependancy
        // triggerState now will be set based on validityPath regardless
        //   thisLevel is set to true or not
        dependencyCheck: ({
            validityPath,
            valuePath,
            initialId,
            set,
            trigger: defaultTrigger,
        }) => selfcheck => {
            if (!initialId) return;

            const reduxStates = store.getState();
            const dependants = Selectors.getTriggerDependants(reduxStates);
            const triggeredStates = Selectors.getTriggeredStates(reduxStates);
            const triggers = Selectors.getTriggers(reduxStates);
            const fieldsets = Selectors.getFieldsets(reduxStates);
            const found = _.uniq(dependants[initialId]);
            let parentTriggered = triggeredStates[initialId];
            const states = {};

            if (selfcheck) {
                const { data: trigger = defaultTrigger } = triggers[initialId] || {};
                let currentState = FR.isTriggered(
                    ObjectPath.get(triggeredStates, validityPath, null),
                );
                // condition was !trigger || _.isBoolean(currentState),
                // Due to the possibility of having different validityPath for different field id but same valueId
                // in different view. All field triggeredState need to be recheck even though triggeredState was set
                if (!trigger) {
                    return;
                }
                const { thisLevel } = trigger || {};
                let fieldValuePath = valuePath ? valuePath.split('.') : [];
                fieldValuePath.pop();
                fieldValuePath = fieldValuePath.join('.');

                const triggered = FR.getTriggersValidity({
                    trigger,
                    path: thisLevel && fieldValuePath,
                });

                const state = !triggered.isValid;
                currentState = FR.isTriggered(ObjectPath.get(triggeredStates, validityPath, null));

                // if is fieldset, set it's field hidden instead
                // of itself
                const foundFieldset = fieldsets[initialId];
                // for fieldset
                if (trigger.type === 'fieldset' && foundFieldset) {
                    let currentPath;

                    if (thisLevel) {
                        ObjectPath.set(states, `fieldsets.${ validityPath }`, state);
                        currentPath = validityPath.split('.');
                        currentPath.pop();
                        currentPath = currentPath.join('.');
                        ObjectPath.set(states, validityPath, state);
                    } else {
                        states[validityPath] = state;
                    }

                    foundFieldset.forEach(fieldId => {
                        const path = `${ currentPath }.${ fieldId }`;
                        currentState = FR.isTriggered(ObjectPath.get(triggeredStates, path, null));

                        if (currentState !== state) {
                            if (thisLevel) {
                                ObjectPath.set(states, path, state);
                            } else {
                                states[fieldId] = state;
                            }
                        }
                    });
                }

                if (currentState !== state) {
                    // for field
                    let formObjectState = state;
                    // for set, if it's false
                    if (set && !formObjectState) {
                        formObjectState = [{}];
                    }
                    ObjectPath.set(states, validityPath, formObjectState);
                }
            } else if (found) {
                let currentPath = validityPath ? validityPath.split('.') : [];
                currentPath.pop();
                currentPath = currentPath.join('.');

                found.forEach(id => {
                    const trigger = triggers[id] && triggers[id].data;
                    if (!trigger) return;
                    const { thisLevel, by } = trigger || {};
                    let state;

                    if (_.isArray(by)) {
                        // check if either of field id in by is triggered
                        const found = _.find(by, id => !triggeredStates[id]);
                        // if not found, _.find return undefined
                        //   set false only it's found
                        if (found) parentTriggered = false;
                    }

                    // if all parent is triggered
                    if (!_.isUndefined(parentTriggered) && parentTriggered) {
                        state = parentTriggered;
                    } // if any parent is not triggered
                    else {
                        let currentPath = valuePath ? valuePath.split('.') : [];
                        currentPath.pop();
                        currentPath = currentPath.join('.');
                        const fieldValuePath = thisLevel && currentPath;
                        const triggered = FR.getTriggersValidity({
                            trigger,
                            path: fieldValuePath,
                        });
                        state = !triggered.isValid;
                    }
                    // if is fieldset, set it's field hidden also
                    const foundFieldset = fieldsets[id];
                    // for fieldset
                    if (trigger.type === 'fieldset' && foundFieldset) {
                        if (thisLevel) {
                            ObjectPath.set(states, `fieldsets.${ id }`, state);
                        } else {
                            states[id] = state;
                        }

                        foundFieldset.forEach(fieldId => {
                            const fieldPath = currentPath ? `${ currentPath }.${ fieldId }` : fieldId;
                            ObjectPath.set(states, fieldPath, state);
                        });
                        return;
                    }
                    // for field
                    const fieldPath = currentPath ? `${ currentPath }.${ id }` : id;
                    ObjectPath.set(states, fieldPath, state);
                });
            }

            if (!_.isEmpty(states)) {
                store.dispatch(setTriggeredStates(states));
            }
        },
    }),
);

export default withDependencyCheckHandler;
