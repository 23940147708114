import store from '../../store';
import _ from 'underscore';

// Misc
import { session } from '../../components/SessionStorage';
import { goToLogin, goToDashboardUser, goToChangePassword } from '../../components/GoToPage';
import { ERROR_MESSAGES, HTTP_CODE } from '../../components/Misc/api';
import {
    showForbiddenAccess,
    showInvalidForm,
    showFailedMessage,
    showAlertMessage,
} from '../../components/Misc/forms/popUps';

const loadingQueue = [];

export const LOAD_PAGE = 'LOAD_PAGE';
function loadPage(state) {
    return {
        type: LOAD_PAGE,
        state: state,
    };
}

export const SET_PAGE_PATH = 'SET_PAGE_PATH';
function setPagePath(path) {
    return {
        type: SET_PAGE_PATH,
        path: path,
    };
}

export const SET_PAGE_PARAMS = 'SET_PAGE_PARAMS';
function setPageParams(params) {
    return {
        type: SET_PAGE_PARAMS,
        params: params,
    };
}

export const SET_BREADCRUMBS = 'SET_BREADCRUMBS';
function setBreadcrumbs(breadcrumbs) {
    return {
        type: SET_BREADCRUMBS,
        breadcrumbs: breadcrumbs,
    };
}

export const SET_LOADING_STATE = 'SET_LOADING_STATE';
function setLoadingState() {
    return {
        type: SET_LOADING_STATE,
        isLoading: loadingQueue.length,
    };
}

export const SET_LOCALE = 'SET_LOCALE';
export function setLocale(locale) {
    return {
        type: SET_LOCALE,
        locale,
    };
}

export const SET_PLATFORM = 'SET_PLATFORM';
export function setPlatform(platform) {
    return {
        type: SET_PLATFORM,
        platform,
    };
}

const debouncedStartLoading = _.debounce(dispatch => {
    dispatch(setLoadingState(true));
}, 100);
const debouncedStopLoading = _.debounce(dispatch => {
    dispatch(setLoadingState(false));
}, 100);
export const startLoading = () => (dispatch, getState) => {
    loadingQueue.push(true);
    debouncedStartLoading(dispatch, getState);
};
export const stopLoading = () => (dispatch, getState) => {
    loadingQueue.pop();
    debouncedStopLoading(dispatch, getState);
};

export const SET_EDIT_FORM = 'SET_EDIT_FORM';
function setEditForm(form) {
    return {
        type: SET_EDIT_FORM,
        form: form,
    };
}

export const SET_ACTION_MESSAGE = 'SET_ACTION_MESSAGE';
function setActionMessage(message) {
    return {
        type: SET_ACTION_MESSAGE,
        state: message.state,
        message: message.content,
    };
}

export const SET_IS_ADMIN_PAGE = 'SET_IS_ADMIN_PAGE';
function setIsAdminPage(isAdminPage) {
    return {
        type: SET_IS_ADMIN_PAGE,
        isAdminPage: isAdminPage,
    };
}

/**
 * by Page
 */
export const SET_USER_OPTION = 'SET_USER_OPTION';
function setUserOptions(option) {
    return {
        type: SET_USER_OPTION,
        path: option.path || '',
        option: option.data || {},
    };
}

export const SET_DEVICE_ONLINE = 'SET_DEVICE_ONLINE';
function setDeviceOnline(isOnline) {
    return {
        type: SET_DEVICE_ONLINE,
        isOnline,
    };
}

// ACTION
export function loadState(state) {
    return dispatch => {
        return dispatch(loadPage(state));
    };
}

export function actionSetPagePath(path) {
    return dispatch => {
        return dispatch(setPagePath(path));
    };
}

export function actionSetPageParams(params) {
    return dispatch => {
        return dispatch(setPageParams(params));
    };
}

export function actionSetBreadcrumbs(path) {
    return dispatch => {
        return dispatch(setBreadcrumbs(path));
    };
}

export function actionSetLoadingState(isLoading) {
    return dispatch => {
        return dispatch(setLoadingState(isLoading));
    };
}

export function actionSetEditForm(form) {
    return dispatch => {
        return dispatch(setEditForm(form));
    };
}

export function actionSetActionMessage(message) {
    return dispatch => {
        return dispatch(setActionMessage(message));
    };
}

export function actionSetIsAdminPage(isAdminPage = true) {
    return dispatch => {
        return dispatch(setIsAdminPage(isAdminPage));
    };
}

export function actionClearSession() {
    session.removeDashboardId();
    session.removeToken();
    session.removeUserInfo();
    session.removeLoginTime();
    session.removeExpiryDate();
    session.removeShownTimeoutWarnings();
    session.removeClient();
    return goToLogin();
}

export function actionForbiddenAcess({ message }) {
    store.dispatch(
        showForbiddenAccess({
            message,
            onConfirm: goToDashboardUser,
        }),
    );
}

export function actionShowError({ message }) {
    store.dispatch(
        showFailedMessage({
            message,
        }),
    );
}

export function actionAPIError(message) {
    store.dispatch(
        showAlertMessage({
            message: message || ERROR_MESSAGES[HTTP_CODE.NOT_FOUND],
        }),
    );
}

export function actionInvalidForm() {
    store.dispatch(
        showInvalidForm({
            onConfirm: goToDashboardUser,
        }),
    );
}

export function enforceChangePassword() {
    goToChangePassword();
}

// by Page
export function actionSetUserPageOptions(option) {
    return dispatch => {
        return dispatch(setUserOptions(option));
    };
}

export function actionSetConnectionAvailability(isOnline) {
    return dispatch => {
        dispatch(setDeviceOnline(isOnline));
    };
}
