import * as _ from 'underscore';
import { messageAPI } from '../../api/message/MessageAPI';

// #### ACTION CREATORS #####

//  ##### FETCHING ##### //

export const SET_MESSAGES_FETCHING = 'SET_MESSAGES_FETCHING';

export const setMessagesFetching = bool => {
    return {
        type: SET_MESSAGES_FETCHING,
        payload: bool,
    };
};

//  ##### ERROR ##### //

export const SET_MESSAGES_ERROR = 'SET_MESSAGES_ERROR';

export const setMessagesError = error => {
    return {
        type: SET_MESSAGES_ERROR,
        payload: error,
    };
};

// ##### SET ##### //

export const SET_MESSAGES = 'SET_MESSAGES';

export const setMessages = messages => {
    return {
        type: SET_MESSAGES,
        payload: messages,
    };
};

export const SET_MESSAGE = 'SET_MESSAGE';

export const setMessage = messages => {
    return {
        type: SET_MESSAGE,
        payload: messages,
    };
};

export const SET_PUBLISH_DATE = 'SET_PUBLISH_DATE';

export const setPublishDate = publishDate => {
    return {
        type: SET_PUBLISH_DATE,
        payload: publishDate,
    };
};

// #### ACTION CREATORS END #####

// #### ACTION FUNCTIONS ####

export const actionGetMessages = () => {
    return async dispatch => {
        dispatch(setMessagesFetching(true));
        try {
            const messages = await messageAPI.getMessages();
            dispatch(setMessages(_.indexBy(messages, 'id')));
        } catch (error) {
            dispatch(setMessagesError(error));
        } finally {
            dispatch(setMessagesFetching(false));
        }
    };
};

export const actionAddMessage = (data, hideformCallback) => {
    return async (dispatch, getState) => {
        dispatch(setMessagesFetching(true));
        const { messages } = getState().message;
        try {
            const message = await messageAPI.createMessage(data);
            messages[message.id] = message;
            dispatch(setMessage(messages));
            hideformCallback();
        } catch (error) {
            dispatch(setMessagesError(error));
        } finally {
            dispatch(setMessagesFetching(false));
        }
    };
};

export const actionUpdateMessage = (messageId, data, hideformCallback) => {
    return async (dispatch, getState) => {
        dispatch(setMessagesFetching(true));
        const { messages } = getState().message;
        try {
            const message = await messageAPI.editMessage(messageId, data);
            messages[message.id] = message;
            dispatch(setMessage(messages));
            hideformCallback();
        } catch (error) {
            dispatch(setMessagesError(error));
        } finally {
            dispatch(setMessagesFetching(false));
        }
    };
};

export const actionDeleteMessage = (messageId, hideformCallback) => {
    return async (dispatch, getState) => {
        dispatch(setMessagesFetching(true));
        const { messages } = getState().message;
        try {
            await messageAPI.removeMessage(messageId);
            delete messages[messageId];
            dispatch(setMessage(messages));
            hideformCallback();
        } catch (error) {
            dispatch(setMessagesError(error));
        } finally {
            dispatch(setMessagesFetching(false));
        }
    };
};

export const actionGetUserMessages = () => {
    return async dispatch => {
        dispatch(setMessagesFetching(true));
        try {
            const messages = await messageAPI.getMessages();
            dispatch(setMessages(messages));
        } catch (error) {
            dispatch(setMessagesError(error));
        } finally {
            dispatch(setMessagesFetching(false));
        }
    };
};

export const actionGetPublishDate = publishDate => {
    return async dispatch => {
        dispatch(setMessagesFetching(true));
        try {
            dispatch(setPublishDate(publishDate));
        } catch (error) {
            dispatch(setMessagesError(error));
        } finally {
            dispatch(setMessagesFetching(false));
        }
    };
};

// #### ACTION FUNCTIONS END ####
