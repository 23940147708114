import _ from 'underscore';
import { isFunction } from '..';
import * as FR from '../../Form/FormRules';
import { compose, withStateHandlers, lifecycle, withProps } from 'recompose';
import withIsEditableUpdate from './withIsEditableUpdate';
import withValueUpdate from './withValueUpdate';
import withProcessedTriggerState from './withProcessedTriggerState';
import withClearOnHide from './withClearOnHide';
import withProcessedFieldname from './withProcessedFieldname';
import withProcessedDatetime from './withProcessedDatetime';
import withIsDisabledOptionsUpdate from './withIsDisabledOptionsUpdate';
import withCustomListOptionsUpdate from './withCustomListOptionsUpdate';
import withCampaignCheck from './withCampaignCheck';
import withTargetListTriggerValue from './withTargetListTriggerValue';
import withSetActiveCampaignsToDropdown from './withSetActiveCampaignsToDropdown';

/**
 * A decorator that process a field props value and decide either to
 * set default value, and to generate extras like tabPath and fieldname,
 * value, isEditable, triggerState, all is handled through inclusion of other decorators.
 *
 * It's composed with:
 * withIsEditableUpdate, withProcessedTriggerState, withProcessedFieldname...
 */
const withProcessedProps = compose(
    // to have a default state value that never change after mounted:-
    //   initialValue, initialId, id (valuePath), tabPath, setPath, valuePath, validityPath,
    withStateHandlers(
        ({ id, isTab, valueId, tabIndex, setId, setPath = '', tabId, tabPath = '', initialId }) => {
            initialId = initialId || id;
            valueId = valueId || initialId;
            // to append path to tab1.0.tab2 if it's tab only
            if (isTab) {
                setPath =
                    setPath && setId && setPath !== setId
                        ? `${ setPath }.${ setId }`
                        : setPath || setId;
                tabPath =
                    tabPath && tabId && tabPath !== tabId
                        ? `${ tabPath }.${ tabId }`
                        : tabPath || tabId;
            }

            // with tabIndex
            if (isTab && tabIndex > -1) {
                // for value, trigger
                setPath = setPath ? `${ setPath }.${ tabIndex }` : setPath || setId;
                // for validity
                tabPath = tabPath ? `${ tabPath }.${ tabIndex }` : tabPath || tabId;
            }

            // if setPath exist, valueId exist and both not equal
            //   multiple conditions to prevent creating 'undefined.0.undefined'
            const valuePath =
                setPath && valueId && setPath !== valueId ? `${ setPath }.${ valueId }` : valueId;
            const validityPath =
                tabPath && initialId && tabPath !== initialId
                    ? `${ tabPath }.${ initialId }`
                    : initialId;

            return {
                initialId,
                validityPath,
                valuePath,
                id: valuePath,
                tabPath,
                setPath,
            };
        },
    ),
    withIsEditableUpdate,
    // ### end
    withProcessedTriggerState,
    withProcessedFieldname,
    // process onChange and onClick
    //   and options and tabIndex
    //   to have default value
    withProps(({ onChange, onClick, options = [], tabIndex = -1 }) => {
        // if its in tab
        onChange = isFunction(onChange);
        onClick = _.isObject(onClick) ? onClick : isFunction(onClick);

        return {
            tabIndex,
            options,
            onChange,
            onClick,
        };
    }),
);

const withFieldProcessedProps = withFields(withProcessedProps);

export default withFieldProcessedProps;
export { withProcessedProps };

function withFields(withProcessedProps) {
    return compose(
        withProcessedProps,
        // This need to be run after withIsEditableUpdate, to have the updated isEditable state and
        // This also need to be run before withValueUpdate before it updated localValue to latest value
        // This is to check if field value is updated but not localValue, which means is not a manual
        // inputted value, then this will check and update field validity e.g. copy feature
        lifecycle({
            componentDidUpdate(prevProps) {
                const {
                    type,
                    localValue,
                    value,
                    initialId,
                    isEditable,
                    isValid = true,
                    validityPath,
                    onValidityChange,
                } = this.props;
                if (!FR.isCaptureFieldType(type)) return;
                const hasLocalValueChanged = _.isEqual(localValue, value); // has local value updated to latest value
                const hasValueChanged = !_.isEqual(prevProps.value, value);

                if (value && hasValueChanged && !hasLocalValueChanged) {
                    const validity = FR.validateInput({ id: initialId, value });
                    if (validity.isValid !== isValid) {
                        isEditable &&
                            onValidityChange({
                                id: validityPath,
                                validity,
                            });
                    }
                }
            },
            componentDidMount() {
                const {
                    type,
                    localValue,
                    value,
                    initialId,
                    isEditable,
                    validityPath,
                    isValid = true,
                    onValidityChange,
                } = this.props;
                if (!FR.isCaptureFieldType(type)) return;
                const hasLocalValueChanged = _.isEqual(localValue, value); // has local value updated to latest value

                if (value && !hasLocalValueChanged) {
                    const validity = FR.validateInput({ id: initialId, value });
                    if (validity.isValid !== isValid) {
                        isEditable &&
                            onValidityChange({
                                id: validityPath,
                                validity,
                            });
                    }
                }
            },
        }),
        withValueUpdate,
        withTargetListTriggerValue,
        withProcessedDatetime,
        withClearOnHide,
        withIsDisabledOptionsUpdate,
        withCustomListOptionsUpdate,
        withCampaignCheck,
        withSetActiveCampaignsToDropdown,
    );
}
