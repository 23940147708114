import _ from 'underscore';
import * as FR from '../../Form/FormRules';

/**
 * Method to get fieldsets of current field object
 * @param {Object} obj field object
 * @returns {Object} fieldsets
 */
function getFieldsets(obj) {
    const fieldsets = [];
    loop(obj);
    return fieldsets;

    function loop(obj) {
        if (!_.isObject(obj)) return;
        Object.entries(obj).forEach(([_index, value]) => {
            const { type, id } = value || {};
            if (type === FR.fieldsetKey || type === FR.terminalConfigurationKey) {
                fieldsets.push(id);
            }
            loop(value);
        });
    }
}

export default getFieldsets;
