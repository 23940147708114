import _ from 'underscore';
import store from '../store';
import SETTINGS from '../settings';
import { session } from '../components/SessionStorage';
import { setShowWarning, setCountdown, setSessionType } from '../redux/actions/SessionAction';
import { stopCountdownTimer } from '../containers/SessionTimeout';

// **** SESSION STORAGE **** //
const timerFilePath = '/timer.js';
const { SESSION_FIRST_WARN_TIME_BEFORE_TIMEOUT, ACTIVITY_IDLE_TIMEOUT } = SETTINGS;
let sessionWorker, idleWorker;
const sessionExpiryDate = session.getExpiryDate();

// **** NO ACTIVITY / IDLE **** //
let initialTime = new Date();

export function clearSession() {
    session.removeDashboardId();
    session.removeToken();
    session.removeUserInfo();
    session.removeLoginTime();
    session.removeExpiryDate();
    session.removeShownTimeoutWarnings();
    session.removeClient();
}

export function addResumeFormInLocalStorage() {
    const formPages = ['form', 'submitted'];
    const isInApplicationPage = formPages.includes(window.location.pathname.split('/')[1]);

    if (isInApplicationPage) {
        window.localStorage.setItem('resumeForm', true);
    }
}

// Shared / TO FORMAT OR SETUP TIMER
function formatTimer({
    sessionTimeToWarning,
    diff,
    interval = _.noop,
    stopFn = _.noop,
    countdownFn = _.noop,
}) {
    if (isNaN(diff)) {
        return;
    } // not number get out
    const inRange = sessionTimeToWarning >= diff;
    const timeLeftTillCountdown = sessionTimeToWarning - diff;
    let minutes = Math.floor(timeLeftTillCountdown / 1000 / 60); // calculate number of minutes
    let seconds = Math.floor(timeLeftTillCountdown / 1000) - minutes * 60; // calculate number of seconds

    // if number of minutes less than 10, add a leading "0"
    minutes = minutes.toString();
    if (minutes.length == 1) {
        minutes = '0' + minutes;
    }
    // if number of seconds less than 10, add a leading "0"
    seconds = seconds.toString();
    if (seconds.length == 1) {
        seconds = '0' + seconds;
    }

    interval({ timeLeftTillCountdown, inRange, stopFn, countdownFn });
}

// WHEN TIMER RUNS, SET THE REMAINING TIME LEFT
function whenTimerRun({ timeLeftTillCountdown, inRange, stopFn, countdownFn }) {
    if (timeLeftTillCountdown === 0 || !inRange) {
        countdownFn(true);
        stopFn();
        return;
    } // no difference
}

function callCountdown(countdown) {
    store.dispatch(setCountdown(countdown));
}

// STARTS OR RUNS THE TIMER
export function runTimer() {
    if (window.Worker !== undefined && sessionExpiryDate && sessionWorker !== null) {
        // create new worker
        sessionWorker = new Worker(timerFilePath);
        const firstWarningTime = new Date(SESSION_FIRST_WARN_TIME_BEFORE_TIMEOUT).getTime();
        const sessionTimeToWarning =
            Math.floor(new Date(sessionExpiryDate).getTime() - new Date().getTime()) -
            firstWarningTime;

        sessionWorker.addEventListener(
            'message',
            function(event) {
                formatTimer({
                    sessionTimeToWarning,
                    diff: event.data,
                    interval: whenTimerRun,
                    stopFn: stopTimer,
                    countdownFn: callCountdown,
                });
            },
            false,
        );
    }
}

// function to stopFn the timer
function stopTimer() {
    if (sessionWorker) {
        sessionWorker.terminate();
        sessionWorker = null;
    }
}

// **** ACTIVITY IDLE TIMER HANDLING **** //

// Stops Idle timer
export function stopIdleTimer() {
    if (idleWorker) {
        idleWorker.terminate();
        idleWorker = null;
    }
}

// WHEN THERES NO ACTIVITY
function doInactive() {
    addResumeFormInLocalStorage();
    store.dispatch(setSessionType('idleTimeout'));
    store.dispatch(setShowWarning(true));
    stopTimer(); // stops session timer
    stopIdleTimer(); // stops idle timer
    stopCountdownTimer();
    clearSession();
}

// Starts the idle timer
function startIdleTimer() {
    if (window.Worker !== undefined && ACTIVITY_IDLE_TIMEOUT && idleWorker !== null) {
        idleWorker = new Worker('/idleWorker.js');
        idleWorker.addEventListener(
            'message',
            function() {
                CheckIdleTime();
            },
            false,
        );
        idleWorker.postMessage('start'); // start interval ms
    }
}

function resetIdleTimer() {
    initialTime = new Date(); // reset the initial time
}

const throttledResetIdleTimer = _.throttle(resetIdleTimer, 5000);

// If any of these events are triggered, reset timer
export function setupIdleTimers() {
    document.addEventListener('mousemove', throttledResetIdleTimer, false);
    document.addEventListener('mousedown', throttledResetIdleTimer, false);
    document.addEventListener('keypress', throttledResetIdleTimer, false);
    document.addEventListener('touchmove', throttledResetIdleTimer, false);
    startIdleTimer();
}

function CheckIdleTime() {
    const dateNowTime = new Date().getTime();
    const lastActiveTime = new Date(initialTime).getTime();
    const inactiveSeconds = Math.floor((dateNowTime - lastActiveTime) / 1000);
    const idleTimeoutSeconds = Math.floor(ACTIVITY_IDLE_TIMEOUT / 1000);

    if (inactiveSeconds >= idleTimeoutSeconds) {
        doInactive();
    }
}
