import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import HistoryReducer from './HistoryReducer';
import MessageReducer from './MessageReducer';
import AuthenticationReducer from './AuthenticationReducer';
import UserReducer from './UserReducer';
import TemplateReducer from './TemplateReducer';
import TemplateDetailReducer from './TemplateDetailReducer';
import TemplateViewReducer from './TemplateViewReducer';
import TemplateAccessReducer from './TemplateAccessReducer';
import TemplateFormReducer from './TemplateFormReducer';
import TemplateRoleReducer from './TemplateRoleReducer';
import ViewReducer from './ViewReducer';
import FormReducer from './FormReducer';
import SectionReducer from './SectionReducer';
import PageReducer from './PageReducer';
import FeatureReducer from './FeatureReducer';
import ConfigurationReducer from './ConfigurationReducer';
import ValidatorReducer from './ValidatorReducer';
import TriggerReducer from './TriggerReducer';
import ValueReducer from './ValueReducer';
import ValidityReducer from './ValidityReducer';
import DisabledReducer from './DisabledReducer';
import ListReducer from './ListReducer';
import FieldsetReducer from './FieldsetReducer';
import FieldReducer from './FieldReducer';
import RuleReducer from './RuleReducer';
import SessionReducer from './SessionReducer';
import DelegatedAccessReducer from './DelegatedAccessReducer';
import DashboardReducer from './DashboardReducer';
import CustomListReducer from './CustomListReducer';
import ClientReducer from './ClientReducer';
import SchemaReducer from './SchemaReducer';
import ReportReducer from './ReportReducer';
import SystemConfigurationReducer from './SystemConfigurationReducer';
import EmailNotificationReducer from './EmailNotificationReducer';
import CaptureEntryReducer from '../../capture-entry/redux/reducers';
import BulkUploadReducer from './BulkUploadReducer';
import FormActionReducer from 'redux/actions/FormActionReducer';

export default combineReducers({
    customListOptions: CustomListReducer,
    dashboard: DashboardReducer,
    history: HistoryReducer,
    message: MessageReducer,
    authentication: AuthenticationReducer,
    user: UserReducer,
    template: TemplateReducer,
    templateDetail: TemplateDetailReducer,
    templateAccess: TemplateAccessReducer,
    templateView: TemplateViewReducer,
    templateForm: TemplateFormReducer,
    templateRole: TemplateRoleReducer,
    view: ViewReducer,
    form: FormReducer,
    section: SectionReducer,
    page: PageReducer,
    feature: FeatureReducer,
    configuration: ConfigurationReducer,
    validator: ValidatorReducer,
    trigger: TriggerReducer,
    value: persistReducer(
        {
            key: 'formValue',
            storage: storage,
        },
        ValueReducer,
    ),
    validity: ValidityReducer,
    disabled: DisabledReducer,
    list: ListReducer,
    fieldset: FieldsetReducer,
    field: FieldReducer,
    rule: RuleReducer,
    session: SessionReducer,
    delegatedAccess: DelegatedAccessReducer,
    client: ClientReducer,
    schema: SchemaReducer,
    report: ReportReducer,
    captureEntry: CaptureEntryReducer,
    systemConfiguration: SystemConfigurationReducer,
    emailNotification: EmailNotificationReducer,
    bulkUpload: BulkUploadReducer,
    formAction: FormActionReducer,
});
