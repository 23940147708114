import { SET_PRESET_LIST, SET_PRESET_LISTS } from '../actions/ListAction';
// temp
import CountriesList from './json/countries.json';
import CurrencyList from './json/currencies.json';
import MalaysiaBankList from './json/malaysia_banks.json';
import MalaysiaStateList from './json/malaysia_state.json';
import MCC from './json/mcc.json';
import CUPMCC from './json/cup_mcc.json';
import MOTOMCC from './json/moto_mcc.json';

import PostCodeCity from '../../lists/Postcode_map_city.json';
import PostCodeState from '../../lists/Postcode_map_state.json';
import PostCodeCityText from '../../lists/Postcode_map_city_text.json';
import PostCodeStateText from '../../lists/Postcode_map_state_text.json';
import Postcode from '../../lists/Postcode.json';
import State from '../../lists/State.json';
import City from '../../lists/City.json';
import SSICCode from '../../lists/SSIC_code.json';
import MCCVisa from '../../lists/MCC_visa.json';
import CimbPreviousAcquirer from '../../lists/Cimb_previous_acquirer.json';
import CimbCardlinkPran from '../../lists/Cimb_cardlink_pran.json';
import MCCIndustry from '../../lists/MCC_industry.json';
import PSICCode from '../../lists/PSIC_code.json';
import CountryDialCode from '../../lists/Country_dial_code.json';

// Bundle SG Postcode and Street, and Building mapping only if it's NETS client
const PostcodeSG = 'fetch';
const PostcodeSGStreetBuilding = 'fetch';

const INITIAL_STATE = {
    lists: {
        1: CountriesList,
        2: MalaysiaBankList,
        3: MalaysiaStateList,
        4: MCC,
        5: CUPMCC,
        6: MOTOMCC,
        7: PostCodeCity,
        8: PostCodeState,
        9: Postcode,
        10: State,
        11: City,
        12: PostCodeCityText,
        13: PostCodeStateText,
        14: SSICCode,
        16: MCCVisa,
        17: PostcodeSG,
        18: PostcodeSGStreetBuilding,
        19: CimbPreviousAcquirer,
        20: CimbCardlinkPran,
        21: MCCIndustry,
        22: CurrencyList,
        23: PSICCode,
        24: CountryDialCode,
    },
    fetching: false,
    error: null,
};

export default function ListReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
    case SET_PRESET_LIST: {
        const { id, list } = action.payload;
        return {
            ...state,
            lists: {
                ...state.lists,
                [id]: list,
            },
        };
    }
    case SET_PRESET_LISTS: {
        const { lists } = action;
        return {
            ...state,
            lists: {
                ...state.lists,
                ...lists,
            },
        };
    }
    default:
        return state;
    }
}
