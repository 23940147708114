export const SET_AJV = 'SET_AJV';
export function setAjv(ajv) {
    return {
        type: SET_AJV,
        ajv,
    };
}

export const SET_VALIDATOR = 'SET_VALIDATOR';
export function setValidator({ id, validator }) {
    return {
        type: SET_VALIDATOR,
        payload: {
            id,
            validator,
        },
    };
}

export const SET_VALIDATORS = 'SET_VALIDATORS';
export function setValidators(validators) {
    return {
        type: SET_VALIDATORS,
        validators,
    };
}

export const SET_VALIDATORS_ERROR = 'SET_VALIDATORS_ERROR';
const setValidatorsError = error => ({
    type: SET_VALIDATORS_ERROR,
    error,
});

export function clearValidatorErrors() {
    return setValidatorsError(null);
}
