import { createSelector } from 'reselect';

const accessSelector = state => state.delegatedAccess;
const accessesSelector = state => state.delegatedAccess.delegatedAccesses;
const accessTemplatesSelector = state => state.delegatedAccess.templates;
const accessUsersSelector = state => state.delegatedAccess.users;
const accessRolesSelector = state => state.delegatedAccess.roles;

export const getAccess = createSelector(
    accessSelector,
    leave => leave,
);

export const getAccesses = createSelector(
    accessesSelector,
    leaves => leaves,
);

export const getAccessTemplates = createSelector(
    accessTemplatesSelector,
    templates => templates,
);

export const getAccessUsers = createSelector(
    accessUsersSelector,
    users => users,
);

export const getAccessRoles = createSelector(
    accessRolesSelector,
    roles => roles,
);
