export const mphRules = {
    if: [
        {
            '==': [
                {
                    var: 'field_rn1521689671200',
                },
                'campaign',
            ],
        },
        true,
        {
            and: [
                {
                    '!': [
                        {
                            or: [
                                {
                                    in: [
                                        'waiver_on_non_cimb_account',
                                        {
                                            var: ['field_rn1520139895588'],
                                        },
                                    ],
                                },
                                {
                                    in: [
                                        'approval_for_ipp_24_months',
                                        {
                                            var: ['field_rn1520139895588'],
                                        },
                                    ],
                                },
                                {
                                    in: [
                                        'approval_for_ipp_36_months',
                                        {
                                            var: ['field_rn1520139895588'],
                                        },
                                    ],
                                },
                                {
                                    and: [
                                        {
                                            in: [
                                                'waiver_on_deposit_edc',
                                                {
                                                    var: ['field_rn1520139943268'],
                                                },
                                            ],
                                        },
                                        {
                                            in: [
                                                'waiver_on_rental_edc',
                                                {
                                                    var: ['field_rn1520139943268'],
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    and: [
                                        {
                                            in: [
                                                'waiver_on_deposit_mpos',
                                                {
                                                    var: ['field_rn1520139992476'],
                                                },
                                            ],
                                        },
                                        {
                                            in: [
                                                'waiver_on_rental_mpos',
                                                {
                                                    var: ['field_rn1520139992476'],
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    '==': [
                                        'no',
                                        {
                                            var: ['field_rn1521017816720', 'yes'],
                                        },
                                    ],
                                },
                                {
                                    '==': [
                                        'no',
                                        {
                                            var: ['field_rn1521017838874', 'yes'],
                                        },
                                    ],
                                },
                                {
                                    and: [
                                        {
                                            '>': [
                                                {
                                                    '+': { var: ['field_rn1522049175999', '0'] },
                                                },
                                                0,
                                            ],
                                        },
                                        {
                                            '==': [
                                                'card_reader_fee',
                                                {
                                                    var: ['field_rn1521017755844', 'false'],
                                                },
                                            ],
                                        },
                                        {
                                            or: [
                                                {
                                                    '<': [
                                                        {
                                                            var: ['field_rn1521017882606', '225'],
                                                        },
                                                        '225',
                                                    ],
                                                },
                                                {
                                                    '<': [
                                                        {
                                                            var: ['field_rn1521017864764', '275'],
                                                        },
                                                        '275',
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138350872', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138390941', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138401274', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138434889', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    if: [
                        {
                            in: [
                                {
                                    var: 'field_rn1520138475799',
                                },
                                ['', null],
                            ],
                        },
                        true,
                        {
                            '>=': [
                                {
                                    var: ['field_rn1520138475799', 1.4],
                                },
                                '1.4',
                            ],
                        },
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_edc_off_us_mydebit', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_edc_on_us_mydebit', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138495393', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138497404', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138499606', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138633648', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138638992', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138640745', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138642609', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138633649', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138638993', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138640746', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138642610', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138633650', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138638995', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138640748', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138642612', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138633629', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138638983', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138640736', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138642600', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138633529', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138638883', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138640636', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138642500', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138642598', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138831968', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138833918', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138835472', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138837393', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138830208', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138351872', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138398941', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138408274', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138433889', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    if: [
                        {
                            in: [
                                {
                                    var: 'field_rn1520138474799',
                                },
                                ['', null],
                            ],
                        },
                        true,
                        {
                            '>=': [
                                {
                                    var: ['field_rn1520138474799', 1.4],
                                },
                                '1.4',
                            ],
                        },
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_contactless_plug_pay_off_us_mydebit', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_contactless_plug_pay_on_us_mydebit', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138494393', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138496404', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138498606', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138833648', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138838992', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138840745', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138842609', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138833649', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138838993', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138840746', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138842610', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138833650', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138838995', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138840748', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138842612', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138833629', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138838983', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138840736', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138842600', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_contactless_plug_pay_rps_off_us_credit', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_contactless_plug_pay_rps_on_us_credit', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_contactless_plug_pay_rps_off_us_debit', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_contactless_plug_pay_rps_on_us_debit', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_contactless_plug_pay_rps_intl', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138831967', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138833917', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138835471', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138837392', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138830207', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138813648', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138818992', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138810745', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138812609', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138813745', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138818993', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138810746', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138812610', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138813458', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138818995', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138810748', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138812612', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138813629', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138818983', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138810736', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138812600', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138831962', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138833911', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138835478', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138837399', 1.4],
                        },
                        '1.4',
                    ],
                },
                {
                    '>=': [
                        {
                            var: ['field_rn1520138830204', 1.4],
                        },
                        '1.4',
                    ],
                },
            ],
        },
    ],
};
