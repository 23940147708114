import _ from 'underscore';
import * as FR from '../../Form/FormRules';
import getMatchedPlaceholders from './getMatchedPlaceholders';
import { Section } from '../../../redux/reducers/FormReducer';
/**
 * A method than get the list of fields in the obj.
 *
 * Definition(s):
 *   Trigger - { data, target, type } format as returned from backend
 *
 * @param {Object} obj - any form object with fields
 * @param {Object} triggers - trigger object { field_id: Trigger }
 * @param {string[]} mdrs - list of mdr ['payment_channel', 'off_us_credit', ...]
 * @returns {string[]} fields - List of field ids in the obj
 */
const getFields = (obj: Section, triggers = {}, mdrs = []): string[] => {
    let fields = [];
    loop(obj);
    return fields;

    function loop(obj) {
        if (!_.isObject(obj)) return;
        Object.entries(obj).forEach(child => {
            const value = child[1] || {};
            const { data: reduxTrigger = {} } = triggers[value.id] || {};
            const {
                type,
                setType,
                id,
                valueId,
                trigger = reduxTrigger,
                extend = {},
                options,
            } = value;
            const isSpreadsheet = setType === FR.setTypeKey.spreadsheet;
            if (FR.isCaptureFieldType(type) || isSpreadsheet) {
                fields.push(id);
                valueId && fields.push(valueId);

                // append mdrs field id as part of the field also
                if (type === FR.mdrKey) {
                    fields = [...fields, ...mdrs];
                }

                // add trigger dependant as depending ids
                if (_.isArray(trigger.by)) {
                    fields = [...fields, ...trigger.by];
                } else if (trigger.by) {
                    fields.push(trigger.by);
                }

                // add options mapping.key as depending id
                if (_.isObject(options) && !_.isEmpty(options)) {
                    const { listID } = options;
                    const keys = listID && getMatchedPlaceholders(String(listID));

                    if (_.isArray(keys) && keys.length) {
                        fields = [...fields, ...keys];
                    }
                }

                // add extend dependant as depending ids
                Object.values(extend).map(e => {
                    // extend.byParent
                    if (_.isArray(e.byParent)) {
                        fields = [...fields, ...e.byParent];
                    } else if (e.byParent) {
                        fields.push(e.byParent);
                    }
                    // extend.by
                    if (_.isArray(e.by)) {
                        fields = [...fields, ...e.by];
                    } else if (e.by) {
                        fields.push(e.by);
                    }
                });

                // terminal configuration consists of fieldsets.
                //   loop continue to get fields inside it
                if (type === FR.terminalConfigurationKey) loop(value);
            } else {
                loop(value);
                return;
            }
        });
    }
};

export default getFields;
