import React from 'react';

class SectionBuilderPlaceholder extends React.Component {
    render() {
        return (
            <div
                className="section-placeholder"
                style={{
                    marginBottom: '56px',
                    borderRadius: '5px',
                    width: '100%',
                    display: 'inline-block',
                    backgroundColor: 'white',
                    boxShadow: '0 1px 8px #cccccc',
                }}>
                <FieldsetBuilderPlaceholder />
            </div>
        );
    }
}

class FieldsetBuilderPlaceholder extends React.Component {
    render() {
        return (
            <div
                className="fieldset-placeholder"
                style={{ width: '100%', display: 'inline-block', padding: '30px' }}>
                <GridBuilderPlaceholder />
                <GridBuilderPlaceholder />
            </div>
        );
    }
}

class GridBuilderPlaceholder extends React.Component {
    render() {
        return (
            <div
                className="grid-placeholder"
                style={{ width: '50%', display: 'inline-block', minHeight: '100px' }}>
                <FieldPlaceholder />
            </div>
        );
    }
}

class FieldPlaceholder extends React.Component {
    render() {
        return (
            <div
                className="field-placeholder"
                style={{
                    width: 'calc(100% - 30px)',
                    borderRadius: '5px',
                    display: 'inline-block',
                    backgroundColor: '#ebebeb',
                    margin: '0 15px',
                    minHeight: '38px',
                }}>
                <div className="moving-color"></div>
            </div>
        );
    }
}

export default SectionBuilderPlaceholder;
