import React from 'react';
import PropTypes from 'prop-types';
import isObject from 'is-object';
import ObjectClone from 'clone';
import _ from 'underscore';

import GridBuilder from './GridBuilder';
import Tab from './DuplicableFieldset';
import TerminalConfiguration from './TerminalConfiguration';
import { FieldsetAction, AddGridButton } from '../Misc/template';
import { gridKey, fieldsetKey, terminalConfigurationKey, setTypeKey } from './FormRules';
import { compose, lifecycle, shouldUpdate, withProps } from 'recompose';
import { withProcessedProps, getFieldsets, filterByIds } from '../Misc/forms';
import withExtendingPropsHandler from '../Misc/forms/withExtendingPropsHandler';
import withOnValuesChange from '../Misc/forms/withOnValuesChange';
import withGetTriggerValidityHandler from '../Misc/forms/withGetTriggerValidityHandler';
import { getValidities, getValues, getTriggeredStates } from '../../redux/selectors';
import store from '../../store';
import defaultToFalse from '../Misc/value/defaultToFalse';
import withDependencyCheckHandler from '../Misc/forms/withDependencyCheckHandler';

const FieldsetHandsontable = React.lazy(() => import('./FieldsetHandsontable'));

// Recompose
const withShouldUpdate = compose(
    shouldUpdate((current, next) => {
        return !_.isEqual(current, next);
    }),
);

const TYPE_SPREADSHEET = setTypeKey.spreadsheet;

interface FieldsetBuilderProps {
    fieldset: any;
    grids: any[];
}
class FieldsetBuilder extends React.Component<FieldsetBuilderProps> {
    EnhancedGBuilder = withShouldUpdate(GridBuilder);

    static defaultProps = {
        className: '',
        grids: [],
        isEditable: true,
        set: [],
        setType: 'tab',
        showComponent: true,
    };

    constructor(props) {
        super(props);

        this.props = props;
    }

    render() {
        let {
            className,
            fieldname,
            grids,
            isEditable,
            name,
            set,
            isDuplicable,
            tabsLoadedNo,
            setType,
            setName,
            duplicate,
            max,
            initialId,
            valueId,
            id,
            setPath,
            tabPath,
            isIndented,
            setId,
            showBorder,
            showTabName,
            tabIndex,
            tabId,
            fieldset,
            copy,
            // form creation props
            isEditingFieldset,
            isEditingField,
            isEditingGrid,
            // utils
            show = true,
            showComponent,
            path,

            // component to pass to children
            values,
            validities,
            triggers,
            triggeredStates,
            disabledFields,
            fieldsetsFields,
        } = this.props;

        const classNames = [
            'slot',
            _.isString(className) && className,
            set.length && 'duplicate-set',
            isIndented && 'indented',
        ];
        set = _.isArray(set) ? set : [];
        const isTabView = setType === 'tab';

        const gridsLength = grids.length;
        const totalGridPerRow = 12;
        const forceXsColumns = false;
        const size = gridsLength;
        const xs = !forceXsColumns ? totalGridPerRow : totalGridPerRow / size;
        let sm;
        if (size > 0) {
            const width = Math.floor((totalGridPerRow / size) * 10);
            sm = (width / 10 + '').replace('.', '-');
        } else {
            sm = 12;
        }
        const colClass = ' col-xs-' + xs + ' col-sm-' + sm;
        let gridComponents;
        // instead of looping, setting all grids in an array [fixes PART 2]
        gridComponents = Object.entries(grids).map((grid, index) => {
            const [key, value] = grid;
            const extraProps = {
                ...value,
                setId,
                tabIndex,
                tabId,
                setPath,
                tabPath,
                isEditingField,
                isEditingGrid,
                fieldname: {
                    parent: `${path}.grids`,
                    current: `${key}`,
                },
                isParentEditable: isEditable,

                // component to pass to children
                values,
                validities,
                triggers,
                triggeredStates,
                disabledFields,
                fieldsetsFields,
            };

            if (isObject(value) && value.type === gridKey) {
                const fieldsets = value.fieldsets;
                const classNames = [
                    colClass,
                    _.isArray(fieldsets) && fieldsets.length && 'grid__fieldset',
                ];
                return (
                    <div className={classNames.join(' ')} key={index}>
                        <this.EnhancedGBuilder
                            fields={value.fields}
                            className="column__grid"
                            {...extraProps}
                        />
                    </div>
                );
            } else if (isObject(value) && value.type === fieldsetKey) {
                return (
                    <div className={colClass + ' column__grids--inner'} key={index}>
                        <FieldsetBuilder
                            key={index}
                            grids={value.grids}
                            className="column__grids column__grids--inner"
                            {...extraProps}
                        />
                    </div>
                );
            }

            return false;
        });

        // if setType is spreadsheet type or it's set
        // - to test this with old structure, add condition id === 'fieldset_id'
        if (set.length || setType === TYPE_SPREADSHEET) {
            let extraProps = {
                isEditingFieldset,
                isEditingField,
                isEditingGrid,
                isParentEditable: isEditable,
                setId: valueId || initialId,
                tabId: initialId,
                valueId: valueId || initialId,
                id,
                fieldname: {
                    parent: fieldname.parent,
                    current: fieldname.current,
                },

                // component to pass to children
                value: values[valueId || initialId],
                validity: validities[initialId],
                triggeredState: triggeredStates[initialId],
                triggers,
                disabledFields,
                fieldsetsFields,
            };

            // when props.terminal_configuration = true
            if (this.props[terminalConfigurationKey]) {
                gridComponents = (
                    <TerminalConfiguration
                        {...extraProps}
                        name={setName}
                        id={fieldset.id}
                        set={set}
                        max={max}
                        setPath={setPath}
                        tabPath={tabPath}
                        className="terminal_configurations"
                        fieldsetsFields={fieldsetsFields}
                    />
                );
            }
            // if setType is spreadsheet type, show spreadsheet
            else if (setType === TYPE_SPREADSHEET) {
                delete extraProps.setId;

                extraProps = {
                    ...extraProps,
                    ...this.props,
                    id: fieldset.id,
                    values,
                };
                gridComponents = (
                    <React.Suspense fallback={<div />}>
                        <FieldsetHandsontable {...extraProps} />
                    </React.Suspense>
                );
            } else {
                // check disabledField, fallback to false ~ !disabled
                const isTabEditable = !defaultToFalse(disabledFields[initialId]);
                gridComponents = (
                    <Tab
                        tabs={set}
                        name={name || 'Tab'}
                        tabsLoadedNo={tabsLoadedNo}
                        allowRemove={isTabEditable && isDuplicable}
                        allowAddMore={isTabEditable && isDuplicable}
                        allowCopy={isTabEditable && isDuplicable}
                        duplicate={duplicate}
                        tabView={isTabView}
                        initialId={initialId}
                        max={max}
                        showBorder={showBorder}
                        showTabName={showTabName}
                        setPath={setPath}
                        tabPath={tabPath}
                        copy={copy}
                        {...extraProps}
                    />
                );
            }
        }

        if (!showComponent) return null;

        return (
            <div className={classNames.join(' ')}>
                {show && gridComponents}
                {isEditingFieldset && <FieldsetAction path={path} fieldset={this.props} />}
                {isEditingGrid &&
                    !set.length && [
                        <AddGridButton
                            path={`${path}.grids`}
                            key="leftGridButton"
                            position="left"
                        />,
                        <AddGridButton
                            path={`${path}.grids`}
                            key="rightGridButton"
                            position="right"
                        />,
                    ]}
            </div>
        );
    }
}

// PropTypes
FieldsetBuilder.propTypes = {
    fieldset: PropTypes.object,
};

export default compose(
    lifecycle({
        shouldComponentUpdate(nextProps) {
            return !_.isEqual(this.props, nextProps);
        },
    }),
    // filter out the necessary values
    // for the component
    withProps(
        ({
            fieldset = {},
            values,
            validities,
            triggers,
            triggeredStates,
            fieldsetsFields = {},
            id,
        }) => {
            id = fieldset.id || id;
            const fields = fieldsetsFields[id] || [];
            const fieldsets = getFieldsets(fieldset);
            const ids = [...fields, id, ...fieldsets];
            const states = store.getState();
            values = ObjectClone(filterByIds(values, ids, getValues(states)));
            validities = ObjectClone(filterByIds(validities, ids, getValidities(states)));
            triggers = ObjectClone(filterByIds(triggers, ids));
            triggeredStates = ObjectClone(
                filterByIds(triggeredStates, ids, getTriggeredStates(states)),
            );
            const trigger = triggers[id] && triggers[id].data;

            return {
                ids,
                values,
                validities,
                trigger,
                triggers,
                triggeredStates,
            };
        },
    ),
    withProcessedProps,
    withGetTriggerValidityHandler,
    withExtendingPropsHandler,
    withOnValuesChange,
    withDependencyCheckHandler,
    lifecycle({
        componentDidMount() {
            const { dependencyCheck } = this.props;
            dependencyCheck(true);
        },
    }),
)(FieldsetBuilder);
