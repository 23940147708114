import _ from 'underscore';
import ObjectClone from 'clone';

import FormAPI from '../../../api/form/FormAPI';
import { templateAPI } from '../../../api/template/TemplateAPI';

// ACTIONS
import actionSetActionForm, { SET_ACTION_FORM } from './actionSetActionForm';
import { setFields } from '../TemplateAction';
import { actionGetTemplateDetail } from '../TemplateDetailAction';
import { setTriggers, setTriggeredDependants } from '../TriggerAction';
import { setAjv, setValidators } from '../ValidatorAction';
import {
    setDisabledFields,
    setDisabledSections,
    setDisabledSelections,
    setDisabledCampaignFields,
} from '../DisabledAction';
import { setCustomListOptions } from '../CustomListAction';
import { setFieldsets, setFieldsetsChilds } from '../FieldsetAction';
import { setValues, setCustomValues } from '../ValueAction';
import { setSectionsItems } from '../SectionAction';
import { startLoading, stopLoading, actionInvalidForm } from '../PageAction';

// Selectors
import { getTriggers, getFieldsetsChilds } from '../../selectors';
import { getLists } from '../../selectors/ListsSelector';

// Misc
import {
    getFieldsIndexByFieldset,
    getFormObjRelationships,
    getDependants,
} from '../../../components/Misc/forms';
import { productsKey, standardMDRsListKey } from '../../../components/Form/FormRules';
import { addValidatorKeywords } from '../../../components/Misc/validators';

import { setSchema } from '../SchemaAction';
import { blobFileDownloadHandler } from '../../misc';

const formAPI = new FormAPI();

export const SET_INITIAL_FORM_DATA = 'SET_INITIAL_FORM_DATA';
export function setInitialFormData(formData) {
    return {
        type: SET_INITIAL_FORM_DATA,
        payload: formData,
    };
}

export const SET_FORM_ID = 'SET_FORM_ID';
export function setFormId(id) {
    return {
        type: SET_FORM_ID,
        payload: id,
    };
}

// There is a clash with TemplateAccessAction.SET_FORM_STATUS
export const SET_FORM_STATUS = 'SET_FORM_FORM_STATUS';
export function setFormStatus(status) {
    return {
        type: SET_FORM_STATUS,
        payload: status,
    };
}

// formCampaignSlug is taken from render endpoint
export const SET_FORM_CAMPAIGN_SLUG = 'SET_FORM_CAMPAIGN_SLUG';
export function setFormCampaignSlug(formCampaignSlug) {
    return {
        type: SET_FORM_CAMPAIGN_SLUG,
        payload: formCampaignSlug,
    };
}

export const SET_CAMPAIGN_SLUG = 'SET_CAMPAIGN_SLUG';
function setCampaignSlug(campaignSlug) {
    return {
        type: SET_CAMPAIGN_SLUG,
        payload: campaignSlug,
    };
}

export const SET_CAMPAIGN_ID = 'SET_CAMPAIGN_ID';
function setCampaignId(campaignId) {
    return {
        type: SET_CAMPAIGN_ID,
        payload: campaignId,
    };
}

export const SET_ACTIVE_CAMPAIGNS = 'SET_ACTIVE_CAMPAIGNS';
export function setActiveCampaigns(activeCampaigns) {
    return {
        type: SET_ACTIVE_CAMPAIGNS,
        payload: activeCampaigns,
    };
}

export const FETCH_VIEW_SUCCESS = 'FETCH_VIEW_SUCCESS';
function fetchViewSuccess() {
    return {
        type: FETCH_VIEW_SUCCESS,
        lastModified: new Date().getTime(),
    };
}

export const FETCH_VIEW_ERROR = 'FETCH_VIEW_ERROR';
function _fetchViewError(error) {
    return {
        type: FETCH_VIEW_ERROR,
        error,
    };
}

export const LOAD_FORM = 'LOAD_FORM';
function loadForm(form) {
    return {
        type: LOAD_FORM,
        form: form,
    };
}

// set current form id
export const SET_CURRENT_FORM = 'SET_CURRENT_FORM';
export function setCurrentForm(id) {
    return {
        type: SET_CURRENT_FORM,
        id,
    };
}

export const SET_UPDATED_DATE = 'SET_UPDATED_DATE';
export function setUpdatedDate(updatedDate) {
    return {
        type: SET_UPDATED_DATE,
        updatedDate,
    };
}

export const LOAD_TEMPLATE = 'LOAD_TEMPLATE';
function loadTemplate(template) {
    return {
        type: LOAD_TEMPLATE,
        template: template,
    };
}

export const GET_TEMPLATE_LIST = 'GET_TEMPLATE_LIST';
function getTemplateList(data) {
    return {
        type: GET_TEMPLATE_LIST,
        data: data,
    };
}

export const SET_FORM_TEMPLATE = 'SET_FORM_TEMPLATE';
function setFormTemplate(template) {
    return {
        type: SET_FORM_TEMPLATE,
        template: template,
    };
}

export const SET_FORM_DATA = 'SET_FORM_DATA';
function getFormData({ form, view_access }) {
    return {
        type: SET_FORM_DATA,
        form,
        viewAccess: view_access,
    };
}

export const SET_FORM_VIEW = 'SET_FORM_VIEW';
function setFormView({ showingViewNumber, meta }) {
    return {
        type: SET_FORM_VIEW,
        form: {
            meta,
            showingViewNumber,
        },
    };
}

// FORM elements
export const CHANGE_FIELD_VALUE = 'CHANGE_FIELD_VALUE';
function changeFieldValue({ value, path }) {
    return {
        type: CHANGE_FIELD_VALUE,
        value: value,
        path: path || '',
    };
}

export const CHANGE_FIELD_VALIDITY = 'CHANGE_FIELD_VALIDITY';
function changeFieldValidity({ fieldname, name, validity, path }) {
    return {
        type: CHANGE_FIELD_VALIDITY,
        fieldname: fieldname,
        name: name,
        validity: validity,
        path: path,
    };
}

export const CHANGE_CHECKBOX_STATUS = 'CHANGE_CHECKBOX_STATUS';
function changeCheckboxStatus({ fieldname, name, path, value }) {
    return {
        type: CHANGE_CHECKBOX_STATUS,
        fieldname: fieldname,
        name: name,
        path: path,
        value: value,
    };
}

export const CHANGE_DROPDOWN = 'CHANGE_DROPDOWN';

export const CHANGE_CODE_EDITOR_VALUE = 'CHANGE_CODE_EDITOR_VALUE';
function changeCodeEditorValue({ name, value }) {
    return {
        type: CHANGE_CODE_EDITOR_VALUE,
        value: value,
        name: name,
    };
}
export const CHANGE_FIELDSET = 'CHANGE_FIELDSET';
function changeFieldset({ path, fieldsets }) {
    return {
        type: CHANGE_FIELDSET,
        path,
        fieldsets,
    };
}

// TEMPLATE
export const SET_PREVIEW_OBJECT = 'SET_PREVIEW_OBJECT';
function setPreviewObject({ path, object }) {
    return {
        type: SET_PREVIEW_OBJECT,
        path: path,
        object: object,
    };
}

export const SET_OPTIONS = 'SET_OPTIONS';
function setOptions({ path, options }) {
    return {
        type: SET_OPTIONS,
        path: path,
        options: options,
    };
}

export const SET_DOWNLOADING_FILES = 'SET_DOWNLOADING_FILES';
function setDownloadingFiles(downloadingFiles) {
    return {
        type: SET_DOWNLOADING_FILES,
        downloadingFiles: downloadingFiles,
    };
}

export const SET_FORM_RULE = 'SET_FORM_RULE';
function setFormRule(rule) {
    return {
        type: SET_FORM_RULE,
        rule,
    };
}

function clearDefaults({ path }) {
    return {
        type: CHANGE_DROPDOWN,
        value: '',
        path: path,
    };
}

function clearDefaultText({ path }) {
    return {
        type: CHANGE_FIELD_VALUE,
        value: '',
        path: path,
    };
}

function viewClearDefaults(path, dispatch) {
    dispatch(clearDefaults({ path: path + '.fieldsets' }));
    dispatch(clearDefaults({ path: path + '.fields' }));
    dispatch(clearDefaults({ path: path + '.validators' }));
    dispatch(clearDefaults({ path: path + '.triggerBase' }));
}

function fieldsetsClearDefaults(path, dispatch) {
    dispatch(clearDefaults({ path: path + '.fields' }));
    dispatch(clearDefaults({ path: path + '.validators' }));
    dispatch(clearDefaults({ path: path + '.triggerBase' }));
}

function fieldsClearDefaults(path, dispatch) {
    dispatch(clearDefaults({ path: path + '.validators' }));
    dispatch(clearDefaults({ path: path + '.triggerBase' }));
}

/**
 * ACTIONS function
 *
 */

export const actionSetCampaignId = campaignId => {
    return async dispatch => {
        try {
            dispatch(setCampaignId(campaignId));
        } catch (error) {
            console.log('error', error);
        }
    };
};

export const actionSetCampaignSlug = campaignSlug => {
    return async dispatch => {
        try {
            dispatch(setCampaignSlug(campaignSlug));
        } catch (error) {
            console.log('error', error);
        }
    };
};

export const actionGetActiveCampaigns = () => {
    return async dispatch => {
        try {
            const activeCampaigns = await formAPI.getActiveCampaigns();
            dispatch(setActiveCampaigns(activeCampaigns));
        } catch (error) {
            console.log('error', error);
        }
    };
};

export const actionClearDefaultVal = (parentPath, currentPath) => {
    return async dispatch => {
        try {
            if (currentPath === 'view') {
                viewClearDefaults(parentPath, dispatch);
            } else if (currentPath === 'fieldsets') {
                fieldsetsClearDefaults(parentPath, dispatch);
            } else if (currentPath === 'fields') {
                fieldsClearDefaults(parentPath, dispatch);
            }
        } catch (error) {
            console.log('error', error);
        }
    };
};

export const actionClearDefaultText = (parentPath, currentPath) => {
    return async dispatch => {
        try {
            if (currentPath === 'fields') {
                // console.log("parent_path", parent_path + '.min_length');
                console.log(dispatch(clearDefaultText({ path: parentPath + '.min_length' })));
                // dispatch(clearDefaultText({ parent_path: parent_path + '.max_length' }))
            }
        } catch (error) {
            console.log('error', error);
        }
    };
};

export function actionLoadForm(form) {
    return dispatch => {
        return dispatch(loadForm(form));
    };
}

export function actionSetFormView(template) {
    return dispatch => {
        return dispatch(setFormView(template));
    };
}

export function actionLoadTemplate(template) {
    return dispatch => {
        return dispatch(loadTemplate(template));
    };
}

export function actionGetTemplateList() {
    return dispatch => {
        dispatch(startLoading());

        templateAPI.getTemplateList(response => {
            dispatch(stopLoading());
            return dispatch(getTemplateList(response));
        });
    };
}

export function actionGetTemplate({ templateId, formId, campaignId }) {
    return async (dispatch, getState) => {
        // await to ensure mdr and ppda is set before next statement
        await dispatch(actionGetTemplateDetail(templateId));
        try {
            const {
                custom_fields: customFields = [],
                disabled_fields,
                disabled_sections,
                fields,
                form,
                meta,
                triggers,
                views,
                validation_schema,
                validators,
            } = await templateAPI.getTemplateRender({ templateId, formId, campaignId });
            let { data, form_status, application_id, campaign, updated_dt } = form || {};
            const initialFormData = ObjectClone(data);
            const { slug } = campaign || {};
            const headers = meta.headers || [];
            const siblings = meta.siblings || [];
            const view = views[0];

            let template;
            if (headers.length) {
                // To get first view
                // Each view is serialized and returned from backend
                //   so view comes with it's details
                template = {
                    views,
                    headers,
                    data,
                    templateId,
                    formStatus: form_status,
                    updatedDate: updated_dt,
                    applicationId: application_id,
                    formId,
                    campaignId,
                    siblings,
                };
                const disabledFields = {},
                    disabledSections = {};
                const fieldsets = getFieldsIndexByFieldset(view);
                const dependants = getDependants(triggers, fields);
                const targetTriggers = _.indexBy(triggers, 'target');
                // get products list from redux which should be
                //   set after getting template detail action
                //   format: [{ title, value }, {...}, ...]
                const lists = getLists(getState());
                let products = lists[productsKey];
                let mdrs = lists[standardMDRsListKey];
                //   format: [ product1, product2, productN ]
                products = products.map(p => p.value);
                mdrs = mdrs.map(mdr => mdr.value);

                disabled_fields.forEach(key => {
                    disabledFields[key] = true;
                });
                disabled_sections.forEach(key => {
                    disabledSections[key] = true;
                });
                const { sectionChildren, fieldsetsFields } = getFormObjRelationships(
                    view,
                    triggers,
                    products,
                    mdrs,
                );

                // flatten out default value
                const customDefaultValue = customFields.reduce((accumulator, obj) => {
                    accumulator[obj.field] = obj.data.default_value;
                    return accumulator;
                }, {});

                // flatten out disable field
                const customDisabledField = customFields.reduce((accumulator, obj) => {
                    accumulator[obj.field] = obj.data.disabled;
                    return accumulator;
                }, {});

                // flatten out disable field
                const customDisabledOptions = customFields.reduce((accumulator, obj) => {
                    accumulator[obj.field] = obj.data.disabled_options;
                    return accumulator;
                }, {});

                // flatten out custom list for dropdown
                const customListOptions = customFields.reduce((accumulator, obj) => {
                    accumulator[obj.field] = obj.data.custom_list_options;
                    return accumulator;
                }, {});

                if (!_.isEmpty(customDefaultValue)) {
                    const mergedDataWithCustomVal = { ...data, ...customDefaultValue };
                    data = mergedDataWithCustomVal;
                }

                /** add custom keywords to ajv */
                const ajv = addValidatorKeywords(validators);

                dispatch(setDisabledCampaignFields(customDisabledField));
                dispatch(setDisabledSelections(customDisabledOptions));
                dispatch(setCustomListOptions(customListOptions));
                dispatch(setCustomValues(customDefaultValue));
                dispatch(setFieldsets(fieldsets));

                /** dispatch compiled validation schema */
                const validate = ajv.compile(validation_schema);
                dispatch(setAjv(ajv));
                dispatch(setSchema(validation_schema));
                dispatch(setValidators(validate));
                dispatch(setTriggers(targetTriggers));
                dispatch(setDisabledFields(disabledFields));
                dispatch(setDisabledSections(disabledSections));
                dispatch(setTriggeredDependants(dependants));
                dispatch(setInitialFormData(initialFormData));
                dispatch(setValues(data));
                dispatch(setSectionsItems(sectionChildren));
                dispatch(setFieldsetsChilds(fieldsetsFields));
                dispatch(setFormCampaignSlug(slug));

                dispatch(setFields(fields));
                dispatch(setFormTemplate(template));
            }
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(stopLoading());
        }
    };
}

export function actionGetFormData(templateId, formId) {
    return async dispatch => {
        dispatch(startLoading());

        try {
            const formData = await formAPI.getFormData(templateId, formId);
            return dispatch(getFormData(formData));
        } catch (e) {
            console.error(e);
            actionInvalidForm();
        } finally {
            dispatch(stopLoading());
        }
    };
}

// For NETS form data check
export function actionCheckFormData(templateId, formId) {
    return async () => {
        try {
            const data = await formAPI.getFormData(templateId, formId);
            return data;
        } catch (e) {
            console.error(e);
        }
    };
}

export function actionChangeView({ page, campaignId }) {
    return async (dispatch, getState) => {
        const { views } = getState().form;
        const triggers = getTriggers(getState());
        const view = views[page - 1];

        const template = {
            views,
            page,
            showingViewNumber: page,
            campaignId,
        };
        const fieldsets = getFieldsIndexByFieldset(view);
        // get products list from redux which should be
        //   set after getting template detail action
        //   format: [{ title, value }, {...}, ...]
        const lists = getLists(getState());
        let products = lists[productsKey];
        let mdrs = lists[standardMDRsListKey];
        //   format: [ product1, product2, productN ]
        products = products.map(p => p.value);
        mdrs = mdrs.map(mdr => mdr.value);
        const { sectionChildren, fieldsetsFields } = getFormObjRelationships(
            view,
            triggers,
            products,
            mdrs,
        );
        // to join fieldsetsFields if that fieldset already exist in redux state
        const reduxFieldsetsFields = getFieldsetsChilds(getState());
        Object.entries(fieldsetsFields).forEach(([id, fields]) => {
            if (reduxFieldsetsFields[id]) {
                fieldsetsFields[id] = _.uniq([...fields, ...reduxFieldsetsFields[id]]);
            }
        });

        dispatch(fetchViewSuccess());
        dispatch(setFieldsets(fieldsets));
        dispatch(setSectionsItems(sectionChildren));
        dispatch(setFieldsetsChilds(fieldsetsFields));
        dispatch(setFormTemplate(template));
    };
}

// Action that call render endpoint to get custom_fields
//    whenever campaign is set
export function actionSetCampaign({ templateId, formId, campaignId }) {
    return async dispatch => {
        dispatch(startLoading());
        try {
            const { custom_fields: customFields = [] } = await templateAPI.getTemplateRender({
                templateId,
                formId,
                campaignId,
            });
            const template = {
                campaignId,
            };

            // flatten out default value
            const customDefaultValue = customFields.reduce(function (accumulator, obj) {
                accumulator[obj.field] = obj.data.default_value;
                return accumulator;
            }, {});

            // flatten out disable field
            const customDisabledField = customFields.reduce(function (accumulator, obj) {
                accumulator[obj.field] = obj.data.disabled;
                return accumulator;
            }, {});

            // flatten out disable field
            const customDisabledOptions = customFields.reduce(function (accumulator, obj) {
                accumulator[obj.field] = obj.data.disabled_options;
                return accumulator;
            }, {});

            // flatten out custom list for dropdown
            const customListOptions = customFields.reduce(function (accumulator, obj) {
                accumulator[obj.field] = obj.data.custom_list_options;
                return accumulator;
            }, {});

            dispatch(setDisabledCampaignFields(customDisabledField));
            dispatch(setDisabledSelections(customDisabledOptions));
            dispatch(setCustomListOptions(customListOptions));
            dispatch(setCustomValues(customDefaultValue));

            dispatch(setFormTemplate(template));
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(stopLoading());
        }
    };
}

// FORM elements actions
export function actionChangeFieldValue(data) {
    return dispatch => {
        return dispatch(changeFieldValue(data));
    };
}

export function actionChangeFieldValidity(data) {
    return dispatch => {
        return dispatch(changeFieldValidity(data));
    };
}

export function actionChangeCodeEditorValue(data) {
    return dispatch => {
        return dispatch(changeCodeEditorValue(data));
    };
}

export function actionChangeCheckboxStatus(data) {
    return dispatch => {
        return dispatch(changeCheckboxStatus(data));
    };
}

export function actionChangeFieldset(data) {
    return dispatch => {
        return dispatch(changeFieldset(data));
    };
}

export function actionExportForm(data) {
    return async dispatch => {
        dispatch(startLoading());
        try {
            const response = await formAPI.exportForm(data);
            blobFileDownloadHandler(response.data, response.headers);
        } catch (error) {
            throw error;
        } finally {
            dispatch(stopLoading());
        }
    };
}

export function actionSetDownloadingFiles(downloadingFiles) {
    return dispatch => {
        dispatch(setDownloadingFiles(downloadingFiles));
    };
}

export function actionSetFormRule(rule) {
    return dispatch => {
        dispatch(setFormRule(rule));
    };
}

// TEMPLATE creation actions

export function actionSetPreviewObject(data) {
    return dispatch => {
        return dispatch(setPreviewObject(data));
    };
}

export function actionSetOptions(data) {
    return dispatch => {
        return dispatch(setOptions(data));
    };
}

// ERROR
export const SET_FORM_ERROR = 'SET_FORM_ERROR';
export function actionSetFormError(error) {
    return {
        type: SET_FORM_ERROR,
        error,
    };
}

export { actionSetActionForm, SET_ACTION_FORM };
