import React from 'react';
import _ from 'underscore';
import { compose } from 'recompose';

import Label from './Label';
import InputWithLabel from './InputWithLabel';
import { IECheckedDropdown } from './Dropdown';

import { withCaptureFieldHandlers } from '../Misc/forms';
import store from '../../store';

class InputWithAddOn extends React.Component {
    constructor(props) {
        super(props);

        this.props = props;
    }

    render() {
        const {
            value: { values },
        } = store.getState();

        const { label, custom_properties, tabPath, tabIndex, tabId, setId } = this.props;

        const isDuplicableFieldset = tabIndex > -1;

        const dropdownFieldId = custom_properties.addOnDropdownComponent.field_id;
        const dropdownFieldDefaultValue = custom_properties.addOnDropdownComponent.defaultValue;
        const dropdownFieldOptions = custom_properties.addOnDropdownComponent.options;

        let dropdownValue = dropdownFieldDefaultValue;

        if (values) {
            if (
                isDuplicableFieldset &&
                values[setId] &&
                values[setId][tabIndex] &&
                values[setId][tabIndex][dropdownFieldId]
            ) {
                dropdownValue = values[setId][tabIndex][dropdownFieldId];
            } else if (values[dropdownFieldId]) {
                dropdownValue = values[dropdownFieldId];
            }
        }

        const id = isDuplicableFieldset ? `${ tabPath }.${ dropdownFieldId }` : dropdownFieldId;

        const dropdownProps = {
            ...this.props,
            type: 'select',
            id,
            initialId: dropdownFieldId,
            setId: isDuplicableFieldset ? setId : dropdownFieldId,
            tabId: isDuplicableFieldset ? tabId : dropdownFieldId,
            valuePath: id,
            validityPath: id,
            defaultValue: dropdownFieldDefaultValue,
            value: dropdownValue,
            options: dropdownFieldOptions,
            isValid: true,
        };

        delete dropdownProps.localValue;
        delete dropdownProps.setLocalValue;
        delete dropdownProps.setSchema;
        delete dropdownProps.label;
        delete dropdownProps.placeholder;
        delete dropdownProps.errorMessage;
        delete dropdownProps.custom_properties;

        const inputProps = {
            ...this.props,
        };

        delete inputProps.label;
        delete inputProps.options;

        return (
            <div>
                <Label {...label} />
                <div className="field-input-add-on-container">
                    <div className="dropdown-add-on">
                        <IECheckedDropdown {...dropdownProps} />
                    </div>
                    <div className="field-input-add-on">
                        <InputWithLabel {...inputProps} />
                    </div>
                </div>
            </div>
        );
    }
}

export default compose(withCaptureFieldHandlers)(InputWithAddOn);
