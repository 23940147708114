import BaseAPI from '../BaseAPI';
import _ from 'underscore';
import { constructViewSections } from '../../components/Misc/sectionRestructure';
export class TemplateAPI extends BaseAPI {
    basePath = 'cap/templates/';

    // Grabbing all list of template from api
    async getTemplates() {
        let templates;

        try {
            ({ templates } = await this.get({
                path: this.basePath,
            }));
        } catch (e) {
            throw Error(e.responseJSON);
        }

        return templates;
    }
    // create
    async createTemplate(data) {
        const { template } = await this.post({
            path: this.basePath,
            data: data,
        });
        return template;
    }

    // update
    async editTemplate(templateId, data) {
        let template;

        try {
            ({ template } = await this.post({
                path: `${ this.basePath }${ templateId }/`,
                data: data,
            }));
            console.log('template', template);
        } catch (e) {
            throw Error(e.responseJSON);
        }

        return template;
    }

    // delete
    async removeTemplate(templateId) {
        let template;

        try {
            template = await this.delete({
                path: `${ this.basePath }${ templateId }/`,
            });
        } catch (e) {
            throw Error(e.responseJSON);
        }

        return template;
    }

    async getTemplateAccesses(templateId) {
        let viewAccesses;

        try {
            const response = await this.get({
                path: `${ this.basePath }${ templateId }/view-accesses/`,
            });
            viewAccesses = response.viewAccesses;
        } catch (e) {
            throw Error(e.responseJSON);
        }

        return viewAccesses;
    }

    async getTemplateOptions() {
        let templates;

        try {
            ({ templates } = await this.get({
                path: this.basePath,
            }));
        } catch (e) {
            throw Error(e.responseJSON);
        }

        return templates;
    }

    // Deprecated old style
    // ####################

    async getTemplateData(id) {
        let template;

        try {
            ({ template } = await this.get({
                path: `${ this.basePath }${ id }/`,
            }));
        } catch (e) {
            throw Error(e.responseJSON);
        }

        return template;
    }

    async getTemplateRender({ templateId, page = 1, formId, campaignId }) {
        let views,
            disabledFields,
            disabledSections,
            triggers,
            validationSchema,
            validators,
            form,
            meta,
            customFields,
            sections,
            fieldsets,
            grids,
            fields;
        let path = `${ this.basePath }${ templateId }/render?page=${ page }`;

        if (formId) {
            path = `${ path }&form=${ formId }`;
        }

        if (campaignId) {
            path = `${ path }&campaign=${ campaignId }`;
        } else if (_.isNull(campaignId)) {
            path = `${ path }&campaign=`;
        }

        try {
            ({
                views,
                disabled_fields: disabledFields,
                disabled_sections: disabledSections,
                triggers,
                form,
                meta,
                custom_fields: customFields,
                fieldsets,
                grids,
                fields,
                validators,
                validation_schema: validationSchema,
            } = await this.get({
                path,
            }));
        } catch (e) {
            throw new Error(e.responseJSON);
        }

        const returnViews = [];

        for (const view of views) {
            const viewSections = view.sections;

            const sectionFormat = constructViewSections({
                viewSections,
                fieldsetsData: fieldsets,
                gridsData: grids,
                fieldsData: fields,
            });

            view.sections = sectionFormat;
            returnViews.push(view);
        }

        return {
            views: returnViews,
            disabled_fields: disabledFields,
            disabled_sections: disabledSections,
            triggers,
            validation_schema: validationSchema,
            validators,
            form,
            meta,
            custom_fields: customFields,
            sections,
            fieldsets,
            grids,
            fields,
            validators,
        };
    }

    // deprecated version, replaced by getTemplateRender
    async getTemplateByViewAccess({ templateId, accessId }) {
        let viewAccess;
        try {
            const response = await this.get({
                path: `${ this.basePath }${ templateId }/view-accesses/${ accessId }/`,
            });
            viewAccess = response.view_access;
        } catch (e) {
            throw new Error(e.responseJSON);
        }
        return viewAccess;
    }

    async getTemplateViewByViewAccess({ templateId, viewId, accessId }) {
        let view;

        try {
            ({ view } = await this.get({
                path: `${ this.basePath }${ templateId }/view-accesses/${ accessId }/views/${ viewId }/`,
            }));
        } catch (e) {
            throw new Error(e.responseJSON);
        }
        return view;
    }
}
export const templateAPI = new TemplateAPI();
export default templateAPI;
