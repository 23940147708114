import ObjectAssign from 'object-assign';
import ObjectPath from 'object-path';

import {
    SET_VALIDITY,
    SET_VALIDITIES,
    SET_VALIDITY_TARGETS,
    SET_VALIDITIES_ERROR,
    SET_VIEW_VALIDITIES,
} from '../actions/ValidityAction';

export interface Validity {
    isValid: boolean;
    errorMessage: string;
}

interface ValidityState {
    validities: Record<string, Validity>;
    viewValidities?: boolean[];
    validityTargets: string[];
    fetching: boolean;
    error: any;
}

const INITIAL_STATE: ValidityState = {
    validities: {},
    validityTargets: [],
    fetching: false,
    error: null,
};

export default function validityReducer(state = INITIAL_STATE, action): ValidityState {
    switch (action.type) {
        case SET_VALIDITY: {
            const { path, validity } = action.payload;
            const validities = ObjectAssign({}, state.validities);
            ObjectPath.set(validities, path, validity);
            return {
                ...state,
                validities,
            };
        }
        case SET_VALIDITIES: {
            const { validities } = action;
            if (validities === null) {
                return {
                    ...state,
                    validities: {},
                };
            }
            return {
                ...state,
                validities,
            };
        }
        case SET_VIEW_VALIDITIES: {
            const { viewValidities } = action;
            return {
                ...state,
                viewValidities,
            };
        }
        case SET_VALIDITY_TARGETS: {
            const { validityTargets } = action;
            return {
                ...state,
                validityTargets: [...state.validityTargets, validityTargets],
            };
        }

        case SET_VALIDITIES_ERROR:
            const { error } = action;
            return {
                ...state,
                error,
            };
        default:
            return state;
    }
}
