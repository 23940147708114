import createForm, { TYPE } from './createForm';

import { ImageForbidden, ImageWarning, ImageSuccessful, ImageInfo } from '../../../images/actions';
import actionSetActionForm from '../../../redux/actions/FormAction/actionSetActionForm';
import store from '../../../store';

export function showConfirmMessage({
    message,
    image = ImageInfo,
    onConfirm,
    onCancel = () => {
        store.dispatch(hideForm());
    },
}) {
    if (!message) {
        message = 'Are you sure?';
    }
    return showForm({
        size: 'small',
        image,
        message,
        buttons: [
            {
                text: 'Confirm',
                onClick: onConfirm,
                buttonType: 'primary',
            },
            {
                text: 'Cancel',
                onClick: onCancel,
            },
        ],
    });
}

export function showSuccessfulMessage({
    message,
    image = ImageSuccessful,
    onConfirm = () => {
        store.dispatch(hideForm());
    },
} = {}) {
    if (!message) {
        message = 'Successfully done';
    }
    return showForm({
        size: 'small',
        image,
        message,
        buttons: [{ text: 'Ok', onClick: onConfirm }],
    });
}

export function showFailedMessage({
    message,
    image = ImageWarning,
    onConfirm = () => {
        store.dispatch(hideForm());
    },
} = {}) {
    if (!message) {
        message = 'Failed';
    }
    return showForm({
        size: 'small',
        image,
        message,
        buttons: [{ text: 'Ok', onClick: onConfirm }],
    });
}

export function showForbiddenAccess({ message, image = ImageForbidden, onConfirm } = {}) {
    if (!message) {
        message = 'Forbidden Access';
    }
    return showForm({
        size: 'small',
        image,
        message,
        buttons: [{ text: 'Back to Dashboard', onClick: onConfirm }],
    });
}

export function showAlertMessage({ message, image = ImageWarning } = {}) {
    if (!message) {
        message = 'Feature Off';
    }
    return showForm({
        size: 'small',
        image,
        message,
        buttons: [
            {
                text: 'Ok',
                onClick: () => {
                    store.dispatch(hideForm());
                },
            },
        ],
    });
}

export function showInvalidFieldMessage({ message, image = ImageWarning, onConfirm } = {}) {
    if (!message) {
        message = 'Please check the fields highlighted in red';
    }
    return showForm({
        size: 'small',
        image,
        message,
        buttons: [{ text: 'Ok', onClick: onConfirm }],
    });
}

export function showInvalidForm({ message, image = ImageWarning, onConfirm } = {}) {
    if (!message) {
        message = 'Invalid Form ID';
    }
    return showForm({
        size: 'small',
        image,
        message,
        buttons: [{ text: 'Back to Dashboard', onClick: onConfirm }],
    });
}

export function showAssignToError({ message, image = ImageWarning, onConfirm } = {}) {
    if (!message) {
        message = 'You are not allowed to delegate your access for selected template(s)';
    }
    return showForm({
        size: 'small',
        image,
        message,
        buttons: [{ text: 'Ok', onClick: onConfirm }],
    });
}

export function showAssignSelfError({ message, image = ImageWarning, onConfirm, onCancel } = {}) {
    if (!message) {
        message = 'Assign Self Error';
    }
    return showForm({
        size: 'small',
        image,
        message,
        buttons: [
            {
                text: 'Override',
                onClick: onConfirm,
            },
            {
                text: 'Cancel',
                onClick: onCancel,
            },
        ],
    });
}

export function showFormOutdated({ message, image = ImageWarning, onConfirm } = {}) {
    if (!message) {
        message = 'Form Outdated';
    }
    return showForm({
        size: 'small',
        image,
        message,
        buttons: [
            {
                text: 'Ok',
                onClick: onConfirm,
            },
        ],
    });
}

export function showInfoMessage({ message, image = ImageInfo, onConfirm } = {}) {
    if (!message) {
        message = 'Info';
    }

    return showForm({
        size: 'small',
        image,
        message,
        buttons: [
            {
                text: 'Ok',
                onClick: onConfirm,
            },
        ],
    });
}

export function showFormDataPatchDifferent({ message, image = ImageInfo, onConfirm } = {}) {
    if (!message) {
        message = 'Patch to old form data not equal to new form data';
    }

    return showForm({
        size: 'small',
        image,
        message,
        buttons: [
            {
                text: 'Ok',
                onClick: onConfirm,
            },
        ],
    });
}

function showForm(options) {
    return actionSetActionForm(createForm(options));
}
function hideForm() {
    return actionSetActionForm({});
}

export { createForm, TYPE };
