import {
    SET_DELEGATED_ACCESS,
    SET_DELEGATED_ACCESSES,
    SET_DELEGATED_ACCESSES_FETCHING,
    SET_DELEGATED_ACCESSES_ERROR,
    SET_DELEGATED_ACCESSES_START_DATE,
    SET_ASSIGN_TO_USERS,
} from '../actions/DelegatedAccessAction';

const INITIAL_STATE = {
    delegatedAccesses: {},
    templates: [],
    users: [],
    roles: [],
    fetching: false,
    error: null,
};

export default function DelegatedAccessReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
    case SET_DELEGATED_ACCESS: {
        const { id, access } = action.payload;
        return {
            ...state,
            delegatedAccesses: {
                ...state.delegatedAccesses,
                [id]: access,
            },
        };
    }
    case SET_DELEGATED_ACCESSES: {
        const { accesses } = action;
        return {
            ...state,
            delegatedAccesses: {
                ...state.delegatedAccesses,
                ...accesses,
            },
        };
    }
    case SET_DELEGATED_ACCESSES_FETCHING: {
        const { fetching } = action;
        return {
            ...state,
            fetching,
        };
    }
    case SET_DELEGATED_ACCESSES_ERROR: {
        const { error } = action;
        return {
            ...state,
            error,
        };
    }
    case SET_DELEGATED_ACCESSES_START_DATE: {
        const { date } = action;
        return {
            ...state,
            startDate: date,
        };
    }
    case SET_ASSIGN_TO_USERS: {
        const { users } = action;
        return {
            ...state,
            users,
        };
    }
    default:
        return state;
    }
}
