import BaseAPI from '../BaseAPI';
import { isoFormatMapper, isoFormatObj } from '../../components/Misc';

export class FeatureAPI extends BaseAPI {
    basePath = 'cap/features/';

    async getApiLink({ page, contentType, dataType, successCallback }) {
        const path = 'cap' + page;

        try {
            return await this.get({ path, contentType, dataType, successCallback });
        } catch (e) {
            if (e.responseJSON) throw new Error(e.responseJSON);
            else throw new Error(e);
        }
    }

    async getFeatureList() {
        const path = this.basePath;

        // Options contains choices for fields
        let { features, options } = await this.get({ path });
        features = isoFormatMapper(features);

        // Map choices to format expected by form builder
        for (const key in options.choices) {
            options.choices[key] = options.choices[key].map(({ label, value }) => {
                return {
                    title: label,
                    value,
                };
            });
        }
        return { features, options };
    }

    async createFeature(data) {
        let feature;
        const path = this.basePath;
        try {
            ({ feature } = await this.post({
                path,
                data,
            }));
        } catch (e) {
            throw e.responseJSON;
        }
        return isoFormatObj(feature);
    }

    async updateFeature(id, data) {
        let feature;
        const path = `${ this.basePath }${ id }/`;
        try {
            ({ feature } = await this.post({ path, data }));
        } catch (e) {
            throw e.responseJSON;
        }
        return isoFormatObj(feature);
    }

    async deleteFeature(id) {
        const path = `${ this.basePath }${ id }/`;
        try {
            return await this.delete({ path });
        } catch (e) {
            throw e.responseJSON;
        }
    }
}

const featureAPI = new FeatureAPI();
export default featureAPI;
