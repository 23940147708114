export const PagePath = {
    login: '/login',
    dashboard: '/',
    change_password: '/change-password',
    reset_password: '/reset-password',
    channel: '/channel',
    form: '/form',
    submitted_form: '/submitted',
    configuration: '/config',
    user: '/user',
    template: '/template',
    view: '/view',
    access: '/access',
    fieldset: '/fieldset',
    history: '/history',
    history_details: '/history/details',
    delegate_access: '/delegate-access',
    forgot_password: '/forgot-password',
    reporting_management: '/report',
    entry_list: '/watchlist',
    theme_management: '/themes',
    bulk_upload: '/bulk-upload',
};

export function goToPage({ page }) {
    window.location.href = page;

    return page;
}

export function goToPageInNewTab({ page, host = true }) {
    const path = host ? window.location.host + '/' + page : page;
    window.open(page, '_blank');
    console.log(path);
    return path;
}

export function goToLogin() {
    window.location.href = PagePath.login;

    return PagePath.login;
}

/*===================
 *  User
 *===================*/
export function goToDashboardUser() {
    window.location.href = PagePath.dashboard;

    return PagePath.dashboard;
}

export function goToChangePassword() {
    window.location.href = PagePath.change_password;

    return PagePath.change_password;
}

export function goToResetPassword() {
    window.location.href = PagePath.reset_password;

    return PagePath.reset_password;
}

export function goToMerchantChannel() {
    window.location.href = PagePath.channel;

    return PagePath.channel;
}

export function goToApplicationForm({ templateId } = {}, redirect) {
    const path = `${ PagePath.form }/${ templateId }`;
    if (!redirect) {
        return path;
    }

    window.location.href = path;

    return path;
}

export function goToSubmittedForm({ templateId, formId }) {
    window.location.href = `${ PagePath.submitted_form }/${ templateId }/${ formId }`;

    return PagePath.submitted_form;
}

export function updateUrlToSubmittedForm({ templateId, formId }) {
    const path = `${ PagePath.submitted_form }/${ templateId }/${ formId }`;
    window.history.pushState(null, null, path);
    return PagePath.submitted_form;
}

export function goToConfigurationManagement() {
    window.location.href = PagePath.configuration;

    return PagePath.configuration;
}

export function goToUserManagement() {
    window.location.href = PagePath.user;

    return PagePath.user;
}

export function goToDelgateAccess() {
    window.location.href = PagePath.delegate_access;

    return PagePath.delegateAccess;
}

export function goToFormHistory({ templateId, formId }) {
    const path = `${ PagePath.history }/${ templateId }/${ formId }`;
    window.location.href = path;

    return path;
}

export function goToForgotPassword() {
    window.location.href = PagePath.forgot_password;

    return PagePath.forgot_password;
}

/*===================
 *  Template
 *===================*/
export function goToFieldset(params) {
    const path = `${ PagePath }/${ params.templateId }${ PagePath.fieldset }${ params.fieldsetId }`;
    window.location.href = path;

    return path;
}
