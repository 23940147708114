/**
 * A method that process and generate and return classes string
 *
 * @param {string} classname - classname to be append on
 * @param {string[]} cls - array of string
 * @returns {string} classnames - space separated classname
 *
 * @example
 * // returns 'cls-1 cls-3 cls-4'
 * classnames('cls-4', [cls-1', 'cls-3']);
 * @example
 * // returns 'cls-1 cls-3 cls-4'
 * classnames('cls-4', [' cls-1', 'cls-3 ']);
 * @example
 * // returns 'cls-1'
 * classnames(null, ['cls-1']);
 */
function classnames(classname, cls = []) {
    return [classname, ...cls]
        .filter(c => c)
        .map(c => c.trim())
        .join(' ');
}

export default classnames;
