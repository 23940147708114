import { getClient } from './redux/selectors/ClientSelector';
import store from './store';

const TIMEZONE = 'Asia/Kuala_Lumpur';

function getTimezone(client = getClient(store.getState())) {
    return client.timezone || TIMEZONE;
}

export { getTimezone };
