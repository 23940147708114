import ObjectEntries from 'object.entries';
import ObjectAssign from 'object-assign';
import _ from 'underscore';

export function translateToSemanticDropdown({ options, translateTo, retainOld = false }) {
    const defaultTanslateTo = {
        name: 'key',
        title: 'text',
        value: 'value',
    };
    retainOld = retainOld;
    options = options || [];
    translateTo = translateTo || defaultTanslateTo;

    const newOptions = new Array();
    let row = {};
    ObjectEntries(options).map(option => {
        const [_key, value] = option;
        row = retainOld ? ObjectAssign({}, value) : {};

        ObjectEntries(translateTo).map(translate => {
            const [keyFrom, keyTo] = translate;
            const valueFrom = value[keyFrom];

            if (!valueFrom) return; // row without value

            if (_.isArray(keyTo))
                keyTo.map(i => {
                    row[i] = valueFrom;
                });
            else row[keyTo] = valueFrom;
        });

        if (row.value) {
            // if there's value in the option
            newOptions.push(row);
        }
    });

    return newOptions;
}
