import React from 'react';

const ButtonClosed = ({ onClick }) => {
    return (
        <div className="cross__container" role="button" onClick={onClick}>
            <span className="cross"></span>
        </div>
    );
};

export default ButtonClosed;
