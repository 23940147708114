import _ from 'underscore';
import { compose, withPropsOnChange } from 'recompose';
import * as FR from '../../Form/FormRules';

// NOTE: This applicable for fields only
/**
 * A decorator that process field.value and localValue
 * then decide to either use value or localValue. The reason
 * for this is the redux state updated after a delayed input change,
 * redux state will only update after localValue updated.
 */
const withValueUpdate = compose(
    withPropsOnChange(
        ['localValue', 'value'],
        ({ type, localValue, defaultValue, initialValue: value }) => {
            const reduxValue = value;
            if (_.isEqual(value, localValue)) return;

            switch (type) {
            case FR.duplicableFieldset:
            case FR.hoTableKey:
            case FR.terminalConfigurationKey:
                value = _.isArray(value) ? value : [{}];
                break;
            default:
                value = _.isUndefined(localValue) ? value : localValue;

                if (_.isArray(value)) {
                    // empty [] array should be treated as valid value that shouldn't fallback to default value
                    // while [null], [''], ['value'] value consider invalid value and shall be cleaned before fallback to default value
                    value = !_.isEmpty(value) && !FR.cleanValue(value) ? defaultValue : value;
                } else {
                    value = value == null ? defaultValue : value;
                }
            }

            return {
                reduxValue,
                value,
            };
        },
    ),
);

export default withValueUpdate;
