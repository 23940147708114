import {
    SET_FORM_LIST_FETCHING,
    SET_FORM_LIST_SUCCESS,
    SET_FORM_LIST_ERROR,
    SET_FORM_LIST as SET_FORM_LIST,
    SET_TEMPLATE_FORM,
} from '../actions/TemplateFormAction';

const INITIAL_STATE = {};

export default function FormListReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_FORM_LIST_FETCHING: {
            return {
                ...state,
                fetching: action.fetching,
            };
        }
        case SET_FORM_LIST_SUCCESS: {
            return {
                ...state,
                lastModified: action.lastModified,
            };
        }
        case SET_FORM_LIST_ERROR: {
            return {
                ...state,
                error: action.error,
            };
        }

        case SET_FORM_LIST: {
            return {
                ...state,
                [action.payload.id]: action.payload.data,
            };
        }

        case SET_TEMPLATE_FORM: {
            const { payload } = action;
            const { templateId, id, form } = payload;

            let forms = [];
            if (state[templateId] && state[templateId].forms) {
                forms = state[templateId].forms;
            }
            for (let i = 0; i < forms.length; i++) {
                if (forms[i].id === id) {
                    forms.splice(i, 1, form);
                    break;
                }
            }

            return {
                ...state,
                [templateId]: {
                    ...state[templateId],
                    forms,
                },
            };
        }
        default: {
            return state;
        }
    }
}
