import { ERROR_MESSAGES } from '../../components/Misc/api';
import BaseAPI from '../BaseAPI';
class UserAPI extends BaseAPI {
    basePath = 'cap/users/';
    me = 'cap/users/me/';
    meChangePassword = 'cap/users/me/change-password';
    delegatedAccesses = `${ this.me }delegated-accesses/`;

    async _tryCatchDefaultError(asyncCallback) {
        let response;
        try {
            response = await asyncCallback();
        } catch (e) {
            if (e.responseJSON) {
                throw e.responseJSON;
            } else {
                throw ERROR_MESSAGES.default;
            }
        }
        return response;
    }

    async getDashboardDelegatedAccesses() {
        const response = await this.get({
            path: `${ this.me }delegated-accesses/`,
        });
        const { status_cd, status_desc, delegated_accesses } = response;

        return { status_cd, status_desc, delegated_accesses };
    }

    async getMe() {
        const response = await this._tryCatchDefaultError(async () => {
            return await this.get({
                path: `${ this.me }`,
            });
        });
        const { status_cd, status_desc, user } = response;

        return {
            status_cd,
            status_desc,
            user,
        };
    }

    /**
     * A method that call api to check username of user exist
     *   in server or not.
     *
     * @param {string} username - Username
     * @returns {Promise} { status_cd, status_desc }
     */
    async checkUsername(username) {
        const response = await this.post({
            path: this.basePath,
            data: {
                username,
            },
        });
        const { status_cd, status_desc } = response;

        return {
            status_cd,
            status_desc,
        };
    }

    /**
     * A method that call api to confirm create using
     *   the username that exist in the server to create
     *   a user for the system.
     *
     * returned user - { username, name, email, role, mobile,
     *   staff_id, department, is_editable }
     *
     * @param {string} username - Username
     * @returns {Promise} { status_cd, status_desc, user }
     */
    async createUsingExistingUsername(username) {
        const response = await this.post({
            path: this.basePath,
            data: {
                username,
                confirm_create: true,
            },
        });
        const { status_cd, status_desc, user } = response;

        return {
            status_cd,
            status_desc,
            user,
        };
    }

    // To add new user
    async addUser(data) {
        const response = await this.post({
            path: this.basePath,
            data: data,
        });
        const { status_cd, status_desc, user } = response;

        return {
            status_cd,
            status_desc,
            user,
        };
    }

    // To update specific user
    async updateUser(username, data) {
        const response = await this.post({
            path: `${ this.basePath }${ username }/`,
            data: data,
        });
        const { status_cd, status_desc, user } = response;

        return {
            status_cd,
            status_desc,
            user,
        };
    }

    // To update specific user's status
    async updateUserStatus({ username, status }) {
        const response = await this.patch({
            path: `${ this.basePath }${ username }/`,
            data: {
                status,
                username,
            },
        });
        const { status_cd, status_desc, user } = response;

        return {
            status_cd,
            status_desc,
            user,
        };
    }

    // To delete specific user
    async deleteUser(username) {
        const response = await this.delete({
            path: `${ this.basePath }${ username }/`,
        });
        return response;
    }

    // To Re-assign user
    async reassignUser({ currentUser, reassignObj }) {
        const response = await this.delete({
            path: `${ this.basePath }${ currentUser }/`,
            data: reassignObj,
        });
        return response;
    }

    // To retrieve existing users
    async getUsers() {
        let users, actionConfigurations, context;
        try {
            const response = await this.get({
                path: this.basePath,
            });
            ({ users, context, action_configurations: actionConfigurations } = response);
        } catch (error) {
            throw error.statusText;
        }

        return {
            users,
            action_configurations: actionConfigurations,
            context,
        };
    }

    async forgotPassword({ username, email }) {
        const response = await this._tryCatchDefaultError(async () => {
            return await this.post({
                path: `${ this.basePath }forget-password`,
                data: {
                    username,
                    email,
                },
            });
        });
        return response;
    }

    async changeUserPassword({ oldPassword, newPassword }) {
        const response = await this._tryCatchDefaultError(async () => {
            return await this.post({
                path: `${ this.meChangePassword }`,
                data: {
                    old_password: oldPassword,
                    new_password: newPassword,
                },
            });
        });
        return response;
    }
    async resetUserPassword({ newPassword, token }) {
        const response = await this._tryCatchDefaultError(async () => {
            return await this.post({
                path: `${ this.basePath }reset-password?token=${ token }`,
                data: {
                    new_password: newPassword,
                },
            });
        });
        return response;
    }
    async adminResetPassword(userId, data) {
        const response = await this._tryCatchDefaultError(async () => {
            return await this.post({
                path: `${ this.basePath }${ userId }/reset-password`,
                data,
            });
        });
        return response;
    }
}

export const userAPI = new UserAPI();
export default UserAPI;
