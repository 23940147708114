import ObjectPath from 'object-path';
import ObjectClone from 'clone';

import {
    SET_INITIAL_FORM_DATA,
    SET_FORM_ID,
    SET_FORM_STATUS,
    SET_FORM_CAMPAIGN_SLUG,
    SET_CAMPAIGN_SLUG,
    SET_CAMPAIGN_ID,
    SET_ACTIVE_CAMPAIGNS,
    GET_TEMPLATE_LIST,
    SET_FORM_TEMPLATE,
    LOAD_FORM,
    SET_ACTION_FORM,
    LOAD_TEMPLATE,
    SET_FORM_DATA,
    SET_FORM_VIEW,
    CHANGE_FIELD_VALUE,
    CHANGE_FIELD_VALIDITY,
    CHANGE_CODE_EDITOR_VALUE,
    CHANGE_CHECKBOX_STATUS,
    CHANGE_FIELDSET,
    SET_PREVIEW_OBJECT,
    SET_OPTIONS,
    SET_CURRENT_FORM,
    SET_UPDATED_DATE,
    SET_DOWNLOADING_FILES,
    SET_FORM_RULE,
    SET_FORM_ERROR,
} from '../actions/FormAction';
import {
    SET_TEMPLATE_CREATE_OBJECT,
    SET_CURRENT_EDITING_FIELD_VALUE,
} from '../actions/SectionAction';

export interface View {
    name: string;
    description: string;
    icon: string;
    sections: Section[];
}
export interface Section {
    id: number;
    fieldsets: Fieldset;
    isEditable: boolean;
}

export interface Sibling {
    pk: number;
    template: number;
    applicationId: string;
    formStatus: string;
    updatedDt: string;
    createdDt: string;
}

export interface Fieldset {
    id: string;
    type: string;
    grids: Grid[];
}

export interface Grid {
    id: string;
    fields: Field[];
    fieldsets: Fieldset[];
}

export interface Field { }

export interface Header {
    name: string;
    icon: string;
    is_clickable: boolean;
}

export interface FormState {
    showingViewNumber: number;
    page: number;
    views: View[];
    headers: Header[];
    data: any;
    templateId: string | null;
    formId: string | null;
    formStatus: any;
    activeCampaigns: any[];
    initialFormData: any;
    updatedDate: any;
    applicationId: string | null;
    uploadedFileUrl: any[];
    campaignId: string | null;
    campaignSlug: string | null;
    formCampaignSlug: string | null;
    downloadingFiles: any[];
    isCreatingForm: boolean;
    error: any;
    rule: any;
    siblings: Sibling[];
}

const INITIAL_STATE: FormState = {
    showingViewNumber: 1,
    page: 1,
    views: [],
    headers: [],
    data: {},
    templateId: null,
    formId: null,
    formStatus: {},
    activeCampaigns: [],
    initialFormData: {},
    updatedDate: null,
    applicationId: null,
    uploadedFileUrl: [],
    campaignId: null,
    campaignSlug: null,
    formCampaignSlug: null,
    downloadingFiles: [],
    error: null,
    rule: { fieldId: null, id: [] },
    isCreatingForm: false,
    siblings: [],
};

export default function FormReducer(state = INITIAL_STATE, action = {}): FormState {
    const { payload } = action;
    switch (action.type) {
        case SET_INITIAL_FORM_DATA:
            return {
                ...state,
                initialFormData: payload,
            };

        case SET_FORM_ID:
            return {
                ...state,
                formId: payload,
            };

        case SET_FORM_STATUS:
            return {
                ...state,
                formStatus: payload,
            };

        case SET_CAMPAIGN_ID:
            return {
                ...state,
                campaignId: payload,
            };

        case SET_FORM_CAMPAIGN_SLUG:
            return {
                ...state,
                formCampaignSlug: payload,
            };

        case SET_CAMPAIGN_SLUG:
            return {
                ...state,
                campaignSlug: payload,
            };

        case SET_ACTIVE_CAMPAIGNS:
            return {
                ...state,
                activeCampaigns: action.payload,
            };

        case LOAD_FORM: {
            const { form } = action;
            if (form === null) {
                return {
                    ...state,
                    ...INITIAL_STATE,
                    isCreatingForm: false,
                };
            }
            return {
                ...state,
                isCreatingForm: true,
                ...form,
            };
        }

        case SET_ACTION_FORM: {
            const form = Object.assign(state, {
                actionForm: action.form,
            });
            return form;
        }

        case SET_CURRENT_FORM: {
            return {
                ...state,
                id: action.id,
            };
        }

        case SET_UPDATED_DATE: {
            return {
                ...state,
                updatedDate: action.updatedDate,
            };
        }

        case SET_DOWNLOADING_FILES: {
            return {
                ...state,
                downloadingFiles: action.downloadingFiles,
            };
        }

        case LOAD_TEMPLATE: {
            const form = Object.assign(
                {
                    // reducer: 'template',
                    showingViewNumber: 1,
                    isCreatingTemplate: true,
                },
                state,
                action.template,
            );
            return form;
        }

        case GET_TEMPLATE_LIST: {
            const templateList = Object.assign({}, state, action.data);
            return templateList;
        }

        case SET_FORM_TEMPLATE: {
            const { template } = action;
            if (template === null) {
                return {
                    ...state,
                    isCreatingForm: false,
                };
            }
            return {
                ...state,
                isCreatingForm: true,
                ...template,
            };
        }

        case SET_FORM_DATA: {
            const data = Object.assign({}, state, {
                ...action.form,
                viewAccess: action.viewAccess,
            });
            return data;
        }

        case SET_FORM_VIEW: {
            const form = Object.assign({}, state, action.form);
            return form;
        }

        case CHANGE_FIELD_VALUE: {
            const root = 'form';
            const rootState = ObjectClone(state);
            const fieldPath = action.path.replace(root + '.', '');
            ObjectPath.set(rootState, fieldPath + '.value', action.value);
            return Object.assign({}, state, rootState);
        }

        case CHANGE_FIELD_VALIDITY: {
            const root = 'form';
            const rootState = ObjectClone(state);
            const fieldPath = action.path ? action.path.replace(root + '.', '') : '';
            if (fieldPath) {
                ObjectPath.set(rootState, fieldPath + '.isValid', !!action.validity.isValid);
                ObjectPath.set(
                    rootState,
                    fieldPath + '.errorMessage',
                    action.validity.errorMessage,
                );
            }

            return Object.assign({}, rootState);
        }

        case CHANGE_CODE_EDITOR_VALUE: {
            let rootValue = action.value;
            try {
                rootValue = JSON.parse(rootValue);

                return Object.assign({}, rootValue);
            } catch (error) {
                console.error('JSON: ' + error);
                return state;
            }
        }

        case CHANGE_CHECKBOX_STATUS: {
            const root = 'form';
            const selectedValue = 'selectedValue';
            const checkboxPath = action.path.replace(root + '.', '');
            const rootState = ObjectClone(state);
            ObjectPath.set(rootState, checkboxPath + '.' + selectedValue, action.value);

            return Object.assign({}, rootState);
        }

        case CHANGE_FIELDSET: {
            const root = 'form';
            const fieldsetPath = action.path.replace(root + '.', '');
            const rootState = ObjectClone(state);
            ObjectPath.set(rootState, fieldsetPath, action.fieldsets);

            return Object.assign({}, rootState);
        }

        // TEMPLATE

        case SET_PREVIEW_OBJECT: {
            const root = 'form';
            const previewObjectPath = action.path.replace(root + '.', '');
            const rootState = ObjectClone(state);
            ObjectPath.set(rootState, previewObjectPath, action.object);

            return Object.assign({}, rootState);
        }

        case SET_OPTIONS: {
            const root = 'form';
            const options = 'options';
            const selectionPath = action.path.replace(root + '.', '');
            const rootState = ObjectClone(state);
            action.options &&
                ObjectPath.set(rootState, selectionPath + `.${options}`, action.options);

            return Object.assign({}, rootState);
        }
        case SET_TEMPLATE_CREATE_OBJECT: {
            const nextState = Object.assign({}, state, {
                creation: {
                    ...state.creation,
                    ...action.obj,
                },
            });

            return nextState;
        }

        case SET_CURRENT_EDITING_FIELD_VALUE: {
            const nextState = Object.assign({}, state);
            if (!action.path) {
                console.error('Undefined path');
                return nextState;
            }

            ObjectPath.set(nextState, action.path, action.value);

            return nextState;
        }

        case SET_FORM_RULE: {
            return {
                ...state,
                rule: {
                    ...state.rule,
                    ...action.rule,
                },
            };
        }

        // ERROR
        case SET_FORM_ERROR: {
            return {
                ...state,
                error: action.error,
            };
        }

        default:
            return state;
    }
}
