import React from 'react';
import _ from 'underscore';
import { connect } from 'react-redux';
import FormBuilder from '../components/Form/FormBuilder';
import ButtonWithIcon from '../components/Form/ButtonWithIcon';
import createForm, { TYPE } from '../components/Misc/forms/createForm';
import { getShowWarning } from '../redux/selectors/SessionSelector';
import store from '../store';
import { setShowWarning, setSessionType } from '../redux/actions/SessionAction';
import { actionLogout } from '../redux/actions/AuthenticationAction';
import { ImageTimeout } from '../images/actions';
import { clearSession, runTimer, setupIdleTimers, stopIdleTimer } from '../services/SystemIdle';
import SETTINGS from '../settings';
import { compose, withProps } from 'recompose';
import { session } from '../components/SessionStorage';
import { goToLogin } from '../components/GoToPage';

const { SESSION_EXPIRY_WARNING_INTERVALS } = SETTINGS;
const sessionExpiryDate = session.getExpiryDate();
let shownWarningsContainer = [];
let countdownWorker;

export function stopCountdownTimer() {
    if (countdownWorker) {
        countdownWorker.terminate();
        countdownWorker = null;
    }
}

class SessionTimeout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            countdown: 0,
        };
        this.startCountdown = this.startCountdown.bind(this);
    }

    componentDidMount() {
        if (!this.props.isLoginPage) {
            // runs my timers if not on login page
            runTimer();
            setupIdleTimers();
        }
    }

    componentDidUpdate(prevProps) {
        const startCountdown = this.props.isCountdown && !prevProps.isCountdown;
        if (startCountdown) {
            store.dispatch(setSessionType('sessionTimeout'));
            this.startCountdown();
        }
    }

    // if countdown becomes 0
    stop() {
        this.stopCountdown();
        store.dispatch(setShowWarning(true));
        store.dispatch(setSessionType('sessionTimeoutLogout'));
        clearSession();
    }

    // counts the remainer time left for session
    startCountdown() {
        const self = this;
        if (window.Worker !== undefined && countdownWorker !== null) {
            countdownWorker = new Worker('/countdownWorker.js');
            countdownWorker.addEventListener(
                'message',
                function() {
                    const currentTime = new Date().getTime();
                    const sessionTimeLeft = new Date(sessionExpiryDate).getTime() - currentTime;
                    // Gets shown warning intervals from session
                    const shownTimeoutWarnings = session.getShownTimeoutWarnings();
                    // Filters out remaining warning intervals
                    const remainingWarningIntervals = SESSION_EXPIRY_WARNING_INTERVALS.filter(
                        configIntervals => {
                            return !shownTimeoutWarnings.includes(configIntervals);
                        },
                    );
                    // Reads each interval and check if any interval time has past current time
                    remainingWarningIntervals.forEach(interval => {
                        if (interval > sessionTimeLeft) {
                            shownWarningsContainer = shownWarningsContainer.concat(interval);
                            session.setShownTimeoutWarnings(shownWarningsContainer);
                            store.dispatch(setShowWarning(true));
                        }
                    });
                    // calculate number of minutes
                    let minutes = Math.floor(sessionTimeLeft / 1000 / 60);
                    // calculate number of seconds
                    let seconds = Math.floor(sessionTimeLeft / 1000) - minutes * 60;
                    // if number of minutes less than 10, add a leading "0"
                    minutes = minutes.toString();
                    if (minutes.length == 1) {
                        minutes = '0' + minutes;
                    }
                    // if number of seconds less than 10, add a leading "0"
                    seconds = seconds.toString();
                    if (seconds.length == 1) {
                        seconds = '0' + seconds;
                    }

                    // If the count down is finished, write some text
                    if (sessionTimeLeft <= 0) {
                        self.stop(); // calls stop fn
                        stopIdleTimer(); // stops idle timer
                    } else {
                        self.setState({
                            countdown: `${ minutes }:${ seconds }`,
                        });
                    }
                },
                false,
            );
            countdownWorker.postMessage('startCountdown'); // start interval ms
        }
    }

    stopCountdown() {
        stopCountdownTimer();
    }

    handleContinueBtn() {
        store.dispatch(setShowWarning(false));
    }

    handleLogoutBtn() {
        store.dispatch(setShowWarning(false));
        session.removeLoginTime();
        this.props.setLogout('idle');
        goToLogin();
    }

    render() {
        const { countdown = '' } = this.state;
        const { showWarning, sessionType } = this.props;
        const typesOfActionForm = {
            idleTimeout: {
                topTitle:
                    'You have been inactive for a while, please re-login again to continue your work.',
                botTitle: '',
                button: {
                    text: 'Logout',
                    function: 'handleLogoutBtn',
                },
            },
            sessionTimeout: {
                topTitle: 'Your session is expiring in',
                botTitle: `${ countdown }`,
                button: {
                    text: 'Continue',
                    function: 'handleContinueBtn',
                },
            },
            sessionTimeoutLogout: {
                topTitle: 'Your session has expired, please re-login',
                botTitle: '',
                button: {
                    text: 'Logout',
                    function: 'handleLogoutBtn',
                },
            },
        };

        const actionFormConfig = typesOfActionForm[sessionType];

        const timeoutForm = createForm({
            size: 'small',
            image: ImageTimeout,
            fields: [
                {
                    title: actionFormConfig ? actionFormConfig.topTitle : '',
                    type: TYPE.label,
                    label: 'session timeout',
                    bold: true,
                },
                {
                    title: actionFormConfig ? actionFormConfig.botTitle : '',
                    type: TYPE.label,
                    label: 'countdown',
                    className: 'no-top-padding',
                },
            ],
            buttons: [
                {
                    text: actionFormConfig ? actionFormConfig.button.text : '',
                    onClick: () => {
                        actionFormConfig && this[actionFormConfig.button.function]();
                    },
                },
            ],
        });
        const classes = ['action__form', showWarning && 'fixed visible'];
        const formClasses = ['container form__wrapper', 'small'];
        const { buttons = {}, form = {} } = timeoutForm;
        const isButtonExist = !_.isEmpty(buttons);
        const buttonComponents =
            isButtonExist &&
            Object.entries(buttons).map((button, index) => {
                const [_key, value] = button;
                const { title, name, onClick } = value;
                const option = {
                    ...value,
                    title: title || name || 'Confirm',
                    onClick: _.isObject
                        ? onClick
                        : (e, data) => {
                            this.handleClick(e, {
                                ...data,
                                action: name || 'confirm',
                            });
                            onClick &&
                                  onClick(e, {
                                      ...data,
                                      action: name || 'confirm',
                                  });
                        },
                };
                return (
                    <ButtonWithIcon key={index} className={`button button__${ name }`} {...option} />
                );
            });

        return (
            <div>
                {showWarning && (
                    <div id="session-timeout" className={classes.join(' ')}>
                        <div className="background"></div>
                        <div className={formClasses.join(' ')}>
                            <FormBuilder form={form} />

                            <div className="button__group">{buttonComponents}</div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        pagePath: state.page.path,
        showWarning: getShowWarning(state),
        sessionType: state.session.sessionType,
        isCountdown: state.session.countdown,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setLogout: data => {
            dispatch(actionLogout(data));
        },
    };
};

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    // check is login page
    withProps(({ pagePath, showWarning }) => {
        const reg = new RegExp('^/login/?$');
        const isLoginPage = reg.test(pagePath);
        return {
            isLoginPage,
            showWarning: isLoginPage ? false : showWarning,
        };
    }),
)(SessionTimeout);
