import { init as initApm } from '@elastic/apm-rum';
import SETTINGS from './settings';
import parseUrl from '../src/components/Misc/utils/parseUrl';

export const apm = initApm({
    serviceName: 'capture-frontend',
    active: SETTINGS.IS_RUM,
    serverUrl: SETTINGS.RUM_SERVER_URL,
    /**
     * RUM by default only transmit when the application is done, running.
     * By default it will only wait up to 3 secs, which is too short for us.
     * Certain pages in capture are only ready after 10s.
     * Disabling this setting means that RUM will just send to APM anytime.
     */
    checkBrowserResponsiveness: false,
});

apm.addLabels(parseUrl());

export default apm;
