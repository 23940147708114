import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './redux/reducers/rootReducer';

const isProduction = process.env.NODE_ENV === 'production';
const store = configureStore({
    reducer: rootReducer,
    middleware: [thunkMiddleware],
    devTools: !isProduction,
});

// if(!isProduction) console.log('First Log', JSON.stringify(store.getState()));
const unsubscribe = store.subscribe(() => {
    if (!isProduction) console.log('Changes', JSON.stringify(store.getState(), null, 2));
});
unsubscribe();

export const persistor = persistStore(store);
export default store;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
