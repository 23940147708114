import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ObjectPath from 'object-path';
import _ from 'underscore';

import store from '../../../store';
import { getValues } from '../../../redux/selectors';
import { error } from '../../Misc';
import { setValue } from '../../../redux/actions/ValueAction';

class Copy extends Component {
    constructor(props) {
        super(props);
    }

    handleCopy = e => {
        const { onClick = {} } = this.props;
        if (_.isFunction(onClick)) {
            onClick(e, this.props);
            return;
        }
        const { fields, from, to } = onClick;
        const pathBeforeIndex = getPathBeforeIndex(from);
        const path = !_.isUndefined(to) ? to : pathBeforeIndex;
        if (!pathBeforeIndex) return;
        // always there's index at the end of from
        const values = getValues(store.getState());
        const fromValue = ObjectPath.get(values, from, {});
        const currentValue = ObjectPath.get(values, pathBeforeIndex, {});
        const fieldIdMapValue = {};
        fields.forEach(id => {
            ObjectPath.set(fieldIdMapValue, id, ObjectPath.get(fromValue, id));
        });
        if (!_.isArray(currentValue)) return;

        // loop every set and replace value based on given pathBeforeIndex
        const newValue = currentValue.map(set => {
            fields.forEach(fieldId => {
                const newFieldValue = ObjectPath.get(fieldIdMapValue, fieldId);
                if (!_.isUndefined(newFieldValue)) {
                    ObjectPath.set(set, fieldId, newFieldValue);
                }
            });
            return set;
        });
        // set value
        store.dispatch(
            setValue({
                path,
                value: newValue,
            }),
        );
        return newValue;
    };

    render() {
        const { id, label, isEditable } = this.props;
        const classNames = ['jpt--input input__copy', !isEditable ? 'disabled' : ''];
        const title = label.title;

        return (
            <div className={classNames.join(' ')} id={id}>
                <div className="copy-icon" onClick={isEditable ? this.handleCopy : undefined}>
                    <i className="clone outline icon"></i>
                </div>
                <div className="copy-text" onClick={isEditable ? this.handleCopy : undefined}>
                    {title}
                </div>
            </div>
        );
    }
}

Copy.defaultProps = {
    fields: [],
    label: {},
    isEditable: true,
};
Copy.propTypes = {
    id: PropTypes.string,
    fields: PropTypes.array,
    label: PropTypes.object,
    isEditable: PropTypes.bool,
};

export default Copy;

/**
 * A method that process string with a number (index) at the end of the string
 * e.g path `path.0`
 * @param {string} path - dotted path
 * @returns {string} pathBeforeIndex
 */
export function getPathBeforeIndex(path) {
    if (!path) return path;

    const splittedPath = path.split('.');
    const index = splittedPath.pop();
    if (!(parseInt(index) > -1)) {
        error(`[Path]: Path given has no index at the end ${ path }`);
        return path;
    }
    const pathBeforeIndex = splittedPath.join('.');
    return pathBeforeIndex;
}
