import {
    SET_TEMPLATES_FETCHING,
    SET_TEMPLATES_ERROR,
    SET_TEMPLATES,
    SET_TEMPLATE,
    SET_FIELDS,
    GET_TEMPLATE_OPTIONS,
    SET_CURRENT_TEMPLATE,
} from '../actions/TemplateAction';

const INITIAL_STATE = {
    fetching: {
        templates: false,
    },
    error: {
        templates: null,
    },
    templates: {},
    fields: {},
};

export default function TemplateReducer(state = INITIAL_STATE, action) {
    const { payload } = action;

    switch (action.type) {
    case SET_TEMPLATES_FETCHING:
        return {
            ...state,
            fetching: {
                ...state.fetching,
                templates: payload,
            },
        };

    case SET_TEMPLATES_ERROR:
        return {
            ...state,
            error: {
                ...state.error,
                templates: payload,
            },
        };

    case SET_TEMPLATES:
        return {
            ...state,
            templates: payload,
        };

    case SET_TEMPLATE:
        return {
            ...state,
            templates: {
                ...state.templates,
                ...payload,
            },
        };

    case SET_CURRENT_TEMPLATE:
        return {
            ...state,
            id: action.id,
        };

    case GET_TEMPLATE_OPTIONS: {
        return {
            ...state,
            templateOptions: payload,
        };
    }

    case SET_FIELDS: {
        return {
            ...state,
            fields: payload,
        };
    }

    default:
        return state;
    }
}
