import _ from 'underscore';
import { compose, withStateHandlers, withProps } from 'recompose';

// NOTE: This applicable for fields and fieldsets only
/**
 * A decorator that process field.isEditable and isParentEditable, then
 * decide to allow field to be disabled or not processed `isEditable`
 * is return as new props.
 */
export const withIsEditableUpdate = compose(
    // to have a default state value for isEditable
    withStateHandlers(({ isEditable, isParentEditable = true }) => {
        // get initial isEditable state
        if (_.isUndefined(isEditable) || isEditable) {
            isEditable = isParentEditable;
        }
        return {
            initialIsEditable: isEditable,
        };
    }, null),
    // processing isEditable and isParentEditable logic
    withProps(({ isEditable, isParentEditable = true }) => {
        if (_.isObject(isEditable)) return;

        if (_.isUndefined(isEditable) || isEditable) {
            isEditable = isParentEditable;
        }

        return {
            isEditable,
        };
    }),
);

export default withIsEditableUpdate;
