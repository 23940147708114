import store from 'store';
import ObjectPath from 'object-path';
import * as FR from '../../Form/FormRules';
import { withProps, compose } from 'recompose';
import { getTriggeredStates } from '../../../redux/selectors';

// NOTE: This applicable for fields and fieldsets only
/**
 * A decorator that process field.trigger and decide to
 * either to show component or don't show the component
 * by checking props.triggerState.
 * props.triggerState will be set using validityPath
 * regardless thisLevel is set to true or not.
 *
 * Resulting props.showComponent will be set to true or false
 */
const withProcessedTriggerState = compose(
    withProps(({ initialId, validityPath, type, triggeredState }) => {
        if (!initialId) return { showComponent: true };

        const reduxStates = store.getState();
        const triggeredStates = getTriggeredStates(reduxStates);

        let show = !FR.isTriggered(ObjectPath.get(triggeredStates, validityPath, triggeredState));
        if (type === FR.fieldsetKey) {
            show = ObjectPath.get(triggeredStates, validityPath, null);
            show = !FR.isTriggered(show);
        }

        return {
            showComponent: show,
        };
    }),
);

export default withProcessedTriggerState;
