import React from 'react';
import _ from 'underscore';
import { toValidDate, MINUTES, isDatetimeInRange } from './misc';
import { getMinutes, getHours, getMonth, getFullYear, getDate } from '../../Misc/datetime';
import moment from 'moment-timezone';

const MinuteColumn = ({ minute, hour, disabled, selected, onClick }) => {
    const { name, isDisabled } = minute;
    const classNames = [
        'calendar-column-minute',
        disabled || isDisabled ? 'disabled' : '',
        selected ? 'active' : '',
    ];
    if (name) {
        minute = name;
    }
    if (disabled || isDisabled) {
        onClick = _.noop;
    }

    return (
        <div
            className={classNames.join(' ')}
            role="button"
            onClick={e => {
                onClick(e, { minute });
            }}>
            {`${hour}:${minute}`}
        </div>
    );
};

const MinuteRows = ({ date, minDate, maxDate, timezone, onClick }) => {
    const hour = getHours(date, timezone);
    const selectedMinute = getMinutes(date, timezone);

    return (
        <div className="calendar-rows-minute">
            {MINUTES.map((minute, i) => {
                const currentDate = moment(date)
                    .set({
                        hour: hour,
                        minute: minute,
                    })
                    .toDate();
                return (
                    <MinuteColumn
                        key={i}
                        hour={hour}
                        minute={minute}
                        disabled={
                            !isDatetimeInRange({
                                date: currentDate,
                                minDate,
                                maxDate,
                                type: 'minutes',
                            })
                        }
                        selected={selectedMinute === minute}
                        onClick={onClick}
                    />
                );
            })}
        </div>
    );
};

const CalendarPopUpMinute = props => {
    const { minDate, maxDate, timezone, onClick } = props;
    let { date } = props;
    date = toValidDate(date);
    const month = getMonth(date, timezone);
    const year = getFullYear(date, timezone);
    const day = getDate(date, timezone);
    const hour = getHours(date, timezone);
    return (
        <div className="calendar-minute-wrapper">
            <div className="popup-top-title border">Minute</div>
            <MinuteRows
                date={date}
                maxDate={maxDate}
                minDate={minDate}
                timezone={timezone}
                onClick={(e, data) => {
                    onClick &&
                        onClick(e, {
                            year,
                            month,
                            day,
                            hour,
                            ...data,
                        });
                }}
            />
        </div>
    );
};

export default CalendarPopUpMinute;
