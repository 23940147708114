import _ from 'underscore';
import store from 'store';
import ObjectPath from 'object-path';
import * as Selectors from 'redux/selectors';
import { REPLACE_VALIDATION_KEY } from '.';
import { error } from '..';
import { isEmptyAndNotNumber } from './misc';
import { setValidators } from 'redux/actions/ValidatorAction';
import { setValidityTargets } from 'redux/actions/ValidityAction';

/**
 * A method to replace current schem for given field id based on given replaceValidation logic
 * Note: This method must be run per field check, previous validation will be replaced once there's
 * second field validation run.
 * @param {Object} { extendingProps, id, hotParentId, hotRowIndex, path }
 * @returns {Object} replacedSchema
 */
function replaceValidation({ extendingProps, id, hotParentId, hotRowIndex, path }) {
    const reduxStates = store.getState();
    const ajv = Selectors.getAjv(reduxStates);
    const values = Selectors.getValues(reduxStates);
    const validationSchema = Selectors.getSchema(reduxStates);
    const replaceValidations = extendingProps[REPLACE_VALIDATION_KEY];

    if (_.isEmpty(replaceValidations)) {
        return;
    }

    // to handle two different format or replace validation object
    let replaceSchema;
    if (Object.prototype.toString.call(replaceValidations) === '[object Object]') {
        // not using underscore _.isObject cause array is object
        replaceSchema = replaceValidationLogicObjectCheck(replaceValidations, {
            id,
            hotParentId,
            hotRowIndex,
            path,
            values,
        });
    } else if (_.isArray(replaceValidations)) {
        for (const logic of replaceValidations) {
            replaceSchema = replaceValidationLogicObjectCheck(logic, {
                id,
                hotParentId,
                hotRowIndex,
                path,
                values,
            });
            if (replaceSchema) break;
        }
    } else {
        error(
            `[TypeError]: replaceValidation given in wrong format, either {} or [{}], but given as ${ replaceValidations }`,
        );
        return;
    }

    // if there is validation schema
    if (replaceSchema) {
        const validate = ajv.compile({
            properties: {
                ...validationSchema.properties,
                [id]: replaceSchema,
            },
        });
        store.dispatch(setValidators(validate));
    } else {
        const validate = ajv.compile(validationSchema);
        store.dispatch(setValidators(validate));
    }
    return replaceSchema;
}

export default replaceValidation;

export { valueComparisonFn };

/**
 * valueComparisonFn function compares by field'(s)
 * values with valueToMatch(value set in field object extend util).
 * Returns a list of booleans into replaceValidation function
 * where its being called.
 * @function
 * @param {boolean} and - truth to be and conditon.
 * @param {string} byValue - value of the by field.
 * @param {string[]} byValueList - a list of values of the by field.
 * @param {string} valueToMatch - a value set by admin in current field's object.
 * @returns {boolean} true or false based on the comparisons.
 */
function valueComparisonFn({ and, byValue, byValueList, valueToMatch }) {
    // Set default in this way due to byValueList might be 'false'
    byValueList = byValueList || [];
    // is valueToMatch array
    const isValueToMatchArray = _.isArray(valueToMatch);
    // single by and single value to match
    const isEqualWithByValue = _.isNull(valueToMatch)
        ? isEmptyAndNotNumber(byValue)
        : _.isEqual(byValue, valueToMatch);
    // multiple by and single value to match; flatten value, [['waiver'], 'another_value'] to ['waiver', 'another_value']
    const isMultipleByContainsValue = _.contains(_.flatten(byValueList), valueToMatch);
    // single by and multiple value to match
    const isByContainsMultipleValue = isValueToMatchArray && _.contains(valueToMatch, byValue);
    // AND -> _.every, OR -> _.some
    const condFunc = and ? _.every : _.some;
    // multiple by and multiple value to match
    const isMultipleByMultipleValue = condFunc(
        byValueList.map((v, i) => {
            if (_.isNull(valueToMatch[i])) {
                return isEmptyAndNotNumber(v);
            } else if (_.isArray(v) && _.isArray(valueToMatch[i])) {
                return _.every(
                    valueToMatch[i].map(m => {
                        // if it's array
                        if (_.isArray(m)) {
                            // when .length is 0 means matched
                            return !_.difference(v, m).length;
                        }
                        return v.indexOf(m) > -1;
                    }),
                );
            }
            return _.isEqual(v, valueToMatch[i]);
        }),
    );

    return [
        isEqualWithByValue,
        and ? isMultipleByMultipleValue : isMultipleByContainsValue,
        isByContainsMultipleValue,
        isMultipleByMultipleValue,
    ];
}

// private
/**
 * A method that run logic check base on given replace valiation logic object
 * @param {Object} logic - { valueToMatch, validatorReplacements, by, and, not }
 * @param {Object} object - { id, hotParentId, hotRowIndex, path, values }
 * @returns {Object} replaceSchema
 */
function replaceValidationLogicObjectCheck(logic, { id, hotParentId, hotRowIndex, path, values }) {
    let replaceSchema;
    const { valueToMatch, validatorReplacements, by, and, not } = logic;

    const nestedFieldValueFn = getNestedFieldValue({ by, path, values });
    const { thisLevelByValue, thisLevelByValueList } = nestedFieldValueFn;

    const isByArray = _.isArray(by);
    const isValueFoundInThisLevelByValueList = _.any(thisLevelByValueList);
    // get by value
    const byValue = thisLevelByValue || values[by];
    const byValueList =
        (isValueFoundInThisLevelByValueList && thisLevelByValueList) ||
        (isByArray && by.map(fieldId => values[fieldId]));

    const getValueMatches = valueComparisonFn({ and, byValue, byValueList, valueToMatch });
    let isValueMatchWithCond = _.some(getValueMatches);
    isValueMatchWithCond = not ? !isValueMatchWithCond : isValueMatchWithCond;
    // handsontable check
    const hotParentValue = ObjectPath.get(values, hotParentId, []);
    let hotRowValueArr = [];

    if (hotParentId) {
        const hotRowValue = hotParentValue[hotRowIndex];
        hotRowValueArr = by.map(by_ => hotRowValue[by_]);
    }

    let checkHotValueToCompare = _.every(
        hotRowValueArr,
        eachRowValue => eachRowValue === valueToMatch,
    );
    checkHotValueToCompare = not ? !checkHotValueToCompare : checkHotValueToCompare;

    if (isValueMatchWithCond) {
        replaceSchema = validatorReplacements;
        !_.isArray(by)
            ? store.dispatch(setValidityTargets({ [by]: id }))
            : by.map(by_ => store.dispatch(setValidityTargets({ [by_]: id })));
    }
    // For Handsontable
    if (hotParentId && checkHotValueToCompare) {
        replaceSchema = validatorReplacements;
    }

    return replaceSchema;
}

/**
 * getNestedFieldValue function will look for nested
 * or dynamic fields and returns its values into
 * replaceValidation function where its being called.
 * @function
 * @param {string|string[]} by - the id of the by field.
 * @param {string} path - the path from parent to the current field.
 * @param {Object} values - the values from redux state.
 * @returns {object} { thisLevelByValue: string, thisLevelByValueList: string[] } - based on 'by', it's either single value or array of value
 */
function getNestedFieldValue({ by, path, values }) {
    const processedFieldPath = path.split('.');
    processedFieldPath.pop();
    const parentPath = processedFieldPath.join('.');
    const thisLevelFields = ObjectPath.get(values, parentPath, {});
    const thisLevelByValue = thisLevelFields[by];
    const thisLevelByValueList = _.isArray(by) && by.map(eachBy => thisLevelFields[eachBy]);
    return { thisLevelByValue, thisLevelByValueList };
}
