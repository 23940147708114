import React from 'react';
import Proptypes from 'prop-types';
import _ from 'underscore';
import { Checkbox } from 'semantic-ui-react';
import { shouldUpdate, compose } from 'recompose';

export const ColumnCheckbox = ({ row, checked, onChange }) => (
    <TableColumn>
        <Checkbox rowIndex={row} checked={checked} onChange={onChange} />
    </TableColumn>
);

const TableColumn = ({ rows, rowIndex, data, className, header, children, onClick }) => {
    data = children || data;
    // sort can be function or key of column to use for sorting purpose
    const { sort, render } = header;
    const row = rows[rowIndex];
    let order = {};
    // data-order=null will affect datatable sorting.
    //   Extending data-order using spread operator,
    //   Which mean if not needed, data-order not be
    //   set in the element.
    if (data) {
        if (_.isFunction(sort)) {
            order = {
                'data-order': sort(data),
            };
        } else if (_.isObject(row) && row[sort]) {
            order = {
                'data-order': row[sort],
            };
        }
    }

    // use render to render <HTMLElement>
    if (_.isFunction(render)) {
        data = render({ data, row });
    }

    return (
        <td className={['table__column', className].join(' ')} onClick={onClick} {...order}>
            {data}
        </td>
    );
};

TableColumn.defaultProps = {
    className: null,
    rows: [],
    header: {},
    data: null,
    children: null,
    onClick: _.noop,
};

TableColumn.propTypes = {
    className: Proptypes.string,
    rows: Proptypes.array,
    header: Proptypes.object,
    data: Proptypes.any,
    children: Proptypes.any,
    onClick: Proptypes.func,
};

export default compose(
    // since we are passing down rows we don't want
    //  column to update when rows / data / children update
    shouldUpdate((current, { rows = [], rowIndex, data, children } = {}) => {
        let { rows: currentRows = [], data: currentData, children: currentChildren } =
            current || {};
        data = children || data;
        currentData = currentChildren || currentData;

        if (!_.isEqual(currentData, data)) {
            return true;
        }
        if (!_.isEqual(currentRows[rowIndex], rows[rowIndex])) {
            return true;
        }

        return false;
    }),
)(TableColumn);
