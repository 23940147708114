import React from 'react';
import _ from 'underscore';

const Loader = ({ className, show = false }) => {
    const cls = ['loader-container', _.isString(className) && className];
    const classNames = ['loader', !show && 'hidden'];
    return (
        <div className={cls.join(' ')}>
            <div className={classNames.join(' ')}></div>
        </div>
    );
};

export default Loader;
