import { createSelector } from 'reselect';

const disabledFieldsSelector = state => state.disabled.fields;
const disabledSectionsSelector = state => state.disabled.sections;
const disabledFieldsByIdsSelector = (state, ids) => {
    const fields = state.disabled.fields;
    const filteredFields = {};
    Object.entries(fields).forEach(([id, value]) => {
        const found = ids.indexOf(id) > -1;
        if (found) {
            filteredFields[id] = value;
        }
    });
    return filteredFields;
};

const disabledCheckboxOptionsSelector = state => state.disabled.selections;
const disabledCampaignFieldsSelector = state => state.disabled.campaignFields;

export const getDisabledFields = createSelector(
    disabledFieldsSelector,
    fields => fields,
);

export const getDisabledSections = createSelector(
    disabledSectionsSelector,
    sections => sections,
);

export const getDisabledFieldsByIds = createSelector(
    disabledFieldsByIdsSelector,
    fields => fields,
);

export const getDisabledSelections = createSelector(
    disabledCheckboxOptionsSelector,
    selections => selections,
);

export const getDisabledCampaignFields = createSelector(
    disabledCampaignFieldsSelector,
    campaignFields => campaignFields,
);
