import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ButtonClosed from '../ButtonClosed';
import { formatDate } from '../../../Misc/datetime';

class Popup extends Component {
    createItem({ hisryObj, index, timezone, format }) {
        const date = hisryObj.timestamp;
        const formattedDate = formatDate({ date, format, timezone });
        return (
            <div key={index}>
                <p className="popup__paragraph">{hisryObj.message}</p>
                <div className="popup__user">
                    <span>{`${ hisryObj.user.name } (${ hisryObj.user.role })`}</span>
                </div>
                <div className="popup__category">
                    <span>
                        {hisryObj.category}, {formattedDate}
                    </span>
                </div>
            </div>
        );
    }

    render() {
        const { history, timezone, format } = this.props;
        const createHistory = history.map((hisryObj, index) => {
            return this.createItem({ hisryObj, index, timezone, format });
        });

        return this.props.show ? (
            <div style={{ opacity: this.props.show ? '1' : '0' }}>
                <div className="popup__backdrop"></div>
                <div className="popup">
                    <div className="popup__wrapper">
                        <div className="popup__title">Remarks</div>
                        <div>{createHistory}</div>
                    </div>
                    <ButtonClosed onClick={this.props.closed} />
                </div>
            </div>
        ) : null;
    }
}
Popup.defaultProps = {
    history: [],
};
Popup.propTypes = {
    history: PropTypes.array.isRequired,
};

export default Popup;
