import React from 'react';
import _ from 'underscore';
import DOMPurify from 'dompurify';

import ErrorMessage from './ErrorMessage';
import Label from './../Label';
import CheckboxGroup from './../CheckboxGroup';

import { compose, withProps } from 'recompose';

import store from '../../../store';
import { getLists } from '../../../redux/selectors/ListsSelector';

// Misc
import { FieldAction } from '../../Misc/template';
import { withCaptureFieldHandlers } from '../../Misc/forms';
import { getValues } from '../../../redux/selectors';

const AGREEMENT_SENTENCE = 'I have read and agree with the terms and conditions';
const ACCEPT_VALUE = 'accept';
const BANKS_AGREEMENT_MAPPING_ID = 'banks_map_to_agreement';
const BANK_NAME_ID = 'application_bank_name'; // core

class BaseAcceptance extends React.Component {
    topClass = 'input__label--top';
    classWarning = 'warning';

    static defaultProps = {
        className: '',
        fieldname: {},
        label: {},
        options: [],
        placeholder: '',
        isValid: true,
    };

    constructor(props) {
        super(props);
        this.props = props;

        this.handleChange = this.props.handleChange.bind(this);
    }

    render() {
        const {
            id,
            className,
            // option
            type,
            title,
            name,
            label,
            children,
            accept,
            value = [],
            onChange,
            isEditable,
            isValid,
            errorMessage,
            acceptanceText = AGREEMENT_SENTENCE,
            // utils
            showComponent,
            path,
        } = this.props;
        let { content = children } = this.props;
        const option = {
            type,
            title,
            name,
            children,
            label,
            accept,
            onChange,
        };

        content = children || content;
        // sanitize the content
        content = DOMPurify.sanitize(content, { SAFE_FOR_JQUERY: true, ADD_ATTR: ['target'] });

        const classNames = [
            'jpt--input input__acceptance',
            _.isString(className) && className,
            !isValid && this.classWarning,
            !isEditable && 'noteditable',
        ];
        const hasAgree = value.indexOf(ACCEPT_VALUE) > -1;
        if (!showComponent) return <div />;

        return (
            <div className={classNames.join(' ')} id={id}>
                {!_.isEmpty(label) && <Label className={this.topClass} {...label} />}

                <div className="acceptance__content">
                    {!children && (
                        <div
                            className="content-html"
                            dangerouslySetInnerHTML={{ __html: content }}
                        />
                    )}
                    {children && children}
                    <CheckboxGroup
                        className="acceptance__checkbox"
                        isEditable={isEditable}
                        options={[
                            {
                                title: acceptanceText,
                                value: ACCEPT_VALUE,
                            },
                        ]}
                        value={(hasAgree || accept) && [ACCEPT_VALUE]}
                        onChange={(e, data) => {
                            _.isFunction(onChange) && onChange(e, data);
                            this.handleChange(e, { value: data.value });
                        }}
                    />
                </div>

                <ErrorMessage message={errorMessage} />

                {this.props.isEditingField && <FieldAction path={path} field={option} />}
            </div>
        );
    }
}

/**
 * HOC to enhance component withCaptureFieldHandlers,
 */
const enhance = compose(
    withCaptureFieldHandlers,
    // format content when it's in object format. e.g. { agreement_id: 'id' }
    withProps(({ content }) => {
        if (!_.isObject(content)) return;

        const state = store.getState();
        const lists = getLists(state);
        const { agreement_id: agreementId = 'capture_client' } = content || {};
        const agreementMapping = lists[BANKS_AGREEMENT_MAPPING_ID] || {};
        content = agreementMapping[agreementId];

        return {
            content,
        };
    }),
    // format and append agreement with bank agreeement
    withProps(({ content, showBankAgreement, prependAgreement }) => {
        if (!showBankAgreement) return;

        const state = store.getState();
        const values = getValues(state);
        const bankNameValue = values[BANK_NAME_ID];
        const lists = getLists(state);
        const agreementMapping = lists[BANKS_AGREEMENT_MAPPING_ID] || {};
        const bankAgreement = agreementMapping[bankNameValue];

        if (bankAgreement) {
            content = prependAgreement ? bankAgreement + content : content + bankAgreement;
        }

        return {
            content,
        };
    }),
);

/**
 * Enhanced Dropdown with Capture Field Handler
 */
const EnhancedAcceptance = enhance(BaseAcceptance);

/**
 * EnhancedDropdown as default export
 */
export default EnhancedAcceptance;

export { BaseAcceptance, BANKS_AGREEMENT_MAPPING_ID };
