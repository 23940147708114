import BaseAPI from '../BaseAPI';

class TemplateRoleAPI extends BaseAPI {
    basePath = 'cap/templates/';

    async getRoles(templateId) {
        const { status_cd, status_desc, roles } = await this.get({
            path: `${ this.basePath }${ templateId }/roles/`,
        });
        return {
            status_cd,
            status_desc,
            roles,
        };
    }
}

export const templateRoleAPI = new TemplateRoleAPI();
