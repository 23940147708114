export function constructViewSections({ viewSections, fieldsetsData, gridsData, fieldsData }) {
    const sections = [];

    viewSections.forEach(({ id, fieldsets }) => {
        const fieldsetIds = fieldsets;
        const sectionFieldsets = getRecursiveFieldsets(fieldsetIds);

        function getRecursiveFieldsets(fieldsetIds = []) {
            const getFieldsets = fieldsetIds.map(fieldsetId => {
                let fieldsets = { ...fieldsetsData[fieldsetId] };
                const { custom_properties: fieldsetsCustomProps = {} } = fieldsets;
                const recursiveFieldsets = getNestedFieldsets(fieldsets);
                const customProps = recursiveFieldsets.custom_properties || {};
                const isSpreadsheet = customProps.setType === 'spreadsheet';

                if (isSpreadsheet) {
                    delete recursiveFieldsets.custom_properties;
                    fieldsets = {
                        ...recursiveFieldsets,
                        ...customProps,
                        id: fieldsetId,
                        type: 'fieldset',
                        set: [],
                    };
                } else if (!isSpreadsheet && customProps.set) {
                    delete fieldsets.grids;

                    recursiveFieldsets.id = fieldsetId;
                    recursiveFieldsets.valueId = fieldsetsCustomProps.valueId;

                    fieldsets = {
                        ...fieldsets,
                        ...fieldsetsCustomProps,
                        id: fieldsetId,
                        type: 'fieldset',
                        set: [recursiveFieldsets],
                    };
                } else {
                    fieldsets = {
                        ...fieldsets,
                        id: fieldsetId,
                        ...recursiveFieldsets,
                    };
                }
                function getNestedFieldsets(fieldsets) {
                    const { grids = [], custom_properties: fieldsetsCustomProps = {} } = fieldsets;
                    const getGrids = grids.map(gridId => {
                        const nestedFieldsets = [];
                        const nestedFieldsetIds = [];
                        const grids = gridsData[gridId] || [];
                        const fields = [];

                        grids.forEach(fieldsetNField => {
                            const gridFieldsets = fieldsetsData[fieldsetNField];
                            const gridFields = fieldsData[fieldsetNField];

                            if (gridFieldsets) {
                                nestedFieldsetIds.push(fieldsetNField);
                                const newFieldsets = getNestedFieldsets(gridFieldsets);
                                nestedFieldsets.push({
                                    type: 'fieldset',
                                    id: fieldsetNField,
                                    ...newFieldsets,
                                });
                            } else if (gridFields) {
                                const customProps = gridFields.custom_properties;
                                fields.push({
                                    type: 'field',
                                    id: fieldsetNField,
                                    ...gridFields,
                                    ...customProps,
                                });
                            }
                        });

                        let fType;
                        if (nestedFieldsets.find(fs => fs.type === 'fieldset')) {
                            fType = {
                                type: 'grid',
                                id: gridId,
                                fieldsets: getRecursiveFieldsets(nestedFieldsetIds),
                            };
                        } else {
                            fType = { type: 'grid', id: gridId, fields };
                        }
                        return fType;
                    });

                    if (!fieldsetsCustomProps.set) {
                        fieldsets = { ...fieldsets, ...fieldsetsCustomProps };
                    }
                    return { ...fieldsets, type: 'fieldset', grids: getGrids };
                }

                return fieldsets;
            });
            return getFieldsets;
        }
        const actionButtons = sectionFieldsets.filter(fs => fs.is_action_button);
        if (actionButtons.length) {
            sections.push({ id: id, action_buttons: sectionFieldsets, fieldsets: [] });
        } else {
            sections.push({ id: id, fieldsets: sectionFieldsets, action_buttons: [] });
        }
    });
    return sections;
}
