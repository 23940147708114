import _ from 'underscore';
import store from 'store';
import * as FR from '../../Form/FormRules';
import { delayedSetValue } from 'redux/actions/ValueAction';
import { setValidity } from 'redux/actions/ValidityAction';

/**
 * A method that run setTargetTriggerValue method and set new
 *   value to fields based on given lists or target_list_value
 *  triggerValues: TriggerValue[],
 *  key: {string/number} - current field value that's going to be
 *      used as the key to retrive value from target_list_value,
 *  lists: {Object} - lists,
 *  path: string - parent path of the field
 *  updateRedux: bool - to either updated redux state
 *  shouldSetValidity: bool - whether to set redux validity, overriden by updateRedux
 * @param {Object} argument - { triggerValues: Object[], key: string/number, lists: Object }
 *
 * definition: --
 * TriggerValue - {
 *   target_list_id: {string/number},
 *   target_list_value: {Object},
 *   list_id: {string/number},
 * }
 * @returns {Object} { field_id: value, ... }
 */
function setTargetTriggerValues({
    triggerValues,
    key,
    lists = {},
    path,
    updateRedux = true,
    shouldSetValidity = true,
    fields = {},
}) {
    let values = {};

    if (!_.isArray(triggerValues)) {
        triggerValues = [triggerValues];
    }

    triggerValues.forEach(triggerValue => {
        values = Object.assign(
            values,
            setTargetTriggerValue({
                triggerValue,
                key,
                lists,
                path,
                updateRedux,
                shouldSetValidity,
                fields,
            }),
        );
    });

    return values;
}

export default setTargetTriggerValues;

export { setTargetTriggerValue };

/**
 * A method that dispatch and set new value of a
 *   field based on given lists or target_list_value.
 *   Changed field's validity will be checked against
 *   the new value.
 *
 * triggerValue - { target_list_id: string/number, target_list_value: Object, list_id: string/number }
 * key - field value. Will be treated as key then use to map and get new value.
 * lists - { key: value, ... } This will only be used when target_list_value is undefined.
 *   It act as a dictionary to map and get new value for targeted field.
 * path - parent path to the field
 * updateRedux - bool to either updated redux or not
 * @param {Object} argument - { triggerValue: Object, key: string/number, lists: Object, path }
 * @returns {Object} { field_id: value }
 */
function setTargetTriggerValue({
    triggerValue,
    key,
    lists,
    path,
    updateRedux = true,
    shouldSetValidity = true,
    fields = {},
}) {
    let {
        target_list_id: targetListID,
        target_list_value: targetListValue,
        list_id: listID,
        list_value_key: listValueKey,
        list_text_id: listTextId,
    } = triggerValue;
    // get default value from fields object
    const { value: defaultValue } = fields[targetListID] || {};

    if (!targetListValue) {
        targetListValue = lists[listID] || {};
    }

    // when targetListValue: { key: value }
    let targetValue = targetListValue[key] || key;
    targetValue = listValueKey && _.isObject(targetValue) ? targetValue[listValueKey] : targetValue;
    const value = {
        path: path ? `${ path }.${ targetListID }` : targetListID,
        value: targetValue,
    };

    // when targetListValue is [{ title, value }]
    if (listTextId && _.isArray(lists[listTextId])) {
        const found = _.find(lists[listTextId], l => l.value === value.value) || {};
        value.value = found.title;
    } else if (!_.isObject(targetListValue[key])) {
        // reset if listTextId undefined and targetListValue[key] not object
        value.value = targetListValue[key];
    }

    if (updateRedux) {
        store.dispatch(delayedSetValue(value));
        // set validity
        if (shouldSetValidity) {
            const validity = FR.validateInput({ id: targetListID, value: value.value });
            store.dispatch(setValidity({ path: value.path, validity }));
        }
    }
    // if value is null or undefined, and defaultValue is defined
    if (value.value == null && !_.isUndefined(defaultValue)) {
        // fallback to default value
        value.value = defaultValue;
    }

    return {
        [targetListID]: value.value,
    };
}
