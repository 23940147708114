import { compose, withPropsOnChange } from 'recompose';
import _ from 'underscore';
import checkShowOption from './checkShowOption';

// depends props.options and props.isEditable
const withDeprecatedOptions = compose(
    // prepare / update deprecated options whenever isEditable / options updated
    withPropsOnChange(['isEditable', 'options', 'value'], props => {
        const { options, isEditable, value } = props;
        if (!_.isArray(options)) return;
        const deprecatedOptions = options.filter(option => {
            const isSelected = _.isArray(value)
                ? value.includes(option.value)
                : value === option.value;
            const showOption = checkShowOption({ isEditable, option, isSelected });
            return showOption;
        });

        return {
            options: deprecatedOptions,
        };
    }),
);

export default withDeprecatedOptions;

export { checkShowOption };
