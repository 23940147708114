import store from 'store';
import _ from 'underscore';
import * as Selectors from 'redux/selectors';
import { compose, withHandlers, withProps, lifecycle } from 'recompose';
import { setValues, setPrevCustomValues } from 'redux/actions/ValueAction';
import {
    actionSetCampaign,
    actionSetCampaignId,
    actionSetCampaignSlug,
} from 'redux/actions/FormAction';
import { campaignDropdownFieldId, nonCampaignValue } from './misc';

// NOTE: This applicable for field only, campaigns' field
export const withCampaignCheck = compose(
    withHandlers({
        onCampaignChange: () => async (campaignId, campaignSlug) => {
            const { formId, templateId } = Selectors.getForm(store.getState());
            await store.dispatch(actionSetCampaign({ templateId, formId, campaignId }));
            store.dispatch(actionSetCampaignId(campaignId));
            store.dispatch(actionSetCampaignSlug(campaignSlug));
        },
    }),
    withProps(({ isCustomListOptions, options }) => {
        if (_.isUndefined(isCustomListOptions) || _.isArray(options)) return;
        const { listID } = options;
        const { lists } = store.getState().list;
        const filteredOptions = lists[listID].filter(
            option => !isCustomListOptions.includes(option.value),
        );
        return {
            options: filteredOptions,
        };
    }),
    lifecycle({
        async componentDidUpdate(prevProps) {
            const props = this.props;
            const { initialId, value, onCampaignChange } = props;
            const prevValue = prevProps.value;
            const isCampignDropdown = initialId === campaignDropdownFieldId;
            if (!isCampignDropdown) return;
            const isValueEqual = _.isEqual(value, prevValue);
            const selectCampaign = !isValueEqual && value !== nonCampaignValue;
            const selectNonCampaign = !isValueEqual && prevValue && value === nonCampaignValue;
            const changedCampaign = isCampignDropdown && !isValueEqual;
            // Updated the campaign logic by Andrew and moved it here
            if (selectCampaign && changedCampaign) {
                const { activeCampaigns } = store.getState().form;
                const filteredCampaign =
                    activeCampaigns.find(activeCampaign => {
                        return activeCampaign.uid === value;
                    }) || {};

                const campaignSlug = filteredCampaign.slug;
                await onCampaignChange(value, campaignSlug);
                // after cutom_fields is get from endpoints
                const state = store.getState();
                const customDefaultValue = Selectors.getCustomDefaultValue(state);
                const prevCustomDefaultValue = Selectors.getPrevCustomDefaultValue(state) || {};
                const foundPrevCustomVal = {};

                const customDefaultValueChanged =
                    prevCustomDefaultValue && prevCustomDefaultValue !== customDefaultValue;

                if (customDefaultValueChanged) {
                    Object.keys(prevCustomDefaultValue).forEach(function(key) {
                        foundPrevCustomVal[key] = null;
                    });
                }

                store.dispatch(setValues({ ...foundPrevCustomVal, ...customDefaultValue }));
                store.dispatch(setPrevCustomValues(customDefaultValue));
            }

            if (selectNonCampaign) {
                const state = store.getState();
                const prevCustomDefaultValue = Selectors.getPrevCustomDefaultValue(state) || {};
                // call endpoint to render
                onCampaignChange(null);

                // unset values
                const customDefaultVal = {};
                Object.keys(prevCustomDefaultValue).forEach(function(key) {
                    customDefaultVal[key] = null;
                });

                store.dispatch(setValues(customDefaultVal));
            }
        },
    }),
);

export default withCampaignCheck;
