/**
 * A method that generate path based on given string
 *
 * @param {string} start - string path key
 * @param {string} middle - string path that may consist placeholder e.g. ${country}.postcode.{block}
 * @param {string} end - string path key
 * @param {string} variables - { key1: 'my', block: '11A' }
 * @returns {string} path - e.g. start.my.postcode.11A.end
 */
export default function joinListMappingPath(start, middle = '', end, variables = {}) {
    let path = start;
    const placeholders = middle.match(/\$\{([\w]*)\}/g);
    placeholders &&
        placeholders.forEach(placeholder => {
            const key = placeholder.match(/\$\{([\w]*)\}/)[1];
            if (variables[key]) {
                middle = middle.replace(placeholder, variables[key]);
            }
        });

    // append middle if there's middle
    if (middle) {
        if (!path) {
            path = middle;
        } else {
            path += `.${ middle }`;
        }
    }
    // append end if there's end
    if (end) {
        if (!path) {
            path = end;
        } else {
            path += `.${ end }`;
        }
    }

    return path;
}
