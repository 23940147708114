import store from 'store';
import { compose, withPropsOnChange } from 'recompose';
import { replaceValidation } from '../../Misc/forms';
import { getFields } from 'redux/selectors/FieldSelector';

// to be use to enhance MIDTID component
const withMIDTIDReplaceValidityCheck = compose(
    withPropsOnChange(['schema', 'isEditable', 'isRequired'], ({ valuePath, isRequired }) => {
        const fields = getFields(store.getState()) || {};
        // To replace validity for field that has replaceValidation
        const midCustomProps = fields['mid'].custom_properties || {};
        const tidCustomProps = fields['tid'].custom_properties || {};
        const midExtend = midCustomProps.extend || {};
        const tidExtend = tidCustomProps.extend || {};

        const midSchema = replaceValidation({
            extendingProps: midExtend,
            id: 'mid',
            path: valuePath,
        });
        const tidSchema = replaceValidation({
            extendingProps: tidExtend,
            id: 'tid',
            path: valuePath,
        });

        // to return correct isRequired state for MIDTID fields, for label's asterisk
        return {
            isRequired: {
                mid: midSchema ? midSchema.isRequired : isRequired.mid,
                tid: tidSchema ? tidSchema.isRequired : isRequired.tid,
            },
            extend: {
                mid: midExtend,
                tid: tidExtend,
            },
        };
    }),
);

export default withMIDTIDReplaceValidityCheck;
