import { historyAPI } from '../../api/history/HistoryAPI';
import { fieldAPI } from '../../api/template/FieldAPI';
import { setFields } from './FieldAction';
import { actionSetActionForm } from './FormAction';
import { getHistoryForm } from '../selectors/HistorySelector';

export const SET_HISTORY_LIST_FETCHING = 'SET_HISTORY_LIST_FETCHING';

export const setHistoryListFetching = fetching => {
    return {
        type: SET_HISTORY_LIST_FETCHING,
        payload: fetching,
    };
};

export const SET_HISTORY_LIST = 'SET_HISTORY_LIST';

export const setHistoryList = historyList => {
    return {
        type: SET_HISTORY_LIST,
        payload: historyList,
    };
};

export const SET_HISTORY_FORM_VIEWS = 'SET_HISTORY_FORM_VIEWS';

export const setHistoryFormViews = views => {
    return {
        type: SET_HISTORY_FORM_VIEWS,
        payload: views,
    };
};

export const SET_HISTORY_FORM_CURRENT_INDEX = 'SET_HISTORY_FORM_CURRENT_INDEX';

export const setHistoryFormCurrentIndex = index => {
    return {
        type: SET_HISTORY_FORM_CURRENT_INDEX,
        payload: index,
    };
};

export const SET_HISTORY_LIST_ERROR = 'SET_HISTORY_LIST_ERROR';

export const setHistoryListError = error => {
    return {
        type: SET_HISTORY_LIST_ERROR,
        payload: error,
    };
};

export const actionGetHistoryList = ({ templateId, formId }) => {
    return async dispatch => {
        dispatch(setHistoryListFetching(true));
        try {
            const histories = await historyAPI.getHistories({ templateId, formId });
            // fetch also sections
            let fields = (await fieldAPI.getTemplateFields(templateId)) || {};
            fields = processToIdMapProp(fields);
            dispatch(setFields(fields));
            dispatch(setHistoryList(histories));
        } catch (error) {
            dispatch(setHistoryListError(error));
        } finally {
            dispatch(setHistoryListFetching(false));
        }
    };
};

export const actionSetHistoryForm = ({ viewIndex, view }) => {
    return async (dispatch, getState) => {
        const { views = [] } = getHistoryForm(getState());
        views.push(view);
        dispatch(setHistoryFormViews(views));
        dispatch(setHistoryFormCurrentIndex(viewIndex));
        dispatch(actionSetActionForm(view));
    };
};

export const actionClearHistoryForm = () => {
    return async dispatch => {
        dispatch(setHistoryFormViews([]));
        dispatch(setHistoryFormCurrentIndex(-1));
    };
};

/**
 * A method to process fields list to field id map to prop object
 *
 * @param {Object[]} fields - fields list
 * @returns {object} idMapField - { field_id: prop }
 */
function processToIdMapProp(fields = []) {
    const idMapField = {};
    fields.forEach(prop => {
        const { id, custom_properties: customProps } = prop || {};
        const { valueId } = customProps || {};
        idMapField[id] = prop;
        if (valueId && !idMapField[valueId]) {
            idMapField[valueId] = prop;
        }
    });
    return idMapField;
}
