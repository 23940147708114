import BaseAPI from '../BaseAPI';

class FieldAPI extends BaseAPI {
    basePath = 'cap/templates/';

    async getTemplateFields(templateId) {
        const { fields } = await this.get({
            path: `${ this.basePath }${ templateId }/fields/`,
        });
        return fields;
    }

    async getTemplateField({ templateId, fieldId }) {
        const { field } = await this.get({
            path: `${ this.basePath }${ templateId }/fields/${ fieldId }/`,
        });
        return field;
    }
}

export const fieldAPI = new FieldAPI();
