import React from 'react';
import _ from 'underscore';
import { toValidDate } from './misc';
import { withStateHandlers } from 'recompose';

const MIN_YEAR = 1000;
const MAX_YEAR = 9999;

function isInRange({ range, minYear = MIN_YEAR, maxYear = MAX_YEAR }) {
    if (!range.length) return true;
    // e.g. range [1990, 1998], minYear 1997, maxYear 1999, next range [1999, 2007]
    if (
        (minYear <= range[1] && maxYear >= range[1]) ||
        (minYear <= range[0] && maxYear >= range[0]) ||
        (minYear >= range[0] && maxYear <= range[1])
    ) {
        return true;
    }
    return false;
}

const YearRangeColumn = ({ range = [], disabled, selected, onClick }) => {
    const [min, max] = range;
    const classNames = [
        'calendar-column-year',
        disabled ? 'disabled' : '',
        selected ? 'active' : '',
    ];
    if (disabled) {
        onClick = _.noop;
    }

    return (
        <div
            className={classNames.join(' ')}
            onClick={e => {
                onClick(e, { range });
            }}>
            {`${ min } - ${ max }`}
        </div>
    );
};

const rangePerColumn = 9; // 9 years
const YearRangeRows = ({ date, minYear, maxYear, startingYear, onClick }) => {
    const selectedYear = date.getFullYear();
    startingYear = startingYear || selectedYear;
    let min = startingYear - rangePerColumn * 4;
    let max = startingYear + rangePerColumn * 4;
    const yearRange = [];
    if (min < MIN_YEAR) {
        min = MIN_YEAR;
        max = MIN_YEAR + rangePerColumn * 9;
    } else if (max > MAX_YEAR) {
        min = MAX_YEAR - rangePerColumn * 9;
        max = MAX_YEAR;
    }
    for (let i = min; i <= max; i += rangePerColumn) {
        yearRange.push([i, i + rangePerColumn - 1]);
    }

    return (
        <div className="calendar-rows-year">
            {yearRange.map((range, i) => (
                <YearRangeColumn
                    key={i}
                    range={range}
                    disabled={!isInRange({ range, minYear, maxYear })}
                    selected={range[0] <= selectedYear && selectedYear <= range[1]}
                    onClick={onClick}
                />
            ))}
        </div>
    );
};

const CalendarPopUpYearRange = props => {
    const { startingYear, setStartingYear, onClick, minDate, maxDate } = props;
    let { date } = props;
    date = toValidDate(date);
    const selectedYear = startingYear || date.getFullYear();
    const minYear = minDate && minDate.getFullYear();
    const maxYear = maxDate && maxDate.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();

    return (
        <div className="calendar-year-wrapper">
            <div className="popup-top-title border">
                <span
                    onClick={() => {
                        const prevYearRange = selectedYear - rangePerColumn * rangePerColumn;
                        setStartingYear(prevYearRange);
                    }}>
                    <i className="angle left icon"></i>
                </span>
                <span>Year Range</span>
                <span
                    onClick={() => {
                        const nextYearRange = selectedYear + rangePerColumn * rangePerColumn;
                        setStartingYear(nextYearRange);
                    }}>
                    <i className="angle right icon"></i>
                </span>
            </div>
            <YearRangeRows
                date={date}
                minYear={minYear}
                maxYear={maxYear}
                startingYear={startingYear}
                onClick={(e, data) => {
                    onClick && onClick(e, { ...data, month, day, hour, minute });
                }}
            />
        </div>
    );
};

const withRangeHandler = withStateHandlers(() => ({}), {
    setStartingYear: () => startingYear => ({
        startingYear,
    }),
});

export default withRangeHandler(CalendarPopUpYearRange);
