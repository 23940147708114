import store from 'store';
import _ from 'underscore';
import * as FR from '../../../Form/FormRules';
import { getValidators, getValues } from 'redux/selectors';
import { setValidators } from 'redux/actions/ValidatorAction';
import { setValues } from 'redux/actions/ValueAction';
import { setValidities } from 'redux/actions/ValidityAction';
import { actionSetActionForm } from 'redux/actions/FormAction';
import { setUpdateFilters } from 'redux/actions/DashboardAction';
import { setDisabledFields } from 'redux/actions/DisabledAction';

function createWithdrawForm({
    formStatus,
    applicationId,
    fields,
    readOnlyFields,
    withdrawReasons,
    formData,
    templateId,
    uid,
    formId,
    callFormAction,
    setActionForm,
}) {
    const fieldsets = [
        {
            type: 'fieldset',
            grids: [
                {
                    type: 'grid',
                    fields: [
                        {
                            type: 'label',
                            name: 'Application ID [Label]',
                            title: `Application: ${ applicationId }`,
                            id: 'application_id',
                            bold: true,
                        },
                    ],
                },
                {
                    type: 'grid',
                    className: 'decline-form-status',
                    fields: [
                        {
                            type: 'label',
                            name: 'Status [Label]',
                            title: `Status: ${ formStatus }`,
                            id: 'form_status',
                        },
                    ],
                },
            ],
        },
        {
            type: 'fieldset',
            name: 'Break Line',
            grids: [
                {
                    type: 'grid',
                    fields: [
                        {
                            type: 'breakline',
                            id: 'field_rn1519982341530',
                        },
                    ],
                },
            ],
            id: 'fieldset_breakline',
        },
        {
            type: 'fieldset',
            name: 'Withdraw Reason',
            grids: [
                {
                    type: 'grid',
                    fields: [
                        {
                            type: 'select',
                            name: 'Reason for Withdrawal [dropdown]',
                            label: {
                                title: 'Reason for Withdrawal',
                            },
                            options: [
                                ...withdrawReasons,
                                {
                                    value: 'Others',
                                    title: 'Others (please specify)',
                                },
                            ],
                            value: '',
                            id: 'withdraw_reason',
                            placeholder: 'Select One',
                        },
                    ],
                },
                {
                    type: 'grid',
                    fields: [
                        {
                            type: 'textarea',
                            name: 'Withdraw - Comment [Textarea]',
                            label: {
                                title: 'Comment',
                            },
                            value: '',
                            placeholder: 'Comment',
                            id: 'withdraw_comment',
                        },
                    ],
                    id: 'grid_rn1523000557842',
                },
            ],
            id: 'fieldset_withdraw_reason',
        },
    ];

    const readOnlyFieldsets = mapReadOnlyFields({
        fields,
        readOnlyFields,
        formData,
    });

    // Insert readonlyFieldsets below application and form status
    fieldsets.splice(1, 0, ...readOnlyFieldsets);

    const form = {
        views: [
            {
                type: 'view',
                sections: [
                    {
                        type: 'section',
                        title: 'Section Withdraw Application',
                        name: 'Withdraw Section',
                        fieldsets: fieldsets,
                    },
                ],
            },
        ],
    };

    const buttons = [
        {
            title: 'Withdraw',
            name: 'withdraw',
            buttonType: 'danger',
            onClick: () =>
                onWithdraw({
                    templateId,
                    uid,
                    formId,
                    callFormAction,
                    setActionForm,
                    form,
                }),
        },
        {
            title: 'Close',
            name: 'close',
            buttonType: 'secondary',
            onClick: () => {
                store.dispatch(actionSetActionForm({}));
            },
        },
    ];
    return { form, buttons };
}

export default createWithdrawForm;

export {
    clearWithdrawFormValue,
    clearWithdrawFormValidities,
    getValidForm,
    getWithdrawValidations,
    handleWithdrawForm,
    mapReadOnlyFields,
    onWithdraw,
    setWithdrawFormDisabledFields,
    setWithdrawFormValidators,
};

function clearWithdrawFormValue() {
    store.dispatch(
        setValues({
            withdraw_reason: '',
            withdraw_comment: '',
        }),
    );
}

function clearWithdrawFormValidities() {
    store.dispatch(
        setValidities({
            withdraw_reason: null,
            withdraw_comment: null,
        }),
    );
}

function getValidForm(validities) {
    let isValidForm = true;

    Object.values(validities).forEach(records => {
        if (!records.isValid) {
            isValidForm = false;
        }
    });

    return {
        isValidForm,
    };
}

function getWithdrawValidations({ validators, values }) {
    const others = 'others';
    const withdrawReason = values['withdraw_reason'] || '';
    const commentValidation = [{ max: 255 }];

    if (withdrawReason.toLowerCase() === others) {
        commentValidation.push('isRequired');
    }

    return {
        ...validators,
        withdraw_comment: commentValidation,
    };
}

async function handleWithdrawForm({
    isValidForm,
    templateId,
    uid,
    formId,
    callFormAction,
    setActionForm,
    values,
    validities,
}) {
    if (isValidForm) {
        const {
            withdraw_reason: withdrawReason = '',
            withdraw_comment: withdrawComment = '',
        } = values;
        const ok = await callFormAction({
            uid,
            formId,
            templateId,
            data: {
                withdraw_reason: withdrawReason,
                withdraw_comment: withdrawComment,
            },
        });
        if (ok) {
            await store.dispatch(setUpdateFilters(true));
            setActionForm({});
        }
    } else {
        store.dispatch(setValidities(validities));
    }
}

/**
 * mapReadOnlyFields is for generating fields to limit to two columns.
 *
 * @param {Object} fields - list of field objects by id.
 * @param {Object[]} readOnlyFields - list of field ids returned from form action.
 * @param {Object} formData - form data of the row.
 * @returns {Object[]} readOnlyFieldsets - list of fieldsets.
 */
function mapReadOnlyFields({ fields, readOnlyFields, formData }) {
    // Limit the columns to 2
    const maxColumns = 2;
    // split fields into chunks [ [field1, field2], [field3, field4] ]
    const chunkedFields = _.chunk(readOnlyFields, maxColumns);

    const readOnlyFieldsets = [];

    chunkedFields.forEach((fieldsets, fieldsetId) => {
        const grids = [];

        fieldsets.forEach((readOnlyField, index) => {
            const fieldExist = !!fields[readOnlyField] || _.isNull(readOnlyField);
            const fieldConfig = fields[readOnlyField] || {};
            const fieldCustomProps = fieldConfig.custom_properties || {};
            const valueId = fieldCustomProps.valueId || readOnlyField;
            const fieldObj = {
                ...fieldConfig,
                value: formData[valueId] || null,
            };
            if (fieldExist) {
                grids.push({
                    type: 'grid',
                    id: index,
                    fields: [fieldObj],
                });
            }
        });
        const result = {
            type: 'fieldset',
            id: fieldsetId,
            grids: grids,
        };

        if (grids.length) {
            readOnlyFieldsets.push(result);
        }
    });

    return readOnlyFieldsets;
}

async function onWithdraw({ templateId, uid, formId, callFormAction, setActionForm, form }) {
    const values = getValues(store.getState());
    const validators = getValidators(store.getState());

    const withdrawValidators = getWithdrawValidations({
        validators,
        values,
    });

    await store.dispatch(setValidators({ ...withdrawValidators }));

    const { validities } = FR.validateForm({ form });
    const { isValidForm } = getValidForm(validities);

    handleWithdrawForm({
        isValidForm,
        templateId,
        uid,
        formId,
        callFormAction,
        setActionForm,
        values,
        validities,
    });
}

function setWithdrawFormDisabledFields(fields) {
    let disabledFields = {};
    fields.forEach(field => {
        disabledFields = {
            ...disabledFields,
            [field]: true,
        };
    });
    store.dispatch(
        setDisabledFields({
            ...disabledFields,
        }),
    );
}

function setWithdrawFormValidators() {
    store.dispatch(
        setValidators({
            withdraw_reason: ['isRequired'],
        }),
    );
}
