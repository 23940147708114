import { createSelector } from 'reselect';

const triggersSelector = state => state.trigger.triggers;
const triggeredStatesSelector = state => state.trigger.states;
const triggerDependantsSelector = state => state.trigger.dependants;
const triggersByIdsSelector = (state, ids) => {
    const triggers = state.trigger.triggers;
    const filteredTriggers = {};
    Object.entries(triggers).forEach(([id, value]) => {
        const found = ids.indexOf(id) > -1;
        if (found) {
            filteredTriggers[id] = value;
        }
    });
    return filteredTriggers;
};
const triggeredStatesByIdsSelector = (state, ids) => {
    const states = state.trigger.states;
    const filteredStates = {};
    Object.entries(states).forEach(([id, value]) => {
        const found = ids.indexOf(id) > -1;
        if (found) {
            filteredStates[id] = value;
        }
    });
    return filteredStates;
};

export const getTriggers = createSelector(
    triggersSelector,
    triggers => triggers,
);

export const getTriggeredStates = createSelector(
    triggeredStatesSelector,
    states => states,
);

export const getTriggerDependants = createSelector(
    triggerDependantsSelector,
    states => states,
);

export const getTriggersByIds = createSelector(
    triggersByIdsSelector,
    triggers => triggers,
);

export const getTriggeredStatesByIds = createSelector(
    triggeredStatesByIdsSelector,
    states => states,
);
