import _ from 'underscore';
import { delegatedAccessAPI } from '../../api/delegatedAccess/DelegatedAccessAPI';

// Selector
import { getAccesses } from '../selectors/DelegatedAccessSelector';

// Actions
import { setPresetList } from './ListAction';
import { setValues } from './ValueAction';

export const SET_DELEGATED_ACCESS = 'SET_DELEGATED_ACCESS';
export function setDelegatedAccess(id, access) {
    return {
        type: SET_DELEGATED_ACCESS,
        payload: {
            id,
            access,
        },
    };
}

export const SET_DELEGATED_ACCESSES = 'SET_DELEGATED_ACCESSES';
export function setDelegatedAccesses(accesses) {
    return {
        type: SET_DELEGATED_ACCESSES,
        accesses,
    };
}

export const SET_DELEGATED_ACCESSES_FETCHING = 'SET_DELEGATED_ACCESSES_FETCHING';
export const setDelegatedAccessesFetching = fetching => {
    return {
        type: SET_DELEGATED_ACCESSES_FETCHING,
        fetching,
    };
};

export const SET_DELEGATED_ACCESSES_ERROR = 'SET_DELEGATED_ACCESSES_ERROR';
export const setDelegatedAccessesError = error => {
    return {
        type: SET_DELEGATED_ACCESSES_ERROR,
        error,
    };
};

export const SET_DELEGATED_ACCESSES_START_DATE = 'SET_DELEGATED_ACCESSES_START_DATE';
export const setDelegatedAccessStartDate = date => {
    return {
        type: SET_DELEGATED_ACCESSES_START_DATE,
        date,
    };
};

export const SET_ASSIGN_TO_USERS = 'SET_ASSIGN_TO_USERS';
export function setAllowedDelegates(users) {
    return {
        type: SET_ASSIGN_TO_USERS,
        users,
    };
}

export function actionGetAccessesList() {
    return async dispatch => {
        dispatch(setDelegatedAccessesFetching(true));
        try {
            const delegatedAccesses = await delegatedAccessAPI.getDelegatedAccesses();
            dispatch(setDelegatedAccesses(_.indexBy(delegatedAccesses, 'id')));
        } catch (e) {
            dispatch(setDelegatedAccessesError(e));
            throw e.statusText;
        } finally {
            dispatch(setDelegatedAccessesFetching(false));
        }
    };
}

export function actionAddAccess(data) {
    return async (dispatch, getState) => {
        const delegatedAccesses = getAccesses(getState());
        dispatch(setDelegatedAccessesFetching(true));
        try {
            const delegatedAccess = await delegatedAccessAPI.addDelegatedAccess(data);
            const newDelegatedAccesses = {
                [delegatedAccess.id]: delegatedAccess,
                ...delegatedAccesses,
            };
            dispatch(setDelegatedAccesses(newDelegatedAccesses));
        } catch (e) {
            dispatch(setDelegatedAccessesError(e));
            throw e;
        } finally {
            dispatch(setDelegatedAccessesFetching(false));
        }
    };
}

export function actionUpdateAccess(accessId, data) {
    return async dispatch => {
        dispatch(setDelegatedAccessesFetching(true));
        try {
            const delegatedAccess = await delegatedAccessAPI.updateDelegatedAccess(accessId, data);
            dispatch(setDelegatedAccess(accessId, delegatedAccess));
        } catch (e) {
            dispatch(setDelegatedAccessesError(e));
            throw e;
        } finally {
            dispatch(setDelegatedAccessesFetching(false));
        }
    };
}

export function actionDeleteAccess(accessId) {
    return async (dispatch, getState) => {
        const delegatedAccesses = getAccesses(getState());

        dispatch(setDelegatedAccessesFetching(true));
        try {
            await delegatedAccessAPI.deleteDelegatedAccess(accessId);
            delete delegatedAccesses[accessId];
            dispatch(setDelegatedAccesses(delegatedAccesses));
        } catch (e) {
            dispatch(setDelegatedAccessesError(e));
            throw e.statusText;
        } finally {
            dispatch(setDelegatedAccessesFetching(false));
        }
    };
}

export function actionGetAllowedDelegatesByTemplates(templates) {
    return async dispatch => {
        dispatch(setDelegatedAccessesFetching(true));
        try {
            const delegates = await delegatedAccessAPI.getAllowedDelegatesByTemplates(templates);
            const users = delegates.map(user => ({
                value: user.id,
                title: `${ user.name } (${ user.role.description })`,
            }));
            dispatch(setAllowedDelegates(delegates));
            dispatch(setPresetList('users', users));
            dispatch(setValues({ users_list: users }));
        } catch (e) {
            dispatch(setDelegatedAccessesError(e));
            throw e.statusText;
        } finally {
            dispatch(setDelegatedAccessesFetching(false));
        }
    };
}
