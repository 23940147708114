import { SET_TEMPLATE_ROLES } from '../actions/TemplateRoleAction';

const INITIAL_STATE = {
    reducer: 'templateRole',
    roles: [],
};

export default function TemplateRoleReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
    case SET_TEMPLATE_ROLES:
        return {
            ...state,
            roles: action.templateRoles,
        };
    default:
        return state;
    }
}
