import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import classnames from '../../Misc/utils/classnames';
import TreeMenu from '../../TreeMenu';
import TreeMenuToolTip from '../../TreeMenu/TreeMenuToolTip';

const StyledTreeMenu = styled(TreeMenu)`
    .tree-menu-title {
        &:hover {
            .weightage {
                visibility: visible;
                opacity: 1;
                z-index: 1;
            }
        }
    }
`;

const StyledValue = styled.div`
    position: relative;

    .weightage {
        position: absolute;
        top: -3px;
        right: 0;
        min-width: 100px;
        text-align: center;
        z-index: -1;
        visibility: hidden;
        opacity: 0;
        background-color: white;
        border-radius: 5px;
        transition: opacity 0.37s ease-in;
        border: 1px solid #dbdbdb;
        cursor: auto;
        padding: 2px 16px;
        white-space: nowrap;
    }
`;

const Weightage = ({ percent, weight }) => {
    return (
        <StyledValue>
            <div
                className="weightage"
                onClick={e => {
                    e.stopPropagation();
                    return false;
                }}>
                Weightage Score: {weight}
            </div>
            <span className="percent">{percent} %</span>
        </StyledValue>
    );
};

class RiskScoringSummary extends React.Component {
    render() {
        const { id, className, tooltip, value, placeholder, showComponent } = this.props;
        const { children, meta } = value;

        if (!showComponent) return <div />;

        if (!value || !value.children) {
            const title = value && value.title;
            return (
                <div id={id} className={classnames('jpt-input risk-scoring-summary', [className])}>
                    <strong>{title || 'Risk Scoring Summary'}</strong>
                    <div>
                        <i>{placeholder || 'Generate risk score to view summary'}</i>
                    </div>
                </div>
            );
        }
        return (
            <div id={id} className={classnames('jpt-input risk-scoring-summary', [className])}>
                <TreeMenu
                    title={value.title || 'Risk Scoring Summary'}
                    value={tooltip && <TreeMenuToolTip text={tooltip} />}>
                    {children.map(({ title, value, children, meta }, i) => {
                        const weightageScore = meta.weightage_score;
                        return (
                            <StyledTreeMenu
                                key={i}
                                title={title}
                                value={<Weightage percent={value} weight={weightageScore} />}>
                                {children.map(rule => (
                                    <TreeMenu
                                        key={rule.id}
                                        title={rule.title}
                                        value={rule.value}
                                        overrideTotalScoreHighest={
                                            rule.override_total_score_highest
                                        }
                                    />
                                ))}
                            </StyledTreeMenu>
                        );
                    })}
                </TreeMenu>
                <TreeMenu
                    className="total-risk-score"
                    title={<strong>{meta.totalTitle || 'Total Risk Score'}</strong>}
                    value={<strong>{meta.total}</strong>}></TreeMenu>
            </div>
        );
    }
}

RiskScoringSummary.defaultProps = {
    className: null,
    tooltip: null,
    value: {},
    showComponent: true,
};

RiskScoringSummary.propTypes = {
    className: PropTypes.string,
    tooltip: PropTypes.string,
    value: PropTypes.shape({
        title: PropTypes.string,
        value: PropTypes.number,
        children: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string.isRequired,
                value: PropTypes.number.isRequired,
                children: PropTypes.arrayOf(
                    PropTypes.shape({
                        title: PropTypes.string.isRequired,
                        value: PropTypes.number.isRequired,
                        overrideTotalScoreHighest: PropTypes.bool,
                    }),
                ).isRequired,
                meta: PropTypes.shape({
                    weightage_score: PropTypes.number,
                }),
            }),
        ),
        meta: PropTypes.shape({
            total: PropTypes.number,
            totalTitle: PropTypes.string,
        }),
    }),
    showComponent: PropTypes.bool,
};

export default RiskScoringSummary;
