export const isRequiredKey = 'isRequired';
export const isAcceptedKey = 'isAccepted';
export const valueFormatKey = 'valueFormat';
export const minLengthKey = 'minLength';
export const maxLengthKey = 'maxLength';
export const viewKey = 'view';
export const sectionKey = 'section';
export const fieldsetKey = 'fieldset';
export const gridKey = 'grid';
export const textKey = 'text';
export const textWithLabelTop = 'textTop';
export const textWithLabelLeft = 'textLeft';
export const textWithLabelRight = 'textRight';
export const textWithoutLabel = 'textWithoutLabel';
export const textWithLeftInnerLabelKey = 'textWithLeftInnerLabel';
export const textWithRightInnerLabelKey = 'textWithRightInnerLabel';
export const textWithButtonKey = 'textWithButton';
export const labelKey = 'label';
export const noteKey = 'note';
export const selectKey = 'select';
export const selectOptionsKey = 'options';
export const selectWithoutLabel = 'selectWithoutLabel';
export const radioKey = 'radio';
export const radioOptionsKey = 'radios';
export const checkboxKey = 'checkbox';
export const checkboxOptionsKey = 'checkboxes';
export const buttonKey = 'button';
export const uploadButtonKey = 'upload';
export const calendarKey = {
    default: 'calendar',
    calendar: 'calendar',
    datetime: 'datetime',
    date: 'date',
    time: 'time',
    year: 'year',
    monthYear: 'monthYear',
};
export const imageKey = 'image';
export const breakLineKey = 'breakline';
export const textAreaKey = 'textarea';
export const remarksKey = 'remark';
export const hoTableKey = 'hot';
export const hoTableColumnKey = 'hotColumn';
export const duplicableFieldset = 'duplicableFieldset';
export const signatoryKey = 'signatory';
export const acceptanceKey = 'acceptance';
export const gpsKey = 'gps';
export const midtidKey = 'midtid';
export const productTypesKey = 'product_types';
export const mdrKey = 'mdr';
export const productsKey = 'products';
export const standardMDRsKey = 'standard_merchant_rates';
export const terminalConfigurationKey = 'terminal_configuration';
export const dateTimeRangeKey = 'datetime_range';
export const copyKey = 'copy';
export const mdr = {
    offUsCredit: 'off_us_credit',
    offUsDebit: 'off_us_debit',
    offUsIntl: 'off_us_international',
    onUsCredit: 'on_us_credit',
    onUsDebit: 'on_us_debit',
    onUsIntl: 'on_us_international',
    flatRate: 'flat_rate',
    flatRatePercent: 'flat_rate_percent',
};
export const setTypeKey = {
    vertical: 'vertical',
    spreadsheet: 'spreadsheet',
};
