/**
 * A method that receive blob data and headers to generate object
 *   url and download it through clicking a temporary <a> tag link.
 * @param {*} data  - file data
 * @param {*} headers - file headers
 */
export function blobFileDownloadHandler(data, headers) {
    const blob = new Blob([data], { type: headers['content-type'] });
    const disposition = headers['content-disposition'];
    const matches = /"([^"]*)"/.exec(disposition);
    const filename = matches != null && matches[1] ? matches[1] : 'file';

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);

    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
