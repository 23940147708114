import ObjectAssign from 'object-assign';

import { LOAD_PAGE } from '../actions/PageAction';

const INITIAL_STATE = {
    reducer: 'view',
};

export default function LoginReducer(state = {}, action) {
    state = ObjectAssign({}, state, INITIAL_STATE);

    switch (action.type) {
    case LOAD_PAGE: {
        return ObjectAssign({}, state, action.state);
    }

    default:
        return state;
    }
}
