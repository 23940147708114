import {
    SET_LIST_ITEM,
    SET_LIST_ITEMS,
    UPDATE_LIST_ITEM,
    SET_SUCCESS_MESSAGE,
    SET_TABLE_DATA_FETCHING,
    SET_TABLE_DATA,
    SET_ERROR,
    SET_SELECTED_TAB_DETAILS,
    SET_ENTRY_LISTS,
    TOGGLE_CHECKBOX_ENTRY_LISTS,
    SET_FIELD_TYPE,
    SET_SEARCH_INPUT,
    SET_SEARCHED_INPUT,
    SET_PAGE,
    SET_SORTING,
} from '../actions';

const INITIAL_STATE = {
    selectedTab: 'business',
    business: {
        fetching: false,
        error: null,
        page: 1,
        totalPages: 1,
        sorted: [
            {
                id: 'registered_name',
                desc: false,
            },
        ],
        entryLists: {},
        entryListsOrder: [],
        listItem: {},
        listItems: {},
        listItemsOrder: [],
        selectedEntryLists: {},
        selectedFieldType: 'registered_name',
        searchInput: '',
        searchedInput: null,
        listingReasonsByEntryLists: {},
        sourceByEntryLists: {},
        successMsg: null,
    },
    individual: {
        fetching: false,
        error: null,
        page: 1,
        totalPages: 1,
        sorted: [
            {
                id: 'individual_name',
                desc: false,
            },
        ],
        entryLists: {},
        entryListsOrder: [],
        listItem: {},
        listItems: {},
        listItemsOrder: [],
        selectedEntryLists: {},
        selectedFieldType: 'individual_name',
        searchInput: '',
        searchedInput: null,
        listingReasonsByEntryLists: {},
        sourceByEntryLists: {},
        successMsg: null,
    },
};

export default function CaptureEntryReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
    case SET_LIST_ITEM: {
        const { selectedTab, uid, listItem } = action;
        return {
            ...state,
            [selectedTab]: {
                ...state[selectedTab],
                listItem: {
                    ...state[selectedTab].listItem,
                    [uid]: listItem,
                },
            },
        };
    }

    case SET_LIST_ITEMS: {
        const { selectedTab, listItems, listItemsOrder } = action;
        return {
            ...state,
            [selectedTab]: {
                ...state[selectedTab],
                listItems,
                listItemsOrder,
            },
        };
    }

    case UPDATE_LIST_ITEM: {
        const { selectedTab, uid, listItem } = action;
        return {
            ...state,
            [selectedTab]: {
                ...state[selectedTab],
                listItems: {
                    ...state[selectedTab].listItems,
                    [uid]: listItem,
                },
            },
        };
    }

    case SET_SELECTED_TAB_DETAILS:
        const { selectedTab } = action;
        return {
            ...state,
            selectedTab,
        };

    case SET_ENTRY_LISTS: {
        const { selectedTab, entryLists, entryListsOrder } = action;
        return {
            ...state,
            [selectedTab]: {
                ...state[selectedTab],
                entryLists,
                entryListsOrder,
            },
        };
    }

    case TOGGLE_CHECKBOX_ENTRY_LISTS: {
        const { selectedTab, selectedEntryLists } = action;
        return {
            ...state,
            [selectedTab]: {
                ...state[selectedTab],
                ...selectedEntryLists,
            },
        };
    }

    case SET_FIELD_TYPE: {
        const { selectedTab, selectedFieldType } = action;
        return {
            ...state,
            [selectedTab]: {
                ...state[selectedTab],
                selectedFieldType,
            },
        };
    }

    case SET_SEARCH_INPUT: {
        const { selectedTab, searchInput } = action;
        return {
            ...state,
            [selectedTab]: {
                ...state[selectedTab],
                searchInput,
            },
        };
    }

    case SET_SEARCHED_INPUT: {
        const { selectedTab, searchedInput } = action;
        return {
            ...state,
            [selectedTab]: {
                ...state[selectedTab],
                searchedInput,
            },
        };
    }

    case SET_PAGE: {
        const { selectedTab, page } = action;
        return {
            ...state,
            [selectedTab]: {
                ...state[selectedTab],
                page,
            },
        };
    }

    case SET_SORTING: {
        const { selectedTab, sorted } = action;
        return {
            ...state,
            [selectedTab]: {
                ...state[selectedTab],
                sorted,
            },
        };
    }

    case SET_TABLE_DATA_FETCHING:
        return {
            ...state,
            fetching: action.fetching,
        };

    case SET_ERROR: {
        const { selectedTab, error } = action;
        return {
            ...state,
            [selectedTab]: {
                ...state[selectedTab],
                error,
            },
        };
    }

    case SET_SUCCESS_MESSAGE: {
        const { selectedTab, successMsg } = action;
        return {
            ...state,
            [selectedTab]: {
                ...state[selectedTab],
                successMsg,
            },
        };
    }

    case SET_TABLE_DATA: {
        const {
            totalPages,
            listItems,
            listItemsOrder,
            page,
            selectedTab,
            searchedInput,
        } = action;
        return {
            ...state,
            [selectedTab]: {
                ...state[selectedTab],
                totalPages,
                page,
                listItems,
                listItemsOrder,
                searchedInput,
            },
        };
    }
    default:
        return state;
    }
}
