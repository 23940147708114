import React from 'react';
import { Icon } from 'semantic-ui-react';

import * as IcFeature from '../../../images/features/';

export const getIconFromName = name => {
    let image = null;
    switch (name) {
    case 'add':
        image = IcFeature.IconAdd;
        break;
    case 'remove':
        image = IcFeature.IconRemove;
        break;
    case 'add user':
        image = IcFeature.IconAddUser;
        break;
    case 'edit':
        image = IcFeature.IconEdit;
        break;
    case 'remove user':
        image = IcFeature.IconRemoveUser;
        break;
    case 'stop':
        image = IcFeature.IconHandStop;
        break;
    case 'suspend':
        image = IcFeature.IconHandStop;
        break;
    case 'reset password':
        image = IcFeature.IconPassword;
        break;
    default:
        break;
    }

    return image;
};

export const ActionButton = () => (
    <div className="action__button options">
        <Icon name="options" alt="options" title="options" />
    </div>
);
