import {
    SET_TEMPLATE_DETAIL_FETCHING,
    SET_TEMPLATE_SECTIONS_FETCHING,
    SET_TEMPLATE_VIEWS_FETCHING,
    SET_TEMPLATE_ACCESSES_FETCHING,
    SET_ROLES_FETCHING,
    SET_TEMPLATE_DETAIL_ERROR,
    SET_TEMPLATE_SECTIONS_ERROR,
    SET_TEMPLATE_VIEWS_ERROR,
    SET_TEMPLATE_ACCESSES_ERROR,
    SET_ROLES_ERROR,
    SET_TEMPLATE_DETAIL,

    // SET LIST
    SET_TEMPLATE_SECTIONS,
    SET_TEMPLATE_VIEWS,
    SET_TEMPLATE_ACCESSES,
    SET_ROLES,

    // SET CREATE, UPDATE, DELETE TO A ROW
    SET_TEMPLATE_SECTION,
    SET_TEMPLATE_VIEW,
    SET_TEMPLATE_ACCESS,
    SET_TEMPLATE_STATUSES,
    SET_TEMPLATE_STATUS,
} from '../actions/TemplateDetailAction';

const INITIAL_STATE = {
    fetching: {
        templateDetail: false,
        sections: false,
        views: false,
        accesses: false,
        roles: false,
    },

    error: {
        templateDetail: null,
        sections: null,
        views: null,
        accesses: null,
        roles: null,
    },

    templateDetail: {},
    sections: {},
    views: {},
    accesses: {},
    statuses: {},
    roles: {},
};

export default function TemplateDetailReducer(state = INITIAL_STATE, action) {
    const { payload } = action;

    switch (action.type) {
    case SET_TEMPLATE_DETAIL_FETCHING:
        return {
            ...state,
            fetching: {
                ...state.fetching,
                templateDetail: payload,
            },
        };

    case SET_TEMPLATE_SECTIONS_FETCHING:
        return {
            ...state,
            fetching: {
                ...state.fetching,
                sections: payload,
            },
        };

    case SET_TEMPLATE_VIEWS_FETCHING:
        return {
            ...state,
            fetching: {
                ...state.fetching,
                roles: payload,
            },
        };

    case SET_TEMPLATE_ACCESSES_FETCHING:
        return {
            ...state,
            fetching: {
                ...state.fetching,
                accesses: payload,
            },
        };

    case SET_ROLES_FETCHING:
        return {
            ...state,
            fetching: {
                ...state.fetching,
                templateDetail: payload,
            },
        };

    case SET_TEMPLATE_DETAIL_ERROR:
        return {
            ...state,
            error: {
                ...state.error,
                templateDetail: payload,
            },
        };

    case SET_TEMPLATE_SECTIONS_ERROR:
        return {
            ...state,
            error: {
                ...state.error,
                sections: payload,
            },
        };

    case SET_TEMPLATE_VIEWS_ERROR:
        return {
            ...state,
            error: {
                ...state.error,
                views: payload,
            },
        };

    case SET_TEMPLATE_ACCESSES_ERROR:
        return {
            ...state,
            error: {
                ...state.error,
                accesses: payload,
            },
        };

    case SET_ROLES_ERROR:
        return {
            ...state,
            error: {
                ...state.error,
                roles: payload,
            },
        };

    case SET_TEMPLATE_DETAIL:
        return {
            ...state,
            templateDetail: payload,
        };

    case SET_TEMPLATE_SECTIONS:
        return {
            ...state,
            sections: payload,
        };

    case SET_TEMPLATE_SECTION:
        return {
            ...state,
            sections: {
                ...state.sections,
                ...payload,
            },
        };

    case SET_TEMPLATE_VIEWS:
        return {
            ...state,
            views: payload,
        };

    case SET_TEMPLATE_VIEW:
        return {
            ...state,
            views: {
                ...state.views,
                ...payload,
            },
        };

    case SET_TEMPLATE_ACCESSES:
        return {
            ...state,
            accesses: payload,
        };

    case SET_TEMPLATE_ACCESS:
        return {
            ...state,
            accesses: {
                ...state.accesses,
                ...payload,
            },
        };

    case SET_TEMPLATE_STATUSES:
        return {
            ...state,
            statuses: payload,
        };

    case SET_TEMPLATE_STATUS:
        return {
            ...state,
            statuses: {
                ...state.statuses,
                ...payload,
            },
        };

    case SET_ROLES:
        return {
            ...state,
            roles: payload,
        };

    default:
        return state;
    }
}
