import _ from 'underscore';

/**
 * Remove object keys by checking againts list of suffix param
 *
 * @param {object} object - object
 * @param {string[]} suffix - suffix of ids to be removed
 * @returns {Object} cleanedObject
 */
export function cleanObjectBySuffix(object = {}, suffix = []) {
    if (!_.isArray(object) && !_.isObject(object)) return object;
    if (_.isObject(object)) object = { ...object };
    else object = [...object];

    Object.entries(object).forEach(([key, value]) => {
        const found = _.find(suffix, suf => {
            if (!suf) return;
            const keyLength = key.length;
            const sufLength = suf.length;
            const suffixFirstIndex = keyLength - sufLength;
            return suffixFirstIndex > -1 && key.indexOf(suf) === suffixFirstIndex;
        });

        // remove the particular key
        if (found) {
            delete object[key];
            return;
        }
        // if value is array clean each
        if (_.isArray(value)) {
            value = [...value]; // prevent memory reference issue
            const newValue = value.map(d =>
                _.isObject(d) || _.isArray(d) ? cleanObjectBySuffix(d, suffix) : d,
            );
            object[key] = newValue;
        }
        // if value is object
        else if (_.isObject(value)) {
            value = { ...value }; // prevent memory reference issue
            const newValue = cleanObjectBySuffix(value, suffix);
            object[key] = newValue;
        }
    });

    return object;
}

/**
 * Remove object keys by checking againts list of removingValuesArr param
 *
 * @param {object} object - object
 * @param {any[]} valuesArr - Array of values to be removed
 * @returns {Object} cleanedObject
 */
export function cleanObjectByValue(object = {}, valuesArr = []) {
    if (!_.isArray(object) && !_.isObject(object)) return object;
    if (_.isObject(object)) object = { ...object };
    else object = [...object];

    Object.entries(object).forEach(([key, value]) => {
        let found;
        _.find(valuesArr, removingValue => {
            found = _.isEqual(value, removingValue);
            return found;
        });

        // remove the particular key
        if (found) {
            delete object[key];
            return;
        }
        // if value is array clean each
        if (_.isArray(value)) {
            value = [...value]; // prevent memory reference issue
            const newValue = value.map(d =>
                _.isObject(d) || _.isArray(d) ? cleanObjectByValue(d, valuesArr) : d,
            );
            object[key] = newValue;
        }
        // if value is object
        else if (_.isObject(value)) {
            value = { ...value }; // prevent memory reference issue
            const newValue = cleanObjectByValue(value, valuesArr);
            object[key] = newValue;
        }
    });

    return object;
}
