import React from 'react';

const Label = props => {
    const { className, position, content, children } = props;
    const classNames = [
        'text-label',
        className ? className : '',
        position === 'right' ? 'note-right' : position === 'left' ? 'note-left' : '',
    ];
    return <div className={classNames.join(' ')}>{content || children}</div>;
};

export default Label;
