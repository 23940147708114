import ObjectAssign from 'object-assign';
import ObjectPath from 'object-path';
import ObjectClone from 'clone';

import {
    SET_PAGE_PATH,
    SET_PAGE_PARAMS,
    SET_BREADCRUMBS,
    SET_LOADING_STATE,
    SET_LOCALE,
    SET_PLATFORM,
    SET_IS_ADMIN_PAGE,
    SET_USER_OPTION,
    SET_DEVICE_ONLINE,
} from '../actions/PageAction';

const INITIAL_STATE = {
    platform: {},
    params: {},
    isOnline: true,
};

export default function PageReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
    case SET_PAGE_PATH: {
        return ObjectAssign({}, state, { path: action.path });
    }

    case SET_PAGE_PARAMS: {
        return ObjectAssign({}, state, { params: action.params });
    }

    case SET_BREADCRUMBS: {
        return ObjectAssign({}, state, { breadcrumbs: action.breadcrumbs });
    }

    case SET_LOADING_STATE: {
        return ObjectAssign({}, state, { isLoading: action.isLoading });
    }

    case SET_LOCALE: {
        return ObjectAssign({}, state, { locale: action.locale });
    }

    case SET_PLATFORM: {
        return {
            ...state,
            platform: action.platform,
        };
    }

    case SET_IS_ADMIN_PAGE: {
        return {
            ...state,
            admin: action.isAdminPage,
        };
    }

    // Page
    case SET_USER_OPTION: {
        const currentState = ObjectClone(state);
        ObjectPath.set(
            currentState,
            action.path ? 'user.' + action.path : 'user',
            action.option,
        );

        return ObjectAssign({}, currentState);
    }

    case SET_DEVICE_ONLINE:
        return {
            ...state,
            isOnline: action.isOnline,
        };

    default:
        return state;
    }
}
