import React from 'react';
import _ from 'underscore';
import moment from 'moment-timezone';
import { toValidDate, HOURS, isDatetimeInRange } from './misc';
import { getHours, getMinutes, getDate, getFullYear, getMonth } from '../../Misc/datetime';

const HourColumn = ({ hour, disabled, selected, onClick }) => {
    const { name, isDisabled } = hour;
    const classNames = [
        'calendar-column-hour',
        disabled || isDisabled ? 'disabled' : '',
        selected ? 'active' : '',
    ];
    if (name) {
        hour = name;
    }
    if (disabled || isDisabled) {
        onClick = _.noop;
    }

    return (
        <div
            className={classNames.join(' ')}
            role="button"
            onClick={e => {
                onClick(e, { hour });
            }}>
            {`${hour}:00`}
        </div>
    );
};

const HourRows = ({ date, minDate, maxDate, timezone, onClick }) => {
    const selectedHour = getHours(date, timezone);
    const hourClock = 24;

    return (
        <div className="calendar-rows-hour">
            {HOURS[hourClock].map((hour, i) => {
                const currentDate = moment(date)
                    .set({
                        hour: hour,
                    })
                    .toDate();
                return (
                    <HourColumn
                        key={i}
                        hour={hour}
                        disabled={
                            !isDatetimeInRange({
                                date: currentDate,
                                minDate,
                                maxDate,
                                type: 'hours',
                            })
                        }
                        index={i}
                        selected={selectedHour === hour}
                        onClick={onClick}
                    />
                );
            })}
        </div>
    );
};

const CalendarPopUpHour = props => {
    const { minDate, maxDate, timezone, onClick } = props;
    let { date } = props;
    date = toValidDate(date);
    const month = getMonth(date, timezone);
    const year = getFullYear(date, timezone);
    const day = getDate(date, timezone);
    const minute = getMinutes(date, timezone);

    return (
        <div className="calendar-hour-wrapper">
            <div className="popup-top-title border">Time</div>
            <HourRows
                date={date}
                maxDate={maxDate}
                minDate={minDate}
                timezone={timezone}
                onClick={(e, data) => {
                    onClick &&
                        onClick(e, {
                            year,
                            month,
                            day,
                            minute,
                            ...data,
                        });
                }}
            />
        </div>
    );
};

export default CalendarPopUpHour;
