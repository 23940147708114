import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import ObjectPath from 'object-path';
import Label from '../Label';
import InputWithNote from '../InputWithNote';
import Dropdown from '../Dropdown';

import { compose, lifecycle, withProps } from 'recompose';
import store from '../../../store';
import { setValue } from '../../../redux/actions/ValueAction';
import { getValues } from '../../../redux/selectors';
import { isTriggered, standardMDRsListKey } from '../FormRules';
import { getLists } from '../../../redux/selectors/ListsSelector';
import { processLabel } from './misc';

const LABEL_ID = 'label';
export const CHANNEL_ID = 'payment_channel';
export const NETWORK_ID = 'network';
export const OFF_US_CREDIT = 'off_us_credit';
export const ON_US_CREDIT = 'on_us_credit';
export const OFF_US_DEBIT = 'off_us_debit';
export const ON_US_DEBIT = 'on_us_debit';
export const OFF_US_INTL = 'off_us_international';
export const ON_US_INTL = 'on_us_international';
export const FLAT_RATE = 'flat_rate';
export const FLAT_PERCENT = 'flat_rate_percent';
export const CURRENCY = 'currency';
export const PRODUCT_ID = 'product';

const ProductMDR = ({
    initialId,
    label,
    setPath,
    tabPath,
    index,
    bold,
    value,
    valueId,
    validity,
    trigger,
    triggeredStates,
    isEditable,
    mdrsValueMapObject,
    isRequired,
    pairedMdrs = [],
}) => {
    const channelValidity = validity[CHANNEL_ID] || {};
    const networkValidity = validity[NETWORK_ID] || {};

    const channelTrigger = trigger[CHANNEL_ID] || {};
    const networkTrigger = trigger[NETWORK_ID] || {};

    if (_.isBoolean(isEditable)) {
        const temp = isEditable;
        isEditable = {};
        // when isEditable is boolean, map it to object
        Object.keys(mdrsValueMapObject).forEach(mdr => {
            isEditable[mdr] = temp;
        });
    }

    const isChannelRequired = isRequired[CHANNEL_ID];
    const isNetworkRequired = isRequired[NETWORK_ID];
    const channelLabel = processLabel({
        label: { title: mdrsValueMapObject[CHANNEL_ID].title },
        isRequired: isChannelRequired,
        isEditable: isEditable[CHANNEL_ID],
    });
    const networkLabel = processLabel({
        label: { title: mdrsValueMapObject[NETWORK_ID].title },
        isRequired: isNetworkRequired,
        isEditable: isEditable[NETWORK_ID],
    });

    // to get the id that's pointed to the value
    valueId = valueId || initialId;

    return (
        <div className="product">
            <div className="product--label-mdr">
                <Label title={label.title} id={LABEL_ID} bold={bold} />
            </div>
            <div className="product--channel">
                <Dropdown
                    label={channelLabel}
                    placeholder={mdrsValueMapObject[CHANNEL_ID].title}
                    setId={valueId}
                    tabId={initialId}
                    setPath={setPath}
                    tabPath={tabPath}
                    tabIndex={index}
                    id={CHANNEL_ID}
                    initialId={CHANNEL_ID}
                    value={value[CHANNEL_ID]}
                    isEditable={isEditable[CHANNEL_ID]}
                    isValid={channelValidity.isValid}
                    errorMessage={channelValidity.errorMessage}
                    trigger={channelTrigger.data}
                    triggeredState={triggeredStates[CHANNEL_ID]}
                    showComponent={!isTriggered(triggeredStates[CHANNEL_ID])}
                />
            </div>
            <div className="product--network">
                <Dropdown
                    label={networkLabel}
                    placeholder={mdrsValueMapObject[NETWORK_ID].title}
                    setId={valueId}
                    tabId={initialId}
                    setPath={setPath}
                    tabPath={tabPath}
                    tabIndex={index}
                    id={NETWORK_ID}
                    initialId={NETWORK_ID}
                    value={value[NETWORK_ID]}
                    isEditable={isEditable[NETWORK_ID]}
                    isValid={networkValidity.isValid}
                    errorMessage={networkValidity.errorMessage}
                    trigger={networkTrigger.data}
                    triggeredState={triggeredStates[NETWORK_ID]}
                    showComponent={!isTriggered(triggeredStates[NETWORK_ID])}
                />
            </div>

            <div className="product--rates">
                {pairedMdrs.map((mdrs, i) => (
                    <div className="product--rates-set" key={'mdrs_' + i}>
                        <>
                            {mdrs[0] ? (
                                <div className="product--rate-left">
                                    <InputWithNote
                                        label={processLabel({
                                            label: { title: mdrsValueMapObject[mdrs[0]].title },
                                            isRequired: isRequired[mdrs[0]],
                                            isEditable: isEditable[mdrs[0]],
                                        })}
                                        placeholder={mdrsValueMapObject[mdrs[0]].title}
                                        note={
                                            !mdrsValueMapObject[mdrs[0]].currency
                                                ? { title: '%' }
                                                : {
                                                    title:
                                                          mdrsValueMapObject[CURRENCY].title ||
                                                          'RM',
                                                    position: 'left',
                                                }
                                        }
                                        setId={valueId}
                                        tabId={initialId}
                                        setPath={setPath}
                                        tabPath={tabPath}
                                        tabIndex={index}
                                        id={mdrs[0]}
                                        initialId={mdrs[0]}
                                        value={value[mdrs[0]]}
                                        isEditable={isEditable[mdrs[0]]}
                                        isValid={validity[mdrs[0]] && validity[mdrs[0]].isValid}
                                        errorMessage={
                                            validity[mdrs[0]] && validity[mdrs[0]].errorMessage
                                        }
                                        trigger={trigger[mdrs[0]] && trigger[mdrs[0]].data}
                                        triggeredState={triggeredStates[mdrs[0]]}
                                        showComponent={!isTriggered(triggeredStates[mdrs[0]])}
                                    />
                                </div>
                            ) : (
                                <div className="product--empty" />
                            )}
                            {mdrs[1] ? (
                                <div className="product--rate-right">
                                    <InputWithNote
                                        label={processLabel({
                                            label: { title: mdrsValueMapObject[mdrs[1]].title },
                                            isRequired: isRequired[mdrs[1]],
                                            isEditable: isEditable[mdrs[1]],
                                        })}
                                        placeholder={mdrsValueMapObject[mdrs[1]].title}
                                        note={
                                            !mdrsValueMapObject[mdrs[1]].currency
                                                ? { title: '%' }
                                                : {
                                                    title:
                                                          mdrsValueMapObject[CURRENCY].title ||
                                                          'RM',
                                                    position: 'left',
                                                }
                                        }
                                        setId={valueId}
                                        tabId={initialId}
                                        setPath={setPath}
                                        tabPath={tabPath}
                                        tabIndex={index}
                                        id={mdrs[1]}
                                        initialId={mdrs[1]}
                                        value={value[mdrs[1]]}
                                        isEditable={isEditable[mdrs[1]]}
                                        isValid={validity[mdrs[1]] && validity[mdrs[1]].isValid}
                                        errorMessage={
                                            validity[mdrs[1]] && validity[mdrs[1]].errorMessage
                                        }
                                        trigger={trigger[mdrs[1]] && trigger[mdrs[1]].data}
                                        triggeredState={triggeredStates[mdrs[1]]}
                                        showComponent={!isTriggered(triggeredStates[mdrs[1]])}
                                    />
                                </div>
                            ) : (
                                <div className="product--empty" />
                            )}
                        </>
                    </div>
                ))}
            </div>
        </div>
    );
};

ProductMDR.propTypes = {
    id: PropTypes.string,
    label: PropTypes.object,
    value: PropTypes.object,
    validity: PropTypes.object,
    triggers: PropTypes.object,
    triggeredStates: PropTypes.object,
    product: PropTypes.string.isRequired,
    isEditable: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    isRequired: PropTypes.object,
};

ProductMDR.defaultProps = {
    label: {},
    value: {},
    validity: {},
    triggers: {},
    triggeredStates: {},
    isEditable: true,
    isRequired: {},
    mdrs: [],
};

export default compose(
    /**
     * To generate mdrsValueMapObject props
     *  from:-
     *  mdrs : [{ title: 'Off-us (Int'l)', value: 'off_us_international' }, ...mdr]
     *
     *  to:-
     *  mdrsValueMapObject: { off_us_international: 'Off-us (Int'l)', ... }
     */
    withProps(({ mdrs }) => {
        const mdrsArr = getLists(store.getState())[standardMDRsListKey] || [];
        const mdrsValueMapObject =
            _.reduce(mdrsArr, (mdr = {}, current, num) => {
                // first item will be object, key value map title
                //  coulnd't be spread like the rest
                if (num === 1) {
                    return {
                        [mdr.value]: mdr,
                        [current.value]: current,
                    };
                }

                return {
                    ...mdr,
                    [current.value]: current,
                };
            }) || {};
        const pairedMdrs = getPairedMdrs(mdrs);
        return {
            mdrsValueMapObject,
            pairedMdrs,
        };
    }),
    lifecycle({
        componentDidMount() {
            const { setPath, product } = this.props;
            const path = `${ setPath }.${ PRODUCT_ID }`;
            const values = getValues(store.getState());
            const productValue = ObjectPath.get(values, path, null);

            if (!productValue) {
                store.dispatch(
                    setValue({
                        path,
                        value: product,
                    }),
                );
            }
        },
    }),
)(ProductMDR);

/**
 * To generate list of paired non standard mdr field [[mdr1, mdr2], [mdr3, mdr4], [mdr5]]
 *
 * @param {Array} mdrs - list of mdr field object
 * @returns {any} list - false or [][]
 */
export function getPairedMdrs(mdrs = []) {
    const list = [];
    for (let i = 0; i <= mdrs.length && mdrs[i] !== undefined; i += 2) {
        const pair = [];
        // only undefined check, null will be displayed as empty space
        pair.push(mdrs[i]);
        if (mdrs[i + 1] !== undefined) pair.push(mdrs[i + 1]);
        list.push(pair);
    }
    return !!list.length && list;
}

/**
 * A method that return object { [key]: boolean } if it's boolean, else return boolean param itself
 * @param {bool} boolean - truth value or object
 * @param {string} key - key of the object to be created
 * @returns {any} any
 */
export function getBooleanObject(boolean, key) {
    if (_.isBoolean(boolean)) return { [key]: boolean };
    return boolean;
}
