import { createSelector } from 'reselect';
import { RootState } from 'store';

const formSelector = (state: RootState) => state.form;
const isCreatingFormSelector = (state: RootState) => state.form.isCreatingForm;
const downloadingFilesSelector = (state: RootState) => state.form.downloadingFiles;
const formRuleSelector = (state: RootState) => state.form.rule;

export const getForm = createSelector(formSelector, form => form);

export const getIsCreatingForm = createSelector(
    isCreatingFormSelector,
    isCreatingForm => isCreatingForm,
);

export const getDownloadingFiles = createSelector(
    downloadingFilesSelector,
    downloadingFiles => downloadingFiles,
);

export const getFormRule = createSelector(formRuleSelector, rule => rule);
