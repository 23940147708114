import BaseAPI from '../BaseAPI';
import { isoFormatMapper, isoFormatObj } from '../../components/Misc';

class ConfigurationAPI extends BaseAPI {
    baseParameterPath = 'cap/parameters/';
    baseRolePath = 'cap/roles/';

    async getParameterList() {
        const path = this.baseParameterPath;
        const { parameters } = await this.get({
            path,
        });
        return isoFormatMapper(parameters);
    }

    async createParameter(data) {
        const path = this.baseParameterPath;
        const { parameter } = await this.post({
            path,
            data,
        });
        return isoFormatObj(parameter);
    }

    async getParameter(id) {
        const path = `${ this.baseParameterPath }${ id }/`;

        const { parameter } = await this.get({ path });
        return isoFormatObj(parameter);
    }

    async updateParameter(id, data) {
        const path = `${ this.baseParameterPath }${ id }/`;

        const { parameter } = await this.post({
            path,
            data,
        });
        return isoFormatObj(parameter);
    }

    async deleteParameter(id) {
        const path = `${ this.baseParameterPath }${ id }/`;

        return await this.delete({ path });
    }

    async getRoleList() {
        const path = this.baseRolePath;
        const { roles } = await this.get({
            path,
        });
        const formatedRoles = isoFormatMapper(roles);
        return formatedRoles;
    }

    async createRole(data) {
        const path = this.baseRolePath;
        const { role } = await this.post({
            path,
            data,
        });
        return isoFormatObj(role);
    }

    async getRole(id) {
        const path = `${ this.baseRolePath }${ id }/`;

        const { role } = await this.get({ path });
        return isoFormatObj(role);
    }

    async updateRole(id, data) {
        let role;
        const path = `${ this.baseRolePath }${ id }/`;

        try {
            ({ role } = await this.post({ path, data }));
        } catch (e) {
            throw e.responseJSON;
        }
        return isoFormatObj(role);
    }

    async deleteRole(id) {
        const path = `${ this.baseRolePath }${ id }/`;

        try {
            return await this.delete({
                path,
            });
        } catch (e) {
            throw e.responseJSON;
        }
    }
}

export default ConfigurationAPI;
