import React from 'react';
import ReactDOM from 'react-dom';
import i18next from '../locales/i18n';

import { compose, withProps, withHandlers, lifecycle } from 'recompose';
import { connect } from 'react-redux';
import { setLocale } from '../redux/actions/PageAction';

const Locale = ({ handleDropdownClick }) => {
    return <div className="language item-group item-group--right" onClick={handleDropdownClick} />;
};

export const i18n = i18next;
export default compose(
    connect(
        null,
        dispatch => {
            return {
                setLocale: locale => {
                    dispatch(setLocale(locale));
                },
            };
        },
    ),
    withHandlers(() => {
        let dropdown = null;

        return {
            onRef: () => ref => (dropdown = ref),
            me: () => () => dropdown,
            handleDropdownClick: () => () => {
                const dom = ReactDOM.findDOMNode(dropdown);
                dom && dom.click();
            },
        };
    }),
    withProps(() => {
        return {
            selectedLng: i18next.language,
        };
    }),
    lifecycle({
        componentDidMount() {
            i18next.on('languageChanged', lng => {
                this.setState({
                    selectedLng: lng,
                });
                this.props.setLocale(lng);
            });
        },
        componentWillUnmount() {
            i18next.off('languageChanged');
        },
    }),
)(Locale);
