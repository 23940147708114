import _ from 'underscore';
import store from 'store';
import * as Selectors from 'redux/selectors';
import { compose, withPropsOnChange } from 'recompose';

// NOTE: This applicable for field that has set custom_properties.show_active_campaigns to `true`
const withSetActiveCampaignsToDropdown = compose(
    withPropsOnChange(['show_active_campaigns'], ({ show_active_campaigns, isEditable }) => {
        const { activeCampaigns } = Selectors.getForm(store.getState());
        if (_.isUndefined(show_active_campaigns)) return;

        const formatActiveCampaigns = activeCampaigns.reduce((campaignArrList, campaign) => {
            if (!isEditable || (isEditable && campaign.is_selectable)) {
                campaignArrList.push({
                    name: campaign.name,
                    title: campaign.name,
                    value: campaign.uid,
                });
            }
            return campaignArrList;
        }, []);

        return {
            options: formatActiveCampaigns,
        };
    }),
);

export default withSetActiveCampaignsToDropdown;
