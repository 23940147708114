import { startLoading, stopLoading, actionClearSession } from './PageAction';
import { actionLogout } from './AuthenticationAction';
import { goToLogin } from '../../components/GoToPage';
import { ERROR_MESSAGES, MESSAGES, STATUS_CODE } from '../../components/Misc/api';
import { showSuccessfulMessage } from '../../components/Misc/forms/popUps';
import UserAPI from '../../api/user/UserAPI';
import { roleAPI } from '../../api/role/RoleAPI';
import { groupAPI } from '../../api/group/GroupAPI';
import _ from 'underscore';

const userAPI = new UserAPI();

export const SET_ERROR = 'SET_ERROR';
export const setError = error => {
    return {
        type: SET_ERROR,
        error,
    };
};

export const GET_ROLES = 'GET_ROLES';
export const getRoles = roles => {
    return {
        type: GET_ROLES,
        roles,
    };
};

export const GET_GROUPS = 'GET_GROUPS';
export const getGroups = groups => {
    return {
        type: GET_GROUPS,
        groups,
    };
};

export const GET_USERS = 'GET_USERS';
function getUsers(users) {
    return {
        type: GET_USERS,
        users,
    };
}

export const GET_ACTION_CONFIGURATIONS = 'GET_ACTION_CONFIGURATIONS';
function getActionConfigs(actionConfigs) {
    return {
        type: GET_ACTION_CONFIGURATIONS,
        actionConfigs,
    };
}

export const GET_CONTEXT = 'GET_CONTEXT';
function getContext(context) {
    return {
        type: GET_CONTEXT,
        context,
    };
}

export const SET_USER = 'SET_USER';
export const setUser = users => {
    return {
        type: SET_USER,
        users,
    };
};

export const SET_USER_FEATURE = 'SET_USER_FEATURE';
function setMyFeature(features) {
    return {
        type: SET_USER_FEATURE,
        features,
    };
}

export const SET_USER_FEATURE_GROUP = 'SET_USER_FEATURE_GROUP';
function setMyFeatureGroup(featureGroups) {
    return {
        type: SET_USER_FEATURE_GROUP,
        featureGroups,
    };
}

export const SET_USER_DEFAULT_FEATURE = 'SET_USER_DEFAULT_FEATURE';
function setMyDefaultFeature(defaultFeature) {
    return {
        type: SET_USER_DEFAULT_FEATURE,
        defaultFeature,
    };
}

export const SET_USER_ROLE = 'SET_USER_ROLE';
function setMyRole(role) {
    return {
        type: SET_USER_ROLE,
        role,
    };
}

export const SET_IS_USER_EDITABLE = 'SET_IS_USER_EDITABLE';
export function setMyIsEditable(isUserEditable) {
    return {
        type: SET_IS_USER_EDITABLE,
        isUserEditable,
    };
}

export const SET_MY_CLIENT = 'SET_MY_CLIENT';
export function setMyClient(client) {
    return {
        type: SET_MY_CLIENT,
        client,
    };
}

export function loadMyFeature() {
    return async dispatch => {
        const { user } = await userAPI.getMe();
        const { client, default_feature, feature_groups, features, role, is_editable } = user;
        const defaultFeature = features[default_feature] || {};
        dispatch(setMyIsEditable(is_editable));
        dispatch(setMyRole(role));
        dispatch(setMyClient(client));
        dispatch(setMyFeature(Object.values(features)));
        dispatch(setMyFeatureGroup(feature_groups));
        dispatch(setMyDefaultFeature(defaultFeature));
    };
}

/** ACTIONS function */

export const actionGetUsers = () => {
    return async dispatch => {
        dispatch(startLoading());
        try {
            const { users, action_configurations, context } = await userAPI.getUsers();
            dispatch(getUsers(_.indexBy(users, 'username')));
            dispatch(getActionConfigs(action_configurations));
            dispatch(getContext(context));
        } catch (e) {
            dispatch(setError(e));
        } finally {
            dispatch(stopLoading());
        }
    };
};

export const actionGetRoles = () => {
    return async dispatch => {
        // TODO until the loading states of roles and users are separate,
        // prevent this one from activating loader, because even whilst user
        // is still loading, this will prematurely turn loader off.
        // dispatch(startLoading());
        try {
            const roles = await roleAPI.getRoles();
            dispatch(getRoles(roles));
        } catch (e) {
            dispatch(setError(e));
        } finally {
            // dispatch(stopLoading());
        }
    };
};

export const actionGetGroups = () => {
    return async dispatch => {
        // TODO until the loading states of groups and users are separate,
        // prevent this one from activating loader, because even whilst user
        // is still loading, this will prematurely turn loader off.
        // dispatch(startLoading());
        try {
            const groups = await groupAPI.getGroups();
            dispatch(getGroups(groups));
        } catch (e) {
            dispatch(setError(e));
        } finally {
            // dispatch(stopLoading());
        }
    };
};

export const actionCheckUser = username => {
    return async dispatch => {
        dispatch(startLoading());

        try {
            const { status_cd, status_desc } = await userAPI.checkUsername(username);

            return {
                status_cd,
                status_desc,
            };
        } catch (e) {
            const { status_cd, status_desc } = e.responseJSON || {};

            return {
                status_cd,
                status_desc,
            };
        } finally {
            dispatch(stopLoading());
        }
    };
};

export function handleSetNewUser(users, newUser) {
    users = {
        [newUser.username]: newUser,
        ...users,
    };
    return users;
}

export const actionAddUser = user => {
    return async (dispatch, getState) => {
        dispatch(startLoading());
        const { users } = getState().user;

        try {
            const response = await userAPI.addUser({
                ...user,
                confirm_create: true,
            });
            const { status_cd, status_desc, user: newUser } = response;

            const modifiedUsers = handleSetNewUser(users, newUser);
            dispatch(setUser(modifiedUsers));

            return {
                status_cd,
                status_desc,
                user: newUser,
            };
        } catch (e) {
            const { status_cd: statusCode, status_desc: statusDesc } = e.responseJSON || {};
            let errorMessages = ERROR_MESSAGES[statusCode];

            if (statusCode === STATUS_CODE.USER_WEAK_PASSWORD) {
                return {
                    status_cd: statusCode,
                    status_desc: statusDesc,
                };
            }

            if (!errorMessages) {
                errorMessages = ERROR_MESSAGES.default;
            }
            dispatch(setError(errorMessages));

            return {
                status_cd: statusCode,
                status_desc: statusDesc,
            };
        } finally {
            dispatch(stopLoading());
        }
    };
};

export const actionUpdateUser = (username, user) => {
    return async (dispatch, getState) => {
        dispatch(startLoading());
        const { users } = getState().user;

        try {
            const currentUser = users[username];
            const response = await userAPI.updateUser(username, user);
            const { status_cd: statusCode, status_desc: statusDesc, user: updatedUser } = response;
            users[username] = { ...currentUser, username, ...updatedUser };
            dispatch(setUser(users));

            return {
                status_cd: statusCode,
                status_desc: statusDesc,
                user: updatedUser,
            };
        } catch (e) {
            const { status_cd: statusCode, status_desc: statusDesc } = e.responseJSON || {};
            let errorMessages = ERROR_MESSAGES[statusCode];

            if (!errorMessages) {
                errorMessages = ERROR_MESSAGES.default;
            }

            dispatch(setError(errorMessages));

            return {
                status_cd: statusCode,
                status_desc: statusDesc,
            };
        } finally {
            dispatch(stopLoading());
        }
    };
};

export const actionDeleteUser = username => {
    return async (dispatch, getState) => {
        dispatch(startLoading());
        const { users } = getState().user;
        try {
            const response = await userAPI.deleteUser(username);
            const { status_cd: statusCode, status_desc: statusDesc } = response;

            delete users[username];
            dispatch(setUser(users));

            return {
                status_cd: statusCode,
                status_desc: statusDesc,
            };
        } catch (e) {
            const { status_cd: statusCode, status_desc: statusDesc, users = [] } =
                e.responseJSON || {};

            let errorMessages = ERROR_MESSAGES[statusCode];

            if (!errorMessages) {
                errorMessages = ERROR_MESSAGES.default;
            }

            if (statusCode !== STATUS_CODE.USER_NEEDS_REASSIGN) {
                dispatch(setError(errorMessages));
            }

            return {
                status_cd: statusCode,
                status_desc: statusDesc,
                users,
            };
        } finally {
            dispatch(stopLoading());
        }
    };
};

export const actionReassignUser = ({ currentUser, reassignObj }) => {
    return async (dispatch, getState) => {
        dispatch(startLoading());
        const { users } = getState().user;
        try {
            const { status_cd: statusCode, status_desc: statusDesc } = await userAPI.reassignUser({
                currentUser,
                reassignObj,
            });

            delete users[currentUser];
            dispatch(setUser(users));

            return {
                status_cd: statusCode,
                status_desc: statusDesc,
            };
        } catch (e) {
            const { status_cd: statusCode } = e.responseJSON || {};

            let errorMessages = ERROR_MESSAGES[statusCode];

            if (!errorMessages) {
                errorMessages = ERROR_MESSAGES.default;
            }

            dispatch(setError(errorMessages));
        } finally {
            dispatch(stopLoading());
        }
    };
};

export const actionUpdateUserStatus = ({ username, status }) => {
    return async (dispatch, getState) => {
        dispatch(startLoading());
        const { users } = getState().user;
        try {
            const currentUser = users[username];
            const response = await userAPI.updateUserStatus({ username, status });
            const { status_cd: statusCode, status_desc: statusDec, user } = response;
            users[username] = { ...currentUser, username, ...user };
            dispatch(setUser(users));

            return {
                status_cd: statusCode,
                status_desc: statusDec,
            };
        } catch (e) {
            const { status_cd: statusCode, status_desc: statusDec } = e.responseJSON || {};

            let errorMessages = ERROR_MESSAGES[statusCode];

            if (statusCode === STATUS_CODE.USER_CANT_SET_DORMANT) {
                errorMessages = statusDec;
            }

            if (!errorMessages) {
                errorMessages = 'Error has occurred';
            }

            dispatch(setError(errorMessages));

            return {
                status_cd: statusCode,
                status_desc: statusDec,
            };
        } finally {
            dispatch(stopLoading());
        }
    };
};

export const actionForgotPassword = ({ username, email }) => {
    return async dispatch => {
        dispatch(startLoading());
        try {
            await userAPI.forgotPassword({ username, email });

            dispatch(
                showSuccessfulMessage({
                    message: MESSAGES.RECOVERY_LINK,
                    onConfirm: () => {
                        // go back to login page
                        dispatch(goToLogin());
                    },
                }),
            );
        } catch (e) {
            let errorMessages = e;

            if (e.status_desc) {
                errorMessages = e.status_desc;
            }
            dispatch(setError(errorMessages));
        } finally {
            dispatch(stopLoading());
        }
    };
};

export const actionChangeUserPassword = ({ oldPassword, newPassword }) => {
    return async dispatch => {
        dispatch(startLoading());
        try {
            await userAPI.changeUserPassword({
                oldPassword,
                newPassword,
            });

            dispatch(
                showSuccessfulMessage({
                    message: MESSAGES.CHANGED_PASSWORD,
                    onConfirm: () => {
                        dispatch(actionLogout());
                        dispatch(actionClearSession());
                    },
                }),
            );
        } catch (e) {
            let errorMessages = e;

            if (e.status_desc) {
                errorMessages = e.status_desc;
            }
            dispatch(setError(errorMessages));
        } finally {
            dispatch(stopLoading());
        }
    };
};

export const actionResetUserPassword = ({ newPassword, token }) => {
    return async dispatch => {
        dispatch(startLoading());
        try {
            await userAPI.resetUserPassword({ newPassword, token });
            dispatch(
                showSuccessfulMessage({
                    message: MESSAGES.RESET_PASSWORD,
                    onConfirm: () => {
                        dispatch(goToLogin());
                    },
                }),
            );
        } catch (e) {
            let errorMessages = e;

            if (e.status_desc) {
                errorMessages = e.status_desc;
            }
            dispatch(setError(errorMessages));
        } finally {
            dispatch(stopLoading());
        }
    };
};

export const handleAdminResetPasswordError = e => {
    const { status_desc: statusDesc } = e;
    let errorMessages = ERROR_MESSAGES.INVALID_PASSWORD;

    if (statusDesc && !_.isObject(statusDesc)) {
        errorMessages = statusDesc;
    }
    return errorMessages;
};

export const actionAdminResetPassword = (userId, data) => {
    return async dispatch => {
        dispatch(startLoading());
        try {
            const response = await userAPI.adminResetPassword(userId, data);
            const { status_cd, status_desc } = response;
            return {
                status_cd,
                status_desc,
            };
        } catch (e) {
            const { status_cd } = e;
            const errorMessages = handleAdminResetPasswordError(e);

            dispatch(setError(errorMessages));

            return {
                status_cd,
                status_desc: errorMessages,
            };
        } finally {
            dispatch(stopLoading());
        }
    };
};
