import BaseAPI from '../BaseAPI';
class MessageAPI extends BaseAPI {
    basePath = 'cap/messages/';
    // get list of messages
    async getMessages() {
        try {
            const { messages } = await this.get({
                path: this.basePath,
            });
            return messages;
        } catch (error) {
            throw error.statusText;
        }
    }

    async createMessage(data) {
        try {
            const { message } = await this.post({
                path: `${ this.basePath }`,
                data: data,
            });
            return message;
        } catch (error) {
            throw error.statusText;
        }
    }

    async editMessage(messageId, data) {
        try {
            const { message } = await this.patch({
                path: `${ this.basePath }${ messageId }/`,
                data: data,
            });
            return message;
        } catch (error) {
            throw error.statusText;
        }
    }

    async removeMessage(messageId) {
        try {
            const message = await this.delete({
                path: `${ this.basePath }${ messageId }/`,
            });
            return message;
        } catch (error) {
            throw error.statusText;
        }
    }
}

export const messageAPI = new MessageAPI();
