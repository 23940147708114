import _ from 'underscore';
import validator from 'validator';
import ObjectClean from 'clean-deep';

export const isRequiredKey = 'isRequired';
export const isAcceptedKey = 'isAccepted';
export const isEmailSeparatedWithCommaKey = 'isEmailSeparatedWithComma';
export const valueFormatKey = 'valueFormat';
export const minLengthKey = 'minLength';
export const maxLengthKey = 'maxLength';
export const viewKey = 'view';
export const sectionKey = 'section';
export const fieldsetKey = 'fieldset';
export const gridKey = 'grid';
export const textKey = 'text';
export const textWithLabelTop = 'textTop';
export const textWithLabelLeft = 'textLeft';
export const textWithLabelRight = 'textRight';
export const textWithoutLabel = 'textWithoutLabel';
export const textWithLeftInnerLabelKey = 'textWithLeftInnerLabel';
export const textWithRightInnerLabelKey = 'textWithRightInnerLabel';
export const textWithButtonKey = 'textWithButton';
export const labelKey = 'label';
export const noteKey = 'note';
export const selectKey = 'select';
export const selectOptionsKey = 'options';
export const selectWithoutLabel = 'selectWithoutLabel';
export const radioKey = 'radio';
export const radioOptionsKey = 'radios';
export const checkboxKey = 'checkbox';
export const checkboxOptionsKey = 'checkboxes';
export const buttonKey = 'button';
export const uploadButtonKey = 'upload';
export const calendarKey = {
    default: 'calendar',
    calendar: 'calendar',
    datetime: 'datetime',
    date: 'date',
    time: 'time',
    year: 'year',
};
export const imageKey = 'image';
export const breakLineKey = 'breakline';
export const textAreaKey = 'textarea';
export const remarksKey = 'remark';
export const hoTableKey = 'hot';
export const hoTableColumnKey = 'hotColumn';
export const duplicableFieldset = 'duplicableFieldset';
export const signatoryKey = 'signatory';
export const acceptanceKey = 'acceptance';
export const gpsKey = 'gps';
export const midtidKey = 'midtid';
export const productTypesKey = 'product_types';
export const mdrKey = 'mdr';
export const productsKey = 'products';
export const standardMDRsKey = 'standard_merchant_rates';
export const terminalConfigurationKey = 'terminal_configuration';
export const setTypeKey = {
    vertical: 'vertical',
    spreadsheet: 'spreadsheet',
};

export const VALIDATOR_ERROR_MESSAGE = {
    isRequired: 'This is a mandatory field',
    isUrl: 'URL',
    isEmail: 'email address',
    isEmailSeparatedWithComma: 'email address(es) separated with ,',
    isAlpha: 'only alphabetic character(s)',
    isAlphanumeric: 'only alphabetic and number character(s)',
    isAlphanumericSpace: 'only alphabetic, number and space character(s)',
    isAlphanumericSpaceWithSpecialChar: 'only alphabetic, number, space and special character(s)',
    isNumeric: 'only numbers',
    isInt: 'only numbers',
    isEmpty: 'empty',
    isFloat: 'a decimal number',
    isLength: 'length of',
    isTime: 'time (HH:MM AM/PM)',
    isYear: 'year',
    regex: 'in the pattern of',
    escape: 'escaped with these character(s)',
    isDecimal: 'having a decimal place',
    isDecimalWithOneDigit: 'only 1 digit with 2 decimal place',
    isRegisteredNumber: 'only max 10 digits followed by a - and a single alphabet (009999999-X)',
};

const VALIDATOR = {
    isRequired: value => isRequired(value),
    isUrl,
    isEmail: validator.isEmail,
    isEmailSeparatedWithComma: isEmailSeparatedWithComma,
    isAlpha: validator.isAlpha,
    isAlphanumeric: validator.isAlphanumeric,
    isAlphanumericSpace: str => {
        return alphanumericSpace(str);
    },
    isAlphanumericSpaceWithSpecialChar: str => alphanumericSpaceWithSpecialChar(str),
    isNumeric: value => isNumeric(value),
    isInt: validator.isInt,
    isEmpty: validator.isEmpty,
    isFloat: validator.isFloat, // options {min: 0.00, max: 12.00}
    isLength: validator.isLength, // options {min: 0, max: undefined}
    isTime: str => {
        return isTime(str);
    },
    isYear: str => {
        return isYear(str);
    },
    regex: validator.matches, // (str, pattern, 'i')
    escape: validator.escape,
    isDecimal: value => isDecimal(value),
    isDecimalWithOneDigit: value => isDecimalWithOneDigit(value),
    isRegisteredNumber: value => isRegisteredNumber(value),
};

export function validateInputOld({ validators = [], value = null }) {
    let errorMessage = '';
    const originalValue = value;
    value = cleanValue(value, false);
    if (!_.isArray(validators)) return { isValid: !errorMessage, errorMessage };

    for (const validator of validators) {
        if (!_.isObject(value)) {
            //If value is not an object
            value = String(value);
            // is max min only
            const { min, max, pattern, message } = validator;
            if (_.isObject(validator) && !(min === null && max === null)) {
                //if validator is an obj (min & max)

                if (validators.includes('isDecimal')) {
                    // validate for decimal
                    if (!VALIDATOR.isDecimal(value) && value) {
                        errorMessage = 'Value should be in two decimal places.';
                    }
                } else if (validators.includes('isDecimalWithOneDigit')) {
                    if (!VALIDATOR.isDecimalWithOneDigit(value) && value) {
                        errorMessage = 'Value should be 1 digit with two decimal places.';
                    }
                } else if (validators.includes('isInt')) {
                    //if validator isInt
                    // number
                    if (!VALIDATOR.isInt(value) && value) {
                        errorMessage = `Value should be ${ VALIDATOR_ERROR_MESSAGE['isInt'] }`;
                    } else if (value && _.isNumber(min) && !VALIDATOR.isInt(value, { min })) {
                        errorMessage = `Number should be at least ${ min }.`;
                    } else if (value && _.isNumber(max) && !VALIDATOR.isInt(value, { max })) {
                        errorMessage = `Number should not exceed ${ max }.`;
                    }
                } else if (!VALIDATOR.isLength(value, { min, max })) {
                    if (validators.includes('isNumeric')) {
                        if (!VALIDATOR.isNumeric(value)) {
                            errorMessage = `Value should be ${
                                VALIDATOR_ERROR_MESSAGE['isNumeric']
                            }`;
                        } else if (value && _.isEqual(min, max)) {
                            errorMessage = `Value should be exactly ${ max } digits.`;
                        } else if (value && min && max) {
                            errorMessage = `Value should be ${ min } to ${ max } digits.`;
                        } else if (value && min) {
                            errorMessage = `Value should be at least ${ min } digits.`;
                        } else if (value && max) {
                            errorMessage = `Value should not exceed ${ max } digits.`;
                        }
                    } else if (value && _.isEqual(min, max)) {
                        errorMessage = `Value should be exactly ${ max } characters long.`;
                    } else if (value && min && max) {
                        errorMessage = `Value should be ${ min } to ${ max } characters long.`;
                    } else if (value && min) {
                        errorMessage = `Value should be at least ${ min } characters long.`;
                    } else if (value && max) {
                        errorMessage = `Value should not exceed ${ max } character(s).`;
                    }
                }
                // if isDecimal / isInt is set, it will never reach
                // it's standalone condition instead of else if
                if (!errorMessage && pattern) {
                    const patternStr = unEscapeRegExp(pattern);
                    const reg = new RegExp(patternStr);
                    if (!reg.test(value)) {
                        errorMessage = message || `Value doesn't match with ${ patternStr }`;
                    }
                }
            } else {
                // value to string, validator.js check string only
                if (!_.isObject(validator)) {
                    // escape min and max
                    if (validator === isRequiredKey && !VALIDATOR['isRequired'](value)) {
                        errorMessage = 'This is a mandatory field';
                    } else if (
                        validator === isAcceptedKey &&
                        !VALIDATOR[validator](originalValue)
                    ) {
                        errorMessage = VALIDATOR_ERROR_MESSAGE[validator];
                    } else if (value && !VALIDATOR[validator](value)) {
                        errorMessage = `Value should be ${ VALIDATOR_ERROR_MESSAGE[validator] }`;
                    }
                }
            }
        } else {
            // IS OBJECT / CHECKBOX / DROPDOWN ETC
            if (validator === isRequiredKey && _.isEmpty(value) && !_.isNumber(value)) {
                errorMessage = 'This is a mandatory field';
            } else if (validator === isAcceptedKey && !VALIDATOR[validator](originalValue)) {
                errorMessage = VALIDATOR_ERROR_MESSAGE[validator];
            }
        }
    }

    return { isValid: !errorMessage, errorMessage };
}

/**
 * Clean up data.
 * ObjectClean removes null/undefined values from arrays and objects.
 * return 0 for empty array
 * @param {any} value - value
 * @param {boolean} emptyArrayToZero - truth value to indicate either to return 0 if 'value' is empty array
 * @returns {any} processedValue
 */
export function cleanValue(value, emptyArrayToZero = true) {
    if (_.isArray(value) || _.isObject(value)) {
        value = ObjectClean(value);
        if (emptyArrayToZero && _.isArray(value) && !value.length) value = value.length;
    } else if (typeof value === 'string') {
        value = value.trim();
    } else if (_.includes([undefined, null], value)) {
        value = '';
    }
    return value;
}

function unEscapeRegExp(string) {
    return string.replace(/^\\+$/g, '');
}

/**
 * Private functions
 */

function isRequired(value) {
    if (_.isNumber(value)) {
        return true;
    }
    return value;
}

export function isEmailSeparatedWithComma(str = '') {
    function isEmail(str) {
        return validator.matches(
            str,
            /^[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+([a-zA-Z]{2,})$/,
        );
    }
    return _.every(str.split(',').map(s => isEmail(s.trim())));
}

function isUrl(str) {
    return validator.matches(str, /^(https?:\/\/)?[^\.\s]+(\.[^\.\s]+)+$/);
}

function alphanumericSpace(str) {
    return validator.matches(str, /^[a-zA-Z0-9\ ]*$/, 'g');
}

function alphanumericSpaceWithSpecialChar(str) {
    return validator.matches(str, /^[a-zA-Z0-9 .,!@$%#^*@+&_:;"'\/\-)(]*$/, 'g');
}

function isYear(str) {
    return validator.matches(str, /^\d{4}$/, 'g');
}

function isTime(str) {
    return validator.matches(str, /^((1[0-2]|0?[1-9]):([0-5][0-9]) ([AaPp][Mm]))$/, 'g');
}

function isNumeric(value) {
    return !(isNaN(value) && value);
}

function isDecimal(value) {
    // at the moment its set to max 2 decimal places
    return validator.matches(value, /^(?!^0\.00$)(([1-9][\d]{0,12})|([0]))\.[\d]{1,2}$/, 'g');
}

function isDecimalWithOneDigit(value) {
    // at the moment its set to max 2 decimal places
    return validator.matches(value, /^([0-9]\.[0-9]{2})$/, 'g');
}

function isRegisteredNumber(value) {
    return validator.matches(value, /^\d{1,10}\-[a-zA-Z]$/, 'g');
}
