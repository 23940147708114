import store from 'store';
import _ from 'underscore';
import { compose, withHandlers } from 'recompose';
import { getIsCreatingForm } from '../../../redux/selectors';
import { cleanValue } from '../../Form/OldFormRules';

const withReducerHandler = compose(
    withHandlers({
        handleReducerValue: ({
            id,
            value,
            defaultValue,
            initialValue,
            // handlers
            updateValue,
        }) => () => {
            // stop running if id isn't even defined
            if (!id) return;
            const isCreatingForm = getIsCreatingForm(store.getState());

            if (!cleanValue(value) && !_.isEqual(value, defaultValue)) {
                value = defaultValue;
            }

            // force set default value when it's mounted.
            if (!initialValue && isCreatingForm && cleanValue(value)) {
                updateValue({ value, force: true });
            }
        },
    }),
);

export default withReducerHandler;
