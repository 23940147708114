import {
    SET_DISABLED_FIELDS,
    SET_DISABLED_SECTIONS,
    SET_DISABLED_SELECTIONS,
    SET_DISABLED_CAMPAIGN_FIELDS,
} from '../actions/DisabledAction';

const INITIAL_STATE = {
    fields: {},
    sections: {},
    selections: {},
    fetching: false,
    error: null,
};

export default function valueReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
    case SET_DISABLED_FIELDS: {
        const { fields } = action;
        if (fields === null) {
            return {
                ...state,
                fields: {},
            };
        }
        return {
            ...state,
            fields: {
                ...state.fields,
                ...fields,
            },
        };
    }
    case SET_DISABLED_SECTIONS: {
        const { sections } = action;
        if (sections === null) {
            return {
                ...state,
                sections: {},
            };
        }
        return {
            ...state,
            sections: {
                ...state.sections,
                ...sections,
            },
        };
    }
    case SET_DISABLED_SELECTIONS: {
        const { selections } = action;
        if (selections === null) {
            return {
                ...state,
                selections: {},
            };
        }
        return {
            ...state,
            selections,
        };
    }
    case SET_DISABLED_CAMPAIGN_FIELDS: {
        const { campaignFields } = action;
        if (campaignFields === null) {
            return {
                ...state,
                campaignFields: {},
            };
        }
        return {
            ...state,
            campaignFields: campaignFields,
        };
    }
    default:
        return state;
    }
}
