import _ from 'underscore';
import { compose, withProps } from 'recompose';

// NOTE: This applicable for fields only, and only customList list is give
const withCustomListOptionsUpdate = compose(
    withProps(({ customList, initialId, isCustomListOptions }) => {
        if (_.isUndefined(customList)) {
            return;
        }
        isCustomListOptions = customList[initialId];
        return {
            isCustomListOptions,
        };
    }),
);

export default withCustomListOptionsUpdate;
