export const SET_ACTION_FORM = 'SET_ACTION_FORM';
export function setActionForm(form) {
    return {
        type: SET_ACTION_FORM,
        form: form,
    };
}

export default function actionSetActionForm(form) {
    return dispatch => {
        return dispatch(setActionForm(form));
    };
}
