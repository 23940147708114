import {
    goToApplicationForm,
    goToSubmittedForm,
    goToPage,
    goToPageInNewTab,
    PagePath,
} from '../GoToPage';

import {
    IconForm,
    IconFeatureManage,
    IconLink,
    IconUserManage,
    IconMessageManage,
    IconReportingManage,
    IconWatchlist,
    IconThemeManage,
    IconEmailNotifictaion,
    IconUploadFile,
} from '../../images/features';
import { downloadFileApi } from '../../redux/actions/FeatureAction';
import store from '../../store';

export { isoFormat, isoFormatObj, isoFormatMapper } from './datetime';

export const Features = {
    searchForm: 'search_form',
    createForm: 'create_form',
    link: 'link',
    externalLink: 'external_link',
    viewDashboard: 'view_dashboard',
    userManagement: 'user_management',
    messageManagement: 'message_management',
    delegateAccessSelf: 'delegate_access_self',
    delegateAccessRole: 'delegated_access_by_role',
    turnAroundTimeReport: 'turn_around_time_report',
    userSessionReport: 'user_session_report',
    reportManagement: 'reporting_management',
    businessReportManagement: 'business_reporting_management',
    itReportManagement: 'it_reporting_management',
    entryList: 'entry_list',
    themeManagement: 'theme_management',
    systemConfiguration: 'system_configuration',
    emailNotification: 'notification_management',
    bulkUpload: 'bulk_create_form',
};

export const LINK_TYPES = [Features.externalLink, Features.link];

export const FeaturesMapFunction = function(featureGroup, featuresById) {
    const FeaturesMap = {
        [Features.createForm]: {
            onClick: (e, data, history) => {
                handleClick(e, Features.createForm, data, history);
            },
        },
        [Features.searchForm]: {
            onClick: (e, data) => {
                handleClick(e, Features.searchForm, data);
            },
            onSubmit: e => {
                handleSubmit(e, Features.searchForm);
            },
        },
        [Features.link]: {
            onClick: (e, data) => {
                handleClick(e, Features.link, data);
            },
        },
        [Features.externalLink]: {
            onClick: (e, data) => {
                handleClick(e, Features.externalLink, data);
            },
        },
        [Features.userManagement]: {
            onClick: (e, data) => {
                handleClick(e, Features.userManagement, data);
            },
        },
        [Features.messageManagement]: {
            onClick: (e, data) => {
                handleClick(e, Features.messageManagement, data);
            },
        },
        [Features.delegateAccessSelf]: {
            onClick: (e, data) => {
                handleClick(e, Features.delegateAccessSelf, data);
            },
        },
        [Features.turnAroundTimeReport]: {
            onClick: (e, data) => {
                handleClick(e, Features.turnAroundTimeReport, data);
            },
        },
        [Features.userSessionReport]: {
            onClick: (e, data) => {
                handleClick(e, Features.userSessionReport, data);
            },
        },
        [Features.reportManagement]: {
            onClick: (e, data) => {
                handleClick(e, Features.reportManagement, data);
            },
        },
        [Features.entryList]: {
            onClick: (e, data) => {
                handleClick(e, Features.entryList, data);
            },
        },
        [Features.themeManagement]: {
            onClick: (e, data) => {
                handleClick(e, Features.themeManagement, data);
            },
        },
        [Features.businessReportManagement]: {
            onClick: (e, data) => {
                handleClick(e, Features.businessReportManagement, data);
            },
        },
        [Features.itReportManagement]: {
            onClick: (e, data) => {
                handleClick(e, Features.itReportManagement, data);
            },
        },
        [Features.systemConfiguration]: {
            onClick: (e, data) => {
                handleClick(e, Features.systemConfiguration, data);
            },
        },
        [Features.emailNotification]: {
            onClick: (e, data) => {
                handleClick(e, Features.emailNotification, data);
            },
        },
        [Features.bulkUpload]: {
            onClick: (e, data) => {
                handleClick(e, Features.bulkUpload, data);
            },
        },
    };

    function handleClick(e, enumType, data, history) {
        switch (enumType) {
        case Features.createForm:
            // href
            if (!history) {
                goToApplicationForm(data.params, true);
                return;
            }
            // SPA
            history.push(goToApplicationForm(data.params));
            break;
        case Features.searchForm:
            goToSubmittedForm(data.params);
            break;
        case Features.link:
            goToPage({ page: data.page });
            break;
        case Features.externalLink:
            goToPageInNewTab({ page: data.page });
            break;
        case Features.userManagement:
            goToPage({ page: '/user' });
            break;
        case Features.messageManagement:
            goToPage({ page: '/message' });
            break;
        case Features.reportManagement:
        case Features.businessReportManagement:
        case Features.itReportManagement:
            goToPage({ page: '/report' });
            break;
        case Features.delegateAccessSelf:
            goToPage({ page: PagePath.delegate_access });
            break;
        case Features.turnAroundTimeReport:
            store.dispatch(
                downloadFileApi({
                    page: '/reports/turn-around-time',
                    contentType: 'text/csv',
                    dataType: 'text',
                }),
            );
            break;
        case Features.userSessionReport:
            store.dispatch(
                downloadFileApi({
                    page: '/reports/user-session',
                    contentType: 'text/csv',
                    dataType: 'text',
                }),
            );
            break;
        case Features.entryList:
            goToPage({ page: '/watchlist' });
            break;
        case Features.themeManagement:
            goToPage({ page: '/themes' });
            break;
        case Features.systemConfiguration:
            goToPage({ page: '/system-configuration' });
            break;
        case Features.emailNotification:
            goToPage({ page: '/email-notification' });
            break;
        case Features.bulkUpload:
            goToPage({ page: '/bulk-upload' });
            break;
        default:
            break;
        }
    }

    function handleSubmit(e, enumType) {
        const value = e.target.value;

        switch (enumType) {
        case Features.searchForm:
            goToSubmittedForm(value);
            break;
        default:
            break;
        }
    }

    const [_key, featureGroupValue] = featureGroup;
    const { type, name, link, is_default: isDefault, features } = featureGroupValue;
    const currentFeature = FeaturesMap[type] || {};
    const { onSubmit, onClick } = currentFeature;

    // Taking 1st index since child feature selection is for future.
    const { template, view_access: accessId, beta } = featuresById[features[0]];
    const templateId = template || 1;
    const icon = {};

    if (!currentFeature || type === Features.viewDashboard) {
        return { type };
    }

    switch (type) {
    case 'external_link':
    case 'link':
        icon.path = IconLink;
        break;
    case 'feature':
        icon.path = IconFeatureManage;
        break;
    case 'user_management':
        icon.path = IconUserManage;
        break;
    case 'message_management':
        icon.path = IconMessageManage;
        break;
    case 'reporting_management':
    case 'business_reporting_management':
    case 'it_reporting_management':
        icon.path = IconReportingManage;
        break;
    case Features.delegateAccessSelf:
        icon.path = IconUserManage;
        break;
    case Features.entryList:
        icon.path = IconWatchlist;
        break;
    case Features.themeManagement:
        icon.path = IconThemeManage;
        break;
    case Features.emailNotification:
        icon.path = IconEmailNotifictaion;
        break;
    case Features.bulkUpload:
        icon.path = IconUploadFile;
        break;
    default:
        icon.path = IconForm;
        break;
    }

    return {
        icon: {
            name: icon.name,
            path: icon.path,
        },
        type,
        templateId,
        accessId,
        name,
        link,
        onClick,
        onSubmit,
        isDefault,
        isBeta: beta,
    };
};

export function parseString(str) {
    var args = [].slice.call(arguments, 1),
        i = 0;

    return str.replace(/%s/g, function() {
        return args[i++];
    });
}

export function normalizeString(str) {
    const normalizedStr = str
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]/g, '_')
        .replace(/_{2,}/g, '_');
    return normalizedStr;
}

/**
 * A function for getting the value of a query string in url
 *
 * @param {string} queryString e.g ?token=3433
 * @param {string} paramName e.g pass 'token' from token=3433, will get 3433
 * @returns {string} urlParameter
 */
export function getUrlParameter(queryString, paramName) {
    paramName = paramName.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + paramName + '=([^&#]*)');
    const results = regex.exec(queryString);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export function isFunction(func) {
    if (typeof func === 'function') return func;
    else return () => {};
}

const isProduction = process.env.NODE_ENV === 'production';
export function log() {
    !isProduction && console.log.apply(null, arguments);
}

export function warn() {
    !isProduction && console.warn.apply(null, arguments);
}

export function error() {
    !isProduction && console.error.apply(null, arguments);
}

export function time() {
    !isProduction && console.time.apply(null, arguments);
}

export function timeEnd() {
    !isProduction && console.timeEnd.apply(null, arguments);
}
