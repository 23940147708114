import store from 'store';
import { compose, withProps } from 'recompose';
import { getDisabledSelections } from 'redux/selectors';

// NOTE: This should only be applicable for dropdown
/**
 * Compose and return isDisabledOptions prop.
 * disabledSelections are retrieved from backend campaign configuration,
 *   if it's not defined it will fallback to isDisabledOptions that's
 *   configured in field
 * e.g. custom_properties: { "isDisabledOptions":[ "value1", "value2" ] }
 */
const withIsDisabledOptionsUpdate = compose(
    withProps(({ initialId, isDisabledOptions }) => {
        const disabledSelections = getDisabledSelections(store.getState());
        isDisabledOptions = disabledSelections[initialId] || isDisabledOptions;
        return {
            isDisabledOptions,
        };
    }),
);

export default withIsDisabledOptionsUpdate;
