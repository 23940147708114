/**
 * System configuration actions
 */

import { startLoading, stopLoading } from './PageAction';
import { showSuccessfulMessage } from '../../components/Misc/forms/popUps';
import { systemConfigurationAPI } from '../../api/configuration/SystemConfigurationAPI';
import _ from 'underscore';

export const SET_ERROR = 'SET_ERROR';
export const setError = error => {
    return {
        type: SET_ERROR,
        error,
    };
};

export const SET_SYSTEM_CONFIGURATIONS = 'SET_SYSTEM_CONFIGURATIONS';
export const setSystemConfigs = systemConfigs => {
    return {
        type: SET_SYSTEM_CONFIGURATIONS,
        systemConfigs,
    };
};

export const SET_SYSTEM_CONFIGURATION_VALUES = 'SET_SYSTEM_CONFIGURATION_VALUES';
export const setSystemConfigValues = systemConfigValues => {
    return {
        type: SET_SYSTEM_CONFIGURATION_VALUES,
        systemConfigValues,
    };
};

export const SET_CHANGED_SYSTEM_CONFIGURATION_VALUES = 'SET_CHANGED_SYSTEM_CONFIGURATION_VALUES';
export const actionUpdateSystemConfigValues = changedSystemConfigValues => {
    return {
        type: SET_CHANGED_SYSTEM_CONFIGURATION_VALUES,
        changedSystemConfigValues,
    };
};

/**
 * ACTIONS function
 *
 */

export const actionGetSystemConfigurations = () => {
    return async dispatch => {
        dispatch(startLoading());
        try {
            const {
                system_configurations: systemConfigurations,
            } = await systemConfigurationAPI.getSystemConfigurations();
            const { section_variables: sectionVariables } = systemConfigurations;
            const sections = _.flatten(Object.keys(sectionVariables).map(i => sectionVariables[i]));

            const keyValue = sections.map(i => {
                const { key, value } = i;
                return {
                    key,
                    value,
                };
            });
            dispatch(setSystemConfigs(systemConfigurations));
            dispatch(setSystemConfigValues(_.indexBy(keyValue, 'key')));
        } catch (e) {
            dispatch(setError(e));
        } finally {
            dispatch(stopLoading());
        }
    };
};

export const handleSaveSystemConfigError = e => {
    const { status_desc: statusDesc } = e;
    let errorMessages = 'Invalid Input';

    if (statusDesc && !_.isObject(statusDesc)) {
        errorMessages = statusDesc;
    }
    return errorMessages;
};

export const actionSaveSystemConfigValues = configValues => {
    return async dispatch => {
        dispatch(startLoading());
        try {
            await systemConfigurationAPI.saveSystemConfigurations(configValues);
            // Reset changed config values
            dispatch(actionUpdateSystemConfigValues({}));
            dispatch(showSuccessfulMessage());
        } catch (e) {
            const errorMessages = handleSaveSystemConfigError(e);

            dispatch(setError(errorMessages));
        } finally {
            dispatch(stopLoading());
        }
    };
};
