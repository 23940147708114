import React from 'react';
import _ from 'underscore';
import * as FR from '../../Form/FormRules';
import LabelMandatory from '../../Form/LabelMandatory';
import { compose, withPropsOnChange } from 'recompose';

const withLabelMandatory = compose(
    // Logic was moved from GridBuilder to here, due to the reason of the need of
    // updated isEditable state to decide whether to show the (*) or not.
    withPropsOnChange(
        ['schema', 'label', 'isEditable'],
        ({ label, schema, isEditable, isRequired }) => {
            if (!_.isObject(label)) return;
            const { title } = label;
            // if schema not empty object {} means show asterisk based on schema,
            // else if schema undefined means fallback to isRequired value
            const isRequiredSchemaNull = _.isUndefined(schema) && isRequired;
            const isRequiredSchemaExist =
                schema &&
                (schema[FR.isRequiredKey] ||
                    schema[FR.isAcceptedKey] ||
                    schema[FR.isAgreementGeneratedKey]);
            const showAsterisk = isRequiredSchemaNull || isRequiredSchemaExist;
            // if no title, or is required schema not set, or not editable
            if (!title || !showAsterisk || !isEditable) {
                return;
            }

            return {
                label: {
                    title: <LabelMandatory title={title} />,
                },
            };
        },
    ),
);

export default withLabelMandatory;
