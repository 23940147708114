import {
    // FETCHING
    SET_MESSAGES_FETCHING,
    // ERROR
    SET_MESSAGES_ERROR,
    // SET
    SET_MESSAGES,
    SET_MESSAGE,
    SET_PUBLISH_DATE,
} from '../actions/MessageAction';

const INITIAL_STATE = {
    fetching: {
        messagesFetching: false,
    },
    error: {
        messagesError: null,
    },
    messages: {},
    publishDate: null,
};

export default function MessageReducer(state = INITIAL_STATE, action) {
    const { payload } = action;
    switch (action.type) {
    case SET_MESSAGES_FETCHING:
        return {
            ...state,
            fetching: {
                ...state.fetching,
                messagesFetching: payload,
            },
        };

    case SET_MESSAGES_ERROR:
        return {
            ...state,
            error: {
                ...state.error,
                messagesError: payload,
            },
        };

    case SET_MESSAGES:
        return {
            ...state,
            messages: {
                ...payload,
            },
        };

    case SET_MESSAGE:
        return {
            ...state,
            messages: {
                ...state.messages,
                ...payload,
            },
        };

    case SET_PUBLISH_DATE:
        return {
            ...state,
            publishDate: payload,
        };

    default:
        return state;
    }
}
