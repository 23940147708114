import BaseAPI from '../BaseAPI';

class EmailNotificationAPI extends BaseAPI {
    basePath = 'cap/templates/';
    emailNotificationPath = 'notification/email/';
    notificationScenario = 'notification/scenarios/';

    async getEmailNotifications(templateId) {
        const { status_cd, status_desc, email_notifications, limit } = await this.get({
            path: `${ this.basePath }${ templateId }/${ this.emailNotificationPath }`,
        });
        return {
            status_cd,
            status_desc,
            email_notifications,
            limit,
        };
    }

    async getEmailNotification(id, templateId) {
        const { status_cd, status_desc, email_notification } = await this.get({
            path: `${ this.basePath }${ templateId }/${ this.emailNotificationPath }${ id }/`,
        });

        return {
            status_cd,
            status_desc,
            email_notification,
        };
    }

    async addEmailNotification(templateId, emailNotification) {
        const response = await this.post({
            path: `${ this.basePath }${ templateId }/${ this.emailNotificationPath }`,
            data: emailNotification,
        });
        const { status_cd, status_desc, email_notification } = response;

        return {
            status_cd,
            status_desc,
            email_notification,
        };
    }

    async updateEmailNotification(id, templateId, emailNotification) {
        const response = await this.patch({
            path: `${ this.basePath }${ templateId }/${ this.emailNotificationPath }${ id }/`,
            data: emailNotification,
        });
        const { status_cd, status_desc, email_notification } = response;

        return {
            status_cd,
            status_desc,
            email_notification,
        };
    }

    async deleteEmailNotification(id, templateId) {
        const response = await this.delete({
            path: `${ this.basePath }${ templateId }/${ this.emailNotificationPath }${ id }/`,
        });
        const { status_cd, status_desc, email_notification } = response;

        return {
            status_cd,
            status_desc,
            email_notification,
        };
    }

    async getEmailNotificationScenarios(templateId) {
        const { status_cd, status_desc, scenarios } = await this.get({
            path: `${ this.basePath }${ templateId }/${ this.notificationScenario }`,
        });
        return {
            status_cd,
            status_desc,
            scenarios,
        };
    }
}

export const emailNotificationAPI = new EmailNotificationAPI();
