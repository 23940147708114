import { createSelector } from 'reselect';

const fieldSelectors = state => state.fields;
const templateSelector = state => state.template;
const templatesSelector = state => state.template.templates;
const templatesFetchingSelector = state => state.template.fetching.templates;

export const getFields = createSelector(
    fieldSelectors,
    fields => fields,
);

export const getTemplate = createSelector(
    templateSelector,
    template => template,
);

export const getTemplatesFetching = createSelector(
    templatesFetchingSelector,
    fetching => fetching,
);

export const getTemplates = createSelector(
    templatesSelector,
    templates => templates,
);
