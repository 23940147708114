import { createSelector } from 'reselect';

const loadingSelector = state => state.page.isLoading;
const adminPageSelector = state => state.page.admin;
const onlineSelector = state => state.page.isOnline;

export const getPageLoading = createSelector(
    loadingSelector,
    isLoading => isLoading,
);

export const getIsAdminPage = createSelector(
    adminPageSelector,
    admin => admin,
);

export const getIsOnline = createSelector(
    onlineSelector,
    isOnline => isOnline,
);
