import { withProps, compose } from 'recompose';

// NOTE: This applicable for fields, grids, fieldsets and sections
const withProcessedFieldname = compose(
    withProps(({ fieldname = {} }) => {
        const { parent, current } = fieldname;
        const path = parent ? parent + '.' + current : current;
        return {
            path,
        };
    }),
);

export default withProcessedFieldname;
