// import $ from 'jquery';

export function getCookie(name) {
    var cookieValue = null;

    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');

        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === name + '=') {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }

    return cookieValue;
}

export function setCookie(name, value, exdays) {
    exdays = exdays || 1;
    const d = new Date();

    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = 'expires=' + d.toUTCString();
    document.cookie = name + '=' + value + ';' + expires + ';path=/';
}

export function removeCookie(name) {
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export const token = 'csrftoken';
export const cUser = {
    fullname: 'fullname',
    role: 'role',
    roleTitle: 'roleTitle',
    emaill: 'email',
    mobile: 'mobile',
    staffId: 'staffId',
};
export const csrftoken = getCookie(token);
