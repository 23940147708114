import {
    SET_EMAIL_NOTFICATION_ERROR,
    SET_EMAIL_NOTIFICATIONS,
    SET_EMAIL_NOTIFICATION_LIMIT,
    SET_NOTIFICATION_SCENARIOS,
} from '../actions/EmailNotificationAction';

const INITIAL_STATE = {
    reducer: 'emailNotification',
    emailNotifications: {},
    limit: null,
    scenarios: {},
    error: null,
};

export default function EmailNotificationReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
    case SET_EMAIL_NOTFICATION_ERROR:
        return {
            ...state,
            error: action.error,
        };
    case SET_EMAIL_NOTIFICATIONS: {
        return {
            ...state,
            emailNotifications: action.emailNotifications,
        };
    }
    case SET_EMAIL_NOTIFICATION_LIMIT: {
        return {
            ...state,
            limit: action.limit,
        };
    }
    case SET_NOTIFICATION_SCENARIOS: {
        return {
            ...state,
            scenarios: action.scenarios,
        };
    }
    default:
        return state;
    }
}
