import { compose, withHandlers } from 'recompose';
import { getTriggersValidity } from '../../Form/FormRules';

const withGetTriggerValidityHandler = compose(
    withHandlers({
        /**
         * Currently it's also being used by field.extend to extend value
         * based on other field's value that's targeted using 'by' property
         * e.g. could use this to extend maxRows for hot type kind of field.
         * @param {Object} props - component props
         * @returns {function} object => ({ isValid, value })
         */
        getTriggerValidity: props => ({ trigger, path, value } = {}) => {
            const embedTrigger = props.trigger;
            trigger = trigger || embedTrigger;

            return getTriggersValidity({
                trigger,
                path,
                value,
            });
        },
    }),
);

export default withGetTriggerValidityHandler;
