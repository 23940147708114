import { createSelector } from 'reselect';

const fieldsetsSelector = state => state.fieldset.fieldsets;
const fieldsetsChildsSelector = state => state.fieldset.fieldsetsFields;

export const getFieldsets = createSelector(
    fieldsetsSelector,
    fieldsets => fieldsets,
);

export const getFieldsetsChilds = createSelector(
    fieldsetsChildsSelector,
    fieldsetsFields => fieldsetsFields,
);
