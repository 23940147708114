import React from 'react';

import Locale from './Locale';
import { withClient } from './Misc/client';
class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            year: new Date().getFullYear() > 2014 ? new Date().getFullYear() : 2017,
        };
    }

    render() {
        return (
            <div className="footer">
                <footer>
                    Copyright © 2014-
                    <span id="footeryear">{this.state.year}</span>&nbsp; Jewel Paymentech. All
                    Rights Reserved.
                    <Locale />
                </footer>
            </div>
        );
    }
}
export default withClient(Footer);
