import _ from 'underscore';
import { compose, withHandlers } from 'recompose';
import setValueToIds from './setValueToIds';
import resetFieldsValidity from './resetFieldsValidity';

const withUpdateValueHandler = compose(
    withHandlers({
        updateValue: ({
            setPath,
            changeFieldValueValidity,
            reduxValue: currentValue,
            setValueToIds: ids,
            onValueChanged,
        }) => ({ inputValue, value, force }) => {
            if (!force && _.isEqual(currentValue, value)) {
                return;
            }
            changeFieldValueValidity({ inputValue, value, force });
            const updatedFieldIds = setValueToIds({ ids, path: setPath, value, onValueChanged });
            // reset validity
            if (updatedFieldIds.length) {
                resetFieldsValidity({ fields: updatedFieldIds, path: setPath });
            }
        },
    }),
);

export default withUpdateValueHandler;
