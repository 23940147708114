/**
 * A method that convert "${key1}.path.{to}"" -> ["key1", "to"]
 * @param {string} string - e.g. "${key1}.path.{to}"
 * @returns {string[]} matchedPlaceholders
 */
export default function getMatchedPlaceholders(string = '') {
    const matched = string.match(/\$\{([\w]*)\}/g);
    return (
        matched &&
        matched.map(str => {
            const found = str.match(/\$\{([\w]*)\}/);
            return found[1];
        })
    );
}
