import React from 'react';
import _ from 'underscore';

import { compose } from 'recompose';
import Label from './Label';
import { withCaptureFieldHandlers, NULL_VALUE_TEXT } from '../Misc/forms';
import { FieldAction } from '../Misc/template';
import PasswordVisibilityToggle from '../PasswordVisibilityToggle';

class InputWithNote extends React.Component {
    static defaultProps = {
        className: '',
        fieldname: {},
        label: {},
        note: {},
        placeholder: '',
        isValid: true,
        showComponent: true,
    };

    constructor(props) {
        super(props);

        this.state = {
            isPasswordVisible: false,
        };

        this.delay = (function() {
            var timer = 0;
            return function(callback, ms) {
                clearTimeout(timer);
                timer = setTimeout(callback, ms);
            };
        })();
        this.handleChange = this.props.handleChange.bind(this);
    }

    render() {
        const topClass = 'input__label--top';
        const classWarning = 'warning';
        let {
            id,
            setLocalValue,

            className,
            fieldname,
            // option
            type,
            title,
            name,
            label,
            note,
            value,
            placeholder,
            isValid,
            errorMessage,
            onChange,
            isPassword,
            isEditable,
            // utils
            showComponent,
            path,
        } = this.props;
        // handle "0" so that it show "0" value
        value = !value && _.isNumber(value) ? String(value) : value;
        const { current } = fieldname;
        const labelTitle = label.title;
        const noteTitle = note.title || '';
        const notePosition = note.position === 'left' ? 'left' : 'right';
        const option = {
            type,
            title,
            name,
            label,
            value,
            placeholder,
            note,
            isPassword,
        };
        const classNames = [
            'jpt--input input__note',
            _.isString(className) && className,
            !isValid && classWarning,
            !value && !isEditable && 'null-value',
        ];
        const fieldClassNames = [
            'input-text',
            notePosition === 'right' ? 'note-right' : 'note-left',
        ];

        if (!showComponent) return <div />;

        return (
            <div className={classNames.join(' ')} id={id}>
                {labelTitle && (
                    <Label className={topClass} {...label}>
                        {labelTitle}
                    </Label>
                )}

                {isEditable && (
                    <div className={fieldClassNames.join(' ')}>
                        {noteTitle && notePosition === 'left' && (
                            <div className="text-label note-left">{noteTitle}</div>
                        )}

                        <div className="field-input-wrapper">
                            <input
                                className={`field-input ${
                                    isPassword ? 'password-visibility-icon' : ''
                                }`}
                                value={value}
                                type={
                                    isPassword && !this.state.isPasswordVisible
                                        ? 'password'
                                        : 'text'
                                }
                                name={name}
                                placeholder={placeholder}
                                onChange={(e, data) => {
                                    const value = e.target.value;
                                    data = {
                                        value,
                                        path,
                                        fieldname: current,
                                    };
                                    setLocalValue(value);
                                    this.delay(() => {
                                        this.handleChange(e, data);
                                    }, 700);
                                    onChange(e, data);
                                }}
                                onBlur={e => {
                                    const data = {
                                        value: e.target.value,
                                        path,
                                        fieldname: current,
                                    };
                                    if (e.target.value !== value) {
                                        this.delay(() => {
                                            this.handleChange(e, data);
                                        }, 0);
                                    }
                                }}
                            />

                            {isPassword && (
                                <PasswordVisibilityToggle
                                    onClick={isVisible => {
                                        this.setState({
                                            isPasswordVisible: isVisible,
                                        });
                                    }}
                                />
                            )}
                        </div>

                        {noteTitle && notePosition === 'right' && (
                            <div className="text-label note-right">{noteTitle}</div>
                        )}
                    </div>
                )}
                {!isEditable && (
                    <div className="input-value-display">
                        {notePosition === 'left'
                            ? `${ noteTitle } ${ value || NULL_VALUE_TEXT }`
                            : `${ value || NULL_VALUE_TEXT } ${ noteTitle }`}
                    </div>
                )}

                <div className={'input__message ' + (errorMessage ? 'input__message--error' : '')}>
                    {errorMessage}
                </div>

                {this.props.isEditingField && <FieldAction path={path} field={option} />}
            </div>
        );
    }
}

export default compose(withCaptureFieldHandlers)(InputWithNote);
