import React from 'react';
import _ from 'underscore';
import SignaturePad from 'signature_pad/dist/signature_pad.js';
import { lifecycle, withHandlers } from 'recompose';
import { compose } from 'redux';

const SignBox = ({ onRefWrapper, onRefPad, className }) => {
    const classNames = ['signature__wrapper', _.isString(className) && className];

    return (
        <div ref={onRefWrapper} className={classNames.join(' ')}>
            <canvas className="signature-pad" ref={onRefPad} width="100%" height="100%" />
        </div>
    );
};

export default compose(
    // handle
    // - setting signature pad, canvas, and started state
    // - resizing canvas
    withHandlers(({ onClear, ratio = 1 }) => {
        let wrapper, canvas, signaturePad, started;

        return {
            onRefWrapper: () => ref => (wrapper = ref),
            onRefPad: () => ref => (canvas = ref),
            getCanvas: () => () => canvas,
            setSignaturePad: () => sp => (signaturePad = sp),
            getSignaturePad: () => () => signaturePad,
            setStarted: () => s => (started = s),
            getData: () => () => signaturePad.toData(),
            resizeCanvas: () => () => {
                let data;
                // Get the original sign
                if (started) data = signaturePad.toDataURL();

                const canvasRatio = Math.max(window.devicePixelRatio || 1, 1);
                wrapper.style.height = canvas.offsetWidth * ratio + 'px';
                canvas.width = canvas.offsetWidth * canvasRatio;
                canvas.height = canvas.offsetWidth * canvasRatio * ratio;
                canvas.getContext('2d').scale(canvasRatio, canvasRatio);

                // Set the sign back into the signature pad
                if (started) signaturePad.fromDataURL(data);
            },
            clear: () => () => {
                signaturePad.clear();
                onClear && onClear({ started });
            },
            undo: () => () => {
                const data = signaturePad.toData();

                if (data.length) {
                    data.pop();
                    signaturePad.fromData(data);
                }

                // this looks like can just if else,
                //  but in order to cater the data.pop in if condition
                //  need to be another condition
                if (!data.length) {
                    signaturePad.clear();
                    started = false;
                    onClear && onClear({ started });
                }
            },
            toDataURL: () => format => signaturePad.toDataURL(format),
        };
    }),
    lifecycle({
        componentDidMount() {
            const { getCanvas, onEnd } = this.props;
            const canvas = getCanvas();
            const signaturePad = new SignaturePad(canvas, {
                onEnd: () => {
                    onEnd && onEnd({ signaturePad });
                    this.props.setStarted(true);
                },
            });

            this.props.setSignaturePad(signaturePad);
            this.props.resizeCanvas();

            window.addEventListener('resize', this.props.resizeCanvas);
        },
        componentWillUnmount() {
            window.removeEventListener('resize', this.props.resizeCanvas);
        },
    }),
)(SignBox);
