import { startLoading, stopLoading } from './PageAction';
import _ from 'underscore';
// using require to temporary solve TypeError: Class extends value undefined is not a constructor or null
const { sectionAPI } = require('../../api/template/SectionAPI');

// fieldset creation
export function getFieldset(templateId, fieldsetId) {
    return async dispatch => {
        dispatch(startLoading());
        startFetching(dispatch);
        try {
            const section = await sectionAPI.getFieldset(templateId, fieldsetId);
            let { fieldsets, action_buttons: actionButtons } = section;
            const isActionButton = _.isEmpty(fieldsets);
            if (isActionButton) fieldsets = actionButtons;
            const object = {
                fieldsets,
            };
            dispatch(setTemplateCreateObject(object));
            dispatch(setFieldsetDetail(section));
            dispatch(stopLoading());

            section.status_cd && dispatch(setFieldsetError(section.status_cd));
        } catch (e) {
            console.error(e);
            dispatch(stopLoading());
        }
    };
}

export function updateSection(templateId, fieldsetId, data, sCb = () => {}) {
    return async (dispatch, getState) => {
        const { section } = getState();
        const { detail } = section;
        const isActionButton = _.isEmpty(detail.fieldsets);
        const updatedSection = {
            name: detail.name,
            description: detail.description,
            action_buttons: [],
            fieldsets: [],
        };
        if (isActionButton) {
            updatedSection.action_buttons = data.fieldsets;
        } else {
            updatedSection.fieldsets = data.fieldsets;
        }

        dispatch(startLoading());
        try {
            const response = await sectionAPI.updateSection(templateId, fieldsetId, updatedSection);
            typeof sCb === 'function' && sCb(response);
            dispatch(stopLoading());
        } catch (e) {
            console.error(e);
            dispatch(stopLoading());
        }
    };
}

export const SET_SECTION_CHILDREN = 'SET_SECTION_CHILDREN';
export function setSectionChildren({ id, sectionChildren }) {
    return {
        type: SET_SECTION_CHILDREN,
        payload: {
            id,
            sectionChildren,
        },
    };
}

export const SET_SECTIONS_ITEMS = 'SET_SECTIONS_ITEMS';
export function setSectionsItems(sectionChildren) {
    return {
        type: SET_SECTIONS_ITEMS,
        sectionChildren,
    };
}

export const SET_FIELDSET_IDS = 'SET_FIELDSET_IDS';
export function setFieldsetIds(params = {}) {
    return {
        type: SET_FIELDSET_IDS,
        payload: {
            templateId: params.templateId,
            fieldsetId: params.fieldsetId,
        },
    };
}

export const SET_FIELDSET_FETCHING = 'SET_FIELDEST_FETCHING';
export function setFieldsetFetching(fetching) {
    return {
        type: SET_FIELDSET_FETCHING,
        fetching,
    };
}

export const SET_FIELDSET_ERROR = 'SET_FIELDEST_ERROR';
export function setFieldsetError(error = '') {
    return {
        type: SET_FIELDSET_ERROR,
        error,
    };
}

export const SET_IS_SELECTING_FIELD = 'SET_IS_SELECTING_FIELD';
export function setSelectingField(isSelecting = false) {
    return {
        type: SET_IS_SELECTING_FIELD,
        isSelecting: isSelecting,
    };
}

export const SET_TEMPLATE_CREATE_OBJECT = 'SET_TEMPLATE_CREATE_OBJECT';
export function setTemplateCreateObject({ type, showTitleAndName, fieldsets }) {
    return {
        type: SET_TEMPLATE_CREATE_OBJECT,
        obj: {
            type,
            showTitleAndName,
            fieldsets,
        },
    };
}

export const SET_SECTION_CREATION = 'SET_SECTION_CREATION';
export function setSectionCreation(creation = {}) {
    return {
        type: SET_SECTION_CREATION,
        creation,
    };
}

export const SET_FIELDSET_DETAIL = 'SET_FIELDSET_DETAIL';
export function setFieldsetDetail(obj = {}) {
    return {
        type: SET_FIELDSET_DETAIL,
        obj: obj,
    };
}

export const SET_WORKING_PATH = 'SET_WORKING_PATH';
export function setWorkingPath(path = '') {
    return {
        type: SET_WORKING_PATH,
        path: path,
    };
}

export const SET_TOTAL_FIELD = 'SET_TOTAL_FIELD';
export function setTotalField(num = 0) {
    return {
        type: SET_TOTAL_FIELD,
        num: num,
    };
}

export const SET_TOTAL_GRID = 'SET_TOTAL_GRID';
export function setTotalGrid(num = 0) {
    return {
        type: SET_TOTAL_GRID,
        num: num,
    };
}

export const SET_TOTAL_FIELDSET = 'SET_TOTAL_FIELDSET';
export function setTotalFieldset(num = 0) {
    return {
        type: SET_TOTAL_FIELDSET,
        num: num,
    };
}

export const SET_EDITING_FIELD = 'SET_EDITING_FIELD';
export function setEditingField(isEditing = false) {
    return {
        type: SET_EDITING_FIELD,
        isEditing: isEditing,
    };
}

export const SET_EDITING_GRID = 'SET_EDITING_GRID';
export function setEditingGrid(isEditing = false) {
    return {
        type: SET_EDITING_GRID,
        isEditing: isEditing,
    };
}

export const SET_EDITING_FIELDSET = 'SET_EDITING_FIELDSET';
export function setEditingFieldset(isEditing = false) {
    return {
        type: SET_EDITING_FIELDSET,
        isEditing: isEditing,
    };
}

export const SET_PANEL = 'SET_PANEL';
export function setPanel(showing) {
    return {
        type: SET_PANEL,
        showing: showing,
    };
}

export const SET_CURRENT_EDITING_FIELD_VALUE = 'SET_CURRENT_EDITING_FIELD_VALUE';
export function setCurrentFieldValue(data = {}) {
    return {
        type: SET_CURRENT_EDITING_FIELD_VALUE,
        path: data.path || '',
        value: data.value || '',
    };
}

export const SET_CLIPBOARD_FIELDS = 'SET_CLIPBOARD_FIELDS';
export function setClipboardField(field = {}) {
    return {
        type: SET_CLIPBOARD_FIELDS,
        field: field,
    };
}

export const SET_CLIPBOARD = 'SET_CLIPBOARD';
export function setClipboard(showing) {
    return {
        type: SET_CLIPBOARD,
        showing: showing,
    };
}

export function startFetching(dispatch) {
    dispatch(setFieldsetFetching(true));
    dispatch(setFieldsetError(null));
}

export function stopFetching(dispatch) {
    dispatch(setFieldsetFetching(false));
}
