import React, { useState } from 'react';
import { IconPasswordVisible, IconPasswordHidden } from '../images/features';

/**
 * This is a component to toggle icons for password visibility.
 *
 * @param {onClick} - A callback function to set input type in the parent component
 *
 */

const PasswordVisibilityToggle = ({ onClick }) => {
    const [isVisible, setIsVisible] = useState(false);
    return (
        <span
            className="password-visibility"
            onClick={() => {
                setIsVisible(!isVisible);
                onClick(!isVisible);
            }}>
            <img src={isVisible ? IconPasswordVisible : IconPasswordHidden} />
        </span>
    );
};

export default PasswordVisibilityToggle;
