import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import _ from 'underscore';

import Calendar from '../Calendar';
import ErrorMessage from '../Calendar/ErrorMessage';
import { withCaptureFieldHandlers } from '../../Misc/forms';
import { compose, withStateHandlers } from 'recompose';
import 'moment-timezone';

class DateTimeRange extends Component {
    constructor(props) {
        super(props);

        this.handleChange = this.props.handleChange.bind(this);
        this.handleFromChange = this.handleFromChange.bind(this);
        this.handleToChange = this.handleToChange.bind(this);
    }

    classWarning = 'warning';

    handleFromChange(e, { value }) {
        const { setLocalValue, onChange } = this.props;
        const updatedValue = {
            ...this.props.value,
            from: value,
        };
        onChange(e, updatedValue);
        setLocalValue(updatedValue);
        this.handleChange(e, { value: updatedValue });
    }

    handleToChange(e, { value }) {
        const { setLocalValue, onChange, calendarType } = this.props;
        const updatedValue = {
            ...this.props.value,
            to: value,
        };
        onChange(e, updatedValue);
        setLocalValue(updatedValue);
        this.handleChange(e, { value: updatedValue });
        const valueInRange = inRange({
            value: updatedValue,
            type: calendarType,
        });

        // will only set validity when 'to' value is changed
        this.props.setInRange(valueInRange);
    }

    render() {
        const {
            id,
            label,
            isEditable,
            isValid,
            placeholder,
            calendarType,
            inRange,
            maxDate,
            minDate,
            timezone,
            format,
            showComponent,
        } = this.props;
        let { value } = this.props;
        // to handle null case
        value = value || { from: '', to: '' };
        const classNames = [
            'jpt--input input__date-time-range range',
            (!isValid || !inRange) && this.classWarning,
        ];
        let { errorMessage } = this.props;
        if (!errorMessage && !inRange) {
            errorMessage = 'From more than To';
        }

        if (!showComponent) return <span />;

        return (
            <div className={classNames.join(' ')} id={id}>
                <div className="input__date-time-range range__from">
                    <Calendar
                        label={label.from}
                        maxDate={maxDate.from}
                        minDate={minDate.from}
                        onChange={this.handleFromChange}
                        placeholder={placeholder}
                        calendarType={calendarType}
                        value={value.from}
                        isEditable={isEditable}
                        timezone={timezone}
                        format={format}
                    />
                </div>
                <div className="input__date-time-range range__to">
                    <Calendar
                        label={label.to}
                        maxDate={maxDate.to}
                        minDate={minDate.to}
                        onChange={this.handleToChange}
                        placeholder={placeholder}
                        calendarType={calendarType}
                        value={value.to}
                        isEditable={!!(value.from && isEditable)}
                        timezone={timezone}
                        format={format}
                    />
                </div>

                <ErrorMessage message={errorMessage} />
            </div>
        );
    }
}

DateTimeRange.defaultProps = {
    label: { from: {}, to: {} },
    maxDate: {},
    minDate: {},
    isEditable: true,
    isValid: true,
    onChange: () => {},
    value: { from: '', to: '' },
    showComponent: true,
};
DateTimeRange.propTypes = {
    label: PropTypes.object,
    maxDate: PropTypes.object,
    minDate: PropTypes.object,
    errorMessage: PropTypes.string,
    isEditable: PropTypes.bool,
    isValid: PropTypes.bool,
    value: PropTypes.object,
    placeholder: PropTypes.string,
    timezone: PropTypes.string,
    format: PropTypes.string,
};

/*
compose will take in DateTimeRange component and return a new updated functionality
withCaptureFieldHandlers function is use in recompose to update the redux state value with setLocalValue method
withStateHandlers method wll set inRange state
and setInRange method can hold the inRange state to update the state
*/
export default compose(
    withCaptureFieldHandlers,
    withStateHandlers(
        ({ inRange = true }) => ({
            inRange,
        }),
        {
            setInRange: () => inRange => ({
                inRange,
            }),
        },
    ),
)(DateTimeRange);

/**
 * A method that compare value.from and value.to.
 *   to check whether value is in range
 *
 * @param {Object} value - { value: { from: string , to: string }, typp: string }
 * @returns {boolean} isInRange
 */
export function inRange({ value = {}, type = 'datetime' }) {
    // Allow both blank.
    if (_.isEmpty(value)) return true;
    // We never do validation if type is time.
    // TODO, this causes inconsistent handling.
    if (type === 'time') return true;

    const from = moment(value.from);
    const to = moment(value.to);

    const formatMapping = {
        datetime: x => x,
        date: x => x.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
        year: x => x.year(),
    };

    // Default to datetime if type is unrecognized.
    const formatFn = formatMapping[type] || formatMapping['datetime'];
    if (formatFn(from) > formatFn(to)) {
        return false;
    }
    return true;
}
