import { startLoading, stopLoading } from './PageAction';
import SessionStorage from '../../components/SessionStorage';
import AuthenticationAPI from '../../api/profile/AuthenticationAPI';

const api = new AuthenticationAPI();
const session = new SessionStorage();

export const SET_USER_INFO = 'SET_USER_INFO';
export function setUserInfo(profile = {}) {
    return {
        type: SET_USER_INFO,
        payload: profile,
    };
}

export const SET_TOKEN = 'SET_TOKEN';
export function setToken(token) {
    return {
        type: SET_TOKEN,
        token: token,
    };
}

export const SET_EXPIRY_DATE = 'SET_EXPIRY_DATE';
export function setExpiryDate(date) {
    return {
        type: SET_EXPIRY_DATE,
        expiryDate: date,
    };
}

export const LOGIN_USER_SUCCEED = 'LOGIN_USER_SUCCEED';
function loginUserSucceed(response) {
    return {
        type: LOGIN_USER_SUCCEED,
        response: response,
    };
}

export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
function loginUserError(response) {
    return {
        type: LOGIN_USER_ERROR,
        response: response,
    };
}

export const SET_RECAPTCHA_RESPONSE = 'SET_RECAPTCHA_RESPONSE';
export function setRecaptchaResponse(recaptchaResponse) {
    return {
        type: SET_RECAPTCHA_RESPONSE,
        recaptchaResponse,
    };
}

export const SET_LOGIN_FORM_VALUES = 'SET_LOGIN_FORM_VALUES';
export function setLoginFormValues({ key, value }) {
    return {
        type: SET_LOGIN_FORM_VALUES,
        key,
        value,
    };
}

export function actionLogin(data, successCallback, failCallback) {
    return async dispatch => {
        dispatch(startLoading());

        try {
            const response = await api.login(data);
            // success
            const profile = response.profile;
            session.setUserInfo(profile);
            session.setToken(response.token);
            session.setLoginTime(new Date());
            session.setExpiryDate(response.token_expiry_date);
            session.setShownTimeoutWarnings([]);
            setToken(response.token);
            setUserInfo(profile);
            setExpiryDate(response.token_expiry_date);

            successCallback && successCallback(response);
            return dispatch(loginUserSucceed(response));
        } catch (e) {
            failCallback && failCallback(e);
            return dispatch(loginUserError(e));
        } finally {
            dispatch(stopLoading());
        }
    };
}

export function actionLogout(data) {
    return async dispatch => {
        dispatch(startLoading());
        try {
            await api.logout(data);
        } catch (e) {
            dispatch(loginUserError(e));
        } finally {
            dispatch(stopLoading());
        }
    };
}

export function actionShowInfoRequired(data = {}) {
    return loginUserError(data);
}
