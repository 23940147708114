import React from 'react';

import _ from 'underscore';
import { compose } from 'recompose';
import { TextArea } from 'semantic-ui-react';
import { NULL_VALUE_TEXT } from '../Misc/forms';
import { FieldAction } from '../Misc/template';

import JPLabel from './Label';
import { withCaptureFieldHandlers } from '../Misc/forms';

const JPTextArea = ({
    id,
    setLocalValue,
    delay,

    fieldname = {},
    // option
    type,
    rows = 3,
    value,
    isEditable,
    isValid,
    className,
    label,
    placeholder,
    errorMessage,
    onChange,
    onBlur,
    // utils
    showComponent = true,
    path,
    handleChange,
    // inherited parent utils
    isEditingField,
}) => {
    const { current } = fieldname;
    const classWarning = 'warning';
    const topClass = 'input__label--top';
    const classNames = [
        'jpt--input input__textarea',
        !isValid && classWarning,
        _.isString && className,
        !value && !isEditable && 'null-value',
    ];
    const option = {
        type,
        value,
        className,
        label,
        placeholder,
        onChange,
    };

    if (!showComponent) return <div />;

    return (
        <div className={classNames.join(' ')} id={id}>
            {label && <JPLabel className={topClass} {...label} />}

            {isEditable && (
                <TextArea
                    rows={rows}
                    placeholder={placeholder}
                    value={value}
                    onChange={(e, data) => {
                        data = {
                            ...data,
                            path,
                            fieldname: current,
                        };
                        onChange(e, data);
                        setLocalValue && setLocalValue(data.value);
                        handleChange &&
                            delay(() => {
                                handleChange(e, data);
                            }, 700);
                    }}
                    onBlur={e => {
                        const data = {
                            value,
                            path,
                            fieldname: current,
                        };
                        onBlur(e, data);

                        handleChange &&
                            delay(() => {
                                handleChange(e, data);
                            }, 0);
                    }}></TextArea>
            )}

            {!isEditable && (
                <TextArea
                    rows={rows}
                    value={value || NULL_VALUE_TEXT}
                    className={'input--label-value noteditable'}
                    readOnly></TextArea>
            )}

            <div className={'input__message ' + (errorMessage ? 'input__message--error' : '')}>
                {errorMessage}
            </div>

            {isEditingField && <FieldAction path={path} field={option} />}
        </div>
    );
};

JPTextArea.defaultProps = {
    fieldname: {},
    isEditable: true,
    isValid: true,
    label: {},
    onChange: _.noop,
    onBlur: _.noop,
    delay: (function() {
        var timer = 0;
        return function(callback, ms) {
            clearTimeout(timer);
            timer = setTimeout(callback, ms);
        };
    })(),
};

export const Textarea = JPTextArea;

export default compose(withCaptureFieldHandlers)(JPTextArea);
