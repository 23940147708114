import React from 'react';
import Proptypes from 'prop-types';
import _ from 'underscore';

import { compose, lifecycle, withHandlers } from 'recompose';

import TableHeader from './Header';
import TableFooter from './Footer';
import TableRow from './Row';

const Table = props => {
    let {
        data = {},
        // Replacing Component
        Row,
        EmptyRow,
        Header,
        Footer,
        // misc
        sorting,
        asc,
        links = {},
        pageIndex,
        columnActions,
        headerCheckboxes,
        columnCheckboxes,
        // is state
        isTable,
        // handlers
        onHeaderClick,
    } = props;
    let { headers = {}, rows = {}, showFooter, showNumbering } = data;

    showFooter = showFooter === undefined ? false : showFooter;
    showNumbering = showNumbering === undefined ? true : false;

    const Wrapper = isTable ? 'table' : 'div';
    const Body = isTable ? 'tbody' : 'div';

    // is table check
    if (!isTable) {
        Header = Header ? Header : 'div';
        Footer = Footer ? Footer : Header;
        Row = Row ? Row : 'div';
        EmptyRow = EmptyRow ? EmptyRow : 'div';
    } else {
        // fallback to table element
        Header = TableHeader;
        Footer = TableFooter;
        Row = TableRow;
        EmptyRow = NoRecordRow;
    }

    return (
        <Wrapper id="table" ref={props.onRef} className="dataTable ui celled table" cellSpacing="0">
            {isTable && (
                <Header
                    sorting={sorting}
                    asc={asc}
                    data={data}
                    columnActions={columnActions}
                    checked={headerCheckboxes[pageIndex]}
                    showNumbering={showNumbering}
                    onHeaderClick={onHeaderClick}
                />
            )}
            {isTable && showFooter && (
                <Footer
                    sorting={sorting}
                    asc={asc}
                    data={data}
                    columnActions={columnActions}
                    showNumbering={showNumbering}
                    onHeaderClick={onHeaderClick}
                />
            )}
            <Body>
                {rows.length ? (
                    rows.map((row, index) => {
                        const [, columns = []] = row;
                        const isChecked = columnCheckboxes[index];
                        const totalRowIndex = columns._no - 1;

                        return (
                            <Row
                                key={totalRowIndex}
                                data={data}
                                links={links}
                                totalRowIndex={totalRowIndex}
                                rowIndex={index}
                                columns={columns}
                                headers={headers}
                                checked={isChecked}
                                columnActions={columnActions}
                                showNumbering={showNumbering}
                            />
                        );
                    })
                ) : (
                    <EmptyRow />
                )}
            </Body>
        </Wrapper>
    );
};

Table.defaultProps = {
    Row: null,
    EmptyRow: null,
    Header: null,
    Footer: null,

    sorting: false,
    asc: null,
    links: {},
    pageIndex: true,
    columnActions: {},
    headerCheckboxes: {},
    columnCheckboxes: {},

    isTable: true,

    onHeaderClick: _.noop,
};

Table.propTypes = {
    Row: Proptypes.any,
    EmptyRow: Proptypes.any,
    Header: Proptypes.any,
    Footer: Proptypes.any,

    sorting: Proptypes.bool,
    asc: Proptypes.bool,
    links: Proptypes.object,
    pageIndex: Proptypes.bool,
    columnActions: Proptypes.object,
    headerCheckboxes: Proptypes.object,
    columnCheckboxes: Proptypes.object,

    isTable: Proptypes.bool,

    onHeaderClick: Proptypes.func,
};

export default compose(
    /**
     *  --- props ---
        isEditing,
        pageIndex,
        searchString,
        searchableColumns,
        data,
     */
    withHandlers(() => {
        const dataTable = null;
        let table = null;

        return {
            onRef: () => ref => {
                table = ref;
            },
            me: () => () => table,
            getDataTable: () => () => dataTable,
        };
    }),
    lifecycle({
        componentWillReceiveProps() {
            const dataTable = this.props.getDataTable();
            if (!dataTable) return;

            this.setState({
                pageInfo: dataTable.page.info(),
                tableRows: dataTable.rows()[0],
            });
        },
    }),
)(Table);

const NoRecordRow = () => (
    <tr>
        <td className="table__column empty" colSpan="100">
            No record found
        </td>
    </tr>
);
