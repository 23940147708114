import React from 'react';

class LabelMandatory extends React.Component {
    render() {
        const { title } = this.props;

        return (
            <React.Fragment>
                <span className="red">* </span>
                <span>{title}</span>
            </React.Fragment>
        );
    }
}

export default LabelMandatory;
