import store from 'store';
import _ from 'underscore';
import { compose, withHandlers, withProps } from 'recompose';
import { connect } from 'react-redux';
import {
    showAssignSelfError,
    showForbiddenAccess,
    showFailedMessage,
    showSuccessfulMessage,
    showConfirmMessage,
} from './popUps';
import { HTTP_CODE, STATUS_CODE } from '../api';
import { ACTION_TYPES } from './actions';
import createAssignOtherForm, {
    showAssignOtherFormError,
    clearAssignOtherFormValidities,
    clearAssignOtherFormValue,
    setLastAssigneeIdIfExist,
} from './actions/createAssignOtherForm';
import createDeclineForm, {
    clearDeclineFormValidities,
    clearDeclineFormValue,
    setDeclineFormDisabledFields,
    setDeclinFormValidators,
} from './actions/createDeclineForm';
import createWithdrawForm, {
    clearWithdrawFormValidities,
    clearWithdrawFormValue,
    setWithdrawFormDisabledFields,
    setWithdrawFormValidators,
} from './actions/createWithdrawForm';
import { actionSetActionForm, setCurrentForm } from 'redux/actions/FormAction';
import { getDecliningApplicationForm } from 'redux/actions/DashboardAction';
import { actionUpdateTemplateForm } from 'redux/actions/TemplateFormAction';
import { actionGetFields } from 'redux/actions/FieldAction';
import { getFields } from 'redux/selectors/FieldSelector';
import { goToFormHistory } from '../../GoToPage';

const withActionsHandlers = compose(
    connect(null, dispatch => ({
        setActionForm: form => {
            dispatch(actionSetActionForm(form));
        },
        setFormId: id => {
            dispatch(setCurrentForm(id));
        },
        getApplicationFormInfo: async ({ formId, templateId }) => {
            await dispatch(getDecliningApplicationForm({ formId, templateId }));
        },
        callFormAction: async ({ formId, templateId, uid, data }) => {
            try {
                await dispatch(
                    actionUpdateTemplateForm({
                        formId,
                        templateId,
                        uid,
                        data,
                    }),
                );
                return true;
            } catch (e) {
                const response = e || {};
                const { status_cd: statusCode, status_desc: statusDesc } = response;
                if (statusCode === STATUS_CODE.BACKEND_INFO) {
                    const assignSelfForm = showAssignSelfError({
                        message: statusDesc,
                        onConfirm: () => {
                            dispatch(
                                actionUpdateTemplateForm({
                                    formId,
                                    templateId,
                                    uid,
                                    data: {
                                        ...data,
                                        confirm: true,
                                    },
                                }),
                            );
                            dispatch(actionSetActionForm({}));
                        },
                        onCancel: () => {
                            dispatch(actionSetActionForm({}));
                        },
                    });
                    dispatch(assignSelfForm);
                } else if (statusCode === STATUS_CODE.BAD_REQUEST) {
                    dispatch(showForbiddenAccess());
                } else if (e.status === HTTP_CODE.BAD_REQUEST && !window.navigator.onLine) {
                    console.log('offline');
                }
                return false;
            }
        },
    })),
    withHandlers({
        declineForm:
            ({ setFormId, getApplicationFormInfo, setActionForm }) =>
            async (e, { templateId, form, isViewingForm }) => {
                !isViewingForm && setFormId(form.id);
                !isViewingForm &&
                    (await getApplicationFormInfo({
                        formId: form.id,
                        templateId,
                    }));
                const declineForm = createDeclineForm({
                    formStatus: form.formStatus,
                    applicationId: form.applicationId,
                });
                clearDeclineFormValidities();
                clearDeclineFormValue();
                setDeclineFormDisabledFields();
                setActionForm(declineForm);
                setDeclinFormValidators();
            },
        // depends on callFormAction
        assignOtherForm:
            ({ setActionForm, callFormAction }) =>
            async (e, { uid, assignees = [], templateId, form }) => {
                if (form.formGroupId) {
                    assignees = assignees.filter(assignee => {
                        if (assignee.is_group_applied) {
                            return assignee.group === form.formGroupId;
                        }
                        return true;
                    });
                }
                assignees = _.map(assignees, o => ({
                    value: o.id,
                    title: `${o.name} (${o.role})`,
                }));
                const assignOtherForm = createAssignOtherForm({
                    formStatus: form.formStatus,
                    applicationId: form.applicationId,
                    assignees,
                    assign: async userId => {
                        if (!userId) {
                            showAssignOtherFormError();
                            return;
                        }
                        const ok = await callFormAction({
                            formId: form.id,
                            templateId,
                            uid,
                            data: {
                                assignee: userId,
                            },
                        });

                        if (ok) {
                            setActionForm({});
                        }
                    },
                    cancel: () => {
                        setActionForm({});
                    },
                });

                clearAssignOtherFormValidities();
                clearAssignOtherFormValue();
                setActionForm(assignOtherForm);
                setLastAssigneeIdIfExist(store.getState().formAction.histories[uid]);
            },
        withdrawForm:
            ({ setFormId, getApplicationFormInfo, setActionForm, callFormAction }) =>
            async (e, { uid, templateId, form, isViewingForm, options = {} }) => {
                await store.dispatch(actionGetFields({ templateId }));
                const readOnlyFields = options.read_only_fields || [];
                const withdrawReasons = options.withdraw_reasons || [];
                const formId = form.id;

                !isViewingForm && setFormId(formId);
                !isViewingForm &&
                    (await getApplicationFormInfo({
                        formId,
                        templateId,
                    }));
                const reduxStates = store.getState();
                const fields = getFields(reduxStates);
                console.log(fields);
                const withdrawForm = createWithdrawForm({
                    formStatus: form.formStatus,
                    applicationId: form.applicationId,
                    fields,
                    readOnlyFields,
                    withdrawReasons,
                    formData: reduxStates.form.initialFormData,
                    templateId,
                    uid,
                    formId,
                    callFormAction,
                    setActionForm,
                });
                clearWithdrawFormValidities();
                clearWithdrawFormValue();
                setActionForm(withdrawForm);
                setWithdrawFormDisabledFields(readOnlyFields);
                setWithdrawFormValidators();
            },
    }),
    withProps(({ declineForm, callFormAction, assignOtherForm, withdrawForm }) => ({
        ACTION_TYPES,
        ACTIONS: {
            [ACTION_TYPES.decline]: async (e, { templateId, form, isViewingForm }) => {
                await declineForm(e, {
                    templateId,
                    form,
                    isViewingForm,
                });
            },
            [ACTION_TYPES.assignToSelf]: async (e, { uid, templateId, form, confirm }) => {
                await callFormAction({
                    uid,
                    templateId,
                    formId: form.id,
                    data: {
                        confirm,
                    },
                });
            },
            // TODO: add last assignee to the form
            [ACTION_TYPES.assignToOther]: async (
                e,
                { uid, templateId, form, isViewingForm, options },
            ) => {
                await assignOtherForm(e, {
                    uid,
                    assignees: options.assignees,
                    templateId,
                    form,
                    isViewingForm,
                });
            },
            [ACTION_TYPES.formHistory]: async (e, { templateId, form, history }) => {
                const formId = form.id;

                goToFormHistory({
                    templateId,
                    formId,
                    history,
                });
            },
            [ACTION_TYPES.withdraw]: async (
                e,
                { uid, templateId, form, isViewingForm, options },
            ) => {
                await withdrawForm(e, {
                    uid,
                    templateId,
                    form,
                    isViewingForm,
                    options,
                });
            },
            [ACTION_TYPES.split_application]: async (e, { uid, templateId, form }) => {
                const confirmMessage = showConfirmMessage({
                    message: 'Are you sure you want to split this application?',
                    onConfirm: async () => {
                        try {
                            await store.dispatch(
                                actionUpdateTemplateForm({
                                    formId: form.id,
                                    templateId,
                                    uid,
                                    data: {
                                        split_application: true,
                                    },
                                }),
                            );
                            store.dispatch(showSuccessfulMessage());
                        } catch (e) {
                            store.dispatch(showFailedMessage({ message: e.status_desc }));
                        }
                    },
                });
                store.dispatch(confirmMessage);
            },
        },
    })),
);

export default withActionsHandlers;
