import _flatten from 'lodash/flatten';

export const SET_PRESET_LIST = 'SET_PRESET_LIST';
export function setPresetList(id, list) {
    return {
        type: SET_PRESET_LIST,
        payload: {
            id,
            list,
        },
    };
}

export const SET_PRESET_LISTS = 'SET_PRESET_LISTS';
export function setPresetLists(lists) {
    return {
        type: SET_PRESET_LISTS,
        lists,
    };
}

export function actionGetList(id) {
    return dispatch => {
        if (parseInt(id) === 17) {
            dispatch(actionGetSgPostcode(id));
        } else if (parseInt(id) === 18) {
            dispatch(actionGetSgPostcodeMapStreetAndBuilding(id));
        }
    };
}

function actionGetSgPostcode(id) {
    return async dispatch => {
        try {
            dispatch(setPresetList(id, 'fetching'));
            // fetch postcode sg
            Promise.all([
                import('../../lists/Postcode_sg_018895_209473'),
                import('../../lists/Postcode_sg_209493_289678'),
                import('../../lists/Postcode_sg_289684_416489'),
                import('../../lists/Postcode_sg_416507_460185'),
                import('../../lists/Postcode_sg_460186_537918'),
                import('../../lists/Postcode_sg_537926_578153'),
                import('../../lists/Postcode_sg_578162_680552'),
                import('../../lists/Postcode_sg_680553_809068'),
                import('../../lists/Postcode_sg_809083_829981'),
            ]).then(chunks => {
                const list = _flatten(chunks.map(c => c.default));
                dispatch(setPresetList(id, list));
            });
        } catch (e) {
            console.log(e);
        }
    };
}

function actionGetSgPostcodeMapStreetAndBuilding(id) {
    return async dispatch => {
        try {
            dispatch(setPresetList(id, 'fetching')); // unset id: 'fetching' in lists state
            // fetch postcode map street, buiilding lists chunks
            Promise.all([
                import('../../lists/Postcode_sg_map_street_building_018895_209473'),
                import('../../lists/Postcode_sg_map_street_building_209493_289678'),
                import('../../lists/Postcode_sg_map_street_building_289684_416489'),
                import('../../lists/Postcode_sg_map_street_building_416507_460185'),
                import('../../lists/Postcode_sg_map_street_building_460186_537918'),
                import('../../lists/Postcode_sg_map_street_building_537926_578153'),
                import('../../lists/Postcode_sg_map_street_building_578162_680552'),
                import('../../lists/Postcode_sg_map_street_building_680553_809068'),
                import('../../lists/Postcode_sg_map_street_building_809083_829981'),
            ]).then(chunks => {
                const object = {};
                chunks.forEach(chunk => {
                    // Object.assign perform better here
                    Object.assign(object, chunk.default);
                });

                dispatch(setPresetList(id, object));
            });
        } catch (e) {
            console.log(e);
        }
    };
}
