import BaseAPI from '../BaseAPI';

export class GroupAPI extends BaseAPI {
    basePath = 'cap/groups/';

    async getGroups() {
        let groups;

        try {
            ({ groups = [] } = await this.get({
                path: this.basePath,
            }));
        } catch (e) {
            // handle
        }

        return groups;
    }
}
export const groupAPI = new GroupAPI();
export default GroupAPI;
