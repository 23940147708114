import BaseAPI from '../BaseAPI';

export class RoleAPI extends BaseAPI {
    basePath = 'cap/roles/';

    async getRoles() {
        let roles;

        try {
            ({ roles = [] } = await this.get({
                path: this.basePath,
            }));
        } catch (e) {
            // handle
        }

        return roles;
    }
}
export const roleAPI = new RoleAPI();
export default RoleAPI;
