import BaseAPI from '../../api/BaseAPI';
import _ from 'underscore';

export class CaptureEntryAPI extends BaseAPI {
    basePath = 'cap/entry-lists/';

    async getListItem({ selectedTab, uid }) {
        const path = `cap/${ selectedTab }-list-items/${ uid }/`;
        let data;
        try {
            data = await this.get({
                path,
            });
        } catch (e) {
            if (e.responseJSON) {
                e = Object.values(e.responseJSON.status_desc);
                throw new Error(e);
            } else {
                throw new Error('Error has occurred');
            }
        }
        return data;
    }

    async createListItem({ selectedTab, listItem }) {
        const path = `cap/${ selectedTab }-list-items/`;
        let data;
        try {
            data = await this.post({
                path,
                data: listItem,
            });
        } catch (e) {
            if (e.responseJSON) {
                const errors = _.mapObject(e.responseJSON.status_desc, function(val) {
                    return `${ val }`;
                });
                e = JSON.stringify(errors, null, '\t').replace(/[{}]/g, '');
                throw new Error(e);
            } else {
                throw new Error(e);
            }
        }
        return data;
    }

    async updateListItem({ selectedTab, uid, listItem }) {
        const path = `cap/${ selectedTab }-list-items/${ uid }/`;
        let data;
        try {
            data = await this.patch({
                path,
                data: listItem,
            });
        } catch (e) {
            if (e.responseJSON) {
                const errors = _.mapObject(e.responseJSON.status_desc, function(val) {
                    return `${ val }`;
                });
                e = JSON.stringify(errors, null, '\t').replace(/[{}]/g, '');
                throw new Error(e);
            } else {
                throw new Error(e);
            }
        }
        return data;
    }

    async deleteListItem({ selectedTab, uid, delete_reason, delete_remarks }) {
        const path = `cap/${ selectedTab }-list-items/${ uid }/`;
        let data;
        try {
            data = await this.delete({
                path,
                data: { delete_reason, delete_remarks },
            });
        } catch (e) {
            if (e.responseJSON) throw new Error(e.responseJSON);
            else throw new Error(e);
        }
        return data;
    }

    async getEntryLists(selectedTab) {
        const path = `${ this.basePath }?type=${ selectedTab }`;
        let data;
        try {
            data = await this.get({
                path,
            });
        } catch (e) {
            if (e.responseJSON) throw new Error(e.responseJSON);
            else throw new Error(e);
        }
        return data;
    }

    async getTableQuery({
        selectedEntryLists,
        page,
        fieldType,
        searchedInput,
        sorted,
        selectedTab,
    }) {
        let { id, desc } = sorted[0];

        if (id === 'entry_list') {
            id = 'entry_list__name';
        }

        let order = `order=${ id }`;
        if (desc) {
            order = `order=-${ id }`;
        }
        if (selectedEntryLists.length) {
            selectedEntryLists = selectedEntryLists.map(list => `list=${ list }`);
        }
        selectedEntryLists = selectedEntryLists.join('&');

        const path = `${ this.basePath }search?type=${ selectedTab }&${ order }&${ selectedEntryLists }&${ fieldType }__icontains=${ searchedInput }&page=${ page }`;

        let data;
        try {
            data = await this.get({
                path,
            });
        } catch (e) {
            if (e.responseJSON) {
                e = Object.values(e.responseJSON.status_desc);
                throw new Error(e);
            } else {
                throw new Error('Error has occurred');
            }
        }
        return data;
    }
}

const captureEntryAPI = new CaptureEntryAPI();
export default captureEntryAPI;
