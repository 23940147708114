export const SET_SHOW_WARNING = 'SET_SHOW_WARNING';
export function setShowWarning(showWarning) {
    return {
        type: SET_SHOW_WARNING,
        payload: {
            showWarning,
        },
    };
}

export const SET_COUNTDOWN = 'SET_COUNTDOWN';
export function setCountdown(countdown) {
    return {
        type: SET_COUNTDOWN,
        payload: {
            countdown,
        },
    };
}

export const SET_SESSION_TYPE = 'SET_SESSION_TYPE';
export function setSessionType(sessionType) {
    return {
        type: SET_SESSION_TYPE,
        payload: {
            sessionType,
        },
    };
}
