import {
    SET_FORM_TRIGGER,
    SET_FORM_TRIGGERS,
    SET_TRIGGERED_STATE,
    SET_TRIGGERED_STATES,
    CLEAR_TRIGGERED_STATES,
    SET_TRIGGER_DEPENDANT,
    SET_TRIGGER_DEPENDANTS,
} from '../actions/TriggerAction';
import DeepAssign from 'deep-assign';

const INITIAL_STATE = {
    triggers: {},
    states: {
        fieldsets: {},
    },
    dependants: {},
    fetching: false,
    error: null,
};

export default function triggerReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
    case SET_FORM_TRIGGER: {
        const { id, trigger } = action.payload;
        return {
            ...state,
            triggers: {
                ...state.triggers,
                [id]: trigger,
            },
        };
    }
    case SET_FORM_TRIGGERS: {
        const { triggers } = action;
        if (triggers === null) {
            return {
                ...state,
                triggers: {},
            };
        }
        return {
            ...state,
            triggers: {
                ...state.triggers,
                ...triggers,
            },
        };
    }
    case SET_TRIGGERED_STATE: {
        const { id, triggered } = action.payload;
        return {
            ...state,
            states: {
                ...state.states,
                [id]: triggered,
            },
        };
    }
    case SET_TRIGGERED_STATES: {
        const { states } = action;
        const deepAssign = {};
        DeepAssign(deepAssign, state.states, states);
        return {
            ...state,
            states: deepAssign,
        };
    }
    case CLEAR_TRIGGERED_STATES: {
        return {
            ...state,
            states: {},
        };
    }
    case SET_TRIGGER_DEPENDANT: {
        const { id, dependant } = action.payload;
        return {
            ...state,
            dependents: {
                ...state.dependents,
                [id]: dependant,
            },
        };
    }
    case SET_TRIGGER_DEPENDANTS: {
        const { dependants } = action;
        return {
            ...state,
            dependants: {
                ...state.dependants,
                ...dependants,
            },
        };
    }
    default:
        return state;
    }
}
