import { clientAPI } from '../../api/client/ClientAPI';
import SessionStorage from '../../components/SessionStorage';
import { generateLogoPath } from '../../components/Misc/client';
import { translateDatetimeFormats } from '../../strftime';

const session = new SessionStorage();

/**
 * ACTION CREATORS
 * Action Creator fetching status true / false
 */

export const SET_CLIENT_FETCHING = 'SET_CLIENT_FETCHING';
export const setClientFetching = fetching => {
    return {
        type: SET_CLIENT_FETCHING,
        payload: fetching,
    };
};

/** sets client */
export const SET_CLIENT = 'SET_CLIENT';
export const setClient = client => {
    return {
        type: SET_CLIENT,
        payload: client,
    };
};

/** error checker */
export const SET_CLIENT_ERROR = 'SET_CLIENT_ERROR';
export const setClientError = error => {
    return {
        type: SET_CLIENT_ERROR,
        payload: error,
    };
};

/** ACTION CREATORS END */

/** ACTION FUNCTIONS */

export const actionGetClient = () => {
    return async dispatch => {
        dispatch(setClientFetching(true));
        try {
            const { logo, primary_color, secondary_color, timezone, datetime_formats } =
                (await clientAPI.getCurrentClient()) || {};
            const client = {
                logo,
                primaryColor: primary_color,
                secondaryColor: secondary_color,
                timezone,
                datetimeFormats: translateDatetimeFormats(datetime_formats),
            };
            session.setClient(client);
            dispatch(
                setClient({
                    ...client,
                    logo: generateLogoPath(client.logo), // matched ? matched[0] : client.logo,
                }),
            );
        } catch (error) {
            dispatch(setClientError(error));
        } finally {
            dispatch(setClientFetching(false));
        }
    };
};

export const actionUpdateClient = ({ logo, primaryColor, secondaryColor }) => {
    return async dispatch => {
        dispatch(setClientFetching(true));
        const client = {
            logo,
            primary_color: primaryColor,
            secondary_color: secondaryColor,
        };
        // if it's undefined logo path, key is removed
        //  - this indicate there's no change of logo
        if (!client.logo) {
            delete client.logo;
        }
        try {
            const { logo, primary_color, secondary_color } =
                (await clientAPI.updateCurrentClient(client)) || {};

            const updatedClient = {
                logo,
                primaryColor: primary_color,
                secondaryColor: secondary_color,
            };
            session.setClient(updatedClient);
            dispatch(
                setClient({
                    ...updatedClient,
                    logo: generateLogoPath(updatedClient.logo),
                }),
            );
        } catch (error) {
            dispatch(setClientError(error));
        } finally {
            dispatch(setClientFetching(false));
        }
    };
};

/** ACTION FUNCTIONS END */
