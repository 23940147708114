import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

type History = Record<string, any>;

export interface FormActionState {
    histories: Record<string, History>; // { uid: history }
}

const initialState: FormActionState = {
    histories: {},
};

const formActionSlice = createSlice({
    name: 'FormActionReducer',
    initialState,
    reducers: {
        setFormActionHistories(state, action: PayloadAction<{ uid: string; history: History }[]>) {
            const dic: Record<string, History> = {};
            for (const { uid, history } of action.payload) {
                dic[uid] = history;
            }
            state.histories = dic;
        },
        updateFormActionHistory(state, action: PayloadAction<{ uid: string; history: History }>) {
            const { uid, history } = action.payload;
            const histories = state.histories;
            histories[uid] = history;
            state.histories = histories;
        },
    },
});

export const { setFormActionHistories, updateFormActionHistory } = formActionSlice.actions;
export default formActionSlice.reducer;
