import moment from 'moment-timezone';
import _ from 'underscore';
import { getClient } from './redux/selectors/ClientSelector';
import store from './store';

function getClientDatetimeFormats(client = getClient(store.getState())) {
    return client.datetimeFormats || {};
}

function sampleDatetime({ format, type = 'datetime' }) {
    format = format || getClientDatetimeFormats()[type];
    const datetime = moment().set({
        month: 11, // December
        date: 31,
        hour: 12,
        minute: 12,
    });
    if (_.isString(format)) {
        return datetime.format(format);
    } else if (_.isFunction(format)) {
        return format({ value: datetime });
    }
    return datetime.format(format);
}

export { getClientDatetimeFormats, sampleDatetime };
