import React from 'react';
import _ from 'underscore';

class Image extends React.Component {
    constructor(props) {
        super(props);

        this.props = props;
    }

    render() {
        const { src, title, name, className } = this.props;
        const classNames = ['image__action', _.isString(className) && className];

        return (
            <div className="image__container ">
                <div className="image__wrapper image__wrapper--circle">
                    <img src={src} alt={name} title={title} className={classNames.join(' ')} />
                </div>
            </div>
        );
    }
}

export default Image;
