import { templateViewAPI } from '../../api/template/TemplateViewAPI';
import { startLoading, stopLoading } from './PageAction';

// #### ACTION CREATORS #####

export const FETCHING = 'FETCHING';

export const fetching = fetching => {
    return {
        type: FETCHING,
        payload: fetching,
    };
};

// fetch current view
export const FETCH_VIEW = 'FETCH_VIEW';

export const fetchView = view => {
    return {
        type: FETCH_VIEW,
        payload: view,
    };
};

export const FETCH_FIELDSET_SECTIONS = 'FETCH_FIELDSET_SECTIONS';

export const fetchSections = choices => {
    return {
        type: FETCH_FIELDSET_SECTIONS,
        payload: choices,
    };
};

export const SET_SECTIONS = 'SET_SECTIONS';

export const setSection = sections => {
    return {
        type: SET_SECTIONS,
        payload: sections,
    };
};

export const SET_DELETE_FIELDSET_SECTION = 'SET_DELETE_FIELDSET_SECTION';

export const setDeleteFieldsetSection = fieldsetId => {
    return {
        type: SET_DELETE_FIELDSET_SECTION,
        payload: fieldsetId,
    };
};

// #### ACTION CREATORS END #####

// #### ACTION FUNCTIONS #####

// fetch the list of templates
export const actionFetchView = (templateId, viewId) => {
    // redux thunk dispatch
    return async dispatch => {
        dispatch(startLoading());
        dispatch(fetching(true));
        try {
            const view = await templateViewAPI.getCurrentView(templateId, viewId);
            dispatch(fetchView(view));
        } catch (error) {
            console.log('error', error);
        } finally {
            dispatch(fetching(false));
            dispatch(stopLoading());
        }
    };
};

export const actionFetchSections = templateId => {
    return async dispatch => {
        try {
            const sections = await templateViewAPI.getFieldsetSections(templateId);
            const sectionChoices = sections.map(section => section);
            dispatch(fetchSections(sectionChoices));
        } catch (error) {}
    };
};

export const actionUpdateSection = (templateId, viewId, view, hideformCallback) => {
    return async dispatch => {
        try {
            const sections = await templateViewAPI.updateSection(templateId, viewId, view);

            dispatch(setSection(sections));
            hideformCallback();
        } catch (error) {
            console.log('error', error);
        }
    };
};

export const actionSetOrderDown = (row, sections) => {
    return async (dispatch, getState) => {
        const state = getState();
        const { templateDetail = {} } = state.templateDetail;
        const { view = {} } = state.templateView;
        const templateId = templateDetail.id;
        const viewId = view.id;
        try {
            const id = row.id;
            const index = sections.indexOf(id);
            if (index < sections.length - 1) {
                sections.splice(index, 1);
                sections.splice(index + 1, 0, id);
            } else {
                // not shifting down.
                return;
            }
            const newView = {
                name: view.name,
                description: view.description,
                icon: view.icon,
                sections,
            };
            dispatch(actionUpdateSection(templateId, viewId, newView, () => {}));
        } catch (error) {
            console.log('error', error);
        }
    };
};

export const actionSetOrderUp = (row, sections = []) => {
    return async (dispatch, getState) => {
        const state = getState();
        const { templateDetail = {} } = state.templateDetail;
        const { view = {} } = state.templateView;
        const templateId = templateDetail.id;
        const viewId = view.id;
        try {
            const id = row.id;
            const index = sections.indexOf(id);
            if (index > 0) {
                sections.splice(index, 1);
                sections.splice(index - 1, 0, id);
            } else {
                // not shifting up.
                return;
            }
            const newView = {
                name: view.name,
                description: view.description,
                icon: view.icon,
                sections,
            };
            dispatch(actionUpdateSection(templateId, viewId, newView, () => {}));
        } catch (error) {
            console.log('error', error);
        }
    };
};

// #### ACTION FUNCTIONS END #####
