import moment from 'moment-timezone';
import { getClientDatetimeFormats } from '../../datetime';

export const DEFAULT_FORMAT = {
    datetime: 'YYYY-MM-DD HH:mm',
    datetimeDetailed: 'YYYY-MM-DD HH:mm:ssA',
    date: 'YYYY-MM-DD',
    time: 'hh:mm A',
    year: 'YYYY',
    monthYear: 'YYYY-MM',
    _time: 'HH:mm',
};

export const BACKEND_DATETIME_KEYS_MAPPING = {
    datetime: 'datetime',
    date: 'date',
    time: 'time',
    year: 'year',
    monthYear: 'month_year',
};

const DEFAULT_PROPS = ['created_dt', 'updated_dt'];

export function formatDate({
    date,
    format = `${DEFAULT_FORMAT.date} ${DEFAULT_FORMAT.time}`,
    timezone,
}) {
    return moment.tz(date, timezone).format(format);
}

/**
 * A method that format given date based on the type given and clients datetime formats
 *
 * @export
 * @param {*} {
 *   date {string|Date},
 *   format {string},
 *   type {string} e.g. 'datetime',
 *   timezone {strig} e.g 'Asia/Kuala Lumpur'
 * }
 * @returns {string} formatted datetime string
 */
export function formatClientDate({ date, format, type = 'datetime', timezone }) {
    if (!date) return '';
    const clientDatetimeFormats = getClientDatetimeFormats() || {};
    format = format || clientDatetimeFormats[type];

    if (!format) return date;
    return moment.tz(date, timezone).format(format);
}

/**
 * Formats an iso-8601 date string, using moment.
 *
 * @export
 * @param {string} isoString - date in iso format
 * @param {string} [format="YYYY/MM/DD"] - date format to be formatted to
 * @returns {string} The formatted date.
 */
export function isoFormat(isoString, format = DEFAULT_FORMAT.date) {
    const dt = moment(isoString);
    return dt.format(format);
}

/**
 * Formats an objects iso date string properties.
 *
 * @export
 * @param {Object} obj - obj with given props to be formatted
 * @param {string[]} [props=DEFAULT_PROPS]  - The array of object properties to format.
 * @param {string} [format=DEFAULT_FORMAT] - Moment format string to use
 * @returns {Object} Formatted object.
 */
export function isoFormatObj(obj, props = DEFAULT_PROPS, format = DEFAULT_FORMAT.date) {
    obj = { ...obj };
    props.forEach(prop => {
        const iso = obj[prop];
        obj[prop] = isoFormat(iso, format);
    });
    return obj;
}

/**
 * Maps a list of objects with iso props into formatted date props.
 *
 * @export
 * @param {Object[]} objs - The array of objects.
 * @param {string[]} [props=DEFAULT_PROPS]  - The array of object properties to format.
 * @param {string} [format=DEFAULT_FORMAT] - Moment format string to use
 * @returns {Object[]} Formatted objects.
 */
export function isoFormatMapper(objs, props = DEFAULT_PROPS, format = DEFAULT_FORMAT.date) {
    return objs.map(obj => {
        return isoFormatObj(obj, props, format);
    });
}

/**
 * Format datetime to milliseconds
 *
 * @export
 * @param {Object} Object - { datetime: String, format: String }
 * @returns {number} milliseconds
 */
export function formatToMilliseconds({ datetime, format }) {
    return moment(datetime, format).valueOf();
}

export function formatDatetimeWithTimezone({ datetime, format, timezone }) {
    return moment.tz(datetime, timezone).format(format);
}

export function getNow({ format, timezone }) {
    return moment.tz(timezone).format(format);
}

/**
 * A method that return month of given date: Date
 * It's like Date.prototype.getMonth(), month value return
 * will be in index.
 *
 * @param {Date} datetime - datetime for month retrieval
 * @param {string} timezone - timezone for the given datetime
 * @returns {number} month
 */
export function getMonth(datetime, timezone) {
    const month = moment.tz(datetime, timezone).format('M');
    return parseInt(month) - 1;
}

/**
 * A method that return full year of given date: Date
 * e.g. 2020 for 2020-01-01
 * @param {Date} datetime - datetime for full year retrieval
 * @param {string} timezone - timezone for the given datetime
 * @returns {number} fullYear
 */
export function getFullYear(datetime, timezone) {
    const year = moment.tz(datetime, timezone).format('YYYY');
    return parseInt(year);
}

/**
 * A method that return date of given date: Date
 * e.g. 1 for 2020-01-01
 * @param {Date} datetime - datetime for date retrieval
 * @param {string} timezone - timezone for the given datetime
 * @returns {number} date
 */
export function getDate(datetime, timezone) {
    const date = moment.tz(datetime, timezone).format('D');
    return parseInt(date);
}

/**
 * A method that return day of given date: Date
 * e.g. 3 for 2020-01-01, 3 represent Wednesday
 * @param {Date} datetime - datetime for day retrieval
 * @param {string} timezone - timezone for the given datetime
 * @returns {number} day
 */
export function getDay(datetime, timezone) {
    const day = moment.tz(datetime, timezone).day();
    return parseInt(day);
}

/**
 * A method that return hours of given date: Date
 * e.g. 12 for 2020-01-01 12:00
 * @param {Date} datetime - datetime for hours retrieval
 * @param {string} timezone - timezone for the given datetime
 * @returns {number} hours
 */
export function getHours(datetime, timezone) {
    const hours = moment.tz(datetime, timezone).format('HH'); // e.g. 04 for 04:00, 12 for 12:04
    return parseInt(hours);
}

/**
 * A method that return minutes of given date: Date
 * e.g. 11 for 2020-01-01 12:11
 * @param {Date} datetime - datetime for minutes retrieval
 * @param {string} timezone - timezone for the given datetime
 * @returns {number} minutes
 */
export function getMinutes(datetime, timezone) {
    const minutes = moment.tz(datetime, timezone).format('mm'); // e.g. 00 for 04:00, 04 for 12:04
    return parseInt(minutes);
}

/**
 * A method that return diff in days of given start and end dates
 *
 * @param {Date} start - start datetime diff
 * @param {Date} end - end datetime diff
 * @param {string} timezone - timezone for the given datetime diff
 * @returns {number} diff
 */
export function getDateDaysDiff(start, end, timezone) {
    const diff = moment
        .tz(start, timezone)
        .startOf('day')
        .diff(moment.tz(end, timezone).startOf('day'), 'days');
    return diff;
}

/**
 * A method that return diff in minutes of given start and end dates
 *
 * @param {Date} start - start datetime diff
 * @param {Date} end - end datetime diff
 * @param {string} timezone - timezone for the given datetime diff
 * @returns {number} diff
 */
export function getDateMinutesDiff(start, end, timezone) {
    const diff = moment.tz(start, timezone).diff(moment.tz(end, timezone), 'minutes');
    return diff;
}

/**
 * A method to count total years based on given datetime, 'from' and 'to'
 * @param {Object} param
 * {
 *   datetime: isostring datatime,
 *   format: 'YYYY-MM-DDTHH:mm:ss.SSSSZ',
 *   from: '2019-03-11T13:12:38.284Z',
 *   to: '2019-03-21T13:12:38.284Z' / 'today',
 *   decimals: number of decimals to show, default 1
 *   timezone: string timezone,
 * }
 * @returns {number} years
 */
export function countYears({ format, from, to, decimals = 1, timezone }) {
    let years;

    if (!from) {
        return 0;
    }
    from = timezone ? moment.tz(from, format, timezone) : moment(from, format);

    if (to === 'today') {
        to = timezone ? moment.tz(timezone) : moment();
    } else {
        to = timezone ? moment.tz(to, format, timezone) : moment(to, format);
    }

    // 3rd params accept bool that indicate either to show decimals or not
    years = to.diff(moment(from), 'year', true);
    years = years.toFixed(decimals);

    return years;
}

/**
 * A method to check whether given date value is in correct format 'formatFrom', then reformat
 * to indended format 'formatTo'.
 * Date format is in strict check mode.
 * @param {string} value - date string value
 * @param {string} formatFrom - string format of date string value, unnecessary if str is in ISO format, strict format check
 * @param {string} formatTo - string format to format date to
 * @param {string} timezone - date string value timezone
 * @returns {string} strDate - formatted date string
 */
export function getValidFormattedDate(value, formatFrom, formatTo, timezone) {
    // Check whether value is defined. Passing undefined to moment will set date to 'now'
    if (!value) return value;
    const momentDate = formatFrom ? moment(value, formatFrom, true) : moment(value);
    const isValid = momentDate.isValid();
    if (!isValid) return value;
    return formatDate({
        date: formatFrom ? moment.tz(value, formatFrom, true, timezone) : value,
        format: formatTo,
        timezone,
    });
}
