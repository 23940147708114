import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import ObjectPath from 'object-path';
import Label from '../Label';
import InputWithLabel from '../InputWithLabel';

import { compose, lifecycle, withHandlers } from 'recompose';
import store from '../../../store';
import { setValue } from '../../../redux/actions/ValueAction';
import { getValues, getValidities } from '../../../redux/selectors';
import { isTriggered } from '../FormRules';
import { setValidity } from '../../../redux/actions/ValidityAction';
import { processLabel } from './misc';

const LABEL_ID = 'label';
const PRODUCT_ID = 'product';
export const MID_ID = 'mid';
export const TID_ID = 'tid';

const ProductMIDTID = ({
    initialId,
    label,
    setPath,
    tabPath,
    index,
    bold,
    value,
    valueId,
    validity,
    trigger,
    triggeredStates,
    isEditable,
    isRequired,
    extend,
}) => {
    let isMidEditable, isTidEditable;
    const midValidity = validity[MID_ID] || {};
    const tidValidity = validity[TID_ID] || {};
    const midTrigger = trigger[MID_ID] || {};
    const tidTrigger = trigger[TID_ID] || {};

    if (_.isBoolean(isEditable)) {
        // when isEditable is boolean, both component editable
        //  state should be same as what defined
        isMidEditable = isEditable;
        isTidEditable = isEditable;
    } else {
        // when isEditable is object
        const { mid = true, tid = true } = isEditable;
        isMidEditable = mid;
        isTidEditable = tid;
    }

    const isMidRequired = isRequired[MID_ID];
    const isTidRequired = isRequired[TID_ID];

    const midLabel = processLabel({
        label: { title: 'MID' },
        isRequired: isMidRequired,
        isEditable: isMidEditable,
    });
    const tidLabel = processLabel({
        label: { title: 'TID' },
        isRequired: isTidRequired,
        isEditable: isTidEditable,
    });

    // to get the id that's pointed to the value
    valueId = valueId || initialId;

    return (
        <div className="product">
            <div className="product--label">
                <Label title={label.title} id={LABEL_ID} bold={bold} />
            </div>
            <div className="product--mid">
                <InputWithLabel
                    label={midLabel}
                    setId={valueId}
                    tabId={initialId}
                    setPath={setPath}
                    tabPath={tabPath}
                    tabIndex={index}
                    id={MID_ID}
                    initialId={MID_ID}
                    value={value[MID_ID]}
                    isEditable={isMidEditable}
                    isValid={midValidity.isValid}
                    errorMessage={midValidity.errorMessage}
                    trigger={midTrigger.data}
                    triggeredState={triggeredStates[MID_ID]}
                    showComponent={!isTriggered(triggeredStates[MID_ID])}
                    extend={extend[MID_ID]}
                />
            </div>
            <div className="product--tid">
                <InputWithLabel
                    label={tidLabel}
                    setId={valueId}
                    tabId={initialId}
                    setPath={setPath}
                    tabPath={tabPath}
                    tabIndex={index}
                    id={TID_ID}
                    initialId={TID_ID}
                    value={value[TID_ID]}
                    isEditable={isTidEditable}
                    isValid={tidValidity.isValid}
                    errorMessage={tidValidity.errorMessage}
                    trigger={tidTrigger.data}
                    triggeredState={triggeredStates[TID_ID]}
                    showComponent={!isTriggered(triggeredStates[TID_ID])}
                    extend={extend[TID_ID]}
                />
            </div>
        </div>
    );
};

ProductMIDTID.propTypes = {
    id: PropTypes.string,
    label: PropTypes.object,
    value: PropTypes.object,
    validity: PropTypes.object,
    triggers: PropTypes.object,
    triggeredStates: PropTypes.object,
    product: PropTypes.string.isRequired,
    isEditable: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    isRequired: PropTypes.object,
    extend: PropTypes.object,
};

ProductMIDTID.defaultProps = {
    label: {},
    value: {},
    validity: {},
    triggers: {},
    triggeredStates: {},
    isEditable: true,
    isRequired: {},
    extend: {},
};

export default compose(
    withHandlers({
        handleValueProductNameUpdate: props => () => {
            const { setPath, product } = props;
            const path = `${ setPath }.${ PRODUCT_ID }`;
            const values = getValues(store.getState());
            const productName = ObjectPath.get(values, path, null);

            if (!productName) {
                store.dispatch(
                    setValue({
                        path,
                        value: product,
                    }),
                );
            }
        },
        handleValidityProductNameUpdate: props => () => {
            const { tabPath, product } = props;
            const path = `${ tabPath }.${ PRODUCT_ID }`;
            const validities = getValidities(store.getState());
            const productName = ObjectPath.get(validities, path, null);

            if (productName !== product) {
                store.dispatch(
                    setValidity({
                        path: tabPath,
                        validity: {
                            [PRODUCT_ID]: product,
                        },
                    }),
                );
            }
        },
    }),
    lifecycle({
        componentDidMount() {
            this.props.handleValueProductNameUpdate();
            this.props.handleValidityProductNameUpdate();
        },
        componentDidUpdate() {
            this.props.handleValueProductNameUpdate();
        },
    }),
)(ProductMIDTID);
