import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import Button from '../ButtonWithIcon';
import Input from '../InputWithLabel';
import ErrorMessage from './ErrorMessage';
import { withCaptureFieldHandlers } from '../../Misc/forms';
import { showAlertMessage } from '../../Misc/forms/popUps';
import { compose, withStateHandlers } from 'recompose';
import store from '../../../store';
import Loader from './Loader';

const LOCATION_SERVICE_ALERT_MESSAGE =
    'Please check your Network Connection or turn\
                                        on your Location Services.';
const classWarning = 'warning';
const GPS = ({
    id,
    className,
    title,
    value,
    isEditable,
    vertical,
    loading,
    isValid,
    errorMessage,
    onClick,
    handleChange,
    setLocalValue,
    setLoading,
}) => {
    const cls = [
        'jpt--input input__gps',
        vertical && 'vertical',
        _.isString(className) && className,
        !isValid && classWarning,
    ];

    return (
        <div className={cls.join(' ')} id={id}>
            <div className="gps-button">
                <Button
                    title={title}
                    isEditable={!loading && isEditable}
                    onClick={(e, data) => {
                        if (loading) return;
                        setLoading(true);
                        getCoordinate(coordinate => {
                            onClick(e, {
                                value: coordinate,
                                ...data,
                            });
                            handleChange &&
                                handleChange(e, {
                                    value: coordinate,
                                });
                            setLocalValue(coordinate);
                            setLoading(false);
                        });
                    }}
                />
                <Loader className="gps-loader" show={loading} />
            </div>

            <Input className="gps-coordinate" value={value} isEditable={false} />
            <ErrorMessage message={errorMessage} />
        </div>
    );
};

GPS.defaultProps = {
    title: 'GPS Coordinate',
    isEditable: true,
    vertical: false,
    isValid: true,
    isLoading: false,
    onClick: () => {},
};

GPS.propTypes = {
    title: PropTypes.string,
    isEditable: PropTypes.bool,
    vertical: PropTypes.bool,
    isValid: PropTypes.bool,
    isLoading: PropTypes.bool,
    onClick: PropTypes.func,
};

export default compose(
    withCaptureFieldHandlers,
    withStateHandlers(
        ({ isLoading }) => ({
            loading: isLoading,
        }),
        {
            setLoading: () => loading => ({
                loading,
            }),
        },
    ),
)(GPS);

export function getCoordinate(cb) {
    const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    };

    navigator.geolocation.getCurrentPosition(success, error, options);

    function success(pos) {
        const crd = pos.coords;
        const coordinate = `${ crd.latitude }, ${ crd.longitude }`;
        cb(coordinate);
    }

    function error(err) {
        console.error(`GPS not working (${ err.code }): ${ err.message }`);
        store.dispatch(
            showAlertMessage({
                message: LOCATION_SERVICE_ALERT_MESSAGE,
            }),
        );
        cb();
    }
}
