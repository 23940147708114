import React from 'react';

import ObjectEntries from 'object.entries';
import ObjectPath from 'object-path';
import ObjectClone from 'clone';
import _ from 'underscore';
import store from '../../store';

import InputField from './InputField';
import InputWithButton from './InputWithButton';
import InputWithLabel from './InputWithLabel';
import InputWithNote from './InputWithNote';
import ButtonWithIcon from './ButtonWithIcon';
import ButtonUpload from './ButtonUpload';
import { IECheckedRadioGroup as RadioGroup } from './RadioGroup';
import { IECheckedCheckboxGroup as CheckboxGroup } from './CheckboxGroup';
import { IECheckedDropdown as Dropdown } from './Dropdown';
import Label from './Label';
import LabelValue from './LabelValue';
import Calendar from './Calendar';
import BreakLine from './BreakLine';
import Image from '../Image';
import TextArea from './TextArea';
import HistoricalRemarks from './HistoricalRemarks';
import GPS from './GPS';
import DateTimeRange from './DateTimeRange';
import Signatory from './Signatory';
import MIDTID from './MIDTID';
import { MID_ID, TID_ID } from './MIDTID/ProductMIDTID';
import ProductTypes from './ProductTypes';
import MDR from './MDR';
import Acceptance from './Acceptance';
import Copy from './Copy';
import EnhancedRiskScoringSummary from './RiskScoringSummary/EnhancedRiskScoringSummary';

import FieldsetBuilder from './FieldsetBuilder';
import { compose, withProps, shouldUpdate } from 'recompose';

// Misc
import {
    withProcessedProps,
    getFieldsets,
    filterByIds,
    getFields,
    processExtendedValues,
    withIsEditableUpdate,
} from '../Misc/forms';
import { ChooseFieldButton, GridAction } from '../Misc/template';
import { getIsRequired } from '../Misc/validators';
import { getClientDatetimeFormats } from '../../datetime';

import {
    textKey,
    labelKey,
    labelValueKey,
    buttonKey,
    selectKey,
    radioKey,
    checkboxKey,
    imageKey,
    breakLineKey,
    calendarKey,
    uploadButtonKey,
    textAreaKey,
    remarksKey,
    signatoryKey,
    acceptanceKey,
    gpsKey,
    dateTimeRangeKey,
    midtidKey,
    productTypesKey,
    mdrKey,
    isTriggered,
    convertDisabledState,
    standardMDRsKey,
    productsKey,
    copyKey,
    standardMDRsListKey,
    riskScoringSummaryKey,
    creditScoringSummaryKey,
} from './FormRules';

// Selector
import { getLists } from '../../redux/selectors/ListsSelector';
import { getSchema } from '../../redux/selectors';
import EnhancedCreditScoringSummary from './CreditScoringSummary/EnhancedCreditScoringSummary';
import InputWithAddOn from './InputWithAddOn';

// Recompose
const withShouldUpdate = compose(
    shouldUpdate((current, next) => {
        return !_.isEqual(current, next);
    }),
);

interface GridBuilderProps {
    fields: any[];
    fieldsets: any[];
}

class GridBuilder extends React.Component<GridBuilderProps> {
    EnhancedInputLabel = withShouldUpdate(InputWithLabel);
    EnhancedInputNote = withShouldUpdate(InputWithNote);
    EnhancedFSBuilder = withShouldUpdate(FieldsetBuilder);
    EnhancedCopy = withIsEditableUpdate(Copy);

    static defaultProps = {
        className: '',
        fields: [],
        fieldsets: [],
        isEditable: true,
    };
    render() {
        const {
            className,
            fields,
            fieldsets,
            isEditable,
            path,
            // utils
            isEditingField,
            setId,
            tabIndex,
            tabId,
            setPath,
            tabPath,

            // component to pass to children
            values,
            validities,
            triggers,
            triggeredStates,
            disabledFields = {},
            fieldsetsFields = {},
            schema,
        } = this.props;
        const extraProps = {
            values,
            validities,
            triggers,
            triggeredStates,
            disabledFields,
            fieldsetsFields,
        };
        let components;

        if (fieldsets.length) {
            components = ObjectEntries(fieldsets).map((fieldset, index) => {
                const [key, value] = fieldset;
                const fieldIds = fieldsetsFields[value.id] || [];
                const fieldsetIds = getFieldsets(value);
                const ids = [...fieldIds, value.id, ...fieldsetIds];
                const fsValues = filterByIds(values, ids);
                const fsTriggeredStates = filterByIds(triggeredStates, ids);
                const fsValidities = filterByIds(validities, ids);

                return (
                    <this.EnhancedFSBuilder
                        {...{
                            isEditingField,
                            fieldname: { parent: `${path}.fieldsets`, current: key },
                            isParentEditable: isEditable,
                        }}
                        fieldset={value}
                        key={value.id || index}
                        className={className}
                        tabIndex={tabIndex}
                        setId={setId}
                        tabId={tabId}
                        setPath={setPath}
                        tabPath={tabPath}
                        {...value}
                        {...extraProps}
                        values={fsValues}
                        triggeredStates={fsTriggeredStates}
                        validities={fsValidities}
                    />
                );
            });
        } else {
            components = ObjectEntries(fields).map((field, index) => {
                let isRequired;
                const [key, obj = {}] = field;
                let { type, button, label, note, id, valueId, value, extend, custom_properties } =
                    obj;
                const initialId = id;
                id = valueId || id;
                const valuePath = setPath ? `${setPath}.${id}` : id;
                const defaultValue = value;

                if (id === 'risk_score' && typeof values[id] === 'number') {
                    values[id] = values[id].toString();
                }

                const initialValue = ObjectPath.get(values, id, null);
                value = initialValue == null ? value : initialValue;
                const validity = ObjectPath.get(validities, obj.id, {});
                const trigger = ObjectPath.get(triggers, initialId, {}).data;
                const triggeredState = ObjectPath.get(triggeredStates, initialId, null);

                const { extendedValues, parents } = processExtendedValues({ extend, values });

                parents.forEach(id => {
                    delete values[id];
                });
                const newValues = {
                    ...values,
                    ...extendedValues,
                };

                if (getIsRequired(schema, obj.id)) {
                    isRequired = true;
                }

                const props = {
                    ...obj,
                    key: obj.id || index,
                    isEditingField,
                    setId: setId || initialId,
                    tabIndex,
                    tabId: tabId || id,
                    setPath,
                    tabPath,
                    fieldname: {
                        parent: `${path}.fields`,
                        current: `${key}`,
                    },
                    isParentEditable: isEditable,
                    isEditable: !disabledFields[initialId],
                    isRequired,
                    defaultValue,
                    initialId,
                    initialValue,
                    values: newValues,
                    value,
                    ...validity, // { isValid, errorMessage }
                    trigger: obj.trigger || trigger,
                    triggeredState,
                    showComponent: !isTriggered(triggeredState),
                    valuePath,
                };

                if (type === labelKey) {
                    return <Label {...props} />;
                } else if (type === labelValueKey) {
                    return <LabelValue {...props} />;
                } else if (type === textKey) {
                    if (button) {
                        return <InputWithButton {...props} />;
                        // eslint-disable-next-line camelcase
                    } else if (custom_properties && custom_properties.addOnDropdownComponent) {
                        return <InputWithAddOn {...props} />;
                    } else if (label || note) {
                        if (label && _.isEmpty(note)) return <this.EnhancedInputLabel {...props} />;
                        else return <this.EnhancedInputNote {...props} />;
                    } else {
                        return <InputField {...props} />;
                    }
                } else if (type === buttonKey) {
                    return <ButtonWithIcon {...props} />;
                } else if (type === uploadButtonKey) {
                    return <ButtonUpload {...props} />;
                } else if (type === radioKey) {
                    return <RadioGroup {...props} />;
                } else if (type === selectKey) {
                    return <Dropdown {...props} />;
                } else if (type === checkboxKey) {
                    return <CheckboxGroup {...props} />;
                } else if (type === imageKey) {
                    return <Image {...props} />;
                } else if (type === breakLineKey) {
                    return <BreakLine {...props} />;
                } else if (type === calendarKey.default) {
                    const calendarType = props.calendarType || calendarKey.default;
                    props.format = getClientDatetimeFormats()[calendarType];
                    return <Calendar {...props} />;
                } else if (type === textAreaKey) {
                    return <TextArea {...props} rows={custom_properties?.textareaRows} />;
                } else if (type === remarksKey) {
                    props.format = getClientDatetimeFormats()['datetime'];
                    return <HistoricalRemarks {...props} rows={custom_properties?.textareaRows} />;
                } else if (type === signatoryKey) {
                    props.format = getClientDatetimeFormats()['datetime'];
                    return <Signatory {...props} />;
                } else if (type === acceptanceKey) {
                    return <Acceptance {...props} />;
                } else if (type === gpsKey) {
                    return <GPS {...props} />;
                } else if (type === copyKey) {
                    return <this.EnhancedCopy {...props} />;
                } else if (type === dateTimeRangeKey) {
                    return <DateTimeRange {...props} />;
                } else if (type === midtidKey) {
                    // using products list to get each product
                    //   mid tid editable state
                    // producst : [{ title, value }, { ... }]
                    const isMIDTIDEditable = {};
                    const products = getLists(store.getState())[productsKey];
                    products.forEach(p => {
                        // if parent is not editable child shouldn't be editable
                        isMIDTIDEditable[p.value] = disabledFields[initialId]
                            ? false
                            : !disabledFields[p.value];
                    });

                    props.isRequired = {
                        [MID_ID]: getIsRequired(schema, MID_ID),
                        [TID_ID]: getIsRequired(schema, TID_ID),
                    };

                    return <MIDTID {...props} isEditable={isMIDTIDEditable} validity={validity} />;
                } else if (type === productTypesKey) {
                    return <ProductTypes {...props} />;
                } else if (type === mdrKey) {
                    if (_.isObject(disabledFields[standardMDRsKey]))
                        props.isEditable = convertDisabledState(disabledFields[standardMDRsKey]);

                    const mdrs = getLists(store.getState())[standardMDRsListKey] || [];
                    props.isRequired = {};
                    mdrs.forEach(mdr => {
                        props.isRequired[mdr.value] = getIsRequired(schema, mdr.value);
                    });

                    // add triggers as props to MDR field
                    props.triggers = triggers;
                    props.triggeredState = _.isObject(triggeredState) ? triggeredState : [];

                    return <MDR {...props} validity={validity} />;
                } else if (type === riskScoringSummaryKey) {
                    return <EnhancedRiskScoringSummary {...props} />;
                } else if (type === creditScoringSummaryKey) {
                    return <EnhancedCreditScoringSummary {...props} />;
                }
            });
        }

        return (
            <div className={className}>
                {components}

                {this.props.isEditingField && <ChooseFieldButton path={`${path}.fields`} />}

                {this.props.isEditingGrid && <GridAction path={path} />}
            </div>
        );
    }
}

export default compose(
    // filter out the necessary values
    // for the component
    withProps(
        ({
            fieldsets = [], // exist when there's fieldset inside a grid
            fields = [],
            values,
            validities,
            triggers = {},
            triggeredStates,
            fieldsetsFields = {},
        }) => {
            const isGridWithFieldsets = fieldsets.length;
            let ids = [];

            if (isGridWithFieldsets) {
                // Get fields by mapping with fieldsetsFields
                // using id of each fieldset
                const fieldIds = _.uniq(
                    _.flatten(
                        fieldsets.map(f => {
                            return fieldsetsFields[f.id] || [];
                        }),
                    ),
                );
                const fieldsetIds = getFieldsets({ fieldsets });
                ids = [...fieldIds, ...fieldsetIds];
            } else {
                const lists = getLists(store.getState());
                let mdrs = lists[standardMDRsKey] || [];
                mdrs = mdrs.map(mdr => mdr.value);
                const fieldIds = getFields(fields, triggers, mdrs);
                ids = fieldIds;
            }
            values = ObjectClone(filterByIds(values, ids));
            validities = ObjectClone(filterByIds(validities, ids));
            triggers = ObjectClone(filterByIds(triggers, ids));
            triggeredStates = ObjectClone(filterByIds(triggeredStates, ids));

            return {
                ids,
                values,
                validities,
                triggers,
                triggeredStates,
                schema: getSchema(store.getState()),
            };
        },
    ),
    withProcessedProps,
)(GridBuilder);
