import React from 'react';
import _ from 'underscore';
import { compose, shouldUpdate, withHandlers } from 'recompose';
import { connect } from 'react-redux';

import { getDownloadingFiles } from '../redux/selectors/FormSelector';
import store from '../store';
import { actionSetDownloadingFiles } from '../redux/actions/FormAction';

const DownloadIndicator = ({ files = [], show }) => {
    const classnames = ['download-indicator-container', show ? 'show' : ''];
    return (
        <div className={classnames.join(' ')}>
            <div className="download-header">{files.length ? 'Downloading...' : 'Done'}</div>
            {files.map(({ name }) => (
                <div className="download-item">
                    <i className="circle icon outline" />
                    <span data-rh={name}>{name}</span>
                </div>
            ))}
        </div>
    );
};

const withDownloadingFileCheck = compose(
    connect(
        store => {
            const files = getDownloadingFiles(store);

            return {
                files,
                show: files.length,
            };
        },
        null,
    ),
    shouldUpdate((current, next) => {
        return !_.isEqual(current, next);
    }),
);

export const withDownloadFileHandlers = withHandlers({
    pushDownloadingFiles: () => file => {
        const downloadingFiles = getDownloadingFiles(store.getState());
        downloadingFiles.push(file);
        store.dispatch(actionSetDownloadingFiles(downloadingFiles));
    },
    popDownloadingFiles: () => () => {
        const downloadingFiles = getDownloadingFiles(store.getState());
        downloadingFiles.pop();
        store.dispatch(actionSetDownloadingFiles(downloadingFiles));
    },
    popDownloadingFileById: () => ({ id, idKey = 'id' }) => {
        let downloadingFiles = getDownloadingFiles(store.getState());
        downloadingFiles = downloadingFiles.filter(file => file[idKey] !== id);
        store.dispatch(actionSetDownloadingFiles([...downloadingFiles]));
    },
});

export default DownloadIndicator;
const EnhancedDownloadIndicator = withDownloadingFileCheck(DownloadIndicator);

export { EnhancedDownloadIndicator };
