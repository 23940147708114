import { startLoading, stopLoading } from './PageAction';
// using require to temporary solve TypeError: Class extends value undefined is not a constructor or null
const featureAPI = require('../../api/feature/FeatureAPI');
const { templateAPI } = require('../../api/template/TemplateAPI');

export const SET_FEATURES_CHOICES = 'SET_FEATURES_CHOICES';
// Choices is an object
function setFeatureChoices(choices) {
    return {
        type: SET_FEATURES_CHOICES,
        choices,
    };
}

// Choices as an array for the following setChoices.
function setViewAccessChoices(choices) {
    return setFeatureChoices({ view_access: choices });
}

export const SET_FEATURES = 'SET_FEATURES';
function setFeatures(features) {
    return {
        type: SET_FEATURES,
        features,
    };
}

/**
 * Set features from an array.
 *
 * Useful for use with api calls, which return data as an array.
 *
 * @param {Object[]} features - list of features
 * @returns {object} Object - A redux action
 */
function setFeaturesFromArray(features) {
    // converts an array of features into an object, indexed by ID.
    features = features.reduce((obj, feature) => {
        obj[feature.id] = feature;
        return obj;
    }, {});

    return setFeatures(features);
}

export const SET_FEATURES_FETCHING = 'SET_FEATURES_FETCHING';
const setFeaturesFetching = fetching => ({
    type: SET_FEATURES_FETCHING,
    fetching,
});

export const SET_FEATURES_ERROR = 'SET_FEATURES_ERROR';
const setFeaturesError = error => ({
    type: SET_FEATURES_ERROR,
    error,
});

export function clearFeatureErrors() {
    return setFeaturesError(null);
}

function startFetching(dispatch) {
    dispatch(setFeaturesFetching(true));
    dispatch(setFeaturesError(null));
}

function stopFetching(dispatch) {
    dispatch(setFeaturesFetching(false));
}

export function fetchViewAccessChoices(templateId) {
    return async dispatch => {
        startFetching(dispatch);

        try {
            const accesses = await templateAPI.getTemplateAccesses(templateId);
            const accessChoices = accesses.map(({ name, id }) => ({ title: name, value: id }));

            dispatch(setViewAccessChoices(accessChoices));
        } catch (e) {
            console.error(e);
            throw e;
            // TODO handling in future.
        } finally {
            stopFetching(dispatch);
        }
    };
}

//
// Feature CRUD actions
//
export function fetchFeatures() {
    return async dispatch => {
        startFetching(dispatch);

        try {
            const { features, options } = await featureAPI.getFeatureList();

            dispatch(setFeaturesFromArray(features));
            dispatch(setFeatureChoices(options.choices));
        } catch (e) {
            dispatch(setFeaturesError(e));
        } finally {
            stopFetching(dispatch);
        }
    };
}

export function createFeature(data) {
    return async (dispatch, getState) => {
        const { features } = getState().feature;
        startFetching(dispatch);

        try {
            const feature = await featureAPI.createFeature(data);
            features[feature.id] = feature;
            dispatch(setFeatures(features));
        } catch (e) {
            dispatch(setFeaturesError(e));
        } finally {
            stopFetching(dispatch);
        }
    };
}

export function updateFeature(id, data) {
    return async (dispatch, getState) => {
        const { features } = getState().feature;
        startFetching(dispatch);

        try {
            const feature = await featureAPI.updateFeature(id, data);
            features[feature.id] = feature;
            dispatch(setFeatures(features));
        } catch (e) {
            dispatch(setFeaturesError(e));
        } finally {
            stopFetching(dispatch);
        }
    };
}

export function deleteFeature(id) {
    return async (dispatch, getState) => {
        const { features } = getState().feature;
        startFetching(dispatch);

        try {
            await featureAPI.deleteFeature(id);
            // Remove feature from state
            delete features[id];
            dispatch(setFeatures(features));
        } catch (e) {
            dispatch(setFeaturesError(e));
        } finally {
            stopFetching(dispatch);
        }
    };
}

export const downloadFileApi = ({
    page,
    contentType = 'application/json',
    dataType = 'json',
}) => dispatch => {
    // show loading
    dispatch(startLoading());

    // 1. Prepare callback
    const successCallback = (data, _, jqXHR) => {
        // hide loading
        dispatch(stopLoading());

        // 3. Prepare Content
        const blob = new Blob([data], { type: contentType });

        // 4. Prepare filename
        const disposition = jqXHR.getResponseHeader('Content-Disposition');
        const matches = /"([^"]*)"/.exec(disposition);
        const filename = matches != null && matches[1] ? matches[1] : 'file';

        // 5. Setup the element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        // 6. Download
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // 2. Retrieve the content
    featureAPI.getApiLink({ page, contentType, dataType, successCallback });

    return page;
};
