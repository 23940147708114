import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';

const StyledToolTipWrapper = styled.div`
    position: relative;

    &:hover,
    &:focus {
        .tooltip-text {
            visibility: visible;
            opacity: 1;
        }
    }

    @media screen and (max-width: 480px) {
        &:hover,
        &:focus {
            .tooltip-background {
                display: block;
                visibility: visible;
                opacity: 1;
            }
        }
    }
`;

const StyledToolTipBackground = styled.div`
    position: fixed;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.5);
    display: none;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.37s ease-in;
    pointer-events: none;
`;

const StyledToolTipIcon = styled.div`
    position: relative;
    width: 15px;
    height: 15px;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #7b7b7b;
    font-weight: normal;
    color: #7b7b7b;
    float: right;
    top: 2px;

    &::after {
        content: 'i';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 12px;
    }
`;

const StyledToolTipText = styled.div`
    position: absolute;
    top: 10px;
    text-align: left;
    width: 360px;
    padding: 8px 16px;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    background-color: white;
    line-height: 20px;
    font-weight: normal;
    z-index: 2;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.37s ease-in;

    &.tooltip-text-left {
        right: 0;
    }

    &.tooltip-text-right {
        left: 0;

        @media screen and (max-width: 600px) {
            left: -180px;
        }

        @media screen and (max-width: 480px) {
            left: 50%;
            right: 50%;
        }
    }

    .html-wrapper {
        font-weight: normal;
        white-space: normal;
    }

    @media screen and (max-width: 480px) {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: calc(100vw - 32px);
    }
`;

const TreeMenuToolTip = ({ text, onClick, position = 'left' }) => {
    // sanitize text
    text = DOMPurify.sanitize(text, { SAFE_FOR_JQUERY: true, ADD_ATTR: ['target'] });
    return (
        <StyledToolTipWrapper
            className="tooltip"
            tabIndex="0"
            role="button"
            onClick={e => {
                e.stopPropagation();
                onClick(e);
            }}>
            <StyledToolTipBackground className="tooltip-background" />
            <StyledToolTipIcon className="tooltip-icon" />
            <StyledToolTipText
                className={`tooltip-text tooltip-text-${ position }`}
                dangerouslySetInnerHTML={{ __html: text }}
            />
        </StyledToolTipWrapper>
    );
};

TreeMenuToolTip.defaultProps = {
    text: null,
    onClick: () => {},
};

TreeMenuToolTip.propTypes = {
    text: PropTypes.string,
    onClick: PropTypes.func,
};

export default TreeMenuToolTip;
