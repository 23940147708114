import { compose, withHandlers } from 'recompose';

const withChangeHandler = compose(
    withHandlers({
        handleChange: ({
            isEditable,
            // handlers
            setLocalValue,
            updateValue,
        }) => (e, { value, inputValue }) => {
            isEditable && setLocalValue(value);

            updateValue({ value, inputValue });
        },
    }),
);

export default withChangeHandler;
