import { createSelector } from 'reselect';
import { RootState } from 'store';

const validitiesSelector = (state: RootState) => state.validity.validities;
const validitiesByIdsSelector = (state: RootState, ids: string[]) => {
    const validities = state.validity.validities;
    const filteredValidities: Record<string, any> = {};
    Object.entries(validities).forEach(([id, value]) => {
        const found = ids.indexOf(id) > -1;
        if (found) {
            filteredValidities[id] = value;
        }
    });
    return filteredValidities;
};

export const getValidities = createSelector(validitiesSelector, validities => validities);

export const getValiditiesByIds = createSelector(validitiesByIdsSelector, validities => validities);
