import _ from 'underscore';
import { withHandlers, compose } from 'recompose';
import { getValidFormattedDate, DEFAULT_FORMAT } from '../datetime';
import * as FR from '../../Form/FormRules';
import replaceValidation from './replaceValidation';

const withChangeFieldValueValidityHandler = compose(
    withHandlers({
        // Handler to update field value
        //  and valdiidty with checking
        changeFieldValueValidity: ({
            type,
            timezone,
            format,
            validityTargetObj,
            // props
            valuePath,
            validityPath,
            initialId,
            isEditable,
            // actions
            setSchema,
            dependencyCheck,
            onValueChanged,
            onValidityChange,
            generateExtendingProps,
        }) => ({ inputValue, value, force }) => {
            // DEFAULT_FORMAT.datetime is the format of date that would be kept in the db now.

            // if date is valid, format to form submission format
            if (type === FR.calendarKey.default && value) {
                if (inputValue)
                    // user inputted value will be handled here, Invalid Date e.g '12/12/20'
                    value = getValidFormattedDate(value, format, DEFAULT_FORMAT.datetime, timezone);
                else value = getValidFormattedDate(value, null, DEFAULT_FORMAT.datetime, timezone);
            } else if (type === FR.dateTimeRangeKey && !_.isEmpty(value)) {
                const { from, to } = value;
                value = {
                    from: getValidFormattedDate(from, format, DEFAULT_FORMAT.datetime, timezone),
                    to: getValidFormattedDate(to, format, DEFAULT_FORMAT.datetime, timezone),
                };
            }

            const extendingProps = generateExtendingProps();

            const newSchema = replaceValidation({
                extendingProps,
                id: initialId,
                path: valuePath,
            });
            if (newSchema) setSchema(newSchema);

            const validity = FR.validateInput({ id: initialId, value });

            validityTargetObj &&
                validityTargetObj.forEach(obj => {
                    const byKey = Object.keys(obj);
                    const checkByMatchCurrentId = _.isEqual(byKey[0], initialId);
                    const checkArrayByMatchCurrentId = _.contains(byKey, initialId);

                    if (checkArrayByMatchCurrentId || checkByMatchCurrentId) {
                        return onValidityChange({
                            id: Object.values(obj),
                            validity: { isValid: true, errorMessage: '' },
                        });
                    }
                });

            isEditable &&
                onValidityChange({
                    id: validityPath,
                    validity,
                });

            (isEditable || force) &&
                onValueChanged({
                    id: valuePath,
                    value,
                });

            dependencyCheck();
        },
    }),
);

export default withChangeFieldValueValidityHandler;
