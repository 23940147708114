
import Table from './Table';
import { Sibling } from 'redux/reducers/FormReducer';

interface Props {
    siblings: Sibling[]
}

export default function History({ siblings }: Props) {

    // Siblings could be null
    const rows = siblings && siblings.length ? siblings : [];

    return <Table
        data={{
            headers: [
                { 'title': 'Application ID', 'name': 'applicationId' },
                { 'title': 'Created Date', 'name': 'createdDt' },
                { 'title': 'Updated Date', 'name': 'updatedDt' },
                { 'title': 'Form Status', 'name': 'formStatus' },
            ],
            rows: rows
        }}
        links={{
            applicationId: {
                onClick: {
                    preset: 'new page',
                    path: '/submitted',
                    params: ['template', 'pk']
                }
            }
        }}
    />;
}