import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import uuid4 from 'uuid4';
import ObjectPath from 'object-path';
import DuplicableFieldset from './DuplicableFieldset';
import store from '../../store';
import { compose } from 'redux';

import { lifecycle, shouldUpdate, withStateHandlers } from 'recompose';

// SELECTORS
import { getTriggers, getValues } from '../../redux/selectors';
import { getLists } from '../../redux/selectors/ListsSelector';

// ACTIONS
import { setValue } from '../../redux/actions/ValueAction';
import { setTriggers, setTriggeredDependants } from '../../redux/actions/TriggerAction';
import { setValidators } from '../../redux/actions/ValidatorAction';
import { setPresetList } from '../../redux/actions/ListAction';

// MISC
import {
    getFields,
    getDependants,
    getFormObjRelationships,
    filterByIds,
    withIsEditableUpdate,
} from '../Misc/forms';
import { cleanValue, terminalConfigurationKey } from './FormRules';

const ID = 'terminal_configuration';
const NAME = 'Terminal Type';
// Component is fully coupled with redux state when come to value change
// User got not control in change by field editable state,
//   triggeredState, and also validators for the field
const TerminalConfiguration = ({
    fieldname,
    id = ID,
    valueId,
    name,
    set,
    max,
    isEditable,
    value,
    validity,

    triggers,
    triggeredState,
    fieldsetsFields,
}) => {
    // to handle null value
    triggeredState = triggeredState || [{}];

    return (
        <DuplicableFieldset
            className="duplicate-set"
            type={terminalConfigurationKey}
            id={id}
            initialId={id}
            setId={valueId || id}
            tabId={id}
            fieldname={fieldname}
            name={name || NAME}
            tabs={set}
            value={value}
            validity={validity}
            isEditable={isEditable}
            triggers={triggers}
            triggeredState={triggeredState}
            fieldsetsFields={fieldsetsFields}
            tabView={false}
            allowAddMore={true}
            allowRemove={true}
            tabsLoadedNo={1}
            max={max}
            onAfterTabAdd={({ path, index }) => {
                const hasUuid = ObjectPath.get(value, `${ index }.${ ID }`, null);
                if (!isEditable || hasUuid) return;

                const uuid = uuid4();
                // update value by path
                store.dispatch(
                    setValue({
                        path: `${ path }.${ index }.${ ID }`,
                        value: uuid,
                    }),
                );
            }}
        />
    );
};

TerminalConfiguration.propTypes = {
    fieldname: PropTypes.object,
    id: PropTypes.string,
    name: PropTypes.string,
    set: PropTypes.array.isRequired,
    value: PropTypes.array,
    validity: PropTypes.array,
    isEditable: PropTypes.bool,
    triggers: PropTypes.object,
    triggeredState: PropTypes.array,
    onChange: PropTypes.func,
};

TerminalConfiguration.defaultProps = {
    fieldname: {},
    id: ID,
    name: NAME,
    set: [{}],
    value: [{}],
    validity: [{}],
    isParentEditable: true,
    isEditable: true,
    triggers: {},
    triggeredState: [{}],
    onChange: _.noop,
};

export default compose(
    shouldUpdate((props, nextProps) => {
        if (!_.isEqual(props, nextProps)) return true;

        return false;
    }),
    withStateHandlers(({ id, set, triggers, fieldsetsFields: parentFieldsetsFields = {} }) => {
        id = id || ID;
        const fields = getFields(set);
        triggers = triggers || getTriggers(store.getState());

        triggers = {
            accessories_label: {
                data: {
                    thisLevel: true,
                    by: 'device_type',
                    when: {
                        valueIsNotNull: true,
                    },
                },
                target: 'accessories_label',
            },
            accessories: {
                data: {
                    thisLevel: true,
                    by: 'device_type',
                    when: {
                        valueIsNotNull: true,
                    },
                },
                target: 'accessories',
            },
            ...triggers,
        };

        const { fieldsetsFields } = getFormObjRelationships(
            {
                sections: [
                    {
                        set,
                    },
                ],
            },
            triggers,
        );

        return {
            fieldsetsFields: {
                ...parentFieldsetsFields,
                [id]: fields,
                ...fieldsetsFields,
            },
            triggers: filterByIds(triggers, fields),
        };
    }, null),
    lifecycle({
        componentDidUpdate({ value: prevValue }) {
            const { value, onChange, id = ID, name = NAME } = this.props;
            // to run onChange when value is updated in redux state
            if (!_.isEqual(value, prevValue)) {
                const lists = getLists(store.getState());
                const currentList = lists[id];

                _.isFunction(onChange) && onChange(null, value);

                const list = value.map((v, i) => ({
                    title: v.name ? v.name : `${ name } ${ i + 1 }`,
                    value: v[ID],
                }));

                // will not update terminal configuraiton when there's no changes
                if (!_.isEqual(currentList, list)) store.dispatch(setPresetList(id, list));
            }
        },
        componentDidMount() {
            // redux state
            const id = this.props.id || ID;
            const values = getValues(store.getState());
            const { setPath, triggers, validators, name = NAME } = this.props;
            const path = setPath ? `${ setPath }.${ id }` : id;
            let { value } = this.props;
            value = value && value.length ? value : ObjectPath.get(values, path, []);
            // to check when component loaded, first terminal configuration uuid value is defined or not
            if (!value[0] || !value[0][ID]) {
                if (!value[0]) value[0] = {};
                const uuid = uuid4();
                value[0][ID] = uuid;

                store.dispatch(
                    setValue({
                        path,
                        value,
                    }),
                );
            }
            // set triggers and trigger dependants if triggers is given
            if (triggers) {
                const triggersArr = cleanValue(Object.entries(triggers).map(([_, v]) => v));
                const dependants = getDependants(triggersArr);
                store.dispatch(setTriggeredDependants(dependants));
                store.dispatch(setTriggers(triggers));
            }

            // set validators
            if (validators) {
                store.dispatch(setValidators(validators));
            }

            // set preset list
            if (value.length) {
                const list = value.map((v, i) => ({
                    title: v.name ? v.name : `${ name } ${ i + 1 }`,
                    value: v[ID],
                }));
                store.dispatch(setPresetList(id, list));
            }
        },
    }),
    withIsEditableUpdate,
)(TerminalConfiguration);
