import {
    SET_FEATURES,
    SET_FEATURES_FETCHING,
    SET_FEATURES_ERROR,
    SET_FEATURES_CHOICES,
} from '../actions/FeatureAction';

const INITIAL_STATE = {
    features: {},
    // Used to populate form dropdown options
    choices: {
        type: [],
        roles: [],
        template: [],
        view_access: [],
    },
    fetching: false,
    error: null,
};

export default function featureReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
    case SET_FEATURES:
        const { features } = action;
        return {
            ...state,
            features,
        };
    case SET_FEATURES_FETCHING:
        const { fetching } = action;
        return {
            ...state,
            fetching,
        };
    case SET_FEATURES_ERROR:
        const { error } = action;
        return {
            ...state,
            error,
        };
        // Choices handling
    case SET_FEATURES_CHOICES:
        const { choices } = action;
        return {
            ...state,
            choices: {
                ...state.choices,
                ...choices,
            },
        };
    default:
        return state;
    }
}
