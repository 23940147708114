import { SET_SHOW_WARNING, SET_COUNTDOWN, SET_SESSION_TYPE } from '../actions/SessionAction';

const INITIAL_STATE = {
    showWarning: false,
    countdown: false,
    sessionType: '',
};

export default function SessionReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
    case SET_SHOW_WARNING: {
        const { showWarning } = action.payload;
        return {
            ...state,
            showWarning,
        };
    }
    case SET_COUNTDOWN: {
        const { countdown } = action.payload;
        return {
            ...state,
            countdown,
        };
    }
    case SET_SESSION_TYPE: {
        const { sessionType } = action.payload;
        return {
            ...state,
            sessionType,
        };
    }
    default:
        return state;
    }
}
