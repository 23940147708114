import React from 'react';
import _ from 'underscore';

import SignBox from './SignBox';
import Button from './Button';
import Label from './../Label';
import ErrorMessage from './ErrorMessage';

// Redux
import { compose, withProps } from 'recompose';

// Misc
import { IconCross, IconCheck, IconUndo, IconNew } from '../../../images/features';
import { FieldAction } from '../../Misc/template';
import { withCaptureFieldHandlers, withFormUtils } from '../../Misc/forms';
import { DEFAULT_FORMAT, formatDate } from '../../Misc/datetime';

const RATIO = 0.57;
class BaseSignatory extends React.Component {
    topClass = 'input__label--top';
    classWarning = 'warning';

    static defaultProps = {
        className: '',
        fieldname: {},
        label: {},
        placeholder: '',
        value: {
            timestamp: null,
            signature: null,
        },
        isValid: true,
        format: DEFAULT_FORMAT.datetimeDetailed,
    };

    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            showPopUp: false,
        };

        this.handleChange = this.props.handleChange.bind(this);
        this.resize = this.resize.bind(this);
    }

    componentDidMount() {
        this.resize();
        window.addEventListener('resize', this.resize);
    }

    componentDidUpdate({ showComponent }) {
        const { showComponent: isShowing } = this.props;
        if (isShowing && isShowing !== showComponent) this.resize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize);
    }

    resize() {
        const sign = this.refs.signatureEmpty;
        if (sign) sign.style.height = sign.offsetWidth * RATIO + 'px';
    }

    clear() {
        this.refs.signbox.handlers.clear();
    }

    undo() {
        this.refs.signbox.handlers.undo();
    }

    save(format) {
        const { onChange } = this.props;
        const data = this.refs.signbox.handlers.toDataURL(format);
        const timestamp = new Date().toISOString();
        const value = { signature: data, timestamp };
        onChange && onChange(null, { value });
        this.handleChange(null, { value });
        this.cancel();
    }

    remove() {
        this.handleChange(null, { value: null });
        this.setState({
            started: false,
        });
    }

    removeCurrent() {
        const { showDeleteConfirmationForm, hideForm } = this.props;
        showDeleteConfirmationForm({
            message: 'Are you sure you want to remove?',
            onConfirm: () => {
                this.handleChange(null, { value: null });
                setTimeout(() => {
                    this.resize();
                }, 100);
                hideForm();
            },
        });
    }

    createNew() {
        document.body.classList.add('no-scroll');
        this.setState({ showPopUp: true });
    }

    cancel() {
        document.body.classList.remove('no-scroll');
        this.setState({
            showPopUp: false,
            started: false,
        });
    }

    render() {
        const {
            id,
            className,
            // option
            type,
            title,
            label,
            name,
            value,
            isEditable,
            isValid,
            errorMessage,
            // utils
            showComponent,
            path,
            timezone,
            format,
        } = this.props;
        const option = {
            type,
            title,
            label,
            name,
            value,
        };
        const classNames = [
            'jpt--input input__signatory',
            _.isString(className) && className,
            !isValid && this.classWarning,
            !isEditable && 'noteditable',
        ];

        const { started, showPopUp } = this.state;
        const formattedTimestamp =
            value.timestamp && formatDate({ date: value.timestamp, format, timezone });

        if (!showComponent) return <div />;

        return (
            <div className={classNames.join(' ')} id={id}>
                {showPopUp && (
                    <div className="signature__modal">
                        <div className="background"></div>
                        <div className="pop-up-wrapper">
                            <div className="pop-up">
                                <div className="title">Editing merchant signatory</div>

                                {label && <Label className={this.topClass} {...label}></Label>}

                                <SignBox
                                    ref={'signbox'}
                                    ratio={RATIO}
                                    onEnd={() => {
                                        this.setState({
                                            started: true,
                                        });
                                    }}
                                    onClear={() => {
                                        this.setState({
                                            started: false,
                                        });
                                    }}
                                />

                                {started && (
                                    <div className="button-group">
                                        <Button icon={IconCheck} onClick={() => this.save()}>
                                            save
                                        </Button>
                                        <Button icon={IconCross} onClick={() => this.clear()}>
                                            clear
                                        </Button>
                                        <Button icon={IconUndo} onClick={() => this.undo()}>
                                            undo
                                        </Button>
                                    </div>
                                )}

                                <div className="button-wrapper">
                                    <div className="jpt--input button__cancel">
                                        <button
                                            onClick={() => this.cancel()}
                                            type="button"
                                            className="ui button"
                                            role="button">
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {label && <Label className={this.topClass} {...label}></Label>}

                {isEditable &&
                    value.signature && [
                    <img
                        key="1"
                        className="signature__image"
                        src={value.signature}
                        alt="signature"
                    />,
                    <div key="2" className="signature__timestamp">
                        {formattedTimestamp}
                    </div>,
                    <div key="3" className="button-group">
                        <Button icon={IconNew} onClick={() => this.createNew()}>
                                new
                        </Button>
                        <Button icon={IconCross} onClick={() => this.removeCurrent()}>
                                clear
                        </Button>
                    </div>,
                ]}

                {!isEditable &&
                    value.signature && [
                    <img
                        key="1"
                        className="signature__image"
                        src={value.signature}
                        alt="signature"
                    />,
                    <div key="2" className="signature__timestamp">
                        {formattedTimestamp}
                    </div>,
                ]}

                {isEditable && !value.signature && (
                    <div>
                        <div
                            className="signature__empty signature__empty--editable"
                            ref="signatureEmpty"></div>
                        <div className="button-group">
                            <Button icon={IconNew} onClick={() => this.createNew()}>
                                new
                            </Button>
                        </div>
                    </div>
                )}

                {!isEditable && !value.signature && (
                    <div className="signature__empty" ref="signatureEmpty"></div>
                )}

                {/* Error Message  */}
                <ErrorMessage message={errorMessage} />

                {this.props.isEditingField && <FieldAction path={path} field={option} />}
            </div>
        );
    }
}

const withOldValueHandling = withProps(({ value }) => {
    if (_.isObject(value)) {
        return;
    }
    if (_.isString(value)) {
        value = {
            signature: value,
            timestamp: null,
        };
    } else if (_.isNull(value)) {
        value = undefined; // let default props to handle default value
    }

    return {
        value,
    };
});

/**
 * HOC to enhance component withCaptureFieldHandlers,
 * and options getting from redux.list state.
 */
const enhance = compose(
    withCaptureFieldHandlers,
    withOldValueHandling,
    withFormUtils,
);

/**
 * Enhanced Signatory
 */
const EnhancedSignatory = enhance(BaseSignatory);

/**
 * EnhancedSignatory as default export
 */
export default EnhancedSignatory;

export { BaseSignatory };
