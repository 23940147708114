import React from 'react';
import _ from 'underscore';
import ObjectPath from 'object-path';

import CheckboxGroup from './CheckboxGroup';
import IECheckboxGroup from './IECheckboxGroup';
import GroupedCheckboxGroup from './GroupedCheckboxGroup';
import ErrorMessage from './ErrorMessage';
import Label from './../Label';
import store from '../../../store';

import { compose, lifecycle, branch, withPropsOnChange } from 'recompose';
import { FieldAction } from '../../Misc/template';
import { withCaptureFieldHandlers } from '../../Misc/forms';
import joinListMappingPath from '../../Misc/forms/joinListMappingPath';
import { getLists } from '../../../redux/selectors';
import withDeprecatedOptions from '../../Misc/forms/withDeprecatedOptions';

const BaseCheckboxGroupHOC = Component =>
    class BaseCheckboxGroup extends React.Component {
        classWarning = 'warning';
        static defaultProps = {
            value: [],
            className: '',
            fieldname: {},
            label: {},
            options: [],
            isValid: true,
            isEditable: true,
        };

        constructor(props) {
            super(props);
            this.props = props;

            this.handleChange = this.props.handleChange.bind(this);
        }

        render() {
            const {
                className,
                // option
                type,
                id,
                title,
                label,
                name,
                options,
                isValid,
                errorMessage,
                onChange,
                isEditable,
                isDisabledOptions,
                columns,
                // utils
                showComponent,
                path,
            } = this.props;
            let { value } = this.props;
            const option = {
                type,
                id,
                title,
                label,
                name,
                options,
                onChange,
                value,
                columns,
            };
            const classNames = [
                'jpt--input input__checkbox',
                _.isString(className) && className,
                !isValid && this.classWarning,
                !isEditable && 'noteditable',
            ];
            if (!_.isArray(value)) {
                value = [];
            }
            if (!showComponent) return <div />;

            // to check if it's group checkboxes
            const mapped = options.map(o => !!o.group);
            const isGrouped = _.some(mapped);

            return (
                <div className={classNames.join(' ')} id={id}>
                    {label && <Label className={'input__label--top'} {...label}></Label>}

                    {!isGrouped && (
                        <Component
                            columns={columns}
                            id={id}
                            checkboxes={options}
                            isEditable={isEditable}
                            isDisabledOptions={isDisabledOptions}
                            selectedValue={value}
                            onAnyCheckboxChanged={(e, data) => {
                                onChange && onChange(e, data);
                                this.handleChange(e, data);
                            }}
                        />
                    )}

                    {isGrouped && (
                        <GroupedCheckboxGroup
                            Component={Component}
                            columns={columns}
                            id={id}
                            groupedCheckboxes={options}
                            isEditable={isEditable}
                            isDisabledOptions={isDisabledOptions}
                            selectedValue={value}
                            onChange={(e, data) => {
                                onChange && onChange(e, data);
                                this.handleChange(e, data);
                            }}
                        />
                    )}

                    <ErrorMessage message={errorMessage} />

                    {this.props.isEditingField && <FieldAction path={path} field={option} />}
                </div>
            );
        }
    };

/**
 * HOC to enhance component withCaptureFieldHandlers,
 * and options getting from redux.list state.
 */

const enhance = compose(
    withCaptureFieldHandlers,
    withPropsOnChange(['options', 'values'], ({ options, values }) => {
        if (_.isArray(options)) return;
        const { listID, clear } = options;
        const lists = getLists(store.getState()) || {};
        let mappedListOptions, path;

        if (listID) {
            path = joinListMappingPath('', String(listID), '', values);
            mappedListOptions = ObjectPath.get(lists, path, null);
        }

        options = mappedListOptions ? mappedListOptions : lists[listID];

        return {
            options,
            clear,
        };
    }),
    // deprecated options must only be processed after options / options.listID mapping all done
    withDeprecatedOptions,
    lifecycle({
        componentDidUpdate(prevProps) {
            const { options = [], value = [], handleChange, clear } = this.props;
            // if value is not in options, unset that value
            if (clear && options !== prevProps.options) {
                const found = [];
                // checkbox don't have the value
                options.forEach(o => {
                    if (value.indexOf(o.value) > -1 && found.length !== value.length)
                        found.push(o.value);
                });

                if (!_.isEqual(found, value)) {
                    handleChange(null, { value: found });
                }
            }
        },
    }),
);

/**
 * Branch, Conditionally Render CheckboxGroup
 * If IE9 then show IE dropdown else show normal
 * checkboxgroup
 */
const BranchCheckboxGroupHOC = branch(
    () => {
        const { platform } = store.getState().page;
        return platform.ie9;
    },
    () => BaseCheckboxGroupHOC(IECheckboxGroup),
    t => BaseCheckboxGroupHOC(t),
)(IECheckboxGroup);

/**
 * Enhanced BranchCheckboxHOC
 */
const IECheckedCheckboxGroup = enhance(BranchCheckboxGroupHOC);

/**
 * EnhancedCheckboxGroup as default export
 */
export default IECheckedCheckboxGroup;

export { IECheckedCheckboxGroup, CheckboxGroup, IECheckboxGroup };
