import _ from 'underscore';

const TYPE = {
    label: 'label',
    text: 'text',
    select: 'select',
    checkbox: 'checkbox',
    calendar: 'calendar',
    textarea: 'textarea',
    radio: 'radio',
};

// Temporary Campaign Fields
const nonCampaignValue = 'non-campaign_(bau)';
const campaignDropdownFieldId = 'field_rn1527737551855';
const campaignRadioFieldId = 'field_rn1521689671200';

const NULL_VALUE_TEXT = 'N/A';

function isEmptyAndNotNumber(value) {
    return !_.isNumber(value) && _.isEmpty(value);
}

export {
    TYPE,
    nonCampaignValue,
    campaignDropdownFieldId,
    campaignRadioFieldId,
    isEmptyAndNotNumber,
    NULL_VALUE_TEXT,
};
