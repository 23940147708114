export const SET_DISABLED_FIELDS = 'SET_DISABLED_FIELDS';
export function setDisabledFields(fields) {
    return {
        type: SET_DISABLED_FIELDS,
        fields,
    };
}

export const SET_DISABLED_SECTIONS = 'SET_DISABLED_SECTIONS';
export function setDisabledSections(sections) {
    return {
        type: SET_DISABLED_SECTIONS,
        sections,
    };
}

export const SET_DISABLED_SELECTIONS = 'SET_DISABLED_SELECTIONS';
export function setDisabledSelections(selections) {
    return {
        type: SET_DISABLED_SELECTIONS,
        selections,
    };
}

export const SET_DISABLED_CAMPAIGN_FIELDS = 'SET_DISABLED_CAMPAIGN_FIELDS';
export function setDisabledCampaignFields(campaignFields) {
    return {
        type: SET_DISABLED_CAMPAIGN_FIELDS,
        campaignFields,
    };
}
