import React from 'react';
import _ from 'underscore';
import LabelMandatory from '../LabelMandatory';

/**
 * A method that process current label with given conditions, isRequired and isEditable,
 * which are used to decide whether to show mandatory (*) asterisk or not.
 *
 * @export
 * @param {object} {
 *   isRequired {boolean},
 *   isEditable {boolean},
 *   label {object} - { title: string },
 * }
 * @returns {object} label - { title {ReactElement|string} }
 */
export function processLabel({ isRequired, isEditable, label }) {
    if (!_.isObject(label)) return;

    const { title } = label;
    // if no title, or not required, or not editable
    if (!title || !isRequired || !isEditable) {
        return label;
    }

    return {
        title: <LabelMandatory title={title} />,
    };
}
