import store from 'store';
import { setValidity } from 'redux/actions/ValidityAction';

/**
 * A method that set field validity based on given list of field ids and path
 *
 * @param {Object} object - {
 *   fields {string[]} - field ids
 *   path {string} - path to validity
 * }
 * @returns {boolean} reset
 */
function resetFieldsValidity({ fields = [], path }) {
    const getFullId = id => (path ? `${ path }.${ id }` : id);
    fields.forEach(id => {
        store.dispatch(
            setValidity({
                path: getFullId(id),
                validity: null,
            }),
        );
    });
    return true;
}

export default resetFieldsValidity;
