import ObjectPath from 'object-path';
import ObjectAssign from 'object-assign';
import IsObject from 'is-object';

class SessionStorage {
    constructor() {
        if (sessionStorage) {
            this.status = true;
        } else {
            this.status = false;
        }

        this.token = '_cap_t';
        this.userInfo = '_u_i';
        this.loginLog = '_l_a';
        this.loginTime = '_tm';
        this.dashboardId = '_dele_a';
        this.expiryDate = '_exp_dt';
        this.shownTimeoutWarnings = '_warn_tm';
        this.client = '_cl';
        this.filter = '_f';
    }

    /**
     * Setter
     * @param {string} token - session token
     * @returns {boolean} set
     */
    setToken(token) {
        if (this.status) {
            if (typeof token !== 'string') {
                token = JSON.stringify(token);
            }
            sessionStorage.setItem(this.token, token);

            return true;
        }
        return false;
    }

    setDashboardId(dashboardId) {
        if (this.status) {
            sessionStorage.setItem(this.dashboardId, dashboardId);
            return true;
        }
        return false;
    }

    setUserInfo(info) {
        // info: any or info: {data: any, path: String}

        if (this.status) {
            let existingData = this.getUserInfo();

            if (info instanceof Object && typeof info.path === 'string') {
                existingData = ObjectAssign({}, existingData);
                const set = ObjectPath.set(existingData, info.path, info.data);

                if (set === undefined) {
                    existingData[info.path] = info.data;
                }
                if (existingData instanceof Object) {
                    existingData = JSON.stringify(existingData);
                }
                sessionStorage.setItem(this.userInfo, existingData);
            } else {
                if (info instanceof Object)
                    sessionStorage.setItem(this.userInfo, JSON.stringify(info));
                else sessionStorage.setItem(this.userInfo, info);
            }

            return true;
        }
        return false;
    }

    setLoginLog(access) {
        if (this.status) {
            if (typeof access !== 'string') {
                access = JSON.stringify(access);
            }
            sessionStorage.setItem(this.loginLog, access);

            return true;
        }
        return false;
    }

    setLoginTime(time) {
        if (this.status) {
            sessionStorage.setItem(this.loginTime, time);

            return true;
        }
        return false;
    }

    setExpiryDate(date) {
        if (this.status) {
            if (typeof date !== 'string') {
                date = JSON.stringify(date);
            }
            sessionStorage.setItem(this.expiryDate, date);
            return true;
        }
        return false;
    }

    setShownTimeoutWarnings(warnings) {
        if (this.status) {
            warnings = JSON.stringify(warnings);
            sessionStorage.setItem(this.shownTimeoutWarnings, warnings);
            return true;
        }
        return false;
    }

    setClient(client) {
        if (this.status && client) {
            if (typeof client !== 'string') {
                client = JSON.stringify(client);
            }
            sessionStorage.setItem(this.client, client);

            return true;
        }
        return false;
    }

    setFilter(filter) {
        if (this.status && filter) {
            if (typeof filter !== 'string') {
                filter = JSON.stringify(filter);
            }
            sessionStorage.setItem(this.filter, filter);

            return true;
        }
        return false;
    }

    /**
     * Getter
     * @returns {boolean|string} token
     */
    getToken() {
        if (this.status) {
            const token = sessionStorage.getItem(this.token) || '';

            if (token instanceof Object)
                try {
                    return JSON.parse(token);
                } catch (error) {
                    return token;
                }
            else return token;
        }
        return false;
    }

    getDashboardId() {
        if (this.status) {
            const dashboardId = sessionStorage.getItem(this.dashboardId) || '';
            if (dashboardId)
                try {
                    return JSON.parse(dashboardId);
                } catch (error) {
                    return dashboardId;
                }
            else return dashboardId;
        }
        return false;
    }

    getUserInfo(path) {
        if (this.status) {
            let data = sessionStorage.getItem(this.userInfo) || '{}';

            try {
                data = JSON.parse(data);
            } catch (error) {
                data = !IsObject(data) ? {} : data;
            }

            if (typeof path === 'string') {
                data = ObjectPath.get(data, path);
                return data;
            }
            return data || {};
        }
        return false;
    }

    getLoginLog(path) {
        let data = this.getFromSession(this.loginLog);
        if (path) data = this.getFromPath(data, path);
        return data;
    }

    getFromSession(target) {
        if (this.status) {
            let data = sessionStorage.getItem(target) || '{}';

            try {
                data = JSON.parse(data);
            } catch (error) {
                data = !IsObject(data) ? {} : data;
            }

            return data || {};
        }

        return false;
    }

    getFromPath(data, path) {
        if (this.status && typeof path === 'string') {
            data = ObjectPath.get(data, path);
            return data;
        }

        return false;
    }

    getLoginTime() {
        if (this.status) {
            const data = sessionStorage.getItem(this.loginTime);
            return data;
        }
        return false;
    }

    getExpiryDate() {
        if (this.status) {
            const expiryDate = sessionStorage.getItem(this.expiryDate) || '';

            if (expiryDate instanceof Object)
                try {
                    return JSON.parse(expiryDate);
                } catch (error) {
                    return expiryDate;
                }
            else return expiryDate;
        }
        return false;
    }

    getShownTimeoutWarnings() {
        if (this.status) {
            const shownTimeoutWarnings = sessionStorage.getItem(this.shownTimeoutWarnings) || '';

            if (shownTimeoutWarnings instanceof Object)
                try {
                    return JSON.parse(shownTimeoutWarnings);
                } catch (error) {
                    return shownTimeoutWarnings;
                }
            else return shownTimeoutWarnings;
        }
        return false;
    }

    getClient() {
        if (this.status) {
            const client = sessionStorage.getItem(this.client) || '{}';

            return JSON.parse(client);
        }
        return false;
    }

    getFilter() {
        if (this.status) {
            const filter = sessionStorage.getItem(this.filter) || '{}';

            return JSON.parse(filter);
        }
        return false;
    }

    /**
     * Remover
     * @returns {boolean} removed
     */
    removeDashboardId() {
        if (this.status) {
            sessionStorage.removeItem(this.dashboardId);
            return true;
        }
        return false;
    }

    removeToken() {
        if (this.status) {
            sessionStorage.removeItem(this.token);
            return true;
        }
        return false;
    }

    removeUserInfo() {
        if (this.status) {
            sessionStorage.removeItem(this.userInfo);
            return true;
        }
        return false;
    }

    removeLoginTime() {
        if (this.status) {
            sessionStorage.removeItem(this.loginTime);
            return true;
        }
        return false;
    }

    removeExpiryDate() {
        if (this.status) {
            sessionStorage.removeItem(this.expiryDate);
            return true;
        }
        return false;
    }

    removeShownTimeoutWarnings() {
        if (this.status) {
            sessionStorage.removeItem(this.shownTimeoutWarnings);
            return true;
        }
        return false;
    }

    removeClient() {
        if (this.status) {
            sessionStorage.removeItem(this.client);
            return true;
        }
        return false;
    }

    removeFilter() {
        if (this.status) {
            sessionStorage.removeItem(this.filter);
            return true;
        }
        return false;
    }
}

export const session = new SessionStorage();
export default SessionStorage;
