import moment from 'moment-timezone';
import _ from 'underscore';
import { sampleDatetime } from '../../../datetime';

export const HOURS = {
    24: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
    12: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
};
export const MINUTES = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];
export const DAYS = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
export const DAYS_IN_MONTH = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
export const MONTHS_LABELS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export function toValidDate(date) {
    const isValid = moment(date).isValid();
    if (!isValid) {
        return moment().toDate();
    } else {
        return moment(date).toDate();
    }
}

export function isDateInRange({ date, minDate, maxDate }) {
    date = moment([date.getFullYear(), date.getMonth(), date.getDate()]);
    minDate = minDate && moment([minDate.getFullYear(), minDate.getMonth(), minDate.getDate()]);
    maxDate = maxDate && moment([maxDate.getFullYear(), maxDate.getMonth(), maxDate.getDate()]);

    if ((minDate && date.diff(minDate) < 0) || (maxDate && date.diff(maxDate) > 0)) return false;

    return true;
}

export function isMonthInRange({ date, minDate, maxDate }) {
    date = moment([date.getFullYear(), date.getMonth()]);
    minDate = minDate && moment([minDate.getFullYear(), minDate.getMonth()]);
    maxDate = maxDate && moment([maxDate.getFullYear(), maxDate.getMonth()]);

    if (
        (minDate && date.diff(minDate, 'months') < 0) ||
        (maxDate && date.diff(maxDate, 'months') > 0)
    )
        return false;

    return true;
}

export function isDatetimeInRange({ date, minDate, maxDate, type }) {
    date = moment(date);
    minDate = minDate && moment(minDate);
    maxDate = maxDate && moment(maxDate);

    if (
        (minDate && date.diff(minDate, type) < 0) ||
        (maxDate && date.diff(maxDate, type, true) > 0)
    )
        return false;

    return true;
}

/**
 * A method to check given date is date or not.
 *  if it's a string, will be formatted to date
 * @param {any} date - Date or String
 * @param {String} displayName - Name that displayed in error message, if any
 * @returns {Date} - Date or null
 */
export function toDate(date, displayName = '') {
    if (date === 'today') {
        date = moment().toDate();
    } else if (date) {
        if (_.isString(date)) {
            try {
                date = moment(date).toDate();
            } catch (e) {
                console.error(`Date: ${displayName} given should be a Date`);
                date = null;
            }
        } else if (!_.isDate(date)) {
            console.error(`Date: ${displayName} given should be a Date`);
            date = null;
        }
    }
    return date;
}

/**
 * This function is for validating calendar component output value, not display.
 * So that component can show error message if is not valid.
 * @export
 * @param {Object} {
 *   value - '2012/12/11T16:00:00.000Z'
 *   format - e.g. 'YYYY/MM/DD HH:mm'
 *   type - e.g. 'datetime'
 *   strict - boolean that indicate to either check to strictly against the format given
 * }
 * @returns {boolean} isValid
 */
export function validateCalendarInput({ value, format, type, strict }) {
    let errorMessage = '';
    let isValid = true;

    if (!format || !value) {
        return {
            errorMessage,
            isValid,
        };
    }

    // Match format
    isValid = moment(value, format, strict).isValid();

    if (!isValid) {
        const formatSample = sampleDatetime({ format, type });
        errorMessage = `Please input date as ${formatSample} format`;
    }

    return {
        isValid,
        errorMessage,
    };
}
