import ObjectPath from 'object-path';

import {
    SET_VALUE,
    SET_VALUES,
    SET_CUSTOM_VALUES,
    SET_PREV_CUSTOM_VALUES,
    SET_VALUES_ERROR,
} from '../actions/ValueAction';

const INITIAL_STATE = {
    values: {},
    error: null,
};

export default function valueReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
    case SET_VALUE: {
        const { path, value } = action.payload;
        const toSetValue = value;
        const values = { ...state.values };
        try {
            ObjectPath.set(values, path, value);
        } catch (e) {
            const splittedPath = path.split('.');
            let tempPath;
            splittedPath.forEach((p, index) => {
                tempPath = tempPath ? `${ tempPath }.${ p }` : p;
                const lastIndex = splittedPath.length - 1;
                const value = ObjectPath.get(values, tempPath, null);
                if (!value && index < lastIndex) {
                    let isArray = false;
                    const nextPath = splittedPath[index + 1];
                    const afterNextPath = splittedPath[index + 2];
                    try {
                        const isNumber = parseInt(nextPath) > -1;
                        isArray = isNumber;
                    } catch (e) {}

                    if (isArray) {
                        ObjectPath.set(values, tempPath, []);
                    } else if (afterNextPath) {
                        ObjectPath.set(values, tempPath, {});
                    }
                }
                if (!value && index === lastIndex) {
                    ObjectPath.set(values, tempPath, toSetValue);
                }
            });
        }
        return {
            ...state,
            values,
        };
    }
    case SET_VALUES: {
        const { values } = action;
        if (values === null) {
            return {
                ...state,
                values: {},
            };
        }
        return {
            ...state,
            values: {
                ...state.values,
                ...values,
            },
        };
    }
    case SET_CUSTOM_VALUES: {
        const { customDefaultValue } = action;
        return {
            ...state,
            customDefaultValue,
        };
    }
    case SET_PREV_CUSTOM_VALUES: {
        const { prevCustomDefaultValue } = action;
        return {
            ...state,
            prevCustomDefaultValue,
        };
    }
    case SET_VALUES_ERROR:
        const { error } = action;
        return {
            ...state,
            error,
        };
    default:
        return state;
    }
}
