import ObjectPath from 'object-path';
import ObjectAssign from 'object-assign';
import IsObject from 'is-object';
import _ from 'underscore';

class LocalStorage {
    constructor() {
        if (localStorage) {
            // console.log('Local storage works!!! Thanks God!')
            this.status = true;
        } else {
            this.status = false;
        }

        this.itemApplicationForm = 'applicationForm';
        this.itemViewingPage = 'viewingPage';

        this.setApplicationFormValue = this.setApplicationFormValue.bind(this);
        this.getApplicationFormValue = this.getApplicationFormValue.bind(this);
    }

    /**
     * Setter
     * @param {Object} data - key value object
     * @returns {boolean} set
     */
    setViewingPage(data) {
        if (this.status) {
            if (typeof data !== 'string') {
                data = JSON.stringify(data);
            }
            localStorage.setItem(this.itemViewingPage, data);

            return true;
        }
        return false;
    }
    setApplicationFormValue({ data, path }) {
        if (this.status) {
            let existingData = this.getApplicationFormValue({});

            if (typeof path === 'string') {
                existingData = ObjectAssign({}, existingData);

                let set;
                try {
                    set = ObjectPath.set(existingData, path, data);
                } catch (e) {
                    // TypeError occur when one of the property
                    //   value is array and contain null value.
                    //   Below is to remove the null value and
                    //   allow object-path to set value with
                    //   no error.
                    if (e instanceof TypeError) {
                        existingData = deepFixArray(existingData);
                        set = ObjectPath.set(existingData, path, data);

                        function deepFixArray(obj) {
                            return fixArray(obj, true);
                        }
                        function fixArray(obj, deep = false) {
                            const newObj = {};

                            Object.entries(obj).forEach(data => {
                                let [key, value] = data;

                                if (_.isArray(value)) {
                                    value = value.map(v => {
                                        if (v === null) return {};
                                        return v;
                                    });
                                } else if (deep && IsObject(value)) {
                                    value = fixArray(value);
                                }

                                newObj[key] = value;
                            });

                            return newObj;
                        }
                    } else console.error(e);
                }

                if (set === undefined) {
                    existingData[path] = data;
                }
                if (typeof existingData !== 'string') {
                    existingData = JSON.stringify(existingData);
                }
                localStorage.setItem(this.itemApplicationForm, existingData);
            } else {
                localStorage.setItem(this.itemApplicationForm, data);
            }

            return true;
        }
        return false;
    }

    /**
     * Getter
     * @returns {boolean|data} data
     */
    getViewingPage() {
        if (this.status) {
            const data = localStorage.getItem(this.itemViewingPage) || '';
            try {
                return JSON.parse(data);
            } catch (error) {
                return data;
            }
        }
        return false;
    }

    getApplicationFormValue({ path } = {}) {
        if (this.status) {
            let data = localStorage.getItem(this.itemApplicationForm) || '{}';
            try {
                data = JSON.parse(data);
            } catch (error) {
                data = !IsObject(data) ? {} : data;
            }

            if (typeof path === 'string') {
                data = ObjectPath.get(data, path, '');
                return data;
            }
            return data || {};
        }
        return false;
    }

    /**
     * Remover
     * @returns {boolean} removed
     */
    removeApplicationFormValue() {
        if (this.status) {
            localStorage.removeItem(this.itemApplicationForm);
            return true;
        }
        return false;
    }
}

export default LocalStorage;
