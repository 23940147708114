import { connect } from 'react-redux';
import { compose, withHandlers, withPropsOnChange } from 'recompose';
import _ from 'underscore';
import ObjectClone from 'clone';

import LocalStorage from '../LocalStorage';
import { goToDashboardUser, goToSubmittedForm, updateUrlToSubmittedForm } from '../GoToPage';

import { validateForm, validateFormViews } from '../Form/FormRules';
import { FormState } from 'redux/reducers/FormReducer';

import {
    actionSetFormView,
    actionChangeView,
    actionExportForm,
    actionSetActionForm,
    actionSetFormError,
    setUpdatedDate,
    setInitialFormData,
    setFormId,
    setFormStatus,
    actionSetFormRule,
} from '../../redux/actions/FormAction';
import { setValidities, setViewValidities } from '../../redux/actions/ValidityAction';
import { callRule } from '../../redux/actions/RuleAction';
import {
    withFormUtils,
    APPROVAL_FIELD_KEY,
    APPROVAL_OPTIONS,
    ACTION_BUTTON_ACTIONS,
} from './forms';
import { showInvalidFieldMessage, showFormDataPatchDifferent } from './forms/popUps';
import store from '../../store';
import * as Selectors from '../../redux/selectors';
import { setValues } from '../../redux/actions/ValueAction';
import { observableDiff, applyChange } from 'deep-diff';
import ObjectPath from 'object-path';
import { actionCheckFormData } from '../../redux/actions/FormAction';
import { validators } from '../Form/tests/ajv';
import { ImageWarning } from '../../images/actions';
import SETTINGS from '../../settings';
import * as jsondiffpatch from 'jsondiffpatch';
import { formatters } from 'jsondiffpatch';
import { cleanObjectBySuffix, cleanObjectByValue } from './utils/object';

const diffPatcher = jsondiffpatch.create({
    textDiff: {
        minLength: Infinity,
    },
});

const localStorage = new LocalStorage();

const SUBMISSION_IGNORED_FIELD_SUFFIX = ['_loading', '_ignored'];

const SUFFIX_LOADING = '_loading';

const mapStateToProps = state => {
    return {
        form: Selectors.getForm(state),
        triggerObj: Selectors.getTriggers(state),
        validatorObj: Selectors.getValidators(state),
        validityObj: Selectors.getValidities(state),
        valueObj: Selectors.getValues(state),
        params: state.page.params || {},
        formRule: Selectors.getFormRule(state),
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        // Form
        setFormView: data => {
            dispatch(actionSetFormView(data));
        },
        changeView: ({ templateId, page, formId, campaignId }) => {
            dispatch(actionChangeView({ templateId, page, formId, campaignId }));
        },
        // Action form
        loadActionForm(form) {
            dispatch(actionSetActionForm(form));
        },
        emptyActionForm() {
            dispatch(actionSetActionForm({}));
        },
        // Field
        setValidities: validities => {
            dispatch(setValidities(validities));
        },
        // Export
        exportForm: async data => {
            await dispatch(actionExportForm(data));
        },
        // Error
        setError: error => {
            dispatch(actionSetFormError(error));
        },
        // Button
        callRule: async ({
            ruleId,
            formId,
            templateId,
            relativePath,
            contextId,
            formStatus,
            updatedDate,
            data,
            delta,
            campaignSlug,
            formCampaignSlug,
            index,
            moduleType,
            type,
            cb,
        }) => {
            dispatch(
                actionSetFormRule({
                    fieldId: props.id,
                    id: ruleId,
                }),
            );
            await dispatch(
                callRule({
                    ruleId,
                    formId,
                    templateId,
                    relativePath,
                    contextId,
                    formStatus,
                    updatedDate,
                    data,
                    delta,
                    campaignSlug,
                    formCampaignSlug,
                    index,
                    moduleType,
                    type,
                    cb,
                }),
            );
            dispatch(
                actionSetFormRule({
                    fieldId: null,
                    id: [],
                }),
            );
        },
    };
};

const withFieldValidityHandlers = withHandlers({
    onValidityChange:
        ({ setValidities }) =>
        validities => {
            setValidities(validities);
        },
});

const withAlertFormButtonHandlers = compose(
    withFormUtils,
    withHandlers({
        showConfirmForm:
            ({ showForm, hideForm }) =>
            ({ message = '', onConfirm }) => {
                showForm({
                    size: 'small',
                    //image,
                    message,
                    buttons: [
                        { text: 'Cancel', onClick: hideForm },
                        { text: 'Ok', onClick: onConfirm },
                    ],
                });
            },
    }),
);

function getReturnedFile(file) {
    const a = document.createElement('a');
    file = _.isObject(file) ? file.url : file;
    a.href = window.open(file, '_blank');
    return true;
}

/**
 * Update form values in redux.value.values
 * @param {object} values - format { id: value, id2, value2, ... }
 */
function updateFormValues(values) {
    store.dispatch(setValues(values));
    store.dispatch(setInitialFormData(ObjectClone(values)));
}

/**
 * Update form id in redux.form.formId
 * @param {string} id - the form id to set
 */
function updateFormId(id) {
    store.dispatch(setFormId(id));
}

/**
 * Update form status in redux.form.formStatus
 * @param {object} status - format { id: number, name: string, code: string }
 */
function updateFormStatus(status) {
    store.dispatch(setFormStatus(status));
}

/**
 * getFlattenFormData is to convert form data object in a single level instead of nested.
 * @param {Object} formData - the original form data object.
 * @param {string} parentPath - the path of each key found e.g: field_1.1.field_2.0.field_3.0.
 * @param {Object} result - the flattened form data e.g { field_3.0.name_1_loading: true }
 * @returns {Object} flattenData
 */
function getFlattenFormData(formData, parentPath = '', result = {}) {
    const flattenData = Object.entries(formData).reduce((accumulator, [key, value]) => {
        const path = `${parentPath}${key}`;
        if (_.isObject(value)) {
            getFlattenFormData(value, `${path}.`, accumulator);
        } else {
            result[path] = value;
        }
        return accumulator;
    }, result);

    return flattenData;
}

/**
 * Update form updatedDate in redux.form.updatedDate
 * @param {string} updatedDate - form updated date
 */
function updateFormUpdatedDate(updatedDate) {
    store.dispatch(setUpdatedDate(updatedDate));
}

function formatFormDelta(delta) {
    return formatters.jsonpatch.format(delta);
}

/**
 * Old delta format will cause ignored fields inside fieldset to be overwritten
 * since the key is not sent by f/e and b/e will just overwrite the whole fieldset
 * e.g  data = principal: [{field1: '', a_loading: true}]
 *      sent = principal: [{field1: ''}]
 *      b/e new value = principal: [{field1: ''}]
 * b/e will acknowledge this as difference and rightfully so
 *
 * Initial and changed data will be cleaned of unwanted keys first to ensure
 * its not treated as diff. For comparison we will use the cleaned data.
 * @param {Object} initialFormData - the original form data object.
 * @param {Object} changedFormData - new form data to be used for delta
 * @returns {Object} diffFormData
 */
export function getJsonDiff(initialFormData, changedFormData) {
    const cleanedInitialData = cleanObjectBySuffix(
        initialFormData,
        SUBMISSION_IGNORED_FIELD_SUFFIX,
    );
    let cleanedChangedData = cleanObjectBySuffix(changedFormData, SUBMISSION_IGNORED_FIELD_SUFFIX);
    const tempDiff = diffPatcher.diff(cleanedInitialData, cleanedChangedData);
    const diffFormData = formatFormDelta(tempDiff);
    const patchedData = diffPatcher.patch(cleanedInitialData, tempDiff);
    // form data with undefined keys is not sent
    // cleaned changed data for fair comparison
    cleanedChangedData = cleanObjectByValue(cleanedChangedData, [undefined]);
    // only produce error message when SEND_DELTA is true and there is difference
    if (!_.isEqual(cleanedChangedData, patchedData) && SETTINGS.SEND_DELTA) {
        console.log(diffFormData, cleanedChangedData, initialFormData, patchedData);
        store.dispatch(
            showFormDataPatchDifferent({
                onConfirm: () => {
                    store.dispatch(actionSetActionForm({}));
                },
            }),
        );
    }
    return diffFormData;
}

export function checkFormDiff(initialFormData, changedFormData) {
    const diffFormData = {};

    observableDiff(initialFormData, changedFormData, d => {
        const arrayNotChanged = d.kind != 'A';
        if (arrayNotChanged) {
            const index = _.findIndex(d.path, _.isNumber);
            const foundNestedField = index > -1;
            if (foundNestedField) {
                const parentPath = d.path.slice(0, index);
                const value = ObjectPath.get(changedFormData, parentPath);
                ObjectPath.set(diffFormData, parentPath, value);
            } else {
                applyChange(diffFormData, null, d);
            }
        } else {
            // get value from the root parent
            const rootField = d.path[0];
            diffFormData[rootField] = changedFormData[rootField];
        }
    });

    return diffFormData;
}

const withDisablingClickedRuleButton = compose(
    withPropsOnChange(
        ['formRule', 'isEditable', 'isLoading'],
        ({ id, onClick, isEditable, isLoading, formRule = {} }) => {
            if (!_.isObject(onClick)) return;
            const { preset = [] } = onClick;
            // only disable button with preset 'with_rule'
            if (!preset.includes('with_rule')) return;

            const { fieldId } = formRule;
            isEditable = !fieldId && isEditable;
            isLoading = (fieldId && fieldId === id) || isLoading;

            return {
                isEditable,
                isLoading,
            };
        },
    ),
);

export const withFormActionHandlers = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withFieldValidityHandlers,
    withAlertFormButtonHandlers,
    withHandlers({
        /**
         * checkFormDataWithTimer is used for action_cd A005 & A006
         * It will call form data endpoint every 1 sec.
         * If all _loading fields are false, will do a save as draft.
         * Then it will call the next action.
         * @param {string} templateId - id of template.
         * @param {string} formId - id of form.
         * @param {object} initialFormData - form data received from forms/formId/ endpoint.
         * @param {object} ruleParams - contains all the rule data from callRule's callback.
         * @param {function} callRule - a function to pass rules e.g 'save' to rule endpoint.
         * @param {function} nextAction - a callback to perform another action after callRule.
         * @returns {Promise} checkFormData
         */
        checkFormDataWithTimer:
            ({ callRule }) =>
            ({ templateId, formId, initialFormData, ruleParams, nextAction }) => {
                const intervalMs = 1000;
                let timeoutId = null;

                async function checkFormData() {
                    const formData = await store.dispatch(actionCheckFormData(templateId, formId));
                    if (!_.isEmpty(formData)) {
                        timeoutId = setTimeout(checkFormData, intervalMs);
                    }
                    const { form } = formData;
                    const { data, form_status: formStatus = {} } = form;
                    const flattenFormData = getFlattenFormData(data);
                    const fieldsLoading = _.filter(Object.keys(flattenFormData), fields => {
                        // - negative starts from the end of the string
                        return fields.substr(-SUFFIX_LOADING.length) === SUFFIX_LOADING;
                    });
                    if (!fieldsLoading.length) return;
                    const fieldsLoadingValue = fieldsLoading.map(
                        fieldKey => flattenFormData[fieldKey],
                    );
                    const fieldsStillLoading = _.contains(fieldsLoadingValue, true);

                    if (!fieldsStillLoading) {
                        clearTimeout(timeoutId);
                        const { value } = store.getState();
                        const { values } = value;
                        const cleanedData = cleanObjectBySuffix(
                            values,
                            SUBMISSION_IGNORED_FIELD_SUFFIX,
                        );

                        const diffFormData = checkFormDiff(initialFormData, cleanedData);
                        const formDataDelta = getJsonDiff(initialFormData, cleanedData);

                        // save as draft
                        callRule({
                            ...ruleParams,
                            formId,
                            formStatus: formStatus.id,
                            ruleId: ['save'],
                            data: diffFormData,
                            delta: formDataDelta,
                            cb: response => {
                                nextAction(response);
                            },
                        });
                    }
                }
                return checkFormData();
            },
    }),
    withHandlers({
        responseToWorkflow:
            ({ checkFormDataWithTimer }) =>
            (response, ruleParams) => {
                takeActionByResponse(response, ruleParams, checkFormDataWithTimer);
            },
        validateForm:
            () =>
            ({ form, tabsId, tabIndex, ignoredIds }) => {
                store.dispatch(setValidities({}));
                return validateForm({ form, tabsId, tabIndex, ignoredIds });
            },
    }),
    withHandlers({
        /**
         *
         * param data is the object than contain the value needed for next action.
         * param data.data is the form data, Object: { field_id: value }
         * param data.rules: { preset: array, rules: id, ...  }
         * @param {SyntheticEvent} e - Synthetic event of form action button mouse click
         * @param {Object} data - { data, rules }
         * @returns {undefined}
         */
        handlePresetButtonClick:
            ({
                // props
                params,
                valueObj,

                // actions
                // form
                changeView,
                loadActionForm,
                validateForm,
                showDeleteConfirmationForm,
                // field
                onValidityChange,
                // export
                exportForm,
                // button
                callRule,
                // error
                setError,

                // handlers
                responseToWorkflow,
            }) =>
            (
                e,
                {
                    rules: {
                        preset = [],
                        rule: ruleId,
                        tabIndex,
                        relativePath,
                        contextId,
                        moduleType,
                        type,
                        ignoredIds,
                    },
                },
            ) => {
                // Private functions
                changePage = changePage.bind(this);
                checkField = checkField.bind(this);
                withRule = withRule.bind(this);

                const state = store.getState();
                const form = Selectors.getForm(state);
                const templateId = params.templateId || Selectors.getTemplate(state).id;
                const formId = params.formId || form.id || form.formId;
                const formData = valueObj;
                const { formStatus = {}, updatedDate } = form;

                const { showingViewNumber = 1, campaignId, campaignSlug, formCampaignSlug } = form;

                if (hasOrEqualTo(preset, 'validate_form')) {
                    const formValidity = checkField({ form, ignoredIds });

                    if (!formValidity.isValid) {
                        const viewValidities = validateFormViews(form);
                        store.dispatch(setViewValidities(viewValidities));

                        scrollTop();
                        onValidityChange(formValidity.validities);
                        return;
                    }
                } else if (
                    hasOrEqualTo(preset, 'validate_view') ||
                    hasOrEqualTo(preset, 'validate_tab')
                ) {
                    const viewValidity = checkField({
                        form: form.views[showingViewNumber - 1],
                        tabIndex,
                        contextId,
                        ignoredIds,
                    });

                    if (!viewValidity.isValid) {
                        scrollTop();
                        onValidityChange(viewValidity.validities);
                        store.dispatch(
                            showInvalidFieldMessage({
                                onConfirm: () => {
                                    store.dispatch(actionSetActionForm({}));
                                },
                            }),
                        );
                        return;
                    }
                } else if (hasOrEqualTo(preset, 'validate_previous_views')) {
                    const viewValidityTable = [] as Array<boolean>;
                    let allValidities = {};
                    for (let i = 0; i < showingViewNumber; i++) {
                        const viewValidity = checkField({
                            form: form.views[i],
                            tabIndex,
                            contextId,
                            ignoredIds,
                        });

                        allValidities = {
                            ...allValidities,
                            ...viewValidity.validities,
                        };
                        viewValidityTable.push(viewValidity.isValid);
                    }

                    const isAllValid = viewValidityTable.every(validity => validity === true);
                    if (!isAllValid) {
                        const viewValidities = validateFormViews(form, showingViewNumber);
                        store.dispatch(setViewValidities(viewValidities));

                        scrollTop();
                        onValidityChange(allValidities);
                        return;
                    }
                }

                // Action will run only either one
                if (hasOrEqualTo(preset, 'next_page')) {
                    changePage('next_page', { form, params });
                    return;
                }

                if (hasOrEqualTo(preset, 'previous_page')) {
                    changePage('previous_page', { form, params });
                    return;
                }

                if (hasOrEqualTo(preset, 'clear_localstorage')) {
                    localStorage.removeApplicationFormValue();
                }

                if (hasOrEqualTo(preset, 'back_to_dashboard')) {
                    goToDashboardUser();
                }

                !loadActionForm && _.isFunction(loadActionForm);

                // comparison
                const initialFormData = form.initialFormData || {};
                const changedFormData = formData;
                const diffFormData = checkFormDiff(initialFormData, changedFormData);
                const formDataDelta = getJsonDiff(initialFormData, changedFormData);

                // Call Export Form action
                if (hasOrEqualTo(preset, 'export')) {
                    // Body data to dispatch to form API
                    const formBody = {
                        data: form.data,
                        id: formId,
                        template: templateId,
                    };
                    // If there is also an action in the preset, call it first
                    // Then wait for the export to finish to call action code
                    if (hasOrEqualTo(preset, 'with_rule')) {
                        withRule((cleanedData, formDataDelta) => async response => {
                            try {
                                await exportForm({ form: formBody });
                                const { form } = response || {};
                                const { updated_dt } = form || {};
                                responseToWorkflow(response, {
                                    relativePath,
                                    contextId,
                                    formId,
                                    templateId,
                                    updatedDate: updated_dt,
                                    formStatus: formStatus.id,
                                    data: cleanedData,
                                    delta: formDataDelta,
                                    index: tabIndex,
                                });
                            } catch (error) {
                                // Open the error form
                                setError({
                                    message: 'Error exporting: ' + error.response.data.status_desc,
                                });
                            }
                        });
                    }
                    // Otherwise dispatch without a callback
                    else
                        exportForm({ form: formBody }).catch(error => {
                            setError({
                                message: 'Error exporting: ' + error.response.data.status_desc,
                            });
                        });
                    // Return to prevent calling 'with_rule' twice.
                    // Safe to return since no other checks are after this.
                    return;
                }

                // Check whether there's form Reject by checking 'approval' field.
                // changedFormData is current form data.
                // 1. check is based on changed form data, instead of diff data. As long as approval field
                //    is having reject value, pop up is needed.
                // 2. check is only needed right before present 'with_rule' being run. It will
                //    run 'with_rule' with configured presets
                // 3. check only applicable to submit action, onClick:{ preset: ['with_rule'], rule: ['submit'] }.
                if (
                    changedFormData[APPROVAL_FIELD_KEY] === APPROVAL_OPTIONS[1] &&
                    hasOrEqualTo(ruleId, ACTION_BUTTON_ACTIONS[0])
                ) {
                    // show Reject confirmation pop up
                    showDeleteConfirmationForm({
                        confirmButtonType: 'danger',
                        confirmButtonName: 'Confirm',
                        image: ImageWarning,
                        message: 'Are you sure you want to reject this application ?',
                        onConfirm: (e, { hideForm }) => {
                            hideForm();
                            // stop when no 'with rule'
                            if (!hasOrEqualTo(preset, 'with_rule')) return;
                            // on confirm, we check preset like normal action button
                            withRule((cleanedData, formDataDelta) => response => {
                                const { form } = response || {};
                                const { updated_dt } = form || {};
                                responseToWorkflow(response, {
                                    relativePath,
                                    contextId,
                                    formId,
                                    templateId,
                                    updatedDate: updated_dt,
                                    formStatus: formStatus.id,
                                    data: cleanedData,
                                    delta: formDataDelta,
                                    index: tabIndex,
                                });
                            });
                        },
                    });
                    return;
                }

                if (hasOrEqualTo(preset, 'with_rule')) {
                    withRule((cleanedData, formDataDelta) => response => {
                        const { form } = response || {};
                        const { updated_dt } = form || {};
                        responseToWorkflow(response, {
                            relativePath,
                            contextId,
                            formId,
                            templateId,
                            updatedDate: updated_dt,
                            formStatus: formStatus.id,
                            data: cleanedData,
                            delta: formDataDelta,
                            index: tabIndex,
                        });
                    });
                }

                // Private functions
                // withRule takes a callback: (cleanedData) => (response) => void
                // This is to prevent calling cleanObjectBySuffix multiple times
                function withRule(callback) {
                    const cleanedData = cleanObjectBySuffix(
                        diffFormData,
                        SUBMISSION_IGNORED_FIELD_SUFFIX,
                    );
                    callRule({
                        ruleId,
                        formId,
                        templateId,
                        relativePath,
                        contextId,
                        formStatus: formStatus.id,
                        updatedDate,
                        data: cleanedData,
                        delta: formDataDelta,
                        // TODO: Refactor callback
                        // Callback of callback
                        cb: callback(cleanedData, formDataDelta),
                        campaignSlug,
                        formCampaignSlug,
                        index: tabIndex,
                        moduleType,
                        type,
                    });
                    return;
                }

                function changePage(direction, { form, params }: { form: FormState }) {
                    let allowChange = true;
                    let page = form.showingViewNumber;
                    const pages = form.views.length;
                    const { templateId, formId } = params;
                    if (!direction) {
                        console.log('Direction is not set / given');
                        return;
                    }

                    switch (direction) {
                        case 'next_page':
                            if (page + 1 <= pages) {
                                page = page + 1;
                            } else {
                                allowChange = false;
                            }
                            break;
                        case 'previous_page':
                            if (page - 1 > 0) {
                                page = page - 1;
                            } else {
                                allowChange = false;
                            }
                            break;
                        default:
                            break;
                    }

                    if (!allowChange) {
                        console.error('Page change denied');
                        return;
                    }

                    changeView({
                        templateId,
                        page,
                        formId,
                        campaignId,
                    });
                    scrollTop();
                }

                function checkField({ form, tabIndex, contextId, ignoredIds = [] }) {
                    const validities = validateForm({
                        form,
                        tabIndex,
                        tabsId: contextId,
                        ignoredIds,
                    }).validities;

                    const foundInvalid = findInvalidInValidities(validities);
                    const isValid = !foundInvalid.length;

                    return {
                        isValid,
                        validities,
                    };
                }

                function scrollTop() {
                    window.scrollTo(0, 0);
                }

                function hasOrEqualTo(anything, value) {
                    if (_.isArray(anything)) {
                        return anything.indexOf(value) > -1;
                    } else if (_.isString(value)) {
                        return anything === value;
                    }

                    return false;
                }

                function findInvalidInValidities(validities) {
                    return Object.values(validities).filter(validity => {
                        // check validity that's in array format
                        //   e.g. standard merchant rates and mid tid fields
                        if (_.isArray(validity)) {
                            const foundInvalid = validity.filter(each => {
                                return findInvalidInValidities(each).length;
                            });

                            return foundInvalid.length;
                        } else {
                            return _.isObject(validity) && !validity.isValid;
                        }
                    });
                }
            },
    }),
    withDisablingClickedRuleButton,
);

function takeActionByResponse(response, ruleParams, checkFormDataWithTimer = _.noop) {
    const { action_cd } = response || {};
    if (!_.isArray(action_cd)) {
        callActionByCode(action_cd, response, ruleParams, checkFormDataWithTimer);
    } else {
        // call multiple action
        action_cd.forEach(cd => {
            callActionByCode(cd, response, ruleParams, checkFormDataWithTimer);
        });
    }
}

/**
 * A method that will run different action based on action_cd given.
 *
 * @param {string} actionCode - action code returned from api form/<action>
 * @param {Object} response - response returned from api
 * @param {Object} ruleParams - rule parameters
 * @param {Function} checkFormDataWithTimer - function to check form data with a timer
 */
function callActionByCode(actionCode, response = {}, ruleParams, checkFormDataWithTimer = _.noop) {
    const { form = {}, file } = response;
    const { id, form_status: formStatus, data, template, updated_dt: updatedDate } = form;
    const templateId = template;
    const initialFormId = Selectors.getForm(store.getState()).formId;
    const formId = id;
    if (updatedDate) updateFormUpdatedDate(updatedDate);

    switch (actionCode) {
        case 'A999': // do nothing
            break;
        case 'A009':
            // 1) do update form values
            // 2) set new form data
            // 3) do A001
            updateFormValues(data);
            setNewFormData({ formId, templateId, formStatus });
            checkFormDataWithTimer({
                templateId,
                formId,
                initialFormData: data,
                updatedDate,
                ruleParams,
                nextAction: () => {
                    // will do A001
                    goToSubmittedForm({
                        templateId,
                        formId,
                    });
                },
            });
            break;
        case 'A008': // assign to other
            const listKey = 'assign_to_users';
            const ruleKey = 'rule_type';
            const list = response[listKey] || [];
            const assignees = _.map(list, o => ({
                value: o.id,
                title: `${o.name} (${o.role})`,
            }));
            showAssignToOther({
                ruleId: [response[ruleKey]],
                formId: ruleParams.formId,
                formStatus: { id: formStatus },
                templateId: ruleParams.templateId,
                updatedDate: ruleParams.updatedDate,
                data: ruleParams.data,
                delta: ruleParams.delta,
                ruleParams, // sending this down for callRule -> takeActionByResponse
                assignees,
            });
            break;
        case 'A007':
            // 1) do update form values
            // 2) set new form data
            updateFormValues(data);
            setNewFormData({ formId, templateId, formStatus });
            break;
        case 'A006':
            // 1) do update form values
            // 2) do check form data
            // 3) do save as draft
            // 4) do A007

            updateFormValues(data);
            if (!initialFormId && formId) {
                setNewFormData({ formId, templateId, formStatus });
            }
            checkFormDataWithTimer({
                templateId,
                formId,
                initialFormData: data,
                updatedDate,
                ruleParams,
                nextAction: async () => {
                    // will do A007
                    const formData = await store.dispatch(actionCheckFormData(templateId, formId));
                    const response = {
                        action_cd: 'A007',
                        form: {
                            ...formData.form,
                            template: templateId,
                        },
                    };
                    takeActionByResponse(response, ruleParams, checkFormDataWithTimer);
                },
            });
            break;
        case 'A005':
            // 1) do update form values
            // 2) do check form data
            // 3) do save as draft
            // 4) do A001

            updateFormValues(data);
            if (!initialFormId && formId) {
                setNewFormData({ formId, templateId, formStatus });
            }
            checkFormDataWithTimer({
                templateId,
                formId,
                initialFormData: {}, // since submit have the same data
                updatedDate,
                ruleParams,
                nextAction: () => {
                    // will do A001
                    goToSubmittedForm({
                        templateId,
                        formId,
                    });
                },
            });

            break;
        case 'A003': // get file - get returned file
            getReturnedFile(file);
            break;
        case 'A002': // redirect to dashboard
            goToDashboardUser();
            break;
        case 'A001': // get submitted form
            goToSubmittedForm({
                templateId,
                formId,
            });
            break;
        default:
            break;
    }
}

/**
 * 1) do update form ID
 * 2) do update form status
 * 3) change browser URL to existing form url
 * @param {object} {formId, templateId, formStatus}
 */
function setNewFormData({ formId, templateId, formStatus }) {
    updateFormId(formId);
    updateFormStatus(formStatus);
    updateUrlToSubmittedForm({ templateId, formId });
}

export const ASSIGNEE_DROPDOWN_ID = 'assign_to_ignored';
export function showAssignToOther({
    ruleId,
    formId,
    templateId,
    applicationId,
    formStatus = {},
    updatedDate,
    data,
    delta,
    ruleParams,
    assignees,
}) {
    const form = Selectors.getForm(store.getState()) || {};
    const status = form.formStatus || {};
    applicationId = applicationId || form.applicationId;
    const formStatusName = formStatus.name || status.name;
    const formStatusId = formStatus.id;

    const assignOtherForm = createAssignOtherForm({
        applicationId,
        formStatus: formStatusName,
        assignees,
        assign: assignee =>
            submitAssignToOther({
                ruleId,
                formId,
                formStatus: formStatusId,
                templateId,
                updatedDate,
                data,
                delta,
                ruleParams,
                assignee,
            }),
        cancel: () => store.dispatch(actionSetActionForm({})),
    });

    // set and show form
    store.dispatch(actionSetActionForm(assignOtherForm));
}

export async function submitAssignToOther({
    ruleId,
    formId,
    formStatus,
    templateId,
    updatedDate,
    data,
    delta,
    ruleParams,
    assignee,
}) {
    // check validity
    if (!assignee) {
        showAssigneeIsMandatory();
        return;
    }
    // close form
    store.dispatch(actionSetActionForm({}));

    // take next action, call form/'rule_type'
    const response = await store.dispatch(
        callRule({
            ruleId,
            formId,
            formStatus,
            templateId,
            assignee,
            updatedDate,
            data,
            delta,
            ruleParams,
        }),
    );
    takeActionByResponse(response, ruleParams);
}

/**
 * A method that generate assign to other form based on given data in the first param
 *
 * @param {Object} data - {
 *   assignees {string[]}
 *   assign {Funciton}
 *   cancel {Function}
 * }
 * @returns {Object} formObject - can be displayed by dispatching setActionForm(formObject)
 */
export function createAssignOtherForm({
    assignees = [],
    applicationId,
    formStatus,
    assign,
    cancel,
}) {
    const form = {
        views: [
            {
                type: 'view',
                sections: [
                    {
                        type: 'section',
                        title: 'Section Assign to other',
                        name: 'Assign to other Section',
                        fieldsets: [
                            {
                                type: 'fieldset',
                                name: 'Fieldset',
                                grids: [
                                    {
                                        type: 'grid',
                                        fields: [
                                            {
                                                type: 'label',
                                                name: 'Application ID [Label]',
                                                title: `Application: ${applicationId}`,
                                                id: 'field_application_id',
                                                bold: true,
                                            },
                                        ],
                                    },
                                    {
                                        type: 'grid',
                                        className: 'decline-form-status',
                                        fields: [
                                            {
                                                type: 'label',
                                                name: 'Status [Label]',
                                                title: `Status: ${formStatus}`,
                                                id: 'field_statu',
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                type: 'fieldset',
                                id: 'fieldset_select_assignee',
                                grids: [
                                    {
                                        type: 'grid',
                                        fields: [
                                            {
                                                type: 'label',
                                                name: 'Please select a user [Label]',
                                                title: 'Please select a user',
                                                id: 'field_label',
                                                bold: true,
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                type: 'fieldset',
                                grids: [
                                    {
                                        type: 'grid',
                                        fields: [
                                            {
                                                type: 'select',
                                                name: 'Assignee [Dropdown]',
                                                label: {
                                                    type: 'outer',
                                                    title: 'Assignee',
                                                    position: 'top',
                                                },
                                                options: assignees,
                                                placeholder: 'Select one assignee',
                                                id: ASSIGNEE_DROPDOWN_ID,
                                            },
                                        ],
                                    },
                                    {
                                        type: 'grid',
                                        fields: [],
                                        id: 'grid_rn1519621234107',
                                    },
                                ],
                                id: 'fieldset_assignee',
                            },
                        ],
                    },
                ],
            },
        ],
    };
    const buttons = [
        {
            title: 'Assign',
            name: 'assign',
            onClick: () => {
                // validate
                const values = Selectors.getValues(store.getState());
                assign(values[ASSIGNEE_DROPDOWN_ID]);
            },
        },
        {
            title: 'Cancel',
            name: 'cancel',
            buttonType: 'secondary',
            onClick: () => {
                cancel();
            },
        },
    ];

    return { form, buttons };
}

export function showAssigneeIsMandatory() {
    store.dispatch(
        setValidities({
            [ASSIGNEE_DROPDOWN_ID]: {
                isValid: false,
                errorMessage: validators.isRequired.error,
            },
        }),
    );
}
