import {
    SET_VIEWS_FETCHING,
    SET_CURRENT_ACCESS_FETCHING,
    SET_TRIGGERS_FETCHING,
    SET_FIELD_VALIDATIONS_FETCHING,
    SET_ROLES_FETCHING,
    SET_FORM_STATUS_FETCHING,
    SET_TEMPLATE_VIEWS_FETCHING,
    SET_ORDER_FETCHING,
    SET_ACCESS_CHOICES_FETCHING,
    SET_VALIDATOR_CHOICES_FETCHING,
    SET_TRIGGER_CHOICES_FETCHING,
    SET_CURRENT_ACCESS_ERROR,
    SET_TRIGGERS_ERROR,
    SET_FIELD_VALIDATIONS_ERROR,
    SET_ROLES_ERROR,
    SET_FORM_STATUS_ERROR,
    SET_TEMPLATE_VIEWS_ERROR,
    SET_ORDER_ERROR,
    SET_ACCESS_CHOICES_ERROR,
    SET_VALIDATOR_CHOICES_ERROR,
    SET_TRIGGER_CHOICES_ERROR,
    SET_VIEWS,
    SET_CURRENT_ACCESS,
    SET_TRIGGERS,
    SET_FIELD_VALIDATIONS,
    SET_ROLES,
    SET_FORM_STATUS,
    SET_TEMPLATE_VIEWS,
    SET_ORDER,
    SET_ACCESS_CHOICES,
    SET_VALIDATOR_CHOICES,
    SET_TRIGGER_CHOICES,
    SET_VIEW,
    SET_FIELD_VALIDATION,
    SET_TRIGGER,
} from '../actions/TemplateAccessAction';

const INITIAL_STATE = {
    fetching: {
        viewaccess: false,
        roles: false,
        formStatus: false,
        views: false,
        templateViews: false,
        fieldValidations: false,
        triggers: false,
        order: false,
        accessChoices: false,
        validatorChoices: false,
        triggerChoices: false,
    },
    error: {
        viewaccess: null,
        roles: null,
        formStatus: null,
        views: null,
        templateViews: null,
        fieldValidations: null,
        triggers: null,
        order: null,
        accessChoices: false,
        validatorChoices: false,
        triggerChoices: false,
    },

    viewaccess: {
        disabled_fields: [],
        disabled_sections: [],
    },
    fieldValidations: {},
    roles: [],
    formStatus: [],
    triggers: {},
    templateViews: {},
    views: [],

    choices: {
        access: {
            // fieldsets: [],
            fields: [],
            sections: [],
        },
        validator: {
            fieldsets: [],
            fields: [],
        },
        trigger: {
            fieldsets: [],
            fields: [],
            triggerBase: [],
        },
    },
};

export default function TemplateAccessReducer(state = INITIAL_STATE, action) {
    const { payload } = action;
    switch (action.type) {
    case SET_CURRENT_ACCESS_FETCHING:
        return {
            ...state,
            fetching: {
                ...state.fetching,
                viewaccess: payload,
            },
        };

    case SET_FORM_STATUS_FETCHING:
        return {
            ...state,
            fetching: {
                ...state.fetching,
                formStatus: payload,
            },
        };

    case SET_ROLES_FETCHING:
        return {
            ...state,
            fetching: {
                ...state.fetching,
                roles: payload,
            },
        };

    case SET_VIEWS_FETCHING:
        return {
            ...state,
            fetching: {
                ...state.fetching,
                views: payload,
            },
        };

    case SET_TEMPLATE_VIEWS_FETCHING:
        return {
            ...state,
            fetching: {
                ...state.fetching,
                templateViews: payload,
            },
        };

    case SET_TRIGGERS_FETCHING:
        return {
            ...state,
            fetching: {
                ...state.fetching,
                triggers: payload,
            },
        };

    case SET_FIELD_VALIDATIONS_FETCHING:
        return {
            ...state,
            fetching: {
                ...state.fetching,
                rules: payload,
            },
        };

    case SET_ORDER_FETCHING:
        return {
            ...state,
            fetching: {
                ...state.fetching,
                order: payload,
            },
        };

    case SET_ACCESS_CHOICES_FETCHING:
        return {
            ...state,
            fetching: {
                ...state.fetching,
                accessChoices: payload,
            },
        };

    case SET_VALIDATOR_CHOICES_FETCHING:
        return {
            ...state,
            fetching: {
                ...state.fetching,
                validatorChoices: payload,
            },
        };

    case SET_TRIGGER_CHOICES_FETCHING:
        return {
            ...state,
            fetching: {
                ...state.fetching,
                triggerChoices: payload,
            },
        };

    case SET_CURRENT_ACCESS_ERROR:
        return {
            ...state,
            error: {
                ...state.error,
                viewaccess: payload,
            },
        };

    case SET_TRIGGERS_ERROR:
        return {
            ...state,
            error: {
                ...state.error,
                triggers: payload,
            },
        };

    case SET_FIELD_VALIDATIONS_ERROR:
        return {
            ...state,
            error: {
                ...state.error,
                rules: payload,
            },
        };

    case SET_ROLES_ERROR:
        return {
            ...state,
            error: {
                ...state.error,
                roles: payload,
            },
        };

    case SET_FORM_STATUS_ERROR:
        return {
            ...state,
            error: {
                ...state.error,
                formStatus: payload,
            },
        };

    case SET_TEMPLATE_VIEWS_ERROR:
        return {
            ...state,
            error: {
                ...state.error,
                templateViews: payload,
            },
        };

    case SET_ORDER_ERROR:
        return {
            ...state,
            error: {
                ...state.error,
                order: payload,
            },
        };

    case SET_ACCESS_CHOICES_ERROR:
        return {
            ...state,
            fetching: {
                ...state.error,
                accessChoices: payload,
            },
        };

    case SET_VALIDATOR_CHOICES_ERROR:
        return {
            ...state,
            fetching: {
                ...state.error,
                validatorChoices: payload,
            },
        };

    case SET_TRIGGER_CHOICES_ERROR:
        return {
            ...state,
            fetching: {
                ...state.error,
                triggerChoices: payload,
            },
        };

    case SET_CURRENT_ACCESS:
        return {
            ...state,
            viewaccess: payload,
        };

    case SET_TRIGGERS:
        return {
            ...state,
            triggers: payload,
        };

    case SET_FIELD_VALIDATIONS:
        return {
            ...state,
            fieldValidations: payload,
        };

    case SET_ROLES:
        return {
            ...state,
            roles: [payload],
        };

    case SET_FORM_STATUS:
        return {
            ...state,
            formStatus: [payload],
        };

    case SET_TEMPLATE_VIEWS:
        return {
            ...state,
            templateViews: payload,
        };

    case SET_VIEWS:
        return {
            ...state,
            views: payload,
        };

    case SET_ACCESS_CHOICES:
        return {
            ...state,
            choices: {
                ...state.choices,
                access: payload,
            },
        };

    case SET_VALIDATOR_CHOICES:
        return {
            ...state,
            choices: {
                ...state.choices,
                validator: payload,
            },
        };

    case SET_TRIGGER_CHOICES:
        return {
            ...state,
            choices: {
                ...state.choices,
                trigger: payload,
            },
        };

    case SET_ORDER:
        return {
            ...state,
            views: payload,
        };

    case SET_VIEW:
        return {
            ...state,
            views: {
                // ...state.views,
                payload,
            },
        };

    case SET_CURRENT_ACCESS:
        return {
            ...state,
            viewaccess: {
                payload,
            },
        };

    case SET_FIELD_VALIDATION:
        return {
            ...state,
            fieldValidations: {
                ...state.fieldValidations,
                ...payload,
            },
        };

    case SET_TRIGGER:
        return {
            ...state,
            triggers: {
                ...state.triggers,
                ...payload,
            },
        };
    default:
        return state;
    }
}
