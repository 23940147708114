import {
    SET_USER_INFO,
    LOGIN_USER_SUCCEED,
    LOGIN_USER_ERROR,
    SET_RECAPTCHA_RESPONSE,
    SET_LOGIN_FORM_VALUES,
} from '../actions/AuthenticationAction';

const INITIAL_STATE = {
    reducer: 'authentication',
    recaptchaResponse: null,
    headerName: '',
    alert: {
        header: '',
        message: '',
        statusCode: '',
        isShowing: false,
    },
    values: {},
};

export default function LoginReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
    case SET_RECAPTCHA_RESPONSE: {
        const { recaptchaResponse } = action;
        return {
            ...state,
            recaptchaResponse,
        };
    }
    case SET_USER_INFO: {
        return Object.assign({}, state, {
            info: action.response,
        });
    }
    case LOGIN_USER_SUCCEED: {
        const alert = Object.assign({}, state.alert);
        alert.header = 'Success';
        alert.message =
                JSON.stringify(action.response.message) || 'You have successfully logged in';
        alert.isShowing = true;

        return Object.assign({}, state, {
            alert,
        });
    }
    case LOGIN_USER_ERROR: {
        const alert = Object.assign({}, state.alert);
        alert.header = 'Error';
        alert.statusCode = action.response.status_cd || {};
        alert.message = action.response.status_desc || action.response.statusText;
        alert.isShowing = true;
        return Object.assign({}, state, {
            alert,
        });
    }
    case SET_LOGIN_FORM_VALUES: {
        const { values } = state;
        const newValues = {
            ...values,
            [action.key]: action.value,
        };
        return {
            ...state,
            values: newValues,
        };
    }
    default:
        return state;
    }
}
