import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';

import moment from 'moment-timezone';

i18n.use(HttpApi).init({
    fallbackLng: 'en',
    debug: true,
    ns: ['translation', 'fieldset'],
    interpolation: {
        escapeValue: false,
        format: function (value, format) {
            if (value instanceof Date) {
                return moment(value).format(format);
            }
            return value;
        },
    },
    resources: {
        en: {
            translation: {
                home: 'Home',
            },
            fieldset: {
                design: 'Design',
            },
            datetime: {
                date: '{{ date, MM/DD/YYYY }}',
            },
        },
        zh: {
            translation: {
                home: '主页',
            },
            fieldset: {
                design: '设计',
            },
        },
        my: {
            translation: {
                home: 'Laman Utama',
            },
            datetime: {
                date: '{{ date, DD/MM/YYYY }}',
            },
        },
    },
});

export const lngs = [
    {
        value: 'en',
        name: 'English',
    },
    {
        value: 'zh',
        name: 'Chinese',
    },
    {
        value: 'my',
        name: 'Bahasa Malaysia',
    },
];
export default i18n;
