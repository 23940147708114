import BaseAPI from '../BaseAPI';

class SystemConfigurationAPI extends BaseAPI {
    basePath = 'cap/system-configurations/';

    async getSystemConfigurations() {
        let systemConfigurations;

        try {
            const response = await this.get({
                path: this.basePath,
            });
            systemConfigurations = response.system_configurations;
        } catch (e) {
            throw e.responseJSON;
        }
        return {
            system_configurations: systemConfigurations,
        };
    }

    async saveSystemConfigurations(data) {
        let response;

        try {
            response = await this.patch({
                path: this.basePath,
                data,
            });
        } catch (e) {
            throw e.responseJSON;
        }

        return {
            response,
        };
    }
}

export const systemConfigurationAPI = new SystemConfigurationAPI();
export default SystemConfigurationAPI;
