import _ from 'underscore';
import { fieldAPI } from '../../api/template/FieldAPI';

export const SET_FIELD = 'SET_FIELD';
export function setField({ id, field }) {
    return {
        type: SET_FIELD,
        payload: {
            id,
            field,
        },
    };
}

export const SET_FIELDS = 'SET_FIELDS';
export function setFields(fields) {
    return {
        type: SET_FIELDS,
        fields,
    };
}

export const SET_FIELDS_FETCHING = 'SET_FIELDS_FETCHING';
export function setFieldsFetching(fetching) {
    return {
        type: SET_FIELDS_FETCHING,
        fetching,
    };
}

export const SET_FIELDS_ERROR = 'SET_FIELDS_ERROR';
export function setFieldsError(error) {
    return {
        type: SET_FIELDS_ERROR,
        error,
    };
}

export const actionGetFields = ({ templateId }) => {
    return async dispatch => {
        dispatch(setFieldsFetching(true));
        try {
            let fields = (await fieldAPI.getTemplateFields(templateId)) || {};
            fields = _.indexBy(fields, 'id');
            dispatch(setFields(fields));
        } catch (error) {
            dispatch(setFieldsError(error));
        } finally {
            dispatch(setFieldsFetching(false));
        }
    };
};
