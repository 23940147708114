import React from 'react';
import _ from 'underscore';
import { compose, withHandlers } from 'recompose';

const Header = ({ className, title, children = [], width, sort, sorting, asc, onClick }) => {
    const iconCls = ['icon', 'sort', sorting ? `caret ${ asc ? 'up' : 'down' }` : ''];
    const style = width ? { minWidth: width } : null;

    return (
        <th className={'table__header ' + className} style={style} onClick={onClick}>
            {!!title && title}
            {children.length && children}
            {sort && <i className={iconCls.join(' ')}></i>}
        </th>
    );
};

const HeaderRow = ({
    data = {},
    columnActions = {},
    sorting,
    asc,

    onHeaderClick,
}) => {
    let { headers = {}, showNumbering } = data;
    showNumbering = showNumbering === undefined ? true : showNumbering;

    const { header = {} } = columnActions || {};
    const components = Object.entries(headers).map((header, index) => {
        let value = header[1];
        value = value || {};
        const { title, name, width, sort = true, sorting, asc = false } = value;

        return (
            <Header
                key={index}
                width={width}
                sort={sort}
                asc={asc}
                sorting={sorting}
                className="sort"
                onClick={e => {
                    sort && onHeaderClick(e, { name, index, asc: !asc });
                }}>
                {title}
            </Header>
        );
    });

    return (
        <tr>
            {showNumbering && (
                <Header
                    key={-1}
                    index={-1}
                    sort={true}
                    sorting={sorting}
                    asc={asc}
                    className="sort"
                    onClick={e => {
                        onHeaderClick(e, { name: '_no', asc: !asc, index: -1 });
                    }}>
                    No.
                </Header>
            )}
            {components}
            {!_.isEmpty(columnActions) && <Header key={header.title}>{header.title}</Header>}
        </tr>
    );
};

export const ComposedHeaderRow = compose(
    withHandlers({
        handleHeaderCheckboxChanged: ({ pageInfo, tableRows, onCheckboxChanged = () => {} }) => (
            e,
            data,
        ) => {
            const rows = tableRows;
            const info = pageInfo;
            const checked = data.checked;

            typeof onCheckboxChanged === 'function' &&
                onCheckboxChanged({
                    rows,
                    info,
                    checked,
                });
        },
    }),
)(HeaderRow);

const TableHeader = props => {
    return (
        <thead>
            <ComposedHeaderRow {...props} />
        </thead>
    );
};

export default TableHeader;
