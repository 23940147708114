import React from 'react';
import Proptypes from 'prop-types';
import _ from 'underscore';
import { compose, withHandlers } from 'recompose';
import TableColumn from './Column';
import { ActionButton, getIconFromName } from './Misc';
import * as IcFeature from '../../../images/features/';

const Row = ({ index, children }) => (
    <tr className={'table__row row__' + (index + 1)}>{children}</tr>
);

const TableRow = ({
    data,
    columnActions,
    links,
    headers,
    columns,
    totalRowIndex,
    rowIndex,
    showNumbering,

    handlePresetClick = () => {},
    mapActionComponents = () => {},
}) => {
    const { actions = {} } = columnActions;
    let indexNum;
    const components = Object.entries(headers).map(([_, header], index) => {
        indexNum = index;
        const { name } = header || {}; // header: { title, name, width, sort }
        const column = columns[name] || '';
        const rows = data.rows; // data.rows: [[rowNumber, rowObject], ...]

        if (links[name]) {
            let { onClick } = links[name];
            onClick =
                typeof onClick === 'function'
                    ? onClick
                    : typeof onClick === 'object'
                        ? handlePresetClick(columns, onClick)
                        : () => {};
            return (
                <TableColumn
                    key={index}
                    index={index}
                    header={header}
                    rows={rows}
                    rowIndex={rowIndex}>
                    <span
                        className="table__column-clickable"
                        onClick={e => {
                            onClick(e, {
                                context: this,
                                index,
                                columnData: column,
                                columns,
                                rows,
                            });
                        }}>
                        {column}
                    </span>
                </TableColumn>
            );
        } else {
            return (
                <TableColumn
                    key={index}
                    index={index}
                    rowIndex={rowIndex}
                    header={header}
                    rows={rows}>
                    {column}
                </TableColumn>
            );
        }
    });

    return (
        <Row index={totalRowIndex}>
            {showNumbering && <TableColumn key={-1}>{++totalRowIndex}</TableColumn>}
            {components}
            {!_.isEmpty(columnActions) && (
                <TableColumn key={++indexNum} className="table__actions">
                    <ActionButton />
                    <div className="action__wrapper">
                        {Object.entries(actions).map(
                            mapActionComponents(rowIndex, columns, headers),
                        )}
                    </div>
                </TableColumn>
            )}
        </Row>
    );
};

TableRow.defaultProps = {
    data: {},
    columnActions: {},
    links: {},
    headers: [],
    columns: {},
    showNumbering: false,
};

TableRow.propTypes = {
    data: Proptypes.object,
    columnActions: Proptypes.object,
    links: Proptypes.object,
    headers: Proptypes.array,
    columns: Proptypes.object,
    showNumbering: Proptypes.bool,
};

export default compose(
    withHandlers({
        handleColumnCheckboxChanged: ({ onCheckboxChanged }) => (e, data) => {
            typeof onCheckboxChanged === 'function' &&
                onCheckboxChanged({
                    rowIndex: data.rowIndex,
                    checked: data.checked,
                });
        },
        handlePresetClick: () => (rowData, onClick = {}) => {
            const { preset, path, params } = onClick;
            let generatedPath = '';

            switch (preset) {
            case '_blank':
            default:
                return function() {
                    generatedPath = '';
                    Object.entries(params).forEach((param, index) => {
                        const key = param[1];
                        generatedPath +=
                                (index === 0 ? path : '') +
                                '/' +
                                (rowData[key] ? rowData[key] : key);
                    });
                    window.location.href = generatedPath;
                };
            }
        },
        mapActionComponents: () => (rowIndex, rowData, headers) => {
            return (button, index) => {
                let buttonObj = button[1];
                buttonObj = buttonObj || {};
                let { title, name, image, icon, onClick, shouldButtonShow } = buttonObj;
                let showButton = true;
                name = name || title || '';
                image = image || getIconFromName(name) || IcFeature.IconEdit;
                onClick =
                    onClick ||
                    (e => {
                        e.preventDefault();
                    });

                if (typeof shouldButtonShow === 'function') {
                    showButton = shouldButtonShow.bind(this)({
                        row: rowData,
                        index: rowIndex,
                    });
                }

                if (showButton) {
                    return (
                        <div
                            key={index + 'link'}
                            className="action__button"
                            onClick={e => {
                                onClick(e, {
                                    id: index,
                                    row: rowData,
                                    headers,
                                    // tableDOM: table
                                });
                            }}
                            data-rh={title}>
                            {!icon && (
                                <img
                                    className="button__icon"
                                    alt={name}
                                    title={title}
                                    src={image}
                                />
                            )}
                            {icon}
                        </div>
                    );
                } else {
                    return null;
                }
            };
        },
    }),
)(TableRow);
