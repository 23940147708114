import { SET_CUSTOM_LIST_OPTIONS } from '../actions/CustomListAction';

const INITIAL_STATE = {
    customList: {},
    fetching: false,
    error: null,
};

export default function customListReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
    case SET_CUSTOM_LIST_OPTIONS: {
        const { customList } = action;
        return {
            ...state,
            customList: customList,
        };
    }
    default:
        return state;
    }
}
