import _ from 'underscore';
import store from '../../../store';
import * as FR from '../../Form/FormRules';

import { compose, withHandlers, withState, lifecycle, mapProps, shouldUpdate } from 'recompose';

// ACTIONS
import { setValidity } from '../../../redux/actions/ValidityAction';
import { setValue } from '../../../redux/actions/ValueAction';
import { setValidityTargets } from '../../../redux/actions/ValidityAction';
import { setTriggeredState, setTriggeredStates } from '../../../redux/actions/TriggerAction';
import { setDisabledFields } from '../../../redux/actions/DisabledAction';
import { actionSetActionForm } from '../../../redux/actions/FormAction';

// Misc
import { NULL_VALUE_TEXT } from './misc';
import setTargetTriggerValues from './setTargetTriggerValues';
import replaceValidation from './replaceValidation';
import filterByIds from './filterByIds';
import getDependants from './getDependants';
import getFields from './getFields';
import getFieldsIdMapObject from './getFieldsIdMapObject';
import getFieldsIndexByFieldset from './getFieldsIndexByFieldset';
import getFieldsets from './getFieldsets';
import getFormObjRelationships from './getFormObjRelationships';
import getFormTriggers from './getFormTriggers';
import processExtendedValues from './processExtendedValues';
import resetFieldsValidity from './resetFieldsValidity';
// HOCs
import withFormUtils from './withFormUtils';
import withProcessedFieldname from './withProcessedFieldname';
import withCampaignCheck from './withCampaignCheck';
import withIsEditableUpdate from './withIsEditableUpdate';
import withProcessedTriggerState from './withProcessedTriggerState';
import withClearOnHide from './withClearOnHide';
import withValueStateHandler from './withValueStateHandler';
import withReplacedValidityCheck from './withReplacedValidityCheck';
import withLabelMandatory from './withLabelMandatory';
import withActionsHandlers from './withActionsHandlers';
import withPrimaryRadioButtonLogic from './withPrimaryRadioButtonLogic';
import withTerminalConfigurationsListCheck from './withTerminalConfigurationsListCheck';
import withFieldProcessedProps, { withProcessedProps } from './withFieldProcessedProps';
import withUpdateValueHandler from './withUpdateValueHandler';
import withExtendingPropsHandler from './withExtendingPropsHandler';
import withDependencyCheckHandler from './withDependencyCheckHandler';
import withGetTriggerValidityHandler from './withGetTriggerValidityHandler';
import withChangeFieldValueValidityHandler from './withChangeFieldValueValidityHandler';
import withChangeHandler from './withChangeHandler';
import withOnValuesChange from './withOnValuesChange';
import withReducerHandler from './withReducerHandler';

export const OWN_SUFFIX = '__own';
export const REPLACE_VALIDATION_KEY = 'replaceValidation';
export const APPROVAL_FIELD_KEY = 'approval';
/** 0 : approve, 1 : reject, 2 : return */
export const APPROVAL_OPTIONS = ['approve', 'reject', 'return'];
/** 0 : submit, 1: save */
export const ACTION_BUTTON_ACTIONS = ['submit', 'save'];

export function hideForm() {
    return actionSetActionForm({});
}
export function getFormValues(form) {
    const validity = FR.validateForm({ form });
    return validity.valueRecords;
}

const withUpdateLimiter = compose(
    shouldUpdate((props, nextProps) => {
        if (!_.isEqual(props, nextProps)) return true;
        return false;
    }),
);

export const withCaptureFieldHandlers = compose(
    withUpdateLimiter,
    withHandlers({
        onByValueChanged: () => ({ by, target }) => {
            store.dispatch(setValidityTargets({ by, target }));
        },
        onValueChanged: () => ({ id, value }) => {
            store.dispatch(setValue({ path: id, value }));
        },
        onValidityChange: () => ({ id, validity }) => {
            store.dispatch(setValidity({ path: id, validity }));
        },
        onTriggerStateChange: () => ({ id, triggered }) => {
            store.dispatch(setTriggeredState({ id, triggered }));
        },
        onTriggerStatesChange: () => states => {
            store.dispatch(setTriggeredStates(states));
        },
        onIsEditableChanged: () => ({ id, isEditable }) => {
            store.dispatch(
                setDisabledFields({
                    [id]: !isEditable,
                }),
            );
        },
    }),
    withValueStateHandler,
    withFieldProcessedProps,
    // ### end
    // handlers list:
    // getTriggerValidity
    withGetTriggerValidityHandler,
    // ### end
    // * required processed and updated value
    // value was update in withFieldProcessedProps
    // so, this is defined after withFieldProcessedProps
    withExtendingPropsHandler,
    // ### end
    // handlers list:
    // dependencyCheck
    withDependencyCheckHandler,
    withState('schema', 'setSchema'),
    // ### end
    // handlers list:
    // changeFieldValueValidity
    withChangeFieldValueValidityHandler,
    // ### end
    // handlers list:
    // updateValue
    withUpdateValueHandler,
    // ### end
    // handlers list:
    // handleChange handler that let field component
    // to update it's value
    //
    // depending methods: setLocalValue, updateValue
    withChangeHandler,
    // ### end
    // This section generate value in props.extend
    // In order to allow handleReducerAndStorage to have the updated
    //   value, this have to run before the handler
    // depending methods: setLocalValue, updateValue
    withOnValuesChange,
    // ### end
    // handlers list:
    // handleReducerValue
    // A handler that's run when field is mounted.
    //   to check reducer value and set either default
    //   or reducer value
    withReducerHandler,
    // ### end
    // to check replacedValidation and update validity of the field if need
    withReplacedValidityCheck,
    // to add mandatory (*) to the field label if needed
    withLabelMandatory,
    // ### end
    // lifecycle :
    // componentDidUpdate, componentDidMount
    //   componentDidUpdate
    //   - check dependency when trigger state changed
    //   - update extendingProps.value when it's needed
    //   componentDidMount
    //   - checkTrigger
    lifecycle({
        // depending methods: setLocalValue, dependencyCheck
        componentDidUpdate(prevProps) {
            const {
                clearOnHide,
                extendingProps = {},
                value,
                values,
                localValue,
                triggeredState,
                showComponent,
                // handler
                updateValue,
                setLocalValue,
            } = this.props;

            const { values: prevValues, triggeredState: prevTriggeredState } = prevProps;
            const { value: extValue } = extendingProps;

            // Check dependency whenever field triggeredState changed
            if (!_.isEqual(prevTriggeredState, triggeredState)) {
                this.props.dependencyCheck();
            }

            if (_.isEqual(prevValues, values)) return;

            // Update extendingProps.value when it's necessary
            const valueNotEqual = !_.isEqual(value, extValue);
            // If clearOnHide is set and component is hidden, don't update it.
            const shouldIgnoreExtends = clearOnHide && !showComponent;
            if (valueNotEqual && !shouldIgnoreExtends && !_.isUndefined(extValue)) {
                if (localValue !== extValue) {
                    setLocalValue(extValue);
                }
                updateValue({
                    value: extValue,
                    force: true,
                });
            }
            if (!_.isEqual(localValue, value) && (_.isNull(extValue) || _.isUndefined(extValue))) {
                if (value && showComponent) {
                    updateValue({
                        value,
                        force: true,
                    });
                }

                this.props.dependencyCheck();
            }
        },
        componentDidMount() {
            const {
                setLocalValue,
                updateValue,
                dependencyCheck,
                handleReducerValue,
                extendingProps,
            } = this.props;

            // Whenever a field mount
            //  check it's trigger and
            //  reducer value
            dependencyCheck(true);
            handleReducerValue();

            // Required to update extend value
            //  if depending component not in
            //  same view (value that's not updated)
            if (extendingProps && !_.isUndefined(extendingProps.value)) {
                const extValue = extendingProps.value;
                const localValue = this.props.localValue;
                const value = this.props.value;

                // Update extendingProps.value when it's necessary
                const valueNotEqual = !_.isEqual(value, extValue);
                if (extValue != null && valueNotEqual) {
                    if (localValue !== extValue) {
                        setLocalValue(extValue);
                    }
                    updateValue({
                        value: extValue,
                        force: true,
                    });
                }
            }
        },
    }),
    // ### end
    // mapProps
    // to filter out unnessary props that's generated
    //   above, so that component will not have not
    //   required props.
    mapProps(
        ({
            form,
            initialValue,
            reduxValue,
            extendingProps,
            validateForm,
            localStorageValue,
            updateValue,
            dependencyCheck,
            handleReducerValue,
            generateExtendingProps,
            changeFieldValueValidity,
            onByValueChanged,
            onValueChanged,
            onValidityChange,
            onIsEditableChanged,
            onTriggerStateChange,
            onTriggerStatesChange,
            ...rest
        }) => ({
            ...rest,
        }),
    ),
);

export {
    NULL_VALUE_TEXT,
    replaceValidation,
    filterByIds,
    getDependants,
    getFields,
    getFieldsIdMapObject,
    getFieldsIndexByFieldset,
    getFieldsets,
    getFormObjRelationships,
    getFormTriggers,
    processExtendedValues,
    resetFieldsValidity,
    setTargetTriggerValues,
    withFormUtils,
    withProcessedFieldname,
    withCampaignCheck,
    withIsEditableUpdate,
    withProcessedTriggerState,
    withClearOnHide,
    withLabelMandatory,
    withActionsHandlers,
    withPrimaryRadioButtonLogic,
    withTerminalConfigurationsListCheck,
    withFieldProcessedProps,
    withProcessedProps,
};
