import store from 'store';
import _ from 'underscore';
import { getTriggers } from 'redux/selectors';
import getFields from './getFields';

function getFieldsIndexByFieldset(obj) {
    const triggers = getTriggers(store.getState());
    const result = {};
    loop(obj);
    return result;

    function loop(obj) {
        if (!_.isObject(obj)) return;
        Object.entries(obj).forEach(child => {
            const value = child[1];
            const { type, id } = value || {};
            const haveOwnTrigger = triggers[id]; // so that it's not shown when parent is shown, it should run it's own trigger
            if (type === 'fieldset' && !haveOwnTrigger) {
                result[id] = getFields(value);
            } else {
                loop(value);
                return;
            }
        });
    }
}

export default getFieldsIndexByFieldset;
