import React from 'react';
import _ from 'underscore';
import PropTypes from 'prop-types';
import ProductMDR from './ProductMDR';

import { compose, lifecycle, shouldUpdate, withPropsOnChange } from 'recompose';
import store from '../../../store';
import { getTriggerDependants } from '../../../redux/selectors';
import { setTriggeredDependants } from '../../../redux/actions/TriggerAction';
import { withProcessedProps } from '../../Misc/forms';
import BreakLine from '../BreakLine';

export const MDR_ID = 'standard_merchant_rates';
const SELECTED_BY = 'product_types';

// Whenever the component is being used in form builder, it will required to add trigger
//   to build the relationship of it with Prodcut Types

const EnhancedProductMDR = compose(
    shouldUpdate((current, next) => {
        return !_.isEqual(current, next);
    }),
)(ProductMDR);

const MDR = ({
    products,
    id,
    initialId,
    valueId,
    setId,
    tabId,
    setPath,
    tabPath,
    bold,
    value,
    validity,
    triggers,
    triggeredState,
    selected,
    isParentEditable,
    isEditable,
    isRequired,
}) => {
    valueId = valueId || initialId;
    // to handle isParentEditable object type,
    //   when parent is not editable, so is mdr field
    if (!isParentEditable) {
        isEditable = false;
    }

    const classNames = ['input__mdr', !selected.length && 'no-mid-tid'];

    return (
        <div className={classNames.join(' ')} id={id}>
            {products.map((p, i) => {
                let foundValueIndex = 0;
                const foundSelectedIndex = selected.indexOf(p.value);

                let foundValue = _.find(value, (v, i) => {
                    if (v && v.product === p.value) {
                        foundValueIndex = i;
                        return true;
                    }
                });

                const foundValidity =
                    _.find(validity, v => {
                        if (v && v.product === p.value) {
                            return true;
                        }
                    }) || validity[foundValueIndex];

                const isProductEditable = _.isBoolean(isEditable)
                    ? isEditable
                    : isEditable[p.value];
                const isProductRequired = isRequired;
                // do not need to render when it's not selected
                if (foundSelectedIndex < 0) {
                    return false;
                }

                // fallback the index of the loop
                // if no value found at any index.
                // when there's no value but with validity
                if (!foundValue) {
                    foundValueIndex = foundSelectedIndex;
                }

                if (p.default_mdr && !_.isEmpty(p.default_mdr)) {
                    foundValue = foundValue || {};
                    const defaultMdr = { ...p.default_mdr, product: p.value };
                    foundValue = { ...defaultMdr, ...foundValue };
                }

                const productSetPath =
                    setPath && setPath !== initialId
                        ? `${ setPath }.${ initialId }.${ foundValueIndex }`
                        : `${ initialId }.${ foundValueIndex }`;
                const productTabPath =
                    tabPath && tabPath !== valueId
                        ? `${ tabPath }.${ valueId }.${ foundValueIndex }`
                        : `${ valueId }.${ foundValueIndex }`;

                return [
                    <EnhancedProductMDR
                        key={p.value || i}
                        product={p.value}
                        mdrs={p.mdrs}
                        label={{ title: p.title }}
                        bold={bold}
                        id={`${ id }.${ foundValueIndex }`}
                        initialId={initialId}
                        index={foundValueIndex}
                        setId={setId}
                        tabId={tabId}
                        setPath={productSetPath}
                        tabPath={productTabPath}
                        value={foundValue}
                        validity={foundValidity}
                        isEditable={isProductEditable}
                        isRequired={isProductRequired}
                        trigger={triggers}
                        triggeredStates={triggeredState[foundValueIndex]}
                    />,
                    <BreakLine key={i} />,
                ];
            })}
            {!selected.length && <div>No MDR</div>}
        </div>
    );
};

MDR.propTypes = {
    isEditable: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    isRequired: PropTypes.object,
};

MDR.defaultProps = {
    id: MDR_ID,
    products: [],
    bold: true,
    selected: [],
    value: [],
    validity: [],
    triggers: {},
    triggeredState: [],
    isParentEditable: true,
    isEditable: {},
    isRequired: {},
};

export default compose(
    // need this to be on top, so that id (path)
    //  that's being use by other recompose is right
    withProcessedProps,
    withPropsOnChange(['products'], ({ products }) => {
        if (_.isArray(products)) return {};
        const { list_id } = products || {};
        const { lists = {} } = store.getState().list || {};

        return {
            products: lists[list_id],
        };
    }),
    withPropsOnChange(['selected', 'values'], ({ selected, values = {} }) => {
        if (_.isArray(selected)) return {};
        const { by = SELECTED_BY } = selected || {};

        return {
            selected: values[by],
        };
    }),
    lifecycle({
        componentDidMount() {
            const { id = MDR_ID, selectedBy = SELECTED_BY } = this.props;
            // set dependants
            const state = store.getState();
            const dependants = getTriggerDependants(state)[selectedBy] || [];

            if (dependants.indexOf(id) < 0) {
                dependants.push(id);
                store.dispatch(
                    setTriggeredDependants({
                        [selectedBy]: [...dependants],
                    }),
                );
            }
        },
    }),
)(MDR);
