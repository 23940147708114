import store from 'store';
import _ from 'underscore';
import { compose, lifecycle } from 'recompose';
import { setTargetTriggerValues } from '.';
import { getLists } from 'redux/selectors';
import * as FieldsSelectors from 'redux/selectors/FieldSelector';

// NOTE: This applicable for field that has set custom_properties.target_list_trigger_value only
const withTargetListTriggerValue = compose(
    lifecycle({
        componentDidUpdate({ target_list_trigger_value: triggerValues, value }) {
            if (_.isEqual(value, this.props.value)) return;
            if (!triggerValues || this.props.value == null) return;
            const state = store.getState();
            const lists = getLists(state);

            const { clearOnHide, showComponent } = this.props;
            // It should set validity of targets if `this` does not have the
            // clearOnHide flag or if the `this` is currently shown
            const shouldSetValidity = !(clearOnHide && !showComponent);
            setTargetTriggerValues({
                triggerValues,
                key: this.props.value,
                lists,
                path: this.props.setPath,
                shouldSetValidity,
                fields: FieldsSelectors.getFields(store.getState()),
            });
        },
    }),
);

export default withTargetListTriggerValue;
