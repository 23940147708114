export const STATUS_CODE = {
    CODEKEY: 'status_cd',
    MESSAGE_KEY: 'status_desc',
    LOGGED_OUT: '0009',
    SUCCESS: '0000',
    UNSUCCESSFUL: '0001',
    NOT_FOUND: '0002',
    FAIL: '0001',
    FORBIDDEN: '1000',
    USER_NOT_FOUND: '1000',
    USER_ALREADY_EXISTS: '1001',
    MALICIOUS_FILE: '1002',
    INVALID_FILE: '1003',
    USER_ALREADY_EXISTS_CAN_USE: '2000',
    USER_NOT_FOUND_CAN_CREATE: '2001',
    USER_NOT_ALLOWED: '2002',
    USER_WEAK_PASSWORD: '2100',
    BACKEND_INFO: '2000',
    USER_CANT_SET_DORMANT: '3000',
    USER_FORCE_PASSWORD_CHANGE: '3001',
    OUTDATED_DATA: '4001',
    EMAIL_NOTIFICATION_EXCEED_LIMIT: '5001',
    USER_NEEDS_REASSIGN: '7000',
    BAD_REQUEST: '9999',
};

export const HTTP_CODE = {
    CODEKEY: 'status',
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    NOT_ACCEPTED: 406,
    SERVER_ERROR: 500,
};

export const ERROR_MESSAGES = {
    [STATUS_CODE.USER_ALREADY_EXISTS]: 'User already exists',
    [STATUS_CODE.USER_NOT_ALLOWED]:
        'Error. Please ensure user exist in LDAP before proceeding with user creation.',
    [STATUS_CODE.MALICIOUS_FILE]: 'Potential malicious content in file detected',
    [STATUS_CODE.INVALID_FILE]:
        'Invalid file uploaded. Please upload a valid PNG, JPEG/JPG or PDF file.',
    [HTTP_CODE.SERVER_ERROR]: 'Internal Server Error. Please contact admin for assistance.',
    [HTTP_CODE.NOT_FOUND]: '404 Not Found',
    INVALID_PASSWORD: 'Invalid Password',
    default: 'Error has occurred',
};

export const MESSAGES = {
    // Password
    RECOVERY_LINK: 'We have sent recovery link to your registered email.',
    CHANGED_PASSWORD: 'Successfully changed password',
    RESET_PASSWORD: 'Password Reset Successfully',
    // User
    USER_EXIST_IN_IAM_TEXT: 'Set a role for this new User',
    USER_NOT_EXISTS_TEXT: 'Fill in user details below',
};
