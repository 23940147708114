import React from 'react';
import _ from 'underscore';

import Dropdown from './Dropdown';
import IEDropdown from './IEDropdown';
import ErrorMessage from './ErrorMessage';
import Label from './../Label';
import store from '../../../store';
import ObjectPath from 'object-path';

import { connect } from 'react-redux';
import { compose, branch, withProps, lifecycle, mapProps } from 'recompose';
import { translateToSemanticDropdown } from '../../Translator';
import { FieldAction } from '../../Misc/template';

import { withCaptureFieldHandlers, withTerminalConfigurationsListCheck } from '../../Misc/forms';
import { cleanValue } from '../FormRules';
import { getLists } from '../../../redux/selectors/ListsSelector';
import { actionGetList } from '../../../redux/actions/ListAction';
import joinListMappingPath from '../../Misc/forms/joinListMappingPath';
import withDeprecatedOptions from '../../Misc/forms/withDeprecatedOptions';

const FETCH_KEY = 'fetch';
const FETCHING_KEY = 'fetching';
const FETCHING_KEYS = [FETCH_KEY, FETCHING_KEY];
const BaseDropdownHOC = Component =>
    class BaseDropdown extends React.Component {
        topClass = 'input__label--top';
        classWarning = 'warning';

        static defaultProps = {
            className: '',
            fieldname: {},
            label: {},
            options: [],
            placeholder: '',
            isValid: true,
            isMultiple: false,
            showUnavailable: true,
        };

        constructor(props) {
            super(props);
            this.props = props;

            this.handleChange = this.props.handleChange.bind(this);
        }

        render() {
            const {
                id,
                className,
                fieldname,
                // option
                type,
                title,
                name,
                placeholder,
                options,
                label,
                value,
                isMultiple,
                onChange,
                isEditable,
                isLoading,
                isValid,
                errorMessage,
                message,
                maxLoad,
                searchAtChar,
                searchAtStarting,
                showUnavailable,
                // utils
                showComponent,
                path,
            } = this.props;
            const option = {
                type,
                title,
                name,
                placeholder,
                options,
                label,
                value,
                isMultiple,
                onChange,
                message,
            };
            const semanticOptions = translateToSemanticDropdown({
                options,
                retainOld: true,
            });
            const classNames = [
                'jpt--input input__select',
                _.isString(className) && className,
                !isValid && this.classWarning,
                !cleanValue(value) && !isEditable && 'null-value',
            ];

            if (!showComponent) return <div />;

            return (
                <div className={classNames.join(' ')} id={id}>
                    {!_.isEmpty(label) && <Label className={this.topClass} {...label} />}

                    <Component
                        className="input__dropdown"
                        options={semanticOptions}
                        maxLoad={maxLoad}
                        searchAtChar={searchAtChar}
                        searchAtStarting={searchAtStarting}
                        placeholder={placeholder}
                        isEditable={isEditable}
                        isMultiple={isMultiple}
                        isLoading={isLoading}
                        showUnavailable={showUnavailable}
                        value={value}
                        onChange={(e, data) => {
                            this.handleChange(e, { ...data, fieldname, options: semanticOptions });
                            onChange(e, { ...data, fieldname, options: semanticOptions });
                        }}
                    />

                    <ErrorMessage message={errorMessage} />

                    {this.props.isEditingField && <FieldAction path={path} field={option} />}
                </div>
            );
        }
    };

/**
 * HOC to enhance component withCaptureFieldHandlers,
 * and options getting from redux.list state.
 */
const enhance = compose(
    // connect dropdown with global lists
    connect(
        (state, { options, values, target_list_trigger_value: targetListTriggerValues }) => {
            if (!_.isObject(options) || _.isArray(options)) return {};
            const { listID } = options;
            const lists = getLists(state);
            let listIdOptions = lists[listID];

            if (options.hasOwnProperty('dynamic_field')) {
                listIdOptions = options['list'][values[[options['dynamic_field']]]];
            }

            if (listID) {
                const path = joinListMappingPath('', String(listID), '', values);
                const mappedListOptions = ObjectPath.get(lists, path, null);
                listIdOptions = mappedListOptions ? mappedListOptions : listIdOptions;

                if (listID == 14) {
                    const { templateDetail } = state.templateDetail;
                    listIdOptions = listIdOptions.filter(code => {
                        if (code.clients) {
                            return (
                                Object.keys(code.clients).includes(templateDetail.client) &&
                                code.clients[templateDetail.client].includes(templateDetail.id)
                            );
                        } else {
                            return true;
                        }
                    });
                }
            }
            const isLoading = FETCHING_KEYS.includes(lists[getListID(listID)]);
            // isLoading need to be checked at this level so that eventhough
            // no mapped option is returned from loaded lists,
            // fetching state should be set to false now, since lists is loaded
            // e.g. fetching will not be updated in withProps if no update on the listIdOptions, 'undefined' -> 'undefined',
            // resulting fetching state to be true
            return {
                listID,
                listIdOptions,
                isLoading,
                targetListTriggerValues,
            };
        },
        dispatch => {
            return {
                loadList: id => {
                    dispatch(actionGetList(id));
                },
            };
        },
    ),
    withTerminalConfigurationsListCheck,
    withProps(({ options = {}, listIdOptions, defaultFirstOption }) => {
        if (_.isArray(options)) return;
        const { clear } = options;
        const firstOption = (listIdOptions && listIdOptions[0]) || {};
        const isDefaultFirstOption = defaultFirstOption && firstOption.value;

        return {
            options: listIdOptions,
            defaultFirstOption: isDefaultFirstOption,
            clearOnUnavailable: clear && !isDefaultFirstOption,
        };
    }),
    withCaptureFieldHandlers,
    // to clear dropdown value that's not in options
    lifecycle({
        componentDidMount() {
            const {
                options,
                value,
                clearOnUnavailable,
                isMultiple,
                handleChange,
                listID,
                isLoading,
                loadList,
            } = this.props;
            let { targetListTriggerValues } = this.props;
            if (clearOnUnavailable && !_.isEmpty(options) && _.isArray(options)) {
                handleClearValue({ options, value, isMultiple, handleChange });
            }

            // if targetListTriggerValues is set, load it's depending list from given .list_id
            if (targetListTriggerValues) {
                // value handling is done here, it used to be object instead of object[] format
                if (!_.isArray(targetListTriggerValues)) {
                    targetListTriggerValues = [targetListTriggerValues];
                }
                loadDependingLists(targetListTriggerValues, loadList);
            }

            // if isLoading, load list
            if (isLoading) {
                loadList(getListID(listID));
            }
        },
        componentDidUpdate({ options: prevOptions }) {
            const { options, value, clearOnUnavailable, isMultiple, handleChange } = this.props;
            if (_.isEqual(options, prevOptions)) {
                return;
            }
            if (clearOnUnavailable && !_.isEmpty(options) && _.isArray(options)) {
                handleClearValue({ options, value, isMultiple, handleChange });
            } else if (clearOnUnavailable && _.isEmpty(options)) {
                // to clear value when options list change from with option list to empty list
                handleClearValue({ options: [], value, isMultiple, handleChange });
            }
        },
    }),
    // deprecated options must only be processed after options / options.listID mapping all done
    withDeprecatedOptions,
    // logic to set dropdown with the first option value
    // - mount: set when dropdown first mount, options available and value is empty.
    // - update: set when dropdown options changed, and options available
    lifecycle({
        componentDidMount() {
            const { defaultFirstOption, options, value, isMultiple, handleChange } = this.props;
            if (!defaultFirstOption) return;
            if (!_.isEmpty(options) && _.isEmpty(value)) {
                const firstOption = options[0] || {};
                handleSetFirstOption({ value, firstOption, isMultiple, handleChange });
            }
        },
        componentDidUpdate(prevProps) {
            const { defaultFirstOption, options, value, isMultiple, handleChange } = this.props;
            if (!defaultFirstOption) return;
            const isEqualOptions = _.isEqual(options, prevProps.options);

            if (!_.isEmpty(options) && !isEqualOptions) {
                const firstOption = options[0] || {};
                handleSetFirstOption({ value, firstOption, isMultiple, handleChange });
            }
        },
    }),
    mapProps(({ listIdOptions, ...rest }) => ({
        ...rest,
    })),
);

/**
 * Branch, Conditionally Render Dropdown
 * If IE9 then show IE dropdown else show normal
 * dropdown
 */
const BranchDropdownHOC = branch(
    () => {
        const { platform } = store.getState().page;
        return platform.ie9;
    },
    () => BaseDropdownHOC(IEDropdown),
    t => BaseDropdownHOC(t),
)(IEDropdown);

/**
 * Enhanced BranchDropdownHOC
 */
const IECheckedDropdown = enhance(BranchDropdownHOC);

/**
 * EnhancedDropdown as default export
 */
export default IECheckedDropdown;

export { IECheckedDropdown, Dropdown, IEDropdown };

/**
 * A method that clear dropdown value when value doesn't exist in the selection anymore.
 * For mulitple selection, values that doesn't exist in the selection will removed, the
 * rest that exist in selection will be retained.
 *
 * @param {Object} { value, options, isMultiple, handleChange }
 * @returns {boolean} cleared
 */
function handleClearValue({ value, options, isMultiple, handleChange }) {
    // if value is not in options, unset that value
    if (_.isArray(options)) {
        if (!isMultiple) {
            const found = options.find(o => o.value === value);
            // reset value to null
            if (!found) {
                handleChange(null, { value: null });
            }
            return true;
        }

        const found = [];
        // mulitple selection dropdown value have the some of the value
        options.forEach(o => {
            if (value.indexOf(o.value) > -1 && found.length !== value.length) found.push(o.value);
        });

        if (!_.isEqual(found, value)) {
            handleChange(null, { value: found });
            return true;
        }
        return false;
    }
}

/**
 * A method that set dropdown value to the first option in the dropdown value.
 * For both single multiple selection dropdown, before and new value will be compare
 * before action handleChange is called.
 * Equality of before and after value is checked, so that no unnecessary handleChange
 * run (redux change triggered).
 *
 * @param {Object} { value, firstOption, isMultiple, handleChange }
 * @returns {boolean} isSet
 */
export function handleSetFirstOption({ value, firstOption, isMultiple, handleChange }) {
    let isSet = false;
    if (!firstOption.value) return isSet;

    if (!isMultiple) {
        const isEqualValue = _.isEqual(value, firstOption.value);
        if (isEqualValue) return isSet;

        value = firstOption.value;
        handleChange(null, { value });
        isSet = true;
    } else {
        const newValue = [firstOption.value];
        const isEqualValue = _.isEqual(value, newValue);
        if (isEqualValue) return isSet;

        handleChange(null, { value: newValue });
        isSet = true;
    }
    return isSet;
}

/**
 * A method to get the list id from given id string
 * e.g. '17' or '18.${list_id}.blockNo'
 *
 * @param {string} id - id or dotted path
 * @returns {string} id
 *
 * @example
 * getListID('17') // '17'
 * getListID('18.${list_id}.blockNo'); // '18'
 */
export function getListID(id = '') {
    return _.isString(id) && id.includes('.') ? id.split('.')[0] : id;
}

/**
 * A method to load depending list that's found in each targetListTriggerValue.list_id
 *
 * @param {Object[]} [targetListTriggerValues=[]] array of logic, custom_properties.target_list_trigger_value
 * @param {function} loadList - redux action that load list into preset list with given id as param
 * @returns {number[]} ids
 */
export function loadDependingLists(targetListTriggerValues = [], loadList = _.noop) {
    const listIDKey = 'list_id';
    return targetListTriggerValues
        .filter(targetListTriggerValue => {
            const lists = getLists(store.getState()); // list need to get updated in every loop
            const listID = getListID(targetListTriggerValue[listIDKey]);
            const isLoaded = !(listID && lists[listID] === FETCH_KEY);
            if (!isLoaded) {
                loadList(listID);
            }
            return !isLoaded;
        })
        .map(t => t[listIDKey]);
}
