import _ from 'underscore';
import { startLoading, stopLoading } from './PageAction';

import { ERROR_MESSAGES } from '../../components/Misc/api';

import { emailNotificationAPI } from '../../api/template/EmailNotificationAPI';
import { getEmailNotifications } from '../selectors/EmailNotificationSelector';

export const SET_EMAIL_NOTFICATION_ERROR = 'SET_EMAIL_NOTFICATION_ERROR';
export const setEmailNotificationError = error => {
    return {
        type: SET_EMAIL_NOTFICATION_ERROR,
        error,
    };
};

export const SET_EMAIL_NOTIFICATIONS = 'SET_EMAIL_NOTIFICATIONS';
export function setEmailNotifications(emailNotifications) {
    return {
        type: SET_EMAIL_NOTIFICATIONS,
        emailNotifications,
    };
}

export const SET_EMAIL_NOTIFICATION_LIMIT = 'SET_EMAIL_NOTIFICATION_LIMIT';
function setEmailNotificationLimit(limit) {
    return {
        type: SET_EMAIL_NOTIFICATION_LIMIT,
        limit,
    };
}

export const SET_NOTIFICATION_SCENARIOS = 'SET_NOTIFICATION_SCENARIOS';
function setNotificationScenarios(scenarios) {
    return {
        type: SET_NOTIFICATION_SCENARIOS,
        scenarios,
    };
}

// ACTIONS function
export function actionGetEmailNotifications(templateId) {
    return async dispatch => {
        dispatch(startLoading());
        try {
            const { email_notifications, limit } = await emailNotificationAPI.getEmailNotifications(
                templateId,
            );

            dispatch(setEmailNotifications(_.indexBy(email_notifications, 'id')));
            dispatch(setEmailNotificationLimit(limit));
        } catch (e) {
            const { status_cd: statusCode, status_desc: statusDesc } = e.responseJSON || {};
            let errorMessages = ERROR_MESSAGES[statusCode] || statusDesc;
            if (!errorMessages) {
                errorMessages = ERROR_MESSAGES['default'];
            }

            dispatch(setEmailNotificationError(errorMessages));

            return {
                status_cd: statusCode,
                status_desc: statusDesc,
            };
        } finally {
            dispatch(stopLoading());
        }
    };
}

export function actionGetEmailNotification(id, templateId) {
    return async (dispatch, getState) => {
        dispatch(startLoading());
        const emailNotifications = getEmailNotifications(getState());
        try {
            const {
                email_notification: emailNotification,
            } = await emailNotificationAPI.getEmailNotification(id, templateId);
            emailNotifications[id] = emailNotification;
            dispatch(setEmailNotifications(emailNotifications));
        } catch (e) {
            const { status_cd: statusCode, status_desc: statusDesc } = e.responseJSON || {};
            let errorMessages = ERROR_MESSAGES[statusCode] || statusDesc;
            if (!errorMessages) {
                errorMessages = ERROR_MESSAGES['default'];
            }

            dispatch(setEmailNotificationError(errorMessages));

            return {
                status_cd: statusCode,
                status_desc: statusDesc,
            };
        } finally {
            dispatch(stopLoading());
        }
        return emailNotifications[id];
    };
}

export function actionAddEmailNotification(templateId, emailNotification) {
    return async (dispatch, getState) => {
        dispatch(startLoading());
        const emailNotifications = getEmailNotifications(getState());

        try {
            const response = await emailNotificationAPI.addEmailNotification(
                templateId,
                emailNotification,
            );
            const { status_cd, status_desc, email_notification: newEmailNotification } = response;
            emailNotifications[newEmailNotification.id] = newEmailNotification;

            dispatch(setEmailNotifications(emailNotifications));
            return {
                status_cd,
                status_desc,
                email_notification: newEmailNotification,
            };
        } catch (e) {
            const { status_cd: statusCode, status_desc: statusDesc } = e.responseJSON || {};
            let errorMessages = ERROR_MESSAGES[statusCode] || statusDesc;
            if (!errorMessages) {
                errorMessages = ERROR_MESSAGES['default'];
            }

            dispatch(setEmailNotificationError(errorMessages));

            return {
                status_cd: statusCode,
                status_desc: statusDesc,
            };
        } finally {
            dispatch(stopLoading());
        }
    };
}

export function actionUpdateEmailNotification(id, templateId, emailNotification) {
    return async (dispatch, getState) => {
        dispatch(startLoading());
        const emailNotifications = getEmailNotifications(getState());

        try {
            const response = await emailNotificationAPI.updateEmailNotification(
                id,
                templateId,
                emailNotification,
            );
            const {
                status_cd: statusCode,
                status_desc: statusDesc,
                email_notification: updatedEmailNotfication,
            } = response;
            emailNotifications[id] = { ...updatedEmailNotfication };
            dispatch(setEmailNotifications(emailNotifications));

            return {
                status_cd: statusCode,
                status_desc: statusDesc,
                email_notification: updatedEmailNotfication,
            };
        } catch (e) {
            const { status_cd: statusCode, status_desc: statusDesc } = e.responseJSON || {};
            let errorMessages = ERROR_MESSAGES[statusCode] || statusDesc;
            if (!errorMessages) {
                errorMessages = ERROR_MESSAGES['default'];
            }

            dispatch(setEmailNotificationError(errorMessages));

            return {
                status_cd: statusCode,
                status_desc: statusDesc,
            };
        } finally {
            dispatch(stopLoading());
        }
    };
}

export function actionDeleteEmailNotification(id, templateId) {
    return async (dispatch, getState) => {
        dispatch(startLoading());
        const emailNotifications = getEmailNotifications(getState());

        try {
            const response = await emailNotificationAPI.deleteEmailNotification(id, templateId);
            const { status_cd: statusCode, status_desc: statusDesc } = response;

            delete emailNotifications[id];
            dispatch(setEmailNotifications(emailNotifications));

            return {
                status_cd: statusCode,
                status_desc: statusDesc,
            };
        } catch (e) {
            const { status_cd: statusCode, status_desc: statusDesc } = e.responseJSON || {};
            let errorMessages = ERROR_MESSAGES[statusCode] || statusDesc;
            if (!errorMessages) {
                errorMessages = ERROR_MESSAGES['default'];
            }

            dispatch(setEmailNotificationError(errorMessages));

            return {
                status_cd: statusCode,
                status_desc: statusDesc,
            };
        } finally {
            dispatch(stopLoading());
        }
    };
}

export function actionGetNotificationScenarios(templateId) {
    return async dispatch => {
        dispatch(startLoading());
        try {
            const { scenarios } = await emailNotificationAPI.getEmailNotificationScenarios(
                templateId,
            );

            dispatch(setNotificationScenarios(_.indexBy(scenarios, 'id')));
        } catch (e) {
            const { status_cd: statusCode, status_desc: statusDesc } = e.responseJSON || {};
            let errorMessages = ERROR_MESSAGES[statusCode] || statusDesc;
            if (!errorMessages) {
                errorMessages = ERROR_MESSAGES['default'];
            }

            dispatch(setEmailNotificationError(errorMessages));
        } finally {
            dispatch(stopLoading());
        }
    };
}
