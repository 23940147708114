import React from 'react';
import styled from 'styled-components';
import classnames from '../Misc/utils/classnames';

const StyledBetaTag = styled.div`
    position: absolute;
    top: 10px;
    right: 8px;
    font-size: 8px;
    font-weight: bold;
    background-color: #a8a8a8;
    color: white;
    border-radius: 3px;
    padding: 2px 4px;
    line-height: 10px;
`;

const BetaTag = ({ className, children = 'beta' }) => {
    return (
        <StyledBetaTag className={classnames('beta-tag', [className])}>{children}</StyledBetaTag>
    );
};

export default BetaTag;
