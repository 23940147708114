import { reportAPI } from '../../api/report/ReportAPI';
import { blobFileDownloadHandler } from '../misc';

export const SET_REPORT_TYPES_FETCHING = 'SET_REPORT_TYPES_FETCHING';
export function setReportTypeFetching(fetching) {
    return {
        type: SET_REPORT_TYPES_FETCHING,
        fetching,
    };
}

export const SET_REPORT_TYPES_ERROR = 'SET_REPORT_TYPES_ERROR';
export function setReportTypeError(error) {
    return {
        type: SET_REPORT_TYPES_ERROR,
        error,
    };
}

export const SET_REPORT_TYPES = 'SET_REPORT_TYPES';
export function setReportTypes(reportTypes) {
    return {
        type: SET_REPORT_TYPES,
        reportTypes,
    };
}

export const SET_REPORT_CLIENTS = 'SET_REPORT_CLIENTS';
export function setReportClients(clients) {
    return {
        type: SET_REPORT_CLIENTS,
        clients,
    };
}

export function actionGetReportTypes() {
    return async dispatch => {
        dispatch(setReportTypeFetching(true));
        try {
            const { report_type, clients } = await reportAPI.getReportTypes();
            dispatch(setReportTypes(report_type));
            dispatch(setReportClients(clients));
        } catch (e) {
            dispatch(setReportTypeError(e));
        } finally {
            dispatch(setReportTypeFetching(false));
        }
    };
}

export function actionGenerateReport(data, reportType) {
    return async dispatch => {
        dispatch(setReportTypeFetching(true));
        try {
            const response = await reportAPI.generateReport(data, reportType);
            await blobFileDownloadHandler(response.data, response.headers);
        } catch (e) {
            dispatch(setReportTypeError(e));
        } finally {
            dispatch(setReportTypeFetching(false));
        }
    };
}
