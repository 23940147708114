import {
    SET_AJV,
    SET_VALIDATOR,
    SET_VALIDATORS,
    SET_VALIDATORS_ERROR,
} from '../actions/ValidatorAction';

const INITIAL_STATE = {
    ajv: null,
    validators: {},
    fetching: false,
    error: null,
};

export default function validatorReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
    case SET_AJV: {
        const { ajv } = action;
        return {
            ...state,
            ajv,
        };
    }

    case SET_VALIDATOR: {
        const { id, validator } = action.payload;
        return {
            ...state,
            validators: {
                ...state.validators,
                [id]: validator,
            },
        };
    }
    case SET_VALIDATORS: {
        const { validators } = action;
        return {
            ...state,
            validators,
        };
    }

    case SET_VALIDATORS_ERROR:
        const { error } = action;
        return {
            ...state,
            error,
        };
    default:
        return state;
    }
}
