import React from 'react';
import _ from 'underscore';
import ObjectPath from 'object-path';

import LocalStorage from '../LocalStorage';

import { connect } from 'react-redux';
import { actionSetFormView, actionChangeView } from '../../redux/actions/FormAction';
import { withReactSuspense } from '../../containers';
import FormHeadersPlaceholder from './placeholders/FormHeadersPlaceHolder';
import { FormState, View } from 'redux/reducers/FormReducer';
import { RootState } from 'store';

import History from './History';

const FormHeaders = withReactSuspense(
    React.lazy(
        () =>
            new Promise(resolve => {
                setTimeout(() => resolve(import('./FormHeaders')), 0);
            }),
    ),
    FormHeadersPlaceholder,
);
const ViewBuilder = withReactSuspense(React.lazy(() => import('./ViewBuilder')));

interface FormBuilderProps {
    form: FormState;
    isCreatingForm: boolean;
    template: any;
    page: any;
    setFormView: any;
    changeView: any;
    fieldname: any;
    showPlaceholder?: boolean;
}

interface State {
    showing: any;
    showHistory: boolean;
}

class FormBuilder extends React.Component<FormBuilderProps, State> {
    constructor(props: FormBuilderProps) {
        super(props);

        this.localStorage = new LocalStorage();
        const localValue = this.localStorage.getViewingPage();
        const useLocalStorageValue = this.props.isCreatingForm;

        this.state = {
            showing: useLocalStorageValue ? localValue || {} : {},
            showHistory: false,
        };
    }

    render() {
        const { client, form, fieldname = {}, page, showPlaceholder = true } = this.props;
        const { views = [], showingViewNumber = 1, className } = form;
        const classNames = ['form', _.isString(className) && className];
        const { parent = '', current = '' } = fieldname;
        const thisParent = parent ? parent + '.' + current : current;

        const isMerchantDeclarationOfNETS = (view: View) => {
            return client === 'nets' && view.name.trim() === 'Merchant Declaration';
        };

        // Generate Views
        const formComponents = Object.entries(views).map((viewKV, index) => {
            const [key, view] = viewKV;
            const number = parseInt(key) + 1;

            // Ugly fix to pre-calculate the Merchant Declaration view values to fix CAP-5622.
            // Even the user never visited it, it won't trigger view invalidity.
            if (number === showingViewNumber || isMerchantDeclarationOfNETS(view)) {
                return (
                    <ViewBuilder
                        showPlaceholder={showPlaceholder}
                        key={index}
                        sections={view.sections}
                        fieldname={{ parent: `${thisParent}.views`, current: key }}
                    />
                );
            }

            return false;
        });

        return (
            <div
                className={classNames.join(' ')}
                onSubmit={function () {
                    return false;
                }}>
                <FormHeaders
                    showPlaceholder={showPlaceholder}
                    form={form}
                    page={page}
                    showHistory={this.state.showHistory}
                    onHistoryHeaderClicked={() => this.setState({ showHistory: true })}
                    onFormHeaderClicked={(isClickable: boolean, showingViewNumber: number) => {
                        if (!isClickable) return;

                        this.setState({ showHistory: false });

                        const { params = {} } = page;
                        const { templateId, formId } = params;
                        this.props.changeView({
                            templateId,
                            formId,
                            page: showingViewNumber,
                        });
                    }}
                />
                {this.state.showHistory && <History siblings={form.siblings} />}
                {!this.state.showHistory &&
                    formComponents.map((item, idx) => {
                        if (idx === showingViewNumber - 1 || item === false) {
                            return item;
                        } else {
                            return <div style={{ display: 'none' }}>{item}</div>;
                        }
                    })}
            </div>
        );
    }
}

const mapStateToProps = (state: RootState, props) => {
    const formFromPath = props.formPath && ObjectPath.get(state, props.formPath);
    const form = props.formPath ? formFromPath : props.form || state.form;

    return {
        form: form || {},
        isCreatingForm: state.form.isCreatingForm,
        template: state.template,
        page: state.page,
        client: state.user.client,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setFormView: data => {
            dispatch(actionSetFormView(data));
        },
        changeView: data => {
            dispatch(actionChangeView(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    FormBuilder,
);
