import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';
import { withFormUtils, withCaptureFieldHandlers } from '../Misc/forms';
import { showAlertMessage } from '../Misc/forms/popUps';
import { compose, withProps, withHandlers } from 'recompose';
import SessionStorage from '../SessionStorage';
import * as _ from 'underscore';
import ReactDropzone from 'react-dropzone';
import { fileAPI } from '../../api/file/FileAPI';
import SETTINGS from '../../settings';
import Label from './Label';
import { withDownloadFileHandlers } from '../DownloadIndicator';
import { STATUS_CODE, ERROR_MESSAGES } from '../Misc/api';
import store from '../../store';
import { actionSetActionForm } from '../../redux/actions/FormAction';
import { ImageWarning } from '../../images/actions';

const { DISPLAY_DOWNLOAD_POP_UP, DISPLAY_OFFLINE_POP_UP } = SETTINGS;
const OFFLINE_MESSAGE = "You're offline";

export class ButtonUpload extends React.Component {
    counter = 0;
    sessionStorage = new SessionStorage();
    token = this.sessionStorage.getToken();

    constructor(props) {
        super(props);

        this.dropzoneRef = React.createRef();
        this.handleChange = this.props.handleChange;
        this.host = SETTINGS.API_URL || 'http://localhost:8000/';
        this.tokenHeader = this.token ? { Authorization: this.token } : {};
        this.headers = {
            ...this.tokenHeader,
        };

        this.state = {
            files: [],
        };
        this.showUploadErrorMessage = showUploadErrorMessage.bind(this);
    }

    uploadAllowedFiles = ({ files, fieldId, formId, templateId }) => {
        const uploaders = files.map(file => {
            const formData = new FormData();

            formData.append('file', file, file.name);
            formData.append('field_path', fieldId);

            if (templateId) {
                formData.append('template_id', templateId);
            }

            if (formId) {
                formData.append('form_id', formId);
            }

            let fileUploader;

            if (this.props.isLogo) {
                fileUploader = fileAPI.logoUploader({ formData, file }).then(response => response);
            } else if (this.props.isSystemConfiguration) {
                formData.append('name', fieldId);

                fileUploader = fileAPI
                    .systemConfigurationUploader({ formData, file })
                    .then(response => response);
            } else {
                fileUploader = fileAPI.fileUploader({ formData, file }).then(response => response);
            }

            return fileUploader;
        });

        const maliciousFiles = [];
        const invalidFiles = [];
        const failedFiles = [];
        let maliciousFileMessage, invalidFileMessage, failedUploadMessage;
        // results is response of each .fileUploader api call
        Promise.all(uploaders).then(results => {
            let files = [];
            // loop results
            results.forEach((response = {}) => {
                const { data, failed, message } = response;
                const statusCode = response[STATUS_CODE.CODEKEY];
                const statusMessage = response[STATUS_CODE.MESSAGE_KEY];
                let { file } = data;

                file = {
                    ...file,
                    name: file.name,
                };
                // fail
                if (failed) {
                    failedFiles.push(file);
                    failedUploadMessage = message;
                    return;
                }
                // success
                // but malicious
                if (statusCode === STATUS_CODE.MALICIOUS_FILE) {
                    maliciousFiles.push(file);
                    maliciousFileMessage =
                        statusMessage || ERROR_MESSAGES[STATUS_CODE.MALICIOUS_FILE];
                }
                // but extension wrong
                else if (statusCode === STATUS_CODE.INVALID_FILE) {
                    invalidFiles.push(file);
                    invalidFileMessage = statusMessage || ERROR_MESSAGES[STATUS_CODE.INVALID_FILE];
                }
                // all ok
                else {
                    files.push(file);
                }
            });

            const { uploadedFiles = [] } = this.state;
            this.setState({
                uploadedFiles: uploadedFiles.concat(files),
            });

            // if value exist already
            const { value = [], setLocalValue } = this.props;
            if (value) {
                files = [...value, ...files];
            }
            // after combining with existing value, if there's value
            if (files.length) {
                setLocalValue(files);
                this.handleChange(null, {
                    value: [...files],
                });
                // this should run after handleChange redux change
                this.props.onChange(files);
            }

            // handle error
            const singleUpload = results.length === 1;
            if (maliciousFileMessage) {
                const message = maliciousFileMessage;
                this.showUploadErrorMessage({
                    message,
                    files: maliciousFiles,
                    singleUpload,
                });
            }
            if (invalidFileMessage) {
                const message = invalidFileMessage;
                this.showUploadErrorMessage({
                    message,
                    files: invalidFiles,
                    singleUpload,
                    // then if there's malicious files
                    onOk: () => {
                        if (maliciousFileMessage) {
                            const message = maliciousFileMessage;
                            this.showUploadErrorMessage({
                                message,
                                files: maliciousFiles,
                                singleUpload,
                            });
                        } else {
                            this.props.hideForm();
                        }
                    },
                });
            }
            if (failedUploadMessage) {
                const message = `Upload Failed: ${failedUploadMessage}`;

                this.showUploadErrorMessage({
                    message,
                    files: failedFiles,
                    singleUpload,
                });
            }
        });
    };

    onFileUpload = ({ files, fieldId, uploadConfigs = {}, formId, templateId }) => {
        if (DISPLAY_OFFLINE_POP_UP && checkIsDeviceOffline()) {
            store.dispatch(
                showAlertMessage({
                    message: OFFLINE_MESSAGE,
                    image: ImageWarning,
                }),
            );
            return;
        }

        const { acceptedMimeTypes, acceptedFileExtensions, unitCalculation } = this.props;
        const {
            accepted_files: acceptedFilesConfig = [],
            is_multiple: isMultiple = false,
            max_files: maxFiles = 0,
        } = uploadConfigs;

        let { value: existingUploadedFiles = [] } = this.props;
        existingUploadedFiles = existingUploadedFiles || [];

        const acceptedFiles = acceptedFilesConfig.map(acceptedFile => {
            const acceptedFileFormats =
                acceptedMimeTypes[acceptedFileExtensions[acceptedFile.type]];
            return {
                size: acceptedFile.size * unitCalculation,
                type: acceptedFileFormats,
            };
        });

        function getFileExtension(filename) {
            const parts = filename.split('.');
            if (parts.length === 1) {
                return ''; // no file extension found
            }
            return parts[parts.length - 1];
        }

        function checkInvalidFileTypes(uploadedFile) {
            let uploadedInvalidFileType;
            if (_.isEmpty(acceptedFiles)) return;
            const invalidFileType = _.every(acceptedFiles, acceptedFile => {
                // .pem file don't have a file type, it's just empty string
                // so we will get the file extension from filename instead
                if (getFileExtension(uploadedFile.name) === 'pem') {
                    return !_.contains(acceptedFile.type, '.pem');
                }
                return !_.contains(acceptedFile.type, uploadedFile.type);
            });
            if (invalidFileType) {
                uploadedInvalidFileType = uploadedFile;
            }
            return uploadedInvalidFileType;
        }

        function checkInvalidFileSizes(acceptedFiles) {
            const invalidFileSize = files.filter(currentFile => {
                return (
                    currentFile.size > acceptedFiles.size &&
                    _.contains(acceptedFiles.type, currentFile.type)
                );
            });
            if (_.isEmpty(invalidFileSize)) return;
            return invalidFileSize;
        }

        const foundInvalidFileType = _.find(files, checkInvalidFileTypes);
        const foundInvalidFileSize = _.find(acceptedFiles, checkInvalidFileSizes);

        if (foundInvalidFileType) {
            const fileExtension = foundInvalidFileType.name
                .split('.')
                .pop()
                .replace(/^.*[\\\/]/, '');
            this.props.showErrorForm({
                message: `${fileExtension} is not an acceptable file type`,
            });
            return;
        } else if (foundInvalidFileSize) {
            const invalidSizeMB = foundInvalidFileSize.size / Math.pow(1024, 2);
            this.props.showErrorForm({
                message: `Only max ${invalidSizeMB}MB file size is accepted`,
            });
            return;
        }

        // existingUploadedFiles is object or array
        const hasExistingUploadedFile = !_.isEmpty(existingUploadedFiles);
        const multipleExistingUploadedFiles =
            existingUploadedFiles && existingUploadedFiles.length > 0;

        if (!isMultiple && (hasExistingUploadedFile || multipleExistingUploadedFiles)) {
            this.props.showErrorForm({ message: 'Only single file upload is accepted' });
            return;
        }

        // Limit number of multiple file uploads
        // filesSelectedTogetherLength refers to multiple files selected one shot.
        // totalFiles is to combine both lengths.
        const filesSelectedTogetherLength = files.length;
        const existingFilesLength = existingUploadedFiles.length;
        const totalFiles = filesSelectedTogetherLength + existingFilesLength;
        if (isMultiple && maxFiles && totalFiles > maxFiles) {
            this.props.showErrorForm({
                message: `Only a maximum number of ${maxFiles} file uploaded is allowed.`,
            });
            return;
        }

        this.setState({
            files: this.state.files.concat(files),
        });

        this.uploadAllowedFiles({ files, fieldId, formId, templateId });
    };

    uploadButton() {
        this.dropzoneRef.current.open();
    }

    removeFile({ file, valueList }) {
        let filteredUploadedFiles = valueList.filter(i => i.uid !== file.uid);
        if (!filteredUploadedFiles.length) {
            filteredUploadedFiles = null;
        }
        this.props.setLocalValue(filteredUploadedFiles);
        this.handleChange(null, {
            value: filteredUploadedFiles,
        });
        // this should run after handleChange redux change
        this.props.onChange(filteredUploadedFiles);
    }

    handleDownload(value) {
        if (DISPLAY_OFFLINE_POP_UP && checkIsDeviceOffline()) {
            store.dispatch(
                showAlertMessage({
                    message: OFFLINE_MESSAGE,
                    image: ImageWarning,
                }),
            );
            return;
        }

        const id = value.name + this.counter++;
        const { pushDownloadingFiles, popDownloadingFileById } = this.props;
        const fieldId = this.props.initialId;
        const formId = this.props.page.params.formId;
        // set cursor to waiting / loading
        document.body.style.cursor = 'wait';
        // push file into redux
        if (DISPLAY_DOWNLOAD_POP_UP && pushDownloadingFiles) pushDownloadingFiles({ ...value, id });

        if (this.props.isLogo) {
            const url = value.full_path;
            window.open(url, '_blank');
            return;
        }

        fileAPI
            .fileDownloader({ value, fieldId, formId })
            .then(({ data, headers }) => {
                const contentDisposition = headers['content-disposition'];
                const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
                let filename = matches != null && matches[1] ? matches[1] : 'file';
                filename = filename.replace(/['"]/g, '');

                // if iOS devices
                const isSafariMobile =
                    /AppleWebKit|Safari/.test(window.navigator.userAgent) &&
                    /Mobile/.test(window.navigator.userAgent);
                const isChromeiOS = /CriOS/.test(window.navigator.userAgent);

                if (isChromeiOS) {
                    // must test chrome ios first, chrome iOS has Safari string
                    downloadFileForChromeiOS({
                        data,
                        filename,
                    });
                    // pop files from redux
                    if (DISPLAY_DOWNLOAD_POP_UP && popDownloadingFileById)
                        popDownloadingFileById({ id });
                    return;
                } else if (isSafariMobile) {
                    // iOS Mobile only
                    // this method need to enable Safari's disable Pop Up blocker
                    downloadFileForSafariiOS({
                        data,
                        filename,
                    });
                    // pop files from redux
                    if (DISPLAY_DOWNLOAD_POP_UP && popDownloadingFileById)
                        popDownloadingFileById({ id });
                    return;
                }

                // for Desktop
                if (!(window.navigator && window.navigator.msSaveOrOpenBlob)) {
                    // BLOB NAVIGATOR
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const a = document.createElement('a');
                    a.href = url;
                    a.setAttribute('download', filename);
                    document.body.appendChild(a);
                    a.click();
                } else {
                    // BLOB FOR EXPLORER 11
                    window.navigator.msSaveOrOpenBlob(new Blob([data]), filename);
                }
                // reset cursor
                document.body.style.cursor = '';
                // pop files from redux
                if (DISPLAY_DOWNLOAD_POP_UP && popDownloadingFileById)
                    popDownloadingFileById({ id });
            })
            .catch(error => {
                this.props.showErrorForm({ message: `Download Failed: ${error}` });
                // reset cursor
                document.body.style.cursor = '';
                // pop files from redux
                if (DISPLAY_DOWNLOAD_POP_UP && popDownloadingFileById)
                    popDownloadingFileById({ id });
            });
        return false;
    }

    render() {
        const classWarning = 'warning';
        const {
            id,
            label,
            value,
            forceGap,
            onClick,
            errorMessage,
            isValid,
            isEditable,
            showComponent,
            showDownloadButton,
            showRemoveButton,
            uploadConfigs,
            acceptedFormats,
            custom_properties: {
                // Unpack the property title, and if it's not defined set it to 'upload'
                title = 'upload',
            } = {},
            page = {},
        } = this.props;

        const classNames = [
            'jpt--input input__button-upload',
            forceGap && 'jpt--input-gap',
            !isValid && classWarning,
        ];
        const formId = page.params.formId;
        const templateId = page.params.templateId;

        if (!showComponent) return <div />;
        return (
            <div id={id}>
                <div className={classNames.join(' ')}>
                    <Label title={label.title} />

                    {!isEditable && (
                        <div className="jpt--input input__button-upload disabled">
                            <div className="input__label--value">
                                <div style={{ textAlign: 'center' }}>
                                    <Icon name={'upload'} />
                                    {title}
                                </div>
                            </div>
                        </div>
                    )}

                    {isEditable && (
                        <div className="jpt--input input__button-upload">
                            <Button
                                type="button"
                                disabled={false}
                                content={title}
                                icon="upload"
                                onClick={() => {
                                    this.uploadButton();
                                    onClick();
                                }}
                            />
                        </div>
                    )}

                    <ReactDropzone
                        ref={this.dropzoneRef}
                        accept={acceptedFormats}
                        onDrop={files => {
                            this.onFileUpload({
                                files,
                                fieldId: id,
                                uploadConfigs,
                                formId,
                                templateId,
                            });
                        }}
                        multiple={uploadConfigs && uploadConfigs.is_multiple}>
                        {({ getRootProps, getInputProps }) => (
                            <div data-testid="dropzone" {...getRootProps()}>
                                <input {...getInputProps()} />
                            </div>
                        )}
                    </ReactDropzone>

                    <div className="file-upload">
                        {value.map((file, i) => {
                            if (!_.isEmpty(file)) {
                                return (
                                    <div className="file-upload-container" key={i}>
                                        <div className="file-name">{file.name}</div>
                                        {isEditable && showRemoveButton && (
                                            <div>
                                                <Icon
                                                    name="remove"
                                                    data-rh="Remove"
                                                    data-rh-at="left"
                                                    className="file-remove"
                                                    role="button"
                                                    onClick={() =>
                                                        this.removeFile({
                                                            file,
                                                            fieldId: id,
                                                            valueList: value,
                                                        })
                                                    }
                                                />
                                            </div>
                                        )}
                                        {showDownloadButton && (
                                            <div>
                                                <Icon
                                                    name="download"
                                                    data-rh="Download"
                                                    data-rh-at="left"
                                                    className="file-download"
                                                    role="button"
                                                    onClick={() => this.handleDownload(file)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                );
                            }
                            return;
                        })}
                    </div>

                    {errorMessage && (
                        <div className="input__message input__message--error">{errorMessage}</div>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ form, page }) => {
    return {
        form,
        page,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        dispatch,
    };
};

export const withFileFormatProps = compose(
    withFormUtils,
    withProps(() => ({
        acceptedMimeTypes: {
            jpeg: ['image/jpeg', 'image/jpg'],
            png: ['image/png'],
            gif: ['image/gif'],
            bmp: ['image/bmp'],
            tiff: ['image/tiff'],
            svg: ['image/svg+xml'],
            pdf: ['application/pdf'],
            msg: ['application/vnd.ms-outlook'],
            word: [
                'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
                'application/vnd.ms-word.document.macroEnabled.12',
                'application/vnd.ms-word.template.macroEnabled.12',
            ],
            excel: [
                'application/vnd.ms-excel',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
                'application/vnd.ms-excel.sheet.macroEnabled.12',
                'application/vnd.ms-excel.template.macroEnabled.12',
                'application/vnd.ms-excel.addin.macroEnabled.12',
                'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
            ],
            powerpoint: [
                'application/vnd.ms-powerpoint',
                'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                'application/vnd.openxmlformats-officedocument.presentationml.template',
                'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
                'application/vnd.ms-powerpoint.addin.macroEnabled.12',
                'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
                'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
                'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
            ],
            xml: ['application/xml'],
            zip: ['application/zip'],
            rar: ['application/x-rar-compressed'],
            // application/vnd.ms-excel is the MIME type for Windows IE, Edge and Chrome
            // .csv to allow Windows's Chrome to show .csv file as uploadable file
            csv: ['text/csv', 'application/vnd.ms-excel', '.csv'],
            // https://pki-tutorial.readthedocs.io/en/latest/mime.html
            // .pem to allow the file to read
            pem: [
                'application/x-x509-ca-cert',
                'application/x-x509-user-cert',
                'application/x-pem-file',
                '.pem',
            ],
        },
        acceptedFileExtensions: {
            jpg: 'jpeg',
            jpeg: 'jpeg',
            msg: 'msg',
            png: 'png',
            gif: 'gif',
            bmp: 'bmp',
            tiff: 'tiff',
            svg: 'svg',
            pdf: 'pdf',
            doc: 'word',
            xls: 'excel',
            ppt: 'powerpoint',
            xml: 'xml',
            zip: 'zip',
            rar: 'rar',
            csv: 'csv',
            pem: 'pem',
        },
        unitCalculation: 1024 * 1024,
    })),
);

ButtonUpload.defaultProps = {
    value: [],
    label: {},
    isValid: true,
    isEditable: true,
    isLogo: false,
    isSystemConfiguration: false,
    errorMessage: '',
    onClick: _.noop,
    onChange: _.noop,
    showComponent: true,
    showRemoveButton: true,
    showDownloadButton: true,
};

ButtonUpload.propTypes = {
    label: PropTypes.object,
    isValid: PropTypes.bool,
    isLogo: PropTypes.bool,
    isSystemConfiguration: PropTypes.bool,
    isEditable: PropTypes.bool,
    errorMessage: PropTypes.string,
    uploadConfigs: PropTypes.shape({
        is_multiple: PropTypes.bool,
        accepted_files: PropTypes.arrayOf(
            PropTypes.shape({
                type: PropTypes.string,
                size: PropTypes.number,
            }),
        ),
    }),
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    showComponent: PropTypes.bool,
    showRemoveButton: PropTypes.bool,
    showDownloadButton: PropTypes.bool,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withFileFormatProps,
    withCaptureFieldHandlers,
    withDownloadFileHandlers,
    withHandlers({
        hideForm: ({ dispatch }) => {
            return () => {
                dispatch(actionSetActionForm({}));
            };
        },
    }),
    withProps(({ acceptedMimeTypes, acceptedFileExtensions, uploadConfigs = {}, value }) => {
        // process single {} value to support multiple value [{}]
        if (!_.isArray(value) && _.isObject(value)) {
            // array is object, need another is array check
            value = [value];
        } else {
            // to handle null
            value = value || [];
        }

        const acceptedFiles: { type: string; size: number }[] = uploadConfigs.accepted_files || [];
        const acceptedFormats: Record<string, string[]> = {};

        for (const file of acceptedFiles) {
            const mimeTypes = acceptedMimeTypes[acceptedFileExtensions[file.type]];
            for (const mimeType of mimeTypes) {
                if (!acceptedFormats[mimeType]) {
                    acceptedFormats[mimeType] = [];
                }
                acceptedFormats[mimeType].push(`.${file.type}`);
            }
        }

        return {
            value,
            acceptedFormats,
        };
    }),
)(ButtonUpload);

const UploadFilesErrorMessage = ({ message, files }) => {
    return (
        <div>
            {message}
            {files.length && (
                <div className="error-item-wrapper">
                    {files.map((file, i) => (
                        <div key={i} className="error-item">
                            <i className="file outline icon"></i>
                            <span data-rh={file.name}>{file.name}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

function showUploadErrorMessage({ message, files, singleUpload, onOk }) {
    if (!singleUpload) {
        message = <UploadFilesErrorMessage message={message} files={files} />;
    }
    this.props.showErrorForm({
        message,
        onOk,
    });
}

function downloadFileForSafariiOS({ data, filename }) {
    const blob = new Blob([data]);
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = filename;

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    window.URL.revokeObjectURL(url);
}

function downloadFileForChromeiOS({ data, filename }) {
    const base64 = window.URL.createObjectURL(data); // new Blob([data]) doesn't works
    const a = openNewWindowWithLink(base64, filename);
    if (a) {
        a.click();
        setTimeout(() => {
            // delay for iPad
            window.URL.revokeObjectURL(base64);
        }, 1000);
    } else {
        // <a> is not created when window.open failed / undefined
        showPopUpBlockerPopUp();
    }
}

function openNewWindowWithLink(base64, filename) {
    // open new window
    const fileWindow = window.open(window.location.origin + '/download', '_blank');
    if (!fileWindow) {
        // need to enable pop up blocker
        return false;
    }
    // click the file there
    const a = fileWindow.document.createElement('a');
    a.href = base64;
    a.style.display = 'none';
    a.setAttribute('download', filename);
    fileWindow.document.body.appendChild(a);
    fileWindow.stop();

    return a;
}

function showPopUpBlockerPopUp() {
    store.dispatch(
        showAlertMessage({
            message: 'Please turn off Pop-ups Blocker',
        }),
    );
}

const maxWidth = 1500;
const maxHeight = 1500;
const maxSize = 1.0 * 1000 * 8; //bytes
function _resizeImg(img, type, size) {
    if (maxSize >= size) {
        return img;
    }
    const canvas = document.createElement('canvas');

    let width = img.width;
    let height = img.height;

    // calculate the width and height, constraining the proportions
    if (width > height) {
        if (width > maxWidth) {
            height = Math.round((height *= maxWidth / width));
            width = maxWidth;
        }
    } else {
        if (height > maxHeight) {
            width = Math.round((width *= maxHeight / height));
            height = maxHeight;
        }
    }

    // resize the canvas and draw the image data into it
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0, width, height);

    window.document.body.appendChild(canvas); // do the actual resized preview
    const resized = canvas.toDataURL(type || 'image/jpeg', 0.8);
    window.document.body.removeChild(canvas);
    return resized;
}

function checkIsDeviceOffline() {
    // check if it's offline
    if (!window.navigator.onLine) {
        return true;
    }
    return false;
}
