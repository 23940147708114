import moment from 'moment-timezone';
import { compose, withProps } from 'recompose';
import { calendarKey } from '../../Form/FormRules';
import { DEFAULT_FORMAT } from '../datetime';
import { getTimezone } from '../../../timezone';

const TIME_REGEX = /^\d{2}:\d{2}$/;
const DATE_REGEX = /^\d{4}\-\d{2}\-\d{2}$/;
const withTimezone = compose(
    withProps(() => ({
        timezone: getTimezone(),
    })),
);
// NOTE: This applicable for calendar field only
// to allow different type of data to be supported by Calendar component
const withProcessedDatetime = compose(
    withProps(({ type, calendarType, value }) => {
        if (type !== calendarKey.default || !value) return;
        // format component acceptable format
        if (calendarType === calendarKey.time) {
            // 23:59 -> [YYYY-MM-DD] 23:59
            value = value.match(TIME_REGEX)
                ? moment(value, DEFAULT_FORMAT._time).format(DEFAULT_FORMAT.datetime)
                : value;
        } else if (calendarType === calendarKey.date) {
            // 2019-12-31 -> 2019-12-31 00:00
            value = value.match(DATE_REGEX)
                ? moment(value, DEFAULT_FORMAT.date).format(DEFAULT_FORMAT.datetime)
                : value;
        }

        return {
            value,
        };
    }),
    withTimezone,
);
export default withProcessedDatetime;
