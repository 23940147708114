import React from 'react';
import _ from 'underscore';
import moment from 'moment-timezone';
import { MONTHS_LABELS, toValidDate, isMonthInRange } from './misc';
import { getMonth, getDate, getHours, getMinutes, getFullYear } from '../../Misc/datetime';

const MonthColumn = ({ month, index, disabled, selected, onClick }) => {
    const { name, isDisabled } = month;
    const classNames = [
        'calendar-column-month',
        disabled || isDisabled ? 'disabled' : '',
        selected ? 'active' : '',
    ];
    if (name) {
        month = name;
    }
    if (disabled || isDisabled) {
        onClick = _.noop;
    }

    return (
        <div
            className={classNames.join(' ')}
            role="button"
            onClick={e => {
                onClick(e, { month: index });
            }}>
            {month}
        </div>
    );
};

const MonthRows = ({ date, minDate, maxDate, timezone, onClick }) => {
    const selectedMonth = getMonth(date, timezone);

    return (
        <div className="calendar-rows-month">
            {MONTHS_LABELS.map((month, i) => {
                const currentDate = moment([getFullYear(date, timezone), i]).toDate();
                return (
                    <MonthColumn
                        key={i}
                        month={month}
                        disabled={
                            !isMonthInRange({
                                date: currentDate,
                                minDate,
                                maxDate,
                                type: 'months',
                                timezone,
                            })
                        }
                        index={i}
                        selected={selectedMonth === i}
                        onClick={onClick}
                    />
                );
            })}
        </div>
    );
};

const Year = ({ year, onClick }) => {
    return (
        <div className="popup-top-title clickable border" role="button" onClick={onClick}>
            {year}
        </div>
    );
};

const CalendarPopUpMonth = props => {
    const { onYearClick, minDate, maxDate, timezone, onClick } = props;
    let { date } = props;
    date = toValidDate(date);
    const year = getFullYear(date, timezone);
    const day = getDate(date, timezone);
    const hour = getHours(date, timezone);
    const minute = getMinutes(date, timezone);

    return (
        <div className="calendar-month-wrapper">
            <Year year={year} onClick={onYearClick} />
            <MonthRows
                date={date}
                minDate={minDate}
                maxDate={maxDate}
                timezone={timezone}
                onClick={(e, data) => {
                    onClick && onClick(e, { ...data, year, day, hour, minute });
                }}
            />
        </div>
    );
};

export default CalendarPopUpMonth;
