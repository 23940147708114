import { SET_FIELDSET, SET_FIELDSETS, SET_FIELDSETS_CHILDS } from '../actions/FieldsetAction';

const INITIAL_STATE = {
    fieldsets: {},
    fieldsetsFields: {},
    fetching: false,
    error: null,
};

export default function FieldsetReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
    case SET_FIELDSET: {
        const { id, fieldset } = action.payload;

        return {
            ...state,
            fieldsets: {
                ...state.fieldsets,
                [id]: fieldset,
            },
        };
    }
    case SET_FIELDSETS: {
        const { fieldsets } = action;
        return {
            ...state,
            fieldsets: {
                ...state.fieldsets,
                ...fieldsets,
            },
        };
    }
    case SET_FIELDSETS_CHILDS: {
        const { fieldsetsFields } = action;
        return {
            ...state,
            fieldsetsFields: {
                ...state.fieldsetsFields,
                ...fieldsetsFields,
            },
        };
    }
    default:
        return state;
    }
}
