/**
 * "" | null | undefined will fallback to false
 * [] | {} will return [] | {}
 * @param {*} value - value
 * @returns {*} value | false
 */
function defaultToFalse(value = false) {
    return !value ? false : value;
}
export default defaultToFalse;
