const ACTION_TYPES = {
    decline: 'decline',
    assignToSelf: 'assign_self',
    assignToOther: 'assign_other',
    formHistory: 'form_history',
    withdraw: 'withdraw',
    split_application: 'split_application',
};

export { ACTION_TYPES };
