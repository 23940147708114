import _ from 'underscore';
import { compose, withStateHandlers, lifecycle } from 'recompose';
import { isCaptureFieldType } from '../../Form/FormRules';

/**
 * A HOC that enhanced field compenent to set it's local state and
 *   update it's local state based on value passed from prop,
 *   if prop value  is updated, local state value is be updated
 *   to prop value.
 *
 * handler:-
 * 1. setLocalValue
 *
 * logic: -
 * 1. lifecycle to check prop value update
 */
const withValueStateHandler = compose(
    withStateHandlers(null, {
        setLocalValue: () => value => ({
            localValue: value,
        }),
    }),
    lifecycle({
        componentDidUpdate(prevProps) {
            const { type, value, setLocalValue } = this.props;
            const prevValue = prevProps.value;
            if (type && !isCaptureFieldType(type)) {
                return;
            }
            if (!_.isEqual(value, prevValue)) {
                setLocalValue(value);
            }
        },
    }),
);

export default withValueStateHandler;
