import React from 'react';
import _ from 'underscore';

import { compose } from 'recompose';
import JPLabel from './Label';
import { withCaptureFieldHandlers, NULL_VALUE_TEXT } from '../Misc/forms';
import { FieldAction } from '../Misc/template';
import PasswordVisibilityToggle from '../PasswordVisibilityToggle';

class InputWithLabel extends React.Component {
    static defaultProps = {
        className: '',
        fieldname: {},
        label: {},
        placeholder: '',
        isValid: true,
    };

    constructor(props) {
        super(props);

        this.state = {
            isPasswordVisible: false,
        };

        this.delay = (function () {
            var timer = 0;
            return function (callback, ms) {
                clearTimeout(timer);
                timer = setTimeout(callback, ms);
            };
        })();
        this.handleChange = this.props.handleChange.bind(this);
    }

    render() {
        const topClass = 'input__label--top';
        const classWarning = 'warning';
        let {
            id,
            setLocalValue,

            className,
            fieldname,
            // option
            type,
            title,
            name,
            label,
            value,
            placeholder,
            isValid,
            errorMessage,
            onChange,
            message,
            isPassword,
            isEditable,
            // utils
            showComponent,
            path,
        } = this.props;
        const option = {
            type,
            title,
            name,
            label,
            value,
            placeholder,
            message,
            isPassword,
        };
        // handle "0" so that it show "0" value
        value = !value && _.isNumber(value) ? String(value) : value;
        const { current } = fieldname;
        const labelTitle = label.title || 'Label';
        const labelPosition = !label.position ? 'top' : label.position;
        const classNames = [
            'jpt--input input__text input-text-label',
            _.isString(className) && className,
            !isValid && classWarning,
            !isEditable && 'noteditable',
            labelPosition !== 'top' && 'labeled',
            !value && !isEditable && 'null-value',
        ];
        const fieldClassNames = ['input-text', labelPosition !== 'top' ? 'side-label' : ''];

        if (!showComponent) return <div />;

        return (
            <div className={classNames.join(' ')} id={id}>
                {labelPosition === 'top' && <JPLabel className={topClass} {...label} />}

                {isEditable && (
                    <div className={fieldClassNames.join(' ')}>
                        {labelPosition === 'left' && (
                            <div className="text-label note-left basic">{labelTitle}</div>
                        )}

                        <div className="field-input-wrapper">
                            <input
                                className={`field-input ${
                                    isPassword ? 'password-visibility-icon' : ''
                                }`}
                                value={value}
                                name={name}
                                placeholder={placeholder}
                                type={
                                    isPassword && !this.state.isPasswordVisible
                                        ? 'password'
                                        : 'text'
                                }
                                onChange={e => {
                                    const value = e.target.value;
                                    const data = {
                                        value,
                                        path,
                                        fieldname: current,
                                    };
                                    setLocalValue(value);
                                    this.delay(() => {
                                        this.handleChange(e, data);
                                    }, 700);
                                    onChange(e, data);
                                }}
                                onBlur={e => {
                                    const data = {
                                        value: e.target.value,
                                        path,
                                        fieldname: current,
                                    };
                                    if (e.target.value !== value) {
                                        this.delay(() => {
                                            this.handleChange(e, data);
                                        }, 0);
                                    }
                                }}
                            />

                            {isPassword && (
                                <PasswordVisibilityToggle
                                    onClick={isVisible => {
                                        this.setState({
                                            isPasswordVisible: isVisible,
                                        });
                                    }}
                                />
                            )}
                            <div
                                className={
                                    'input__message ' +
                                    (errorMessage ? 'input__message--error' : '')
                                }>
                                {errorMessage}
                            </div>
                        </div>

                        {labelPosition === 'right' && (
                            <div className="text-label note-right basic">{labelTitle}</div>
                        )}
                    </div>
                )}

                {!isEditable && (
                    <div className={fieldClassNames.join(' ')}>
                        {labelPosition === 'left' && (
                            <div className="text-label note-left basic">{labelTitle}</div>
                        )}

                        <div className="input-value-display field-label">
                            {value || NULL_VALUE_TEXT}
                        </div>

                        {labelPosition === 'right' && (
                            <div className="text-label note-right basic">{labelTitle}</div>
                        )}
                    </div>
                )}

                {this.props.isEditingField && <FieldAction path={path} field={option} />}
            </div>
        );
    }
}

export default compose(withCaptureFieldHandlers)(InputWithLabel);
